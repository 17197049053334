import React, { useEffect, useState } from "react";
import styles from "./Invoices.module.css"
import { useAuth } from "../../components/context/Auth";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
import Button from "../../components/updatedComponents/Dealers/Button";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import { DispatchDownloadIcon, DispatchReloadIcon } from "../../components/icons/Icon";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import { Shareinvoice } from "../../components/icons/Icon4";
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import fetchData from "../../APIsControll/apiControll";
import { useNavigate } from 'react-router-dom'
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
const Invoices = () => {
  const navigate = useNavigate();
 const [daysTab, setDaysTab] = useState("today");
  const [keyword1, setKeyword1] = useState("");
  const [data, setData] = useState([])
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [statusData, setStatusData] = useState([])
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [invoicestatus, setInvoicestatus] = useState("All");
  const [loading, setLoading] = useState(false);
  const { mode, managerId } = useAuth();
  const [ordermanager, setOrdermanager] = useState([]);
  const [dispatchmanager, setDispatchmanager] = useState([]);
  const [selectedordermanager, setSelectedOrdermanager] = useState("");
  const [selecteddispatchmanager, setSelectedDispatchmanager] = useState("");
  const [reloadTrigger, setReloadTrigger] = useState(false);
const [filteredData, setFilteredData] = useState([
  { status: "New Order" },
    { status: "Draft" },
    { invoiceStatus: "Payment Pending" },
    { status: "Packing In Progress" },
    { invoiceStatus: "Completed" },
    { status: "Cancelled" }
  ]);
  const [selectedSortby, setSelectedSortby] = useState("");
const dayOptions = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "month",
      label: "This Month",
    },
 ];
  const orderHeader = [
    'check',
    "Name & City",
    "Order Date",
    "Order Status",
    "Order ID",
    "Amount",
    "Status",
    "Last Action",
  ];
  const handleDayChange = (label) => {
    const selectedFilter = dayOptions.find((item) => item.label === label);
    setDaysTab(selectedFilter?.value || "today");
  };
  const options = ordermanager.map(ormanager => ({
    label: ormanager.name,
    value: ormanager.managerId
  }));
  const handleOredermanagerSelection = (optionis) => {
    setSelectedOrdermanager(optionis.value);
  };
  const handleOredermanagerRejection = () => {
    setSelectedOrdermanager(null);
  };
  const options1 = dispatchmanager.map(dismanager => ({
    label: dismanager.name,
    value: dismanager.managerId
  }));
  const option3 = [
    { label: "New Order", value: "New Order" },
    { label: "Draft", value: "Draft" },
    { label: "In Progress", value: "In Progress" },
    { label: "Pending", value: "Pending" },
    { label: "Payment Pending", value: "Payment Pending" },
    { label: "Packing In Progress", value: "Packing In Progress" },
    { label: "Packed", value: "Packed" },
    { label: "Item Out of Stock", value: "Item Out of Stock" },
    { label: "Edit Request Approved", value: "Edit Request Approved" },
    { label: "Dispatched Pending", value: "Dispatched Pending" },
    { label: "Dispatched", value: "Dispatched" },
    { label: "Completed", value: "Completed" },
    { label: "Delivered", value: "Delivered" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Payment Under Verification", value: "Payment Under Verification" },
    { label: "Pending", value: "Pending" },
    { label: "Uploaded", value: "Uploaded" },
    { label: "Reupload", value: "Reupload" },
    { label: "OrderReturn", value: "OrderReturn" },
  ]
  const handleDispatchmanagerSelection = (optionis) => {
    setSelectedDispatchmanager(optionis.value);
  };
  const handleDispatchmanagerRejection = () => {
    setSelectedDispatchmanager(null);
  };
  const getallinvoicedata = async () => {
    setLoading(true)
    try {
      const payload = {
        managerId: parseInt(managerId),
        keyword: keyword1 || '',
        invoiceStatus: invoicestatus,
        sortKey: selectedSortby ? selectedSortby : "",
        ordermanagerId: selectedordermanager,
        dispatchManagerId: selecteddispatchmanager,
        day: daysTab ? daysTab : '',
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      }
      const res = await fetchData(`invoice/allInvoices?page=${page}&limit=20`, payload);
      if (res.status === "success") {
        setLoading(false)
        setData(res)
        setPage(res?.page);
        setPages(res?.pages);
        setStatusData(res?.orders)
        setOrdermanager(res?.managers?.ordermanagers);
        setDispatchmanager(res?.managers?.dispatchmanagers);
       }
    } catch (error) {
      console.log(error);

    }
    finally {
      setLoading(false)

    }
  }
  useEffect(() => {
    getallinvoicedata()
  }, [daysTab, startDate, endDate, page, keyword1, selectedordermanager, selecteddispatchmanager, invoicestatus, selectedSortby, reloadTrigger])

  
  useEffect(() => {
    const filtered = data?.data?.map(item => {
      const statusObj = { status: item.status };
      const invoiceStatusObj = { invoiceStatus: item.invoiceStatus };
      return [statusObj, invoiceStatusObj];
    }).flat();
    setFilteredData(filtered);
  }, [data]);
  const handleSortbySelection = (option) => {
    setSelectedSortby(option?.label);
  };
  const handleSortbyRejection = () => {
    setSelectedSortby(null);
  };
  const getStatusColor = (invoiceStatus) => {
    switch (invoiceStatus) {
      case "ordered":
      case "Packed":
      case "success":
        return "green";
      case "Pending":
        return "#FF9B04";
      case "Uploaded":
        return "#4164E3";
      case "Cancelled":
        return "red";
      case "Reupload":
        return "#34A853";
      case "Not Packed":
      case "ordered Pending":
      case "Payment Under Verification":
      case "Request For Edit":
      case "Edit request Approved":
      case "Request For Cancel":
        return "red";
      case "Packing In Progress":
      case "Draft":
      case "In Progress":
      case "Payment Pending":
        return "red";
      case "New Order":
      case "draft":
        return "#7698FF";
      default:
        return "";
    }
  };
  const handleReload = () => {
    setDaysTab("today")
    setReloadTrigger((prev) => !prev);
  };
  return (
    <React.Fragment>

      <div
        style={{ backgroundColor: mode ? "#232529" : "#D9DCE5", }} className={styles.main} >
        <div className={`${styles.mainCon} ${mode ? styles.mainConDark : styles.mainConLight}`}>
          <div className={styles.order_top}>
            <div className={styles.order_top_left}>
              <TopTabBar text={"Invoice"} />

            </div>
            <div className={styles.order_right}>
              <div>
                <SlidingFilter2
                  itemList={dayOptions.map((item) => item.label)}
                  setFilter={handleDayChange}
                  selectedItem={
                    dayOptions.find((item) => item.value === daysTab)?.label
                  }
                />
              </div>
              <div>

                <InputTypedate
                  text={"From"}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setDaysTab("");
                  }}
                />
              </div>
              <div>

                <InputTypedate
                  text={"To"}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setDaysTab("");
                  }}
                />
              </div>
              <div>
                <Button
                  buttonIcon={<DispatchReloadIcon />}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={handleReload
                  }
                />
              </div>
              <div>
                <Button
                  buttonIcon={<DispatchDownloadIcon />}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                />
              </div>

            </div>
          </div>

          <div
            style={{
              backgroundColor: mode ? "#232529" : "white",
              minWidth: "100%",
              maxWidth: "100%",
              overflowX: "scroll"
            }} className={styles.carddiv}
          >
            {statusData?.map((status, i) => (
              <div
                // onClick={() => setInvoicestatus(status?.invoiceStatus)}
                onClick={() => {
                  setInvoicestatus(status?.invoiceStatus);
                  setPage(1);
                }}
                key={i}
                className={`${styles.order_filter} ${mode ? styles.order_filter_dark : ""
                  } ${status.invoiceStatus === invoicestatus ? styles.order_filter_active : ""
                  }`}   

              >

                <DealerStatusCard
                  statusText={status.invoiceStatus}
                  statusItemCount={status.count}
                />
              </div>
            ))}
          </div>

          <div
            className={styles.search}>
            <div className={styles.searchcontainer}>
              <SearchBar

                placeholder={"Search by Order ID"}
                value={keyword1}
                onChange={(e) => setKeyword1(e.target.value)} />
            </div>
            <div className={styles.dropdowncontainer}>
              <DropDown
                label={" Order Manager"}
                listLabel={"All"}
                options={options}
                onSelect={handleOredermanagerSelection}
                onReject={handleOredermanagerRejection}
              />
              <DropDown
                label={" Dispatch  Manager"}
                listLabel={"All"}
                options={options1}
                onSelect={handleDispatchmanagerSelection}
                onReject={handleDispatchmanagerRejection}
              />

              <DropDown
                label={"Sort By"}
                listLabel={"All"}
                options={option3}
                onSelect={handleSortbySelection}
                onReject={handleSortbyRejection}
              />
              <div ><Button
                buttonText={"Send Data"}
                buttonTextColor={"white"}
                buttonBgColor={"#4164E3"}
                buttonIcon={< Shareinvoice />}
              /></div>
            </div>
          </div>


          <div className={styles.tablemain} >
            <ul className={styles.order_data_list}>
              <li
                className={`${styles.order_data_list_row_header} ${mode ? styles.order_data_list_row_header_dark : ""
                  }`}
              >
                {orderHeader.map((h) => (
                  <div
                    key={h}
                    style={{
                      width: h === "check" ? "5%" : "14%",
                    }}
                  >
                    {h === "check" ? (
                      <div>
                        <label className={styles.cl_checkbox}>
                          <input type="checkbox" />
                          <span></span>
                        </label>
                      </div>
                    ) : (
                      h
                    )}
                  </div>
                ))}
              </li>
              <div className={styles.order_data_list_container}>
                {loading ?
                  <div style={{ margin: "1rem auto" }}>
                    <Spinner height={80} width={80} />
                  </div>
                  :
                  data?.data?.length === 0 ? (
                    <img
                      src="/assets/updatedAssets/noDATA.png"
                      alt="img"
                      style={{
                        height: "12rem",
                        width: "16rem",
                        margin: "1rem auto",
                      }}
                    />
                  ) :
                    <>
                      {data?.data?.map((d, index) => (
                        <li
                          key={index}
                          className={`${styles.order_data_list_row} ${mode ? styles.order_data_list_row_dark : ""
                            }`}>
                          <div style={{ width: "5%", }}>
                            <label style={{ width: "5%", }} className={styles.cl_checkbox}>
                              <input type="checkbox" />
                              <span></span>
                            </label>
                          </div>
                          <div
                            onClick={() => {
                              navigate("/invoices/invoice?orderId=" + d.orderId);
                            }}
                            className={styles.entry}
                            style={{ width: "15%", }}
                          >
                            <div>
                              {d.storeName?.length > 10 ? `${d.storeName.slice(0, 10)}...` : d.storeName}{' '}
                              {d.city?.length > 12 ? `${d.city.slice(0, 12)}...` : d.city}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              navigate("/invoices/invoice?orderId=" + d.orderId);
                            }}
                            className={styles.entry}
                            style={{ width: "15%" }}
                          >
                            {d.orderDate}
                          </div>
                          <div
                            onClick={() => {
                              navigate("/invoices/invoice?orderId=" + d.orderId);
                            }}
                            className={styles.entry}
                            style={{ width: "15%" }}
                          >
                            {d.status.length > 15 ? d.status.substring(0, 15) + "..." : d.status}
                          </div>

                          <div
                            onClick={() => {
                              navigate("/invoices/invoice?orderId=" + d.orderId);
                            }}
                            className={styles.entry}
                            style={{ width: "15%" }}
                          >
                            {d.orderId}
                          </div>
                          <div
                            onClick={() => {
                              navigate("/invoices/invoice?orderId=" + d.orderId);
                            }}
                            className={styles.entry}
                            style={{ width: "15%" }}
                          >
                            {d.totalAmount}
                          </div>
                          <div
                            onClick={() => {
                              navigate("/invoices/invoice?orderId=" + d.orderId);
                            }}
                            className={styles.entry}
                            style={{ width: "15%", color: getStatusColor(d?.invoiceStatus) }}
                          >
                            {d.invoiceStatus}
                          </div>
                          <div
                            onClick={() => {
                              navigate("/invoices/invoice?orderId=" + d.orderId);
                            }}
                            className={styles.entry}
                            style={{ width: "15%" }}
                          >
                            {d.lastAction}
                          </div>
                        </li>
                      ))}
                    </>}
              </div>
            </ul>
          </div>
          <div className={styles.mgx_pagination} style={{ backgroundColor: mode ? "#232529" : "#fff" }} >
            <div style={{ width: "50%" }}>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  fontSize: "14px",
                }}             >
                <GoToPage
                  currentPage={page}
                  setCurrentPage={setPage}
                  totalPages={pages}
                  key={"1"}
                />
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "12px",
              }}
            >
              <Pagenation
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={pages}
                key={"1"}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Invoices