// import React, { useEffect, useRef, useState } from "react";
// import styles from "./Manager.module.css";
// import { ManagerDevices3Dots } from "../../icons/Icon";
// import { useAuth } from "../../context/Auth";
// import fetchData from "../../../APIsControll/apiControll";
// import Skeleton from "../Skeleton/Skeleton";
// import { truncateText } from "../../../Utils/TextUtils";

// const ManagerDevicesTable = () => {
//   const { mode, managerId } = useAuth();
//   const [data, setData] = useState([])
//   const [dropdownVisible, setDropdownVisible] = useState(null);
//   const markReadRef = useRef()
//   const [loading, setloading] = useState({
//     loading1: false
//   })
//   const getAllRequest = async () => {
//     try {
//       setloading({ ...loading, loading1: true })
//       const payload = {
//         managerId: managerId
//       }
//       const res = await fetchData(`managers/allNewDeviceRequest`, payload);
//       // //console.log(res);
//       if (res?.status === "success") {
//         setData(res?.data);
//         setloading({ ...loading, loading1: false })
//         return
//       }
//       if (res?.status === "failed") {
//         setData([]);
//         setloading({ ...loading, loading1: false })
//         return
//       }
//     } catch (error) {
//       //console.log(error);
//       setloading({ ...loading, loading1: false })
//     }
//   }

//   const toggleDropdown = (index) => {
//     setDropdownVisible((prev) => (prev === index ? null : index));
//   };

//   const handleOptionClick = (option, index) => {
//     //console.log(`Row ${index} selected: ${option}`);
//     setDropdownVisible(null); // Close the dropdown after selecting an option
//   };

//   useEffect(() => {
//     getAllRequest()
//   }, [])

//   const getCellStyle = (mode, baseColor = "#1B1D21") => {
//     return mode ? { backgroundColor: baseColor, color: "#E4E7EB" } : {};
//   };

//   const getHeaderStyle = (mode) => {
//     return mode ? { backgroundColor: "#232529", color: "#E4E7EB" } : {};
//   };

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//           //console.log("event",event);

//             if (markReadRef.current && !markReadRef.current.contains(event.target)) {
//               //console.log("hhh");

//               setDropdownVisible(null);  // Reset the subBox state when clicking outside
//             }else{
//               //console.log("hhh dddd");
//             }
//         };

//         document.addEventListener('click', handleClickOutside);
//         return () => {
//             document.removeEventListener('click', handleClickOutside);
//         };
//     }, []);

//   return (
//     <div
//       className={styles.table_container}
//       style={mode ? { backgroundColor: "#3D3D3D" } : {}}
//     >
//       <table className={styles.custom_table}>
//         <thead>
//           <tr>
//             <th style={getHeaderStyle(mode)}>Device ID</th>
//             <th style={getHeaderStyle(mode)}>Login ID</th>
//             <th style={getHeaderStyle(mode)}>Platform</th>
//             <th style={getHeaderStyle(mode)}>OS</th>
//             <th style={getHeaderStyle(mode)}>Status</th>
//             <th style={getHeaderStyle(mode)}>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {
//             loading.loading1 ? <Skeleton lines={3} thickness={4} /> : <>
//               {
//                 data.length === 0 ? <div>No data found</div> :
//                   data.map((e, index) => (
//                     <tr key={index}>
//                       {/* <td style={getCellStyle(mode)}>{e.col1}</td> */}
//                       <td style={getCellStyle(mode)}>{truncateText(e?.deviceId, 16)}</td>
//                       <td style={getCellStyle(mode)}>{truncateText(e?.loginId, 16)}</td>
//                       <td style={getCellStyle(mode)}>{e?.devicePlatform}</td>
//                       <td style={getCellStyle(mode)}>{e?.deviceOS}</td>
//                       <td
//                         style={{ color: "#FB9600", backgroundColor: mode && "#1B1D21" }}
//                       >
//                         {e?.deviceActive}
//                       </td>

//                       <td style={{ ...getCellStyle(mode) }}  >
//                         <div className={styles.threeDot} onClick={() => toggleDropdown(index)}>
//                           <div><ManagerDevices3Dots color={mode ? "#ffffff" : "#1C274C"} /></div>
//                           {dropdownVisible === index && (
//                             <div className={styles.optionIs}
//                               style={{
//                                 backgroundColor: mode ? "#232529" : "#ffff",
//                                 color: mode ? "#fff" : "#000",
//                                 border: mode ? "none" : "1px solid #E4E7EB"
//                               }}
//                               ref={markReadRef}
//                             >
//                               <div className={`${styles.option_item} ${mode ? styles.darkHover : styles.lightHover}`} > <span>Active </span> </div>
//                               <div className={`${styles.option_item} ${mode ? styles.darkHover : styles.lightHover}`} > <span>Disbale </span> </div>
//                             </div>
//                           )}
//                         </div>
//                       </td>

//                     </tr>
//                   ))
//               }
//             </>
//           }

//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default ManagerDevicesTable;

import React, { useEffect, useRef, useState } from "react";
import styles from "./Manager.module.css";
import { ManagerDevices3Dots } from "../../icons/Icon";
import { useAuth } from "../../context/Auth";
import fetchData from "../../../APIsControll/apiControll";
import Skeleton from "../Skeleton/Skeleton";
import { truncateText } from "../../../Utils/TextUtils";
import { useToast } from "../Toaster/Toaster";
import NoDataFound from "../NoDataFound/NoDataFound";
import { TemplateNotFound } from "../../icons/Icon2";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import ManagerModal from "./ManagerModal";
import { socket, SocketConnected } from "../../../Socket/socket";

const ManagerDevicesTable = ({ setRequestLength, modelTogele }) => {
  const { mode, managerId ,designation } = useAuth();
  const [data, setData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [modelContact, setModelContact] = useState(null);
  const [modelState, setModeleState] = useState(false);
  const dropdownRefs = useRef([]);
  const { show } = useToast();

  const [loading, setloading] = useState({
    loading1: false,
    loading2: false,
  });

  useEffect(() => {
    setRequestLength(Number(data.length));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    // const socket = io(process.env.REACT_APP_URL);
    SocketConnected({ socketUserId: `${managerId}`, role: designation, warehouseNames: JSON.parse(localStorage.getItem("warehouse")) });
    socket.on("allPendingRequestsUpdated", (allPendingRequestsUpdated) => {
      return setData([...data, ...allPendingRequestsUpdated?.result]);
    });
    // return () => {
    //   socket.disconnect();
    // };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togleModel = (contant) => {
    setModelContact(contant);
    setModeleState(true);
  };

  const getAllRequest = async () => {
    try {
      // setloading({ ...loading, loading1: true });
      const payload = {
        managerId: managerId,
      };
      const res = await fetchData(`managers/allNewDeviceRequest`, payload);
      if (res?.status === "success") {
        setData(res?.data);
        // setloading({ ...loading, loading1: false });
        return;
      }
      if (res?.status === "failed") {
        setData([]);
        // setloading({ ...loading, loading1: false });
        return;
      }
    } catch (error) {
      // setloading({ ...loading, loading1: false });
    }
  };

  const toggleDropdown = (index) => {
    setDropdownVisible((prev) => (prev === index ? null : index));
  };

  const handleOptionClick = async (option, data) => {
    if (option === "Active") {
      try {
        const paylod = {
          managerId: managerId,
          deviceIds: [data?.deviceId],
        };
        setloading({ ...loading, loading2: true });
        const res = await fetchData(`managers/accessDeviceRequest`, paylod);
        if (res?.status === "success") {
          getAllRequest();
          setModeleState(false);
          show(`${res?.msg}`, "success");
          setloading({ ...loading, loading2: false });
          return;
        }
        if (res?.status === "failed") {
          show(`${res?.msg}`, "success");
          setloading({ ...loading, loading2: false });
          return;
        } else {
          show("something went wrong", "error");
        }
      } catch (error) {
        show("something went wrong", "error");
      }
      return;
    }
    if (option === "Disabled") {
      try {
        const paylod = {
          managerId: managerId,
          deviceIds: [data?.deviceId],
        };
        setloading({ ...loading, loading2: true });
        const res = await fetchData(`managers/disabledDeviceRequest`, paylod);
        if (res?.status === "success") {
          getAllRequest();
          setModeleState(false);
          show(`${res?.msg}`, "success");
          setloading({ ...loading, loading2: false });
          return;
        }
        if (res?.status === "failed") {
          show(`${res?.msg}`, "success");
          setloading({ ...loading, loading2: false });
          return;
        } else {
          show("something went wrong", "error");
        }
      } catch (error) {
        show("something went wrong", "error");
      }
      return;
    }

    setDropdownVisible(null);
  };

  useEffect(() => {
    getAllRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownVisible !== null &&
        dropdownRefs.current[dropdownVisible] &&
        !dropdownRefs.current[dropdownVisible].contains(event.target)
      ) {
        setDropdownVisible(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisible]);

  const getCellStyle = (mode, baseColor = "#1B1D21") => {
    return mode ? { backgroundColor: baseColor, color: "#E4E7EB" } : {};
  };

  const getHeaderStyle = (mode) => {
    return mode ? { backgroundColor: "#232529", color: "#E4E7EB" } : {};
  };
  useEffect(() => {
    setModeleState(false);
  }, [modelTogele]);

  return (
    <>
      <ModalWrapper
        isOpen={modelState}
        onClose={() => setModeleState(!modelState)}
      >
        {modelContact}
      </ModalWrapper>
      <div
        className={styles.table_container}
        style={mode ? { backgroundColor: "#2C2E33" } : {}}
      >
        <table className={styles.custom_table}>
          <thead>
            <tr>
              <th style={getHeaderStyle(mode)}>Device ID</th>
              <th style={getHeaderStyle(mode)}>Device Name</th>
              <th style={getHeaderStyle(mode)}>Manager Name</th>
              <th style={getHeaderStyle(mode)}>Login ID</th>
              <th style={getHeaderStyle(mode)}>Platform</th>
              <th style={getHeaderStyle(mode)}>OS</th>
              <th style={getHeaderStyle(mode)}>Status</th>
              <th style={getHeaderStyle(mode)}>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading.loading1 ? (
              <tr style={{ border: "1px solid" }}>
                <td style={getCellStyle(mode)}>
                  {" "}
                  <Skeleton lines={3} thickness={4} />{" "}
                </td>
                <td style={getCellStyle(mode)}>
                  {" "}
                  <Skeleton lines={3} thickness={4} />{" "}
                </td>
                <td style={getCellStyle(mode)}>
                  {" "}
                  <Skeleton lines={3} thickness={4} />{" "}
                </td>
                <td style={getCellStyle(mode)}>
                  {" "}
                  <Skeleton lines={3} thickness={4} />{" "}
                </td>
                <td style={getCellStyle(mode)}>
                  {" "}
                  <Skeleton lines={3} thickness={4} />{" "}
                </td>
                <td style={getCellStyle(mode)}>
                  {" "}
                  <Skeleton lines={3} thickness={4} />{" "}
                </td>
              </tr>
            ) : (
              <>
                {data.length === 0 ? (
                  <tr
                    style={{
                      height: "100%",
                      border: "1px solid",
                      width: "100%",
                    }}
                  >
                    <td style={getCellStyle(mode)}> </td>
                    <td style={getCellStyle(mode)}> </td>
                    <td style={getCellStyle(mode)}> </td>
                    <td style={getCellStyle(mode)}>
                      <div style={{ width: "10px" }}>
                        <NoDataFound icon={<TemplateNotFound />} />
                      </div>
                    </td>
                    <td style={getCellStyle(mode)}> </td>
                    <td style={getCellStyle(mode)}> </td>
                    <td style={getCellStyle(mode)}> </td>
                    <td style={getCellStyle(mode)}> </td>
                  </tr>
                ) : (
                  data.map((e, index) => (
                    <tr key={index}>
                      <td style={getCellStyle(mode)}>
                        {truncateText(e?.deviceId, 16)}
                      </td>
                      <td style={getCellStyle(mode)}>
                        {truncateText(e?.deviceName, 16)}
                      </td>
                      <td style={getCellStyle(mode)}>
                        {truncateText(e?.managerName, 16)}
                      </td>
                      <td style={getCellStyle(mode)}>
                        {truncateText(e?.loginId, 16)}
                      </td>
                      <td style={getCellStyle(mode)}>{e?.devicePlatform}</td>
                      <td style={getCellStyle(mode)}>{e?.deviceOS}</td>
                      <td
                        style={{
                          color: "#FB9600",
                          backgroundColor: mode && "#1B1D21",
                        }}
                      >
                        {e?.deviceActive}
                      </td>
                      <td style={{ ...getCellStyle(mode) }}>
                        <div
                          className={styles.threeDot}
                          onClick={(e) => {
                            toggleDropdown(index);
                            e.stopPropagation();
                          }}
                        >
                          <div>
                            <ManagerDevices3Dots
                              color={mode ? "#ffffff" : "#1C274C"}
                            />
                          </div>
                          {dropdownVisible === index && (
                            <div
                              className={styles.optionIs}
                              style={{
                                backgroundColor: mode ? "#232529" : "#ffff",
                                color: mode ? "#fff" : "#000",
                                border: mode ? "none" : "1px solid #E4E7EB",
                              }}
                              ref={(el) => (dropdownRefs.current[index] = el)}
                            >
                              <div
                                className={`${styles.option_item} ${
                                  mode ? styles.darkHover : styles.lightHover
                                }`}
                                // onClick={handleOptionClick("Active", e)}
                                onClick={() =>
                                  togleModel(
                                    // <RequestModel
                                    //   colorcode={"green"}
                                    //   title={"Accept"}
                                    //   handleClose={() => setModeleState(false)}
                                    //   handeSubmit={() =>
                                    //     handleOptionClick("Active", e)
                                    //   }
                                    // />
                                    <ManagerModal
                                      heading={"Accept Request"}
                                      centerHeading={
                                        "Are you sure you want to Accept?"
                                      }
                                      subHeading={
                                        "To confirm! type accept in the text field."
                                      }
                                      targetWord={"accept"}
                                      handleSubmit={() => {
                                        handleOptionClick("Active", e);
                                      }}
                                      handleCloseModal={() =>
                                        setModeleState(false)
                                      }
                                      focus={true}
                                    />
                                  )
                                }
                              >
                                <span>Accept</span>
                              </div>
                              <div
                                className={`${styles.option_item} ${
                                  mode ? styles.darkHover : styles.lightHover
                                }`}
                                // onClick={() => handleOptionClick("Disabled", e)}
                                onClick={() =>
                                  togleModel(
                                    // <RequestModel
                                    //   colorcode={"red"}
                                    //   title={"Decline"}
                                    //   handleClose={() => setModeleState(false)}
                                    //   handeSubmit={() =>
                                    //     handleOptionClick("Disabled", e)
                                    //   }
                                    // />
                                    <ManagerModal
                                      heading={"Decline Request"}
                                      centerHeading={
                                        "Are you sure you want to Decline?"
                                      }
                                      subHeading={
                                        "To confirm! type decline in the text field."
                                      }
                                      targetWord={"decline"}
                                      handleSubmit={() => {
                                        handleOptionClick("Disabled", e);
                                      }}
                                      handleCloseModal={() =>
                                        setModeleState(false)
                                      }
                                      focus={modelState}
                                    />
                                  )
                                }
                              >
                                <span>Decline</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ManagerDevicesTable;
