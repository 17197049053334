import { DragNDropIcon } from "../../icons/Icon";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { useRef, useState } from "react";

const APKUpload = ({
  mode,
  apkFile,
  setApkFile,
  fileType = "application/vnd.android.package-archive",
  onFileChange,
  uploadFile,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const validateFileType = (file) => file.type === fileType;

  const handleUpload = async (file) => {
    setLoading(true);
    try {
      await uploadFile(file);
      setApkFile(file);
      onFileChange && onFileChange(file);
    } catch (error) {
      alert("Upload failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file && validateFileType(file)) {
      handleUpload(file);
    } else {
      alert("Please upload a valid APK file.");
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file && validateFileType(file)) {
      handleUpload(file);
    } else {
      alert("Please upload a valid APK file.");
    }
  };

  const onButtonClick = () => {
    if (!loading) inputRef?.current?.click();
  };

  const handleDelete = () => {
    if (loading) return;
    setApkFile(null);
    onFileChange && onFileChange(null);
  };

  return (
    <>
      <div
        className={styles.file_upload}
        style={
          mode ? { backgroundColor: "#1B1D21", borderColor: "#1B1D21" } : {}
        }
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={onButtonClick}
      >
        {loading ? (
          <p style={{ color: mode ? "#FFFFFF" : "#646B88" }}>Uploading...</p>
        ) : apkFile ? (
          <p style={{ color: mode ? "#FFFFFF" : "#646B88" }}>
            {apkFile.name || apkFile}
          </p>
        ) : (
          <div className={styles.file_upload_text}>
            <DragNDropIcon />
            <p>Drag & Drop an APK file</p>
            <p style={mode ? { color: "#fff" } : {}}>
              or <span>browse files</span> on your computer
            </p>
            <input
              type="file"
              id="fileInput"
              accept={fileType}
              style={{ display: "none" }}
              ref={inputRef}
              onChange={handleChange}
              disabled={loading}
            />
            {dragActive && (
              <div
                className={styles.drag_overlay}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "12px",
        }}
        className={styles.file_upload_footer}
      >
        <span style={{ color: "#646B88" }}>Only APK files allowed</span>
        {apkFile && (
          <span
            style={{ color: "#F93E3E", cursor: "pointer" }}
            onClick={handleDelete}
          >
            Remove
          </span>
        )}
      </div>
    </>
  );
};

export default APKUpload;
