import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  AddManager,
  AllOrders,
  Dealers,
  EstimateInvoice,
  Kyc,
  MainMobileChats,
  MainWhatsappChat,
  Newviewdispatchdetails,
  NoMatch,
  Order,
  OrderDealers,
  SaleOrderInovice,
  TransferDealer,
  ViewDispatchDetails,
  ViewDispatchDetailsMob,
} from "../exports";
import WhatsappMobSaleOrder from "../mobile/pages/WhatsappMobSaleOrder";



const SplitScreenLayout = () => {
  return (
    <div style={styles.container}>
      <div style={styles.leftPanel}>
        <Order />
      </div>
      <div style={styles.rightPanel}>
        <WhatsappMobSaleOrder />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    height: "90vh",
  },
  leftPanel: {
    width: "75%",

  },
  rightPanel: {
    width: "25%",

  },
};


const OrderManagerRoutes = ({ width }) => {
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");

  return (
    <Routes>
      {/* <Route path="/dashboard" element={<AllOrders />} /> */}
      <Route path="/profile" element={<AddManager />} />
      <Route path="/dealers/:id" element={<Kyc />} />
      <Route path="/dealers" element={<Dealers />} />
      <Route path="/dealers/transferdealer" element={<TransferDealer />} />
      <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <Newviewdispatchdetails />} />
      <Route path="/orders/order/:route" element={<Order />} />
      <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <ViewDispatchDetails />} />
      <Route path="/orders" element={<AllOrders />} />
      <Route path="/orders/order/:route" element={<Order />} />
      <Route path="/orders/saleinvoice" element={<SaleOrderInovice />} />
      <Route path="/orders/dealers" element={<OrderDealers />} />
      <Route path="/orders/estimateinvoice" element={<EstimateInvoice />} />
      <Route path="/whatsappchats" element={width < 1084 ? <MainMobileChats /> : <MainWhatsappChat />} />
      {!orderId &&
        (<Route path="/orders/order/saleorder" element={<SplitScreenLayout />} />)}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default OrderManagerRoutes;
