import { io } from 'socket.io-client';
const socket = io(process.env.REACT_APP_URL, {
    transports: ["websocket"],
    withCredentials: true
});

const SocketConnected = (data) => {
    // console.log('Socket Connected',data);
    socket.emit('Socket Connected', data);
}


export { 
    SocketConnected, 
    socket
 };