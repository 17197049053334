import React, { useEffect, useRef } from 'react'
import styles from './Wrapper.module.css'
// import { useAuth } from '../../context/Auth';
const DrawerWrapper = ({ isOpen, children, onClose }) => {
    // const { mode } = useAuth()
    const drawerRef = useRef(null);

    const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            onClose();
        }
    };
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);
    return (
        <div ref={drawerRef} className={`${styles.drawer} ${isOpen ? styles.open : ""}`}  >
            <div className={styles.drawerContent}>
                {children}
            </div>
        </div>
    )
}

export default DrawerWrapper