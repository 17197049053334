import React, { useRef, useState } from 'react';
import { useAuth } from '../../context/Auth';
import styles from '../../../pages/Home.module.css';
import InputTag from '../FormComponents/InputTag';
import { LogInBtnIcon } from '../../icons/Icon';
import { PInloginIcon } from '../../icons/Icon2';

const Pinlogin = ({ loading, handleClick, confirmPin, setConfirmPin, name }) => {
    const { mode } = useAuth();
    const [error, setError] = useState('');
    const confirmPinRefs = useRef([]);

    const handlePinChange = (value, index) => {
        if (value.length > 1 || !/^\d$/.test(value)) return;

        const updatedConfirmPin = [...confirmPin];
        updatedConfirmPin[index] = value;
        setConfirmPin(updatedConfirmPin);

        setTimeout(() => {
            if (value && index < 5) {
                confirmPinRefs.current[index + 1].focus();
            }
        }, 100);
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const updatedConfirmPin = [...confirmPin];
            updatedConfirmPin[index] = '';
            setConfirmPin(updatedConfirmPin);

            if (index > 0) confirmPinRefs.current[index - 1].focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const confirmPinValue = confirmPin.join('');

        if (confirmPinValue.length !== 6) {
            setError('PIN must be exactly 6 digits.');
            return;
        }

        setError('');
        handleClick()
    };

    return (
        <div
            className={styles.login_container}
            style={mode ? { backgroundColor: '#2C2E33' } : {}}
        >
            <div
                className={styles.login1}
                style={mode ? { backgroundColor: '#232529' } : {}}
            >
                <div className={styles.sub_Login}>
                    <div className={styles.login_Header}>
                        <div className={styles.login_Header_Text}>
                            <h2 style={mode ? { color: '#ffffff' } : {}}>Login</h2>
                            <p style={mode ? { color: '#9C9797' } : {}}>
                                Now you are able to login to your account with your PIN
                            </p>
                        </div>
                        <div>
                            <PInloginIcon />
                        </div>
                    </div>
                    <form className={styles.login_Form} onSubmit={handleSubmit}>
                        <div className={styles.loginFormconintener}>
                            <div className={styles.input_group}>
                                <InputTag
                                    maxLength={50}
                                    // value={ localStorage.getItem("loginName") || localStorage.getItem('loginID')}
                                    value={name || localStorage.getItem("loginName")}
                                    readOnly={true}
                                />
                            </div>
                            <div
                                className={styles.input_group}
                                style={{ marginTop: '20px' }}
                            >
                                <label
                                    className={styles.input_Tage_lable}
                                    style={mode ? { color: '#ffffff' } : {}}
                                >
                                    Enter PIN
                                </label>
                                <div className={styles.pin_container}>
                                    {confirmPin.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="tel"
                                            autoFocus={index === 0 ? true : false}
                                            maxLength={1}
                                            value={digit}
                                            onChange={(e) =>
                                                handlePinChange(e.target.value, index)
                                            }
                                            onKeyDown={(e) =>
                                                handleKeyDown(e, index)
                                            }
                                            className={styles.pin_input}
                                            ref={(el) =>
                                                (confirmPinRefs.current[index] = el)
                                            }
                                            style={{
                                                backgroundColor: mode
                                                    ? '#1B1D21'
                                                    : '#fff',
                                                color: mode ? '#fff' : '#000',
                                                border: mode
                                                    ? 'none'
                                                    : '1px solid #E4E7EB',
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                            {error && (
                                <div className={styles.error_message}>{error}</div>
                            )}
                            <button
                                type="submit"
                                disabled={loading}
                                className={styles.login_button}
                                style={{ marginTop: '20px' }}
                            >
                                {loading ? (
                                    <>
                                        <span className={styles.spinner}></span>
                                        <p>Loading...</p>
                                    </>
                                ) : (
                                    <>
                                        <LogInBtnIcon />
                                        <p>Login</p>
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                    <div style={{ marginTop: "40px" }} />
                </div>
            </div>
        </div>
    );
};

export default Pinlogin;




