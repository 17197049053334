import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../components/context/Auth";
import styles from "./Warehouse.module.css";
import { ManagerCameraIcon } from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import { CreateTem, TemplateNotFound } from "../../components/icons/Icon2";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import PaginationComponent from "../../components/updatedComponents/Pagnation/Pagenation";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import { Editwarehouse, View12 } from "../../components/icons/Icon4";
import WarehouseInfoCard from "../../components/updatedComponents/Dealers/Warehousecardinfo";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import fetchData from "../../APIsControll/apiControll";
import { formatDate } from "../../Utils/TextUtils";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import InputRedio from "../../components/updatedComponents/FormComponents/InputRedio";
import LocationSarchFilter from "../../components/updatedComponents/Filter/LocationSarchFilter";
import NoDataFound from "../../components/updatedComponents/NoDataFound/NoDataFound";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
// import { io } from "socket.io-client";
  import {socket} from "../../Socket/socket"
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
const Warehouse = () => {
  const { mode, managerId } = useAuth();
  const { show } = useToast();
  const [keyword1, setKeyword1] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedState, setSelectedState] = useState("");
  const [isimage, setIsimage] = useState(null);
  const [locationData, setLocationData] = useState("");
  const [fileis, setfileis] = useState(null);
  const [imagefile, setImagefile] = useState(null);
  const [status, setStatus] = useState("All");
  const [resData, setResData] = useState();
  const [getAllWarehouseLoading, setgetAllWarehouseLoading] = useState(false);
  const [show1, setShow] = useState(false);
  const [pincode, setPincode] = useState("");
  const [viewEdit, setViewEdit] = useState(0);
  const [warehouseData, setWarehouseData] = useState([]);
  const [previewImage, setPreviewImage] = useState(
    "/assets/updatedAssets/warehouseimage.png"
  );
  const warehouseStatusData = [
    { statusText: "All", statusItemCount: resData?.total },
    { statusText: "Active", statusItemCount: resData?.statusCounts?.active },
    { statusText: "Disable", statusItemCount: resData?.statusCounts?.disable },
  ];
  const [state, setState] = useState({
    totalPages: "",
    currentPage: 1,
  });
  const fileInputRef = useRef(null);

  const [state1, setState1] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: [],
  });
  const [District, setDistrict] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: [],
  });
  const [City, setCity] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: [],
  });
  const handlePhotoUploadClick = () => {
    fileInputRef.current.click();
  };
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
      setfileis(file);
    }
  };
  const getTruncatedName = (name, city, state) => {
    const fullName = `${name || ""}, ${city || ""}, ${state || ""}`;
    let maxLength;
    if (window.innerWidth <= 350) {
      maxLength = 4;
    } else if (window.innerWidth <= 500) {
      maxLength = 6;
    } else if (window.innerWidth <= 700) {
      maxLength = 8;
    } else if (window.innerWidth >= 701 && window.innerWidth <= 900) {
      maxLength = 8;
    } else {
      maxLength = 12;
    }
    return `${fullName.slice(0, maxLength)}${
      fullName.length > maxLength ? "..." : ""
    }`;
  };

  const [dataWare, setDataWare] = useState({
    warehousename: "",
    warehouse_id: "",
    Keyword1: "",
    warehouseId: "",
    street: "",
    pincode: "",
    tempclosed: "",
    description: "",
    workingdays: "",
    status: "",
    latitude: "",
    longitude: "",
    warehousePrefix: "",
    city: "",
    district: "",
    state: "",
    file: "",
    googleMapLink: "",
  });
  const [fields, setFields] = useState([
    { workday: "Monday", workstatus: "" },
    { workday: "Tuesday", workstatus: "" },
    { workday: "Wednesday", workstatus: "" },
    { workday: "Thursday", workstatus: "" },
    { workday: "Friday", workstatus: "" },
    { workday: "Saturday", workstatus: "" },
    { workday: "Sunday", workstatus: "" },
  ]);
  function handleChange(i, event) {
    const values = [...fields];
    values[i]["workstatus"] = event.target.value;
    setFields(values);
  }
  const [tempClosed, setTempClosed] = useState({
    fromdate: "",
    tilldate: "",
  });
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const todayDateString = today.toISOString().split("T")[0];
  const getAllWarehouse = async () => {
    try {
      const payload = {
        managerId: managerId,
        keyword: keyword1 || "",
        status: status.toLowerCase() || "",
        locations: {
          states: state1.selectFilter,
          districts: District.selectFilter,
          cities: City.selectFilter,
          pincodes: [],
        },
      };
      setgetAllWarehouseLoading(true);
      const res = await fetchData(
        `warehouses/getAllWarehouse?page=${page}&limit=32`,
        payload
      );
      setgetAllWarehouseLoading(false);
      if (res.status === "success") {
        setPage(res?.page);
        setPages(res?.pages);
        setWarehouseData(res.data);
        setResData(res);
        return;
      }
      if (res.status === "failed") {
        setWarehouseData([]);
        setgetAllWarehouseLoading(false);
        return;
      }
      if (res.pages) {
        setState((prevState) => ({
          ...prevState,
          totalPages: res.pages || "",
        }));
      }
    } catch (error) {
      setgetAllWarehouseLoading(false);
      console.error("Error fetching warehouse data:", error);
    }
  };
  useEffect(() => {
    getAllWarehouse();
  }, [
    page,
    selectedState,
    keyword1,
    status,
    state1.selectFilter,
    District.selectFilter,
    City.selectFilter,
  ]);

  const getWareHouse = (warehouseId) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      warehouse_id: warehouseId,
      managerId: managerId,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(process.env.REACT_APP_URL + `warehouses/getWarehouse`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setDataWare({
            ...dataWare,
            description: result?.data?.description,
            pincode: result?.data?.pincode,
            warehousename: result?.data?.warehousename,
            warehousePrefix: result?.data?.warehouse_id,
            warehouseId: result?.data?.warehouseId,
            googleMapLink: result?.data?.googleMapLink,
            state: result?.data?.state,
            city: result?.data?.city,
            district: result?.data?.district,
            street: result?.data?.street,
            status: result?.data?.status,
            latitude: result?.data?.location?.latitude,
            longitude: result?.data?.location?.longitude,
          });
          setTempClosed({
            ...tempClosed,
            fromdate: formatDate(result?.data?.tempclosed[0]?.fromdate),
            tilldate: formatDate(result?.data?.tempclosed[0]?.tilldate),
          });
          setFields(result.data.workingdays);
          setShow(true);
          setLocationData(result?.data?.location);
          setImagefile(result.data.profileImage);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getFilterData = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      managerId: managerId,
      stateSearch: setSelectedState,
      districtSearch: "",
      citySearch: "",
      pincodeSearch: "",
      locations: {},
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_URL + "warehouses/filterLocation",
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.status === "success") {
          setDistrict((pre) => ({
            ...pre,
            locationList: result?.data?.districts,
          }));
          setState1((pre) => ({ ...pre, locationList: result?.data?.states }));
          setCity((pre) => ({ ...pre, locationList: result?.data?.cities }));
        } else {
          console.error("API Response Error:", result);
        }
      })
      .catch((error) => console.error("Fetch Error:", error));
  };
  useEffect(() => {
    getFilterData();
  }, [selectedState]);
  const getLocationData = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      keyword: dataWare?.pincode,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setDataWare({
            ...dataWare,
            state: result.states[0],
            city: result.SubDistName[0],
            district: result.districts[0],
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (dataWare?.pincode?.length === 6) {
      getLocationData();
    }
  }, [dataWare?.pincode]);
  const validatePincode = (code) => {
    const regex = /^[1-9][0-9]{5}$/;
    return regex.test(code);
  };
  const [errors, setErrors] = useState({
    warehousename: "",
    warehousePrefix: "",
    pincode: "",
    street: "",
    latitude: "",
    longitude: "",
    file: "",
  });

  const validateFields = () => {
    const validationErrors = {};
    if (!dataWare?.warehousename?.trim())
      validationErrors.warehousename = "Warehouse name is required.";
    if (!dataWare?.warehousePrefix?.trim())
      validationErrors.warehousePrefix = "Warehouse ID is required.";
    if (!pincode?.trim()) {
      validationErrors.pincode = "Pincode is required.";
    } else if (!validatePincode(pincode)) {
      validationErrors.pincode =
        "Invalid Pincode. Please enter a valid 6-digit PIN Code.";
    }
    if (!dataWare?.street?.trim())
      validationErrors.street = "Street is required.";
    if (!dataWare?.latitude)
      validationErrors.latitude = "Latitude is required.";
    if (!dataWare?.longitude)
      validationErrors.longitude = "Longitude is required.";
    if (!fileis) validationErrors.file = "Profile image is required.";

    if (!dataWare?.status?.trim())
      validationErrors.status = "Status is required.";

    const hasIncompleteWorkdays = fields.some(
      (field) => !field.workstatus?.trim()
    );
    if (hasIncompleteWorkdays) {
      validationErrors.workingdays =
        "Please select a status for all working days.";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const postWarehouse = () => {
    if (!validateFields()) {
      return;
    }

    const formData = new FormData();

    formData.append("managerId", managerId);
    formData.append("warehousename", dataWare?.warehousename);
    formData.append("street", dataWare.street);
    formData.append("pincode", pincode ? pincode : dataWare?.pincode);
    if (tempClosed?.fromdate || tempClosed?.tilldate) {
      formData.append("tempclosed", JSON.stringify([tempClosed]));
    } else {
      formData.append("tempclosed", JSON.stringify([]));
    }
    formData.append("description", dataWare.description);
    formData.append("googleMapLink", dataWare.googleMapLink);
    formData.append("workingdays", JSON.stringify(fields));
    formData.append("status", dataWare?.status);
    formData.append("latitude", dataWare?.latitude);
    formData.append("longitude", dataWare?.longitude);
    formData.append("warehousePrefix", dataWare?.warehousePrefix);
    formData.append("city", dataWare?.city);
    formData.append("district", dataWare?.district);
    formData.append("state", dataWare?.state);

    if (fileis) {
      formData.append("file", fileis);
    }

    fetch(process.env.REACT_APP_URL + "warehouses/addWarehouse", {
      method: "POST",
      headers: {
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setDataWare("");

          setTempClosed([{ fromdate: "", tilldate: "" }]);
          setPincode("");
          setFields([]);
          setfileis(null);
          getAllWarehouse();
          setShow(false);
        }
        if (result.status === "failed") {
          show(result.msg, "error");
        }
      })
      .catch((error) => console.log(error));
  };

  const validateFields1 = () => {
    const validationErrors = {};

    if (!dataWare?.latitude)
      validationErrors.latitude = "Latitude is required.";
    if (!dataWare?.longitude)
      validationErrors.longitude = "Longitude is required.";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const updateWareHouse = () => {
    if (!validateFields1()) {
      return;
    }
    const formData = new FormData();
    formData.append("managerId", managerId);
    formData.append("warehousename", dataWare?.warehousename);
    formData.append("street", dataWare.street);
    formData.append("pincode", dataWare?.pincode);
    if (tempClosed?.fromdate || tempClosed?.tilldate) {
      formData.append("tempclosed", JSON.stringify([tempClosed]));
    } else {
      formData.append("tempclosed", JSON.stringify([]));
    }
    formData.append("description", dataWare?.description || "");
    formData.append("workingdays", JSON.stringify(fields));
    formData.append("status", dataWare?.status);
    formData.append("latitude", dataWare?.latitude ? dataWare?.latitude : "");
    formData.append(
      "longitude",
      dataWare?.longitude ? dataWare?.longitude : ""
    );
    formData.append("warehouse_id", dataWare?.warehousePrefix);
    formData.append("city", dataWare?.city);
    formData.append("district", dataWare?.district);
    formData.append("state", dataWare?.state);
    formData.append("googleMapLink", dataWare?.googleMapLink);

    if (isimage || fileis) {
      formData.append("file", isimage ? isimage : fileis);
    }

    fetch(process.env.REACT_APP_URL + "warehouses/updateWarehouse", {
      method: "POST",
      headers: {
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          alert("Updated successfully");
          setShow(false);
          getAllWarehouse();
        }
        if (result.status === "failed") {
          show(result.msg, "error");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handelTogle = () => {
    setShow(!show1);
    setErrors(errors);
  };
  useEffect(() => {
    if (!show1) {
      setErrors({
        warehousename: "",
        warehousePrefix: "",
        pincode: "",
        street: "",
        latitude: "",
        longitude: "",
        file: "",
      });
      setDataWare({
        warehousename: "",
        warehouse_id: "",
        street: "",
        pincode: "",
        description: "",
        status: "",
        warehousePrefix: "",
        warehouseId: "",
        city: "",
        district: "",
        state: "",
        googleMapLink: "",
        latitude: "",
        longitude: "",
        fileis: "",
        isimage: "",
      });
      setPreviewImage("/assets/updatedAssets/warehouseimage.png");
      setfileis(null);
      setFields([
        { workday: "Monday", workstatus: "" },
        { workday: "Tuesday", workstatus: "" },
        { workday: "Wednesday", workstatus: "" },
        { workday: "Thursday", workstatus: "" },
        { workday: "Friday", workstatus: "" },
        { workday: "Saturday", workstatus: "" },
        { workday: "Sunday", workstatus: "" },
      ]);
      setTempClosed({
        fromdate: "",
        tilldate: "",
      });
      setImagefile(null);
    }
  }, [show1]);

  useEffect(() => {
    // const socket = io(process.env.REACT_APP_URL);

    socket.on("updatedWarehouseData", (updatedWarehouseData) => {
      setWarehouseData((prev) => {
        const newData = Array.isArray(updatedWarehouseData)
          ? updatedWarehouseData
          : [updatedWarehouseData];
        return [...newData, ...prev];
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <div
        className={styles.mainCon}
        style={{ backgroundColor: mode ? "#232529" : "#D9DCE5" }}
      >
        <div
          style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}
          className={styles.voucher_maindiv}
        >
          <div
            className={styles.dispatch_top_left}
            style={{
              position: "relative",
              backgroundColor: mode ? "#2C2E33" : "#F8F9FC",
            }}
          >
            <div className={styles.mgx_top}>
              <div className={styles.mgx_top_left}>
                <TopTabBar text={"Warehouses"} />
              </div>
            </div>

            <div style={{ fontWeight: "500" }} className={styles.warehousebtn}>
              <Button
                buttonClick={() => {
                  setShow(true);
                  setViewEdit(2);
                  setDataWare({});
                }}
                buttonIcon={<CreateTem />}
                buttonBgColor={"#4164E3"}
                buttonText={"Add Warehouse"}
                buttonTextColor={"white"}
              />
            </div>
          </div>
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <div
              className={styles.mgx_middle}
              style={
                mode
                  ? { backgroundColor: "#232529", borderColor: "#FFFFFF" }
                  : {}
              }
            >
              {warehouseStatusData.map((status1) => (
                <div
                  style={{
                    alignContent: "left",
                    cursor: "pointer",
                    display: "flex",
                  }}
                  key={status1.statusText}
                  className={`${styles.order_filter} ${
                    mode ? styles.order_filter_dark : ""
                  } ${
                    status1.statusText === status
                      ? styles.order_filter_active
                      : ""
                  }`}
                  onClick={() => {
                    setStatus(status1?.statusText);
                    setPage(1);
                  }}
                >
                  <DealerStatusCard
                    manager
                    statusText={status1.statusText}
                    statusItemCount={status1.statusItemCount}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.search}>
            <div style={{ width: "37vh" }} className="search-container">
              <SearchBar
                placeholder={"Search by Order ID"}
                value={keyword1}
                onChange={(e) => setKeyword1(e.target.value)}
              />
            </div>
            <div className={styles["dropdown-container"]}>
              <LocationSarchFilter
                text={"State"}
                list={state1?.locationList}
                search={state1?.searchValue}
                setSearch={(value) =>
                  setState1((prevState) => ({
                    ...prevState,
                    searchValue: value,
                  }))
                }
                selectFilter={state1?.selectFilter}
                setSelectFilter={(value) =>
                  setState1((prevState) => ({
                    ...prevState,
                    selectFilter: value,
                  }))
                }
              />
              <LocationSarchFilter
                text={"District"}
                list={District?.locationList}
                search={District?.searchValue}
                setSearch={(value) =>
                  setDistrict((prevState) => ({
                    ...prevState,
                    searchValue: value,
                  }))
                }
                selectFilter={District?.selectFilter}
                setSelectFilter={(value) =>
                  setDistrict((prevState) => ({
                    ...prevState,
                    selectFilter: value,
                  }))
                }
              />
              <LocationSarchFilter
                text={"City"}
                list={City?.locationList}
                search={City?.searchValue}
                setSearch={(value) =>
                  setCity((prevState) => ({ ...prevState, searchValue: value }))
                }
                selectFilter={City?.selectFilter}
                setSelectFilter={(value) =>
                  setCity((prevState) => ({
                    ...prevState,
                    selectFilter: value,
                  }))
                }
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: "60px",
              overflowY: "auto",
              position: "relative",
              height: "calc(100vh - 420px)",
            }}
          >
            <div className={styles.container}>
              {getAllWarehouseLoading ? (
                <>loading</>
              ) : warehouseData.length === 0 ? (
                <div
                  style={{ display: "flex", width: "100%", paddingTop: "30PX" }}
                >
                  <NoDataFound
                    icon={<TemplateNotFound />}
                    to={"/templates/template"}
                    textTitle={"No Data Found!"}
                  />
                </div>
              ) : (
                warehouseData.map((item, index) => (
                  <div
                    className={styles.cards}
                    style={{
                      backgroundColor: mode ? "#1B1D21" : "white",
                    }}
                    key={index}
                  >
                    <div>
                      <WarehouseInfoCard
                        dealerName={item?.warehousename}
                        warehouseimage={item?.profileImage}
                        warehouseStatus={item?.status}
                        warehouseName={getTruncatedName(
                          item?.warehousename,
                          item?.city,
                          item?.state
                        )}
                      />
                      <div style={{ display: "flex", gap: "20PX" }}>
                        <div
                          style={{ display: "flex", gap: "20PX" }}
                          onClick={() => {
                            setViewEdit(0);
                            getWareHouse(item?._id);
                          }}
                        >
                          <View12 mode={mode} />
                          <div
                            style={{}}
                            onClick={(e) => {
                              e.stopPropagation();
                              setViewEdit(1);
                              getWareHouse(item?._id);
                            }}
                          >
                            {" "}
                            <Editwarehouse mode={mode} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div>
            <ModalWrapper
              style={{
                backgroundColor: mode ? "#2C2E33 " : "#FFFFFF",
                height: "80%",
                overflowY: "scroll",
                borderRadius: "20px",
              }}
              isOpen={show1}
              onClose={setShow}
            >
              <button
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: "25px",
                  color: mode ? "white" : "black",
                  cursor: "pointer",
                }}
                onClick={() => setShow(false)}
              >
                &times;
              </button>
              <div
                style={{
                  color: mode ? "white" : "black",
                  fontSize: "18px",
                  fontWeight: "500",
                  padding: "20px 0px 0px 20px",
                }}
              >
                {viewEdit === 1
                  ? "Update Warehouse"
                  : viewEdit === 0
                  ? dataWare?.warehousename
                  : "Add Warehouse"}
              </div>

              <div>
                {" "}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: "20px",
                    gap: "20px",
                  }}
                >
                  <div className={styles.left_side}>
                    <div style={{ width: "100%" }}>
                      <InputTag
                        labelText={"Warehouse Name"}
                        star={true}
                        padding={"8px"}
                        fontSize={"12px"}
                        placeholderText={"Enter Warehouse Name"}
                        maxLength={40}
                        placeholderType={"text"}
                        value={dataWare?.warehousename}
                        onChange={
                          viewEdit !== 0
                            ? (e) =>
                                setDataWare({
                                  ...dataWare,
                                  warehousename: e.target.value,
                                })
                            : null
                        }
                        disabled={viewEdit === 0}
                      />
                      {errors.warehousename && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.warehousename}
                        </p>
                      )}
                    </div>

                    <div style={{ width: "100%" }}>
                      <InputTag
                        labelText={"  Warehouse ID"}
                        star={true}
                        padding={"8px"}
                        maxLength={5}
                        fontSize={"12px"}
                        placeholderText={"Enter Warehouse ID"}
                        placeholderType={"text"}
                        value={dataWare?.warehouseId}
                        onChange={
                          viewEdit !== 0
                            ? (e) =>
                                setDataWare({
                                  ...dataWare,
                                  warehousePrefix: e.target.value,
                                })
                            : null
                        }
                        disabled={viewEdit === 0}
                      />
                      {errors.warehousePrefix && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.warehousePrefix}
                        </p>
                      )}
                    </div>

                    <div style={{ width: "100%" }}>
                      <InputTag
                        labelText={"PIN Code"}
                        star={true}
                        padding={"8px"}
                        maxLength={6}
                        fontSize={"12px"}
                        placeholderText={"Enter PIN Code"}
                        placeholderType={"Number"}
                        value={dataWare?.pincode}
                        onChange={
                          viewEdit !== 0
                            ? (e) => {
                                setDataWare({
                                  ...dataWare,
                                  pincode: e.target.value,
                                });
                                setPincode(e.target.value);

                                if (!e.target.value.trim()) {
                                  setErrors((prev) => ({
                                    ...prev,
                                    pincode: "Pincode is required.",
                                  }));
                                } else if (!validatePincode(e.target.value)) {
                                  setErrors((prev) => ({
                                    ...prev,
                                    pincode:
                                      "Invalid Pincode. Please enter a valid 6-digit PIN Code.",
                                  }));
                                } else {
                                  setErrors((prev) => {
                                    const { pincode, ...rest } = prev;
                                    return rest;
                                  });
                                }
                              }
                            : null
                        }
                        disabled={viewEdit === 0}
                      />
                      {errors.pincode && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.pincode}
                        </p>
                      )}
                    </div>

                    <div style={{ width: "100%" }}>
                      <InputTag
                        labelText={"Street Address"}
                        star={true}
                        padding={"8px"}
                        maxLength={50}
                        fontSize={"12px"}
                        placeholderText={"Enter Street Address"}
                        placeholderType={"text"}
                        value={dataWare?.street}
                        onChange={
                          viewEdit !== 0
                            ? (e) =>
                                setDataWare({
                                  ...dataWare,
                                  street: e.target.value,
                                })
                            : null
                        }
                        disabled={viewEdit === 0}
                      />
                    </div>

                    <div style={{ display: "flex", gap: "10px" }}>
                      <div style={{ width: "100%" }}>
                        <InputTag
                          labelText={"State"}
                          star={true}
                          padding={"8px"}
                          fontSize={"12px"}
                          maxLength={20}
                          placeholderText={"Enter State"}
                          placeholderType={"text"}
                          value={dataWare?.state}
                          onChange={
                            viewEdit !== 0
                              ? (e) =>
                                  setDataWare({
                                    ...dataWare,
                                    state: e.target.value,
                                  })
                              : null
                          }
                          disabled={viewEdit === 0}
                        />
                      </div>

                      <div style={{ width: "100%" }}>
                        <InputTag
                          labelText={"District"}
                          star={true}
                          padding={"8px"}
                          fontSize={"12px"}
                          maxLength={20}
                          placeholderText={"Enter District"}
                          placeholderType={"text"}
                          value={dataWare?.district}
                          onChange={
                            viewEdit !== 0
                              ? (e) =>
                                  setDataWare({
                                    ...dataWare,
                                    district: e.target.value,
                                  })
                              : null
                          }
                          disabled={viewEdit === 0}
                        />
                      </div>

                      <div style={{ width: "100%" }}>
                        <InputTag
                          labelText={"City"}
                          star={true}
                          padding={"8px"}
                          fontSize={"12px"}
                          maxLength={20}
                          placeholderText={"Enter City"}
                          placeholderType={"text"}
                          value={dataWare?.city}
                          onChange={
                            viewEdit !== 0
                              ? (e) =>
                                  setDataWare({
                                    ...dataWare,
                                    city: e.target.value,
                                  })
                              : null
                          }
                          disabled={viewEdit === 0}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div>
                          <InputTag
                            labelText={"Latitude"}
                            star={true}
                            padding={"8px"}
                            fontSize={"12px"}
                            maxLength={50}
                            placeholderText={"Enter Latitude"}
                            placeholderType={"text"}
                            value={dataWare?.latitude}
                            onChange={
                              viewEdit !== 0
                                ? (e) =>
                                    setDataWare({
                                      ...dataWare,
                                      latitude: e.target.value,
                                    })
                                : null
                            }
                            disabled={viewEdit === 0}
                          />
                          {errors.latitude && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.latitude}
                            </p>
                          )}
                        </div>
                      </div>

                      <div style={{ width: "100%" }}>
                        <div>
                          <InputTag
                            labelText={"Longitude"}
                            star={true}
                            padding={"8px"}
                            fontSize={"12px"}
                            maxLength={50}
                            placeholderText={"Enter Longitude"}
                            placeholderType={"text"}
                            value={dataWare?.longitude}
                            onChange={
                              viewEdit !== 0
                                ? (e) =>
                                    setDataWare({
                                      ...dataWare,
                                      longitude: e.target.value,
                                     })
                                : null
                            }
                            disabled={viewEdit === 0}
                          />
                          {errors.longitude && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {errors.longitude}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div style={{ width: "100%" }}>
                      <InputTag
                        labelText={"Map link"}
                        padding={"8px"}
                        fontSize={"12px"}
                        maxLength={200}
                        placeholderText={"Enter Map Link"}
                        placeholderType={"text"}
                        value={
                          dataWare?.googleMapLink === "undefined"
                            ? ""
                            : dataWare?.googleMapLink
                        }
                        onChange={
                          viewEdit !== 0
                            ? (e) =>
                                setDataWare({
                                  ...dataWare,
                                  googleMapLink: e.target.value,
                                })
                            : null
                        }
                        disabled={viewEdit === 0}
                      />
                    </div>
                    <span
                      style={{
                        color: mode ? "white" : "black",
                        fontSize: "14px",
                      }}
                    >
                      Temporary Closed
                    </span>
                    <div style={{ display: "flex" }}>
                      <InputTypedate
                        text={"Form"}
                        value={tempClosed?.fromdate}
                        onChange={(e) =>
                          setTempClosed({
                            ...tempClosed,
                            fromdate: e.target.value,
                          })
                        }
                        min={todayDateString}
                        disable={viewEdit === 0}
                      />

                      <InputTypedate
                        text={"To"}
                        value={tempClosed?.tilldate}
                        onChange={(e) =>
                          setTempClosed({
                            ...tempClosed,
                            tilldate: e.target.value,
                          })
                        }
                        min={tempClosed?.fromdate || todayDateString}
                        disable={viewEdit === 0}
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <InputTag
                        labelText={"Description"}
                        padding={"8px"}
                        maxLength={50}
                        fontSize={"12px"}
                        placeholderText={"Enter Description"}
                        placeholderType={"text"}
                        value={
                          dataWare?.description === "undefined"
                            ? ""
                            : dataWare?.description
                        }
                        disabled={viewEdit === 0}
                        onChange={
                          viewEdit !== 0
                            ? (e) =>
                                setDataWare({
                                  ...dataWare,
                                  description: e.target.value,
                                })
                            : null
                        }
                      />
                      {errors.description && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {errors.description}
                        </p>
                      )}
                    </div>
                  </div>
{/* 
                  <div className={styles.seandCon}>
                    <div
                      className={styles.nm_photo}
                      onClick={handlePhotoUploadClick}
                    >
                      <img
                        src={
                          imagefile
                            ? `${process.env.REACT_APP_S3URL}${imagefile}`
                            : previewImage || "default-placeholder.png"
                        }
                        alt="Manager"
                      />
                      <div className={styles.nm_camera_icon}>
                        <ManagerCameraIcon />
                      </div>

                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{
                          display: "none",
                          cursor: viewEdit === 0 ? "not-allowed" : "pointer",
                        }}
                        onChange={handlePhotoChange}
                        accept="image/*"
                        disabled={viewEdit === 0}
                      />
                    </div>

                    {errors.file && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {errors.file}
                      </p>
                    )}

                    <div
                      className={styles.right_side}
                      style={{
                        border: mode ? "none" : "1px solid #ccc",
                        marginTop: "40px",
                        color: mode ? "white" : "black",
                      }}
                    >
                      {errors.workingdays && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            margintop: "10px",
                            textAlign: "center",
                          }}
                        >
                          {errors.workingdays}
                        </p>
                      )}
                      <div>Work Days:</div>

                      {fields &&
                        fields.map((field, idx) => (
                          <div key={idx} className={styles.redioBtnConintner3}>
                            <div
                              style={{ color: mode ? "white" : "black" }}
                              className="status-label"
                            >
                              {field.workday}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "flex-end",
                              }}
                            >
                              <InputRedio
                                lableText="Active"
                                value="active"
                                required={true}
                                name={idx}
                                onChange={
                                  viewEdit !== 0
                                    ? (e) => handleChange(idx, e)
                                    : null
                                }
                                checked={field?.workstatus === "active"}
                                disabled={viewEdit === 0}
                              />

                              <InputRedio
                                lableText="Disable"
                                value="disable"
                                required={true}
                                name={idx}
                                onChange={
                                  viewEdit !== 0
                                    ? (e) => handleChange(idx, e)
                                    : null
                                }
                                checked={field?.workstatus === "disable"}
                                disabled={viewEdit === 0}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div> */}
                     <div className={styles.seandCon}>
                    <div
                      className={styles.nm_photo}
                      onClick={handlePhotoUploadClick}
                    >
                      {(imagefile || fileis)?
                      <img
                        src={
                          imagefile
                            ? `${process.env.REACT_APP_S3URL}${imagefile?imagefile:fileis}`
                            : previewImage || "default-placeholder.png"
                        }
                        alt="Manager" 
                      />:
                      dataWare?.warehousename === undefined || ""?  <img
                      src={
                        // imagefile
                        //   ? `${process.env.REACT_APP_S3URL}${imagefile?imagefile:fileis}`
                           previewImage || "default-placeholder.png"
                      }
                      alt="Manager" 
                    />:
                       <div style={{fontSize:"45px",color: mode?"white":"black",fontWeight:"500",textAlign:"center",margintop:"20px"}}  className={styles.Frist_text}>
              {dataWare?.warehousename?.charAt(0).toUpperCase()}
            </div>}
                      <div className={styles.nm_camera_icon}>
                        <ManagerCameraIcon />
                      </div>

                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{
                          display: "none",
                          cursor: viewEdit === 0 ? "not-allowed" : "pointer",
                        }}
                        onChange={handlePhotoChange}
                        accept="image/*"
                        disabled={viewEdit === 0}
                      />
                    </div>

                    {errors.file && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {errors.file}
                      </p>
                    )}

                    <div
                      className={styles.right_side}
                      style={{
                        border: mode ? "none" : "1px solid #ccc",
                        marginTop: "40px",
                        color: mode ? "white" : "black",
                      }}
                    >
                      {errors.workingdays && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            margintop: "10px",
                            textAlign: "center",
                          }}
                        >
                          {errors.workingdays}
                        </p>
                      )}
                      <div>Work Days:</div>

                      {fields &&
                        fields.map((field, idx) => (
                          <div key={idx} className={styles.redioBtnConintner3}>
                            <div
                              style={{ color: mode ? "white" : "black" }}
                              className="status-label"
                            >
                              {field.workday}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "flex-end",
                              }}
                            >
                              <InputRedio
                                lableText="Active"
                                value="active"
                                required={true}
                                name={idx}
                                onChange={
                                  viewEdit !== 0
                                    ? (e) => handleChange(idx, e)
                                    : null
                                }
                                checked={field?.workstatus === "active"}
                                disabled={viewEdit === 0}
                              />

                              <InputRedio
                                lableText="Disable"
                                value="disable"
                                required={true}
                                name={idx}
                                onChange={
                                  viewEdit !== 0
                                    ? (e) => handleChange(idx, e)
                                    : null
                                }
                                checked={field?.workstatus === "disable"}
                                disabled={viewEdit === 0}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 20px 20px 20px ",
                }}
              >
                <div className={styles.redioBtnConintner3}>
                  {errors.status && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        paddingTop: "15px",
                      }}
                    >
                      {errors.status}
                    </p>
                  )}

                  <div
                    style={{ color: "white", marginTop: "5px" }}
                    className="status-label"
                  >
                    Status
                  </div>

                  <InputRedio
                    lableText="Active"
                    value="active"
                    name="formHorizontalRadios"
                    onChange={
                      viewEdit !== 0
                        ? (e) =>
                            setDataWare({ ...dataWare, status: e.target.value })
                        : null
                    }
                    checked={dataWare?.status === "active"}
                    disabled={viewEdit === 0}
                  />

                  <InputRedio
                    lableText="Disable"
                    value="disable"
                    name="formHorizontalRadios"
                    onChange={
                      viewEdit !== 0
                        ? (e) =>
                            setDataWare({ ...dataWare, status: e.target.value })
                        : null
                    }
                    checked={dataWare?.status === "disable"}
                    disabled={viewEdit === 0}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: viewEdit === 0 ? "flex-start" : "flex-end",
                  }}
                  className={`button-container ${
                    viewEdit === 1 ? "edit-mode" : ""
                  }`}
                >
                  <div style={{ width: "100px" }}>
                    <Button
                      buttonText="Cancel"
                      buttonBgColor="black"
                      buttonTextColor="white"
                      buttonClick={() => {
                        setShow(false);
                        setDataWare({});
                      }}
                    />
                  </div>
                  {viewEdit !== 0 && (
                    <div style={{ width: "100px" }}>
                      <Button
                        buttonBgColor="#4164E3"
                        buttonTextColor="white"
                        buttonText={viewEdit === 1 ? "Update" : "Create"}
                        buttonClick={
                          viewEdit === 1
                            ? () => updateWareHouse(dataWare.warehousename)
                            : postWarehouse
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </ModalWrapper>
          </div>
        </div>
        <div
          className={styles.paginationdiv}
          style={{ backgroundColor: mode ? "#232529" : "#FFFFFF" }}
        >
          <div className={styles.page1}>
            <GoToPage
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={pages}
              key={"1"}
            />
          </div>

          <div className={styles.page2}>
            <PaginationComponent
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={pages}
              key={"2"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Warehouse;
