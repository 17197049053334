import TopTabBar from "../Tanolet/TopTabBar";
import styles from "./Refunds.module.css";
import VerticalDropDown from "../FormComponents/VerticalDropDown";
import DealerStatusCard from "../Dealers/DealerStatusCard";
import { useAuth } from "../../context/Auth";
import { useState } from "react";
import Skeleton from "../Skeleton/Skeleton";
import NoDataFound from "../NoDataFound/NoDataFound";
import { TemplateNotFound } from "../../icons/Icon2";
import SearchBar from "../FormComponents/SearchBar";
import InputTypedate from "../FormComponents/InputTypedate";
import InputRadio from "../FormComponents/InputRedio";
import GoToPage from "../Pagnation/GoToPage";
import Pagination from "../Pagnation/Pagenation";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import RefundModal from "./RefundModal";

const Refunds = () => {
  const { mode } = useAuth();
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refundData, setRefundData] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState({
    label: "Merchant ID",
    value: "merchantId",
  });

  const getStatusColor = (status) => {
    switch (status) {
      case "Success":
        return "#31974D";

      case "Failed":
        return "#F93E3E";

      case "Rejected":
        return "#F93E3E";

      case "Pending":
        return "#FF9B04";

      default:
        return "";
    }
  };

  const seachBarPlaceholder = selectedRadio.label;

  const loading = false;
  return (
    <>
      <ModalWrapper
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        noBtn
      >
        <RefundModal
          mode={mode}
          close={() => setIsModalOpen(false)}
          data={refundData}
          getStatusColor={getStatusColor}
        />
      </ModalWrapper>

      <div
        className={styles.refunds}
        style={
          mode ? { backgroundColor: "#2C2E33", borderColor: "#2C2E33" } : {}
        }
      >
        <div className={styles.refunds_top}>
          <TopTabBar text={"Refunds"} />
          <div className={styles.d_f}>
            <InputTypedate text={"From"} onChange={(e) => {}} />
            <InputTypedate text={"To"} onChange={(e) => {}} />
            <div className={styles.bank_dd}>
              <VerticalDropDown
                height={"37px"}
                fontSize={"12px"}
                dropDownText={"Select Bank"}
              />
            </div>
          </div>
        </div>

        <div
          className={styles.refunds_filters}
          style={
            mode ? { borderColor: "#232529", backgroundColor: "#232529" } : {}
          }
        >
          <>
            {filterData?.map((s, index) => (
              <div
                key={s.status + index}
                className={`${styles.filter} ${
                  mode ? styles.filter_dark : ""
                } ${
                  s.status === selectedFilter
                    ? mode
                      ? styles.filter_active_dark
                      : styles.filter_active
                    : ""
                }`}
                onClick={() => {
                  setSelectedFilter(s.status);
                }}
              >
                <DealerStatusCard
                  statusText={s.status}
                  statusItemCount={s.count}
                />
              </div>
            ))}
          </>
        </div>

        <div className={styles.d_f}>
          <div style={{ width: "180px" }}>
            <SearchBar
              padding={"6px"}
              fontSize={"12px"}
              fontWeight={"400"}
              borderRadius={"5px"}
              placeholder={seachBarPlaceholder}
            />
          </div>
          <div style={{ width: "180px" }}>
            <VerticalDropDown
              height={"30px"}
              fontSize={"12px"}
              dropDownText={"All"}
            />
          </div>
          <div className={styles.refunds_radio_btns}>
            <p style={mode ? { color: "#FFFFFF" } : {}}>Sort By</p>
            <InputRadio
              lableText="Merchant ID"
              name="sortOption"
              id="merchantId"
              value="merchantId"
              checked={selectedRadio.value === "merchantId"}
              onChange={() =>
                setSelectedRadio({
                  label: "Merchant ID",
                  value: "merchantId",
                })
              }
            />
            <InputRadio
              lableText="Original Transaction ID"
              name="sortOption"
              id="originalTransactionId"
              value="originalTransactionId"
              checked={selectedRadio.value === "originalTransactionId"}
              onChange={() =>
                setSelectedRadio({
                  label: "Original Transaction ID",
                  value: "originalTransactionId",
                })
              }
            />
            <InputRadio
              lableText="Refund Bank RRN"
              name="sortOption"
              id="refundBankRRN"
              value="refundBankRRN"
              checked={selectedRadio.value === "refundBankRRN"}
              onChange={() =>
                setSelectedRadio({
                  label: "Refund Bank RRN",
                  value: "refundBankRRN",
                })
              }
            />
            <InputRadio
              lableText="Original Bank RRN"
              name="sortOption"
              id="originalBankRRN"
              value="originalBankRRN"
              checked={selectedRadio.value === "originalBankRRN"}
              onChange={() =>
                setSelectedRadio({
                  label: "Original Bank RRN",
                  value: "originalBankRRN",
                })
              }
            />
          </div>
        </div>

        <ul className={styles.data_list}>
          <li
            className={`${styles.data_list_row_header} ${
              mode ? styles.data_list_row_header_dark : ""
            }`}
          >
            {tableHeaderData.map((h) => (
              <div className={styles.entry} key={h}>
                {h}
              </div>
            ))}
          </li>

          <div className={styles.data_list_container}>
            {loading ? (
              <div>
                <Skeleton
                  lines={15}
                  thickness={5}
                  additionalStyles={{ marginBottom: "0px", marginTop: "0px" }}
                />
              </div>
            ) : (
              <>
                {dummyData.length === 0 ? (
                  <div className={styles.no_data}>
                    <NoDataFound
                      icon={<TemplateNotFound />}
                      textTitle={`No Data Found`}
                    />
                  </div>
                ) : (
                  <>
                    {dummyData.map((d, index) => (
                      <>
                        <li
                          key={d.originalTransactionId + index}
                          className={`${styles.data_list_row} ${
                            mode ? styles.data_list_row_dark : ""
                          }`}
                          onClick={() => {
                            setRefundData(d);
                            setIsModalOpen(true);
                          }}
                        >
                          <div className={styles.entry}>
                            {d.originalTransactionId}
                          </div>
                          <div className={styles.entry}>
                            {d.refundTransactionId}
                          </div>
                          <div className={styles.entry}>
                            {d.originalBankRrn}
                          </div>
                          <div className={styles.entry}>{d.refundBankRrn}</div>
                          <div className={styles.entry}>{d.subMerchantId}</div>
                          <div className={styles.entry}>{d.customerVpaUpi}</div>
                          <div className={styles.entry}>{d.dateTime}</div>
                          <div className={styles.entry}>
                            {d.refundAmountInr}
                          </div>
                          <div className={styles.entry}>{d.rejectReason}</div>
                          <div
                            className={styles.entry}
                            style={{ color: getStatusColor(d.status) }}
                          >
                            {d.status}
                          </div>
                        </li>
                      </>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </ul>

        <div
          className={styles.pagination}
          style={mode ? { backgroundColor: "#232529" } : {}}
        >
          <div style={{ width: "50%" }}>
            <div className={styles.go_to_page}>
              <GoToPage
                // currentPage={page}
                // setCurrentPage={setPage}
                // totalPages={pages}
                key={"1"}
              />
            </div>
          </div>
          <div className={styles.pages}>
            <Pagination
              // currentPage={page}
              // setCurrentPage={setPage}
              // totalPages={pages}
              key={"1"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Refunds;

const filterData = [
  {
    status: "All",
    count: 0,
  },
  {
    status: "Success",
    count: 0,
  },
  {
    status: "Pending",
    count: 0,
  },
  {
    status: "Failed",
    count: 0,
  },
];
const tableHeaderData = [
  "Original Transaction ID",
  "Refund Transaction ID",
  "Original Bank RRN",
  "Refund Bank RRN",
  "Sub Merchant ID",
  "Customer VPA",
  "Date & Time",
  "Refund Amount (INR)",
  "Reject Reason",
  "Status",
];
const dummyData = [
  {
    originalTransactionId: "OT123456789",
    refundTransactionId: "RT987654321",
    originalBankRrn: "OBR123456789",
    refundBankRrn: "RBR987654321",
    subMerchantId: "SM12345",
    customerVpaUpi: "customer@upi",
    dateTime: "2024-02-20 10:30:00",
    refundAmountInr: 500.75,
    rejectReason: "None",
    status: "Success",
  },
  {
    originalTransactionId: "OT123456790",
    refundTransactionId: "RT987654322",
    originalBankRrn: "OBR123456790",
    refundBankRrn: "RBR987654322",
    subMerchantId: "SM12346",
    customerVpaUpi: "buyer@upi",
    dateTime: "2024-02-20 11:00:00",
    refundAmountInr: 1200.0,
    rejectReason: "Insufficient Funds",
    status: "Failed",
  },
  {
    originalTransactionId: "OT123456791",
    refundTransactionId: "RT987654323",
    originalBankRrn: "OBR123456791",
    refundBankRrn: "RBR987654323",
    subMerchantId: "SM12347",
    customerVpaUpi: "shopper@upi",
    dateTime: "2024-02-20 12:15:00",
    refundAmountInr: 299.99,
    rejectReason: "None",
    status: "Success",
  },
  {
    originalTransactionId: "OT123456792",
    refundTransactionId: "RT987654324",
    originalBankRrn: "OBR123456792",
    refundBankRrn: "RBR987654324",
    subMerchantId: "SM12348",
    customerVpaUpi: "user@upi",
    dateTime: "2024-02-20 13:45:00",
    refundAmountInr: 750.5,
    rejectReason: "Technical Issue",
    status: "Pending",
  },
  {
    originalTransactionId: "OT123456793",
    refundTransactionId: "RT987654325",
    originalBankRrn: "OBR123456793",
    refundBankRrn: "RBR987654325",
    subMerchantId: "SM12349",
    customerVpaUpi: "client@upi",
    dateTime: "2024-02-20 14:20:00",
    refundAmountInr: 999.99,
    rejectReason: "Fraud Suspicion",
    status: "Rejected",
  },
  {
    originalTransactionId: "OT123456794",
    refundTransactionId: "RT987654326",
    originalBankRrn: "OBR123456794",
    refundBankRrn: "RBR987654326",
    subMerchantId: "SM12350",
    customerVpaUpi: "customer@upi",
    dateTime: "2024-02-20 15:30:00",
    refundAmountInr: 459.75,
    rejectReason: "None",
    status: "Success",
  },
  {
    originalTransactionId: "OT123456795",
    refundTransactionId: "RT987654327",
    originalBankRrn: "OBR123456795",
    refundBankRrn: "RBR987654327",
    subMerchantId: "SM12351",
    customerVpaUpi: "merchant@upi",
    dateTime: "2024-02-20 16:10:00",
    refundAmountInr: 1450.25,
    rejectReason: "Timeout",
    status: "Failed",
  },
  {
    originalTransactionId: "OT123456796",
    refundTransactionId: "RT987654328",
    originalBankRrn: "OBR123456796",
    refundBankRrn: "RBR987654328",
    subMerchantId: "SM12352",
    customerVpaUpi: "shopkeeper@upi",
    dateTime: "2024-02-20 17:40:00",
    refundAmountInr: 330.5,
    rejectReason: "None",
    status: "Success",
  },
  {
    originalTransactionId: "OT123456797",
    refundTransactionId: "RT987654329",
    originalBankRrn: "OBR123456797",
    refundBankRrn: "RBR987654329",
    subMerchantId: "SM12353",
    customerVpaUpi: "consumer@upi",
    dateTime: "2024-02-20 18:15:00",
    refundAmountInr: 599.99,
    rejectReason: "Network Issue",
    status: "Pending",
  },
  {
    originalTransactionId: "OT123456798",
    refundTransactionId: "RT987654330",
    originalBankRrn: "OBR123456798",
    refundBankRrn: "RBR987654330",
    subMerchantId: "SM12354",
    customerVpaUpi: "client@upi",
    dateTime: "2024-02-20 19:05:00",
    refundAmountInr: 725.0,
    rejectReason: "None",
    status: "Success",
  },
  {
    originalTransactionId: "OT123456799",
    refundTransactionId: "RT987654331",
    originalBankRrn: "OBR123456799",
    refundBankRrn: "RBR987654331",
    subMerchantId: "SM12355",
    customerVpaUpi: "vendor@upi",
    dateTime: "2024-02-20 20:30:00",
    refundAmountInr: 1800.0,
    rejectReason: "Transaction Limit Exceeded",
    status: "Rejected",
  },
  {
    originalTransactionId: "OT123456800",
    refundTransactionId: "RT987654332",
    originalBankRrn: "OBR123456800",
    refundBankRrn: "RBR987654332",
    subMerchantId: "SM12356",
    customerVpaUpi: "shopper@upi",
    dateTime: "2024-02-20 21:10:00",
    refundAmountInr: 399.99,
    rejectReason: "None",
    status: "Success",
  },
  {
    originalTransactionId: "OT123456801",
    refundTransactionId: "RT987654333",
    originalBankRrn: "OBR123456801",
    refundBankRrn: "RBR987654333",
    subMerchantId: "SM12357",
    customerVpaUpi: "customer@upi",
    dateTime: "2024-02-20 22:05:00",
    refundAmountInr: 1299.99,
    rejectReason: "None",
    status: "Success",
  },
  {
    originalTransactionId: "OT123456802",
    refundTransactionId: "RT987654334",
    originalBankRrn: "OBR123456802",
    refundBankRrn: "RBR987654334",
    subMerchantId: "SM12358",
    customerVpaUpi: "merchant@upi",
    dateTime: "2024-02-20 23:15:00",
    refundAmountInr: 2050.75,
    rejectReason: "Technical Issue",
    status: "Pending",
  },
];
