import styles from "./Transactions.module.css";
import TextElement from "../FormComponents/TextElement";
import { Close, RefundIcon } from "../../icons/Icon";
import { CloseIcon } from "../../icons/Icon2";
import Button from "../Dealers/Button";
import DetailRow from "./DetailRow";

const TransactionModal = ({ mode, close, data, getStatusColor }) => {
  return (
    <div
      className={styles.transaction_modal}
      style={mode ? { backgroundColor: "#1B1D21" } : {}}
    >
      <div>
        <TextElement
          fontSize="16px"
          labelColor={mode ? "#FFFFFF" : "#000000"}
          label={`Merchant Transaction ID ${data.merchantTransactionId}`}
        />
        <div onClick={close} style={{ cursor: "pointer" }}>
          <CloseIcon mode={mode} />
        </div>
      </div>

      <DetailRow
        mode={mode}
        labelLeft="Bank RRN"
        valueLeft={data.bankRrnUtr}
        labelRight="Status"
        valueRight={
          <span style={{ color: getStatusColor(data.status) }}>
            {data.status}
          </span>
        }
      />

      <div
        className={`${styles.border} ${mode ? styles.border_dark : ""}`}
      ></div>

      <DetailRow
        mode={mode}
        labelLeft="Total Amount (₹)"
        valueLeft={data.amount}
        labelRight="Refunded Amount (₹)"
        valueRight={data.amount}
      />
      <div
        className={`${styles.border} ${mode ? styles.border_dark : ""}`}
      ></div>

      <DetailRow
        mode={mode}
        labelLeft="Initiation Date"
        valueLeft={data.transactionInitiationDate}
        labelRight="Completion Date"
        valueRight={data.transactionCompletionDate}
      />
      <div
        className={`${styles.border} ${mode ? styles.border_dark : ""}`}
      ></div>

      <DetailRow
        mode={mode}
        labelLeft="Error Code"
        valueLeft="No Error"
        labelRight="Error Message"
        valueRight="No Error"
      />
      <div
        className={`${styles.border} ${mode ? styles.border_dark : ""}`}
      ></div>

      <div style={{ gap: "15px" }}>
        <Button
          buttonBgColor="#000000"
          buttonTextColor="#FFFFFF"
          buttonText="Close"
          buttonIcon={<Close />}
          buttonClick={close}
        />
        <Button
          buttonBgColor="#4164E3"
          buttonTextColor="#FFFFFF"
          buttonText="Refund"
          buttonIcon={<RefundIcon />}
        />
      </div>
    </div>
  );
};

export default TransactionModal;
