import React, { useEffect, useRef, useState } from "react";
import styles from "./newdispatch.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import { useReactToPrint } from "react-to-print";
import { GreenOutButton, RedOutButton } from "../../components/Buttons";
import {
  DeleteIcon,
  DispatchReloadIcon,
  ShareIcon,
} from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import ShareLinkModal from "../../components/updatedComponents/OrderManager/ShareLinkModal";
import { Back, Statusicon } from "../../components/icons/Icon4";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import fetchData from "../../APIsControll/apiControll";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";

const Newviewdispatchdetails = () => {
  const navigate = useNavigate();
  const { switchDisplay, managerId, mode } = useAuth();
  const handelPreview = () => {
    // if (!disabled && image) {
      const imageUrl =
        images instanceof File || images instanceof Blob
          ? URL.createObjectURL(images)
          : process.env.REACT_APP_S3URL + images;

      window.open(
        imageUrl,
        "ImageViewer",
        "width=800,height=600,scrollbars=yes,resizable=yes"
      );
    }
  // };
  
  const params = useParams();
  const orderId = params.orderId.includes("saleorder")
    ? params.orderId.split("&")[0]
    : params.orderId;
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState("");

  const [showDeliveryModal, setShowDeliveryModal] = useState(false);

  const [packer, setPacker] = useState(managerId);
  const [couriers, setCouriers] = useState([]);
  const [partners, setPartners] = useState("");
  const [data, setData] = useState({});
  const [isReloaded, setIsReloaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ShareLinkModalSate, setShareLinkModalSate] = useState(false);
  const [isImage, setisImage] = React.useState("");

  const [delivery, setDelivery] = useState({
    courierName: "",
    partnerName: "",
  });
  const [printLabel, setPrintLabel] = useState({
    qtyPacks: "",
    delPerName: "",
    delPerMobile: "",
    delTrackingNo: "",
    delTrackingUrl: "",
    courierSlip: "",
  });
  const [courierSlip, setCourierSlip] = useState();
  const [images, setImages] = useState([]);
  const [packs, setPacks] = useState();
  const [point, setPoint] = useState();

  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        orderId: orderId,
        courierName: delivery.courierName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData(result.data);
            setPacker(result.data.order.pmId);
            setCouriers(result.data.couriers);
            setPartners(result.data.courierPartners);
            setPrintLabel((prevState) => ({
              ...prevState,
              qtyPacks: result.data.order.qtyPacks,
              delPerName: result.data.order.delPerName,
              delPerMobile: result.data.order.delPerMobile,
              delTrackingNo: result.data.order.delTrackingNo,
              delTrackingUrl: result.data.order.delTrackingUrl,
              courierSlip: result.data.order.courierSlip,
            }));
            if (!delivery.courierName) {
              setDelivery((prevState) => ({
                ...prevState,
                courierName: result.data.order.courierName,
                partnerName: result.data.order.partnerName,
                shippingType: result.data.order.shippingType,
              }));
            }
          }
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [orderId, managerId, delivery.courierName, isReloaded]);

  useEffect(() => {
    // console.log("jjjj");

    const getLink = async () => {
      // console.log("ffff");
      try {
        const palyod = { orderId: orderId };
        const res = await fetchData("receipt/generatePaymentLink", palyod);
        setLink(res.shortUrl);
      } catch (error) {
        console.log(error);
      }
    };
    getLink();
  }, []);

  useEffect(() => {
    if (packs) {
      setPoint(
        Array.from(
          Array(
            packs === "0"
              ? 1
              : packs === "1"
              ? 1
              : packs === "2"
              ? 2
              : parseInt(packs - 1)
          ).keys()
        )
      );
    }
  }, [packs]);

  const labelRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => labelRef.current,
  });

  const handleDispatched = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("shippingType", delivery.shippingType);
    formdata.append("courierName", delivery.courierName);
    formdata.append("partnerName", delivery.partnerName);

    formdata.append("delPerName", printLabel.delPerName);
    formdata.append("delPerMobile", printLabel.delPerMobile);
    formdata.append("delTrackingNo", printLabel.delTrackingNo);
    formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
    formdata.append("image", courierSlip[0]);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_URL +
        "dispatch/deliveryDetails/" +
        managerId +
        "/" +
        orderId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowDeliveryModal(false);
          setIsReloaded(Math.random());
        }
        alert(result.msg);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (isImage) {
      setImages();
    }
  }, [isImage]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleDispatched();
    }
  };

  const filterItem =
    data.order &&
    data.order.items &&
    data.order.items.length > 0 &&
    data.order.items.filter((item) => item.qty > 0);

  return (
    <React.Fragment>
      <ModalWrapper
        isOpen={ShareLinkModalSate}
        onClose={() => setShareLinkModalSate(!ShareLinkModalSate)}
      >
        <ShareLinkModal
          managerId={2}
          isModalOpen={true}
          mode={mode}
          generatedLink={`https://dealer.wallicon.in/${link}`}
          mobileNo={data?.order?.mobile}
          email={data?.order?.email}
          handleCloseModal={() => setShareLinkModalSate(!ShareLinkModalSate)}
        />
      </ModalWrapper>
      {/*  */}
      <div
        className={styles.text_center}
        style={{
          backgroundColor: mode ? "#2C2E33" : "#fff",
          color: mode ? "#fff" : "#000",
        }}
      ></div>

      {loading ? (
        <div style={{ width: "100%", height: "100%", padding: "10px" }}>
          <div
            style={{
              padding: "10px",
              backgroundColor: mode ? "" : "#fff",
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          > 
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "200px" }}>
                  <Skeleton lines={1} thickness={4} />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "200px", float: "right" }}>
                  <Skeleton lines={1} thickness={4} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "80%" }}>
                  <Skeleton lines={8} thickness={2} />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "80%", float: "right" }}>
                  <Skeleton lines={8} thickness={2} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "100%" }}>
                  <Skeleton lines={8} thickness={3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.mainCon}>
          <div
            style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}
            className={styles.mainContainer}
          >
            {/* 
            <div
              className={styles.dispatch_top_left}
              style={{
                position: "relative",
                top: "-5px",
                paddingTop: "0",
                width: "100%",
                display: "flex",

                justifyContent: "space-between",
                backgroundColor: mode ? "#2C2E33" : "#F8F9FC",

                padding: "10px",
              }}
            >


              <div className={styles.mgx_top}>
                <div className={styles.mgx_top_left}>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "3PX",
                      marginRight: "10PX"
                    }}
                  >
                    <TopTabBar text={"Packing Voucher"} />

         
                  </div>
                </div>
              </div>
              <div
                className={`${data.order.dispatchStatus === "New Order"
                  ? styles.status_blue
                  : data.order.dispatchStatus === "Packed" ||
                    data.order.dispatchStatus === "Dispatched"
                    ? styles.statuscolor3
                    : data.order.dispatchStatus === "Not Packed"
                      ? styles.statuscolor1
                      : data.order.dispatchStatus === "In Progress" ||
                        data.order.dispatchStatus === "Pending"
                        ? styles.status
                        : ""
                  }`}
                style={{

                  padding: "5px 8px",
                  backgroundColor: mode ? "#1B1D21" : "white",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  width: "18vh",
                  justifyContent: "center",
                  height: "5vh",
                }}
              >
                <div
                  style={{
                  }}
                >
                  {" "}
                  <Statusicon status={data?.order?.dispatchStatus} />
                </div>{" "}
                {data.order.dispatchStatus}
              </div>
            </div> */}
            <div style={{ padding: "10px" }} className={styles.order_top}>
              <div className={styles.order_top_left}>
                <TopTabBar text={"Packing Voucher"} />
              </div>
              <div
                style={{ display: "flex", paddingRight: "10px", gap: "5px" }}
              >
                <TextElement
                  label={"Voucher No. & Voucher Date: "}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={
                    (orderId ? orderId : "") +
                    (orderId && data.order.orderDate ? " / " : "") +
                    (data.order.orderDate ? data.order.orderDate : "")
                  }
                  // value={orderId + " / " + data.orderDate }
                  // value={orderId ==="undefined"?"":orderId + " / " + data.orderDate ==="undefined"?"":data.orderDate}

                  valueColor={mode ? "#fff" : "#646B88"}
                  fontSize={"12px"}
                  type={"order"}
                />

                <TextElement
                  label={"Created By  "}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={""}
                  // value={(data.order.packermanager ? data.order.packermanager : "")
                  // + (orderId && data.order.orderDate ? " / " : "") + (data.order.orderDate ? data.order.orderDate: "")
                  // }
                  // value={orderId + " / " + data.orderDate }
                  // value={orderId ==="undefined"?"":orderId + " / " + data.orderDate ==="undefined"?"":data.orderDate}

                  valueColor={mode ? "#fff" : "#646B88"}
                  fontSize={"12px"}
                  type={"order"}
                />
                <TextElement
                  label={"Order Manager "}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={
                    data.order.ordermanager ? data.order.ordermanager : ""
                    // + (orderId && data.order.orderDate ? " / " : "") + (data.order.orderDate ? data.order.orderDate: "")
                  }
                  // value={orderId + " / " + data.orderDate }
                  // value={orderId ==="undefined"?"":orderId + " / " + data.orderDate ==="undefined"?"":data.orderDate}

                  valueColor={mode ? "#fff" : "#646B88"}
                  fontSize={"12px"}
                  type={"order"}
                />
                <TextElement
                  label={"status : "}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={
                    data.order.dispatchStatus ? data.order.dispatchStatus : ""
                  }
                  valueColor={
                    // data.order.dispatchStatus === "Packed"   ? "red" :
                    //  data.order.dispatchStatus === "Dispatched" ? "yellow" :"green"
                    data.order.dispatchStatus === "New Order"
                      ? " #2196F3 "
                      : data.order.dispatchStatus === "Packed"
                      ? " #00A052"
                      : data.order.dispatchStatus === "Dispatched"
                      ? " #00A052"
                      : data.order.dispatchStatus === "Not Packed"
                      ? ""
                      : data.order.dispatchStatus === "In Progress"
                      ? " #DDE100"
                      : data.order.dispatchStatus === "Pending"
                      ? "RED"
                      : "#DDE100"
                  }
                  fontSize={"12px"}
                  type={"order"}
                />
              </div>
            </div>
            <div
              style={{
                paddingTop: "0",
                paddingLeft: "10px",
                paddingRight: "10px",
                // paddingBottom: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: mode ? "#232529" : "white",
                  borderRadius: "10px",
                  // padding: "5px 0px",
                  color: mode ? "white" : "black",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // padding: "3px",
                  }}
                >
                  <div
                    style={{ gap: "3px" }}
                    className={styles.voucherFlexDirection}
                  >
                    <h2 className={styles.slipheading_div}>
                      {data.order && data.order.storeName}
                    </h2>
                    <span
                      style={{
                        color: mode ? " white" : "black",
                        fontWeight: "400",
                      }}
                      className={styles.sliptext}
                    >
                      Ship To:&nbsp;
                      <b
                        style={{
                          color: mode ? " white" : "black",
                          fontWeight: "400",
                        }}
                      >
                        {data.order.shippingInfo &&
                          data.order.shippingInfo.name}
                      </b>
                    </span>
                    <span
                      style={{
                        color: mode ? " white" : "black",
                        fontWeight: "400",
                      }}
                      className={styles.sliptext}
                    >
                      Address:&nbsp;
                      <b
                        style={{
                          color: mode ? " white" : "black",
                          fontWeight: "400",
                        }}
                      >
                        {data.order.shippingInfo &&
                          data.order.shippingInfo.street +
                            ", " +
                            data.order.shippingInfo.city +
                            ", " +
                            data.order.shippingInfo.state +
                            ", " +
                            data.order.shippingInfo.pincode}
                      </b>
                    </span>
                    <div style={{ display: "flex", gap: "5px" }}>
                      {" "}
                      <span
                        style={{
                          color: mode ? " white" : "black",
                          fontWeight: "400",
                        }}
                        className={styles.sliptextGustin}
                      >
                        GSTUIN:<b>{data.order && data.order.gstuin}</b>
                      </span>
                      <span
                        style={{
                          color: mode ? " white" : "black",
                          fontWeight: "400",
                        }}
                        className={styles.sliptext}
                      >
                        Contact:&nbsp;
                        {data.order.shippingInfo &&
                          data.order.shippingInfo.mobile}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      alignItems: "end",
                    }}
                    className={styles.packingVoucher_left}
                  >
                    <div className={styles.voucher_flex}></div>
                    <div className={styles.voucher_flex}></div>
                    <div className={styles.voucher_flex}></div>
                    <div className={styles.voucher_flex}>
                      <span
                        style={{
                          color: mode ? " white" : "black",
                          fontWeight: "400",
                        }}
                        className={styles.sliptext}
                      >
                        Sub Method:&nbsp;
                        {data.order && data.order.partnerName}
                      </span>
                    </div>
                    <div className={styles.voucher_flex}>
                      <span
                        style={{
                          color: mode ? " white" : "black",
                          fontWeight: "400",
                        }}
                        className={styles.sliptext}
                      >
                        Delivery Method:&nbsp;
                        <span
                          style={{
                            color: mode ? " white" : "black",
                            fontWeight: "400",
                            border: mode
                              ? "1px solid #E4E7EB"
                              : "1px solid #E4E7EB",
                            borderRadius: "5px",
                            padding: "3px",
                          }}
                        >
                          {" "}
                          {data.order && data.order.courierName}
                        </span>{" "}
                      </span>
                    </div>
                    <span
                      style={{
                        color: mode ? " white" : "black",
                        fontWeight: "400",
                      }}
                      className={styles.sliptext}
                    >
                      Packer:&nbsp;
                      {data.order && data.order.packermanager}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                padding: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: mode ? "#2C2E33" : "#F8F9FC",

                  borderRadius: "10px",
                }}
                className={styles.mainPack_delivery_image}
              >
                <div
                  style={{
                    backgroundColor: mode ? "#232529" : "white",

                    borderRadius: "10px",
                  }}
                >
                  {" "}
                  <div
                    style={{
                      padding: "10px",
                    }}
                    className={styles.pack_image_div}
                  >
                    <span
                      style={{
                        color: mode ? "white" : "#626884",
                      }}
                      className={styles.DeliveryDetailsTxt}
                    >
                      Delivery Detail Slip
                    </span>
                    {data.order.courierSlip ? (
                      <img
                        src={
                          process.env.REACT_APP_S3URL + data.order.courierSlip
                        }
                        alt="delivery__slip"
                        className={styles.delivery_image}
                        // onClick={() =>
                        //   window.open(
                        //     process.env.REACT_APP_S3URL +
                        //       data.order.courierSlip,
                        //     // "mozillaWindow",
                        //     // "popup"
                        //     // imageUrl,
                        //     "ImageViewer",
                        //     "width=800,height=600,scrollbars=yes,resizable=yes"
                          
                        //   )
                        // }
                                onClick={handelPreview} // Calls function to open popup

                      />
                    ) : (
                      <div className={styles.delivery_image}>No Data</div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    padding: "10px",
                    backgroundColor: mode ? "#232529" : "white",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  {data.order.packingImgs &&
                    data.order.packingImgs.map((item, i) => (
                      <div key={i} className={styles.pack_image_div}>
                        <span
                          style={{
                            color: mode ? "white" : "#626884",
                          }}
                          className={styles.DeliveryDetailsTxt}
                        >
                          Pack {i + 1}
                        </span>
                        <img
                          key={i}
                          src={process.env.REACT_APP_S3URL + item}
                          alt="pack_image"
                          onClick={handelPreview} 
                          // onClick={() =>
                          //   window.open(
                          //     process.env.REACT_APP_S3URL + item,
                          //     "mozillaWindow",
                          //     "popup"
                          //   )
                          // }
                          className={styles.delivery_image}
                        />
                      </div>
                    ))}
                </div>
                <div
                  style={{
                    backgroundColor: mode ? "#232529" : "white",
                    borderRadius: "10px",
                    padding: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="w-100 d-flex flex-wrap"
                    style={{ gap: "16px" }}
                  >
                    {filterItem &&
                      filterItem.map((item, index) => ( 
                        <div key={index} style={{ width: "100%" }}>
                          <div
                            style={{
                              color: mode ? " #626884" : "white",
                            }}
                            className="d-flex flex-column mb-2"
                          >
                            {item.product_name && (
                              <span
                                style={{
                                  color: mode ? "white" : "#626884",
                                }}
                              >
                                Category: {item.product_name}
                              </span>
                            )}
                            <span
                              style={{
                                color: mode ? "white" : "#626884",
                                fontWeight: "500",
                              }}
                            >
                              Item No:{" "}
                              {item.itemNo ? item.itemNo : item.accessoriesName}
                            </span>
                          </div>

                          <div
                            className={styles.item_card}
                            style={{
                              border: "1px solid #6E6F70",
                              borderRadius: "10px",
                              padding: "10px",
                              overflow: "hidden",
                            }}
                          >
                            <div className="d-flex flex-row w-100 mb-2">
                              <img
                                src={
                                  process.env.REACT_APP_S3URL + item.thumbnail
                                }
                                alt={
                                  process.env.REACT_APP_S3URL + item.thumbnail
                                }
                                className={styles.item_image}
                                style={{
                                  borderRadius: "10px",
                                }}
                              />
                            </div>

                            {item.packingImg && (
                              <div className={styles.item_pack_div}>
                                <img
                                  src={
                                    process.env.REACT_APP_S3URL +
                                    item.packingImg
                                  }
                                  alt="items_pack_image"
                                  className={styles.main_pack_image}
                                  style={{
                                    borderRadius: "10px",
                                  }}
                                  // onClick={() =>
                                  //   window.open(
                                  //     process.env.REACT_APP_S3URL +
                                  //       item.packingImg,
                                  //     "mozillaWindow",
                                  //     "popup"
                                  //   )
                                  // }
                                  onClick={handelPreview} 
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ backgroundColor: mode ? "#2C2E33CC" : " white" }}
              className={styles.button_div}
            >
              <div className={styles.btn_flex}>
                <div style={{ width: "87px" }}>
                  {/* <Button
                    buttonIcon={<Back />}
                    btnType="button"
                    buttonText={"BACK"}
                    buttonBgColor={"black"}
                    buttonTextColor={"white"}
                    buttonClick={() => {
                      navigate(-1);
                    }}
                  /> */}
                  <Button
                    buttonIcon={<Back />}
                    buttonBgColor={"black"}
                    buttonTextColor={"white"}
                    buttonText={"BACK"}
                    buttonClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <div>
                  {" "}
                  {orderId && !orderId.includes("saleorder") && (
                    <Button
                      buttonIcon={<DispatchReloadIcon />}
                      btnType="button"
                      buttonText={"UPDATE"}
                      buttonBgColor={"black"}
                      buttonTextColor={"white"}
                      disabled={
                        data.order.dispatchStatus === "Cancelled" ? true : false
                      }
                    />
                  )}
                </div>
                <ModalWrapper
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  noBtn
                >
                  {"modalContent"}
                </ModalWrapper>

                <div
                  style={{
                    width: "85px",
                  }}
                >
                  <Button
                    buttonIcon={<ShareIcon />}
                    buttonBgColor={"#000000"}
                    buttonTextColor={"white"}
                    buttonText={"Share"}
                    buttonClick={() =>
                      setShareLinkModalSate(!ShareLinkModalSate)
                    }
                    btnType="button"
                    title="SHARE"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Newviewdispatchdetails;
