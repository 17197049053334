import React, { useState } from "react";
import styles from "./Manager.module.css";
import { useAuth } from "../../context/Auth";
import Button from "../Dealers/Button";
const RequestModel = ({
  title,
  colorcode,
  handeSubmit,
  handleClose,
  loading,
}) => {
  const { mode } = useAuth();
  const [confirmationText, setConfirmationText] = useState("");
  const [err, seterr] = useState("");
  const handleModalSubmit = () => {
    //console.log("confirmationText", confirmationText);

    if (confirmationText.toLocaleLowerCase() === "accept") {
      setConfirmationText("");
      seterr("");
      handeSubmit();
    } else {
      seterr("Please! Enter the same text.");
    }
  };
  return (
    <div
      className={styles.device_logout}
      style={{ backgroundColor: mode ? "#232529" : "#fff" }}
    >
      <p
        className={styles.device_logout_bold_text}
        style={{ alignSelf: "flex-start", color: mode ? "#ffffff" : "#000" }}
      >
        {title} Device
      </p>
      <img src="/assets/updatedAssets/logout_img.png" alt="" />

      <p
        className={`${styles.device_logout_bold_text}`}
        style={{ color: mode ? "#ffffff" : "#000" }}
      >
        Are you sure you want to{" "}
        <span style={{ color: colorcode }}> {title} </span> this Device
      </p>
      <p
        className={`${styles.device_logout_light_text}`}
        style={{ color: mode ? "#ffffff" : "#000" }}
      >
        To confirm! type <span style={{ color: "green" }}> Accept </span> in the
        text field.
      </p>
      <div
        className={styles.enter_input}
        style={mode ? { backgroundColor: "#1B1D21", border: "none" } : {}}
      >
        <input
          type="text"
          placeholder="Enter"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          style={mode ? { backgroundColor: "#1B1D21", color: "#919EC3" } : {}}
        />
      </div>
      <span style={{ color: "red", fontSize: "12px" }}>{err}</span>
      <div className={styles.bottom_buttons}>
        <div style={{ width: "30%" }}>
          <Button
            buttonText={"No"}
            buttonBgColor={"#000000"}
            buttonTextColor={"#ffffff"}
            buttonBorder={"none"}
            buttonClick={handleClose}
          />
        </div>
        <div style={{ width: "30%" }}>
          <Button
            buttonText={"Yes"}
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#ffffff"}
            buttonBorder={"none"}
            buttonClick={handleModalSubmit}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestModel;
