// import React from 'react'
// import styles from "./LocationFilter.module.css"
// import { useAuth } from '../../context/Auth';
// import { Spinner } from '../../icons/Icon3';
// export const LocationsFilter = ({ locations, className, style, title, loading }) => {
//     const handelChack  = (e)=>{
//         //console.log(e);

//     }
//     const { mode } = useAuth();
//     return (
//         <div className={mode ? `${styles.menuItem_dark0} ${className} ` : `${styles.menuItem0} ${className}`} style={style} >
//             <div className={styles.sarchCon} >
//                 <span>{title} </span>
//                 <input type="text" name="" id=""
//                     placeholder='Sarch...'
//                     className={styles.sarchInput}
//                     style={{ border: mode ? "none" : "1px solid #E4E7EB", backgroundColor: mode ? "#2C2E33" : "#fff", color: mode ? "#fff" : "#000" }}
//                 />
//             </div>
//             <div className={styles.scrollDiv}  >
//                 {
//                     loading ? <div style={{margin:"10px"}}> <Spinner size="22" /> </div>  : <>
//                         {locations?.map((e, i) =>
//                             <div className={mode ? styles.darkHover : styles.lightHover} >
//                                 <label className={styles.cl_checkbox} onClick={handelChack} >
//                                     <input type="checkbox" id={i} />
//                                     <span></span>
//                                 </label>
//                                 <label htmlFor={i} > {e} </label>
//                             </div>
//                         )}
//                     </>
//                 }
//             </div>

//         </div>
//     );
// };

// import React from 'react';
// import styles from "./LocationFilter.module.css";
// import { useAuth } from '../../context/Auth';
// import { Spinner } from '../../icons/Icon3';
// import { truncateText } from '../../../Utils/TextUtils';

// export const LocationsFilter = ({ locations, className, style, title, loading, setSelectedLocations, sareshOnchange, selectedLocations, sarchValue, ApplyOnclick, ResetOnclick }) => {

//     const { mode } = useAuth();

//     const handleCheck = (e, location) => {
//         if (e.target.checked) {
//             setSelectedLocations((prev) => [...prev, location]);
//         } else {
//             setSelectedLocations((prev) => prev.filter((loc) => loc !== location));
//         }
//     };

//     return (
//         <div className={mode ? `${styles.menuItem_dark0} ${className}` : `${styles.menuItem0} ${className}`} style={style}>
//             <div className={styles.sarchCon}>
//                 <span>{title}</span>
//                 <input
//                     type="text"
//                     placeholder='Search...'
//                     className={styles.sarchInput}
//                     style={{
//                         border: mode ? "none" : "1px solid #E4E7EB",
//                         backgroundColor: mode ? "#2C2E33" : "#fff",
//                         color: mode ? "#fff" : "#000"
//                     }}
//                     onChange={(e) => sareshOnchange(e.target.value)}
//                     value={sarchValue}
//                 />
//             </div>
//             <div className={styles.scrollDiv}>
//                 {loading ? (
//                     <div style={{ margin: "10px" }}>
//                         <Spinner size="22" />
//                     </div>
//                 ) : (
//                     locations?.map((location, i) => (
//                         <div className={mode ? styles.darkHover : styles.lightHover} key={i}>
//                             <label className={styles.cl_checkbox}>
//                                 <input
//                                     type="checkbox"
//                                     id={location}
//                                     onChange={(e) => handleCheck(e, location)}
//                                     checked={selectedLocations?.includes(location)}
//                                 />
//                                 <span></span>
//                             </label>
//                             <label htmlFor={location}>{truncateText(location.toUpperCase(), 20)}</label>
//                         </div>
//                     ))
//                 )}
//             </div>
//             {/* <div style={{ width: "100%", marginTop: "5px", display: "flex", fontSize: "13px", gap: "4px",  }}>
//                 <div style={{ width: "100%", height:"30px",  }} >
//                     <button style={{ width: "100%", border: "none", outline: "none", color: "#fff", backgroundColor: "#000", borderRadius: "5px", height:"100%" }}
//                         onClick={ResetOnclick}
//                     >Reset</button> </div>
//                 <div style={{ width: "100%",  height:"30px", }}  >
//                     <button style={{ width: "100%", border: "none", outline: "none", color: "#fff", backgroundColor: "#000", borderRadius: "5px", height:"100%"  }}
//                         onClick={ApplyOnclick}
//                     >Apply</button> </div>
//             </div> */}
//         </div>
//     );
// };

import React from "react";
import styles from "./LocationFilter.module.css";
import { useAuth } from "../../context/Auth";
import { Spinner } from "../../icons/Icon3";
import { truncateText } from "../../../Utils/TextUtils";

export const LocationsFilter = ({
  locations,
  className,
  style,
  title,
  loading,
  setSelectedLocations,
  sareshOnchange,
  selectedLocations,
  sarchValue,
}) => {
  const { mode } = useAuth();

  const handleCheck = (e, location) => {
    if (e.target.checked) {
      setSelectedLocations((prev) => [...prev, location]);
    } else {
      setSelectedLocations((prev) => prev.filter((loc) => loc !== location));
    }
  };

  return (
    <div
      className={
        mode
          ? `${styles.menuItem_dark0} ${className}`
          : `${styles.menuItem0} ${className}`
      }
      style={style}
    >
      <div className={styles.sarchCon}>
        <span>{title}</span>
        <input
          type="text"
          placeholder="Search..."
          className={styles.sarchInput}
          style={{
            border: mode ? "none" : "1px solid #E4E7EB",
            backgroundColor: mode ? "#232529" : "#fff",
            color: mode ? "#fff" : "#000",
          }}
          onChange={(e) => sareshOnchange(e.target.value)}
          value={sarchValue}
        />
      </div>
      <div className={styles.scrollDiv}>
        {loading ? (
          <div style={{ margin: "10px" }}>
            <Spinner size="22" />
          </div>
        ) : (
          locations?.map((location, i) => (
            <div
              className={mode ? styles.darkHover : styles.lightHover}
              key={i}
            >
              <label className={styles.cl_checkbox}>
                <input
                  type="checkbox"
                  id={location}
                  onChange={(e) => handleCheck(e, location)}
                  checked={selectedLocations?.includes(location)}
                />
                <span></span>
              </label>
              <label htmlFor={location}>
                {truncateText(location.toUpperCase(), 20)}
              </label>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
