import styles from "./Orders.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  DispatchReloadIcon,
  CreateVoucherIcon,
} from "../../components/icons/Icon";
import { useCallback, useEffect, useMemo, useState } from "react";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
import Button from "../../components/updatedComponents/Dealers/Button";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import fetchData from "../../APIsControll/apiControll";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import ToggleSearchBar from "../../components/updatedComponents/FormComponents/ToggleSearchBar";
import VoucherList from "./VoucherList";
import OrderFilters from "./OrderFilters";
import { SocketConnected } from "../../Socket/socket";

const AllOrders = () => {
  const {
    mode,
    managerId,
    voucherTab,
    setVoucherTab,
    handleVoucherChange,
    orderData,
    setOrderData,
    designations,
    designation
  } = useAuth();
  const navigate = useNavigate();
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [ordersFilters, setOrdersFilters] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [managers, setManagers] = useState([]);
  const [selectedManagerID, setSelectedManagerID] = useState("");
  const [daysTab, setDaysTab] = useState("today");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const [selectedFilter, setSelectedFilter] = useState("All");

  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [purchaseFilters, setPurchaseFilters] = useState([]);

  const [adjustmentOrderData, setAdjustmentOrderData] = useState([]);
  const [adjustmentFilters, setAdjustmentFilters] = useState([]);

  const [transferOrderData, setTransferOrderData] = useState([]);
  const [transferFilters, setTransferFilters] = useState([]);
  const [fromWarehouse, setFromWarehouse] = useState("");
  const [toWarehouse, setToWarehouse] = useState("");



  useEffect(() => {
    SocketConnected({ socketUserId: `${managerId}`, role: designation, warehouseNames: JSON.parse(localStorage.getItem("warehouse")) });
  }, [designation, managerId]);

  useEffect(() => {
    const getOrders = async () => {
      setPurchaseOrderData([]);
      setAdjustmentOrderData([]);
      setTransferOrderData([]);

      if (daysTab === "period" && (startDate === "" || endDate === "")) return;

      const apiData = {
        managerId: parseInt(managerId),
        day: daysTab === "period" ? "" : daysTab,
        orderStatus: selectedFilter,
        warehousename: selectedWarehouse,
        ordermanagerId: selectedManagerID,
        keyword: debouncedKeyword,
        startDate: startDate,
        endDate: endDate,
      };

      try {
        setLoading(true);
        const result = await fetchData(
          `order/allorders?page=${page}&limit=15`,
          apiData
        );

        if (result.status === "success") {
          setOrderData(result.data);
          setPages(result.pages);
          setLoading(false);

          if (result.orders) {
            const formattedOrders = result.orders.map((s) => ({
              statusText: s.status,
              statusCount: s.count,
            }));
            setOrdersFilters(formattedOrders);
          }

          if (result.warehouses) {
            const formattedWarehouses = result.warehouses.map((w) => ({
              label: w.warehousename,
              value: w._id,
            }));
            setWarehouses(formattedWarehouses);
          }

          if (result.managers) {
            const formattedManagers = result.managers.map((m) => ({
              label: m.name,
              value: m.managerId,
            }));
            setManagers(formattedManagers);
          }
        }

        if (result.status === "failed") {
          setOrderData([]);
          setOrdersFilters([]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    if (managerId && voucherTab === "Order") {
      getOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    daysTab,
    managerId,
    page,
    selectedFilter,
    selectedWarehouse,
    selectedManagerID,
    debouncedKeyword,
    startDate,
    endDate,
    voucherTab,
  ]);

  useEffect(() => {
    const getPurchaseOrders = async () => {
      setAdjustmentOrderData([]);
      setTransferOrderData([]);
      setOrderData([]);

      if (daysTab === "period" && (startDate === "" || endDate === "")) return;

      const apiData = {
        managerId: parseInt(managerId),
        keyword: debouncedKeyword,
        // sortKey: sortKey,
        warehouse: selectedWarehouse,
        day: daysTab === "period" ? "" : daysTab,
        startDate: startDate,
        endDate: endDate,
        orderStatus: selectedFilter,
        // orderStatus: selectedPurchaseFilter,
        inventorymanagerId: selectedManagerID,
      };
      try {
        setLoading(true);
        const result = await fetchData(
          `inventory/allPurchase?page=${page}&limit=15`,
          apiData
        );
        if (result.status === "success") {
          setLoading(false);
          setPages(result.pages);
          setPurchaseOrderData(result.data);
          setPurchaseFilters(result.orders);
          if (result.warehouses) {
            setWarehouses(result.warehouses);
          }
          if (result.managers) {
            const formattedManagers = result.managers.map((m) => ({
              label: m.name,
              value: m.managerId,
            }));
            setManagers(formattedManagers);
          }
        }
        if (result.status === "failed") {
          setOrderData([]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        //console.log("Error", error);
      } finally {
        setLoading(false);
      }
    };
    if (managerId && voucherTab === "Purchase") {
      getPurchaseOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    daysTab,
    endDate,
    debouncedKeyword,
    managerId,
    page,
    selectedManagerID,
    // selectedPurchaseFilter,
    selectedFilter,
    selectedWarehouse,
    startDate,
    voucherTab,
  ]);

  useEffect(() => {
    const getAdjustmentOrders = async () => {
      setPurchaseOrderData([]);
      setTransferOrderData([]);
      setOrderData([]);

      if (daysTab === "period" && (startDate === "" || endDate === "")) return;

      const apiData = {
        managerId: parseInt(managerId),
        // sortKey: sortKey,
      };
      try {
        setLoading(true);
        const result = await fetchData(
          `inventory/allAdjustment?page=${page}&limit=15`,
          apiData
        );
        if (result.status === "success") {
          setLoading(false);
          setPages(result.pages);
          setAdjustmentOrderData(result.data);
          setAdjustmentFilters(result.orders);
        }
        if (result.status === "failed") {
          setOrderData([]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        //console.log("Error", error);
      } finally {
        setLoading(false);
      }
    };
    if (managerId && voucherTab === "Adjustment") {
      getAdjustmentOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerId, page, voucherTab]);

  useEffect(() => {
    const getTransferOrders = async () => {
      setPurchaseOrderData([]);
      setAdjustmentOrderData([]);
      setOrderData([]);

      if (daysTab === "period" && (startDate === "" || endDate === "")) return;

      const apiData = {
        managerId: parseInt(managerId),
        keyword: debouncedKeyword,
        // sortKey: sortKey,
        fromwarehouse: fromWarehouse,
        towarehouse: toWarehouse,
        day: daysTab === "period" ? "" : daysTab,
        startDate: startDate,
        endDate: endDate,
        orderStatus: selectedFilter,
        // orderStatus: selectedTransferFilter,
        inventorymanagerId: selectedManagerID,
      };
      try {
        setLoading(true);
        const result = await fetchData(
          `inventory/stocktransfers?page=${page}&limit=15`,
          apiData
        );
        if (result.status === "success") {
          setLoading(false);
          setPages(result.pages);
          setTransferOrderData(result.data);
          setTransferFilters(result.orders);
          if (result.warehouses) {
            setWarehouses(result.warehouses);
          }
          if (result.managers) {
            const formattedManagers = result.managers.map((m) => ({
              label: m.name,
              value: m.managerId,
            }));
            setManagers(formattedManagers);
          }
        }
        if (result.status === "failed") {
          setOrderData([]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        //console.log("Error", error);
      } finally {
        setLoading(false);
      }
    };
    if (managerId && voucherTab === "Transfer") {
      getTransferOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    daysTab,
    endDate,
    debouncedKeyword,
    managerId,
    page,
    selectedManagerID,
    selectedWarehouse,
    startDate,
    voucherTab,
    fromWarehouse,
    toWarehouse,
    selectedFilter,
    // selectedTransferFilter,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, 700);

    return () => clearTimeout(timer);
  }, [keyword]);

  const handleDayChange = (label) => {
    const selectedFilter = dayOptions.find((item) => item.label === label);
    const newDayTab = selectedFilter?.value || "today";

    setDaysTab(newDayTab);

    if (newDayTab !== "period") {
      setOrdersFilters([]);
      setPages([]);
      setStartDate("");
      setEndDate("");
    }
  };

  const resetFilters = () => {
    setSelectedWarehouse("");
    setSelectedManagerID("");
    setDaysTab("today");
    // setSelectedOrderFilter("All");
    setSelectedFilter("All");
    setStartDate("");
    setEndDate("");
    setKeyword("");
  };

  const orderStatusData = [
    {
      status: "All",
      count: ordersFilters[0]?.statusCount || 0,
    },
    {
      status: "New Order",
      count: ordersFilters[1]?.statusCount || 0,
    },
    {
      status: "Cancelled",
      count: ordersFilters[2]?.statusCount || 0,
    },
    {
      status: "In Progress",
      count: ordersFilters[3]?.statusCount || 0,
    },
    {
      status: "Payment Pending",
      count: ordersFilters[4]?.statusCount || 0,
    },
    {
      status: "Payment Under Verification",
      count: ordersFilters[5]?.statusCount || 0,
    },
    {
      status: "Packing In Progress",
      count: ordersFilters[6]?.statusCount || 0,
    },
    {
      status: "Dispatched Pending",
      count: ordersFilters[7]?.statusCount || 0,
    },
    {
      status: "Dispatched",
      count: ordersFilters[8]?.statusCount || 0,
    },
  ];

  const purchaseStatusData = [
    {
      status: "All",
      count: purchaseFilters[0]?.count || 0,
    },
    {
      status: "draft",
      count: purchaseFilters[1]?.count || 0,
    },
    {
      status: "success",
      count: purchaseFilters[2]?.count || 0,
    },
  ];

  const adjustmentStatusData = [
    {
      status: "All",
      count: adjustmentFilters[0]?.count + adjustmentFilters[1]?.count || 0,
    },
    {
      status: "draft",
      count: adjustmentFilters[0]?.count || 0,
    },
    {
      status: "success",
      count: adjustmentFilters[1]?.count || 0,
    },
  ];

  const transferStatusData = [
    {
      status: "All",
      count: transferFilters[0]?.count || 0,
    },
    {
      status: "draft",
      count: transferFilters[1]?.count || 0,
    },
    {
      status: "New Order",
      count: transferFilters[2]?.count || 0,
    },
    {
      status: "In Progress",
      count: transferFilters[3]?.count || 0,
    },
    {
      status: "Completed",
      count: transferFilters[4]?.count || 0,
    },
  ];

  const placeholder = [
    {
      status: "All",
      count: 0,
    },
    {
      status: "Failed",
      count: 0,
    },
    {
      status: "Success",
      count: 0,
    },
  ];

  let dayOptions = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "month", label: "This Month" },
    { value: "period", label: "Period" },
  ];

  if (designations.includes("superadmin")) {
    dayOptions.splice(dayOptions.length - 1, 0, {
      value: "previousyear",
      label: "Previous Year",
    });
  }

  const orderTypeData = [
    "Order",
    "Collection",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];

  const designationOptionsMap = useMemo(
    () => ({
      inventorymanager: orderTypeData,
      superadmin: orderTypeData,
      ordermanager: ["Order", "Collection", "Production & Sale"],
      default: ["Order"],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getAllowedOrderTypes = useCallback(
    (designations) => {
      if (!designations || designations.length === 0) {
        return designationOptionsMap.default;
      }

      const allowedTypes = new Set(designationOptionsMap.default);

      designations.forEach((designation) => {
        if (designationOptionsMap[designation]) {
          designationOptionsMap[designation].forEach((type) =>
            allowedTypes.add(type)
          );
        }
      });

      return Array.from(allowedTypes);
    },
    [designationOptionsMap]
  );

  const voucherOptions = useMemo(() => {
    return getAllowedOrderTypes(designations);
  }, [designations, getAllowedOrderTypes]);

  const transferOrderHeader = [
    "Voucher No",
    "From Warehouse",
    "To Warehouse",
    "Date",
    "Amount",
    "Status",
    "Items",
  ];

  const adjustmentOrderHeader = [
    "Voucher No.",
    "Warehouse",
    "Date",
    "Amount",
    "Status",
    "Items",
  ];

  const purchaseOrderHeader = [
    "Voucher No.",
    "Vendors Name",
    "Warehouse",
    "Date",
    "Amount",
    "Status",
    "Items",
  ];

  const orderHeader = [
    "Name",
    "Order ID",
    "Time & Date",
    "Created By",
    "Order Manager",
    "Source",
    "Amount",
    "Status",
    "Last Action",
  ];

  return (
    <div
      className={styles.order}
      style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
    >
      <div className={styles.order_top}>
        <div className={styles.order_top_left}>
          <TopTabBar text={voucherTab} />
        </div>
        <div className={styles.order_right}>
          <div>
            <SlidingFilter2
              itemList={dayOptions.map((item) => item.label)}
              setFilter={handleDayChange}
              selectedItem={
                dayOptions.find((item) => item.value === daysTab)?.label
              }
            />
          </div>

          {daysTab === "period" && (
            <>
              <div>
                <InputTypedate
                  text={"From"}
                  value={startDate}
                  max={endDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    if (daysTab !== "period") {
                      setDaysTab("");
                    }
                    setSelectedFilter("");
                    // setSelectedOrderFilter("");
                  }}
                />
              </div>
              {startDate !== "" && (
                <div>
                  <InputTypedate
                    text={"To"}
                    value={endDate}
                    min={startDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      if (daysTab !== "period") {
                        setDaysTab("");
                      }
                      setSelectedFilter("");
                      // setSelectedOrderFilter("");
                    }}
                  />
                </div>
              )}
            </>
          )}

          <div>
            <Button
              buttonIcon={<DispatchReloadIcon />}
              buttonBgColor={"#000000"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => resetFilters()}
            />
          </div>
          <div>
            <Button
              buttonIcon={<CreateVoucherIcon />}
              buttonText={"Create"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => {
                navigate("/orders/order/saleorder");
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={styles.order_filters}
        style={
          mode ? { borderColor: "#232529", backgroundColor: "#232529" } : {}
        }
      >
        <>
          {voucherTab === "Order" && (
            <OrderFilters
              mode={mode}
              headerData={orderStatusData}
              selectedFilter={selectedFilter}
              handleClick={(s) => {
                setSelectedFilter(s);
                setPage(1);
              }}
            />
          )}
          {voucherTab === "Collection" && (
            <OrderFilters mode={mode} headerData={placeholder} />
          )}
          {voucherTab === "Purchase" && (
            <OrderFilters
              mode={mode}
              headerData={purchaseStatusData}
              selectedFilter={selectedFilter}
              handleClick={(s) => {
                setSelectedFilter(s);
                setPage(1);
              }}
            />
          )}
          {voucherTab === "Adjustment" && (
            <OrderFilters
              mode={mode}
              headerData={adjustmentStatusData}
              selectedFilter={selectedFilter}
              handleClick={(s) => {
                setSelectedFilter(s);
                setPage(1);
              }}
            />
          )}
          {voucherTab === "Transfer" && (
            <OrderFilters
              mode={mode}
              headerData={transferStatusData}
              selectedFilter={selectedFilter}
              handleClick={(s) => {
                setSelectedFilter(s);
                setPage(1);
              }}
            />
          )}
          {voucherTab === "Production" && (
            <OrderFilters mode={mode} headerData={placeholder} />
          )}
          {voucherTab === "Production & Sale" && (
            <OrderFilters mode={mode} headerData={placeholder} />
          )}
          {voucherTab === "Production & Transfer" && (
            <OrderFilters mode={mode} headerData={placeholder} />
          )}
        </>
      </div>

      <div className={styles.order_data}>
        <div className={styles.order_data_filters}>
          <div>
            <ToggleSearchBar
              placeholder={"Search"}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                setPage(1);
              }}
              onToggle={(isExpanded) => setIsSearchBarOpen(isExpanded)}
            />
          </div>

          <div>
            {isSearchBarOpen ? (
              <DropDown
                label={"Type"}
                listLabel={"Order"}
                options={voucherOptions.map((o) => ({ label: o, value: o }))}
                onSelect={(option) => setVoucherTab(option.value)}
                onReject={() => setVoucherTab("Order")}
              />
            ) : (
              <SlidingFilter2
                itemList={voucherOptions.map((item) => item)}
                setFilter={(v) => handleVoucherChange(voucherOptions, v)}
                selectedItem={voucherOptions.find(
                  (item) => item === voucherTab
                )}
              />
            )}

            {designations.includes("superadmin") && (
              <>
                {voucherTab === "Transfer" ? (
                  <>
                    <DropDown
                      label={"From"}
                      listLabel={"All"}
                      options={warehouses}
                      onSelect={(option) => setFromWarehouse(option.label)}
                      onReject={() => setFromWarehouse(null)}
                    />
                    <DropDown
                      label={"To"}
                      listLabel={"All"}
                      options={warehouses}
                      onSelect={(option) => setToWarehouse(option.label)}
                      onReject={() => setToWarehouse(null)}
                    />
                  </>
                ) : (
                  <DropDown
                    label={"Warehouse"}
                    listLabel={"All"}
                    options={warehouses}
                    onSelect={(option) => setSelectedWarehouse(option.label)}
                    onReject={() => setSelectedWarehouse(null)}
                  />
                )}
                <DropDown
                  label={"Manager"}
                  listLabel={"All"}
                  options={managers}
                  onSelect={(option) => setSelectedManagerID(option.value)}
                  onReject={() => setSelectedManagerID(null)}
                />
              </>
            )}

            {/* <DropDown
              label={"Sort By"}
              listLabel={"View All"}
              options={orders.map((o) => ({
                label: o.statusText,
                value: o.statusText,
              }))}
            /> */}
          </div>
        </div>

        <ul className={styles.order_data_list}>
          {voucherTab === "Order" && (
            <VoucherList
              mode={mode}
              loading={loading}
              voucherTab={voucherTab}
              headerData={orderHeader}
              orderData={orderData}
              handleClick={(d) =>
                navigate("/orders/order/saleorder?orderId=" + d.orderId)
              }
            />
          )}

          {voucherTab === "Purchase" && (
            <VoucherList
              mode={mode}
              loading={loading}
              voucherTab={voucherTab}
              headerData={purchaseOrderHeader}
              orderData={purchaseOrderData}
              handleClick={(d) =>
                navigate(`/orders/order/purchase?orderId=${d.voucherNo}`)
              }
            />
          )}

          {voucherTab === "Adjustment" && (
            <VoucherList
              mode={mode}
              loading={loading}
              voucherTab={voucherTab}
              headerData={adjustmentOrderHeader}
              orderData={adjustmentOrderData}
              handleClick={(d) =>
                navigate("/orders/order/adjustment?orderId=" + d.voucherNo)
              }
            />
          )}

          {voucherTab === "Transfer" && (
            <VoucherList
              mode={mode}
              loading={loading}
              voucherTab={voucherTab}
              headerData={transferOrderHeader}
              orderData={transferOrderData}
              handleClick={(d) =>
                navigate("/orders/order/transfer?orderId=" + d.voucherNo)
              }
            />
          )}
        </ul>
      </div>

      <div
        className={styles.pagination}
        style={mode ? { backgroundColor: "#232529" } : {}}
      >
        <div style={{ width: "50%" }}>
          <div className={styles.go_to_page}>
            <GoToPage
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={pages}
              key={"1"}
            />
          </div>
        </div>
        <div className={styles.pages}>
          <Pagenation
            currentPage={page}
            setCurrentPage={setPage}
            totalPages={pages}
            key={"1"}
          />
        </div>
      </div>
    </div>
  );
};

export default AllOrders;
