import React, { useEffect, useState } from "react";
import styles from "./Product.module.css";
import { useAuth } from "../../components/context/Auth";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImageUploder from "../../components/updatedComponents/Product/ImageUploder";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import KeyWordGen from "../../components/updatedComponents/Product/KeyWordGen";
import InputTextArea from "../../components/updatedComponents/Product/InputTextArea";
import InputRedio from "../../components/updatedComponents/FormComponents/InputRedio";
import Button from "../../components/updatedComponents/Dealers/Button";
import { AddTO } from "../../components/icons/Icon3";
import DrawerWrapper from "../../components/updatedComponents/layoutWrapper/DrawerWrapper";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import { handleFileData } from "../../APIsControll/apiControl2";
import { validateFormForAddUpdateStyle } from "../../validation/AddDealer";
import fetchData from "../../APIsControll/apiControll";

const AddStyleAndType = () => {
  const { show } = useToast();
  const type_id = new URLSearchParams(window.location.search).get("type_id");
  const disabled =
    new URLSearchParams(window.location.search).get("disabled") === "true";
  const { mode } = useAuth();
  const location = useLocation();
  const Navigate = useNavigate();
  const pathParts = location.pathname.split("/");
  const addTypeString = pathParts[2];
  const [modelContainer, setmodelContainer] = useState(null);
  const [toggle, setToggle] = useState({
    modelToggle: false,
  });
  const [errors, setErrors] = useState({});
  const [url, seturl] = useState("");
  const { productname, productId } = useParams();
  const [SingleData, setSingleData] = useState({
    product_name: "",
    product_id: "",
    type_name: "",
    logo: "",
    metaTitle: "",
    metaKeywords: [],
    focusKeyword: [],
    url: "",
    title: "",
    description: "",
    metaDescription: "",
    status: "",
    // DeskTopBanner: [{ bannerImg: '', itemUrl: '', itemUrlType: '', }],
    DeskTopBanner: [],
    // mobItemBanner: [{ bannerImg: '', itemUrl: '', itemUrlType: '', }],
    mobItemBanner: [],
    bannerUpdate: "",
    mobBannerUpdate: "",
  });

  // set Meta url
  useEffect(() => {
    const formatUrlFromMetaTitle = (metaTitle) => {
      return metaTitle.replace(/\s+/g, " ").split(" ").join("-").toLowerCase();
    };
    const formattedUrl = formatUrlFromMetaTitle(SingleData.metaTitle);
    seturl(formattedUrl);
  }, [SingleData]);

  // //console.log("addTypeString", addTypeString);

  const getStyle = async () => {
    try {
      const payload = { _id: type_id };
      let endpoint = "";
      if (addTypeString === "addstyle") {
        endpoint = "content/style";
      } else if (addTypeString === "addtype") {
        endpoint = "content/type";
      }
      if (endpoint) {
        const res = await fetchData(endpoint, payload);
        seturl(res.data.url);
        setSingleData({
          ...SingleData,
          type_name: res.data.style_name
            ? res.data.style_name
            : "" || res.data.type_name
              ? res.data.type_name
              : "",
          logo: res.data.logo ? res.data.logo : "",
          DeskTopBanner: res.data.banner ? res.data.banner : [],
          mobItemBanner: res.data.mobBanner ? res.data.mobBanner : [],
          title: res.data.title ? res.data.title : "",
          description: res.data.description ? res.data.description : "",
          metaDescription: res.data.metaDescription
            ? res.data.metaDescription
            : "",
          metaKeywords: res.data.metaKeywords ? res.data.metaKeywords : [],
          focusKeyword: [res.data.focusKeyword] ? [res.data.focusKeyword] : [],
          metaTitle: res.data.metaTitle ? res.data.metaTitle : "",
          url: res.data.metaTitle ? res.data.metaTitle : "",
          status: res.data.status ? res.data.status : "",
        });
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // //console.log("HH", SingleData.title);

  useEffect(() => {
    if (type_id) {
      getStyle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // //console.log('SingleData ----> ', errors);

  const AddUpdate = async () => {
    try {
      const validationErrors = validateFormForAddUpdateStyle(SingleData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
      const formdata = new FormData();
      formdata.append("product_name", SingleData.product_name || productname);
      formdata.append("product_id", SingleData.product_id || productId);
      formdata.append("style_name", SingleData.type_name);
      formdata.append("type_name", SingleData.type_name);
      formdata.append("logo", SingleData.logo);
      formdata.append("metaTitle", SingleData.metaTitle);
      formdata.append("metaKeywords", JSON.stringify(SingleData.metaKeywords));
      formdata.append("focusKeyword", SingleData.focusKeyword[0]);
      formdata.append("url", url);
      formdata.append("title", SingleData.title);
      formdata.append("description", SingleData.description);
      formdata.append("metaDescription", SingleData.metaDescription);
      formdata.append("status", SingleData.status);
      SingleData.DeskTopBanner.forEach((item) => {
        formdata.append("bannerImg", item.bannerImg);
        formdata.append("itemUrl", item.itemUrl);
        formdata.append("itemUrlType", item.itemUrlType);
      });
      SingleData.mobItemBanner.forEach((item) => {
        formdata.append("mobBannerImg", item.bannerImg);
        formdata.append("mobItemUrl", item.itemUrl);
        formdata.append("mobItemUrlType", item.itemUrlType);
      });
      formdata.append("bannerUpdate", "");
      if (type_id) {
        formdata.append("_id", type_id);
      }
      formdata.append("mobBannerUpdate", "");
      let endpoint = "";
      if (addTypeString === "addstyle") {
        endpoint = "content/addUpdateStyle";
      } else if (addTypeString === "addtype") {
        endpoint = "content/addUpdateType";
      }
      if (endpoint) {
        const res = await handleFileData(endpoint, formdata);
        if (res.status === "success") {
          show(res.msg || res.data, "success");
          Navigate(-1);
          return;
        }
        if (res.status === "failed") {
          show(res.msg, "error");
          return;
        }
      }
    } catch (error) {
      show("Something went wrong", "error");
    }
  };

  // Create DeskTop
  const addNewDeskTopBanner = () => {
    setSingleData((prevData) => ({
      ...prevData,
      DeskTopBanner: [
        ...prevData.DeskTopBanner,
        {
          bannerImg: "",
          itemUrl: "",
          itemUrlType: "",
        },
      ],
    }));
  };

  // Create Mobile
  const addNewMobileBanner = () => {
    setSingleData((prevData) => ({
      ...prevData,
      mobItemBanner: [
        ...prevData.mobItemBanner,
        {
          bannerImg: "",
          itemUrl: "",
          itemUrlType: "",
        },
      ],
    }));
  };

  // set Desktop and Mobile Image
  const handleImageChange = (bannerType, index, imageUrl) => {
    const updatedBanner = [...SingleData[bannerType]];
    updatedBanner[index].bannerImg = imageUrl;
    setSingleData({
      ...SingleData,
      [bannerType]: updatedBanner,
    });
  };

  return (
    <div className={styles.Main_Layout}>
      <DrawerWrapper
        isOpen={toggle.modelToggle}
        onClose={() => setToggle({ ...toggle, modelToggle: false })}
      >
        {modelContainer}
      </DrawerWrapper>

      <div
        className={styles.Layout}
        style={{
          backgroundColor: mode ? "#2B2D31" : "#F8F9FC",
          color: mode ? "#fff" : "#000",
          cursor: disabled ? "not-allowed" : "",
        }}
      >
        {/* <form> */}
        <div className={styles.Top_bar_css}>
          <div className={styles.gobackClass}>
            <TopTabBar text={`Product / ${productname}`} />
          </div>
          <div className={styles.btn_Continer}>
            <div>
              <DropDown
                label={"Sort By"}
                options={[
                  { label: "Active" },
                  { label: "Disabled" },
                  { label: "Delete" },
                  { label: "Ascending" },
                  { label: "Descending" },
                ]}
              />
            </div>
            <div>
              <Button
                buttonText={type_id ? "Update" : "Create"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#fff"}
                buttonIcon={<AddTO />}
                // disabled={disabled}
                disabledButtonTextColor={"#fff"}
                disabledButtonBgColor={"#4164E3"}
                buttonClick={(e) => {
                  e.preventDefault();
                  setToggle({ ...toggle, modelToggle: !toggle.modelToggle });
                  setmodelContainer(
                    <>
                      <div
                        className={styles.bootem_seet_open}
                        style={{
                          backgroundColor: mode ? "#2B2D31" : "#F8F9FC",
                          color: mode ? "#fff" : "#000",
                        }}
                      >
                        <div>
                          <Button
                            buttonText={"Cancel"}
                            buttonBgColor={"#000"}
                            buttonTextColor={"#fff"}
                            // buttonIcon={<AddTO />}
                            buttonClick={()=>setToggle((prevToggle) => ({
                              ...prevToggle,
                              modelToggle: !prevToggle.modelToggle,
                            }))
                          
                          }
                          />
                        </div>
                        <div>
                          <Button
                            buttonText={type_id ? "Update":"Create"}
                            buttonBgColor={"#4164E3"}
                            buttonTextColor={"#fff"}
                            buttonClick={AddUpdate}
                          // buttonIcon={<AddTO />}
                          />
                        </div>
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.form_continer}>
          {/* col - 1 */}
          <div className={styles.Col_sm}>
            <InputTag
              labelText={"Meta URL"}
              star={true}
              padding={"8px"}
              fontSize={"12px"}
              maxLength={10000}
              placeholderType={"text"}
              required={true}
              value={url}
              disabled={true}
              onChange={(e) =>
                setSingleData({ ...SingleData, url: e.target.value })
              }
            />
            <InputTag
              labelText={
                addTypeString === "addstyle" ? "Style Name" : "Type Name"
              }
              star={true}
              padding={"8px"}
              fontSize={"12px"}
              maxLength={10000}
              placeholderType={"text"}
              value={SingleData?.type_name}
              onChange={(e) =>
                setSingleData({ ...SingleData, type_name: e.target.value })
              }
              warning={errors.type_name}
              disabled={disabled}
            />
            <div
              className={styles.Col_sm_sub4}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div
                className={styles.text_color}
                style={{ color: mode ? "#fff" : "#647298" }}
              >
                {" "}
                <span>Logo </span>{" "}
              </div>{" "}
              <div></div>
              <div>
                <ImageUploder
                  image={SingleData.logo}
                  setimage={(imge) =>
                    setSingleData({ ...SingleData, logo: imge })
                  }
                  selectText={"Select Master Image"}
                  error={errors.logo}
                  disabled={disabled}
                />
              </div>
            </div>
            <div
              className={styles.Col_sm_sub4}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div
                className={styles.text_color}
                style={{ color: mode ? "#fff" : "#647298" }}
              >
                {" "}
                <span>Desktop Banner </span>{" "}
              </div>{" "}
              <div></div>
              {SingleData?.DeskTopBanner?.map((item, index) => (
                <div key={`Dexttop-${index}`}>
                  <div>
                    <ImageUploder
                      image={item.bannerImg}
                      setimage={(imageUrl) =>
                        handleImageChange("DeskTopBanner", index, imageUrl)
                      }
                      options={[
                        { label: "ITEM", value: "item" },
                        { label: "URL", value: "Url" },
                        { label: "N/A", value: "N/A" },
                      ]}
                      value={item.itemUrlType}
                      setoption={(selectedOption) => {
                        const updatedDeskTopBanner = [
                          ...SingleData.DeskTopBanner,
                        ];
                        updatedDeskTopBanner[index].itemUrlType =
                          selectedOption.value;
                        setSingleData({
                          ...SingleData,
                          DeskTopBanner: updatedDeskTopBanner,
                        });
                      }}
                      selectText={"Select Master Image"}
                      disabled={disabled}
                    />
                  </div>
                  <div>
                    <InputTag
                      padding={"8px"}
                      fontSize={"12px"}
                      maxLength={10000}
                      placeholderType={"text"}
                      placeholderText="URL.."
                      value={item.itemUrl}
                      onChange={(e) => {
                        const updatedDeskTopItemUrl = [
                          ...SingleData.DeskTopBanner,
                        ];
                        updatedDeskTopItemUrl[index].itemUrl = e.target.value;
                        setSingleData({
                          ...SingleData,
                          DeskTopBanner: updatedDeskTopItemUrl,
                        });
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
              ))}
              <div style={{ width: "120px", height: "auto" }}>
                <Button
                  buttonText={"ADD"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#fff"}
                  buttonIcon={<AddTO />}
                  disabled={disabled}
                  disabledButtonTextColor={"#fff"}
                  disabledButtonBgColor={"#4164E3"}
                  buttonClick={addNewDeskTopBanner}
                />
              </div>
            </div>
            <div
              className={styles.Col_sm_sub4}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div
                className={styles.text_color}
                style={{ color: mode ? "#fff" : "#647298" }}
              >
                {" "}
                <span> Mobile Banner </span>{" "}
              </div>{" "}
              <div></div>
              {SingleData?.mobItemBanner?.map((item, index) => (
                <div key={`mobItem-${index}`}>
                  <div>
                    <ImageUploder
                      image={item.bannerImg}
                      setimage={(imageUrl) =>
                        handleImageChange("mobItemBanner", index, imageUrl)
                      }
                      options={[
                        { label: "ITEM", value: "item" },
                        { label: "URL", value: "Url" },
                        { label: "N/A", value: "N/A" },
                      ]}
                      selectText={"Select Master Image"}
                      value={item.itemUrlType}
                      setoption={(selectedOption) => {
                        const updatedDeskTopBanner = [
                          ...SingleData.mobItemBanner,
                        ];
                        updatedDeskTopBanner[index].itemUrlType =
                          selectedOption.value;
                        setSingleData({
                          ...SingleData,
                          mobItemBanner: updatedDeskTopBanner,
                        });
                      }}
                      disabled={disabled}
                    />
                  </div>
                  <div>
                    <InputTag
                      padding={"8px"}
                      fontSize={"12px"}
                      maxLength={10000}
                      placeholderType={"text"}
                      placeholderText="URL.."
                      value={item.itemUrl}
                      onChange={(e) => {
                        const updatedDeskTopItemUrl = [
                          ...SingleData.mobItemBanner,
                        ];
                        updatedDeskTopItemUrl[index].itemUrl = e.target.value;
                        setSingleData({
                          ...SingleData,
                          mobItemBanner: updatedDeskTopItemUrl,
                        });
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
              ))}
              <div style={{ width: "120px", height: "auto" }}>
                <Button
                  buttonText={"ADD"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#fff"}
                  buttonIcon={<AddTO />}
                  disabledButtonTextColor={"#fff"}
                  disabledButtonBgColor={"#4164E3"}
                  buttonClick={addNewMobileBanner}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>

          {/* col - 2 */}
          <div
            className={styles.Col_sm1}
            style={{
              backgroundColor: mode ? "#232529" : "#fff",
              color: mode ? "#fff" : "#000",
            }}
          >
            <div>
              <InputTag
                labelText={
                  addTypeString === "addstyle" ? "Style Title" : "Type Title"
                }
                padding={"8px"}
                fontSize={"12px"}
                maxLength={10000}
                placeholderType={"text"}
                // required={true}
                star={true}
                warning={errors.title}
                value={SingleData.title}
                onChange={(e) =>
                  setSingleData({ ...SingleData, title: e.target.value })
                }
                disabled={disabled}
              />
            </div>
            <div>
              <InputTextArea
                // ErrorText={"Character limit 50-160*"}
                lableText={"Product Description (Optional)"}
                value={SingleData.description}
                onChange={(e) =>
                  setSingleData({ ...SingleData, description: e.target.value })
                }
                disabled={disabled}
              // warning={}
              />
            </div>
            <div className={styles.Redio_type_btn_con}>
              <div>
                Status<span style={{ color: "red" }}>*</span>{" "}
              </div>
              <div className={styles.redio_btn_div}>
                <div>
                  <InputRedio
                    lableText={"Yes"}
                    name={"OnlineVisible"}
                    checked={SingleData?.status === "Active"}
                    onChange={() =>
                      setSingleData({ ...SingleData, status: "Active" })
                    }
                    disabled={disabled}
                  />
                </div>
                <div>
                  <InputRedio
                    lableText={"No"}
                    name={"OnlineVisible"}
                    checked={SingleData?.status === "Disable"}
                    onChange={() =>
                      setSingleData({ ...SingleData, status: "Disable" })
                    }
                    disabled={disabled}
                  />
                </div>
                <div className={styles.redio_btn_div_err}>
                  {errors.status && (
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {errors.status}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* col - 3 */}
          <div
            className={styles.Col_sm2}
            style={{
              backgroundColor: mode ? "#232529" : "#fff",
              color: mode ? "#fff" : "#000",
            }}
          >
            <div>
              <InputTag
                labelText={"Meta Title"}
                star={true}
                padding={"8px"}
                fontSize={"12px"}
                maxLength={10000}
                placeholderType={"text"}
                required={true}
                value={SingleData.metaTitle}
                onChange={(e) =>
                  setSingleData({ ...SingleData, metaTitle: e.target.value })
                }
                warning={errors.metaTitle}
                disabled={disabled}
              />
            </div>
            <div>
              <InputTextArea
                // ErrorText={"Meta Description (Optional)"}
                lableText={"Meta Description (Optional)"}
                value={SingleData.metaDescription}
                onChange={(e) =>
                  setSingleData({
                    ...SingleData,
                    metaDescription: e.target.value,
                  })
                }
                disabled={disabled}
              />
            </div>
            <div>
              <KeyWordGen
                lableText={"Keywords"}
                words={SingleData.metaKeywords}
                setWords={(e) =>
                  setSingleData({ ...SingleData, metaKeywords: e })
                }
                disabled={disabled}
              />
            </div>
            <div>
              <KeyWordGen
                lableText={"Focus Keywords"}
                words={SingleData.focusKeyword}
                setWords={(e) =>
                  setSingleData({ ...SingleData, focusKeyword: e })
                }
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        {/* </form> */}
      </div>
    </div>
  );
};

export default AddStyleAndType;
