export const validateForm = (formData, Designation, SelectManger) => {
  const errors = {};

  if (!formData.fullName) errors.fullName = "Full Name is required.";
  else if (formData.fullName.length > 100)
    errors.fullName = "Full Name cannot exceed 100 characters.";

  if (!formData.mobileNumber)
    errors.mobileNumber = "Mobile Number is required.";
  else if (!/^\d{10}$/.test(formData.mobileNumber))
    errors.mobileNumber = "Mobile Number must be exactly 10 digits.";

  if (!formData.storeName) errors.storeName = "Store Name is required.";

  else if (formData.storeName.length > 200)
    errors.storeName = "Store Name cannot exceed 200 characters.";

  if (!Designation) errors.designation = "Designation is required.";

  if (!SelectManger) errors.selectManager = "Assign Order Manager is required.";

  if (!formData.whatsAppNumber)
    errors.whatsAppNumber = "WhatsApp Number is required.";
  else if (!/^\d{10}$/.test(formData.whatsAppNumber))
    errors.whatsAppNumber = "WhatsApp Number must be exactly 10 digits.";

  if (!formData.shippingAddress)
    errors.shippingAddress = "Shipping Address is required.";
  else if (formData.shippingAddress.length > 600)
    errors.shippingAddress = "Shipping Address cannot exceed 600 characters.";

  if (!formData.pin) errors.pin = "PIN Code is required.";
  else if (!/^\d{6}$/.test(formData.pin))
    errors.pin = "PIN Code must be exactly 6 digits.";

  if (!formData.state) errors.state = "State is required.";
  if (!formData.district) errors.district = "District is required.";
  if (!formData.city) errors.city = "City is required.";

  if (formData.gstUin.length > 15)
    errors.gstUin = "GSTUIN cannot exceed 15 characters.";

  return errors;
};

export const validateFormForAddUpdateStyle = (formData) => {
  const errors = {};
  if (!formData.type_name) errors.type_name = "Type Name is required.";
  if (!formData.metaTitle) errors.metaTitle = "Meta Title is required.";
  if (!formData.title) errors.title = "Title is required.";
  if (!formData.status) errors.status = "Status is required.";
  if (!formData || !formData.logo) {
    errors.logo = "Logo is required.";
  } 
  return errors;
};
export const validateFormForAddUpdateItem= (formData ,selectItem ) => {
  const errors = {};

  // if (!formData.type_name) errors.type_name = "Type Name is required.";
  if (!formData.metaTitle) errors.metaTitle = "Meta Title is required.";
  if (!formData.itemNo) errors.itemNo = "Item No is required.";
  // if (!formData.itemColor) errors.itemColor = "Item Color is required.";
  // if (!formData.description) errors.description = "Item Color is required.";
  if (!formData.metaDescription) errors.metaDescription = "Meta Description is required.";
  if (!formData.itemSize) errors.itemSize = "Meta Description is required.";
  if (!formData.title) errors.title = "Title is required.";
  if (!formData.status) errors.status = "Status is required.";
  if (!formData.productimg) errors.productimg = "Product Image is required.";
  if (!formData.closeupimg) errors.closeupimg = "Closeup Image is required.";
  if (!formData.thumbnail) errors.thumbnail = "Thumbnail Image is required.";
  if (!formData.masterimg) errors.masterimg = "Master Image is required.";
  // if (formData.itemAddMoreColor.length === 0) errors.itemAddMoreColor = "Item More Color is required.";
  // if (formData.metaKeywords.length === 0) errors.metaKeywords = "Meta Keywords is required.";
  // if (formData.focusKeyword.length === 0) errors.focusKeyword = "Focus Keywords is required.";
  // if (Object.keys(selectItem.SelectType).length === 0 ) errors.SelectType = "Type is required.";
  // if (Object.keys(selectItem.Selectitems).length === 0 ) errors.Selectitems = "Iems is required.";

  return errors;
};
export const validateFormForAddUpdateCollection= (formData  ) => {
  const errors = {};

  if (!formData.collection_name) errors.collection_name = "Collection Name is required.";
  if (!formData.logo) errors.logo = "Logo is required.";
  if (!formData.metaTitle) errors.metaTitle = "Meta Title is required.";
  if (!formData.title) errors.title = "Meta Title is required.";
  // if (!formData.mrp) errors.mrp = "Base Price is required.";
  if (!formData.status) errors.status = "Status is required.";
  if (!formData.size) errors.size = "Size is required.";
  if (formData.metaKeywords.length === 0) errors.metaKeywords = "Meta Keywords is required.";
  if (formData.focusKeyword.length === 0) errors.focusKeyword = "Focus Keywords is required.";
  return errors;
};
// validate for Add and Update Product  
export const validateForAddUpdateProduct= (formData  ) => {
  const errors = {};

  if (!formData.type_name) errors.type_name = "Product Name is required.";
  if (!formData.logo) errors.logo = "Logo is required.";
  if (!formData.metaTitle) errors.metaTitle = "Meta Title is required.";
  if (!formData.title) errors.title = "Product Title is required.";
  // // if (!formData.mrp) errors.mrp = "Base Price is required.";
  // if (!formData.description) errors.status = "Base Price is description.";
  // if (!formData.metaDescription) errors.status = "Base Price is metaDescription.";

  // if (!formData.status) errors.size = "Base Price is required.";
  // if (!formData.size) errors.size = "Base Price is required.";
  // if (!formData.size) errors.size = "Base Price is required.";
  // if (formData.metaKeywords.length === 0) errors.metaKeywords = "Meta Keywords is required.";
  // if (formData.focusKeyword.length === 0) errors.focusKeyword = "Focus Keywords is required.";
  return errors;
};
