import React, { useEffect, useState } from "react";
import styles from "./Invoices.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import { io } from "socket.io-client";
const UploadInvoice = () => {
  const { mode } = useAuth();
  const { managerId } = useAuth();
  const [searchparams] = useSearchParams();
  const orderId = searchparams.get("orderId");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();

  const orderHeader = [
    "File",
    "Date",
    "Order ID",
    "Amount",
    "Status",
    "Last Action",
  ];
  useEffect(() => {
    if (orderId && managerId) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        orderId: orderId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "invoice/getInvoicePdf", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData(result.data);

            //console.log("uploaded Data", result?.data);

            setLoading(false);
          }
        })
        .catch((error) =>{} )
    }
  }, [orderId, managerId]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_URL);

    socket.on("updateInvoiceData", (updateInvoiceData) => {
      setData((prev) => {
        const newData = Array.isArray(updateInvoiceData)
          ? updateInvoiceData
          : [updateInvoiceData];
        return [...newData, ...prev];
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <React.Fragment>
      <div
        style={{ backgroundColor: mode ? "#232529" : "#D9DCE5" }}
        className={styles.main}
      >
        <div
          className={`${styles.mainCon} ${
            mode ? styles.mainConDark : styles.mainConLight
          }`}
        >
          <div className={styles.order_top}>
            <div className={styles.order_top_left}>
              <TopTabBar text={"Sale Invoice"} />
            </div>
          </div>

          <div className={styles.tablemain}>
            <ul className={styles.order_data_list}>
              <li
                className={`${styles.order_data_list_row_header} ${
                  mode ? styles.order_data_list_row_header_dark : ""
                }`}
              >
                {orderHeader.map((h) => (
                  <div className={styles.entry} key={h}>
                    {h}
                  </div>
                ))}
              </li>
              <div className={styles.order_data_list_container2}>
                {loading ? (
                  <div style={{ margin: "1rem auto" }}>
                    <Spinner height={80} width={80} />
                  </div>
                ) : data?.data?.length === 0 ? (
                  <img
                    src="/assets/updatedAssets/noDATA.png"
                    alt="img"
                    style={{
                      height: "12rem",
                      width: "16rem",
                      margin: "1rem auto",
                    }}
                  />
                ) : (
                  <>
                    {data?.map((d, i) => (
                      <li
                        style={{ cursor: "pointer" }}
                        key={i}
                        className={`${styles.order_data_list_row2} ${
                          mode ? styles.order_data_list_row_dark : ""
                        }`}
                        onClick={() =>
                          window.open(
                            d.invoice &&
                              process.env.REACT_APP_S3URL + d.invoice,
                            "mozillaWindow",
                            "popup"
                          )
                        }
                      >
                        <div className={styles.entry}>{d.invoice?.slice(0, 20)}</div>

                        <div className={styles.entry}>{d.invoiceDate}</div>
                        <div className={styles.entry}>{d.orderId}</div>
                        <div className={styles.entry}>{d.amount}</div>
                        <div className={styles.entry}>{d.status}</div>
                        <div className={styles.entry}>{d.lastAction}</div>
                      </li>
                    ))}
                  </>
                )}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadInvoice;

