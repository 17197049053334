import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import styles from "./Apps.module.css";
import TopTabBar from "../Tanolet/TopTabBar";
import Button from "../Dealers/Button";
import { ManagerCameraIcon, SaveAsDraftIcon } from "../../icons/Icon";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import InputTag from "../FormComponents/InputTag";
import VersionHistory from "./VersionHistory";
import { Upload } from "../../icons/Icon2";
import APKUpload from "./APKUpload";
import MultipleImageUpload from "./MultipleImageUpload";
import { useToast } from "../Toaster/Toaster";
import fetchData from "../../../APIsControll/apiControll";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import ManagerModal from "../Manager/ManagerModal";
import InputRedio from "../FormComponents/InputRedio";

const CreateApp = () => {
  const { mode, managerId } = useAuth();
  const { show } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const app_name = location.state?.app_name;
  const fileInputRef = useRef();
  const [openIndex, setOpenIndex] = useState(null);
  const [apkFile, setApkFile] = useState(null);
  const [images, setImages] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(
    "/assets/updatedAssets/androidIcon.png"
  );
  const [appData, setAppData] = useState({
    logo: null,
    name: "",
    shortDesc: "",
    websiteUrl: "",
    desc: "",
    apkFile: apkFile,
    version: "",
    releaseDate: "",
    changelog: "",
    images: images,
    visibility: "private",
  });
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imagesChanged, setImagesChanged] = useState(false);

  const uploadFile = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const uploadFiles = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAppData((prev) => ({ ...prev, logo: file }));
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
    }
  };

  const verifyEntries = () => {
    if (
      !appData.name.trim() ||
      !appData.shortDesc.trim() ||
      !appData.desc.trim() ||
      !appData.websiteUrl.trim() ||
      !appData.version.trim() ||
      !appData.releaseDate.trim() ||
      !appData.changelog.trim()
    ) {
      show("All fields are required.", "warning");
      return false;
    }

    if (appData.logo === null) {
      show("Please upload a logo.", "warning");
      return false;
    }

    if (!appData.apkFile) {
      show("Please upload an APK file.", "warning");
      return false;
    }

    if (images.length < 4) {
      show("Please upload at least four images.", "warning");
      return false;
    }

    return true;
  };

  const createApp = async () => {
    setProgress(0);
    setLoading(true);

    try {
      const formData = new FormData();

      formData.append("managerId", managerId);
      formData.append("name", appData.name);
      formData.append("shortDesc", appData.shortDesc);
      formData.append("desc", appData.desc);
      formData.append("websiteUrl", appData.websiteUrl);
      formData.append("version", appData.version);
      formData.append("releaseDate", appData.releaseDate);
      formData.append("changelog", appData.changelog);
      formData.append("visibility", appData.visibility);

      if (appData.apkFile) formData.append("apkFile", appData.apkFile);
      if (appData.logo) formData.append("logo", appData.logo);
      images.forEach((image) => formData.append("images", image));

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", `${process.env.REACT_APP_URL}apps/createApp`, true);
        xhr.setRequestHeader("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentCompleted = Math.round(
              (event.loaded / event.total) * 100
            );
            setProgress(percentCompleted);
          }
        };

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            const result = JSON.parse(xhr.responseText);
            if (result.status === "success") {
              show(result.msg, "success");
              navigate("/apps");
            }

            if (result.status === "warning") {
              show(result.msg, "warning");
            }

            if (result.status === "failed") {
              show(result.msg, "error");
            }
            setProgress(100);
            setTimeout(() => setLoading(false), 0);
            resolve(result);
          } else {
            setTimeout(() => setLoading(false), 0);
            // reject(new Error(`Failed with status ${xhr.status}`));
          }
        };

        xhr.onerror = () => {
          setTimeout(() => setLoading(false), 0);
          // reject(new Error("Network error"));
        };

        xhr.send(formData);
      });
    } catch (error) {
      console.error("Catch error:", error);
      setTimeout(() => setLoading(false), 0);
      setProgress(0);
    }
  };

  const updateApp = async () => {
    setProgress(0);
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("managerId", managerId);
      formData.append("name", appData.name);
      formData.append("shortDesc", appData.shortDesc);
      formData.append("desc", appData.desc);
      formData.append("websiteUrl", appData.websiteUrl);
      formData.append("version", appData.version);
      formData.append("releaseDate", appData.releaseDate);
      formData.append("changelog", appData.changelog);
      formData.append("visibility", appData.visibility);

      formData.append("removedImages", JSON.stringify(removedImages || []));

      if (appData.apkFile) formData.append("apkFile", appData.apkFile);
      if (appData.logo) formData.append("logo", appData.logo);
      images.forEach((image) => formData.append("images", image));

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", `${process.env.REACT_APP_URL}apps/updateApp`, true);
        xhr.setRequestHeader("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentCompleted = Math.round(
              (event.loaded / event.total) * 100
            );
            setProgress(percentCompleted);
          }
        };

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            const result = JSON.parse(xhr.responseText);
            if (result.status === "success") {
              show(result.msg, "success");
              navigate("/apps");
            }

            if (result.status === "warning") {
              show(result.msg, "warning");
            }

            if (result.status === "failed") {
              show(result.msg, "error");
            }
            setProgress(100);
            setLoading(false);
            resolve(result);
          } else {
            setLoading(false);
            // reject(new Error(`Failed with status ${xhr.status}`));
          }
        };

        xhr.onerror = () => {
          setLoading(false);
          // reject(new Error("Network error"));
        };

        xhr.send(formData);
      });
    } catch (error) {
      console.error("Catch error:", error);
      setLoading(false);
      setProgress(0);
    }
  };

  const handleSubmit = async () => {
    if (app_name) {
      await updateApp();
    } else {
      await createApp();
    }
  };

  useEffect(() => {
    const getApp = async () => {
      const apiData = { managerId: managerId, name: app_name };
      try {
        const result = await fetchData("apps/getApp", apiData);
        setAppData(result.app);
      } catch (error) {}
    };
    if (app_name) {
      getApp();
    }
  }, [app_name, managerId]);

  useLayoutEffect(() => {
    if (app_name && !previewImage.startsWith("blob:")) {
      setApkFile(appData.apkUrl);
      setImages(appData.images);

      if (appData.logo) {
        setPreviewImage(process.env.REACT_APP_S3URL + appData.logo);
      }
    }
  }, [appData.apkUrl, appData.images, appData.logo, app_name, previewImage]);

  useEffect(() => {
    if (!imagesChanged) {
      setAppData((prev) => ({
        ...prev,
        apkFile: apkFile,
        images: images,
      }));
    }
    if (isModalOpen) {
      setAppData((prev) => ({
        ...prev,
        apkFile: apkFile,
      }));
    }
  }, [apkFile, images, imagesChanged, isModalOpen]);

  return (
    <div
      className={styles.apps}
      style={mode ? { backgroundColor: "#2C2E33", borderColor: "#2C2E33" } : {}}
    >
      {progress > 0 && progress < 100 && loading ? (
        <div className={styles.progress}>
          <p style={mode ? { color: "#FFFFFF" } : {}}>Uploading!</p>
          <div className={styles.progress_bar}>
            <div
              className={styles.progress_done}
              data-done={progress}
              style={{
                width: `${progress}%`,
                opacity: 1,
                transition: "width 0.5s ease-in-out",
              }}
            >
              {progress > 5 && `${progress}%`}
            </div>
          </div>
        </div>
      ) : progress === 100 && loading ? (
        <div className={`${styles.progress} ${styles.loader_con}`}>
          <span
            style={mode ? { color: "#FFFFFF" } : {}}
            class={styles.loader}
          ></span>
          <span style={mode ? { color: "#FFFFFF" } : {}}>
            Saving Application!
          </span>
        </div>
      ) : (
        <>
          <ModalWrapper
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            noBtn
          >
            <ManagerModal
              heading={`${app_name ? "Update" : "Create"} App`}
              centerHeading={`Are you sure you want to ${
                app_name ? "Update" : "Create"
              } ?`}
              subHeading={`To confirm! type ${
                app_name ? "update" : "create"
              } in the text field.`}
              targetWord={app_name ? "update" : "create"}
              handleSubmit={() => {
                handleSubmit();
              }}
              handleCloseModal={() => setIsModalOpen(false)}
              focus={isModalOpen}
            />
          </ModalWrapper>

          <div className={styles.apps_top}>
            <div>
              <TopTabBar
                truncate={false}
                text={app_name ? "Apps / Edit / " + app_name : "Apps / Create "}
              />
            </div>
            <div>
              <Button
                buttonText={app_name ? "Update" : "Save"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#FFFFFF"}
                buttonIcon={app_name ? <Upload /> : <SaveAsDraftIcon />}
                buttonClick={() => {
                  if (!verifyEntries()) return;
                  setIsModalOpen(true);
                }}
              />
            </div>
          </div>

          <div
            className={styles.c_app_content}
            style={mode ? { backgroundColor: "#232529" } : {}}
          >
            <div
              className={styles.img_div}
              onClick={() => fileInputRef.current.click()}
              style={{
                position: "relative",
                backgroundColor: mode && "#1B1D21",
                borderColor: mode && "#1B1D21",
              }}
            >
              <img
                src={
                  typeof previewImage === "string"
                    ? previewImage
                    : URL.createObjectURL(previewImage)
                }
                alt="Preview"
              />
              <div className={styles.camera_icon}>
                <ManagerCameraIcon />
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleLogoChange}
                accept="image/*"
              />
            </div>

            <div className={styles.c_app_form}>
              <div className={styles.form_left}>
                <InputTag
                  labelText={"Application Heading"}
                  placeholderText={""}
                  placeholderType={"text"}
                  padding={"8px"}
                  fontSize={"12px"}
                  required
                  star
                  maxLength={20}
                  value={appData.name}
                  readOnly={app_name}
                  onChange={(e) =>
                    setAppData((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
                <InputTag
                  labelText={"Sub Heading"}
                  placeholderText={""}
                  placeholderType={"text"}
                  padding={"8px"}
                  fontSize={"12px"}
                  required
                  star
                  maxLength={100}
                  value={appData.shortDesc}
                  onChange={(e) =>
                    setAppData((prev) => ({
                      ...prev,
                      shortDesc: e.target.value,
                    }))
                  }
                />
                <InputTag
                  labelText={"Website"}
                  placeholderText={""}
                  placeholderType={"text"}
                  padding={"8px"}
                  fontSize={"12px"}
                  required
                  star
                  maxLength={100}
                  value={appData.websiteUrl}
                  onChange={(e) =>
                    setAppData((prev) => ({
                      ...prev,
                      websiteUrl: e.target.value,
                    }))
                  }
                />
                <div>
                  <p
                    className={styles.txt}
                    style={mode ? { color: "#FFFFFF" } : {}}
                  >
                    Description<span>*</span>
                  </p>
                  <textarea
                    name=""
                    id=""
                    placeholder="Heading - Content"
                    required
                    rows="4"
                    className={`${styles.textarea} ${
                      mode ? styles.textarea_dark : ""
                    }`}
                    value={appData.desc}
                    onChange={(e) =>
                      setAppData((prev) => ({
                        ...prev,
                        desc: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
                <div>
                  <p
                    className={styles.txt}
                    style={mode ? { color: "#FFFFFF" } : {}}
                  >
                    Upload Application<span>*</span>
                  </p>
                  <APKUpload
                    mode={mode}
                    apkFile={apkFile}
                    setApkFile={setApkFile}
                    uploadFile={uploadFile}
                  />
                </div>
                <div className={styles.form_left_row}>
                  <InputTag
                    labelText={"Version Name"}
                    placeholderText={""}
                    placeholderType={"text"}
                    padding={"8px"}
                    fontSize={"12px"}
                    required
                    star
                    maxLength={20}
                    value={appData.version}
                    onChange={(e) =>
                      setAppData((prev) => ({
                        ...prev,
                        version: e.target.value,
                      }))
                    }
                  />
                  <InputTag
                    labelText={"Date"}
                    placeholderText={""}
                    placeholderType={"date"}
                    padding={"8px"}
                    fontSize={"12px"}
                    required
                    star
                    maxLength={20}
                    value={
                      app_name
                        ? appData.releaseDate.split("T")[0]
                        : appData.releaseDate
                    }
                    onChange={(e) =>
                      setAppData((prev) => ({
                        ...prev,
                        releaseDate: e.target.value,
                      }))
                    }
                  />
                </div>
                <InputTag
                  labelText={"Version Info"}
                  placeholderText={""}
                  placeholderType={"text"}
                  padding={"8px"}
                  fontSize={"12px"}
                  required
                  star
                  maxLength={100}
                  value={appData.changelog}
                  onChange={(e) =>
                    setAppData((prev) => ({
                      ...prev,
                      changelog: e.target.value,
                    }))
                  }
                />
              </div>
              <div className={styles.form_right}>
                <div className={styles.radio_options}>
                  <p
                    className={styles.txt}
                    style={mode ? { color: "#FFFFFF" } : {}}
                  >
                    Public<span>*</span>
                  </p>
                  <div>
                    <InputRedio
                      lableText="Public"
                      name="public"
                      id="public"
                      value="public"
                      checked={appData.visibility === "public"}
                      onChange={() =>
                        setAppData((prev) => ({
                          ...prev,
                          visibility: "public",
                        }))
                      }
                    />
                    <InputRedio
                      lableText="Private"
                      name="private"
                      id="private"
                      value="private"
                      checked={appData.visibility === "private"}
                      onChange={() =>
                        setAppData((prev) => ({
                          ...prev,
                          visibility: "private",
                        }))
                      }
                    />
                    <InputRedio
                      lableText="Unlisted"
                      name="unlisted"
                      id="unlisted"
                      value="unlisted"
                      checked={appData.visibility === "unlisted"}
                      onChange={() =>
                        setAppData((prev) => ({
                          ...prev,
                          visibility: "unlisted",
                        }))
                      }
                    />
                  </div>
                </div>

                <p
                  className={styles.txt}
                  style={mode ? { color: "#FFFFFF" } : {}}
                >
                  Upload Images<span>*</span>
                </p>
                <MultipleImageUpload
                  mode={mode}
                  uploadFiles={uploadFiles}
                  images={images}
                  setImages={setImages}
                  removedImages={removedImages}
                  setRemovedImages={setRemovedImages}
                  onChange={() => {
                    setImagesChanged(true);
                  }}
                />
                {app_name && (
                  <div className={styles.version_history}>
                    <p style={mode ? { color: "#FFFFFF" } : {}}>
                      Version History
                    </p>
                    {appData?.versionHistory?.map((data, index) => (
                      <VersionHistory
                        mode={mode}
                        key={index}
                        version={data.version}
                        date={data.releaseDate}
                        content={data.changelog}
                        apkUrl={data.apkUrl}
                        apkSize={data.apkSize}
                        latest={data.version === appData.version}
                        isOpen={openIndex === index}
                        onToggle={() => handleToggle(index)}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateApp;
