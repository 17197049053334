import { useAuth } from "../../context/Auth";
import { NavbarArrowDownDarkIcon, NavbarArrowDownIcon } from "../../icons/Icon";
import styles from "./FromComponent.module.css";
import { useState, useEffect, useRef } from "react";

const SelectBox = ({ label, option, dropDownText, star, onSelect, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);
  const { mode } = useAuth();

  // //console.log(selectedOption);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option); // Call the onSelect function with the selected option
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    if (value) {
      setSelectedOption(value);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, value]);
  return (
    <div className={styles.vertical_dropdown_container} ref={dropdownRef}>
      <label
        className={styles.vertical_dropdown_label}
        style={mode ? { color: "#fff" } : {}}
      >
        {label}
        {star && <span style={{ color: "red" }}>*</span>}
      </label>
      <div
        className={styles.vertical_custom_select}
        style={
          mode ? { border: "1px solid #333" } : { border: "1px solid #E4E7EB" }
        }
      >
        <div
          className={styles.vertical_dropdown_trigger}
          style={mode ? { backgroundColor: "#1B1D21", border: "none" } : {}}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={styles.vertical_dropdown_placeholder}
            style={{ color: mode ? "#fff" : "#646b88" }}
          >
            {value ? (
              value.label || value
            ) : (
              <>
                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                  {dropDownText}
                </span>
                {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />}
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <ul
            className={styles.vertical_dropdown_list}
            style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
          >
            {option.map((option, index) => (
              <li
                key={index}
                className={`${styles.vertical_dropdown_item} ${
                  mode ? styles.vertical_dropdown_item_dark : ""
                }`}
                onClick={() => handleOptionSelect(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectBox;
