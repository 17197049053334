// import React, { useEffect, useState } from 'react'
// import { useNavigate, useSearchParams } from 'react-router-dom'
// import { GreenButton, RedOutButton } from '../../components/Buttons'
// import { DeleteFile, UpLoadFile } from '../../components/icons/Icon'
// import styles from './Product.module.css'

// const AddCollection = () => {
//     const [searchParams] = useSearchParams();
//     // const step = searchParams.get("step");
//     const getId = searchParams.get("id");
//     const pId = searchParams.get("product_id");
//     const id = (!getId || getId === null) ? "" : getId
//     const product_id = (!pId || pId === null) ? "" : pId

//     const navigate = useNavigate()
//     const product_name = window.location.pathname.split("/")[window.location.pathname.split("/")?.length - 1]

//     const [loading, setLoading] = useState(false)

//     const [addData, setAddData] = useState({
//         collection_name: "",
//         uploadLogo: "",
//         metaTitle: "",
//         title: "",
//         description: "",
//         mrp: "",
//         url: "",
//         size: "",
//         metaKeywords: "",
//         focusKeyword: "",
//         metaDescription: "",
//         status: "",
//         type_name: []
//     })

//     // ---------------------------Desktop Banner------------------------------------
//     const [banner, setBanner] = useState([{
//         bannerImg: "",
//         itemUrl: "",
//         itemUrlType: ""
//     }])
//     const [bannerUpdate, setBannerUpdate] = useState([])
//     const addFields = () => {
//         let newfield = { bannerImg: '', itemUrl: '', itemUrlType: '' }
//         setBanner([...banner, newfield])
//     }
//     const handleFileChange = (index, event) => {
//         let data = [...banner];
//         data[index][event.target.name] = event.target.files[0];
//         setBanner(data);
//     }
//     const handleFormChange = (index, event) => {
//         let data = [...banner];
//         data[index][event.target.name] = event.target.value;
//         setBanner(data);
//     }
//     const removeFormFields = (i) => {
//         const values = [...banner];
//         values.splice(i, 1);
//         setBanner(values);
//     }

//     // ---------------------------Mobile Banner------------------------------------
//     const [mobBanner, setMobBanner] = useState([{
//         bannerImg: "",
//         itemUrl: "",
//         itemUrlType: ""
//     }])
//     const [mobBannerUpdate, setMobBannerUpdate] = useState([])

//     const addMobBanner = () => {
//         let newfield = { bannerImg: '', itemUrl: '', itemUrlType: '' }
//         setMobBanner([...mobBanner, newfield])
//     }
//     const handleMobFileChange = (index, event) => {
//         let data = [...mobBanner];
//         data[index][event.target.name] = event.target.files[0];
//         setMobBanner(data);
//     }
//     const handleMobFormChange = (index, event) => {
//         let data = [...mobBanner];
//         data[index][event.target.name] = event.target.value;
//         setMobBanner(data);
//     }
//     const removeMobFormFields = (i) => {
//         const values = [...mobBanner];
//         values.splice(i, 1);
//         setMobBanner(values);
//     }
//     // ---------------------------Types------------------------------------
//     const [fields, setFields] = useState([{}])

//     const addTypes = () => {
//         let newfield = ''
//         setFields([...fields, newfield])
//     }
//     const handleTypeChange = (index, event) => {
//         let data = [...fields];
//         data[index] = event.target.value;
//         setFields(data);
//     }
//     const removeType = (i) => {
//         const values = [...fields];
//         values.splice(i, 1);
//         setFields(values);
//     }
//     // ---------------------------Accessories------------------------------------
//     const [accessories, setAccessories] = useState([{}])

//     const addAccessories = () => {
//         let newfield = ''
//         setAccessories([...accessories, newfield])
//     }
//     const handleAccessoryChange = (index, event) => {
//         let data = [...accessories];
//         data[index] = event.target.value;
//         setAccessories(data);
//     }
//     const removeAccessory = (i) => {
//         const values = [...accessories];
//         values.splice(i, 1);
//         setAccessories(values);
//     }

//     // ---------------------------Meta Keywords----------------------
//     const [input, setInput] = useState('');
//     const [tags, setTags] = useState([]);
//     const [isKeyReleased, setIsKeyReleased] = useState(false);

//     const onKeyDown = (e) => {
//         const { key } = e;
//         const trimmedInput = input.trim();

//         if ((key === ',' || key === "Enter") && trimmedInput?.length && !tags.includes(trimmedInput)) {
//             e.preventDefault();
//             setTags(prevState => [...prevState, trimmedInput]);
//             setInput('');
//         }

//         if (key === "Backspace" && !input?.length && tags?.length && isKeyReleased) {
//             const tagsCopy = [...tags];
//             const poppedTag = tagsCopy.pop();
//             e.preventDefault();
//             setTags(tagsCopy);
//             setInput(poppedTag);
//         }

//         setIsKeyReleased(false);
//     };

//     const onKeyUp = () => {
//         setIsKeyReleased(true);
//     }

//     const deleteTag = (index) => {
//         setTags(prevState => prevState.filter((tag, i) => i !== index))
//     }

//     const onChange = (e) => {
//         const { value } = e.target;
//         setInput(value);
//     };

//     // Ravi Changes
//     const [tages2, setTages2] = useState([])
//     useEffect(() => {
//         const trimmedInput = input.trim();
//         if (input.includes(",")) {
//             setTages2(trimmedInput.split(','))
//         }
//     }, [input])

//     useEffect(() => {
//         if (tages2) {
//             setTags(tages2)
//             setInput("")
//         }
//     }, [tages2])
//     // Ravi Changes

//     // ---------------------------Get Style By Id----------------------
//     useEffect(() => {
//         var myHeaders = new Headers();
//         myHeaders.append("Content-Type", "application/json");
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//         var raw = JSON.stringify({
//             "_id": id,
//             "product_id": product_id,
//             // "product_name": product_name.replace(/-/g, " ")
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "content/collection", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === 'success') {
//                     if (result.data) {
//                         setAddData(prevState => ({
//                             ...prevState,
//                             collection_name: result.data.collection_name ? result.data.collection_name : "",
//                             uploadLogo: result.data.logo ? result.data.logo : "",
//                             metaTitle: result.data.metaTitle ? result.data.metaTitle : "",
//                             title: result.data.title ? result.data.title : "",
//                             description: result.data.description ? result.data.description : "",
//                             url: result.data.url ? result.data.url : "",
//                             mrp: result.data.mrp ? result.data.mrp : "",
//                             metaKeywords: result.data.metaKeywords ? result.data.metaKeywords : "",
//                             focusKeyword: result.data.focusKeyword ? result.data.focusKeyword : "",
//                             metaDescription: result.data.metaDescription ? result.data.metaDescription : "",
//                             status: result.data.status ? result.data.status : "",
//                             size: result.data.size ? result.data.size : "",
//                         }))
//                         if (result.data.metaKeywords?.length === 1) {
//                             setTags(result.data.metaKeywords[0].split(','))
//                         } else {
//                             setTags(result.data.metaKeywords)
//                         }
//                         setFields(result.data.type_name)
//                         setBanner(result.data.banner)
//                         setMobBanner(result.data.mobBanner?.length > 0 ? result.data.mobBanner : [{}])
//                         setAccessories(result.data.accessories)
//                     }
//                 }
//                 setAddData(prevState => ({
//                     ...prevState,
//                     types: result.types ? result.types : [],
//                 }))
//             })
//             .catch(error => //console.log('error', error));
//     }, [id, product_id,])

//     // ----------------------------Add Update Product-------------------------
//     //console.log("accessories", accessories);
//     const handleAddUpdate = (e) => {
//         e.preventDefault()
//         setLoading(true)
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         var formdata = new FormData();
//         formdata.append("product_name", product_name);
//         product_id && formdata.append("product_id", product_id);
//         formdata.append("collection_name", addData.collection_name);
//         formdata.append("logo", addData.uploadLogo);
//         formdata.append("metaTitle", addData.metaTitle);
//         formdata.append("metaKeywords", JSON.stringify(tags));
//         formdata.append("focusKeyword", addData.focusKeyword);
//         formdata.append("url", addData.url);
//         formdata.append("mrp", addData.mrp);
//         formdata.append("title", addData.title);
//         formdata.append("description", addData.description);
//         formdata.append("metaDescription", addData.metaDescription);
//         formdata.append("status", addData.status);
//         formdata.append("size", addData.size);
//         formdata.append("type_name", JSON.stringify(fields));
//         banner.map(item =>
//             <>
//                 {(formdata.append("bannerImg", item.bannerImg),
//                     formdata.append("itemUrl", item.itemUrl),
//                     formdata.append("itemUrlType", item.itemUrlType))}
//             </>
//         )
//         mobBanner.map(item =>
//             <>
//                 {(formdata.append("mobBannerImg", item.bannerImg),
//                     formdata.append("mobItemUrl", item.itemUrl),
//                     formdata.append("mobItemUrlType", item.itemUrlType))}
//             </>
//         )
//         formdata.append("bannerUpdate", bannerUpdate);
//         formdata.append("mobBannerUpdate", mobBannerUpdate);
//         id && formdata.append("_id", id);
//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: formdata,
//             redirect: 'follow'
//         };
//         fetch(process.env.REACT_APP_URL + "content/addUpdateCollection", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     setLoading(false)
//                     navigate(-1)
//                 } else {
//                     setLoading(false)
//                 }
//             })
//             .catch(error => //console.log('error', error));
//     }

//     //console.log(accessories)

//     return (
//         <React.Fragment>
//             <h1 className={styles.text_center}>{id ? "Update" : "Add"} Collection</h1>
//             <form onSubmit={handleAddUpdate} className={styles.addSTC}>
//                 <div className={styles.metaRow}>
//                     <div className={styles.meta}>Meta URL</div>
//                     <div className={styles.url}>
//                         {process.env.REACT_APP_GLOBALURL}
//                         <input type={"text"}
//                             name='url'
//                             value={addData.url}
//                             onChange={(e) => setAddData({ ...addData, url: e.target.value })}
//                             placeholder="Enter Url"
//                             className={styles.add_url_input} />
//                         {process.env.REACT_APP_GLOBALURL?.length + addData.url?.length}
//                     </div>
//                 </div>
//                 <div className={styles.addProductRow}>
//                     <div className={styles.addProductCol}>
//                         <div className={styles.addProductColP}>
//                             <div className={styles.addProductRow}>
//                                 <div className={styles.addProductCol3}>Collection Name</div>
//                                 <div className={styles.addProductCol9}>
//                                     <input
//                                         type="text"
//                                         name='collection_name'
//                                         value={addData.collection_name}
//                                         onChange={(e) => setAddData({ ...addData, collection_name: e.target.value })}
//                                         placeholder="Enter Collection Name"
//                                         className={styles.add_product_input}
//                                         required
//                                     />
//                                 </div>
//                             </div>
//                             {banner && banner.map((item, index) =>
//                             (
//                                 <div className={styles.addBannerRow} key={index}>
//                                     <div className={styles.addProductCol3}>{index + 1}. Desktop Banner :</div>
//                                     <div className={styles.addBannerCol9}>
//                                         <img src={
//                                             item.bannerImg && item.bannerImg.name ?
//                                                 URL.createObjectURL(item.bannerImg)
//                                                 : item.bannerImg ? process.env.REACT_APP_S3URL + item.bannerImg
//                                                     : "/assets/img.png"
//                                         }
//                                             alt="img" height={39} width={66} className={styles.bannerImg} />
//                                         <label className={styles.bannerAddBtn}>
//                                             <UpLoadFile />
//                                             Upload Image
//                                             <input
//                                                 name="bannerImg"
//                                                 type="file"
//                                                 className={styles.bannerImgInput}
//                                                 id="banner"
//                                                 onChange={(e) => {
//                                                     handleFileChange(index, e);
//                                                     if (item.bannerImg.name && !bannerUpdate.includes(item._id) && item._id) {
//                                                         setBannerUpdate(prevState => [...prevState, item._id])
//                                                     }
//                                                 }}
//                                                 accept="image/*"
//                                             />
//                                         </label>
//                                         <input
//                                             type="text"
//                                             placeholder="Enter URL"
//                                             className={styles.bannerUrlInput}
//                                             name="itemUrl"
//                                             value={item.itemUrl}
//                                             onChange={(e) => handleFormChange(index, e)}
//                                         />
//                                         <select
//                                             className={styles.bannerSelect}
//                                             name="itemUrlType"
//                                             value={item.itemUrlType}
//                                             onChange={(e) => handleFormChange(index, e)}
//                                         >
//                                             <option className={styles.bg_dark}>Select</option>
//                                             <option className={styles.bg_dark} value="item">Item</option>
//                                             <option className={styles.bg_dark} value="url">Url</option>
//                                             <option className={styles.bg_dark} value="N/A">N/A</option>
//                                         </select>
//                                         <div className={styles.bannerDeleteBtn}
//                                             onClick={() => {
//                                                 removeFormFields(index);
//                                                 if (!bannerUpdate.includes(item._id) && item._id) {
//                                                     setBannerUpdate(prevState => [...prevState, item._id])
//                                                 }
//                                             }} >
//                                             <DeleteFile color="red" /><br />
//                                             Delete
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))}
//                             <div role="button" className={styles.addMoreBtn} onClick={() => { addFields() }} >
//                                 + Add Desktop Banner
//                             </div>
//                             <div className={styles.addBannerRow}>
//                                 <div className={styles.addProductCol3}>Product Title</div>
//                                 <div className={styles.addProductCol9}>
//                                     <textarea
//                                         name='title'
//                                         value={addData.title}
//                                         onChange={(e) => setAddData({ ...addData, title: e.target.value })}
//                                         placeholder="Enter Title"
//                                         className={styles.add_product_input}
//                                         rows={3} />
//                                 </div>
//                             </div>
//                             <div className={styles.addBannerRow}>
//                                 <div className={styles.addProductCol3}>product Description <br /> (optional)</div>
//                                 <div className={styles.addProductCol9}>
//                                     <textarea
//                                         name="description"
//                                         value={addData.description}
//                                         onChange={(e) => setAddData({ ...addData, description: e.target.value })}
//                                         placeholder="Enter Description"
//                                         className={styles.add_product_input}
//                                         rows={5} />
//                                 </div>
//                             </div>
//                             <div className={styles.addBannerRow}>
//                                 <div className={styles.addProductCol3}>Size</div>
//                                 <div className={styles.addProductCol9}>
//                                     <input type="text"
//                                         name='size'
//                                         value={addData.size}
//                                         onChange={(e) => setAddData({ ...addData, size: e.target.value })}
//                                         placeholder="Enter Size"
//                                         className={styles.add_product_input} />
//                                 </div>
//                             </div>

//                             {/* --------------------------------Types------------------------- */}
//                             {fields && fields.map((item, index) =>
//                             (<div className={styles.addBannerRow} key={index} >
//                                 <div className={styles.addProductCol3}>
//                                     <label className={index === 0 ? "m-0" : "d-none"} >
//                                         Select Type
//                                     </label>
//                                 </div>
//                                 <div className={styles.addBannerCol9 + " d-flex justify-content-between"}>
//                                     <select
//                                         name="type_name"
//                                         className={styles.add_product_input + " w-75"}
//                                         value={item}
//                                         onChange={(e) => { handleTypeChange(index, e); }}
//                                     >
//                                         <option className={styles.bg_dark} value="">Select Type</option>
//                                         {Object.keys(item)?.length === 0 ? "" : <option>{item}</option>}
//                                         {
//                                             addData.types && addData.types.filter(x => !fields.some(y => x.type_name === y)).map((obj, i) => <option className={styles.bg_dark} key={i} value={obj.type_name}>{obj.type_name}</option>)
//                                         }
//                                     </select>
//                                     <div className={styles.bannerDeleteBtn} onClick={(e) => removeType(index, e)}>
//                                         <DeleteFile color="red" /><br />
//                                         Delete
//                                     </div>
//                                 </div>

//                             </div>
//                             ))}
//                             <div role="button" className={styles.addMoreBtn} onClick={() => { addTypes() }} >
//                                 + Add More Types
//                             </div>

//                             {/* --------------------------------Accessories------------------------- */}
//                             {accessories && accessories.map((item, index) => {

//                                 return (
//                                     <div className={styles.addBannerRow} key={index} >
//                                         <div className={styles.addProductCol3}>{index + 1}. Accessories :</div>
//                                         <div className={styles.addProductCol9 + " d-flex justify-content-between"}>
//                                             <img src={
//                                                 item.accessoriesImg && item.accessoriesImg.name ?
//                                                     URL.createObjectURL(item.accessoriesImg)
//                                                     : item.accessoriesImg ? process.env.REACT_APP_S3URL + item.accessoriesImg
//                                                         : "/assets/img.png"
//                                             }
//                                                 alt="img" height={39} width={66} className={styles.bannerImg} />
//                                             <label className={styles.bannerAddBtn}>
//                                                 <UpLoadFile />
//                                                 Upload Image
//                                                 <input
//                                                     name="accessoriesImg"
//                                                     type="file"
//                                                     className={styles.bannerImgInput}
//                                                     id="banner"
//                                                     onChange={(e) => {
//                                                         let data = [...accessories];
//                                                         data[index][e.target.name] = e.target.files[0];
//                                                         setAccessories(data);
//                                                     }}
//                                                     accept="image/*"
//                                                 />
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 placeholder="Enter Accessories Name"
//                                                 className={styles.bannerUrlInput + " w-auto"}
//                                                 name="itemUrl"
//                                                 value={item.accessoriesName}
//                                                 onChange={(e) => handleAccessoryChange(index, e)}
//                                             />

//                                             <div role="button" className={styles.bannerDeleteBtn} onClick={(e) => removeAccessory(index, e)}>
//                                                 <DeleteFile color="red" /><br />
//                                                 Delete
//                                             </div>

//                                         </div>

//                                     </div>

//                                 )
//                             })}
//                             <div role="button" className={styles.addMoreBtn} onClick={() => { addAccessories() }} >
//                                 + Add More Accessories
//                             </div>

//                             <div className={styles.addBannerRow}>
//                                 <div className={styles.addProductCol3}>Status</div>
//                                 <div className={styles.addProductCol9}>
//                                     <label>
//                                         <input
//                                             type="radio"
//                                             name="status"
//                                             value="Active"
//                                             onChange={(e) => setAddData({ ...addData, status: e.target.value })}
//                                             checked={addData.status === "Active" ? true : false}
//                                         />Active
//                                     </label>
//                                     <label>
//                                         <input
//                                             type="radio"
//                                             name="status"
//                                             value="Disable"
//                                             onChange={(e) => setAddData({ ...addData, status: e.target.value })}
//                                             checked={addData.status === "Disable" ? true : false}
//                                         />Disable
//                                     </label>
//                                 </div>
//                             </div>

//                         </div>
//                     </div>
//                     <div className={styles.addProductCol}>
//                         <div className={styles.addProductColP}>
//                             <div className={styles.addProductRow}>
//                                 <div className={styles.addProductCol3}>Upload Logo :</div>
//                                 <div className={styles.addBannerCol9}>
//                                     <img src={
//                                         addData.uploadLogo && addData.uploadLogo.name ?
//                                             URL.createObjectURL(addData.uploadLogo)
//                                             : addData.uploadLogo ? process.env.REACT_APP_S3URL + addData.uploadLogo
//                                                 : "/assets/img.png"
//                                     }
//                                         alt="img" height={39} width={66} className={styles.bannerImg}
//                                     />
//                                     <label className={styles.bannerAddBtn}>
//                                         <UpLoadFile />
//                                         Upload Image
//                                         <input
//                                             type="file"
//                                             accept="image/*"
//                                             className={styles.d_none}
//                                             name="uploadLogo"
//                                             onChange={(e) => setAddData({ ...addData, uploadLogo: e.target.files[0] })}
//                                         />
//                                     </label>

//                                     <div className={styles.bannerDeleteBtn}
//                                         onClick={() => { setAddData({ ...addData, uploadLogo: "" }) }}
//                                     >
//                                         <DeleteFile color="red" /><br />
//                                         Delete
//                                     </div>
//                                 </div>
//                             </div>
//                             {mobBanner && mobBanner.map((item, index) =>
//                             (
//                                 <div className={styles.addBannerRow} key={index}>
//                                     <div className={styles.addProductCol3}>{index + 1}. Mobile Banner :</div>
//                                     <div className={styles.addBannerCol9}>
//                                         <img src={
//                                             item.bannerImg && item.bannerImg.name ?
//                                                 URL.createObjectURL(item.bannerImg)
//                                                 : item.bannerImg ? process.env.REACT_APP_S3URL + item.bannerImg
//                                                     : "/assets/img.png"
//                                         }
//                                             alt="img" height={39} width={66} className={styles.bannerImg} />
//                                         <label className={styles.bannerAddBtn}>
//                                             <UpLoadFile />
//                                             Upload Image
//                                             <input
//                                                 name="bannerImg"
//                                                 type="file"
//                                                 className={styles.bannerImgInput}
//                                                 id="banner"
//                                                 onChange={(e) => {
//                                                     handleMobFileChange(index, e);
//                                                     if (item.bannerImg.name && item._id) {
//                                                         if (!mobBannerUpdate.includes(item._id)) {
//                                                             setMobBannerUpdate(prevState => [...prevState, item._id])
//                                                         }
//                                                     }
//                                                 }}
//                                                 accept="image/*"
//                                             />
//                                         </label>
//                                         <input
//                                             type="text"
//                                             placeholder="Enter URL"
//                                             className={styles.bannerUrlInput}
//                                             name="itemUrl"
//                                             value={item.itemUrl}
//                                             onChange={(e) => handleMobFormChange(index, e)}
//                                         />

//                                         <select
//                                             className={styles.bannerSelect}
//                                             name="itemUrlType"
//                                             value={item.itemUrlType}
//                                             onChange={(e) => handleMobFormChange(index, e)}
//                                         >
//                                             <option className={styles.bg_dark}>Select</option>
//                                             <option className={styles.bg_dark} value="item">Item</option>
//                                             <option className={styles.bg_dark} value="url">Url</option>
//                                             <option className={styles.bg_dark} value="N/A">N/A</option>
//                                         </select>
//                                         <div className={styles.bannerDeleteBtn}
//                                             onClick={() => {
//                                                 removeMobFormFields(index);
//                                                 if (!mobBannerUpdate.includes(item._id) && item._id) {
//                                                     setMobBannerUpdate(prevState => [...prevState, item._id])
//                                                 }
//                                             }} >
//                                             <DeleteFile color="red" /><br />
//                                             Delete
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))}
//                             <div role="button" className={styles.addMoreBtn} onClick={() => { addMobBanner() }} >
//                                 + Add Mobile Banner
//                             </div>

//                             <div className={styles.addBannerRow}>
//                                 <div className={styles.addProductCol3}>Meta Title</div>
//                                 <div className={styles.addProductCol9}>
//                                     <input type="text"
//                                         name='metaTitle'
//                                         value={addData.metaTitle}
//                                         onChange={(e) => {
//                                             setAddData({
//                                                 ...addData, metaTitle: e.target.value,
//                                                 url: e.target.value.toLocaleLowerCase().replace(/[\s,-=;]+/g, "-")
//                                             })
//                                         }}
//                                         placeholder="Enter Title"
//                                         className={styles.add_product_input} />
//                                     <div>
//                                         *use 60 letters only<span className={styles.counter}>{addData.metaTitle?.length}</span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className={styles.addBannerRow}>
//                                 <div className={styles.addProductCol3}>Meta Description <br /> (optional)</div>
//                                 <div className={styles.addProductCol9}>
//                                     <textarea
//                                         name="metaDescription"
//                                         value={addData.metaDescription}
//                                         onChange={(e) => setAddData({ ...addData, metaDescription: e.target.value })}
//                                         placeholder="Enter Description"
//                                         className={styles.add_product_input}
//                                         rows={5} />
//                                     <div>
//                                         *use 50-1200 letters only<span className={styles.counter}>{addData.metaDescription?.length}</span>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className={styles.addBannerRow}>
//                                 <div className={styles.addProductCol3}>Keywords (optional)</div>
//                                 <div className={styles.addtag}>
//                                     <div className={styles.tag_main_div}>
//                                         {tags.map((tag, index) =>
//                                             <div key={index} className={styles.tag}>{tag}
//                                                 <button type='button' className={styles.tag_button} onClick={() => deleteTag(index)}>x</button>
//                                             </div>
//                                         )}
//                                         <textarea
//                                             rows={1}
//                                             value={input}
//                                             placeholder="Enter a tag"
//                                             onKeyDown={onKeyDown}
//                                             onKeyUp={onKeyUp}
//                                             onChange={onChange}
//                                             className={styles.add_inputtag}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className={styles.addBannerRow}>
//                                 <div className={styles.addProductCol3}>Focus Keywords (optional)</div>
//                                 <div className={styles.addProductCol9}>
//                                     <input type="text"
//                                         name='focusKeyword'
//                                         value={addData.focusKeyword}
//                                         onChange={(e) => setAddData({ ...addData, focusKeyword: e.target.value })}
//                                         placeholder="Enter Focus Keyword"
//                                         className={styles.add_product_input} />
//                                 </div>
//                             </div>
//                             <div className={styles.addBannerRow}>
//                                 <div className={styles.addProductCol3}>Base Price</div>
//                                 <div className={styles.addProductCol9}>
//                                     <input type="number"
//                                         placeholder="Enter Base Price"
//                                         name="mrp"
//                                         value={addData.mrp}
//                                         onChange={(e) => setAddData({ ...addData, mrp: e.target.value })}
//                                         className={styles.add_product_input}
//                                     // required
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className={styles.addProductBtmBtn}>
//                     <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => navigate(-1)} />
//                     <GreenButton disabled={loading && true} title={id ? "UPDATE" : "CREATE"} btnType="submit" />
//                 </div>
//             </form>
//         </React.Fragment >
//     )
// }
// export default AddCollection;

// import React, { useState, useEffect } from 'react'
// import { useNavigate, useSearchParams } from 'react-router-dom'
// // import Select from 'react-select'
// import { GreenButton, RedOutButton } from '../../components/Buttons'
// import { DeleteFile, UpLoadFile } from '../../components/icons/Icon'
// import styles from './Product.module.css'

// const AddProduct = () => {
//     const [searchParams] = useSearchParams();
//     // const step = searchParams.get("step");
//     const getId = searchParams.get("id");
//     const id = (!getId || getId === null) ? "" : getId
//     const navigate = useNavigate()

//     const [loading, setLoading] = useState(false)

//     const [addProduct, setAddProduct] = useState({
//         productName: "",
//         uploadLogo: "",
//         metaTitle: "",
//         metaKeyword: "",
//         focusKeyword: "",
//         title: "",
//         url: "",
//         description: "",
//         metaDescription: "",
//         status: "",
//         cartType: "",
//         dealerOnly: "",
//         accessories: "",
//         partneredCompanyProduct: ""
//     })

//     // ---------------------------Desktop Banner------------------------------------
//     const [banner, setBanner] = useState([{
//         bannerImg: "",
//         itemUrl: "",
//         itemUrlType: ""
//     }])
//     const [bannerUpdate, setBannerUpdate] = useState([])
//     const addFields = () => {
//         let newfield = { bannerImg: '', itemUrl: '', itemUrlType: '' }
//         setBanner([...banner, newfield])
//     }
//     const handleFileChange = (index, event) => {
//         let data = [...banner];
//         data[index][event.target.name] = event.target.files[0];
//         setBanner(data);
//     }
//     const handleFormChange = (index, event) => {
//         let data = [...banner];
//         data[index][event.target.name] = event.target.value;
//         setBanner(data);
//     }
//     const removeFormFields = (i) => {
//         const values = [...banner];
//         values.splice(i, 1);
//         setBanner(values);
//     }
//     // ---------------------------Mobile Banner------------------------------------
//     const [mobBanner, setMobBanner] = useState([{
//         bannerImg: "",
//         itemUrl: "",
//         itemUrlType: ""
//     }])
//     const [mobBannerUpdate, setMobBannerUpdate] = useState([])

//     const addMobBanner = () => {
//         let newfield = { bannerImg: '', itemUrl: '', itemUrlType: '' }
//         setMobBanner([...mobBanner, newfield])
//     }
//     const handleMobFileChange = (index, event) => {
//         let data = [...mobBanner];
//         data[index][event.target.name] = event.target.files[0];
//         setMobBanner(data);
//     }
//     const handleMobFormChange = (index, event) => {
//         let data = [...mobBanner];
//         data[index][event.target.name] = event.target.value;
//         setMobBanner(data);
//     }
//     const removeMobFormFields = (i) => {
//         const values = [...mobBanner];
//         values.splice(i, 1);
//         setMobBanner(values);
//     }

//     // --------------------------------------------------Delivery Method-----------------------------------
//     // const [courierName, setCourierName] = useState([])
//     const [fields, setFields] = useState([{
//         deliveryMethod: "",
//         partnerName: []
//     }])

//     // const addDelivey = () => {
//     //     let newfield = { deliveryMethod: '', partnerName: '' }
//     //     setFields([...fields, newfield])
//     // }
//     // const handleDeliveyChange = (index, event) => {
//     //     let data = [...fields];
//     //     data[index][event.target.name] = event.target.value;
//     //     setFields(data);
//     // }
//     // const removeDelivey = (i) => {
//     //     const values = [...fields];
//     //     values.splice(i, 1);
//     //     setFields(values);
//     // }
//     // const handleChange = (index, event) => {
//     //     let data = [...fields];
//     //     data[index]["partnerName"] = Array.isArray(event) ? event.map(x => x.value) : [];
//     //     setFields(data);
//     // }

//     // ---------------------------Meta Keywords----------------------
//     const [input, setInput] = useState('');
//     const [tags, setTags] = useState([]);
//     const [isKeyReleased, setIsKeyReleased] = useState(false);

//     const onKeyDown = (e) => {
//         const { key } = e;
//         const trimmedInput = input.trim();

//         if ((key === ',' || key === "Enter") && trimmedInput?.length && !tags.includes(trimmedInput)) {
//             e.preventDefault();
//             setTags(prevState => [...prevState, trimmedInput]);
//             setInput('');
//         }

//         if (key === "Backspace" && !input?.length && tags?.length && isKeyReleased) {
//             const tagsCopy = [...tags];
//             const poppedTag = tagsCopy.pop();
//             e.preventDefault();
//             setTags(tagsCopy);
//             setInput(poppedTag);
//         }

//         setIsKeyReleased(false);
//     };

//     const onKeyUp = () => {
//         setIsKeyReleased(true);
//     }

//     const deleteTag = (index) => {
//         setTags(prevState => prevState.filter((tag, i) => i !== index))
//     }

//     const onChange = (e) => {
//         const { value } = e.target;
//         setInput(value);
//     };

//     // Ravi Changes
//     const [tages2, setTages2] = useState([])
//     useEffect(() => {
//         const trimmedInput = input.trim();
//         if (input.includes(",")) {
//             setTages2(trimmedInput.split(','))
//         }
//     }, [input]);

//     useEffect(() => {
//         if (tages2) {
//             setTags(tages2)
//             setInput("")
//         }
//     }, [tages2])
//     // Ravi Changes

//     // ---------------------------Get Courier----------------------
//     // useEffect(() => {
//     //     const getCourierName = async () => {
//     //         fetch(process.env.REACT_APP_URL + "deliverymethod/courierNames", {
//     //             method: "POST",
//     //             headers: {
//     //                 'Content-Type': 'application/json',
//     //                 "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
//     //             }
//     //         })
//     //             .then(response => response.json())
//     //             .then(result => setCourierName(result.data))
//     //     }
//     //     getCourierName()
//     // }, [])

//     // ---------------------------Get Product By Id----------------------
//     useEffect(() => {
//         if (id) {
//             var myHeaders = new Headers();
//             myHeaders.append("Content-Type", "application/json");
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//             var raw = JSON.stringify({
//                 "_id": id
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "content/product", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === 'success') {
//                         if (result.data) {
//                             setAddProduct(prevState => ({
//                                 ...prevState,
//                                 uploadLogo: result.data.logo ? result.data.logo : "",
//                                 productName: result.data.product_name ? result.data.product_name : "",
//                                 metaTitle: result.data.metaTitle ? result.data.metaTitle : "",
//                                 title: result.data.title ? result.data.title : "",
//                                 description: result.data.description ? result.data.description : "",
//                                 url: result.data.url ? result.data.url : "",
//                                 focusKeyword: result.data.focusKeyword ? result.data.focusKeyword : "",
//                                 metaKeyword: result.data.metaKeywords ? result.data.metaKeywords : "",
//                                 metaDescription: result.data.metaDescription ? result.data.metaDescription : "",
//                                 status: result.data.status ? result.data.status : "",
//                                 cartType: result.data.cartType ? result.data.cartType : "",
//                                 dealerOnly: result.data.dealerOnly ? result.data.dealerOnly : "",
//                                 accessories: result.data.accessories ? result.data.accessories : "",
//                                 partneredCompanyProduct: result.data.partneredCompanyProduct ? result.data.partneredCompanyProduct : "",
//                             }))
//                             if (result.data?.metaKeywords?.length === 1) {
//                                 setTags(result.data.metaKeywords[0].split(','))
//                             } else {
//                                 setTags(result.data.metaKeywords)
//                             }
//                             setBanner(result.data.banner)
//                             setMobBanner(result.data.mobBanner)
//                             setFields(result.data.courierName)
//                         }
//                     }
//                 })
//                 .catch(error => //console.log('error', error));
//         }
//     }, [id])

//     // ----------------------------Add Update Product-------------------------
//     const handleAddUpdate = (e) => {
//         e.preventDefault()
//         setLoading(true)
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         var formdata = new FormData();
//         formdata.append("product_name", addProduct.productName);
//         formdata.append("logo", addProduct.uploadLogo);
//         formdata.append("metaTitle", addProduct.metaTitle);
//         formdata.append("url", addProduct.url);
//         formdata.append("title", addProduct.title);
//         formdata.append("description", addProduct.description);
//         formdata.append("metaKeywords", JSON.stringify(tags));
//         formdata.append("focusKeyword", addProduct.focusKeyword);
//         formdata.append("metaDescription", addProduct.metaDescription);
//         formdata.append("status", addProduct.status);
//         formdata.append("cartType", addProduct.cartType);
//         formdata.append("dealerOnly", addProduct.dealerOnly);
//         formdata.append("accessories", addProduct.accessories);
//         formdata.append("partneredCompanyProduct", addProduct.partneredCompanyProduct);
//         formdata.append("courierName", JSON.stringify(fields));
//         banner.map(item =>
//             <>
//                 {(formdata.append("bannerImg", item.bannerImg),
//                     formdata.append("itemUrl", item.itemUrl),
//                     formdata.append("itemUrlType", item.itemUrlType))}
//             </>
//         )
//         mobBanner.map(item =>
//             <>
//                 {(formdata.append("mobBannerImg", item.bannerImg),
//                     formdata.append("mobItemUrl", item.itemUrl),
//                     formdata.append("mobItemUrlType", item.itemUrlType))}
//             </>
//         )
//         formdata.append("bannerUpdate", bannerUpdate);
//         formdata.append("mobBannerUpdate", mobBannerUpdate);
//         id && formdata.append("_id", id);
//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: formdata,
//             redirect: 'follow'
//         };
//         fetch(process.env.REACT_APP_URL + "content/addUpdateProduct/", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     setLoading(false)
//                     navigate(-1)
//                 } else {
//                     setLoading(false)
//                 }
//             })
//             .catch(error => //console.log('error', error));
//     }

//     return (
//         <React.Fragment>
//             <div className={styles.product_right}>
//                 <div className='mt-2'>
//                     <u role='button' onClick={() => navigate(-1)}>
//                         &lt; Go Back
//                     </u>
//                 </div>
//                 <h2>Add Product</h2>
//                 <form onSubmit={handleAddUpdate} className={styles.add_product_main_div}>
//                     <div className={styles.metaRow}>
//                         <div className={styles.meta}>Meta URL</div>
//                         <div className={styles.url}>
//                             {process.env.REACT_APP_GLOBALURL}
//                             <input type={"text"}
//                                 name='url'
//                                 value={addProduct.url}
//                                 onChange={(e) => setAddProduct({ ...addProduct, url: e.target.value })}
//                                 placeholder="Enter Url"
//                                 className={styles.add_url_input} />
//                             {process.env.REACT_APP_GLOBALURL?.length + (addProduct.url && addProduct.url?.length)}
//                         </div>
//                     </div>
//                     <div className={styles.addProductRow}>
//                         <div className={styles.addProductCol}>
//                             <div className={styles.addProductColP}>
//                                 <div className={styles.addProductRow}>
//                                     <div className={styles.addProductCol3}>Product Name</div>
//                                     <div className={styles.addProductCol9}>
//                                         <input type="text"
//                                             name='product_name'
//                                             value={addProduct.productName}
//                                             onChange={(e) => setAddProduct({ ...addProduct, productName: e.target.value })}
//                                             placeholder="Enter Product Name"
//                                             className={styles.add_product_input}
//                                         // required
//                                         />
//                                     </div>
//                                 </div>
//                                 {banner && banner?.map((item, index) =>
//                                 (
//                                     <div className={styles.addBannerRow} key={index}>
//                                         <div className={styles.addProductCol3}>{index + 1}. Desktop Banner :</div>
//                                         <div className={styles.addBannerCol9}>
//                                             <img src={
//                                                 item.bannerImg && item.bannerImg.name ?
//                                                     URL?.createObjectURL(item.bannerImg)
//                                                     : item.bannerImg ? process.env.REACT_APP_S3URL + item.bannerImg
//                                                         : "/assets/img.png"
//                                             }
//                                                 alt="img" height={39} width={66} className={styles.bannerImg} />
//                                             <label className={styles.bannerAddBtn}>
//                                                 <UpLoadFile />
//                                                 Upload Image
//                                                 <input
//                                                     name="bannerImg"
//                                                     type="file"
//                                                     className={styles.bannerImgInput}
//                                                     id="banner"
//                                                     onChange={(e) => {
//                                                         handleFileChange(index, e);
//                                                         if (item.bannerImg.name && !bannerUpdate.includes(item._id) && item._id) {
//                                                             setBannerUpdate(prevState => [...prevState, item._id])
//                                                         }
//                                                     }}
//                                                     accept="image/*"
//                                                 />
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 placeholder="Enter URL"
//                                                 className={styles.bannerUrlInput}
//                                                 name="itemUrl"
//                                                 value={item.itemUrl}
//                                                 onChange={(e) => handleFormChange(index, e)}
//                                             />
//                                             <select
//                                                 className={styles.bannerSelect}
//                                                 name="itemUrlType"
//                                                 value={item.itemUrlType}
//                                                 onChange={(e) => handleFormChange(index, e)}
//                                             >
//                                                 <option className={styles.bg_dark} value="">Select</option>
//                                                 <option className={styles.bg_dark} value="item">Item</option>
//                                                 <option className={styles.bg_dark} value="url">Url</option>
//                                                 <option className={styles.bg_dark} value="N/A">N/A</option>
//                                             </select>
//                                             <div className={styles.bannerDeleteBtn}
//                                                 onClick={() => {
//                                                     removeFormFields(index);
//                                                     if (!bannerUpdate.includes(item._id) && item._id) {
//                                                         setBannerUpdate(prevState => [...prevState, item._id])
//                                                     }
//                                                 }} >
//                                                 <DeleteFile color="red" /><br />
//                                                 Delete
//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))}
//                                 <div className={styles.addMoreBtn} onClick={() => { addFields() }} >
//                                     + Add Desktop Banner
//                                 </div>
//                                 <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Product Title</div>
//                                     <div className={styles.addProductCol9}>
//                                         <textarea
//                                             name='metaTitle'
//                                             value={addProduct.title}
//                                             onChange={(e) => setAddProduct({ ...addProduct, title: e.target.value })}
//                                             placeholder="Enter Title"
//                                             rows={3}
//                                             className={styles.add_product_input} />
//                                     </div>
//                                 </div>
//                                 <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Product Description <br /> (optional)</div>
//                                     <div className={styles.addProductCol9}>
//                                         <textarea
//                                             name="description"
//                                             value={addProduct.description}
//                                             onChange={(e) => setAddProduct({ ...addProduct, description: e.target.value })}
//                                             placeholder="Enter Description"
//                                             className={styles.add_product_input}
//                                             rows={5} />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className={styles.addProductCol}>
//                             <div className={styles.addProductColP}>
//                                 <div className={styles.addProductRow}>
//                                     <div className={styles.addProductCol3}>Upload Logo :</div>
//                                     <div className={styles.addBannerCol9}>
//                                         <img src={
//                                             addProduct.uploadLogo && addProduct.uploadLogo.name ?
//                                                 URL.createObjectURL(addProduct.uploadLogo)
//                                                 : addProduct.uploadLogo ? process.env.REACT_APP_S3URL + addProduct.uploadLogo
//                                                     : "/assets/img.png"
//                                         }
//                                             alt="img" height={39} width={66} className={styles.bannerImg}
//                                         />

//                                         <label className={styles.bannerAddBtn}>

//                                             <UpLoadFile />
//                                             Upload Image
//                                             <input
//                                                 type="file"
//                                                 accept="image/*"
//                                                 className={styles.d_none}
//                                                 name="uploadLogo"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, uploadLogo: e.target.files[0] })}
//                                             />
//                                         </label>

//                                         <div className={styles.bannerDeleteBtn}
//                                             onClick={() => { setAddProduct({ ...addProduct, uploadLogo: "" }) }}
//                                         >
//                                             <DeleteFile color="red" /><br />
//                                             Delete
//                                         </div>
//                                     </div>
//                                 </div>

//                                 {mobBanner && mobBanner.map((item, index) =>
//                                 (
//                                     <div className={styles.addBannerRow} key={index}>
//                                         <div className={styles.addProductCol3}>{index + 1}. Mobile Banner :</div>
//                                         <div className={styles.addBannerCol9}>
//                                             <img src={
//                                                 item.bannerImg && item.bannerImg.name ?
//                                                     URL.createObjectURL(item.bannerImg)
//                                                     : item.bannerImg ? process.env.REACT_APP_S3URL + item.bannerImg
//                                                         : "/assets/img.png"
//                                             }
//                                                 alt="img" height={39} width={66} className={styles.bannerImg} />

//                                             <label className={styles.bannerAddBtn}>
//                                                 <UpLoadFile />
//                                                 Upload Image
//                                                 <input
//                                                     name="bannerImg"
//                                                     type="file"
//                                                     className={styles.bannerImgInput}
//                                                     id="banner"
//                                                     onChange={(e) => {
//                                                         handleMobFileChange(index, e);
//                                                         if (item.bannerImg.name && item._id) {
//                                                             if (!mobBannerUpdate.includes(item._id)) {
//                                                                 setMobBannerUpdate(prevState => [...prevState, item._id])
//                                                             }
//                                                         }
//                                                     }}
//                                                     accept="image/*"
//                                                 />
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 placeholder="Enter URL"
//                                                 className={styles.bannerUrlInput}
//                                                 name="itemUrl"
//                                                 value={item.itemUrl}
//                                                 onChange={(e) => handleMobFormChange(index, e)}
//                                             />

//                                             <select
//                                                 className={styles.bannerSelect}
//                                                 name="itemUrlType"
//                                                 value={item.itemUrlType}
//                                                 onChange={(e) => handleMobFormChange(index, e)}
//                                             >
//                                                 <option className={styles.bg_dark}>Select</option>
//                                                 <option className={styles.bg_dark} value="item">Item</option>
//                                                 <option className={styles.bg_dark} value="url">Url</option>
//                                                 <option className={styles.bg_dark} value="N/A">N/A</option>
//                                             </select>

//                                             <div className={styles.bannerDeleteBtn}
//                                                 onClick={() => {
//                                                     removeMobFormFields(index);
//                                                     if (!mobBannerUpdate.includes(item._id) && item._id) {
//                                                         setMobBannerUpdate(prevState => [...prevState, item._id])
//                                                     }
//                                                 }} >
//                                                 <DeleteFile color="red" /><br />
//                                                 Delete
//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))}

//                                 <div className={styles.addMoreBtn} onClick={() => { addMobBanner() }} >
//                                     + Add Mobile Banner
//                                 </div>

//                                 <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Meta Title</div>
//                                     <div className={styles.addProductCol9}>
//                                         <textarea
//                                             name='metaTitle'
//                                             value={addProduct.metaTitle}
//                                             onChange={(e) => {
//                                                 setAddProduct({
//                                                     ...addProduct,
//                                                     metaTitle: e.target.value,
//                                                     url: e.target.value.toLocaleLowerCase().replace(/[\s,-=;]+/g, "-")

//                                                 })

//                                             }}
//                                             placeholder="Enter Title"
//                                             rows={3}
//                                             className={styles.add_product_input} />
//                                         <div>
//                                             *use 60 letters only<span className={styles.counter}>{addProduct.metaTitle?.length}</span>

//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Meta Description <br /> (optional)</div>
//                                     <div className={styles.addProductCol9}>
//                                         <textarea
//                                             name="metaDescription"
//                                             value={addProduct.metaDescription}
//                                             onChange={(e) => setAddProduct({ ...addProduct, metaDescription: e.target.value })}
//                                             placeholder="Enter Description"
//                                             className={styles.add_product_input}
//                                             rows={5} />
//                                         <div>
//                                             *use 60 letters only<span className={styles.counter}>{addProduct.metaDescription?.length}</span>

//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Keywords (optional)</div>
//                                     <div className={styles.addtag}>
//                                         <div className={styles.tag_main_div}>
//                                             {tags.map((tag, index) =>
//                                                 <div key={index} className={styles.tag}>{tag}
//                                                     <button type='button' className={styles.tag_button} onClick={() => deleteTag(index)}>x</button>
//                                                 </div>
//                                             )}
//                                             <textarea
//                                                 rows={1}
//                                                 value={input}
//                                                 placeholder="Enter a tag"
//                                                 onKeyDown={onKeyDown}
//                                                 onKeyUp={onKeyUp}
//                                                 onChange={onChange}
//                                                 className={styles.add_inputtag}
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Focus Keywords (optional)</div>
//                                     <div className={styles.addProductCol9}>
//                                         <input type="text"
//                                             name='focusKeyword'
//                                             value={addProduct.focusKeyword}
//                                             onChange={(e) => setAddProduct({ ...addProduct, focusKeyword: e.target.value })}
//                                             placeholder="Enter Focus Keyword"
//                                             className={styles.add_product_input} />
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>
//                     </div>
//                     {/* {fields && fields.map((item, index) =>
//                     (<div className={styles.addProductRow} key={index} >
//                         <div className={styles.addProductCol}>
//                             <div className={styles.addProductColP}>
//                                 <div className={styles.addProductRow} key={index} >
//                                     <div className={styles.addProductCol3}>
//                                         <label className={index === 0 ? "m-0" : "d-none"} >
//                                             Delivery Method
//                                         </label>
//                                     </div>
//                                     <div className={styles.addBannerCol9}>
//                                         <select
//                                             name="deliveryMethod"
//                                             className={styles.bannerSelect}
//                                             value={item.deliveryMethod}
//                                             onChange={(e) => { handleDeliveyChange(index, e); }}
//                                         >
//                                             <option className={styles.bg_dark} value="">Select Delivery Method</option>
//                                             {item.deliveryMethod && <option>{item.deliveryMethod}</option>}
//                                             {
//                                                 courierName.filter(x => !fields.some(y => x.courierName === y.deliveryMethod)).map((obj, i) => <option className={styles.bg_dark} key={i} value={obj.courierName}>{obj.courierName}</option>)
//                                             }
//                                         </select>

//                                         <div className={styles.bannerDeleteBtn} onClick={(e) => removeDelivey(index, e)}>
//                                             <DeleteFile color="red" /><br />
//                                             Delete
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className={styles.addProductCol}>
//                             <div className={styles.addProductColP}>
//                                 <div className={styles.addProductRow}>
//                                     <div className={styles.addProductCol3}>
//                                         <label className={index === 0 ? "m-0" : "d-none"}>
//                                             Partner
//                                         </label>
//                                     </div>
//                                     <div className={styles.addBannerCol9}>
//                                         <Select
//                                             options={courierName && courierName.find(option => option.courierName === item.deliveryMethod)?.partners.map(type => ({ value: type.partnerName, label: type.partnerName, }))}
//                                             value={courierName && courierName.find(option => option.courierName === item.deliveryMethod)?.partners.map(type => ({ value: type.partnerName, label: type.partnerName, })).filter(obj => fields[index].partnerName.includes(obj.value))}
//                                             onChange={(e) => handleChange(index, e)}
//                                             className={styles.selectReact}
//                                             // styles={colourStyles}
//                                             isMulti
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     ))}
//                     <div className={styles.addMoreBtn} onClick={() => { addDelivey() }} >
//                         + Add Delivery Method
//                     </div> */}

//                     <div className={styles.addProductRow}>
//                         <div className={styles.addProductCol}>
//                             <div className={styles.addProductColP}>
//                                 <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Status</div>
//                                     <div className={styles.addProductCol9}>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 name="status"
//                                                 value="Active"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, status: e.target.value })}
//                                                 checked={addProduct.status === "Active" ? true : false}
//                                             />Active
//                                         </label>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 name="status"
//                                                 value="Disable"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, status: e.target.value })}
//                                                 checked={addProduct.status === "Disable" ? true : false}
//                                             />Disable
//                                         </label>
//                                     </div>
//                                 </div>
//                                 {/* <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Cart Type</div>
//                                     <div className={styles.addProductCol9}>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 name="cartType"
//                                                 value="ocart"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, cartType: e.target.value })}
//                                                 checked={addProduct.cartType === "ocart" ? true : false}
//                                             />
//                                             Cart
//                                         </label>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 name="cartType"
//                                                 value="pcart"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, cartType: e.target.value })}
//                                                 checked={addProduct.cartType === "pcart" ? true : false}
//                                             />
//                                             P-Cart
//                                         </label>
//                                     </div>
//                                 </div> */}
//                                 <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Dealer Only</div>
//                                     <div className={styles.addProductCol9}>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 name="dealerOnly"
//                                                 value="Active"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, dealerOnly: e.target.value })}
//                                                 checked={addProduct.dealerOnly === "Active" ? true : false}
//                                             />Active
//                                         </label>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 name="dealerOnly"
//                                                 value="Disable"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, dealerOnly: e.target.value })}
//                                                 checked={addProduct.dealerOnly === "Disable" ? true : false}
//                                             />Disable
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className={styles.addProductCol}>
//                             <div className={styles.addProductColP}>
//                                 <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Accessories</div>
//                                     <div className={styles.addProductCol9}>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 value="Enable"
//                                                 name="accessories"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, accessories: e.target.value })}
//                                                 checked={addProduct.accessories === "Enable" ? true : false}
//                                             />Enable
//                                         </label>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 value="Disable"
//                                                 name="accessories"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, accessories: e.target.value })}
//                                                 checked={addProduct.accessories === "Disable" ? true : false}
//                                             />Disable
//                                         </label>
//                                     </div>
//                                 </div>
//                                 {/* <div className={styles.addBannerRow}>
//                                     <div className={styles.addProductCol3}>Partner Product</div>
//                                     <div className={styles.addProductCol9}>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 name="partneredCompanyProduct"
//                                                 value="Yes"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, partneredCompanyProduct: e.target.value })}
//                                                 checked={addProduct.partneredCompanyProduct === "Yes" ? true : false}
//                                             />Yes
//                                         </label>
//                                         <label>
//                                             <input
//                                                 type="radio"
//                                                 name="partneredCompanyProduct"
//                                                 value="No"
//                                                 onChange={(e) => setAddProduct({ ...addProduct, partneredCompanyProduct: e.target.value })}
//                                                 checked={addProduct.partneredCompanyProduct === "No" ? true : false}
//                                             />No
//                                         </label>
//                                     </div>
//                                 </div> */}
//                             </div>
//                         </div>
//                     </div>

//                     <div className={styles.addProductBtmBtn}>
//                         <RedOutButton type="button" title="CANCEL" handleSubmit={() => navigate("/products")} />
//                         <GreenButton disabled={loading && true} title={id ? "UPDATE" : "CREATE"} btnType="submit" />
//                     </div>
//                 </form>
//             </div>
//         </React.Fragment>
//     )
// }

// export default AddProduct

import React, { useEffect, useState } from "react";
import styles from "./Product.module.css";
import { useAuth } from "../../components/context/Auth";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import ImageUploder from "../../components/updatedComponents/Product/ImageUploder";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import KeyWordGen from "../../components/updatedComponents/Product/KeyWordGen";
import InputTextArea from "../../components/updatedComponents/Product/InputTextArea";
import InputRedio from "../../components/updatedComponents/FormComponents/InputRedio";
import Button from "../../components/updatedComponents/Dealers/Button";
import { AddTO, DeleteTo } from "../../components/icons/Icon3";
import DrawerWrapper from "../../components/updatedComponents/layoutWrapper/DrawerWrapper";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import { handleFileData } from "../../APIsControll/apiControl2";
import { useNavigate, useParams } from "react-router-dom";
import VerticalDropDown2 from "../../components/updatedComponents/FormComponents/VerticalDropDown2";
import fetchData from "../../APIsControll/apiControll";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";
import { validateFormForAddUpdateCollection } from "../../validation/AddDealer";

const AddCollection = () => {
  const { show } = useToast();
  const { mode } = useAuth();
  const { productName } = useParams();

  // const location = useLocation();
  // const pathParts = location.pathname.split("/");
  // const addTypeString = pathParts[2];

  const productId = new URLSearchParams(window.location.search).get(
    "productId"
  );
  const collectionId = new URLSearchParams(window.location.search).get(
    "collectionId"
  );
  // const disabled = new URLSearchParams(window.location.search).get("disabled") === "true";
  const [modelContainer, setmodelContainer] = useState(null);
  const [toggle, setToggle] = useState({
    modelToggle: false,
  });
  const [ProductType, setProductType] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [errors, setErrors] = useState({});
  const [url, seturl] = useState("");
  const [loading, setLoading] = useState({ getTypeloading: false });

  // //console.log("selectedTypes", selectedTypes);
const navigate = useNavigate()
  const [SingleData, setSingleData] = useState({
    product_name: "",
    product_id: "",
    type_name: "",
    collection_name: "",
    logo: "",
    metaTitle: "",
    metaKeywords: [],
    focusKeyword: [],
    url: "",
    mrp: "",
    title: "",
    description: "",
    metaDescription: "",
    status: "",
    size: "",
    DeskTopBanner: [],
    mobItemBanner: [],
    accessories: [{}],
    // selectType: [],
    // DeskTopBanner: [{ bannerImg: '', itemUrl: '', itemUrlType: '', }],
    // mobItemBanner: [{ bannerImg: '', itemUrl: '', itemUrlType: '', }],
  });

  // set Meta url
  useEffect(() => {
    const formatUrlFromMetaTitle = (metaTitle) => {
      return metaTitle.replace(/\s+/g, " ").split(" ").join("-").toLowerCase();
    };
    const formattedUrl = formatUrlFromMetaTitle(SingleData.metaTitle);
    seturl(formattedUrl);
  }, [SingleData]);

  // Create DeskTop
  const addNewDeskTopBanner = () => {
    setSingleData((prevData) => ({
      ...prevData,
      DeskTopBanner: [
        ...prevData.DeskTopBanner,
        {
          bannerImg: "",
          itemUrl: "",
          itemUrlType: "",
        },
      ],
    }));
  };

  // Create Mobile
  const addNewMobileBanner = () => {
    setSingleData((prevData) => ({
      ...prevData,
      mobItemBanner: [
        ...prevData.mobItemBanner,
        {
          bannerImg: "",
          itemUrl: "",
          itemUrlType: "",
        },
      ],
    }));
  };

  // set Desktop and Mobile Image
  const handleImageChange = (bannerType, index, imageUrl) => {
    const updatedBanner = [...SingleData[bannerType]];
    updatedBanner[index].bannerImg = imageUrl;
    setSingleData({
      ...SingleData,
      [bannerType]: updatedBanner,
    });
  };

  const handleAddAccessory = () => {
    const newAccessory = {
      id: SingleData.accessories.length + 1,
    };
    setSingleData({
      ...SingleData,
      accessories: [...SingleData.accessories, newAccessory],
    });
  };

  const handleRemoveAccessory = (index) => {
    setSingleData({
      ...SingleData,
      accessories: SingleData.accessories.filter((_, i) => i !== index), // Remove accessory by index
    });
    const newSelectedTypes = selectedTypes.filter((_, i) => i !== index); // Remove
    setSelectedTypes(newSelectedTypes); // Update selected types array
  };

  const getCollection = async () => {
    try {
      setLoading({ ...loading, getTypeloading: true });
      const payload = {
        product_id: productId,
        _id: collectionId ? collectionId : "",
      };

      const res = await fetchData(`content/collection`, payload);
      if (res.status === "success") {
        setProductType(res.types);
        setSingleData({
          ...SingleData,
          collection_name: res?.data?.collection_name
            ? res?.data?.collection_name
            : "",
          product_name: res?.data?.product_name ? res?.data?.product_name : "",
          DeskTopBanner: res?.data?.banner ? res?.data?.banner : [],
          mobItemBanner: res?.data?.mobBanner ? res?.data?.mobBanner : [],
          size: res?.data?.size ? res?.data?.size : "",
          logo: res?.data?.logo ? res?.data?.logo : "",
          title: res?.data?.title ? res?.data?.title : "",
          description: res?.data?.description ? res?.data?.description : "",
          metaTitle: res?.data?.metaTitle ? res?.data?.metaTitle : "",
          metaDescription: res?.data?.metaDescription
            ? res?.data?.metaDescription
            : "",
          metaKeywords: res?.data?.metaKeywords ? res?.data?.metaKeywords : "",
          focusKeyword: res?.data?.focusKeyword
            ? [res?.data?.focusKeyword]
            : [],
          status: res?.data?.status ? res?.data?.status : "",
          mrp: res?.data?.mrp ? res?.data?.mrp : "",
        });
        seturl(res?.data?.url);
        // res?.data?.type_name
      }
      // if (res.status === "success") {
      //     setProductType(res.types);
      //     return
      // }
    } catch (error) {
      //console.log("Error");
    } finally {
      setLoading({ ...loading, getTypeloading: false });
    }
  };

  const NewPrductCreate = async () => {
    const validationErrors = validateFormForAddUpdateCollection(SingleData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const formdata = new FormData();
      formdata.append("product_name", productName);
      formdata.append("collection_name", SingleData.collection_name);
      formdata.append("product_id", productId);
      formdata.append("logo", SingleData.logo);
      formdata.append("metaTitle", SingleData.metaTitle);
      formdata.append("metaKeywords", JSON.stringify(SingleData.metaKeywords));
      formdata.append("focusKeyword", SingleData.focusKeyword[0]);
      formdata.append("url", url);
      formdata.append("mrp", Number(SingleData.mrp));
      formdata.append("title", SingleData.title);
      formdata.append("description", SingleData.description);
      formdata.append("metaDescription", SingleData.metaDescription);
      formdata.append("status", SingleData.status);
      formdata.append("size", SingleData.size);
      // formdata.append("type_name", SingleData.type_name);
      formdata.append(
        "type_name",
        JSON.stringify(selectedTypes?.map((item) => item.value))
      );
      SingleData?.DeskTopBanner.forEach((item) => {
        formdata.append("bannerImg", item.bannerImg);
        formdata.append("itemUrl", item.itemUrl);
        formdata.append("itemUrlType", item.itemUrlType);
      });
      SingleData.mobItemBanner.forEach((item) => {
        formdata.append("mobBannerImg", item.bannerImg);
        formdata.append("mobItemUrl", item.itemUrl);
        formdata.append("mobItemUrlType", item.itemUrlType);
      });

      formdata.append("bannerUpdate", "");
      formdata.append("mobBannerUpdate", "");
      collectionId && formdata.append("_id", collectionId);

      // let endpoint = '';
      // if (addTypeString === "addstyle") {
      //     endpoint = 'content/style';
      // } else if (addTypeString === "addtype") {
      //     endpoint = 'content/type';
      // }
      const res = await handleFileData("content/addUpdateCollection", formdata);
      // //console.log("res", res);
      if (res.status === "success") {
        show(res.msg || res.message || res.data, "success");
        navigate(-1)
        return;
      }
      if (res.status === "failed") {
        show(res.msg || res.message, "error");
        return;
      } else {
        show("Error", "error");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    getCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(toggle.modelToggle);

  return (
    <div className={styles.Main_Layout}>
      <DrawerWrapper
        isOpen={toggle.modelToggle}
        onClose={() => setToggle({ ...toggle, modelToggle: false })}
      >
        {modelContainer}
      </DrawerWrapper>
      <div
        className={styles.Layout}
        style={{
          backgroundColor: mode ? "#2B2D31" : "#F8F9FC",
          color: mode
            ? "#fff"
            : "#000" /* cursor: disabled ? "not-allowed" : "" */,
        }}
      >
        {/* <form> */}
        <div className={styles.Top_bar_css}>
          <div className={styles.gobackClass}>
            <TopTabBar text={`Product / ${productName}`} />
          </div>
          <div className={styles.btn_Continer}>
            <div>
              <DropDown
                label={"Sort By"}
                options={[
                  { label: "Active" },
                  { label: "Disabled" },
                  { label: "Delete" },
                  { label: "Ascending" },
                  { label: "Descending" },
                ]}
              />
            </div>
            <div>
              <Button
                buttonText={collectionId ? "Update" : "Create"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#fff"}
                buttonIcon={<AddTO />}
                // disabled={disabled}
                disabledButtonTextColor={"#fff"}
                disabledButtonBgColor={"#4164E3"}
                buttonClick={(e) => {
                  e.preventDefault();
                  setToggle({ ...toggle, modelToggle: !toggle.modelToggle });
                  setmodelContainer(
                    <>
                      <div
                        className={styles.bootem_seet_open}
                        style={{
                          backgroundColor: mode ? "#2B2D31" : "#F8F9FC",
                          color: mode ? "#fff" : "#000",
                        }}
                      >
                        <div>
                          <Button
                            buttonText={"Cancel"}
                            buttonBgColor={"#000"}
                            buttonTextColor={"#fff"}
                            // buttonIcon={<AddTO />}
                            buttonClick={() => setToggle((prevToggle) => ({
                              ...prevToggle,
                              modelToggle: !prevToggle.modelToggle,
                            }))
                            }
                          />
                        </div>
                        <div>
                          <Button
                            buttonText={collectionId ? "Update" : "Create"}
                            buttonBgColor={"#4164E3"}
                            buttonTextColor={"#fff"}
                            // buttonIcon={<AddTO />}
                            buttonClick={NewPrductCreate}
                          />
                        </div>
                      </div>
                    </>
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.form_continer}>
          {/* col - 1 */}
          <div className={styles.Col_sm}>
            <InputTag
              labelText={"Meta URL"}
              star={true}
              padding={"8px"}
              fontSize={"12px"}
              maxLength={10000}
              placeholderType={"text"}
              required={true}
              value={url}
              disabled={true}
              onChange={(e) =>
                setSingleData({ ...SingleData, url: e.target.value })
              }
            />
            <InputTag
              labelText={"Collection Name"}
              star={true}
              padding={"8px"}
              fontSize={"12px"}
              maxLength={10000}
              placeholderType={"text"}
              value={SingleData?.collection_name}
              onChange={(e) =>
                setSingleData({
                  ...SingleData,
                  collection_name: e.target.value,
                })
              }
              warning={errors.collection_name}
            />
            <InputTag
              labelText={"Size"}
              star={true}
              padding={"8px"}
              fontSize={"12px"}
              maxLength={10000}
              placeholderType={"text"}
              value={SingleData?.size}
              onChange={(e) =>
                setSingleData({ ...SingleData, size: e.target.value })
              }
              warning={errors.size}
            />

            <div
              className={styles.Col_sm_sub4}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div
                className={styles.text_color}
                style={{ color: mode ? "#fff" : "#647298" }}
              >
                {" "}
                <span>Logo </span>{" "}
              </div>{" "}
              <div></div>
              <div>
                <ImageUploder
                  image={SingleData.logo}
                  setimage={(imge) =>
                    setSingleData({ ...SingleData, logo: imge })
                  }
                  selectText={"Select Master Image"}
                  error={errors.logo}
                />
              </div>
            </div>
            <div
              className={styles.Col_sm_sub4}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div
                className={styles.text_color}
                style={{ color: mode ? "#fff" : "#647298" }}
              >
                {" "}
                <span>Desktop Banner </span>{" "}
              </div>{" "}
              <div></div>
              {SingleData?.DeskTopBanner?.map((item, index) => (
                <div key={`Dexttop-${index}`}>
                  <div>
                    <ImageUploder
                      image={item.bannerImg}
                      setimage={(imageUrl) =>
                        handleImageChange("DeskTopBanner", index, imageUrl)
                      }
                      options={[
                        { label: "ITEM", value: "item" },
                        { label: "URL", value: "Url" },
                        { label: "N/A", value: "N/A" },
                      ]}
                      value={item.itemUrlType}
                      setoption={(selectedOption) => {
                        const updatedDeskTopBanner = [
                          ...SingleData.DeskTopBanner,
                        ];
                        updatedDeskTopBanner[index].itemUrlType =
                          selectedOption.value;
                        setSingleData({
                          ...SingleData,
                          DeskTopBanner: updatedDeskTopBanner,
                        });
                      }}
                      selectText={"Select Master Image"}
                    />
                  </div>
                  <div>
                    <InputTag
                      padding={"8px"}
                      fontSize={"12px"}
                      maxLength={10000}
                      placeholderType={"text"}
                      placeholderText="URL.."
                      value={item.itemUrl}
                      onChange={(e) => {
                        const updatedDeskTopItemUrl = [
                          ...SingleData.DeskTopBanner,
                        ];
                        updatedDeskTopItemUrl[index].itemUrl = e.target.value;
                        setSingleData({
                          ...SingleData,
                          DeskTopBanner: updatedDeskTopItemUrl,
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
              <div style={{ width: "120px", height: "auto" }}>
                <Button
                  buttonText={"ADD"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#fff"}
                  buttonIcon={<AddTO />}
                  // disabled={disabled}
                  disabledButtonTextColor={"#fff"}
                  disabledButtonBgColor={"#4164E3"}
                  buttonClick={addNewDeskTopBanner}
                />
              </div>
            </div>
            <div
              className={styles.Col_sm_sub4}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div
                className={styles.text_color}
                style={{ color: mode ? "#fff" : "#647298" }}
              >
                {" "}
                <span> Mobile Banner </span>{" "}
              </div>{" "}
              <div></div>
              {SingleData?.mobItemBanner?.map((item, index) => (
                <div key={`mobItem-${index}`}>
                  <div>
                    <ImageUploder
                      image={item.bannerImg}
                      setimage={(imageUrl) =>
                        handleImageChange("mobItemBanner", index, imageUrl)
                      }
                      options={[
                        { label: "ITEM", value: "item" },
                        { label: "URL", value: "Url" },
                        { label: "N/A", value: "N/A" },
                      ]}
                      selectText={"Select Master Image"}
                      value={item.itemUrlType}
                      setoption={(selectedOption) => {
                        const updatedDeskTopBanner = [
                          ...SingleData.mobItemBanner,
                        ];
                        updatedDeskTopBanner[index].itemUrlType =
                          selectedOption.value;
                        setSingleData({
                          ...SingleData,
                          mobItemBanner: updatedDeskTopBanner,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <InputTag
                      padding={"8px"}
                      fontSize={"12px"}
                      maxLength={10000}
                      placeholderType={"text"}
                      placeholderText="URL.."
                      value={item.itemUrl}
                      onChange={(e) => {
                        const updatedDeskTopItemUrl = [
                          ...SingleData.mobItemBanner,
                        ];
                        updatedDeskTopItemUrl[index].itemUrl = e.target.value;
                        setSingleData({
                          ...SingleData,
                          mobItemBanner: updatedDeskTopItemUrl,
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
              <div style={{ width: "120px", height: "auto" }}>
                <Button
                  buttonText={"ADD"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#fff"}
                  buttonIcon={<AddTO />}
                  disabledButtonTextColor={"#fff"}
                  disabledButtonBgColor={"#4164E3"}
                  buttonClick={addNewMobileBanner}
                />
              </div>
            </div>

            {/* <div className={styles.Col_sm_sub4}
                            style={{ backgroundColor: mode ? "#232529" : "#fff", color: mode ? "#fff" : "#000" }} >
                            <div className={styles.text_color} style={{ color: mode ? "#fff" : "#647298" }} > <span> Mobile Banner </span> </div> <div></div>
                            {SingleData?.mobItemBanner?.map((item, index) => (
                                <div key={`mobItem-${index}`}>
                                    <div>
                                        <ImageUploder
                                            image={item.bannerImg}
                                            setimage={(imageUrl) => handleImageChange('mobItemBanner', index, imageUrl)}
                                            options={[
                                                { label: "ITEM", value: "item" },
                                                { label: "URL", value: "Url" },
                                                { label: "N/A", value: "N/A" },
                                            ]}
                                            selectText={"Select Master Image"}
                                            value={item.itemUrlType}
                                            setoption={(selectedOption) => {
                                                const updatedDeskTopBanner = [...SingleData.mobItemBanner];
                                                updatedDeskTopBanner[index].itemUrlType = selectedOption.value;
                                                setSingleData({
                                                    ...SingleData,
                                                    mobItemBanner: updatedDeskTopBanner,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <InputTag
                                            padding={"8px"}
                                            fontSize={"12px"}
                                            maxLength={10000}
                                            placeholderType={"text"}
                                            placeholderText="URL.."
                                            value={item.itemUrl}
                                            onChange={(e) => {
                                                const updatedDeskTopItemUrl = [...SingleData.mobItemBanner];
                                                updatedDeskTopItemUrl[index].itemUrl = e.target.value;
                                                setSingleData({
                                                    ...SingleData,
                                                    mobItemBanner: updatedDeskTopItemUrl,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            ))
                            }
                            <div style={{ width: "120px", height: "auto" }}>
                                <Button
                                    buttonText={"ADD"}
                                    buttonBgColor={"#4164E3"}
                                    buttonTextColor={"#fff"}
                                    buttonIcon={<AddTO />}
                                    disabledButtonTextColor={"#fff"}
                                    disabledButtonBgColor={"#4164E3"}
                                    buttonClick={addNewMobileBanner}
                                />
                            </div>
                        </div> */}
          </div>

          {/* col - 2 */}
          <div
            className={styles.Col_sm1}
            style={{
              backgroundColor: mode ? "#232529" : "#fff",
              color: mode ? "#fff" : "#000",
            }}
          >
            <div>
              <InputTag
                labelText={"Product Title"}
                padding={"8px"}
                fontSize={"12px"}
                maxLength={10000}
                placeholderType={"text"}
                required={true}
                star={true}
                value={SingleData.title}
                onChange={(e) =>
                  setSingleData({ ...SingleData, title: e.target.value })
                }
                warning={errors.title}
              />
            </div>
            <div>
              <InputTag
                labelText={"Base Price"}
                padding={"8px"}
                fontSize={"12px"}
                maxLength={10000}
                placeholderType={"number"}
                required={true}
                star={true}
                value={SingleData.mrp}
                onChange={(e) =>
                  setSingleData({ ...SingleData, mrp: e.target.value })
                }
                warning={errors.mrp}
              />
            </div>
            {loading.getTypeloading ? (
              <Skeleton lines={1} thickness={4} />
            ) : (
              <div className={styles.Add_select_type}>
                {SingleData?.accessories?.map((acc, i) => (
                  <div className={styles.Add_select_type2} key={i}>
                    <div className={styles.Add_select_type21}>
                      <VerticalDropDown2
                        dropDownText={"--Select--"}
                        label={"Select Type"}
                        height={"37px"}
                        fontSize={"12px"}
                        options={ProductType.filter(
                          (item) => !selectedTypes.some(type => type.value === item.type_name)
                        ).map((item) => ({
                          label: item.type_name,
                          value: item.type_name,
                        }))}
                        onchange={(label) => {
                          const newSelectedTypes = [...selectedTypes];
                          newSelectedTypes[i] = label;
                          setSelectedTypes(newSelectedTypes);
                        }}
                        value={selectedTypes[i]}
                        star={true}
                      />
                    </div>
                    <div
                      className={styles.Add_select_type22}
                      onClick={() => handleRemoveAccessory(i)}
                    >
                      <DeleteTo />
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div>
              <div style={{ width: "120px", height: "auto" }}>
                <Button
                  buttonText={"ADD"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#fff"}
                  buttonIcon={<AddTO />}
                  disabledButtonTextColor={"#fff"}
                  disabledButtonBgColor={"#4164E3"}
                  buttonClick={handleAddAccessory}
                />
              </div>
            </div>
            <div>
              <InputTextArea
                // star={true}
                // ErrorText={"Character limit 50-160"}
                lableText={"Product Description (Optional)"}
                value={SingleData.description}
                onChange={(e) =>
                  setSingleData({ ...SingleData, description: e.target.value })
                }
              // warning={errors.description}
              />
            </div>
            <div className={styles.Redio_type_btn_con}>
              <div>
                Status<span style={{ color: "red" }}>*</span>{" "}
              </div>
              <div className={styles.redio_btn_div}>
                <div>
                  <InputRedio
                    lableText={"Yes"}
                    name={"OnlineVisible"}
                    checked={SingleData?.status === "Active"}
                    onChange={() =>
                      setSingleData({ ...SingleData, status: "Active" })
                    }
                  // disabled={disabled}
                  />
                </div>
                <div>
                  <InputRedio
                    lableText={"No"}
                    name={"OnlineVisible"}
                    checked={SingleData?.status === "Disable"}
                    onChange={() =>
                      setSingleData({ ...SingleData, status: "Disable" })
                    }
                  // disabled={disabled}
                  />
                </div>
                <div className={styles.redio_btn_div_err}>
                  {errors.status && (
                    <span style={{ fontSize: "12px", fontWeight: "400" }}>
                      {errors.status}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* col - 3 */}
          <div
            className={styles.Col_sm2}
            style={{
              backgroundColor: mode ? "#232529" : "#fff",
              color: mode ? "#fff" : "#000",
            }}
          >
            <div>
              <InputTag
                labelText={"Meta Title"}
                star={true}
                padding={"8px"}
                fontSize={"12px"}
                maxLength={10000}
                placeholderType={"text"}
                required={true}
                value={SingleData.metaTitle}
                onChange={(e) =>
                  setSingleData({ ...SingleData, metaTitle: e.target.value })
                }
                warning={errors.metaTitle}
              />
            </div>
            <div>
              <InputTextArea
                // star={true}
                // ErrorText={"Character limit 50-160"}
                lableText={"Meta Description (Optional)"}
                value={SingleData.metaDescription}
                onChange={(e) =>
                  setSingleData({
                    ...SingleData,
                    metaDescription: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <KeyWordGen
                lableText={"Keywords"}
                star={true}
                words={SingleData.metaKeywords}
                setWords={(e) =>
                  setSingleData({ ...SingleData, metaKeywords: e })
                }
                errorText={errors.metaKeywords}
              />
            </div>
            <div>
              <KeyWordGen
                lableText={"Focus Keywords"}
                star={true}
                words={SingleData.focusKeyword}
                setWords={(e) =>
                  setSingleData({ ...SingleData, focusKeyword: e })
                }
                errorText={errors.focusKeyword}
              />
            </div>
          </div>
        </div>

        {/* </form> */}
      </div>
    </div>
  );
};

export default AddCollection;
