import { useRef, useState } from "react";
import { useToast } from "../Toaster/Toaster";
import styles from "../../../pages/ordermanager/Orders.module.css";
import VerticalDropDown2 from "../FormComponents/VerticalDropDown2";
import InputTag from "../FormComponents/InputTag";
import VerticalDropDown from "../FormComponents/VerticalDropDown";
import FileUpload from "../OrderManager/FileUpload";
import Button from "../Dealers/Button";
import { Close, SaveTaxRateIcon } from "../../icons/Icon";

const RefundReceipt = ({
  mode,
  handleCloseModal,
  accounts = [],
  amount,
  updateReceiptData,
  receiptData,
}) => {
  const transactionAmountRef = useRef(null);
  const { show } = useToast();
  const [localReceiptData, setLocalReceiptData] = useState({
    paymentMethod: "Cash",
    transactionScreenshot: "",
    paymentAmount: "",
    transactionId: "",
    accountNumber: "",
    totalAmount: amount,
    receiptId: receiptData.receiptId,
    orderId: receiptData.orderId,
    paymentType: "Cash",
    reason: receiptData.reason ? receiptData.reason : "",
  });
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const resetFieldsOnPaymentMethodChange = (newPaymentMethod) => {
    const updatedData = {
      ...localReceiptData,
      paymentMethod: newPaymentMethod,
    };

    switch (newPaymentMethod) {
      case "Cash":
        updatedData.accountNumber = "";
        updatedData.paymentType = "";
        updatedData.transactionId = "";
        updatedData.transactionScreenshot = "";
        break;
      case "Photo":
        updatedData.paymentType = "";
        break;
      case "POD":
        if (localReceiptData.paymentType === "Cash") {
          updatedData.accountNumber = "";
          updatedData.paymentType = "";
          updatedData.transactionId = "";
          updatedData.transactionScreenshot = "";
        }
        break;
      default:
        break;
    }

    setLocalReceiptData(updatedData);
  };

  const validateField = (field, value) => {
    switch (field) {
      case "paymentAmount":
        return value && !isNaN(value)
          ? null
          : "Payment amount must be a valid number.";
      case "accountNumber":
        return value ? null : "Account number is required.";
      case "transactionScreenshot":
        return value ? null : "Transaction screenshot is required.";
      case "transactionId":
        return value ? null : "Transaction ID is required.";
      default:
        return null;
    }
  };

  const validateData = () => {
    const errors = {};
    const rules = {
      Cash: ["paymentAmount"],
      Photo: [
        "paymentAmount",
        "accountNumber",
        "transactionScreenshot",
        "transactionId",
      ],
      "Pay on Delivery":
        localReceiptData.paymentType === "Cash"
          ? ["paymentAmount"]
          : [
              "paymentAmount",
              "accountNumber",
              "transactionScreenshot",
              "transactionId",
            ],
    };

    const requiredFields = rules[localReceiptData.paymentMethod] || [];
    requiredFields.forEach((field) => {
      const error = validateField(field, localReceiptData[field]);
      if (error) errors[field] = error;
    });

    if (Object.keys(errors).length > 0) {
      // console.error("Validation Errors:", errors);
      show("Please fill all the required fields!", "warning");
      return false;
    }

    return true;
  };

  const buttonClick = async () => {
    if (localReceiptData.reason === "") {
      show("Reason cannot be empty!", "warning");
    } else if (parseFloat(localReceiptData.paymentAmount) !== amount) {
      show("Total Amount should be same as Refund Amount!", "warning");
    } else {
      if (validateData()) {
        setLoading(true);
        try {
          await updateReceiptData(localReceiptData);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    }
  };

  const handleFileChange = (file) => {
    setImage(file);
    setLocalReceiptData((prevState) => ({
      ...prevState,
      transactionScreenshot: file || "",
    }));
  };

  const handleFieldChange = (field, value) => {
    setLocalReceiptData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "ordered":
      case "Packed":
      case "success":
      case "Dispatched":
      case "Delivered":
        return "#31974D";

      case "Not Packed":
      case "ordered Pending":
      case "Payment Under Verification":
      case "Request For Edit":
      case "Edit request Approved":
      case "Dispatched Pending":
        return "#FF9B04";

      case "Packing In Progress":
        return "#9747FF";
      // return "#7698FF";

      case "Draft":
      case "In Progress":
      case "Cancelled":
      case "Request For Cancel":
      case "Payment Pending":
        return "#F93E3E";

      case "New Order":
      case "draft":
        return "#7698FF";

      default:
        return "";
    }
  };

  const refundOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Photo", value: "Photo" },
    { label: "Online", value: "Online" },
  ];

  return (
    <div
      className={styles.refund_receipt_2}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      <div className={styles.refund_receipt_header}>
        <p style={{ color: mode ? "#fff" : "#000" }}>Refund Receipt</p>
        <div className={styles.receipt_dets}>
          <p style={{ color: mode ? "#fff" : "#000" }}>
            Order Status:{" "}
            <span
              style={{
                color: getStatusColor(receiptData.orderStatus),
              }}
            >
              {receiptData.orderStatus || "DEV"}
            </span>
          </p>
        </div>
        <div
          className={styles.receipt_dets}
          style={{
            color: mode ? "#FFFFFF" : "#000000",
          }}
        >
          <p style={{ margin: 0 }}>
            {receiptData.orderId} / {receiptData.receiptId} /{" "}
            {receiptData.receiptDate}{" "}
          </p>
          {!receiptData.verifyBy ? "Not Received" : receiptData.verifyBy}
        </div>{" "}
      </div>

      <div className={styles.refund_receipt_body}>
        <div className={styles.refund_receipt_body_left}>
          <div>
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Payment Method"}
              placeholderText={""}
              placeholderType={"text"}
              value={receiptData.paymentMethod}
              maxLength={100}
              readOnly={true}
            />
          </div>

          {(receiptData.paymentMethod === "Online" ||
            receiptData.paymentMethod === "POD") && (
            <div>
              <InputTag
                padding={"8px"}
                fontSize={"12px"}
                labelText={"Payment Type"}
                placeholderText={""}
                placeholderType={"text"}
                value={receiptData.paymentType}
                maxLength={100}
                readOnly={true}
              />
            </div>
          )}

          <div>
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Total Amount"}
              placeholderText={""}
              placeholderType={"text"}
              value={receiptData.totalAmount}
              maxLength={100}
              readOnly={true}
            />
          </div>

          {(receiptData.paymentMethod === "Photo" ||
            (receiptData.paymentMethod === "POD" &&
              receiptData.paymentType === "Photo")) && (
            <div>
              <InputTag
                padding={"8px"}
                fontSize={"12px"}
                labelText={"Account"}
                placeholderText={""}
                placeholderType={"text"}
                value={
                  accounts.find(
                    (a) => a.accountNumber === receiptData.accountNumber
                  )
                    ? `${receiptData.accountNumber} / ${
                        accounts.find(
                          (a) => a.accountNumber === receiptData.accountNumber
                        ).bankName
                      }`
                    : ""
                }
                maxLength={100}
                readOnly={true}
              />
            </div>
          )}

          {(receiptData.paymentMethod === "Photo" ||
            (receiptData.paymentMethod === "POD" &&
              receiptData.paymentType === "Photo")) && (
            <FileUpload
              mode={mode}
              image={receiptData.transactionScreenshot}
              viewOnly={true}
            />
          )}

          {(receiptData.paymentMethod === "Photo" ||
            (receiptData.paymentMethod === "POD" &&
              receiptData.paymentType === "Photo") ||
            receiptData.paymentMethod === "Online") && (
            <div>
              <InputTag
                padding={"8px"}
                fontSize={"12px"}
                labelText={"Transaction ID / RRN No."}
                placeholderText={""}
                placeholderType={"text"}
                value={receiptData.transactionId}
                maxLength={100}
                readOnly={true}
              />
            </div>
          )}
        </div>

        <div className={styles.refund_receipt_body_right}>
          <div style={{ gap: "1rem", display: "flex", alignItems: "center" }}>
            <div style={{ width: "50%" }}>
              <VerticalDropDown2
                height={"37px"}
                fontSize={"12px"}
                label={"Refund Method"}
                dropDownText={localReceiptData.paymentMethod || "Select Method"}
                options={refundOptions}
                value={refundOptions.find(
                  (option) => option.value === localReceiptData.paymentMethod
                )}
                onchange={(option) => {
                  resetFieldsOnPaymentMethodChange(option.value);
                  transactionAmountRef.current.focus();
                }}
              />
            </div>
            <div style={{ width: "50%" }}>
              <InputTag
                padding={"8px"}
                fontSize={"12px"}
                labelText={"Refund Amount"}
                placeholderText={"000.00"}
                value={amount}
                readOnly={true}
              />
            </div>
          </div>

          {(localReceiptData.paymentMethod === "Online" ||
            localReceiptData.paymentMethod === "Photo") && (
            <>
              <div>
                <VerticalDropDown
                  height={"37px"}
                  fontSize={"12px"}
                  label={"Account"}
                  dropDownText={
                    accounts.find(
                      (a) => a.accountNumber === localReceiptData.accountNumber
                    )
                      ? `${localReceiptData.accountNumber} / ${
                          accounts.find(
                            (a) =>
                              a.accountNumber === localReceiptData.accountNumber
                          ).bankName
                        }`
                      : "Select"
                  }
                  options={accounts.map((a) => ({
                    label: `${a.accountNumber} / ${a.bankName}`,
                    value: a.accountNumber,
                  }))}
                  value={{
                    label: accounts.find(
                      (a) => a.accountNumber === localReceiptData.accountNumber
                    )
                      ? `${localReceiptData.accountNumber} / ${
                          accounts.find(
                            (a) =>
                              a.accountNumber === localReceiptData.accountNumber
                          ).bankName
                        }`
                      : "Select",
                    value: localReceiptData.accountNumber,
                  }}
                  onchange={(option) =>
                    handleFieldChange("accountNumber", option.value)
                  }
                />
              </div>

              {localReceiptData.paymentMethod === "Photo" && (
                <FileUpload
                  mode={mode}
                  image={image}
                  setImage={setImage}
                  onFileChange={handleFileChange}
                />
              )}

              <div>
                <InputTag
                  padding={"8px"}
                  fontSize={"12px"}
                  labelText={"Transaction ID"}
                  placeholderText={"Ref / UTR Number / Transaction ID"}
                  placeholderType={"number"}
                  value={localReceiptData.transactionId || ""}
                  onChange={(e) =>
                    handleFieldChange("transactionId", e.target.value)
                  }
                  maxLength={20}
                  required={true}
                />
              </div>
            </>
          )}

          <div className={styles.refund_receipt}>
            <p style={mode ? { color: "#FFFFFF" } : {}}>Enter reason</p>
            <textarea
              rows="5"
              name=""
              id=""
              placeholder="Enter refund reason"
              className={`${styles.textarea} ${
                mode ? styles.textarea_dark : ""
              }`}
              value={localReceiptData.reason || ""}
              onChange={(e) => handleFieldChange("reason", e.target.value)}
            ></textarea>
          </div>

          <div>
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Total Amount"}
              placeholderText={"Enter Refund Amount (In INR)"}
              placeholderType={"number"}
              ref={transactionAmountRef}
              value={localReceiptData.paymentAmount || ""}
              onChange={(e) => {
                handleFieldChange("paymentAmount", e.target.value);
              }}
              maxLength={10}
              required={true}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  buttonClick();
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.refund_receipt_btns}>
        <Button
          buttonBgColor={"#000"}
          buttonTextColor={"#fff"}
          buttonText={"Close"}
          buttonIcon={<Close />}
          buttonClick={handleCloseModal}
        />
        <Button
          buttonBgColor={"#4164E3"}
          buttonText={"Refund"}
          buttonTextColor={"#fff"}
          buttonIcon={<SaveTaxRateIcon />}
          buttonClick={buttonClick}
          disabled={
            localReceiptData.status === "Received" ||
            localReceiptData.status === "Cancelled" ||
            localReceiptData.status === "Refund" ||
            loading
          }
          loading={loading}
        />
      </div>
    </div>
  );
};

export default RefundReceipt;
