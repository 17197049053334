import React, { useState, useEffect, useRef } from "react";
import styles from "./Apps.module.css";
import { LeftArrowIcon2, RightArrowIcon2 } from "../../icons/Icon";

const ImageSlider = ({ images, width, n = 4, autoSlideInterval }) => {
  const [currentIndex, setCurrentIndex] = useState(n);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const [noOfSlides, setNoOfSlides] = useState(n);

  const sliderRef = useRef(null);
  const autoSlideRef = useRef(null);
  const userInteractionTimeout = useRef(null);

  const totalSlides = images?.length;
  const safeImages = Array.isArray(images) ? images : [];
  const slides = [
    ...safeImages.slice(-n),
    ...safeImages,
    ...safeImages.slice(0, n),
  ];

  useEffect(() => {
    const updateN = () => {
      const width = window.innerWidth;
      if (width > 1200) {
        setNoOfSlides(4);
      } else if (width > 900) {
        setNoOfSlides(3);
      } else if (width > 600) {
        setNoOfSlides(2);
      } else {
        setNoOfSlides(1);
      }
    };

    updateN();
    window.addEventListener("resize", updateN);
    return () => window.removeEventListener("resize", updateN);
  }, []);

  useEffect(() => {
    if (!isTransitioning) return;

    if (currentIndex >= totalSlides + n) {
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentIndex(n);
      }, 300);
    }

    if (currentIndex < n) {
      setTimeout(() => {
        setIsTransitioning(false);
        setCurrentIndex(totalSlides + n - 1);
      }, 300);
    }
  }, [currentIndex, totalSlides, n, isTransitioning]);

  useEffect(() => {
    if (!isTransitioning) {
      requestAnimationFrame(() => setIsTransitioning(true));
    }
  }, [isTransitioning]);

  const nextSlide = () => {
    setCurrentIndex((prev) => prev + 1);
    handleUserInteraction();
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => prev - 1);
    handleUserInteraction();
  };

  useEffect(() => {
    if (autoSlideInterval && !isUserInteracting) startAutoSlide();
    return () => clearInterval(autoSlideRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSlideInterval, isUserInteracting]);

  const startAutoSlide = () => {
    clearInterval(autoSlideRef.current);
    autoSlideRef.current = setInterval(() => {
      setCurrentIndex((prev) => prev + 1);
    }, autoSlideInterval);
  };

  const handleUserInteraction = () => {
    setIsUserInteracting(true);
    clearInterval(autoSlideRef.current);
    clearTimeout(userInteractionTimeout.current);
    userInteractionTimeout.current = setTimeout(() => {
      setIsUserInteracting(false);
    }, 5000);
  };

  const handleMouseEnter = () => {
    setIsUserInteracting(true);
    clearInterval(autoSlideRef.current);
  };

  const handleMouseLeave = () => {
    userInteractionTimeout.current = setTimeout(() => {
      setIsUserInteracting(false);
    }, 3000);
  };

  return (
    <div
      className={styles.slider_container}
      style={{ maxWidth: width }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        className={`${styles.slider_arrow} ${styles.left}`}
        onClick={prevSlide}
      >
        <LeftArrowIcon2 />
      </button>

      <div className={styles.slider_wrapper}>
        <div
          className={styles.slider_track}
          ref={sliderRef}
          style={{
            transform: `translateX(-${(100 / noOfSlides) * currentIndex}%)`,
            width: `${(100 / noOfSlides) * slides.length}%`,
            transition: isTransitioning ? "transform 0.3s ease-in-out" : "none",
          }}
        >
          {slides.map((img, index) => (
            <div
              key={index}
              className={styles.slider_item}
              style={{ width: `${100 / noOfSlides}%` }}
            >
              <img
                src={`${process.env.REACT_APP_S3URL}${img}`}
                alt={`slide-${index}`}
                className={styles.slider_image}
              />
            </div>
          ))}
        </div>
      </div>

      <button
        className={`${styles.slider_arrow} ${styles.right}`}
        onClick={nextSlide}
      >
        <RightArrowIcon2 />
      </button>
    </div>
  );
};

export default ImageSlider;

// import React, { useState, useEffect, useRef } from "react";
// import styles from "./Apps.module.css";
// import { LeftArrowIcon2, RightArrowIcon2 } from "../../icons/Icon";

// const ImageSlider = ({ images, width, n = 4 }) => {
//   const [currentIndex, setCurrentIndex] = useState(n);
//   const [isTransitioning, setIsTransitioning] = useState(true);
//   const sliderRef = useRef(null);

//   const totalSlides = images.length;

//   const slides = [...images.slice(-n), ...images, ...images.slice(0, n)];

//   useEffect(() => {
//     if (!isTransitioning) return;

//     if (currentIndex >= totalSlides + n) {
//       setTimeout(() => {
//         setIsTransitioning(false);
//         setCurrentIndex(n);
//       }, 300);
//     }

//     if (currentIndex < n) {
//       setTimeout(() => {
//         setIsTransitioning(false);
//         setCurrentIndex(totalSlides + n - 1);
//       }, 300);
//     }
//   }, [currentIndex, totalSlides, n, isTransitioning]);

//   useEffect(() => {
//     if (!isTransitioning) {
//       requestAnimationFrame(() => setIsTransitioning(true));
//     }
//   }, [isTransitioning]);

//   const nextSlide = () => setCurrentIndex((prev) => prev + 1);
//   const prevSlide = () => setCurrentIndex((prev) => prev - 1);

//   return (
//     <div className={styles.slider_container} style={{ maxWidth: width }}>
//       <button
//         className={`${styles.slider_arrow} ${styles.left}`}
//         onClick={prevSlide}
//       >
//         <LeftArrowIcon2 />
//       </button>

//       <div className={styles.slider_wrapper}>
//         <div
//           className={styles.slider_track}
//           ref={sliderRef}
//           style={{
//             transform: `translateX(-${(100 / n) * currentIndex}%)`,
//             width: `${(100 / n) * slides.length}%`,
//             transition: isTransitioning ? "transform 0.3s ease-in-out" : "none",
//           }}
//         >
//           {slides.map((img, index) => (
//             <div
//               key={index}
//               className={styles.slider_item}
//               style={{ width: `${100 / n}%` }}
//             >
//               <img
//                 src={img}
//                 alt={`slide-${index}`}
//                 className={styles.slider_image}
//               />
//             </div>
//           ))}
//         </div>
//       </div>

//       <button
//         className={`${styles.slider_arrow} ${styles.right}`}
//         onClick={nextSlide}
//       >
//         <RightArrowIcon2 />
//       </button>
//     </div>
//   );
// };

// export default ImageSlider;
