import { DragNDropIcon } from "../../icons/Icon";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { useRef, useState } from "react";

const MultipleImageUpload = ({
  mode,
  images,
  setImages,
  fileType = "image/*",
  uploadFiles,
  maxImages = 5,
  setRemovedImages = [],
  onChange,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const validateFileType = (file) => file.type.startsWith("image/");

  const handleUpload = async (files) => {
    setLoading(true);
    try {
      const validFiles = Array.from(files).filter(validateFileType);
      if (validFiles.length === 0) {
        alert("Please upload only image files.");
        setLoading(false);
        return;
      }

      if (images.length + validFiles.length > maxImages) {
        alert(`You can only upload up to ${maxImages} images.`);
        setLoading(false);
        return;
      }

      await uploadFiles(validFiles);

      setImages((prevImages) => {
        const updatedImages = [...prevImages, ...validFiles];

        if (onChange) onChange(updatedImages);

        return updatedImages;
      });
    } catch (error) {
      alert("Upload failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length > 0) {
      handleUpload(files);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleUpload(files);
    }
  };

  const onButtonClick = () => {
    if (!loading && images.length < maxImages) {
      inputRef.current.click();
    }
  };

  const handleDelete = (index) => {
    if (loading) return;

    const removedImage = images[index];

    const updatedImages = images.filter((_, i) => i !== index);

    setImages(updatedImages);
    setRemovedImages((prev) => [...prev, removedImage]);

    if (onChange) onChange(updatedImages);
  };

  return (
    <>
      <div className={styles.multiple_img_upload}>
        <div>
          <div
            className={styles.file_upload}
            style={
              mode ? { backgroundColor: "#1B1D21", borderColor: "#1B1D21" } : {}
            }
            onDragOver={handleDrag}
            onDrop={handleDrop}
            onClick={onButtonClick}
          >
            {loading ? (
              <p style={{ color: mode ? "#FFFFFF" : "#646B88" }}>
                Uploading...
              </p>
            ) : (
              <div className={styles.file_upload_text}>
                <DragNDropIcon />
                <p>Drag & Drop multiple images</p>
                <p style={mode ? { color: "#fff" } : {}}>
                  or <span>browse files</span> on your computer
                </p>
                <input
                  type="file"
                  id="fileInput"
                  accept={fileType}
                  multiple
                  style={{ display: "none" }}
                  ref={inputRef}
                  onChange={handleChange}
                  disabled={loading}
                />
                {dragActive && (
                  <div
                    className={styles.drag_overlay}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
              </div>
            )}
          </div>

          <div className={styles.file_upload_footer}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ fontSize: "12px" }}>Only Images Allowed</span>
              {images.length !== 0 && (
                <span
                  style={{
                    color: "#F93E3E",
                    cursor: "pointer",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    setRemovedImages(images);
                    setImages([]);
                    if (onChange) onChange([]);
                  }}
                >
                  Remove All
                </span>
              )}
            </div>
          </div>
        </div>
        {images.length !== 0 &&
          images.map((image, index) => (
            <div key={index} className={styles.image_preview_wrapper}>
              <img
                src={
                  image instanceof File
                    ? URL.createObjectURL(image)
                    : process.env.REACT_APP_S3URL + image
                }
                alt={`Uploaded ${index}`}
                className={styles.image_preview}
              />
              <span
                className={styles.delete_icon}
                onClick={() => handleDelete(index)}
              >
                ❌
              </span>
            </div>
          ))}
      </div>
    </>
  );
};

export default MultipleImageUpload;
