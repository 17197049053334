// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
// import { useAuth } from '../../components/context/Auth';
// import { Facebook, Instagram, Pinterest, Spinner, Twitter, Youtube } from '../../components/icons/Icon';
// import Loading from '../../components/Loading';
// import Modal from '../../components/Modal';
// import ImageCrop from './ImageCrop';
// import styles from "./Kyc.module.css"
// import SearchPlace from './SearchPlace';

// const Kyc = () => {

//     const navigate = useNavigate()
//     const { managerId } = useAuth();
//     const params = useParams();
//     const dealerId = parseInt(params.id)
//     const [address, setAddress] = useState({
//         place_id: "",
//         formatted_address: ""
//     });
//     const [kycSuccesText, setKycSuccesText] = useState(false)

//     const [selectedProducts, setSelectedProducts] = useState([]);
//     const [allProducts, setAllProducts] = useState([])
//     const [showModal, setShowModal] = useState(false)
//     const [managers, setManagers] = useState([])
//     const [statusData, setStatusData] = useState()
//     const [genralInfo, setGenralInfo] = useState({
//         name: '',
//         product: [],
//         storeName: '',
//         mobile: '',
//         email: '',
//         designation: '',
//         whatsapp: '',
//         gstuin: '',
//         street: '',
//         pincode: '',
//         city: '',
//         district: '',
//         state: '',
//         country: '',
//         facebook: '',
//         instagram: '',
//         youtube: '',
//         pinterest: '',
//         twitter: '',
//         storeDesc: '',
//         experience: '',
//         google: '',
//         nrne: '',
//         catalogStatus: '',
//         profileImage: '',
//         storeImg: '',
//         leadBlock: '',
//         status: '',
//         managerId: '',
//     })
//     const [rejectionList, setRejectionList] = useState({})
//     const [msgShow, setMsgShow] = useState("");
//     const [showRejectModal, setShowRejectModal] = useState(false)
//     const [showRevertbackModal, setShowRevertbackModal] = useState(false)
//     const [showLeadsBlockModal, setShowLeadsBlockModal] = useState(false)
//     const [loading, setLoading] = useState(false)
//     const [loadingInput, setLoadingInput] = useState(false)
//     const [showAcceptModal, setShowAcceptModal] = useState(false);
//     const [profileImageBlob, setProfileImageBlob] = useState();
//     const [storeImageBlob, setStoreImageBlob] = useState();
//     const kycDivRef = useRef(null);
//     const scrollToTop = () => {
//         if (kycDivRef.current) {
//             kycDivRef.current.scrollTop = 0;
//         }
//     };
//     const scrollToMiddle = () => {
//         if (kycDivRef.current) {
//             kycDivRef.current.scrollTop = 800;
//         }
//     };
//     const [error, setError] = useState({
//         profileImage: '',
//         storeImg: '',
//         selectedProducts: '',
//     })

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         updateKyc()

//         // if (!genralInfo.profileImage ? !profileImageBlob : false) {
//         //     setError({ ...error, profileImage: "Please upload profile image" })
//         //     alert('Please upload profile image')
//         //     scrollToTop()
//         // }
//         // else if (!genralInfo.storeImg ? !storeImageBlob : false) {
//         //     setError({ ...error, storeImg: "Please upload store image" })
//         //     alert('Please upload store image')
//         //     scrollToMiddle()
//         // }
//         // // else if (selectedProducts.length ? selectedProducts.find(obj => !obj.status === 'Active'):"") {
//         // //     setError({ ...error, selectedProducts: "Please select atleast one product" })
//         // // }
//         // else {
//         //     updateKyc();
//         // }
//         // setValidated(true);
//     };

//     useEffect(() => {
//         if (dealerId) {
//             setProgressPercent(prevState => ({
//                 ...prevState,
//                 profileImage: true
//             }));
//             setProgressPercent(prevState => ({
//                 ...prevState,
//                 storeImg: true
//             }));
//             var myHeaders = new Headers();
//             myHeaders.append("Content-Type", "application/json");
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "dealerId": dealerId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "kyc/dealer", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === "success") {
//                         if (result.data) {
//                             setProgressPercent(prevState => ({
//                                 ...prevState,
//                                 profileImage: false
//                             }));
//                             setProgressPercent(prevState => ({
//                                 ...prevState,
//                                 storeImg: false
//                             }));
//                             setGenralInfo(prevState => ({
//                                 ...prevState,
//                                 name: result.data.name ? result.data.name : "",
//                                 profileImage: result.data.profileImage ? result.data.profileImage : "",
//                                 storeImg: result.data.storeImg ? result.data.storeImg : "",
//                                 storeName: result.data.storeName ? result.data.storeName : "",
//                                 mobile: result.data.mobile ? result.data.mobile : "",
//                                 email: result.data.email ? result.data.email : "",
//                                 designation: result.data.designation ? result.data.designation : "",
//                                 whatsapp: result.data.whatsapp ? result.data.whatsapp : "",
//                                 gstuin: result.data.gstuin ? result.data.gstuin : "",
//                                 street: result.data.street ? result.data.street : "",
//                                 pincode: result.data.pincode ? result.data.pincode : "",
//                                 city: result.data.city ? result.data.city : "",
//                                 district: result.data.district ? result.data.district : "",
//                                 state: result.data.state ? result.data.state : "",
//                                 country: result.data.country ? result.data.country : "",
//                                 facebook: result.data.facebook ? result.data.facebook : "",
//                                 instagram: result.data.instagram ? result.data.instagram : "",
//                                 youtube: result.data.youtube ? result.data.youtube : "",
//                                 pinterest: result.data.pinterest ? result.data.pinterest : "",
//                                 twitter: result.data.twitter ? result.data.twitter : "",
//                                 storeDesc: result.data.storeDesc ? result.data.storeDesc : "",
//                                 experience: result.data.experience ? result.data.experience : "",
//                                 google: result.data.google ? result.data.google : "",
//                                 nrne: result.data.nrne ? result.data.nrne : "",
//                                 catalogStatus: result.data.catalogStatus ? result.data.catalogStatus : "",
//                                 leadBlock: result.data.leadBlock ? result.data.leadBlock : false,
//                                 managerId: result.data.managerId ? result.data.managerId : "",
//                                 status: result.data.status ? result.data.status : "",
//                                 product: result.products ? result.products : "",
//                             }))
//                             setAddress(prevState => ({
//                                 ...prevState,
//                                 place_id: result.data.place_id ? result.data.place_id : "",
//                                 formatted_address: result.data.formatted_address ? result.data.formatted_address : ""
//                             }))
//                         }
//                         if (result.products) {
//                             setAllProducts(result.products)
//                             setSelectedProducts(result.data.selectedProducts)
//                         }
//                         if (result.managers) {
//                             setManagers(result.managers)
//                         }
//                     }
//                 })
//                 .catch(error => //console.log('error', error));
//         }
//     }, [dealerId, managerId])

//     const selectModal = (info) => {
//         setShowModal(!showModal)
//     }
//     const [hw, setHW] = useState({ width: 0, height: 0, imgName: "profile" })
//     const [progressPercent, setProgressPercent] = useState({
//         profileImage: false,
//         storeImg: false,
//         qrCode: false,
//         adharImg: false,
//         gstuinImg: false
//     })
//     const [inputImg, setInputImg] = useState('')
//     const onInputChange = (e) => {
//         let url = URL.createObjectURL(e.target.files[0]);
//         setInputImg(url)
//         selectModal()
//     }

//     const updateKyc = () => {
//         setMsgShow("")
//         setLoading(true)
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//         const formdata = new FormData();
//         formdata.append("name", genralInfo.name);
//         formdata.append("designation", genralInfo.designation);
//         formdata.append("storeName", genralInfo.storeName);
//         formdata.append("street", genralInfo.street);
//         formdata.append("pincode", genralInfo.pincode);
//         formdata.append("city", genralInfo.city);
//         formdata.append("district", genralInfo.district);
//         formdata.append("state", genralInfo.state);
//         formdata.append("country", genralInfo.country);
//         formdata.append("phone", genralInfo.mobile);
//         formdata.append("whatsapp", genralInfo.whatsapp);
//         formdata.append("email", genralInfo.email);
//         formdata.append("gstuin", genralInfo.gstuin);
//         formdata.append("place_id", address.place_id);
//         formdata.append("formatted_address", address.formatted_address);
//         formdata.append("assignedManager", genralInfo.managerId);
//         formdata.append("nrne", genralInfo.nrne);
//         formdata.append("catalogStatus", genralInfo.catalogStatus);
//         formdata.append("leadBlock", genralInfo.leadBlock);
//         formdata.append("status", genralInfo.status);
//         formdata.append("experience", genralInfo.experience);
//         formdata.append("selectedProducts", JSON.stringify(selectedProducts));
//         formdata.append("storeDesc", genralInfo.storeDesc);
//         formdata.append("facebook", genralInfo.facebook);
//         formdata.append("twitter", genralInfo.twitter);
//         formdata.append("instagram", genralInfo.instagram);
//         formdata.append("youtube", genralInfo.youtube);
//         formdata.append("pinterest", genralInfo.pinterest);
//         formdata.append("rejectionList", JSON.stringify(rejectionList));
//         formdata.append("profileImage", profileImageBlob);
//         formdata.append("storeImg", storeImageBlob);
//         formdata.append("linkedin", "https://www.linkedin.com/login");
//         formdata.append("google", "https://www.google.co.in/");
//         formdata.append("snapchat", "https://accounts.snapchat.com/accounts/login?continue=%2Faccounts%2Fwelcome");
//         formdata.append("tumblr", "https://www.tumblr.com/login");
//         formdata.append("vimeo", "https://vimeo.com/log_in");
//         formdata.append("behance", "https://www.behance.net/gallery/58902999/Login");

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: formdata,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "kyc/dealerKyc?managerId=" + managerId + "&dealerId=" + dealerId, requestOptions)
//             .then((response) => response.json())
//             .then(result => {
//                 // setInputImg("")
//                 if (result.status === "success") {
//                     setMsgShow(result.msg)
//                     setShowAcceptModal(true)
//                 }
//                 else {
//                     setMsgShow(result.msg)
//                     setShowAcceptModal(true)
//                     setStatusData(result.status)
//                 }
//             })
//             .catch((error) => console.error(error))
//             .finally(() => setLoading(false))
//     }

//     var points = new Array(100);
//     for (var i = 0; i < 5; i++) {
//         points[i] = i + 1;
//     }

//     const [leadBlock, setLeadBlock] = useState()

//     useEffect(() => {
//         if (genralInfo.pincode.length === 6) {
//             setLoadingInput(true)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "keyword": genralInfo.pincode,
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === "success") {
//                         setGenralInfo(prevState => ({
//                             ...prevState,
//                             city: result.SubDistName[0] ? result.SubDistName[0].toUpperCase() : "",
//                             district: result.districts[0] ? result.districts[0] : "",
//                             state: result.states[0] ? result.states[0] : "",
//                         }))
//                         setLoadingInput(false)
//                     }
//                 })
//                 .catch(error => //console.log('error', error));
//         }
//     }, [genralInfo.pincode])

//     // useEffect(() => {
//     //     setKycSuccesText("SAVE");
//     // },[genralInfo.status])

//     return (
//         <React.Fragment>

//             <Modal
//                 show={showRejectModal}
//                 close={setShowRejectModal}
//                 closeBtn={true}
//                 heading="KYC REJECTED"
//                 content={
//                     <div className={styles.text_center}>
//                         <h3>KYC REJECTED</h3>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="CANCEL" handleSubmit={() => setShowRejectModal(false)} />
//                             <GreenButton title="OK" handleSubmit={() => { setShowRejectModal(false) }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showAcceptModal}
//                 close={setShowAcceptModal}
//                 closeBtn={true}
//                 heading={statusData === 'failed' ? 'ERROR' : "KYC STATUS"}
//                 content={
//                     <div className={styles.text_center}>
//                         <p className={styles.message}>{msgShow}</p>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="CANCEL" handleSubmit={() => setShowAcceptModal(false)} />
//                             <GreenButton title="OK" handleSubmit={() => { navigate("/dealers") }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showRevertbackModal}
//                 close={setShowRevertbackModal}
//                 closeBtn={true}
//                 heading="REJECT LIST"
//                 content={
//                     <div className={styles.text_center}>
//                         <div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Profile Image</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.profileImage}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.profileImage) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'profileImage')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, profileImage: "please select valid profile image" }) }
//                                         }}
//                                         checked={rejectionList.profileImage ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Store Image</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.storeImg}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.storeImg) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'storeImg')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, storeImg: "please select valid store image" }) }
//                                         }}
//                                         checked={rejectionList.storeImg ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Dealer Name</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.dealerName} </span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.dealerName) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'dealerName')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, dealerName: "please enter valid dealer name" }) }
//                                         }}
//                                         checked={rejectionList.dealerName ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Email Address</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.emailAddress}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.emailAddress) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'emailAddress')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, emailAddress: "please enter valid email address" }) }
//                                         }}
//                                         checked={rejectionList.emailAddress ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Mobile No.</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.mobileNumber}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.mobileNumber) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'mobileNumber')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, mobileNumber: "please enter valid mobile number" }) }
//                                         }}
//                                         checked={rejectionList.mobileNumber ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>GSTUIN</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.gstuin}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.gstuin) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'gstuin')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, gstuin: "please enter valid GST number" }) }
//                                         }}
//                                         checked={rejectionList.gstuin ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Designation</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.designation}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.designation) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'designation')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, designation: "please enter valid designation name" }) }
//                                         }}
//                                         checked={rejectionList.designation ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Store Name</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.storeName}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.storeName) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'storeName')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, storeName: "please enter valid trade name" }) }
//                                         }}
//                                         checked={rejectionList.storeName ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Whatsapp</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.whatsapp}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.whatsapp) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'whatsapp')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, whatsapp: "please enter valid whatsapp number" }) }
//                                         }}
//                                         checked={rejectionList.whatsapp ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>City</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.city}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.city) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'city')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, city: "please enter valid city name" }) }
//                                         }}
//                                         checked={rejectionList.city ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Country</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.country}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.country) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'country')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, country: "please enter valid country name" }) }
//                                         }}
//                                         checked={rejectionList.country ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Pincode</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.pincode}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.pincode) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'pincode')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, pincode: "please enter valid pincode number" }) }
//                                         }}
//                                         checked={rejectionList.pincode ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>State</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.state}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.state) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'state')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, state: "please enter valid state name" }) }
//                                         }}
//                                         checked={rejectionList.state ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectionList_div}>
//                                 <span className={styles.rejectionList}>Street</span>
//                                 <span className={styles.rejectionList_text}>{rejectionList.street}</span>
//                                 <span className={styles.rejectionList_checkbox}>
//                                     <input
//                                         type="checkbox"
//                                         onClick={() => {
//                                             if (rejectionList.street) {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'street')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }
//                                             else { setRejectionList({ ...rejectionList, street: "please enter valid street name" }) }
//                                         }}
//                                         checked={rejectionList.street ? true : false}
//                                         className={styles.rejectCheckbox}
//                                     />
//                                 </span>
//                             </div>
//                             <div className={styles.rejectListModal_btn}>
//                                 <RedOutButton title="CANCEL" handleSubmit={() => setShowRevertbackModal(false)} />
//                                 <GreenButton title="ASK TO CHECK" handleSubmit={() => { setShowRevertbackModal(false) }} />
//                             </div>
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showLeadsBlockModal}
//                 close={setShowLeadsBlockModal}
//                 closeBtn={true}
//                 heading="Lead Block"
//                 content={
//                     <div className={styles.text_center}>
//                         <h3>Are you sure want to {genralInfo.leadBlock === "Active" ? "enable" : "disable"} organic leads</h3>
//                         <div className={styles.leadModal_btn}>
//                             <RedOutButton title="CANCEL" handleSubmit={() => setShowLeadsBlockModal(false)} />
//                             <GreenButton title="OK"
//                                 handleSubmit={() => {
//                                     setGenralInfo(prevState =>
//                                     ({
//                                         ...prevState,
//                                         leadBlock: leadBlock
//                                     }));
//                                     setShowLeadsBlockModal(false);
//                                     setKycSuccesText(true);
//                                 }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <ImageCrop
//                 displayModal={showModal}
//                 closeModal={selectModal}
//                 inputImg={inputImg}
//                 hw={hw}
//                 setInputImg={setInputImg}
//                 setProgressPercent={setProgressPercent}
//                 setAddData={setGenralInfo}
//                 dealerId={dealerId}
//                 setProfileImageBlob={setProfileImageBlob}
//                 setStoreImageBlob={setStoreImageBlob}
//             />

//             <form onSubmit={handleSubmit} className={styles.kyc_main_container_div}>
//                 <div className={styles.dflex_space_between}>
//                     <div>
//                         <div className={styles.text_underline}>
//                             <span onClick={() => navigate(-1)}>
//                                 &lt;&nbsp;&nbsp;<span className={styles.goBack}>Go Back</span>
//                             </span>
//                         </div>
//                         <div className={styles.demo}>
//                             {genralInfo.storeName}
//                         </div>
//                     </div>
//                     <div className={styles.kyc_btn_div}>
//                         {/* <RedOutButton btnType="button" title="REJECT" css="" handleSubmit={() => setShowRejectModal(true)} />
//                         <GreenOutButton btnType="button" title="REVERT BACK" css={styles.revertBack_btn} handleSubmit={() => setShowRevertbackModal(true)} /> */}
//                         {
//                             kycSuccesText ? <GreenButton btnType="submit" disabled={rejectionList.length > 0 ? true : false} title={loading ? <Spinner size="15" /> : " SAVE & UPDATE"} css="" /> : null
//                         }

//                     </div>
//                 </div>

//                 <div className={styles.kyc_div_height} ref={kycDivRef}>
//                     <h3 className={styles.billingDetails_heading}>Billing Details</h3>
//                     <div className={styles.billingDetails_div}>
//                         <div className={styles.profileImage_div}>
//                             <label className={error.profileImage && genralInfo.profileImage.length === 0 ? styles.profileImage_alert : styles.profileImage} >
//                                 {
//                                     progressPercent.profileImage && !genralInfo.profileImage ?
//                                         <Loading />
//                                         :
//                                         <>
//                                             {!profileImageBlob ?
//                                                 (
//                                                     !genralInfo.profileImage ? <div className={error.profileImage && genralInfo.profileImage.length === 0 ? styles.profile_bg_alert : styles.profile_bg}>
//                                                         Upload Profile Image
//                                                     </div>
//                                                         :
//                                                         <img
//                                                             src={`${process.env.REACT_APP_S3URL + genralInfo.profileImage}?${new Date().getTime()}`}
//                                                             alt="Profile"
//                                                             className={styles.profile_Image_div}
//                                                             title={genralInfo.name}
//                                                         />
//                                                 )
//                                                 :
//                                                 <img
//                                                     src={profileImageBlob && URL.createObjectURL(profileImageBlob)}
//                                                     alt="Profile"
//                                                     className={styles.profile_Image_div}
//                                                     title={genralInfo.name}
//                                                 />
//                                             }
//                                         </>
//                                 }
//                                 <input
//                                     type="file"
//                                     accept='image/*'
//                                     className={styles.d_none}
//                                     onChange={(e) => { onInputChange(e); selectModal(); setHW({ width: 200, height: 200, imgName: "profile" }); setKycSuccesText(true) }}
//                                 />
//                             </label>
//                             <div className={styles.kyc_toggle_btn_profile_img}>
//                                 <label
//                                     className={!rejectionList.profileImage ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                     onClick={() => {
//                                         const withoutA = Object.fromEntries(
//                                             Object.entries(rejectionList).filter(([key]) => key !== 'profileImage')
//                                         )
//                                         setKycSuccesText(true);
//                                         setRejectionList(withoutA)
//                                     }}
//                                 >
//                                     &#10003;
//                                 </label>
//                                 <label
//                                     className={rejectionList.profileImage ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                     onClick={() => { setRejectionList({ ...rejectionList, profileImage: "please select valid profile image" }); setKycSuccesText(true) }}
//                                 >
//                                     X
//                                 </label>
//                             </div>
//                         </div>

//                         <div className='w-100 d-flex ms-5'>

//                             <div className='w-100'>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Full Name</div>
//                                         <input
//                                             required
//                                             type="text"
//                                             name="Name"
//                                             value={genralInfo.name}
//                                             onChange={(e) => {
//                                                 setGenralInfo({ ...genralInfo, name: e.target.value });
//                                                 setKycSuccesText(true)
//                                             }}
//                                             placeholder="Enter Full Name"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.name ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'name')
//                                                 )
//                                                 setKycSuccesText(true);
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.name ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, name: "please enter valid name" }); setKycSuccesText(true) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Mobile Number</div>
//                                         <input
//                                             required
//                                             type="number"
//                                             name="mobile"
//                                             value={genralInfo.mobile}
//                                             onChange={(e) => {
//                                                 setGenralInfo({ ...genralInfo, mobile: e.target.value });
//                                                 setKycSuccesText(true);
//                                             }}
//                                             placeholder="Enter Mobile Number"
//                                             className={styles.kyc_input}
//                                             maxLength="10"
//                                             onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.mobileNumber ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'mobileNumber')
//                                                 )
//                                                 setKycSuccesText(true);
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.mobileNumber ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, mobileNumber: "please enter valid mobile number" }); setKycSuccesText(true); }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Designation</div>
//                                         <input
//                                             required
//                                             type="text"
//                                             name="designation"
//                                             value={genralInfo.designation}
//                                             onChange={(e) => {
//                                                 setGenralInfo({ ...genralInfo, designation: e.target.value });
//                                                 setKycSuccesText(true);
//                                             }}
//                                             placeholder="Enter Designation"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.designation ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'designation')
//                                                 )
//                                                 setKycSuccesText(true);
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.designation ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, designation: "please enter valid designation name" }); setKycSuccesText(true); }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className='w-100'>
//                                 <div className={`${styles.row} justify-content-end`}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Company Name</div>
//                                         <input
//                                             required
//                                             type="text"
//                                             name="tradeName"
//                                             value={genralInfo.storeName}
//                                             onChange={(e) => {
//                                                 setGenralInfo({ ...genralInfo, storeName: e.target.value })
//                                                 setKycSuccesText(true);
//                                             }}
//                                             placeholder="Enter Trade Name"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.storeName ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'storeName')
//                                                 )
//                                                 setRejectionList(withoutA);
//                                                 setKycSuccesText(true);
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.storeName ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, storeName: "please enter valid trade name" }); setKycSuccesText(true); }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>

//                                 <div className={`${styles.row} justify-content-end`}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Whatsapp Number</div>
//                                         <input
//                                             required
//                                             type="text"
//                                             name="whatsapp"
//                                             value={genralInfo.whatsapp}
//                                             onChange={(e) => {
//                                                 setGenralInfo({ ...genralInfo, whatsapp: e.target.value });
//                                                 setKycSuccesText(true);
//                                             }}
//                                             placeholder="Enter Whatsapp Number"
//                                             className={styles.kyc_input}
//                                             maxLength="10"
//                                             onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.whatsapp ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'whatsapp')
//                                                 )
//                                                 setRejectionList(withoutA);
//                                                 setKycSuccesText(true);
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.whatsapp ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, whatsapp: "please enter valid whatsapp number" }); setKycSuccesText(true); }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={`${styles.row} justify-content-end`}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Email Address</div>
//                                         <input
//                                             required
//                                             type="email"
//                                             name="email"
//                                             value={genralInfo.email}
//                                             onChange={(e) => {
//                                                 setGenralInfo({ ...genralInfo, email: e.target.value });
//                                                 setKycSuccesText(true);
//                                             }}
//                                             placeholder="Enter Email Address"
//                                             className={styles.kyc_input} />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.emailAddress ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'emailAddress')
//                                                 )
//                                                 setRejectionList(withoutA);
//                                                 setKycSuccesText(true);
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.emailAddress ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, emailAddress: "please enter valid email address" }); setKycSuccesText(true); }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={`${styles.row} justify-content-end`}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>GSTUIN (OPTIONAL)</div>
//                                         <input
//                                             type="text"
//                                             name="gstuin"
//                                             value={genralInfo.gstuin}
//                                             onChange={(e) => {
//                                                 setGenralInfo({ ...genralInfo, gstuin: e.target.value });
//                                                 setKycSuccesText(true);
//                                             }}
//                                             placeholder="Enter GSTUIN"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.gstuin ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'gstuin')
//                                                 )
//                                                 setRejectionList(withoutA);
//                                                 setKycSuccesText(true);
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.gstuin ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, gstuin: "please enter valid GST number" }); setKycSuccesText(true); }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>

//                         </div>

//                     </div>

//                     <div className='d-flex'>
//                         <div className='w-100'>
//                             <div className={styles.row}>
//                                 <div className={styles.col_10}>
//                                     <div className={styles.margin}>Street Address</div>
//                                     <input
//                                         required
//                                         value={genralInfo.street}
//                                         onChange={(e) => {
//                                             setGenralInfo({ ...genralInfo, street: e.target.value });
//                                             setKycSuccesText(true);
//                                         }}
//                                         type="text"
//                                         placeholder="Enter Street"
//                                         className={styles.kyc_input}
//                                     />
//                                 </div>
//                                 <div className={styles.kyc_toggle_btn}>
//                                     <label
//                                         className={!rejectionList.street ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                         onClick={() => {
//                                             const withoutA = Object.fromEntries(
//                                                 Object.entries(rejectionList).filter(([key]) => key !== 'street')
//                                             )
//                                             setRejectionList(withoutA);
//                                             setKycSuccesText(true);
//                                         }}
//                                     >
//                                         &#10003;
//                                     </label>
//                                     <label
//                                         className={rejectionList.street ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                         onClick={() => { setRejectionList({ ...rejectionList, street: "please enter valid street name" }); setKycSuccesText(true); }}
//                                     >
//                                         X
//                                     </label>
//                                 </div>
//                             </div>

//                             <div className={styles.row}>
//                                 <div className={styles.col_10}>
//                                     <div className={styles.margin}>Pincode</div>
//                                     <input
//                                         required
//                                         value={genralInfo.pincode}
//                                         onChange={(e) => {
//                                             setGenralInfo({ ...genralInfo, pincode: e.target.value });
//                                             setKycSuccesText(true);
//                                         }}
//                                         type="text"
//                                         placeholder="Enter Pincode"
//                                         className={styles.kyc_input}
//                                         maxLength="6"
//                                         onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
//                                     />
//                                 </div>
//                                 <div className={styles.kyc_toggle_btn}>
//                                     <label
//                                         className={!rejectionList.pincode ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                         onClick={() => {
//                                             const withoutA = Object.fromEntries(
//                                                 Object.entries(rejectionList).filter(([key]) => key !== 'pincode')
//                                             )
//                                             setRejectionList(withoutA);
//                                             setKycSuccesText(true);
//                                         }}
//                                     >
//                                         &#10003;
//                                     </label>
//                                     <label
//                                         className={rejectionList.pincode ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                         onClick={() => { setRejectionList({ ...rejectionList, pincode: "please enter valid pincode number" }); setKycSuccesText(true); }}
//                                     >
//                                         X
//                                     </label>
//                                 </div>
//                             </div>

//                             <div className={styles.row}>
//                                 <div className={styles.col_10}>
//                                     <div className={styles.margin}>City</div>
//                                     <input
//                                         required
//                                         value={loadingInput ? 'loading...' : genralInfo.city}
//                                         onChange={(e) => {
//                                             setGenralInfo({ ...genralInfo, city: e.target.value });
//                                             setKycSuccesText(true);
//                                         }}
//                                         type="text"
//                                         placeholder="Enter City"
//                                         className={styles.kyc_input}
//                                     />
//                                 </div>
//                                 <div className={styles.kyc_toggle_btn}>
//                                     <label
//                                         className={!rejectionList.city ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                         onClick={() => {
//                                             const withoutA = Object.fromEntries(
//                                                 Object.entries(rejectionList).filter(([key]) => key !== 'city')
//                                             )
//                                             setRejectionList(withoutA);
//                                             setKycSuccesText(true);
//                                         }}
//                                     >
//                                         &#10003;
//                                     </label>
//                                     <label
//                                         className={rejectionList.city ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                         onClick={() => { setRejectionList({ ...rejectionList, city: "please enter valid city name" }); setKycSuccesText(true); }}
//                                     >
//                                         X
//                                     </label>
//                                 </div>
//                             </div>

//                             <div className={styles.row}>
//                                 <div className={styles.col_10}>
//                                     <div className={styles.margin}>District</div>
//                                     <input
//                                         required
//                                         value={loadingInput ? 'loading...' : genralInfo.district}
//                                         onChange={(e) => {
//                                             setGenralInfo({ ...genralInfo, district: e.target.value });
//                                             setKycSuccesText(true);
//                                         }}
//                                         type="text"
//                                         placeholder="Enter District"
//                                         className={styles.kyc_input}
//                                     />
//                                 </div>
//                                 <div className={styles.kyc_toggle_btn}>
//                                     <label
//                                         className={!rejectionList.district ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                         onClick={() => {
//                                             const withoutA = Object.fromEntries(
//                                                 Object.entries(rejectionList).filter(([key]) => key !== 'district')
//                                             )
//                                             setRejectionList(withoutA);
//                                             setKycSuccesText(true);
//                                         }}
//                                     >
//                                         &#10003;
//                                     </label>
//                                     <label
//                                         className={rejectionList.district ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                         onClick={() => { setRejectionList({ ...rejectionList, district: "please enter valid district name" }); setKycSuccesText(true); }}
//                                     >
//                                         X
//                                     </label>
//                                 </div>
//                             </div>

//                             <div className={styles.row}>
//                                 <div className={styles.col_10}>
//                                     <div className={styles.margin}>State</div>
//                                     <input
//                                         required
//                                         value={loadingInput ? 'loading...' : genralInfo.state}
//                                         onChange={(e) => {
//                                             setGenralInfo({ ...genralInfo, state: e.target.value });
//                                             setKycSuccesText(true);
//                                         }}
//                                         type="text"
//                                         placeholder="Enter State"
//                                         className={styles.kyc_input}
//                                     />
//                                 </div>
//                                 <div className={styles.kyc_toggle_btn}>
//                                     <label
//                                         className={!rejectionList.state ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                         onClick={() => {
//                                             const withoutA = Object.fromEntries(
//                                                 Object.entries(rejectionList).filter(([key]) => key !== 'state')
//                                             )
//                                             setRejectionList(withoutA);
//                                             setKycSuccesText(true);
//                                         }}
//                                     >
//                                         &#10003;
//                                     </label>
//                                     <label
//                                         className={rejectionList.state ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                         onClick={() => { setRejectionList({ ...rejectionList, state: "please enter valid state name" }); setKycSuccesText(true); }}
//                                     >
//                                         X
//                                     </label>
//                                 </div>
//                             </div>

//                             <div className={styles.row}>
//                                 <div className={styles.col_10}>
//                                     <div className={styles.margin}>Country</div>
//                                     <input
//                                         required
//                                         value={genralInfo.country}
//                                         onChange={(e) => {
//                                             setGenralInfo({ ...genralInfo, country: e.target.value });
//                                             setKycSuccesText(true);
//                                         }}
//                                         type="text"
//                                         placeholder="Enter Country"
//                                         className={styles.kyc_input}
//                                     />
//                                 </div>
//                                 <div className={styles.kyc_toggle_btn}>
//                                     <label
//                                         className={!rejectionList.country ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                         onClick={() => {
//                                             const withoutA = Object.fromEntries(
//                                                 Object.entries(rejectionList).filter(([key]) => key !== 'country')
//                                             )
//                                             setRejectionList(withoutA);
//                                             setKycSuccesText(true);
//                                         }}
//                                     >
//                                         &#10003;
//                                     </label>
//                                     <label
//                                         className={rejectionList.country ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                         onClick={() => { setRejectionList({ ...rejectionList, country: "please enter valid country name" }); setKycSuccesText(true); }}
//                                     >
//                                         X
//                                     </label>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className={styles.searchPlace_div}>
//                             <SearchPlace
//                                 address={address}
//                                 setAddress={setAddress}
//                                 city={address.place_id ? "" : genralInfo.city + " " + genralInfo.state}
//                             />
//                         </div>
//                     </div>

//                     <div className={styles.accountSetting}>
//                         <div className={`${styles.kyc_sub_heading} text-start mt-4 pt-3`}>Account Settings</div>

//                         <div className={styles.accountSetting_div}>

//                             <div className={styles.accountSetting_left}>
//                                 <div className='mb-4'>
//                                     <div className={styles.margin}>Assign Order Manager</div>
//                                     <select
//                                         required={true}
//                                         value={genralInfo.managerId}
//                                         onChange={(e) => {
//                                             setGenralInfo({ ...genralInfo, managerId: e.target.value });
//                                             setKycSuccesText(true);
//                                         }}
//                                         className={styles.kyc_input_select}>
//                                         <option value="" className={styles.bg_gray_dark} hidden>-- Select --</option>
//                                         {managers && managers.map((item, index) => (
//                                             <option key={index} value={item.managerId} className={styles.bg_gray_dark}>{item.name}</option>
//                                         ))}
//                                     </select>
//                                 </div>
//                                 <div className=''>
//                                     <div className={`${styles.margin} mb-3`}>Organic Leads</div>
//                                     <div className={styles.dfac}>
//                                         <label>
//                                             <input
//                                                 required
//                                                 onChange={(e) => { setLeadBlock(true); setShowLeadsBlockModal(true) }}
//                                                 type="radio"
//                                                 name="leadBlock"
//                                                 value={true}
//                                                 className={styles.kyc_mouse_radio_Btn}
//                                                 checked={genralInfo.leadBlock === true ? true : false}
//                                             />&nbsp;&nbsp;&nbsp;Active
//                                         </label>
//                                         <label>
//                                             <input
//                                                 required
//                                                 onChange={(e) => { setLeadBlock(false); setShowLeadsBlockModal(true) }}
//                                                 type="radio"
//                                                 name="leadBlock"
//                                                 value={false}
//                                                 className={styles.kyc_mouse_radio_Btn}
//                                                 checked={genralInfo.leadBlock === false ? true : false}
//                                             />&nbsp;&nbsp;&nbsp;Disable
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className={styles.accountSetting_right}>
//                                 <div className='d-flex justify-content-between mb-3'>
//                                     <div className={`${styles.col3} w-50`}>
//                                         <div className={`${styles.margin} mb-3`}>No Return No Exchange Mandotory Checkbox</div>
//                                         <div className={`${styles.dfac}`}>
//                                             <label>
//                                                 <input
//                                                     required
//                                                     onChange={(e) => {
//                                                         setGenralInfo({ ...genralInfo, nrne: e.target.value });
//                                                         setKycSuccesText(true);
//                                                     }}
//                                                     type="radio"
//                                                     name="nrne"
//                                                     value="Active"
//                                                     className={styles.kyc_mouse_radio_Btn}
//                                                     checked={genralInfo.nrne === "Active" ? true : false}
//                                                 />&nbsp;&nbsp;&nbsp;Active
//                                             </label>
//                                             <label>
//                                                 <input
//                                                     required
//                                                     onChange={(e) => {
//                                                         setGenralInfo({ ...genralInfo, nrne: e.target.value });
//                                                         setKycSuccesText(true);
//                                                     }}
//                                                     type="radio"
//                                                     name="nrne"
//                                                     value="Disable"
//                                                     className={styles.kyc_mouse_radio_Btn}
//                                                     checked={genralInfo.nrne === "Disable" ? true : false}
//                                                 />&nbsp;&nbsp;&nbsp;Disable
//                                             </label>
//                                         </div>
//                                     </div>

//                                     <div className='w-50 d-flex flex-column'>
//                                         <div className={styles.Catalogue_ml}>Catalogue</div>
//                                         <div className={`${styles.dfac} justify-content-evenly`}>
//                                             <label>
//                                                 <input
//                                                     required
//                                                     onChange={(e) => {
//                                                         setGenralInfo({ ...genralInfo, catalogStatus: e.target.value });
//                                                         setKycSuccesText(true);
//                                                     }}
//                                                     type="radio"
//                                                     name="catalogStatus"
//                                                     value="Active"
//                                                     className={`${styles.kyc_mouse_radio_Btn}`}
//                                                     checked={genralInfo.catalogStatus === "Active" ? true : false}
//                                                 />&nbsp;&nbsp;&nbsp;Active
//                                             </label>
//                                             <label>
//                                                 <input
//                                                     required
//                                                     onChange={(e) => {
//                                                         setGenralInfo({ ...genralInfo, catalogStatus: e.target.value });
//                                                         setKycSuccesText(true);
//                                                     }}
//                                                     type="radio"
//                                                     name="catalogStatus"
//                                                     value="Disable"
//                                                     className={styles.kyc_mouse_radio_Btn}
//                                                     checked={genralInfo.catalogStatus === "Disable" ? true : false}
//                                                 />&nbsp;&nbsp;&nbsp;Disable
//                                             </label>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className='mt-3'>
//                                     <div className={`${styles.margin} mb-3`}>Status</div>
//                                     <div className={styles.dfac}>
//                                         <label>
//                                             <input
//                                                 required
//                                                 onChange={(e) => {
//                                                     setGenralInfo({ ...genralInfo, status: e.target.value });
//                                                     if (genralInfo.status === "New") {
//                                                         setKycSuccesText(false);
//                                                     } else {
//                                                         setKycSuccesText(true);
//                                                     }
//                                                 }}
//                                                 type="radio"
//                                                 name="status"
//                                                 value="New"
//                                                 className={styles.kyc_mouse_radio_Btn}
//                                                 checked={genralInfo.status === "New" ? true : false}
//                                             />&nbsp;&nbsp;&nbsp;New&nbsp;&nbsp;&nbsp;
//                                         </label>
//                                         <label>
//                                             <input
//                                                 required
//                                                 onChange={(e) => {
//                                                     setGenralInfo({ ...genralInfo, status: e.target.value })
//                                                     if (genralInfo.status === "Pending") {
//                                                         setKycSuccesText(false);
//                                                     } else {
//                                                         setKycSuccesText(true);
//                                                     }
//                                                 }}
//                                                 type="radio"
//                                                 name="status"
//                                                 value="Pending"
//                                                 className={styles.kyc_mouse_radio_Btn}
//                                                 checked={genralInfo.status === "Pending" ? true : false}
//                                             />&nbsp;&nbsp;&nbsp;Pending
//                                         </label>
//                                         <label>
//                                             <input
//                                                 required
//                                                 onChange={(e) => {
//                                                     setGenralInfo({ ...genralInfo, status: e.target.value });
//                                                     if (genralInfo.status === "Update") {
//                                                         setKycSuccesText(false);
//                                                     } else {
//                                                         setKycSuccesText(true);
//                                                     }
//                                                 }}
//                                                 type="radio"
//                                                 name="status"
//                                                 value="Update"
//                                                 className={styles.kyc_mouse_radio_Btn}
//                                                 checked={genralInfo.status === "Update" ? true : false}
//                                             />&nbsp;&nbsp;&nbsp;Update&nbsp;&nbsp;&nbsp;
//                                         </label>
//                                         <label>
//                                             <input
//                                                 required
//                                                 onChange={(e) => {
//                                                     setGenralInfo({ ...genralInfo, status: e.target.value });
//                                                     if (genralInfo.status === "Verified") {
//                                                         setKycSuccesText(false);
//                                                     } else {
//                                                         setKycSuccesText(true);
//                                                     }
//                                                 }}
//                                                 type="radio"
//                                                 name="status"
//                                                 value="Verified"
//                                                 className={styles.kyc_mouse_radio_Btn}
//                                                 checked={genralInfo.status === "Verified" ? true : false}
//                                             />&nbsp;&nbsp;&nbsp;Verified&nbsp;&nbsp;&nbsp;
//                                         </label>
//                                         <label>
//                                             <input
//                                                 required
//                                                 onChange={(e) => {
//                                                     setGenralInfo({ ...genralInfo, status: e.target.value });
//                                                     if (genralInfo.status === "Rejected") {
//                                                         setKycSuccesText(false);
//                                                     } else {
//                                                         setKycSuccesText(true);
//                                                     }
//                                                 }}
//                                                 type="radio"
//                                                 name="status"
//                                                 value="Rejected"
//                                                 className={styles.kyc_mouse_radio_Btn}
//                                                 checked={genralInfo.status === "Rejected" ? true : false}
//                                             />&nbsp;&nbsp;&nbsp;Rejected&nbsp;&nbsp;&nbsp;
//                                         </label>
//                                         <label>
//                                             <input
//                                                 required
//                                                 onChange={(e) => {
//                                                     setGenralInfo({ ...genralInfo, status: e.target.value });
//                                                     if (genralInfo.status === "Blacklisted") {
//                                                         setKycSuccesText(false);
//                                                     } else {
//                                                         setKycSuccesText(true);
//                                                     }
//                                                 }}
//                                                 type="radio"
//                                                 name="status"
//                                                 value="Blacklisted"
//                                                 className={styles.kyc_mouse_radio_Btn}
//                                                 checked={genralInfo.status === "Blacklisted" ? true : false}
//                                             />&nbsp;&nbsp;&nbsp;Blacklisted&nbsp;&nbsp;&nbsp;
//                                         </label>
//                                         <label>
//                                             <input
//                                                 required
//                                                 onChange={(e) => {
//                                                     setGenralInfo({ ...genralInfo, status: e.target.value });
//                                                     if (genralInfo.status === "Deleted") {
//                                                         setKycSuccesText(false);
//                                                     } else {
//                                                         setKycSuccesText(true);
//                                                     }
//                                                 }}
//                                                 type="radio"
//                                                 name="status"
//                                                 value="Deleted"
//                                                 disabled={true}
//                                                 className={styles.kyc_mouse_radio_Btn}
//                                                 checked={genralInfo.status === "Deleted" ? true : false}
//                                             />&nbsp;&nbsp;&nbsp;Deleted&nbsp;&nbsp;&nbsp;
//                                         </label>
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>

//                         {/* <div className={styles.rowOffice}>
//                         <div className={styles.col3}>
//                             <div className={styles.margin}>Assign Order Manager</div>
//                             <select
//                                 value={genralInfo.managerId}
//                                 onChange={(e) => setGenralInfo({ ...genralInfo, managerId: e.target.value })}
//                                 className={styles.kyc_input_select}>
//                                 <option className={styles.bg_gray_dark} hidden>-- Select --</option>
//                                 {managers && managers.map((item, index) => (
//                                     <option key={index} value={item.managerId} className={styles.bg_gray_dark}>{item.name}</option>
//                                 ))}

//                             </select>
//                         </div>

//                         <div className={styles.col3}>
//                             <div className={`${styles.margin} text-center mb-3`}>No Return No Exchange Mandotory Checkbox</div>
//                             <div className={`${styles.dfac} justify-content-evenly`}>
//                                 <label>
//                                     <input
//                                         required
//                                         onChange={(e) => setGenralInfo({ ...genralInfo, nrne: e.target.value })}
//                                         type="radio"
//                                         name="nrne"
//                                         value="Active"
//                                         className={styles.kyc_mouse_radio_Btn}
//                                         checked={genralInfo.nrne === "Active" ? true : false}
//                                     />&nbsp;&nbsp;&nbsp;Active
//                                 </label>
//                                 <label>
//                                     <input
//                                         required
//                                         onChange={(e) => setGenralInfo({ ...genralInfo, nrne: e.target.value })}
//                                         type="radio"
//                                         name="nrne"
//                                         value="Disable"
//                                         className={styles.kyc_mouse_radio_Btn}
//                                         checked={genralInfo.nrne === "Disable" ? true : false}
//                                     />&nbsp;&nbsp;&nbsp;Disable
//                                 </label>
//                             </div>
//                         </div>
//                         <div className={styles.col3}>
//                             <div className={`${styles.margin} w-50 text-center ms-3 mb-3`}>Catalogue</div>
//                             <div className={`${styles.dfac} justify-content-evenly`}>
//                                 <label>
//                                     <input
//                                         required
//                                         onChange={(e) => setGenralInfo({ ...genralInfo, catalogStatus: e.target.value })}
//                                         type="radio"
//                                         name="catalogStatus"
//                                         value="Active"
//                                         className={`${styles.kyc_mouse_radio_Btn}`}
//                                         checked={genralInfo.catalogStatus === "Active" ? true : false}
//                                     />&nbsp;&nbsp;&nbsp;Active
//                                 </label>
//                                 <label>
//                                     <input
//                                         required
//                                         onChange={(e) => setGenralInfo({ ...genralInfo, catalogStatus: e.target.value })}
//                                         type="radio"
//                                         name="catalogStatus"
//                                         value="Disable"
//                                         className={styles.kyc_mouse_radio_Btn}
//                                         checked={genralInfo.catalogStatus === "Disable" ? true : false}
//                                     />&nbsp;&nbsp;&nbsp;Disable
//                                 </label>
//                             </div>
//                         </div>

//                         <div className='d-flex'>
//                             <div className='w-25'>
//                                 <div className={`${styles.margin} mb-3`}>Organic Leads</div>
//                                 <div className={styles.dfac}>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => { setLeadBlock(true); setShowLeadsBlockModal(true) }}
//                                             type="radio"
//                                             name="leadBlock"
//                                             value={true}
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.leadBlock === true ? true : false}
//                                         />&nbsp;&nbsp;&nbsp;Active
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => { setLeadBlock(false); setShowLeadsBlockModal(true) }}
//                                             type="radio"
//                                             name="leadBlock"
//                                             value={false}
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.leadBlock === false ? true : false}
//                                         />&nbsp;&nbsp;&nbsp;Disable
//                                     </label>
//                                 </div>
//                             </div>

//                             <div className='w-75'>
//                                 <div className={`${styles.margin} mb-3`}>Status</div>
//                                 <div className={styles.dfac}>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="New"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "New" ? true : false}
//                                         />&nbsp;&nbsp;&nbsp;New&nbsp;&nbsp;&nbsp;
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="Pending"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "Pending" ? true : false}
//                                         />&nbsp;&nbsp;&nbsp;Pending
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="Update"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "Update" ? true : false}
//                                         />&nbsp;&nbsp;&nbsp;Update&nbsp;&nbsp;&nbsp;
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="Verified"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "Verified" ? true : false}
//                                         />&nbsp;&nbsp;&nbsp;Verified&nbsp;&nbsp;&nbsp;
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="Rejected"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "Rejected" ? true : false}
//                                         />&nbsp;&nbsp;&nbsp;Rejected&nbsp;&nbsp;&nbsp;
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="Blacklisted"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "Blacklisted" ? true : false}
//                                         />&nbsp;&nbsp;&nbsp;Blacklisted&nbsp;&nbsp;&nbsp;
//                                     </label>
//                                 </div>
//                             </div>
//                         </div>

//                     </div> */}
//                     </div>

//                     <div className={`${styles.kyc_sub_heading} text-start`}>Website Settings</div>
//                     <div>
//                         <div>
//                             <label className={error.storeImg && genralInfo.storeImg.length === 0 ? styles.storeImage_alert : styles.storeImage}>
//                                 {progressPercent.storeImg && !genralInfo.storeImg ?
//                                     <Loading />
//                                     :
//                                     <>
//                                         {!storeImageBlob ?
//                                             (!genralInfo.storeImg ?
//                                                 <div className={error.storeImg && genralInfo.storeImg.length === 0 ? styles.store_bg_alert : styles.store_bg}  >
//                                                     Upload Store Image
//                                                 </div>
//                                                 :
//                                                 <img
//                                                     src={`${process.env.REACT_APP_S3URL + genralInfo.storeImg}?${new Date().getTime()}`}
//                                                     alt="store"
//                                                     className={styles.store_Image_div}
//                                                     title={genralInfo.storeName}
//                                                 />
//                                             )
//                                             :
//                                             <img
//                                                 // src={`${process.env.REACT_APP_S3URL + genralInfo.storeImg}?${new Date().getTime()}`}
//                                                 src={storeImageBlob && URL.createObjectURL(storeImageBlob)}
//                                                 alt="store"
//                                                 className={styles.store_Image_div}
//                                                 title={genralInfo.storeName}
//                                             />
//                                         }
//                                     </>
//                                 }
//                                 <input
//                                     type="file"
//                                     accept='image/*'
//                                     className={styles.d_none}
//                                     onChange={(e) => { onInputChange(e); selectModal(); setHW({ width: 1920, height: 720, imgName: "store" }); setKycSuccesText(true); }}
//                                 />
//                             </label>

//                         </div>
//                         <div>
//                             <div className={styles.d_flex_align_center}>Store Image-1920x680</div>
//                             <div className={styles.kyc_toggle_btn_profile_img}>
//                                 <label
//                                     className={!rejectionList.storeImg ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                     onClick={() => {
//                                         const withoutA = Object.fromEntries(
//                                             Object.entries(rejectionList).filter(([key]) => key !== 'storeImg')
//                                         )
//                                         setRejectionList(withoutA);
//                                         setKycSuccesText(true);
//                                     }}
//                                 >
//                                     &#10003;
//                                 </label>
//                                 <label
//                                     className={rejectionList.storeImg ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                     onClick={() => { setRejectionList({ ...rejectionList, storeImg: "please select valid store image" }); setKycSuccesText(true); }}
//                                 >
//                                     X
//                                 </label>
//                             </div>
//                         </div>
//                     </div>

//                     <div className={styles.row_mt_20}>
//                         <div className={styles.col3}>
//                             <div className={styles.fs_20_mt_10}>Your Experience</div>
//                             <select
//                                 // required
//                                 value={genralInfo.experience}
//                                 onChange={(e) => { setGenralInfo({ ...genralInfo, experience: e.target.value }); setKycSuccesText(true); }}
//                                 className={styles.bg_Transparent_w_85_border_p_10}>
//                                 <option className={styles.bg_gray_dark} value="" hidden>-- Select --</option>
//                                 {points.map((item, index) => <option key={index} value={index + 1} className={styles.bg_gray_dark}>{item}&nbsp;Year&#43;</option>)}
//                             </select>
//                             <div className={styles.fs_20_mt_10}>Facebook </div>
//                             <div className={styles.position_relative}>
//                                 <div className={styles.socialMedia_icons}>
//                                     <Facebook />
//                                 </div>
//                                 <input

//                                     value={genralInfo.facebook}
//                                     onChange={(e) => { setGenralInfo({ ...genralInfo, facebook: e.target.value }); setKycSuccesText(true); }}
//                                     type="text"
//                                     name="facebook"
//                                     placeholder="Enter Facebook Url"
//                                     className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                 />
//                             </div>
//                             <div className={styles.fs_20_mt_10}>Instagram</div>
//                             <div className={styles.position_relative}>
//                                 <div className={styles.socialMedia_icons}>
//                                     <Instagram />
//                                 </div>
//                                 <input

//                                     value={genralInfo.instagram}
//                                     onChange={(e) => { setGenralInfo({ ...genralInfo, instagram: e.target.value }); setKycSuccesText(true); }}
//                                     type="text"
//                                     name="instagram"
//                                     placeholder="Enter Instagram Url"
//                                     className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                 />
//                             </div>
//                             <div className={styles.fs_20_mt_10}>Youtube</div>
//                             <div className={styles.position_relative}>
//                                 <div className={styles.socialMedia_icons}>
//                                     <Youtube />
//                                 </div>
//                                 <input

//                                     value={genralInfo.youtube}
//                                     onChange={(e) => { setGenralInfo({ ...genralInfo, youtube: e.target.value }); setKycSuccesText(true); }}
//                                     type="text"
//                                     name="youtube"
//                                     placeholder="Enter Youtube Url"
//                                     className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                 />
//                             </div>
//                             <div className={styles.fs_20_mt_10}>Pinterest</div>
//                             <div className={styles.position_relative}>
//                                 <div className={styles.socialMedia_icons}>
//                                     <Pinterest />
//                                 </div>
//                                 <input

//                                     value={genralInfo.pinterest}
//                                     onChange={(e) => { setGenralInfo({ ...genralInfo, pinterest: e.target.value }); setKycSuccesText(true); }}
//                                     type="text"
//                                     name="pinterest"
//                                     placeholder="Enter Pinterest Url"
//                                     className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                 />
//                             </div>
//                             <div className={styles.fs_20_mt_10}>Twitter</div>
//                             <div className={styles.position_relative}>
//                                 <div className={styles.socialMedia_icons}>
//                                     <Twitter />
//                                 </div>
//                                 <input

//                                     value={genralInfo.twitter}
//                                     onChange={(e) => { setGenralInfo({ ...genralInfo, twitter: e.target.value }); setKycSuccesText(true); }}
//                                     type="text"
//                                     name="twitter"
//                                     placeholder="Enter Twitter Url"
//                                     className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                 />
//                             </div>
//                         </div>
//                         <div className={styles.col9}>
//                             <div className={styles.fs_20_mt_10}>Products They Want To Sell</div>
//                             <div className={styles.row_br_5_bg_dark_p_10}>
//                                 {
//                                     allProducts && allProducts.map((item, index) =>
//                                         <div key={index} className={styles.productName_center}>
//                                             <button type="button"
//                                                 className={selectedProducts.find(val => val.product_id === item.product_id
//                                                 ) ?
//                                                     styles.productBtn
//                                                     :
//                                                     styles.productDisableBtn
//                                                 }
//                                                 onClick={() => {
//                                                     if (selectedProducts.filter((val) => val.product_id === item.product_id && val.status === "Pending").length !== 0) {
//                                                         selectedProducts.forEach((obj, idx) => {
//                                                             if (obj.product_id === item.product_id && obj.status === "Pending") {
//                                                                 const newUsers = [...selectedProducts];
//                                                                 newUsers[idx].status = 'Active';
//                                                                 setSelectedProducts(newUsers);
//                                                                 setKycSuccesText(true);
//                                                             }
//                                                         })
//                                                     }
//                                                     else if (selectedProducts.filter((val) => val.product_id === item.product_id).length === 0) {
//                                                         setSelectedProducts([...selectedProducts, {
//                                                             product_name: item.product_name,
//                                                             product_id: item.product_id,
//                                                             status: "Active",
//                                                         }]);
//                                                         setKycSuccesText(true);
//                                                     } else {
//                                                         let arr = selectedProducts;
//                                                         let arr1 = arr.filter((val) => val.product_id !== item.product_id);
//                                                         setSelectedProducts(arr1);
//                                                         setKycSuccesText(true);
//                                                     }
//                                                 }}
//                                             >
//                                                 {selectedProducts.find(val => val.product_name === item.product_name) ?
//                                                     <span className={styles.plus_product}>&#10004;</span> :
//                                                     <span className={styles.plus_product}>&nbsp;&#10011;&nbsp;</span>
//                                                 }
//                                                 {item.product_name}
//                                             </button>
//                                         </div>
//                                     )
//                                 }
//                             </div>
//                             {error.selectedProducts && <div className={styles.input_field_alert}>{error.selectedProducts}</div>}

//                             <div className={styles.row}>
//                                 <div className="col-12">
//                                     <div className={styles.fs_20_mt_10}>About {genralInfo.storeName} <span className="fs_14">(This Paragraph will be visible in their website)</span></div>
//                                     <textarea
//                                         // required
//                                         value={genralInfo.storeDesc}
//                                         onChange={(e) => { setGenralInfo({ ...genralInfo, storeDesc: e.target.value }); setKycSuccesText(true); }}
//                                         name="storeDesc"
//                                         rows="12"
//                                         placeholder="Enter About Demo Interiors"
//                                         className={styles.bg_Transparent_w_98_border_p_10}
//                                     />
//                                     <div className={styles.text_end_text_danger_fs_12}>*First 50-160 letters will be used for your Search Engine ranking.</div>
//                                 </div>

//                             </div>
//                         </div>
//                     </div>

//                 </div>
//             </form>
//             {/* <div className={styles.d_flex_align_center}>
//                             <div className="">Dealer Name: {genralInfo.name} &nbsp;</div>
//                             <div className={styles.kyc_toggle_btn_profile_img}>
//                                 <label
//                                     className={!rejectionList.dealerName ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                     onClick={() => {
//                                         const withoutA = Object.fromEntries(
//                                             Object.entries(rejectionList).filter(([key]) => key !== 'dealerName')
//                                         )

//                                         setRejectionList(withoutA)
//                                     }}
//                                 >
//                                     &#10003;
//                                 </label>
//                                 <label
//                                     className={rejectionList.dealerName ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                     onClick={() => { setRejectionList({ ...rejectionList, dealerName: "please enter valid dealer name" }) }}
//                                 >
//                                     X
//                                 </label>
//                             </div>
//                         </div> */}

//             {/* <div className={styles.kyc_sub_main_div}>

//                     <div className={styles.kyc_scroll_div}>

//                         <div className={styles.profileAndStore}>
//                             <div>
//                                 <div className={styles.d_flex_align_center}>

//                                     <label className={error.profileImage && genralInfo.profileImage.length === 0 ? styles.profileImage_alert : styles.profileImage} >
//                                         {
//                                             progressPercent.profileImage && !genralInfo.profileImage ?
//                                                 <Loading />
//                                                 :
//                                                 !genralInfo.profileImage ? <div className={error.profileImage && genralInfo.profileImage.length === 0 ? styles.profile_bg_alert : styles.profile_bg}>
//                                                     Upload Profile Image
//                                                 </div> :
//                                                     <img
//                                                         src={`${process.env.REACT_APP_S3URL + genralInfo.profileImage}?${new Date().getTime()}`}
//                                                         alt="Profile"
//                                                         className={styles.profile_Image_div}
//                                                         title={genralInfo.name}
//                                                     />
//                                         }
//                                         <input
//                                             type="file"
//                                             accept='image/*'
//                                             className={styles.d_none}
//                                             onChange={(e) => { onInputChange(e); selectModal(); setHW({ width: 200, height: 200, imgName: "profile" }) }}
//                                         />
//                                     </label>
//                                     <div className={styles.kyc_toggle_btn_profile_img}>
//                                         <label
//                                             className={!rejectionList.profileImage ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'profileImage')
//                                                 )

//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.profileImage ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, profileImage: "please select valid profile image" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.d_flex_align_center}>
//                                     <div className="">Dealer Name: {genralInfo.name} &nbsp;</div>
//                                     <div className={styles.kyc_toggle_btn_profile_img}>
//                                         <label
//                                             className={!rejectionList.dealerName ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'dealerName')
//                                                 )

//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.dealerName ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, dealerName: "please enter valid dealer name" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div>
//                                 <div>
//                                     <label className={error.storeImg && genralInfo.storeImg.length === 0 ? styles.storeImage_alert : styles.storeImage}>
//                                         {progressPercent.storeImg && !genralInfo.storeImg ?
//                                             <Loading />
//                                             :

//                                             !genralInfo.storeImg ? <div className={error.storeImg && genralInfo.storeImg.length === 0 ? styles.store_bg_alert : styles.store_bg}>
//                                                 Upload Store Image

//                                             </div> :
//                                                 <img
//                                                     src={`${process.env.REACT_APP_S3URL + genralInfo.storeImg}?${new Date().getTime()}`}
//                                                     alt="store"
//                                                     className={styles.store_Image_div}
//                                                     title={genralInfo.storeName}
//                                                 />
//                                         }
//                                         <input
//                                             type="file"
//                                             accept='image/*'
//                                             className={styles.d_none}
//                                             onChange={(e) => { onInputChange(e); selectModal(); setHW({ width: 1920, height: 720, imgName: "store" }) }}
//                                         />
//                                     </label>

//                                 </div>
//                                 <div>
//                                     <div className={styles.d_flex_align_center}>Store Image-1920x680</div>
//                                     <div className={styles.kyc_toggle_btn_profile_img}>
//                                         <label
//                                             className={!rejectionList.storeImg ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'storeImg')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.storeImg ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, storeImg: "please select valid store image" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className={styles.row_mt_20}>
//                             <div className={styles.col3}>
//                                 <div className={styles.fs_20_mt_10}>Experience</div>
//                                 <select
//                                     required
//                                     value={genralInfo.experience}
//                                     onChange={(e) => setGenralInfo({ ...genralInfo, experience: e.target.value })}
//                                     className={styles.bg_Transparent_w_85_border_p_10}>
//                                     <option className={styles.bg_gray_dark} value="" hidden>-- Select --</option>
//                                     {points.map((item, index) => <option key={index} value={index + 1} className={styles.bg_gray_dark}>{item}&nbsp;Year&#43;</option>)}
//                                 </select>
//                                 <div className={styles.fs_20_mt_10}>Facebook </div>
//                                 <div className={styles.position_relative}>
//                                     <div className={styles.socialMedia_icons}>
//                                         <Facebook />
//                                     </div>
//                                     <input

//                                         value={genralInfo.facebook}
//                                         onChange={(e) => setGenralInfo({ ...genralInfo, facebook: e.target.value })}
//                                         type="text"
//                                         name="facebook"
//                                         placeholder="Enter Facebook Url"
//                                         className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                     />
//                                 </div>
//                                 <div className={styles.fs_20_mt_10}>Instagram</div>
//                                 <div className={styles.position_relative}>
//                                     <div className={styles.socialMedia_icons}>
//                                         <Instagram />
//                                     </div>
//                                     <input

//                                         value={genralInfo.instagram}
//                                         onChange={(e) => setGenralInfo({ ...genralInfo, instagram: e.target.value })}
//                                         type="text"
//                                         name="instagram"
//                                         placeholder="Enter Instagram Url"
//                                         className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                     />
//                                 </div>
//                                 <div className={styles.fs_20_mt_10}>Youtube</div>
//                                 <div className={styles.position_relative}>
//                                     <div className={styles.socialMedia_icons}>
//                                         <Youtube />
//                                     </div>
//                                     <input

//                                         value={genralInfo.youtube}
//                                         onChange={(e) => setGenralInfo({ ...genralInfo, youtube: e.target.value })}
//                                         type="text"
//                                         name="youtube"
//                                         placeholder="Enter Youtube Url"
//                                         className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                     />
//                                 </div>
//                                 <div className={styles.fs_20_mt_10}>Pinterest</div>
//                                 <div className={styles.position_relative}>
//                                     <div className={styles.socialMedia_icons}>
//                                         <Pinterest />
//                                     </div>
//                                     <input

//                                         value={genralInfo.pinterest}
//                                         onChange={(e) => setGenralInfo({ ...genralInfo, pinterest: e.target.value })}
//                                         type="text"
//                                         name="pinterest"
//                                         placeholder="Enter Pinterest Url"
//                                         className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                     />
//                                 </div>
//                                 <div className={styles.fs_20_mt_10}>Twitter</div>
//                                 <div className={styles.position_relative}>
//                                     <div className={styles.socialMedia_icons}>
//                                         <Twitter />
//                                     </div>
//                                     <input

//                                         value={genralInfo.twitter}
//                                         onChange={(e) => setGenralInfo({ ...genralInfo, twitter: e.target.value })}
//                                         type="text"
//                                         name="twitter"
//                                         placeholder="Enter Twitter Url"
//                                         className={styles.bg_Transparent_w_75_border_p_10_ml_30}
//                                     />
//                                 </div>
//                             </div>
//                             <div className={styles.col9}>
//                                 <div className={styles.fs_20_mt_10}>Products they want to sell</div>
//                                 <div className={styles.row_br_5_bg_dark_p_10}>
//                                     {
//                                         allProducts && allProducts.map((item, index) =>
//                                             <div key={index} className={styles.productName_center}>
//                                                 <button type="button"
//                                                     className={selectedProducts.find(val => val.product_id === item.product_id
//                                                     ) ?
//                                                         styles.productBtn
//                                                         :
//                                                         styles.productDisableBtn
//                                                     }
//                                                     onClick={() => {
//                                                         if (selectedProducts.filter((val) => val.product_id === item.product_id && val.status === "Pending").length !== 0) {
//                                                             selectedProducts.forEach((obj, idx) => {
//                                                                 if (obj.product_id === item.product_id && obj.status === "Pending") {
//                                                                     const newUsers = [...selectedProducts];
//                                                                     newUsers[idx].status = 'Active';
//                                                                     setSelectedProducts(newUsers);
//                                                                 }
//                                                             })
//                                                         }
//                                                         else if (selectedProducts.filter((val) => val.product_id === item.product_id).length === 0) {
//                                                             setSelectedProducts([...selectedProducts, {
//                                                                 product_name: item.product_name,
//                                                                 product_id: item.product_id,
//                                                                 status: "Active",
//                                                             }])
//                                                         } else {
//                                                             let arr = selectedProducts;
//                                                             let arr1 = arr.filter((val) => val.product_id !== item.product_id);
//                                                             setSelectedProducts(arr1);
//                                                         }
//                                                     }}
//                                                 >
//                                                     {selectedProducts.find(val => val.product_name === item.product_name) ?
//                                                         <span className={styles.plus_product}>&#10004;</span> :
//                                                         <span className={styles.plus_product}>&nbsp;&#10011;&nbsp;</span>
//                                                     }
//                                                     {item.product_name}
//                                                 </button>
//                                             </div>
//                                         )
//                                     }
//                                 </div>
//                                 {error.selectedProducts && <div className={styles.input_field_alert}>{error.selectedProducts}</div>}

//                                 <div className={styles.row}>
//                                     <div className="col-12">
//                                         <div className={styles.fs_20_mt_10}>About {genralInfo.storeName} <span className="fs_14">(This Paragraph will be visible in their website)</span></div>
//                                         <textarea
//                                             required
//                                             value={genralInfo.storeDesc}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, storeDesc: e.target.value })}
//                                             name="storeDesc"
//                                             rows="10"
//                                             placeholder="Enter About Demo Interiors"
//                                             className={styles.bg_Transparent_w_98_border_p_10}
//                                         />
//                                         <div className={styles.text_end_text_danger_fs_12}>*First 50-160 letters will be used for your Search Engine ranking.</div>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>

//                         <div className={styles.kyc_sub_heading}>General Info</div>
//                         <div className={styles.row_fs_Kyc20_align_items_center}>
//                             <div className={styles.col5}>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Name</div>
//                                         <input
//                                             required
//                                             type="text"
//                                             name="Name"
//                                             value={genralInfo.name}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, name: e.target.value })}
//                                             placeholder="Enter Dealer Name"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.name ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'name')
//                                                 )

//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.name ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, name: "please enter valid name" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Store Name</div>
//                                         <input
//                                             required
//                                             type="text"
//                                             name="tradeName"
//                                             value={genralInfo.storeName}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, storeName: e.target.value })}
//                                             placeholder="Enter Trade Name"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.storeName ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'storeName')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.storeName ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, storeName: "please enter valid trade name" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Mobile Number</div>
//                                         <input
//                                             required
//                                             type="number"
//                                             name="mobile"
//                                             value={genralInfo.mobile}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, mobile: e.target.value })}
//                                             placeholder="Enter Mobile Number"
//                                             className={styles.kyc_input}
//                                             maxLength="10"
//                                             onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.mobileNumber ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'mobileNumber')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.mobileNumber ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, mobileNumber: "please enter valid mobile number" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Email Address</div>
//                                         <input
//                                             required
//                                             type="email"
//                                             name="email"
//                                             value={genralInfo.email}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, email: e.target.value })}
//                                             placeholder="Enter Email Address"
//                                             className={styles.kyc_input} />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.emailAddress ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'emailAddress')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.emailAddress ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, emailAddress: "please enter valid email address" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className={styles.col5}>

//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Designation</div>
//                                         <input
//                                             required
//                                             type="text"
//                                             name="designation"
//                                             value={genralInfo.designation}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, designation: e.target.value })}
//                                             placeholder="Enter Designation"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.designation ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'designation')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.designation ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, designation: "please enter valid designation name" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Whatsapp</div>
//                                         <input
//                                             required
//                                             type="text"
//                                             name="whatsapp"
//                                             value={genralInfo.whatsapp}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, whatsapp: e.target.value })}
//                                             placeholder="Enter Whatsapp Number"
//                                             className={styles.kyc_input}
//                                             maxLength="10"
//                                             onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.whatsapp ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'whatsapp')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.whatsapp ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, whatsapp: "please enter valid whatsapp number" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>GSTUIN (OPTIONAL)</div>
//                                         <input
//                                             type="text"
//                                             name="gstuin"
//                                             value={genralInfo.gstuin}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, gstuin: e.target.value })}
//                                             placeholder="Enter GSTUIN"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.gstuin ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'gstuin')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.gstuin ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, gstuin: "please enter valid GST number" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className={styles.kyc_sub_heading}>Address</div>
//                         <div className={styles.kyc_row_fs_Kyc20}>
//                             <div className={styles.col5}>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Street</div>
//                                         <input
//                                             required
//                                             value={genralInfo.street}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, street: e.target.value })}
//                                             type="text"
//                                             placeholder="Enter Street"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.street ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'street')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.street ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, street: "please enter valid street name" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Pincode</div>
//                                         <input
//                                             required
//                                             value={genralInfo.pincode}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, pincode: e.target.value })}
//                                             type="text"
//                                             placeholder="Enter Pincode"
//                                             className={styles.kyc_input}
//                                             maxLength="6"
//                                             onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.pincode ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'pincode')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.pincode ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, pincode: "please enter valid pincode number" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>City</div>
//                                         <input
//                                             required
//                                             value={loadingInput ? 'loading...' : genralInfo.city}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, city: e.target.value })}
//                                             type="text"
//                                             placeholder="Enter City"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.city ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'city')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.city ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, city: "please enter valid city name" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>

//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>District</div>
//                                         <input
//                                             required
//                                             value={loadingInput ? 'loading...' : genralInfo.district}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, district: e.target.value })}
//                                             type="text"
//                                             placeholder="Enter District"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.district ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'district')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.district ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, district: "please enter valid district name" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>

//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>State</div>
//                                         <input
//                                             required
//                                             value={loadingInput ? 'loading...' : genralInfo.state}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, state: e.target.value })}
//                                             type="text"
//                                             placeholder="Enter State"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.state ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'state')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.state ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, state: "please enter valid state name" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                                 <div className={styles.row}>
//                                     <div className={styles.col_10}>
//                                         <div className={styles.margin}>Country</div>
//                                         <input
//                                             required
//                                             value={genralInfo.country}
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, country: e.target.value })}
//                                             type="text"
//                                             placeholder="Enter Country"
//                                             className={styles.kyc_input}
//                                         />
//                                     </div>
//                                     <div className={styles.kyc_toggle_btn}>
//                                         <label
//                                             className={!rejectionList.country ? styles.toggle_btn_active : styles.kyc_w_50_mouse}
//                                             onClick={() => {
//                                                 const withoutA = Object.fromEntries(
//                                                     Object.entries(rejectionList).filter(([key]) => key !== 'country')
//                                                 )
//                                                 setRejectionList(withoutA)
//                                             }}
//                                         >
//                                             &#10003;
//                                         </label>
//                                         <label
//                                             className={rejectionList.country ? styles.toggle_btn_deactive : styles.kyc_w_50_mouse}
//                                             onClick={() => { setRejectionList({ ...rejectionList, country: "please enter valid country name" }) }}
//                                         >
//                                             X
//                                         </label>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className={styles.searchPlace_div}>
//                                 <SearchPlace
//                                     address={address}
//                                     setAddress={setAddress}
//                                     city={address.place_id ? "" : genralInfo.city + " " + genralInfo.state}
//                                 />
//                             </div>
//                         </div>

//                         <div className={styles.kyc_sub_heading}>For Office Use</div>
//                         <div className={styles.rowOffice}>
//                             <div className={styles.col3}>
//                                 <div className={styles.margin}>Assign Order Manager</div>
//                                 <select
//                                     value={genralInfo.managerId}
//                                     onChange={(e) => setGenralInfo({ ...genralInfo, managerId: e.target.value })}
//                                     className={styles.kyc_input_select}>
//                                     <option className={styles.bg_gray_dark} hidden>-- Select --</option>
//                                     {managers && managers.map((item, index) => (
//                                         <option key={index} value={item.managerId} className={styles.bg_gray_dark}>{item.name}</option>
//                                     ))}

//                                 </select>
//                             </div>

//                             <div className={styles.col3}>
//                                 <div className={styles.margin}>No Return No Exchange Mandotory Checkbox</div>
//                                 <div className={styles.dfac}>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, nrne: e.target.value })}
//                                             type="radio"
//                                             name="nrne"
//                                             value="Active"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.nrne === "Active" ? true : false}
//                                         />Active
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, nrne: e.target.value })}
//                                             type="radio"
//                                             name="nrne"
//                                             value="Disable"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.nrne === "Disable" ? true : false}
//                                         />Disable
//                                     </label>
//                                 </div>
//                             </div>
//                             <div className={styles.col3}>
//                                 <div className={styles.margin}>Catalogue</div>
//                                 <div className={styles.dfac}>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, catalogStatus: e.target.value })}
//                                             type="radio"
//                                             name="catalogStatus"
//                                             value="Active"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.catalogStatus === "Active" ? true : false}
//                                         />Active
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, catalogStatus: e.target.value })}
//                                             type="radio"
//                                             name="catalogStatus"
//                                             value="Disable"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.catalogStatus === "Disable" ? true : false}
//                                         />Disable
//                                     </label>
//                                 </div>
//                             </div>
//                             <div className={styles.col3}>
//                                 <div className={styles.margin}>Organic Leads</div>
//                                 <div className={styles.dfac}>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => { setLeadBlock(true); setShowLeadsBlockModal(true) }}
//                                             type="radio"
//                                             name="leadBlock"
//                                             value={true}
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.leadBlock === true ? true : false}
//                                         />Active
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => { setLeadBlock(false); setShowLeadsBlockModal(true) }}
//                                             type="radio"
//                                             name="leadBlock"
//                                             value={false}
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.leadBlock === false ? true : false}
//                                         />Disable
//                                     </label>
//                                 </div>
//                             </div>

//                             <div className={styles.col8}>
//                                 <div className={styles.margin}>Status</div>
//                                 <div className={styles.dfac}>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="New"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "New" ? true : false}
//                                         />New
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="Pending"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "Pending" ? true : false}
//                                         />Pending
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="Update"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "Update" ? true : false}
//                                         />Update
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="Verified"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "Verified" ? true : false}
//                                         />Verified
//                                     </label>
//                                     <label>
//                                         <input
//                                             required
//                                             onChange={(e) => setGenralInfo({ ...genralInfo, status: e.target.value })}
//                                             type="radio"
//                                             name="status"
//                                             value="Rejected"
//                                             className={styles.kyc_mouse_radio_Btn}
//                                             checked={genralInfo.status === "Rejected" ? true : false}
//                                         />Rejected
//                                     </label>
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                 </div> */}

//         </React.Fragment >
//     )
// }

// export default Kyc

import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Kyc.module.css";
import { useAuth } from "../../components/context/Auth";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import Button from "../../components/updatedComponents/Dealers/Button";
import { KycPls, KycUplodFile, Upload } from "../../components/icons/Icon2";
import { LogInBtnIcon, ManagerCameraIcon } from "../../components/icons/Icon";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import UploadSingleFile from "../../components/updatedComponents/kyc/UploadSingleFile";
import InputRedio from "../../components/updatedComponents/FormComponents/InputRedio";
import VerticalDropDown from "../../components/updatedComponents/FormComponents/VerticalDropDown";
import { useParams } from "react-router-dom";
import fetchData from "../../APIsControll/apiControll";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import ImageCroper2 from "./ImageCroper2";
import { handleFileData } from "../../APIsControll/apiControl2";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import { IsEdite } from "../../components/icons/Icon3";

const Kyc = () => {
  const { mode, managerId } = useAuth();
  const { show } = useToast();
  const apiKey = process.env.REACT_APP_MAP_KEY;
  const mapApiJs = "https://maps.googleapis.com/maps/api/js";
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setloading] = useState({
    loading: false,
  });
  const [isEdite, setEdite] = useState(false);
  const [profileFile, setProfileFile] = useState(null);
  const [previewImage1, setPreviewImage1] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [managers, setManagers] = useState([]);
  const [StoreImage, setStoreImage] = useState(null);
  const searchInput = useRef(null);
  const [inputSearch, setInputSearch] = useState("");
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const inputForstore = useRef(null);
  const [address, setAddress] = useState({
    place_id: "",
    formatted_address: "",
  });
  const [genralInfo, setGenralInfo] = useState({
    name: "",
    product: [],
    storeName: "",
    mobile: "",
    email: "",
    designation: "",
    whatsapp: "",
    gstuin: "",
    street: "",
    pincode: "",
    city: "",
    district: "",
    state: "",
    country: "",
    facebook: "",
    instagram: "",
    youtube: "",
    pinterest: "",
    twitter: "",
    storeDesc: "",
    experience: "",
    google: "",
    nrne: "",
    catalogStatus: "",
    profileImage: "",
    storeImg: "",
    leadBlock: "",
    status: "",
    managerId: "",
  });

  //console.log("selectedProducts", selectedProducts);

  const handlePhotoUploadClick = () => {
    fileInputRef.current.click();
  };
  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // setPreviewImage1(true)
      setProfileFile(file);
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
    }
  };
  const handlePhotoUploadClick1 = () => {
    inputForstore.current.click();
  };
  const handleChangeForStoreImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setStoreImage(imageUrl);
    }
  };

  function loadAsyncScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  }

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress((prevState) => ({
      ...prevState,
      place_id: place?.place_id,
      formatted_address: place?.formatted_address,
    }));
  };

  // init autocomplete
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current,
      { componentRestrictions: { country: "in" } }
    );
    // //console.log("autocomplete", autocomplete);

    autocomplete.setFields([
      "address_components",
      "formatted_address",
      "place_id",
      "geometry",
      "name",
    ]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  // load map script after mounted
  useEffect(() => {
    if (inputSearch) {
      initMapScript().then(() => initAutocomplete());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, initAutocomplete]);

  useEffect(() => {
    if (address.formatted_address) {
      setInputSearch(address?.formatted_address);
    }
  }, [address?.formatted_address]);
  // //console.log(genralInfo);

  const getDealerInfo = async () => {
    try {
      const payload = {
        managerId: managerId,
        dealerId: id,
      };
      const result = await fetchData(`kyc/dealer`, payload);
      setGenralInfo((prevState) => ({
        ...prevState,
        name: result.data.name ? result.data.name : "",
        profileImage: result.data.profileImage ? result.data.profileImage : "",
        storeImg: result.data.storeImg ? result.data.storeImg : "",
        storeName: result.data.storeName ? result.data.storeName : "",
        mobile: result.data.mobile ? result.data.mobile : "",
        email: result.data.email ? result.data.email : "",
        designation: result.data.designation ? result.data.designation : "",
        whatsapp: result.data.whatsapp ? result.data.whatsapp : "",
        gstuin: result.data.gstuin ? result.data.gstuin : "",
        street: result.data.street ? result.data.street : "",
        pincode: result.data.pincode ? result.data.pincode : "",
        city: result.data.city ? result.data.city : "",
        district: result.data.district ? result.data.district : "",
        state: result.data.state ? result.data.state : "",
        country: result.data.country ? result.data.country : "",
        facebook: result.data.facebook ? result.data.facebook : "",
        instagram: result.data.instagram ? result.data.instagram : "",
        youtube: result.data.youtube ? result.data.youtube : "",
        pinterest: result.data.pinterest ? result.data.pinterest : "",
        twitter: result.data.twitter ? result.data.twitter : "",
        storeDesc: result.data.storeDesc ? result.data.storeDesc : "",
        experience: result.data.experience ? result.data.experience : "",
        google: result.data.google ? result.data.google : "",
        nrne: result.data.nrne ? result.data.nrne : "",
        catalogStatus: result.data.catalogStatus
          ? result.data.catalogStatus
          : "",
        leadBlock: result.data.leadBlock ? result.data.leadBlock : false,
        managerId: result.data.managerId ? result.data.managerId : "",
        status: result.data.status ? result.data.status : "",
        product: result.products ? result.products : "",
      }));
      setAddress((prevState) => ({
        ...prevState,
        place_id: result.data?.place_id ? result.data?.place_id : "",
        formatted_address: result.data?.formatted_address
          ? result.data?.formatted_address
          : "",
      }));
      setPreviewImage(
        result.data.profileImage === ""
          ? null
          : `${process.env.REACT_APP_S3URLA}${result.data.profileImage}`
      );
      setProfileFile(result.data.profileImage);
      setStoreImage(
        result.data.storeImg === ""
          ? ""
          : `${process.env.REACT_APP_S3URLA}${result.data.storeImg}`
      );

      if (result.products) {
        setAllProducts(result.products);
        setSelectedProducts(result.data.selectedProducts);
      }
      if (result.managers) {
        setManagers(result.managers);
      }
    } catch (error) {
      //console.log("error ", error);
    }
  };
  useEffect(() => {
    setEdite(false);
    getDealerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateKyc = useCallback(async () => {
    if (!profileFile || profileFile === "") {
      show("Profile image is required", "error");
      return;
    }
    try {
      setloading({ ...loading, loading: true });
      const formData = new FormData();
      formData.append("name", genralInfo.name);
      formData.append("designation", genralInfo.designation);
      formData.append("storeName", genralInfo.storeName);
      formData.append("street", genralInfo.street);
      formData.append("pincode", genralInfo.pincode);
      formData.append("city", genralInfo.city);
      formData.append("district", genralInfo.district);
      formData.append("state", genralInfo.state);
      formData.append("country", genralInfo.country);
      formData.append("phone", genralInfo.mobile);
      formData.append("whatsapp", genralInfo.whatsapp);
      formData.append("email", genralInfo.email);
      formData.append("gstuin", genralInfo.gstuin);
      formData.append("place_id", address.place_id);
      formData.append("formatted_address", address.formatted_address);
      formData.append("assignedManager", genralInfo.managerId);
      formData.append("nrne", genralInfo.nrne);
      formData.append("catalogStatus", genralInfo.catalogStatus);
      formData.append("leadBlock", genralInfo.leadBlock); /// no ideia
      formData.append("status", genralInfo.status); // NO ideia
      formData.append("experience", genralInfo.experience);
      formData.append("selectedProducts", JSON.stringify(selectedProducts));
      formData.append("storeDesc", genralInfo.storeDesc);
      formData.append("facebook", genralInfo.facebook);
      formData.append("twitter", genralInfo.twitter);
      formData.append("instagram", genralInfo.instagram);
      formData.append("youtube", genralInfo.youtube);
      formData.append("pinterest", genralInfo.pinterest);
      formData.append("profileImage", profileFile);
      formData.append("storeImg", undefined);
      formData.append("rejectionList", JSON.stringify({}));

      const res = await handleFileData(
        `kyc/dealerKyc?managerId=${managerId}&dealerId=${id}`,
        formData
      );
      // //console.log("kyc res = " + res);
      if (res.status === "success") {
        show(res.msg, "success");
        return;
      }
      if (res.status === "failed") {
        show(res.msg, "error");
        return;
      }
    } catch (error) {
      show(error.message, "error");
    } finally {
      setloading({ ...loading, loading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genralInfo, id, managerId, profileFile]);

  const handelEdit = () => {
    //console.log("done");
    setEdite(!isEdite);
  };

  // console.log("previewImage",previewImage);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        updateKyc();
      }}
    >
      <div className={styles.main}>
        <div
          className={styles.continer}
          style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}
        >
          <ModalWrapper isOpen={previewImage1}>
            <ImageCroper2 image={previewImage} />
          </ModalWrapper>
          {/* top bar for go back */}
          <div className={styles.go_back}>
            <div>
              <TopTabBar text={"Dealer"} />
            </div>
            <div>
              <div style={{ width: "120px" }}>
                {isEdite ? (
                  <Button
                    buttonBgColor={"#4164E3"}
                    buttonText={"Update"}
                    buttonTextColor={"#fff"}
                    buttonIcon={<Upload />}
                    loading={loading.loading}
                    disabled={loading.loading}
                  />
                ) : (
                  <Button
                    buttonBgColor={"#4164E3"}
                    buttonText={"Edit"}
                    buttonTextColor={"#fff"}
                    buttonIcon={<IsEdite />}
                    loading={loading.loading}
                    disabled={loading.loading}
                    buttonClick={(e) => {
                      e.preventDefault();
                      handelEdit();
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles.Personal_Info}>
            <div
              className={styles.Personal}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div className={styles.profileContainer}>
                <p>Personal Info</p>
                <div
                  className={styles.nm_photo}
                  onClick={handlePhotoUploadClick}
                  style={{ border: mode ? "1px solid #000" : "1px solid #ccc" }}
                >
                  {
                    genralInfo.profileImage || previewImage ?
                      <img
                        src={`${process.env.REACT_APP_S3URL}/${genralInfo?.profileImage}`}
                        alt="Manager" /> :
                      <div className={styles.Frist_text}>
                        {genralInfo.name.charAt(0).toUpperCase()}
                      </div>
                  }
                  <div className={styles.nm_camera_icon}>
                    <ManagerCameraIcon />
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handlePhotoChange}
                    accept="image/*"
                  />
                </div>
                <div className={styles.nm_info}>
                  <InputTag
                    labelText={"Full Name"}
                    star={true}
                    padding={"8px"}
                    fontSize={"12px"}
                    placeholderText={"Enter Full Name"}
                    maxLength={100}
                    placeholderType={"text"}
                    value={genralInfo?.name}
                    onChange={(event) => {
                      setGenralInfo({
                        ...genralInfo,
                        name: event.target.value,
                      });
                    }}
                    required={true}
                    disabled={isEdite ? false : true}
                  />
                </div>
                <div className={styles.nm_info}>
                  <InputTag
                    labelText={"Mobile Number"}
                    star={true}
                    padding={"8px"}
                    fontSize={"12px"}
                    placeholderText={"Enter Mobile Number"}
                    maxLength={10}
                    placeholderType={"number"}
                    value={genralInfo?.mobile}
                    onChange={(event) => {
                      setGenralInfo({
                        ...genralInfo,
                        mobile: event.target.value,
                      });
                    }}
                    required={true}
                    disabled={isEdite ? false : true}
                  />
                </div>
                <div className={styles.nm_info}>
                  <InputTag
                    labelText={"Designation"}
                    star={true}
                    padding={"8px"}
                    fontSize={"12px"}
                    placeholderText={"Enter Designation"}
                    maxLength={100}
                    placeholderType={"text"}
                    value={genralInfo?.designation}
                    onChange={(event) => {
                      setGenralInfo({
                        ...genralInfo,
                        designation: event.target.value,
                      });
                    }}
                    required={true}
                    disabled={isEdite ? false : true}
                  />
                </div>
              </div>
            </div>
            <div
              className={styles.Company}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div className={styles.Company_FristChile}>
                <div className={styles.nm_info}>
                  <InputTag
                    labelText={"Company Name"}
                    star={true}
                    padding={"8px"}
                    fontSize={"12px"}
                    placeholderText={"Enter Company Name"}
                    maxLength={100}
                    placeholderType={"text"}
                    value={genralInfo?.storeName}
                    onChange={(event) => {
                      setGenralInfo({
                        ...genralInfo,
                        storeName: event.target.value,
                      });
                    }}
                    required={true}
                    disabled={isEdite ? false : true}
                  />
                </div>
                <div className={styles.nm_info}>
                  <InputTag
                    labelText={"Email"}
                    star={true}
                    padding={"8px"}
                    fontSize={"12px"}
                    placeholderText={"Enter Email"}
                    maxLength={50}
                    placeholderType={"email"}
                    value={genralInfo?.email}
                    onChange={(event) => {
                      setGenralInfo({
                        ...genralInfo,
                        email: event.target.value,
                      });
                    }}
                    required={true}
                    disabled={isEdite ? false : true}
                  />
                </div>
                <div className={styles.nm_info}>
                  <InputTag
                    labelText={"WhatsApp Number"}
                    star={true}
                    padding={"8px"}
                    fontSize={"12px"}
                    placeholderText={"Enter WhatsApp Number"}
                    maxLength={10}
                    placeholderType={"number"}
                    value={genralInfo?.whatsapp}
                    onChange={(event) => {
                      setGenralInfo({
                        ...genralInfo,
                        whatsapp: event.target.value,
                      });
                    }}
                    required={true}
                    disabled={isEdite ? false : true}
                  />
                </div>
                <div className={styles.nm_info}>
                  <InputTag
                    labelText={"GSTUIN(Optional)"}
                    // star={true}
                    padding={"8px"}
                    fontSize={"12px"}
                    placeholderText={"Enter GSTUIN"}
                    maxLength={100}
                    placeholderType={"text"}
                    value={genralInfo?.gstuin}
                    onChange={(event) => {
                      setGenralInfo({
                        ...genralInfo,
                        gstuin: event.target.value,
                      });
                    }}
                    disabled={isEdite ? false : true}
                  />
                </div>
              </div>
              <div className={styles.Company_SeacandChile}>
                <p> Upload Your Documents </p>
                <UploadSingleFile text={"Aadhar Card"} />
                <div style={{ marginTop: "10px" }}>
                  <UploadSingleFile text={"Pan card"} />
                </div>
              </div>
            </div>
          </div>
          {/* map Address  */}
          <div className={styles.Location}>
            <div
              className={styles.LocationAdress}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <p>Location</p>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"Street Address"}
                  star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter Street"}
                  maxLength={50}
                  placeholderType={"text"}
                  value={genralInfo?.street}
                  onChange={(event) => {
                    setGenralInfo({
                      ...genralInfo,
                      street: event.target.value,
                    });
                  }}
                  required={true}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"PIN Code"}
                  star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter PIN Code"}
                  maxLength={6}
                  placeholderType={"number"}
                  value={genralInfo?.pincode}
                  onChange={(event) => {
                    setGenralInfo({
                      ...genralInfo,
                      pincode: event.target.value,
                    });
                  }}
                  required={true}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"District"}
                  star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter District"}
                  maxLength={50}
                  placeholderType={"text"}
                  value={genralInfo?.district}
                  onChange={(event) => {
                    setGenralInfo({
                      ...genralInfo,
                      district: event.target.value,
                    });
                  }}
                  required={true}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"City"}
                  star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter City"}
                  maxLength={50}
                  placeholderType={"text"}
                  value={genralInfo?.city}
                  onChange={(event) => {
                    setGenralInfo({ ...genralInfo, city: event.target.value });
                  }}
                  required={true}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"State"}
                  star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter State"}
                  maxLength={50}
                  placeholderType={"text"}
                  value={genralInfo?.state}
                  onChange={(event) => {
                    setGenralInfo({ ...genralInfo, state: event.target.value });
                  }}
                  required={true}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"Country"}
                  star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter Country"}
                  maxLength={50}
                  placeholderType={"text"}
                  value={genralInfo?.country}
                  onChange={(event) => {
                    setGenralInfo({
                      ...genralInfo,
                      country: event.target.value,
                    });
                  }}
                  required={true}
                  disabled={isEdite ? false : true}
                />
              </div>
            </div>
            <div
              className={styles.LocationMap}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div className={styles.nm_info}>
                <span style={{ fontSize: "14px" }}>
                  {" "}
                  Google map (Optional){" "}
                </span>
                <input
                  ref={searchInput}
                  type="search"
                  placeholder="Search place"
                  className={styles.seacrh_inputField1}
                  value={inputSearch}
                  disabled={isEdite ? false : true}
                  onChange={(e) => {
                    setInputSearch(e.target.value);
                  }}
                  style={{
                    backgroundColor: mode ? "#1B1D21" : "#FFFFFF",
                    border: mode ? "none" : "1px solid #D9D9D9",
                    color: mode ? "#FFFFFF" : "#000",
                  }}
                />
              </div>

              <div className={styles.Location_map}>
                {address?.place_id && (
                  <iframe
                    className={styles.google_map}
                    loading="lazy"
                    allowFullScreen
                    style={{ backgroundColor: mode ? "#000" : "#fff" }}
                    title="Wallicon Private Limited"
                    src={`https://www.google.com/maps/embed/v1/place?q=place_id:${address?.place_id}&key=${process.env.REACT_APP_MAP_KEY}`}
                  />
                )}
              </div>
            </div>
            <div
              className={styles.Account_Setting}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <p>Account Setting</p>
              <div className={styles.nm_info}>
                <VerticalDropDown
                  dropDownText={"Sales 4"}
                  label={"Assign Order Manager"}
                  height={"37px"}
                  fontSize={"12px"}
                  value={{
                    label:
                      managers?.find(
                        (item) => item.managerId === genralInfo.managerId
                      )?.name || "Select",
                  }}
                  onchange={(option) => {
                    setGenralInfo({ ...genralInfo, managerId: option.value });
                  }}
                  options={managers?.map((item) => ({
                    label: item.name,
                    value: item.managerId,
                  }))}
                  star={true}
                  required={true}
                />
              </div>
              <div className={styles.redioBtnConintner}>
                <div
                  className={styles.redioText}
                  style={{ color: mode ? "#FFFFFF" : "#646B88" }}
                >
                  Organic leads{" "}
                </div>
                <div className={styles.redioBtnConintner2}>
                  <InputRedio
                    lableText="Active"
                    name="Organic"
                    checked={genralInfo?.catalogStatus === "Active"}
                    onChange={() =>
                      setGenralInfo({ ...genralInfo, catalogStatus: "Active" })
                    }
                    required={true}
                    disabled={isEdite ? false : true}
                  />
                  <InputRedio
                    lableText="Disable"
                    name="Organic"
                    // value="Disable"
                    checked={genralInfo?.catalogStatus === "Disable"}
                    onChange={() =>
                      setGenralInfo({ ...genralInfo, catalogStatus: "Disable" })
                    }
                    required={true}
                    disabled={isEdite ? false : true}
                  />
                </div>
              </div>
              <div className={styles.redioBtnConintner}>
                <div
                  className={styles.redioText}
                  style={{ color: mode ? "#FFFFFF" : "#646B88" }}
                >
                  No Return No Exchange Mandotory Checkbox
                </div>
                <div className={styles.redioBtnConintner2}>
                  <InputRedio
                    lableText="Active"
                    name="Mandotory"
                    checked={genralInfo?.nrne === "Active"}
                    onChange={() =>
                      setGenralInfo({ ...genralInfo, nrne: "Active" })
                    }
                    required={true}
                    disabled={isEdite ? false : true}
                  />

                  <InputRedio
                    lableText="Disable"
                    name="Mandotory"
                    checked={genralInfo?.nrne === "Disable"}
                    onChange={() =>
                      setGenralInfo({ ...genralInfo, nrne: "Disable" })
                    }
                    required={true}
                    disabled={isEdite ? false : true}
                  />
                </div>
              </div>

              <div className={styles.redio_Btn_Conintner_Status}>
                <p>Status</p>
                <div className={styles.status_redio_btn}>
                  <div className={styles.redioCon}>
                    <InputRedio
                      name="Active"
                      disabled={isEdite ? false : true}
                      required={true}
                      checked={genralInfo?.status === "New"}
                      lableText="New"
                      onChange={() =>
                        setGenralInfo({ ...genralInfo, status: "New" })
                      }
                    />
                  </div>
                  <div className={styles.redioCon}>
                    <InputRedio
                      name="Active"
                      disabled={isEdite ? false : true}
                      required={true}
                      checked={genralInfo?.status === "Pending"}
                      lableText="Pending"
                      onChange={() =>
                        setGenralInfo({ ...genralInfo, status: "Pending" })
                      }
                    />
                  </div>
                </div>
                <div
                  className={styles.status_redio_btn}
                  style={{ marginTop: "20px" }}
                >
                  <div className={styles.redioCon}>
                    <InputRedio
                      name="Active"
                      disabled={isEdite ? false : true}
                      required={true}
                      checked={genralInfo?.status === "Verified"}
                      lableText="Verifyed"
                      onChange={() =>
                        setGenralInfo({ ...genralInfo, status: "Verified" })
                      }
                    />
                  </div>
                  <div className={styles.redioCon}>
                    <InputRedio
                      name="Active"
                      disabled={isEdite ? false : true}
                      required={true}
                      checked={genralInfo?.status === "Update"}
                      lableText="Update"
                      onChange={() =>
                        setGenralInfo({ ...genralInfo, status: "Update" })
                      }
                    />
                  </div>
                </div>
                <div
                  className={styles.status_redio_btn}
                  style={{ marginTop: "20px" }}
                >
                  <div className={styles.redioCon}>
                    <InputRedio
                      name="Active"
                      disabled={isEdite ? false : true}
                      required={true}
                      checked={genralInfo?.status === "Blacklisted"}
                      lableText="Blacklisted"
                      onChange={() =>
                        setGenralInfo({ ...genralInfo, status: "Blacklisted" })
                      }
                    />
                  </div>
                  <div className={styles.redioCon}>
                    <InputRedio
                      name="Active"
                      disabled={isEdite ? false : true}
                      required={true}
                      checked={genralInfo?.status === "Rejected"}
                      lableText="Rejected"
                      onChange={() =>
                        setGenralInfo({ ...genralInfo, status: "Rejected" })
                      }
                    />
                  </div>
                </div>
                <div
                  className={styles.status_redio_btn}
                  style={{ marginTop: "20px" }}
                >
                  <div className={styles.redioCon}>
                    <InputRedio
                      name="Active"
                      disabled={isEdite ? false : true}
                      required={true}
                      checked={genralInfo?.status === "Deleted"}
                      lableText="Deleted"
                      onChange={() =>
                        setGenralInfo({ ...genralInfo, status: "Deleted" })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* about */}

          <div className={styles.sosal_Media}>
            <div
              className={styles.sosale_media_link}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <div className={styles.nm_info}>
                <VerticalDropDown
                  dropDownText={"Enter your Exp"}
                  label={"Experience"}
                  height={"37px"}
                  fontSize={"12px"}
                  value={{
                    label:
                      {
                        1: "1 Year +",
                        2: "2 Year +",
                        3: "3 Year +",
                        4: "4 Year +",
                        5: "5 Year +",
                      }[genralInfo.experience] || "Select",
                  }}
                  onchange={(option) => {
                    setGenralInfo({ ...genralInfo, experience: option.value });
                  }}
                  options={[
                    { label: "1 Year +", value: 1 },
                    { label: "2 Year +", value: 2 },
                    { label: "3 Year +", value: 3 },
                    { label: "4 Year +", value: 4 },
                    { label: "5 Year +", value: 5 },
                  ]}
                  star={true}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"Facebook"}
                  // star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"www.facebook.com"}
                  maxLength={100}
                  placeholderType={"text"}
                  value={genralInfo?.facebook}
                  onChange={(event) => {
                    setGenralInfo({
                      ...genralInfo,
                      facebook: event.target.value,
                    });
                  }}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"Instagram"}
                  // star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"www.instagram.com"}
                  maxLength={100}
                  placeholderType={"text"}
                  value={genralInfo?.instagram}
                  onChange={(event) => {
                    setGenralInfo({
                      ...genralInfo,
                      instagram: event.target.value,
                    });
                  }}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"YouTube"}
                  // star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"www.youbute.com"}
                  maxLength={100}
                  placeholderType={"text"}
                  value={genralInfo?.youtube}
                  onChange={(event) => {
                    setGenralInfo({
                      ...genralInfo,
                      youtube: event.target.value,
                    });
                  }}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"Twitter"}
                  // star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"www.twitter.com"}
                  maxLength={100}
                  placeholderType={"text"}
                  value={genralInfo?.twitter}
                  onChange={(event) => {
                    setGenralInfo({
                      ...genralInfo,
                      twitter: event.target.value,
                    });
                  }}
                  disabled={isEdite ? false : true}
                />
              </div>
              <div className={styles.nm_info}>
                <InputTag
                  labelText={"Pinterest"}
                  // star={true}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"www.pinterest.com"}
                  maxLength={100}
                  placeholderType={"text"}
                  value={genralInfo?.pinterest}
                  onChange={(event) => {
                    setGenralInfo({
                      ...genralInfo,
                      pinterest: event.target.value,
                    });
                  }}
                  disabled={isEdite ? false : true}
                />
              </div>
            </div>
            <div
              className={styles.sosale_media_About}
              style={{
                backgroundColor: mode ? "#232529" : "#fff",
                color: mode ? "#fff" : "#000",
              }}
            >
              <p>Product they want to sell</p>
              <div className={styles.KycChack_coontiner}>
                {allProducts.map((product) => {
                  const isSelected = selectedProducts.some(
                    (item) => item.product_id === product.product_id
                  );

                  return (
                    <div
                      className={styles.kycchack}
                      style={{
                        backgroundColor: mode ? "#2C2E33" : "#F8F9FC",
                        color: isSelected
                          ? "green"
                          : mode
                            ? "#FFFFFF"
                            : "#4164E3",
                      }}
                      key={product?.product_id}
                      onClick={() => {
                        if (isSelected) {
                          setSelectedProducts(
                            selectedProducts.filter(
                              (item) => item.product_id !== product.product_id
                            )
                          );
                        } else {
                          setSelectedProducts([...selectedProducts, product]);
                        }
                      }}
                    >
                      {isSelected ? (
                        <KycPls mode={mode} isSelect={isSelected} />
                      ) : (
                        <KycPls mode={mode} />
                      )}
                      {product?.product_name}
                    </div>
                  );
                })}
              </div>
              <div
                className={styles.KycChack_coontiner1}
                style={{ marginTop: "10px" }}
              >
                <div style={{ marginBottom: "10px" }}> Description</div>
                <div>
                  <textarea
                    className={styles.Description_textarea}
                    style={{
                      backgroundColor: mode ? "#1D1F23" : "#FCFDFE",
                      color: mode ? "#FFFFFF" : "#000",
                      border: mode ? "1px solid #1D1F23" : "1px solid #D9D9D9",
                      minHeight: "200px",
                    }}
                    value={genralInfo?.storeDesc}
                    disabled={isEdite ? false : true}
                    placeholder="Enter description"
                    onChange={(event) => {
                      setGenralInfo({
                        ...genralInfo,
                        storeDesc: event.target.value,
                      });
                    }}
                  ></textarea>
                </div>
                <div className={styles.textarea_text}>
                  <div>
                    First 50-160 letters will be used for your Search Engine
                    ranking.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={styles.upload_file}
            style={{
              backgroundColor: mode ? "#232529" : "#fff",
              color: mode ? "#fff" : "#000",
            }}
          >
            <p>Store Image</p>
            <div className={styles.upload_file_continer}>
              {StoreImage !== "" ? (
                <div className={styles.imagePreview1}>
                  <img
                    src={StoreImage}
                    alt="Preview"
                    className={styles.uploadedImage1}
                  />
                </div>
              ) : (
                <>
                  <div>
                    <KycUplodFile />
                  </div>
                  <div>
                    <p style={{ margin: "0" }}>
                      {" "}
                      <span
                        className={styles.browseSpan}
                        onClick={handlePhotoUploadClick1}
                      >
                        {" "}
                        Click to upload{" "}
                      </span>{" "}
                      or drag and drop
                    </p>
                  </div>
                  <div>
                    <div style={{ width: "120px" }}>
                      <Button
                        buttonBgColor={"#4164E3"}
                        buttonText={"Upload"}
                        buttonIcon={<LogInBtnIcon />}
                        buttonTextColor={"#fff"}
                        buttonClick={handlePhotoUploadClick1}
                      />
                    </div>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    ref={inputForstore}
                    onChange={handleChangeForStoreImage}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Kyc;
