import React from "react";
import styles from "./Apps.module.css";

const StarRating = ({ rating }) => {
  const getStarClass = (index) => {
    if (rating >= index + 1) return "full"; 
    if (rating >= index + 0.5) return "half";
    return "empty"; 
  };

  return (
    <div className={styles.star_rating}>
      {[...Array(5)].map((_, index) => {
        const type = getStarClass(index);
        return (
          <div key={index} className={styles.star_wrapper}>
            <span className={`${styles.star} ${styles.outlined}`}>★</span>
            {type !== "empty" && (
              <span
                className={`${styles.star} ${styles.filled}`}
                style={type === "half" ? { clipPath: "inset(0 50% 0 0)" } : {}}
              >
                ★
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default StarRating;
