import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
// import styles from "./AdjustmentOrder.module.css";
import styles from "./PurchaseOrder.module.css";
import fetchData from "../../APIsControll/apiControll";
import VerticalDropDown2 from "../../components/updatedComponents/FormComponents/VerticalDropDown2";
import {
  NewEyeIcon,
  RemoveIcon,
  SaveAsDraftIcon,
  SaveIcon,
  TestTopAddDealerIcon,
} from "../../components/icons/Icon";
import AddNewItemModal from "../../components/updatedComponents/OrderManager/AddNewItemModal";
import ManagerModal from "../../components/updatedComponents/Manager/ManagerModal";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import PurchaseItemList from "../../components/updatedComponents/OrderManager/PurchaseItemList";
import PurchaseNewItemList from "../../components/updatedComponents/OrderManager/PurchaseNewItemList";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import Button from "../../components/updatedComponents/Dealers/Button";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";

const AdjustmentOrder = ({ onOrderData }) => {
  const { show } = useToast();
  const { managerName, managerId, mode } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get("orderId");
  const itemInpRefs = useRef([]);
  const quantityRefs = useRef([]);
  const scroll_wrapper_ref = useRef(null);
  const [generalInfo, setGeneralInfo] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const [showItemSearch, setShowItemSearch] = useState(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [itemsdetails, setItemsdetails] = useState([
    {
      itemNo: "",
      price: "",
      product_name: "",
      thumbnail: "",
      totalQty: "",
      qty: "",
      unit: "",
      rate: "",
      amount: "",
      warehouse: "",
    },
  ]);

  useEffect(() => {
    const apiData = {
      managerId: managerId,
      voucherNo: orderId || "",
    };

    const getAdjustmentOrder = async () => {
      try {
        setLoading(true);
        const result = await fetchData("inventory/singleAdjustment", apiData);
        if (result.status === "success") {
          setLoading(false);
          if (result.data.order) {
            setGeneralInfo((prevState) => ({
              ...prevState,
              voucherNo: result.data.order.voucherNo
                ? result.data.order.voucherNo
                : "",
              managerName: result.data.order.managerName,
              voucherDate: result.data.order.voucherDate
                ? result.data.order.voucherDate
                : "",
              warehouse_id: result.data.order.warehouse_id
                ? result.data.order.warehouse_id
                : "",
              shippingCharge: result.data.order.shippingCharge
                ? result.data.order.shippingCharge
                : "",
              manageName: result.data.order.manageName
                ? result.data.order.manageName
                : "",
              manageValue: result.data.order.manageValue
                ? result.data.order.manageValue
                : "",
              status: result.data.order.status ? result.data.order.status : "",
            }));
            setItemsdetails(result.data.order.itemsdetails);
          }
          if (result.data.warehouses) {
            setWarehouses(result.data.warehouses);
          }
        }
      } catch (error) {
        //console.log("Error ", error);
        setLoading(false);
      }
    };
    if (managerId) {
      getAdjustmentOrder();
    }
  }, [managerId, orderId]);

  useEffect(() => {
    onOrderData({
      loading,
      status: generalInfo.status,
      orderId: orderId,
      orderDate: generalInfo.voucherDate,
    });
  }, [
    generalInfo.status,
    generalInfo.voucherDate,
    loading,
    onOrderData,
    orderId,
  ]);

  const handleAdjustmentOrder = async (status) => {
    const apiData = {
      managerId: managerId,
      voucherNo: orderId || "",
      warehouse_id: generalInfo.warehouse_id,
      shippingCharge: generalInfo.shippingCharge || "",
      manageName: generalInfo.manageName || "",
      manageValue: generalInfo.manageValue || "",
      itemsdetails: itemsdetails.filter((item) =>
        Object.values(item).some(
          (value) => value !== "" && value !== null && value !== undefined
        )
      ),
      status: status,
    };
    try {
      setLoading2(true);
      const result = await fetchData("inventory/adjustment", apiData);
      if (result.status === "success") {
        setLoading2(false);
        show(result.msg, "success");
        navigate("/orders/adjustmentinvoice/" + result.data.voucherNo);
      }
      if (result.status === "failed") {
        setLoading2(false);
        show(result.msg, "error");
      }
    } catch (error) {
      setLoading2(false);
      //console.log("Error ", error);
    }
  };

  const handleItemInputFocus = (index) => {
    setFocusedItemIndex(index);
    setShowItemSearch(true);
  };

  const handleItemInputBlur = () => {
    setTimeout(() => {
      setShowItemSearch(false);
      setFocusedItemIndex(null);
    }, 200);
  };

  const handleSelectItem = (item, index) => {
    setTimeout(() => {
      setItemsdetails((prevState) => {
        const updatedItems = prevState?.map((currentItem, idx) => {
          return idx === index
            ? {
                ...currentItem,
                ...item,
                rate: item.rate ?? currentItem.rate,
              }
            : currentItem;
        });
        return updatedItems;
      });
    }, 0);
  };

  const handleFocusNextItem = () => {
    setTimeout(() => {
      const lastItemIndex = itemsdetails?.length - 1;

      if (itemInpRefs.current?.length) {
        const nextItemIndex = lastItemIndex + 1;

        if (nextItemIndex < itemInpRefs.current?.length) {
          const nextItemRef = itemInpRefs.current[nextItemIndex];

          if (nextItemRef) {
            nextItemRef.focus();
            setFocusedItemIndex(nextItemIndex);
            setShowItemSearch(true);
          }
        }
      }
    }, 0);
  };

  const handleAddClick = () => {
    const errors = [];
    if (itemsdetails)
      itemsdetails.forEach((item) => {
        if (item.itemNo === "") {
          errors.push(`Item Number cannot be empty`);
        }
        if (item.qty === "") {
          errors.push(`Item Quantity cannot be empty`);
        }
      });

    if (errors?.length > 0) {
      show(errors.join(" "), "warning");
    } else {
      setItemsdetails([
        ...itemsdetails,
        {
          itemNo: "",
          product_name: "",
          collection_name: "",
          warehouse: "",
          qty: "",
          dp: "",
          unit: "",
          amount: "",
          totalQty: "",
          desc: "",
          descKey: "",
        },
      ]);
      if (scroll_wrapper_ref.current) {
        setTimeout(() => {
          const container = scroll_wrapper_ref.current;
          const scrollAmount = container.scrollTop + 100;
          container.scrollTo({ top: scrollAmount, behavior: "smooth" });
        }, 100);
      }
      handleFocusNextItem();
    }
  };

  const handleRemoveClick = (index) => {
    if (itemsdetails?.length > 1) {
      const list = [...itemsdetails];
      list.splice(index, 1);
      setItemsdetails(list);
    }
  };

  const handleOpenModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const verifyEntries = () => {
    const warnings = [];
    if (generalInfo.warehouse_id === undefined || !generalInfo.warehouse_id) {
      warnings.push("Warehouse is required!");
    }
    if (
      itemsdetails.every(
        (item) =>
          item.amount === "" &&
          item.price === "" &&
          item.unit === "" &&
          item.qty === "" &&
          item.itemNo === ""
      ) ||
      itemsdetails.some((item) =>
        Object.values(item).every((value) => value === "")
      )
    ) {
      warnings.push("Item Details are required");
    }
    if (warnings?.length === 0) {
      return true;
    } else {
      show(warnings.join(" "), "warning");
      return false;
    }
  };

  const handleSubmit = () => {
    handleOpenModal(
      <ManagerModal
        heading={"Confirm Order"}
        centerHeading={"Are you sure want to place this Order?"}
        targetWord={"order"}
        handleCloseModal={() => handleCloseModal()}
        handleSubmit={() => handleAdjustmentOrder("success")}
      />
    );
  };

  const openAddItemModal = () => {
    handleOpenModal(
      <AddNewItemModal
        handleCloseModal={() => setIsModalOpen(false)}
        managerId={managerId}
        mode={mode}
      />
    );
  };

  let cartPriceSum =
    itemsdetails &&
    itemsdetails.reduce((sum, currentItem) => {
      return (
        sum +
        parseFloat(currentItem.price ? currentItem.price : 0) *
          parseFloat(currentItem.qty ? currentItem.qty : 0)
      );
    }, 0);

  let subtotal = cartPriceSum ? cartPriceSum : 0;

  let totalAmount =
    subtotal +
    parseFloat(generalInfo.shippingCharge ? generalInfo.shippingCharge : 0) +
    parseFloat(generalInfo.manageValue ? generalInfo.manageValue : 0);

  const adjustmentHeader = [
    "S.No",
    "Item No",
    "Product",
    "Quantity",
    "Unit",
    "Rate",
    "Amount",
  ];

  return (
    <>
      <ModalWrapper isOpen={isModalOpen} onClose={handleCloseModal} noBtn>
        {modalContent}
      </ModalWrapper>

      <div className={styles.scroll_wrapper} ref={scroll_wrapper_ref}>
        <form className={styles.a_form}>
          {(loading && orderId) || loading2 ? (
            <div>
              <Skeleton
                lines={1}
                thickness={4.5}
                additionalStyles={{
                  marginBottom: "0px",
                  marginTop: "20px",
                }}
              />
            </div>
          ) : (
            <div>
              <VerticalDropDown2
                height={"37px"}
                fontSize={"12px"}
                dropDownText={"Auto"}
                label={"Warehouse"}
                value={{
                  label:
                    warehouses?.find((w) => w._id === generalInfo.warehouse_id)
                      ?.warehousename || "Select",
                  value: generalInfo.warehouse_id,
                }}
                options={
                  warehouses &&
                  warehouses?.map((w) => ({
                    label: w.warehousename,
                    value: w._id,
                  }))
                }
                onchange={(option) => {
                  setGeneralInfo((prevState) => ({
                    ...prevState,
                    warehouse_id: option.value,
                  }));
                  itemInpRefs.current[0]?.focus();
                }}
                dropDownOpen={!orderId && true}
                disableOpen={orderId}
              />
            </div>
          )}
          {(loading && orderId) || loading2 ? (
            <div>
              <Skeleton
                lines={1}
                thickness={4.5}
                additionalStyles={{
                  marginBottom: "0px",
                  marginTop: "20px",
                }}
              />
            </div>
          ) : (
            <div>
              <InputTag
                labelText={"Manager Name"}
                value={orderId ? generalInfo.managerName : managerName}
                padding={"8px"}
                fontSize={"12px"}
                required
                readOnly={true}
              />
            </div>
          )}
        </form>

        <ul className={styles.p_table}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              visibility: loading && "hidden",
            }}
          >
            <li
              className={`${styles.p_table_header} ${
                mode ? styles.p_table_header_dark : ""
              }`}
            >
              {adjustmentHeader?.map((h, index) => (
                <div
                  className={`${styles.entry_2} ${
                    h === "Item No" ? styles.item_no : ""
                  }`}
                  key={`${h}-${index}`}
                >
                  {h}
                </div>
              ))}
            </li>
            {!orderId && itemsdetails?.length > 1 && (
              <div style={{ cursor: "pointer", visibility: "hidden" }}>
                <RemoveIcon />
              </div>
            )}
          </div>
          <div className={styles.p_table_list}>
            <>
              {itemsdetails?.map((item, index) => (
                <React.Fragment key={index}>
                  {orderId ? (
                    <>
                      {(loading && orderId) || loading2 ? (
                        <div>
                          <Skeleton
                            lines={
                              itemsdetails?.length === 1
                                ? 1
                                : itemsdetails?.length / 2
                            }
                            thickness={5}
                            additionalStyles={{
                              marginBottom: "0px",
                              marginTop: "0px",
                            }}
                          />
                        </div>
                      ) : (
                        <PurchaseItemList
                          index={index}
                          mode={mode}
                          item={item}
                        />
                      )}
                    </>
                  ) : (
                    <PurchaseNewItemList
                      index={index}
                      mode={mode}
                      item={item}
                      managerId={managerId}
                      itemInpRefs={(el) => (itemInpRefs.current[index] = el)}
                      itemsdetailslength={itemsdetails?.length}
                      onSelectItem={(item) => {
                        handleSelectItem(item, index);
                      }}
                      addItem={() => handleAddClick()}
                      removeItem={() => handleRemoveClick(index)}
                      showItemSearch={
                        showItemSearch && focusedItemIndex === index
                      }
                      setShowItemSearch={setShowItemSearch}
                      focusedIndex={focusedItemIndex}
                      onFocus={() => handleItemInputFocus(index)}
                      onBlur={handleItemInputBlur}
                      onCompletion={() => handleSubmit()}
                      warehouse_id={generalInfo.warehouse_id}
                      openAddItemModal={openAddItemModal}
                      type={"Adjustment"}
                      quantityRefs={quantityRefs.current}
                    />
                  )}
                </React.Fragment>
              ))}
            </>
          </div>
          {!orderId && (
            <div
              className={styles.cricle_btn}
              onClick={() => {
                handleAddClick();
              }}
            >
              <TestTopAddDealerIcon color="#4164E3" />
            </div>
          )}
        </ul>
      </div>

      {(loading && orderId) || loading2 ? (
        <div
          style={{ padding: "0", boxShadow: "none" }}
          className={`${styles.footer} ${mode ? styles.footer_dark : ""}`}
        >
          <Skeleton
            lines={1}
            thickness={5}
            additionalStyles={{
              marginBottom: "0px",
              marginTop: "0px",
              borderRadius: "15px 15px 0 0",
            }}
          />
        </div>
      ) : (
        <div className={`${styles.footer} ${mode ? styles.footer_dark : ""}`}>
          {/* <div className={styles.footer_left}>
          <div
            className={`${styles.receipt_data} ${
              mode ? styles.receipt_data_dark : ""
            }`}
          >
            <div
              className={`${styles.receipt_data_header} ${
                mode ? styles.receipt_data_header_dark : ""
              }`}
            >
              <div className="col">Pay ID </div>
              <div className="col">Method</div>
              <div className="col">Amount</div>
              <div className="col">Status</div>
            </div>
            <div className={styles.scroll_con}>
              <div
                className={`${styles.receipt_data_entry} ${
                  mode ? styles.receipt_data_entry_dark : ""
                }`}
              >
                <div className="col">2 </div>
                <div className="col">2 </div>
                <div className="col">3 </div>
                <div className="col" style={{ color: "#FB9600" }}>
                  1
                </div>
              </div>
            </div>
          </div>
          <div className={styles.add_receipt_btn}>
            <TestTopAddDealerIcon />
          </div>
        </div> */}

          <div className={styles.footer_right}>
            {orderId ? (
              <div>
                <Button
                  buttonIcon={<NewEyeIcon />}
                  buttonText={"View Invoice"}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={() =>
                    navigate("/orders/adjustmentinvoice/" + orderId)
                  }
                />
              </div>
            ) : (
              <div>
                <Button
                  buttonIcon={<SaveAsDraftIcon />}
                  buttonText={"Save as Draft"}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                />
              </div>
            )}
            <div
              className={`${styles.footer_right_1} ${
                mode ? styles.footer_right_1_dark : ""
              }`}
            >
              <div>
                <p>Sub Total:</p>
                <p>₹ {subtotal}</p>
              </div>
              <div>
                <p>Shipping Charges:</p>
                <p>
                  ₹{" "}
                  {generalInfo.shippingCharge ? generalInfo.shippingCharge : 0}
                </p>
              </div>
              <div>
                <p>Total Amount(INR):</p>
                <p>₹ {totalAmount}</p>
              </div>
            </div>
            {!orderId && (
              <div className={styles.footer_right_2}>
                <Button
                  buttonIcon={<SaveIcon />}
                  buttonText={"Save"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#FFFFFF"}
                  buttonBorder={"none"}
                  buttonClick={() => {
                    if (verifyEntries()) {
                      handleSubmit();
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AdjustmentOrder;
