import styles from "./Transactions.module.css";

const DetailRow = ({
  mode,
  labelLeft,
  valueLeft,
  labelRight,
  valueRight,
  color,
}) => (
  <div className={styles.detailRow}>
    <div className={styles.element}>
      <p style={{ color: mode ? "#979797" : "" }}>{labelLeft}</p>
      <p style={{ color: color || (mode ? "#FFFFFF" : "") }}>{valueLeft}</p>
    </div>
    {labelRight && valueRight && (
      <div className={styles.element} style={{ textAlign: "right" }}>
        <p style={{ color: mode ? "#979797" : "" }}>{labelRight}</p>
        <p style={{ color: color || (mode ? "#FFFFFF" : "") }}>{valueRight}</p>
      </div>
    )}
  </div>
);

export default DetailRow;
