import styles from "./Apps.module.css";
import StarRating from "./StarRating";

const ReviewCard = ({ mode, ratingCount, review, name, store }) => {
  return (
    <div
      className={styles.review}
      style={mode ? { backgroundColor: "#1B1D21", color: "#FFFFFF" } : {}}
    >
      <div>
        <StarRating rating={ratingCount} />
      </div>
      <p>{review}</p>
      <div>
        <div className={styles.review_photo}></div>
        <div className={styles.review_user_dets}>
          <p>{name}</p>
          <p style={mode ? { color: "#FFFFFF" } : {}}>{store}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
