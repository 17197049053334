import React, { useEffect, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useAuth } from "../../components/context/Auth";
import { CancelIcon2, Printbtn, Spinner } from "../../components/icons/Icon";
import styles from "./DishpatchManager.module.css";
import styles1 from "../../components/updatedComponents/Test/Test.module.css";

import { RedOutButton } from "../../components/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { PackingSlip } from "./PackingSlip";
import Button from "../../components/updatedComponents/Dealers/Button";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import { TemplateDone } from "../../components/icons/Icon2";
import {
  Back,
  Cancel,
  Canceldeny,
  Label,
  Pack,
  Pakingslip,
  Viewdispatch,
} from "../../components/icons/Icon4";
import DropDown2 from "../../components/updatedComponents/FormComponents/Dropdown2";
import DropDown3 from "../../components/updatedComponents/FormComponents/Dropdown3";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";
import ImageUploader from "../../components/updatedComponents/Product/ImageUploder";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import { PrintLabel } from "./PrintLabel";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";
// import { AllPackingorder } from "../../Socket/socket";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";

const PackingVoucher = () => {
  let componentRef = React.useRef();
  const navigate = useNavigate();
  const { switchDisplay, managerId, designation, mode } = useAuth();
  const params = useParams();
  const orderId = params.orderId;
  const [loading, setLoading] = useState(false);
  const [pakingManagers, stPakingManagers] = useState([]);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [partners, setPartners] = useState("");
  const [data, setData] = useState({});
  const [isReloaded, setIsReloaded] = useState(false);
  const [lableBtnLoading, setLableBtnLoading] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const { show } = useToast()
  // const [isImage, setisImage] = React.useState("");
  // const [selectedpackingmanager, setSelectedpackingmanager] = useState("");
  const [courierPartners, setCourierPartners] = useState([]);
  const [isloading, setIsloading] = useState({
    acceptloading: false,
  });
  const [selectedManager, setSelectedManager] = useState(null);
  const [delivery, setDelivery] = useState({
    courierName: "",
    partnerName: "",
  });
  const [printLabel, setPrintLabel] = useState({
    qtyPacks: "",
    delPerName: "",
    delPerMobile: "",
    delTrackingNo: "",
    delTrackingUrl: "",
    courierSlip: "",
  });
  const optionsPart = courierPartners?.map((Part) => ({
    name: Part.partnerName,
    contactNumber: Part.contactNumber,
    trackingNumber: Part.trackingNumber,
    trackingUrl: Part.trackingUrl,
  }));

  const handlePartnerSelection = (optionis) => {
    // console.log("optionis", optionis.name);
    setPrintLabel((prevState) => ({
      ...prevState,
      delPerName: optionis.name || "",
      delPerMobile: optionis.contactNumber || "",
      delTrackingNo: optionis.trackingNumber || "",
      delTrackingUrl: optionis.trackingUrl || "",
    }));
  };



  const [courierSlip, setCourierSlip] = useState(null);

  const [showPackedModal, setShowPackedModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [images, setImages] = useState([]);
  const [packs, setPacks] = useState();
  const [point, setPoint] = useState();

  const getOrder = async () => {
    setLoading(true);
    if (managerId && orderId) {
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        managerId: managerId,
        orderId: orderId,
        courierName: delivery.courierName,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            getlocation();
            setData(result.data);
            setPartners(result.data.courierPartners);
            setCourierPartners(result.data.courierPartners);
            stPakingManagers(result?.data?.pakingManagers);
            setPrintLabel((prevState) => ({
              ...prevState,
              qtyPacks: result.data.order.qtyPacks,
              delPerName: result.data.order.delPerName,
              delPerMobile: result.data.order.delPerMobile,
              delTrackingNo: result.data.order.delTrackingNo,
              delTrackingUrl: result.data.order.delTrackingUrl,
              courierSlip: result.data.order.courierSlip,
            }));
            // setPartnerdata(result.data.courierPartners);

            if (!delivery.courierName) {
              setDelivery((prevState) => ({
                ...prevState,
                courierName: result.data.order.courierName,
                methodName: result.data.order.methodName,
                partnerName: result.data.order.partnerName,
                shippingType: result.data.order.shippingType,
              }));
            }
          }
        })

        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerId, orderId, delivery.courierName, isReloaded]);

  const handleSendLink = async () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      orderId: orderId,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    await fetch(
      process.env.REACT_APP_URL + "receipt/generatePaymentLink",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setPaymentLink(result.shortUrl);
          orderPlacedNotification1(
            data.order.paymentMethod,
            result.shortUrl,
            data.order.whatsapp === "" ? data.order.mobile : data.order.whatsapp
          );
        }
      });
  };

  const handleAcceptOrder = () => {
    setIsloading({ ...isloading, acceptloading: true });
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      pmId: selectedManager === null ? managerId : selectedManager.managerId,
      courierName: delivery.courierName,
      partnerName: delivery.partnerName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/acceptOrder", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          handleSendLink();
          // AllPackingorder({ socketUserId: `${managerId}`, role: designation })
        }
        if (result.status === "failed") {
          show("error", result.msg);
        }
        setShowAcceptModal(true);
      }).catch(() => setIsloading({ ...isloading, acceptloading: false }));
  };

  const orderPlacedNotification1 = (
    paymentMethod,
    paymentLinkis,
    whatsappNo
  ) => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: whatsappNo,
      templateName:
        paymentMethod === "POD" ? "order_placed_pod" : "online_order_placed",
      headerId: "https://dealer.wallicon.in/assets/order_placed.jpg",
      buttonDataPayload: paymentLinkis,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        getOrder();
        getlocation();
        setShowAcceptModal(false);
      })
      .finally(() => {
        setIsloading({ ...isloading, acceptloading: false });
        setShowAcceptModal(false);
      });
  };

  useEffect(() => {
    if (packs) {
      setPoint(
        Array.from(
          Array(
            packs === "0"
              ? 1
              : packs === "1"
                ? 1
                : packs === "2"
                  ? 2
                  : parseInt(packs - 1)
          ).keys()
        )
      );
    }
  }, [packs]);

  useEffect(() => {
    if (check && images.length < parseInt(packs)) {
      point.forEach((element) => {
        setImages((prevState) => [...prevState.slice(0, images.length), {}]);
      });
    }
  }, [check, images.length, packs, point]);

  const handlePrintLabel = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      qtyPacks: printLabel.qtyPacks,
      delPerName: printLabel.delPerName,
      delPerMobile: printLabel.delPerMobile,
      delTrackingNo: printLabel.delTrackingNo,
      delTrackingUrl: printLabel.delTrackingUrl,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          handlePrint();
          setShowLabelModal(false);
        } else {
          alert(result.msg);
        }
      });
  };
  const handlePacked = (e) => {
    if (!images.length) {
      e.preventDefault();
      setShowPackedModal(true);
      alert("Please upload image.");
      return;
    }

    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    var formdata = new FormData();
    formdata.append("packingQty", packs);
    images.forEach((image) => formdata.append("images", image.image));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_URL}packing/orderPacked/${managerId}/${orderId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          getOrder();
          getlocation();
        } else {
          alert(result.msg);
        }
      });
  };

  const labelRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => labelRef.current,
  });

  // const generatePaymentLink = () => {
  //   setPaymentLink("");
  //   var myHeaders = new Headers();
  //   myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     orderId: orderId,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     process.env.REACT_APP_URL + "receipt/generatePaymentLink",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === "success") {
  //         setPaymentLink(result.shortUrl);
  //       }
  //     });
  // };

  const handleDispatched = () => {
    if (
      printLabel.delPerName === "" ||
      printLabel.delPerName === undefined ||
      printLabel.delPerName === "undefined"
    ) {
      alert("Partner is required.");
      return;
    }
    if (!courierSlip) {
      alert("Image is required.");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("shippingType", delivery.shippingType || "");
    formdata.append("courierName", delivery.courierName || "");
    formdata.append("partnerName", delivery.partnerName || "");
    formdata.append("delPerName", printLabel.delPerName || "");
    formdata.append("delPerMobile", printLabel.delPerMobile || "");
    formdata.append("delTrackingNo", printLabel.delTrackingNo || "");
    formdata.append("delTrackingUrl", printLabel.delTrackingUrl || "");
    formdata.append("image", courierSlip);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_URL}dispatch/deliveryDetails/${managerId}/${orderId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          if (result.data.dac === null) {
            // orderDispatchedNotification();
          } else {
            orderDispatchedNotificationAuth(
              result.data.shippingInfo.name,
              result.data.orderId,
              result.data.dac
            );
          }
          handleSendLink();
          setShowDeliveryModal(false);
          setIsReloaded(Math.random());
        }

        alert(result.msg);

      });

  };
  // const orderDispatchedNotification = () => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //   myHeaders.append("Content-Type", "application/json");

  //   const raw = JSON.stringify({
  //     managerId: managerId,
  //     phoneNumber: data.order.whatsapp,
  //     templateName: "online_order_dispatched",
  //     headerId: "https://dealer.wallicon.in/assets/dispatched.jpg",
  //     buttonDataPayload: paymentLink,
  //   });
  //   const requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       getOrder();
  //       getlocation();
  //     });
  // };
  const orderDispatchedNotificationAuth = (name, id, dac) => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: data.order.whatsapp,
      templateName: "delivery_auths_code",
      headerId: "https://dealer.wallicon.in/assets/dispatched.jpg",
      buttonDataPayload: paymentLink,
      dealerName: name,
      orderId: id,
      deliveryCode: dac,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        getOrder();
        getlocation();
      });
  };
  // useEffect(() => {
  //   if (isImage) {

  //     setImages((prevState) => [...prevState, ...isImage]);
  //   }
  // }, [isImage]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleDispatched();
    }
  };

  const [isFetching, setIsFetching] = useState(false);

  const getlocation = () => {
    if (isFetching) return;

    if (
      data.order &&
      data.order.shippingInfo &&
      data.order.shippingInfo.pincode
    ) {
      setLableBtnLoading(true);
      setIsFetching(true); // Set fetching flag

      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        keyword: data.order.shippingInfo.pincode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData((prevState) => ({
              ...prevState,
              SubDistName: result.SubDistName[0] ? result.SubDistName[0] : "",
              districts: result.districts[0] ? result.districts[0] : "",
            }));
          }
          setLableBtnLoading(false);
        })
        .finally(() => setIsFetching(false));
    }
  };

  const handleApproveEdit = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "dispatch/editOrderApproved",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowApproveModal(false);
          navigate("/dispatchdashboard");
        }
      });
  };

  const handleApproveCancel = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "dispatch/cancelOrderApproved",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowApproveModal(false);
          navigate("/dispatchdashboard");
        } else {
          alert(result.msg);
          setShowApproveModal(false);
        }
      });
  };

  const handleDenyEdit = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/editOrderDeny", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowDenyModal(false);
        }
      });
  };
  const handleDenyCancel = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "dispatch/canceleOrderDeny",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowDenyModal(false);
        }
      });
    // .catch((error) => console.error(error));
  };

  const handleCancelOrder = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      cancelReason: cancelReason,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/cancelOrder", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowCancelOrderModal(false);
          getOrder();
        } else {
          alert(result.msg);
        }
      });
  };

  const filterItem =
    data.order &&
    data.order.items &&
    data.order.items.length > 0 &&
    data.order.items.filter((item) => item.unit || item.qty > 0 || item.desc);

  const handleManagerSelect = (option) => {
    setSelectedManager(option);
  };

  const handleManagerReject = () => {
    setSelectedManager(null);
  };
  const orderHeader = [
    "S.No.",
    "Item Number",
    "Product",
    "Warehouse",
    "Quantity",
    "Unit",
  ];

  return (
    <React.Fragment>
      <div>
        <ModalWrapper
          isOpen={showDeliveryModal}
          onClose={() => setShowDeliveryModal(false)}
          closeBtn={true}
          style={{
            backgroundColor: mode ? "#232529" : "white",
            overflowY: "scroll",
            width: "64vh",
            borderRadius: "20px",
            padding: "20px",
          }}
          heading={
            data.order && data.order.dispatchStatus === "Dispatched"
              ? "UPDATE DELIVERY DETAILS"
              : "DELIVERY DETAILS"
          }
          children={
            <div
              className={styles.text_center}
              style={{ position: "relative" }}
            >
              <div
                style={{
                  position: "absolute",
                  right: "20px",
                  fontSize: "22px",
                  fontWeight: "500",
                  color: mode ? "white" : "#646B88",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                }}
                onClick={() => setShowDeliveryModal(false)}
              >
                &times;
              </div>

              <div
                style={{
                  color: mode ? "white" : "#646B88",
                  fontWeight: "600",
                  fontSize: "18px",
                  padding: "2px",
                }}
              >
                Delivery Details
              </div>

              <form onSubmit={handleSubmit}>
                <div className={styles.modal_div}>
                  <InputTag
                    labelText={"Method"}
                    padding={"8px"}
                    fontSize={"12px"}
                    maxLength={40}
                    value={delivery.courierName}
                  />
                  <div className="w-100 mx-1">
                    <InputTag
                      labelText={"Sub-Method"}
                      padding={"8px"}
                      fontSize={"12px"}
                      maxLength={40}
                      value={delivery.methodName}
                    />
                  </div>

                  <div className="d-flex flex-column w-100 ms-1">
                    <label
                      style={{
                        color: mode ? "white" : "#646B88",
                      }}
                    >
                      {" "}
                      &nbsp;Partner
                    </label>
                    <DropDown3
                      // listLabel={"Select"}
                      listLabel={printLabel.delPerName || ""}
                      options={optionsPart}
                      onSelect={(event) => handlePartnerSelection(event)}
                    />
                  </div>
                </div>

                <div style={{ gap: "20px" }} className={styles.modal_div}>
                  <InputTag
                    padding={"8px"}
                    placeholderText={"Name"}
                    fontSize={"12px"}
                    maxLength={40}
                    value={printLabel.delPerName || ""}
                    onChange={(e) => {
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delPerName: e.target.value,
                      }));
                    }}
                  />
                  <InputTag
                    padding={"8px"}
                    placeholderText={"number"}
                    fontSize={"12px"}
                    maxLength={40}
                    value={printLabel?.delPerMobile || ""}
                    onChange={(e) => {
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delPerMobile: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div style={{ gap: "20px" }} className={styles.modal_div}>
                  <InputTag
                    padding={"8px"}
                    placeholderText={"Tracking Number"}
                    fontSize={"12px"}
                    maxLength={40}
                    value={printLabel.delTrackingNo || ""}
                    onChange={(e) => {
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delTrackingNo: e.target.value,
                      }));
                    }}
                  />

                  <InputTag
                    padding={"8px"}
                    placeholderText={"Tracking URL"}
                    fontSize={"12px"}
                    maxLength={40}
                    value={printLabel.delTrackingUrl || ""}
                    onChange={(e) => {
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delTrackingUrl: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div className={styles.dragImage_div}>
                  <ImageUploader
                    setimage={(file) => setCourierSlip(file)}
                    image={courierSlip}
                    styles={styles}
                  />
                </div>

                <div
                  style={{ justifyContent: "center" }}
                  className={styles.modalBtn_div}
                >
                  <div style={{ width: "140px" }}>
                    <Button
                      buttonIcon={<Cancel />}
                      buttonTextColor={"white"}
                      buttonBgColor={"black"}
                      buttonText={"CANCEL"}
                      buttonClick={(e) => {
                        e.preventDefault();
                        setShowDeliveryModal(false);
                      }}
                    />
                  </div>

                  <div style={{ width: "140px" }}>
                    <Button
                      loading={loading}
                      buttonIcon={<Viewdispatch />}
                      buttonTextColor={"white"}
                      buttonBgColor={"#4164E3"}
                      buttonText={"DISPATCHED"}
                    // buttonClick={
                    //   data.order && data.order.dispatchStatus === "Dispatched"
                    //     ? "UPDATE DISPATCHED"
                    //     : "DISPATCHED"
                    // }
                    />
                  </div>
                </div>
              </form>
            </div>
          }
        />

        <ModalWrapper
          isOpen={showPackedModal}
          onClose={() => setShowPackedModal(false)}
          closeBtn={true}
          style={{
            maxWidth: "400px",
            backgroundColor: mode ? "#232529" : "white",
            borderRadius: "20px",
            padding: "20px",
          }}
          children={
            <form onSubmit={handlePacked} className={styles.text_center}>
              <div className={styles.main_div}>
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    cursor: "pointer",
                    color: mode ? "white" : "#646B88",
                    fontSize: "15px",
                    fontWeight: "400",
                  }}
                  onClick={() => setShowPackedModal(false)}
                >
                  &#10006;
                </div>

                <div
                  style={{
                    color: mode ? "white" : "#646B88",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  {"PACKING STATUS"}
                </div>

                <div
                  style={{
                    color: mode ? "white" : "black",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
                  <input
                    style={{
                      border: mode ? "" : "1px solid #E4E7EB",
                      backgroundColor: mode ? "#1C1D21" : "white",
                      color: mode ? "white" : "black",
                    }}
                    type="number"
                    name="packs"
                    value={packs}
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 4))
                    }
                    onChange={(e) => {
                      setPacks(e.target.value);
                    }}
                    className={styles.enterPackets}
                    required
                    autoFocus={true}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>

                <div
                  style={{
                    color: mode ? "white" : "black",
                    fontWeight: "400",
                    fontSize: "16px",
                    marginTop: "5px",
                  }}
                  className={styles.checkBox_addImage}
                >
                  <input
                    type="checkbox"
                    name="vehicle1"
                    value="check"
                    className={styles.checkbox}
                    checked={check}
                    onChange={(e) => setCheck(e.target.checked)}
                  />
                  <label htmlFor="vehicle1">
                    &nbsp;&nbsp;Add image of every pack
                  </label>
                </div>

                <div>
                  <div
                    className={styles.dragImage_div}
                    style={{
                      maxHeight: "400px",
                      overflowY: "auto",
                      position: "relative",
                    }}
                  >
                    {check
                      ? Array.from({ length: packs }, (_, index) => (
                        <div
                          key={index}
                          className={styles.packImage}
                          style={{ gap: "20px" }}
                        >
                          <ImageUploader
                            setimage={(uploadedImage) => {
                              setImages((prevImageData) => {
                                const updatedImageData = [...prevImageData];
                                updatedImageData[index] = {
                                  image: uploadedImage,
                                };
                                return updatedImageData;
                              });
                            }}
                            image={images[index]?.image}
                            labelText={`Product Image ${index + 1}`}
                          />
                        </div>
                      ))
                      : packs > 0 && (
                        <div
                          className={styles.packImage}
                          style={{ gap: "20px" }}
                        >
                          <ImageUploader
                            setimage={(uploadedImage) => {
                              setImages([{ image: uploadedImage }]);
                            }}
                            image={images[0]?.image}
                            labelText={"Product Image"}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div
                style={{ width: "25vh" }}
                className={styles.packingModal_btn}
              >
                <Button
                  buttonIcon={<Cancel />}
                  buttonBgColor={"black"}
                  buttonText={"NO"}
                  buttonTextColor={"white"}
                  buttonClick={(e) => {
                    e.preventDefault();
                    setShowPackedModal(false);
                  }}
                />

                <Button
                  loading={loading}
                  buttonIcon={<TemplateDone />}
                  buttonBgColor={"#4164E3"}
                  buttonText={"YES"}
                  buttonTextColor={"white"}
                  buttonClick={() => {
                    if (!packs || isNaN(packs) || packs <= 0) {
                      alert("Please enter a valid number of packs.");
                      return;
                    } else {
                      setShowPackedModal(false);
                    }
                  }}
                />
              </div>
            </form>
          }
        />

        <ModalWrapper
          isOpen={showLabelModal}
          onClose={() => setShowLabelModal(false)}
          style={{
            borderRadius: "20px",
            backgroundColor: mode ? "white" : "black",
            width: "45vh",
          }}
        >
          <div
            className={styles.text_center}
            style={{
              backgroundColor: mode ? "#2C2E33" : "#fff",
              color: mode ? "#fff" : "#000",
              borderRadius: "10px",
              padding: "20px",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "16px",
                right: "15px",
                cursor: "pointer",
                fontSize: "22px",
                color: mode ? "white" : "#646B88",
              }}
              onClick={() => setShowLabelModal(false)}
              title="Close"
            >
              &times;
            </div>

            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: mode ? "white" : "#646B88",
              }}
            >
              Print Label
            </p>
            <p
              style={{
                color: mode ? "white" : "black",
                fontSize: "16px",
                fontWeight: "400",
                marginBottom: "10px",
              }}
              className={styles.sliptext}
            >
              Are you sure you want to Print Label & Marked as Packed?
            </p>

            <div className={styles.modal_div}>
              <p
                style={{
                  color: mode ? "white" : "black",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginBottom: "5px",
                }}
                className={styles.sliptext}
              >
                Enter Number Of Packs
              </p>
              <input
                name="name"
                type="number"
                className={`${switchDisplay ? styles.inputcolorpack : styles.inputpack
                  }`}
                value={printLabel.qtyPacks}
                onChange={(e) =>
                  setPrintLabel((prevState) => ({
                    ...prevState,
                    qtyPacks: e.target.value,
                  }))
                }
                style={{
                  backgroundColor: mode ? "#232529" : "#fff",
                  color: mode ? "#9F9F9F" : "#000",
                  borderRadius: "5px",
                  padding: "10px",
                  width: "15%",
                  boxSizing: "border-box",
                  border: mode ? "#232529" : "1px solid #D9D9D9",
                }}
              />
            </div>

            <div>
              <div className={styles.modal_div}>
                <input
                  name="name"
                  type="text"
                  placeholder="Name"
                  className={`${switchDisplay ? styles.inputcolor : styles.input
                    }`}
                  value={printLabel.delPerName}
                  onChange={(e) =>
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerName: e.target.value,
                    }))
                  }
                  style={{
                    backgroundColor: mode ? "#232529" : "#fff",
                    color: mode ? "#9F9F9F" : "#000",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "100%",
                    border: mode ? "#232529" : "1px solid #D9D9D9",
                    marginBottom: "10px",
                  }}
                />
                <input
                  name="mobile"
                  type="number"
                  placeholder="Mobile Number"
                  className={`${switchDisplay ? styles.inputcolor : styles.input
                    }`}
                  value={printLabel.delPerMobile}
                  onChange={(e) =>
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerMobile: e.target.value,
                    }))
                  }
                  style={{
                    backgroundColor: mode ? "#232529" : "#fff",
                    color: mode ? "#9F9F9F" : "#000",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "100%",
                    marginBottom: "10px",
                    border: mode ? "#232529" : "1px solid #D9D9D9",
                  }}
                />
              </div>
              <div className={styles.modal_div}>
                <input
                  name="name"
                  type="text"
                  placeholder="Tracking Number"
                  className={`${switchDisplay ? styles.inputcolor : styles.input
                    }`}
                  value={printLabel.delTrackingNo}
                  onChange={(e) =>
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delTrackingNo: e.target.value,
                    }))
                  }
                  style={{
                    backgroundColor: mode ? "#232529" : "#fff",
                    color: mode ? "#9F9F9F" : "#000",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "100%",
                    marginBottom: "10px",
                    border: mode ? "#232529" : "1px solid #D9D9D9",
                  }}
                />
                <input
                  name="name"
                  type="text"
                  placeholder="Tracking URL"
                  className={`${switchDisplay ? styles.inputcolor : styles.input
                    }`}
                  value={printLabel.delTrackingUrl}
                  onChange={(e) =>
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delTrackingUrl: e.target.value,
                    }))
                  }
                  style={{
                    backgroundColor: mode ? "#232529" : "#fff",
                    color: mode ? "#9F9F9F" : "#000",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "100%",
                    marginBottom: "10px",
                    border: mode ? "#232529" : "1px solid #D9D9D9",
                  }}
                />
              </div>
            </div>

            <div
              className={styles.modalBtn_div}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginLeft: "20px",
                marginTop: "20px",
              }}
            >
              <div>
                <Button
                  buttonIcon={<Cancel />}
                  buttonText={"Cancel"}
                  buttonTextColor={"white"}
                  buttonBgColor={"black"}
                  buttonClick={() => setShowLabelModal(false)}
                  style={{
                    width: "5px",
                    height: "50px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                />
              </div>

              <div>
                <Button
                  buttonIcon={<Label />}
                  buttonTextColor={"white"}
                  buttonBgColor={"#4164E3"}
                  buttonText={"PRINT"}
                  disabled={!printLabel.qtyPacks}
                  buttonClick={() => handlePrintLabel()}
                  style={{
                    width: "150px",
                    height: "50px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                />
              </div>
              <div style={{ display: "none" }}>
                <PrintLabel
                  data={data.order}
                  SubDistName={data.SubDistName}
                  districts={data.districts}
                  qtyPacks={printLabel.qtyPacks}
                  ref={labelRef}
                  handlePrint={handlePrint}
                />
              </div>
            </div>
          </div>
        </ModalWrapper>
      </div>
      <ModalWrapper
        isOpen={showApproveModal}
        onClose={() => setShowApproveModal(false)}
        closeBtn={true}
        style={{
          backgroundColor: mode ? "#2C2E33" : "white",
          borderRadius: "20px",
          width: "40vh",
          position: "relative",
        }}
        children={
          <div className={styles.text_center}>
            <button
              onClick={() => setShowApproveModal(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "30px",
                color: mode ? "white" : "#646B88",
                cursor: "pointer",
              }}
            >
              ×
            </button>

            <h1
              style={{
                fontSize: "16px",
                padding: "10px",
                marginTop: "10px",
                color: mode ? "white" : "#646B88",
              }}
            >
              {" "}
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "CANCEL ORDER REQUEST"
                  : "EDIT ORDER REQUEST")}
            </h1>
            <p
              style={{
                fontSize: "14px",
                padding: " 10px 20px 10px",
                fontWeight: "500",
                color: mode ? "white" : "black",
              }}
              className={styles.message}
            >
              Are you sure you want to Approve this{" "}
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "Cancel"
                  : "Edit")}{" "}
              Order Request?
            </p>
            <div
              style={{
                gap: "10px",
                display: "flex",
                justifyContent:"center",
                // marginLeft: "80px",
                // padding:"10px",
                // paddingLeft:"30px",
                // width: "90px",
                marginBottom: "20px",
              }}
            >
              <div style={{width: "90px",}}><Button
                buttonIcon={<Cancel />}
                buttonText={"NO"}
                buttonTextColor={"white"}
                buttonBgColor={"black"}
                buttonClick={() => setShowApproveModal(false)}
              /></div>
            <div style={{width: "90px",}}>  <Button
                loading={loading}
                buttonIcon={<TemplateDone />}
                buttonText={"YES"}
                buttonTextColor={"white"}
                buttonBgColor={"#4164E3"}
                buttonClick={() => {
                  if (
                    data.order &&
                    data.order.reqType === "Request For Cancel"
                  ) {
                    handleApproveCancel();
                  } else {
                    handleApproveEdit();
                  }
                }}
              /></div>
            </div>
          </div>
        }
      />
      <ModalWrapper
        isOpen={showDenyModal}
        onClose={() => setShowDenyModal(false)}
        closeBtn={true}
        style={{
          backgroundColor: mode ? "#2C2E33" : "white",
          width: "40vh",
          borderRadius: "20px",
          position: "relative",
        }}
        children={
          <div className={styles.text_center}>
            <span
              onClick={() => setShowDenyModal(false)}
              style={{
                position: "absolute",
                top: "18px",
                right: "20px",
                fontSize: "18px",
                color: mode ? "white" : "#646B88",
                cursor: "pointer",
              }}
            >
              &#10006;
            </span>

            <div
              style={{
                fontSize: "16px",
                
                padding: "10px",
                marginTop: "10px",
                fontWeight: "500",
                color: mode ? "white" : "#646B88",
              }}
            >
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "DENY CANCEL ORDER REQUEST"
                  : "DENY EDIT ORDER REQUEST")}
            </div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: mode ? "white" : "black",
                padding: " 10px 20px 10px",

              }}
              className={styles.message}
            >
              Are you sure you want to Deny this{" "}
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "Cancel"
                  : "Edit")}{" "}
              2 Order Request?
            </p>
            <div
              style={{
                gap: "10px",
                display: "flex",
                marginBottom: "20px",
                // marginLeft: "72px",

                // width: "90px",
                justifyContent:"center",

              }}
            >
           <div style={{  width: "90px",}}>   <Button
                buttonIcon={<Cancel />}
                buttonBgColor={"black"}
                buttonText={"NO"}
                buttonTextColor={"white"}
                buttonClick={() => setShowDenyModal(false)}
              /></div>
        <div style={{  width: "90px",}}>      <Button
                loading={loading}
                buttonIcon={<TemplateDone />}
                buttonBgColor={"#4164E3"}
                buttonText={"YES"}
                buttonTextColor={"white"}
                buttonClick={() => {
                  if (
                    data.order &&
                    data.order.reqType === "Request For Cancel"
                  ) {
                    handleDenyCancel();
                  } else {
                    handleDenyEdit();
                  }
                }}
              /></div>
            </div>
          </div>
        }
      />
      <ModalWrapper
        isOpen={showAcceptModal}
        onClose={() => setShowAcceptModal(false)}
        style={{
          backgroundColor: mode ? "#2C2E33" : "white",
          width: "38vh",
          padding: "15px",
          borderRadius: "20px",
          position: "relative",
        }}
        children={
          <div className={styles.text_center}>
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "20px",
                fontSize: "21px",
                cursor: "pointer",
                color: mode ? "white" : "#9C9797",
                fontWeight: "bold",
              }}
              onClick={() => setShowAcceptModal(false)}
            >
              &times;
            </div>
            <h1
              style={{
                fontWeight: "500",
                color: mode ? "white" : "#646B88",
                fontSize: "15px",
                padding: "5px",
              }}
            >
              {"DISPATCH ORDER STATUS"}
            </h1>
            <p
              style={{
                fontWeight: "500",
                color: mode ? "white" : "black",
                fontSize: "14px",
              }}
              className={styles.message}
            >
              Are you sure want to accept this order?
            </p>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "100px" }}>
                <Button
                  buttonIcon={<Cancel />}
                  buttonBgColor={"black"}
                  buttonText={"NO"}
                  buttonTextColor={"white"}
                  buttonClick={() => setShowAcceptModal(false)}
                />
              </div>
              <div style={{ width: "100px" }}>
                <Button
                  loading={isloading.acceptloading}

                  buttonIcon={<TemplateDone />}
                  buttonText={"YES"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"white"}
                  buttonClick={async () => {
                    await handleAcceptOrder();
                  }}
                />
              </div>
            </div>
          </div>
        }
      />
      <ModalWrapper
        isOpen={showCancelOrderModal}
        onClose={() => setShowCancelOrderModal(false)}
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          position: "relative",
          width: "45vh",
        }}
      >
        <button
          onClick={() => setShowCancelOrderModal(false)}
          style={{
            position: "absolute",
            top: "15px",
            right: "20px",
            fontSize: "21px",
            background: "transparent",
            border: "none",
            color: mode ? "#fff" : "#000",
            cursor: "pointer",
          }}
          aria-label="Close"
        >
          &times;
        </button>

        <div
          style={{
            backgroundColor: mode ? "#2C2E33" : "#FFFFFF",
            color: mode ? "#fff" : "#000",
            borderRadius: "10px",
            padding: "20px",
          }}
          className={styles.text_center}
        >
          <p
            style={{
              color: mode ? "#FFFFFF" : "#646B88",
              fontWeight: "500",
              fontSize: "18px",
            }}
          >
            CANCEL ORDER
          </p>
          <p className={styles.message}>
            Are You Sure Want To Cancel This Order?
          </p>
          <input type="text" aria-rowcount={2} placeholder="Enter 20 characters " className={styles.reqIssue} value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} style={{
            backgroundColor: mode ? "#232529" : "white",
            color: mode ? "#9F9F9F" : "black",
            borderRadius: "5px",
            width: "60%",
            border: "1px solid #D9D9D9",
            textAlign: "center",
            marginBottom: "20px",
          }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              marginTop: "8px",
              marginLeft: "5px",
            }}
          >
            <div style={{ width: "100px" }}>
              <Button
                buttonIcon={<CancelIcon2 />}
                buttonText={"NO"}
                buttonTextColor={"white"}
                buttonBgColor={"black"}
                buttonClick={() => setShowCancelOrderModal(false)}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Button
                loading={loading}
                buttonIcon={<TemplateDone />}
                buttonText={"Yes"}
                buttonTextColor={"white"}
                buttonBgColor={"#4164E3"}
                buttonClick={() => {
                  handleCancelOrder();
                }}
                style={{
                  backgroundColor: "blue",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>
        </div>
      </ModalWrapper>
      {loading ? (
        <div style={{ width: "100%", height: "100%", padding: "10px" }}>
          <div
            style={{
              padding: "10px",
              backgroundColor: mode ? "" : "#fff",
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "200px" }}>
                  <Skeleton lines={1} thickness={4} />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "200px", float: "right" }}>
                  <Skeleton lines={1} thickness={4} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "80%" }}>
                  <Skeleton lines={8} thickness={2} />
                </div>
              </div>
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "80%", float: "right" }}>
                  <Skeleton lines={8} thickness={2} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <div style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "100%" }}>
                  <Skeleton lines={8} thickness={3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.mainCon}>
          <div
            className={styles.voucher_maindiv}
            style={{
              backgroundColor: mode ? "#2C2E33" : "#F8F9FC",
              color: mode ? "#fff" : "#1B1D21",
              // border:"1px solid green"
              // border:"2px solid green"
            }}
          >
            <div
              className={styles.packingVoucherDiv}
              style={{
                backgroundColor: mode ? "#2C2E33" : "#F8F9FC",
              }}
            >
              <div
                className={styles1.dispatch_top_left}
                style={{
                  position: "relative",
                  paddingTop: "0",
                  width: "100%",
                  display: "flex",

                  justifyContent: "space-between",
                  backgroundColor: mode ? "#2C2E33" : "#F8F9FC",
                  padding: "8px",
                }}
              >
                {/* <div className={styles.mgx_top}>
                  <div className={styles1.mgx_top_left}>
                    <div style={{ paddingLeft: "5px" }}>
                      <TopTabBar text={"Packing Voucher"} />
                    </div>
                  </div>
                </div> */}
                <div style={{ padding: "10px" }} className={styles.order_top}>
                  <div className={styles.order_top_left}>
                    <TopTabBar text={"Packing Voucher"} />
                  </div>
                  <div style={{ display: "flex", paddingRight: '10px', gap: "5px" }}>
                    <TextElement
                      label={"Voucher No. & Voucher Date: "}
                      labelColor={mode ? "#fff" : "#646B88"}
                      value={(orderId ? orderId : "") + (orderId && data?.order?.orderDate ? " / " : "") + (data?.order?.orderDate ? data?.order?.orderDate : "")}
                      // value={orderId + " / " + data.orderDate }
                      // value={orderId ==="undefined"?"":orderId + " / " + data.orderDate ==="undefined"?"":data.orderDate}

                      valueColor={mode ? "#fff" : "#646B88"}
                      fontSize={"12px"}
                      type={"order"}
                    />

                    <TextElement
                      label={"Created By  "}
                      labelColor={mode ? "#fff" : "#646B88"}
                      // value={(data?.order?.packermanager ? data?.order?.packermanager : "") 
                      // + (orderId && data.order.orderDate ? " / " : "") + (data.order.orderDate ? data.order.orderDate: "")
                      // }
                      value={""}
                      // value={orderId ==="undefined"?"":orderId + " / " + data.orderDate ==="undefined"?"":data.orderDate}

                      valueColor={mode ? "#fff" : "#646B88"}
                      fontSize={"12px"}
                      type={"order"}
                    />
                    <TextElement
                      label={"Order Manager "}
                      labelColor={mode ? "#fff" : "#646B88"}
                      value={(data?.order?.ordermanager ? data?.order?.ordermanager : "")
                        // + (orderId && data.order.orderDate ? " / " : "") + (data.order.orderDate ? data.order.orderDate: "")
                      }
                      // value={orderId + " / " + data.orderDate }
                      // value={orderId ==="undefined"?"":orderId + " / " + data.orderDate ==="undefined"?"":data.orderDate}

                      valueColor={mode ? "#fff" : "#646B88"}
                      fontSize={"12px"}
                      type={"order"}
                    />
                    <TextElement
                      label={"status : "}
                      labelColor={mode ? "#fff" : "#646B88"}
                      value={(data?.order?.dispatchStatus ? data?.order?.dispatchStatus : "")
                      }

                      valueColor={
                        // data.order.dispatchStatus === "Packed"   ? "red" :
                        //  data.order.dispatchStatus === "Dispatched" ? "yellow" :"green"
                        data?.order?.dispatchStatus === "New Order" ? " #2196F3 " :

                          data?.order?.dispatchStatus === "Packed" ? " #9747FF" :
                            data?.order?.dispatchStatus === "Dispatched" ? " #00A052" :

                              data?.order?.dispatchStatus === "Not Packed" ? "red" :
                                data?.order?.dispatchStatus === "In Progress" ? " #DDE100" :
                                  data?.order?.dispatchStatus === "Pending" ? " #FF7F50 " : " red "
                      }
                      fontSize={"12px"}
                      type={"order"}

                    />
                  </div>
                </div>
                {/* <div
                  className={`${
                    data?.order?.dispatchStatus === "New Order"
                      ? styles.status_blue
                      : data?.order?.dispatchStatus === "Packed"
                      ? styles.statuscolor3
                      : data?.order?.dispatchStatus === "Dispatched"
                      ? styles.statuscolor4
                      : data?.order?.dispatchStatus === "Not Packed"
                      ? styles.statuscolor1
                      : data?.order?.dispatchStatus === "In Progress"
                      ? styles.statuscolor9
                      : data?.order?.dispatchStatus === "Cancelled"
                      ? styles.statuscolor8
                      : data?.order?.dispatchStatus === "Pending"
                      ? styles.statuscolor12
                      : ""
                  }`}
                  style={{
                    borderRadius: "5px",
                    boxShadow: mode ? "0px 0px 0px #000" : "1px 1px 3px #ccc",
                    padding: "5px 8px",
                    backgroundColor: mode ? "#1B1D21" : "white",
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    width: "20vh",
                    justifyContent: "center",
                    height: "5vh",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "2px solid green",
                    }}
                  >
                    <Statusicon status={data?.order?.dispatchStatus} />
                  </div>
                  {data?.order?.dispatchStatus}
                </div> */}
              </div>
            </div>

            <div
              style={{
                paddingTop: "0",
                paddingLeft: "10px",
                paddingRight: "10px",
                backgroundColor: mode ? "#2C2E33" : "#F8F9FC",
              }}
            >
              {" "}
              <div
                style={{
                  backgroundColor: mode ? "#232529" : "white",
                }}
                className={styles.voucher_flex}
              >
                <div className={styles.voucherFlexDirection}>
                  <h2
                    style={{
                      color: mode ? " white" : "black",
                    }}
                    className={styles.slipheading_div}
                  >
                    {data?.order && data?.order?.storeName}
                  </h2>
                  <span
                    style={{
                      color: mode ? " white" : "black",
                      fontWeight: "400",
                    }}
                    className={styles.sliptext1}
                  >
                    Ship To :{" "}
                    <b
                      style={{
                        color: mode ? " white" : "black",
                        fontWeight: "400",
                      }}
                      className={styles.sliptext}
                    >
                      {data?.order?.shippingInfo &&
                        data?.order?.shippingInfo?.name}
                    </b>
                  </span>
                  <span
                    style={{
                      color: mode ? " white" : "black",
                      fontWeight: "400",
                    }}
                    className={styles.sliptext1}
                  >
                    Address : &nbsp;
                    <b
                      style={{
                        color: mode ? " white" : "black",
                        fontWeight: "400",
                      }}
                      className={styles.sliptext}
                    >

                      {data?.order?.shippingInfo &&
                        data?.order?.shippingInfo.street +
                        ", " +
                        data?.order?.shippingInfo?.city +
                        ", " +
                        data?.order?.shippingInfo?.district +
                        ", " +
                        data?.order?.shippingInfo?.state +
                        ", " +
                        data?.order?.shippingInfo?.pincode}
                    </b>
                  </span>
                  <div style={{ display: "flex", gap: "3px" }}>    <span
                    style={{
                      color: mode ? " white" : "black",
                      fontWeight: "400",
                    }}
                    className={styles.sliptextGustin}
                  >
                    GSTUIN : &nbsp;
                    <b
                      style={{
                        fontWeight: "500",

                        color: mode ? " white" : "black",
                      }}
                    >
                      {data?.order && data?.order?.gstuin}
                    </b>
                  </span>
                    <span
                      style={{
                        color: mode ? " white" : "black",
                        fontWeight: "400",
                      }}
                      className={styles.sliptext1}
                    >
                      Contact : &nbsp;
                      {data?.order?.shippingInfo &&
                        data?.order?.shippingInfo?.mobile}
                    </span></div>
                  {/* <span
                    style={{
                      fontWeight: "400",
                      color: mode ? " white" : "black",
                    }} 
                    className={styles.sliptext1}
                  > 
                    Order Manager : &nbsp;
                    {data.order && data.order.ordermanager}
                  </span> */}
                  {/* <span
                    style={{
                      color: mode ? " white" : "black",

                      fontWeight: "400",
                    }}
                    className={styles.sliptext}
                  >
                    Packing Manager : &nbsp;
                    {data?.order && data?.order?.packermanager}
                  </span> */}
                </div>
                <div className={styles.packingVoucher_left}>
                  <div className={styles.voucher_flex1}>
                    
                  </div>
                  {/* <div className={styles.voucher_flex}>
                    <span
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                      }}
                      className={styles.slipDetailsTxt}
                    >
                      Voucher Date : {data?.order && data?.order?.orderDate}
                    </span>
                  </div> */}
                  <div style={{ paddingTop: "20px" }} className={styles.voucher_flex}>
                    <span
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                      }}
                      className={styles.slipDetailsTxt}
                    >
                      Delivery Type : {data?.order && data?.order?.shippingType}
                    </span>
                  </div>
                  <div className={styles.voucher_flex}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "5px",
                      }}
                    >
                      <span
                        style={{
                          color: mode ? "#FFFFFF" : "black",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.slipDetailsTxt}
                      >
                        Delivery Method :
                      </span>
                      {delivery.courierName && (
                        <div
                          style={{ color: mode ? "#FFFFFF" : "black", marginRight: "10px" }}
                          className={`${styles.slipDetailsDataSelect}`}
                        >
                          {delivery.courierName
                            ? delivery.courierName
                            : data?.order?.courierName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.voucher_flex1}>
                    {/* <span
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                      }}
                      className={styles.slipDetailsTxt}
                    >
                      Sub Method :{" "}
                      {delivery.partnerName
                        ? delivery.partnerName
                        : data?.order?.partnerName}
                    </span> */}
                    <span style={{
                      color: mode ? " white" : "black",
                      fontWeight: "400"
                    }} className={styles.sliptext}>
                      Packer:&nbsp;
                      {data.order && data.order.packermanager}
                    </span>
                    <div
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                      }}
                      className={`${styles.slipDetailsDataSelect1} py-2 px-1`}
                    ></div>
                  </div>
              
        
                </div>{" "}
              </div>
            </div>
            <div style={{ padding: "10px" }} className={styles.tablemain}>
              <ul className={styles.order_data_list}>
                <li
                  className={`${styles.order_data_list_row_header} ${mode ? styles.order_data_list_row_header_dark : ""
                    }`}
                >
                  {orderHeader.map((h) => (
                    <>
                      <div
                        style={{ marginLeft: "5px", paddingLeft: "5px" }}
                        className={styles.entry}
                        key={h}
                      >
                        <div>
                          <label className={styles.cl_checkbox}>{h}</label>
                        </div>
                      </div>
                    </>
                  ))}
                </li>
                <div className={styles.order_data_list_container}>
                  {loading ? (
                    <div style={{ margin: "1rem auto" }}>
                      <Spinner height={80} width={80} />
                    </div>
                  ) : data?.data?.length === 0 ? (
                    <img
                      src="/assets/updatedAssets/noDATA.png"
                      alt="img"
                      style={{
                        height: "12rem",
                        width: "16rem",
                        margin: "1rem auto",
                      }}
                    />
                  ) : (
                    <>
                      {filterItem &&
                        filterItem.map((d, index) => (
                          <li
                            key={index}
                            className={`${styles.order_data_list_row} ${mode ? styles.order_data_list_row_dark : ""
                              }`}
                          >
                            <div
                              style={{ marginLeft: "30px" }}
                              onClick={() => { }}
                              className={styles.entry}
                            >
                              {index + 1}
                            </div>
                            <div onClick={() => { }} className={styles.entry}>
                              {" "}
                              {d.itemNo}
                            </div>
                            <div onClick={() => { }} className={styles.entry}>
                              {d.product_name}
                            </div>
                            <div
                              style={{ paddingLeft: "20px" }}
                              onClick={() => { }}
                              className={styles.entry}
                            >
                              {d.warehouse}
                            </div>
                            <div
                              style={{ paddingLeft: "15px" }}
                              onClick={() => { }}
                              className={styles.entry}
                            >
                              {d.qty}
                            </div>
                            <div onClick={() => { }} className={styles.entry}>
                              {d.unit || "Pcs"}{" "}
                            </div>
                          </li>
                        ))}
                    </>
                  )}
                </div>
              </ul>
            </div>
            <div>
              <div className={styles.packingdispachstatusdiv}>
                {data?.order?.dispatchStatus === "New Order" && (
                  <>
                    <div className={styles.neworderbottom} style={{}}>
                      <div className={styles.packerdiv}>
                        <div className={styles.totalitemdiv}>
                          <div
                            style={{
                              color: mode ? "#9C9797" : "#646B88",
                              marginLeft: "10px",
                            }}
                          >
                            Total Items: {data.order.items.length}
                          </div>
                        </div>
                        <div
                          style={{
                            color: mode ? "#FBFBFB" : "#646B88",
                            marginLeft: "10px",
                          }}
                          className={styles.select_box}
                        >
                          Select Packer
                        </div>

                        <div className={styles.DropDown2div}>
                          <DropDown2
                            options={pakingManagers}
                            listLabel="Self"
                            onSelect={handleManagerSelect}
                            onReject={handleManagerReject}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className={styles.btn_div}>
                  <div
                    style={{
                      width: "100px",
                      position: "relative",

                    }}
                  >
                    <Button
                      buttonIcon={<Back />}
                      buttonBgColor={"black"}
                      buttonTextColor={"white"}
                      buttonText={"BACK"}
                      buttonClick={() => window.history.back()}
                    />
                  </div>

                  {data?.order?.reqStatus === "" &&
                    data?.order?.dispatchStatus !== "New Order" &&
                    data?.order?.dispatchStatus !== "Cancelled" && (
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <Button
                          buttonIcon={<Cancel />}
                          buttonBgColor={"black"}
                          buttonTextColor={"white"}
                          buttonText={"Cancel Order"}
                          buttonClick={() => {
                            setShowCancelOrderModal(true);
                          }}
                        />
                      </div>
                    )}

                  {data?.order?.dispatchStatus === "New Order" ? (
                    <div
                      style={{
                        width: "100px",
                        position: "relative",
                      }}
                    >
                      <Button
                        buttonIcon={<TemplateDone />}
                        buttonBgColor={"#4164E3"}
                        buttonTextColor={"white"}
                        buttonText={"Accept"}
                        buttonClick={() => setShowAcceptModal(true)}
                      />
                    </div>
                  ) : (data?.order?.pmId === parseInt(managerId) ||
                    designation === "superadmin") &&
                    data?.order?.packingStatus !== "Packed" &&
                    data?.order?.packingStatus !== "Dispatched" ? (
                    <>
                      {data?.order?.reqStatus === "Pending" ? (
                        <>
                          {data?.order?.reqType === "Request For Edit" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <Button
                                  buttonText={"APPROVE EDIT REQUEST"}
                                  buttonClick={() => {
                                    setShowApproveModal(true);
                                  }}
                                  css={styles.editRequestButton}
                                />

                                <Button
                                  buttonIcon={<Cancel />}
                                  buttonBgColor={"black"}
                                  buttonTextColor={"white"}
                                  buttonText={"Cancel"}
                                  buttonClick={() => {
                                    setShowCancelOrderModal(true);
                                  }}
                                />
                              </div>
                            )}
                          {data?.order?.reqType === "Request For Edit" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <RedOutButton
                                btnType="button"
                                title={"DENY EDIT REQUEST"}
                                handleSubmit={() => {
                                  setShowDenyModal(true);
                                }}
                                css="px-3 py-2"
                              />
                            )}

                          {data?.order?.reqType === "Request For Cancel" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <Button
                                btnType="button"
                                buttonBgColor={"black"}
                                buttonTextColor={"white"}
                                buttonIcon={<Cancel />}
                                buttonText={"APPROVE CANCEL REQUEST"}
                                buttonClick={() => {
                                  setShowApproveModal(true);
                                }}
                                css={styles.editRequestButton}
                              />
                            )}
                          {data?.order?.reqType === "Request For Cancel" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <Button
                                buttonIcon={<Canceldeny />}
                                buttonBgColor={"black"}
                                buttonTextColor={"white"}
                                buttonText={"DENY CANCEL REQUEST"}
                                buttonClick={() => {
                                  setShowDenyModal(true);
                                }}
                                css="px-3 py-2"
                              />
                            )}
                        </>
                      ) : (
                        <>
                          {data?.order?.dispatchStatus !== "Cancelled" && (
                            <>
                              <ReactToPrint
                                trigger={() => (
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <Button
                                      buttonBgColor={"black"}
                                      buttonTextColor={"white"}
                                      buttonIcon={<Pakingslip />}
                                      buttonText={"PACKING SLIP"}
                                      buttonClick={(e) => {
                                        setPrintLabel((prevState) => ({
                                          ...prevState,
                                          qtyPacks: e.target.value,
                                        }));
                                      }}
                                      title={
                                        <div className={styles.btn}>
                                          <Printbtn />
                                          &nbsp;&nbsp;PACKING SLIP
                                        </div>
                                      }
                                    />
                                  </div>
                                )}
                                content={() => componentRef}
                              />
                              <div style={{ display: "none" }}>
                                <PackingSlip
                                  orderId={orderId}
                                  managerId={managerId}
                                  delivery={delivery}
                                  setDelivery={setDelivery}
                                  ref={(el) => (componentRef = el)}
                                />
                              </div>
                              <div
                                style={{
                                  width: "80px",
                                  position: "relative",
                                }}
                              >
                                <Button
                                  buttonBgColor={"black"}
                                  buttonTextColor={"white"}
                                  buttonIcon={<Pack />}
                                  buttonText={"PACK"}
                                  buttonClick={() => {
                                    setShowPackedModal(true);
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {data?.order?.dispatchStatus === "Dispatched" ? (
                        <div

                        >
                          <Button
                            buttonBgColor={"#000"}
                            buttonIcon={<Viewdispatch />}
                            buttonText={"View Dispatch Details"}
                            buttonTextColor={"white"}
                            buttonClick={() =>
                              navigate("/viewdispatchdetails/" + orderId)
                            }
                          />
                        </div>
                      ) : data?.order?.reqStatus === "Pending" ? (
                        <>
                          {data?.order?.reqType === "Request For Edit" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <Button
                                  buttonText={"APPROVE EDIT REQUEST"}
                                  buttonClick={() => {
                                    setShowApproveModal(true);
                                  }}
                                  css={styles.editRequestButton}
                                />
                              </div>
                            )}
                          {data?.order?.reqType === "Request For Edit" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <Button
                                  buttonText={"DENY EDIT REQUEST"}
                                  buttonClick={() => {
                                    setShowDenyModal(true);
                                  }}
                                  css="px-3 py-2"
                                />
                              </div>
                            )}
                          {data?.order?.reqType === "Request For Cancel" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <Button
                                  buttonIcon={<Cancel />}
                                  buttonBgColor={"black"}
                                  buttonTextColor={"white"}
                                  buttonText={"APPROVE CANCEL REQUEST"}
                                  buttonClick={() => {
                                    setShowApproveModal(true);
                                  }}
                                  css={styles.editRequestButton}
                                />
                              </div>
                            )}
                          {data?.order?.reqType === "Request For Cancel" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <Button
                                  buttonIcon={<Canceldeny />}
                                  buttonBgColor={"#4164E3"}
                                  buttonTextColor={"white"}
                                  buttonText={"DENY CANCEL REQUEST"}
                                  buttonClick={() => {
                                    setShowDenyModal(true);
                                  }}
                                  css="px-3 py-2"
                                />
                              </div>
                            )}
                        </>
                      ) : (
                        <>
                          {data.order?.dispatchStatus !== "Cancelled" && (
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <Button
                                buttonIcon={<Canceldeny />}
                                buttonText={"DELIVERY DETAILS"}
                                buttonBgColor={"black"}
                                buttonTextColor={"white"}
                                disabled={
                                  data.order?.dispatchStatus === "Cancelled"
                                    ? true
                                    : false
                                }
                                buttonClick={() => {
                                  setPrintLabel((prevState) => ({
                                    ...prevState,
                                    delPerName:
                                      partners &&
                                      partners.find(
                                        (x) =>
                                          x.partnerName === delivery.partnerName
                                      )?.partnerName,
                                    delPerMobile:
                                      partners &&
                                      partners.find(
                                        (x) =>
                                          x.partnerName === delivery.partnerName
                                      )?.contactNumber,
                                    delTrackingNo:
                                      partners &&
                                      partners.find(
                                        (x) =>
                                          x.partnerName === delivery.partnerName
                                      )?.trackingNumber,
                                    delTrackingUrl:
                                      partners &&
                                      partners.find(
                                        (x) =>
                                          x.partnerName === delivery.partnerName
                                      )?.trackingUrl,
                                  }));
                                  setShowDeliveryModal(true);
                                  // generatePaymentLink();
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {data.order?.reqStatus === "Pending" ? (
                        ""
                      ) : (


                        <ReactToPrint
                          trigger={() => (
                            <div
                              style={{
                                position: "relative",
                              }}
                            >

                              <Button
                                buttonTextColor={"white"}
                                buttonBgColor={"#4164E3"}
                                disabled={
                                  data.order?.dispatchStatus === "Cancelled" ||
                                    loading
                                    ? true
                                    : false
                                }
                                buttonText={
                                  <>
                                    {lableBtnLoading ? (
                                      <Spinner size="15" />
                                    ) : (
                                      <div>
                                        <Label />
                                        &nbsp;&nbsp;LABEL
                                      </div>
                                    )}
                                  </>
                                }
                                buttonClick={() => {
                                  setShowLabelModal(true);
                                }}
                              />
                            </div>
                          )}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default PackingVoucher;