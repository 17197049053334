import React, { useEffect, useRef, useState } from "react";
import styles from "./Camp.module.css";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import { useAuth } from "../../components/context/Auth";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";
import Button from "../../components/updatedComponents/Dealers/Button";
import {
  AddBtnicon,
  TemplateDone,
  TemplateNotFound,
} from "../../components/icons/Icon2";
import DrawerWrapper from "../../components/updatedComponents/layoutWrapper/DrawerWrapper";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import { Back, Pluse } from "../../components/icons/Icon3";
import { LocationsFilter } from "../../components/updatedComponents/Filter/LocationsFilter";
import TimeOutFetch from "../../APIsControll/TimeOutFechControll";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";
import NoDataFound from "../../components/updatedComponents/NoDataFound/NoDataFound";
import { TextColorCode, truncateText } from "../../Utils/TextUtils";
import { useParams } from "react-router-dom";
import SrarchPin from "../../components/updatedComponents/FormComponents/SrarchPin";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
const DealerList = () => {
  const { mode, managerId } = useAuth();
  const [selectAll, setSelectAll] = useState(false);
  const [selectAll1, setSelectAll1] = useState(false);
  const { show } = useToast();
  const { tempname } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [toggelList, setToggelList] = useState("Added");
  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);
  const [groupDealer, setGroupDealer] = useState([]);
  const [groupDealer1, setGroupDealer1] = useState([]);
  const [sarchLocation, setSarchLocation] = useState({
    stateSearchLocation: "",
    DistricsSearchLocation: "",
    CitySearchLocation: "",
    pincodeSarch: "",
  });

  const [locations0, setlocations0] = useState([]);
  const [sarchValue, setSarchValue] = useState("");
  const [filteredLocations, setFilteredLocations] = useState(locations0);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [locations1, setlocations1] = useState([]);
  const [sarchValue1, setSarchValue1] = useState("");
  const [filteredLocations1, setFilteredLocations1] = useState(locations1);
  const [selectedLocations1, setSelectedLocations1] = useState([]);

  const [locations2, setlocations2] = useState([]);
  const [sarchValue2, setSarchValue2] = useState("");
  const [filteredLocations2, setFilteredLocations2] = useState(locations2);
  const [selectedLocations2, setSelectedLocations2] = useState([]);

  const [loading, setloading] = useState({
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    loading5: false,
  });

  const [inputBoxSarch, setInputBoxSarch] = useState({
    listSarch: "",
    addedSarch: "",
  });

  const [addedData, setAddedData] = useState([]);
  const [addedPage, setAddedPage] = useState(1);
  const [addedPages, setAddedPages] = useState(1);
  const [Descpction, setDescpction] = useState("");

  const [listData, setlistData] = useState([]);
  const [listPage, setlistPage] = useState(1);
  const [listPages, setlistPages] = useState(1);
  const [showMenu, setShowMenu] = useState(false);
  const [showMenu1, setShowMenu1] = useState(false);
  const [showMenu2, setShowMenu2] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  const itemList = ["Added", "List"];

  const getLoactionFilter = async () => {
    const payload = {
      managerId: managerId,
      stateSearch: "",
      districtSearch: "",
      citySearch: "",
      pincodeSearch: sarchLocation.pincodeSarch,
      locations: {
        states: [],
        districts: [],
        cities: [],
        pincodes: [],
      },
    };
    try {
      const res = await TimeOutFetch(
        `wallikonCampaign/filterLocation`,
        payload
      );
      // setlocations0(prev => [...prev, ...res?.data?.states]);
      setlocations0(res?.data?.states);
      setlocations1(res?.data?.districts);
      setlocations2(res?.data?.cities);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    setFilteredLocations(locations0);
  }, [locations0]);

  useEffect(() => {
    setFilteredLocations1(locations1);
  }, [locations1]);

  useEffect(() => {
    setFilteredLocations2(locations2);
  }, [locations2]);

  const getDealser = async () => {
    try {
      setloading((prve) => ({
        ...prve,
        loading2: true,
      }));
      const payload = {
        managerId: managerId,
        keyword: inputBoxSarch.listSarch,
        groupName: tempname,
        status: "",
        locations: {
          states: selectedLocations.length > 0 ? selectedLocations : [],
          districts: selectedLocations1.length > 0 ? selectedLocations1 : [],
          cities: selectedLocations2.length > 0 ? selectedLocations2 : [],
          pincodes:
            sarchLocation.pincodeSarch === ""
              ? []
              : [sarchLocation.pincodeSarch],
        },
      };
      const res = await TimeOutFetch(
        `wallikonCampaign/filteredGroupDealers?page=${listPage}&limit=${12}`,
        payload
      );
      setloading((prve) => ({
        ...prve,
        loading2: false,
      }));

      if (res.status === "success") {
        // const filterData = res.data.filter(data => data.dealerId !== addedData[0]?.dealerInfo?.dealerId)
        setlistData(res?.data);
        // setlistData(filterData);
        setlistPage(res?.page);
        setlistPages(res?.pages);
        setloading((prve) => ({
          ...prve,
          loading2: false,
        }));
        return;
      }
      if (res.status === "failed") {
        setlistData(res?.data);
        setloading((prve) => ({
          ...prve,
          loading2: false,
        }));
        return;
      } else {
        setloading((prve) => ({
          ...prve,
          loading2: false,
        }));
        return show("Something went wrong", "error");
      }
    } catch (error) {
      //console.log(error);
      setloading((prve) => ({
        ...prve,
        loading2: false,
      }));
    }
  };

  useEffect(() => {
    getLoactionFilter();
  }, []);

  const getSingleGroup = async () => {
    try {
      setloading((prve) => ({
        ...prve,
        loading3: true,
      }));
      const payload = {
        managerId: managerId,
        groupName: tempname,
        keyword: inputBoxSarch.addedSarch,
        status: "",
        locations: {
          states: selectedLocations.length > 0 ? selectedLocations : [],
          districts: selectedLocations1.length > 0 ? selectedLocations1 : [],
          cities: selectedLocations2.length > 0 ? selectedLocations2 : [],
          pincodes:
            sarchLocation.pincodeSarch === ""
              ? []
              : [sarchLocation.pincodeSarch],
        },
      };
      const res = await TimeOutFetch(
        `wallikonCampaign/getSingleGroup`,
        payload
      );
      setloading((prve) => ({
        ...prve,
        loading3: false,
      }));
      if (res.status === "success") {
        setAddedData(res?.data);
        setAddedPage(res?.page);
        setAddedPages(res?.pages);
        setloading((prve) => ({
          ...prve,
          loading3: false,
        }));
        return;
      }
      if (res.status === "failed") {
        setAddedData(res?.data);
        setloading((prve) => ({
          ...prve,
          loading3: false,
        }));
        return;
      } else {
        setloading((prve) => ({
          ...prve,
          loading3: false,
        }));
        return show("Something went wrong", "error");
      }
    } catch (error) {
      return show("Something went wrong", "error");
    }
  };

  useEffect(() => {
    getSingleGroup();
  }, [addedPage, inputBoxSarch.addedSarch]);

  useEffect(() => {
    if (sarchLocation.pincodeSarch.length === 6) {
      getDealser();
    }
  }, [sarchLocation.pincodeSarch]);

  useEffect(() => {
    getDealser();
    getSingleGroup();
  }, [
    listPage,
    inputBoxSarch.listSarch,
    selectedLocations2,
    selectedLocations1,
    selectedLocations,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef1.current && !boxRef1.current.contains(event.target)) {
        setShowMenu(false);
      }
      if (boxRef2.current && !boxRef2.current.contains(event.target)) {
        setShowMenu1(false);
      }
      if (boxRef3.current && !boxRef3.current.contains(event.target)) {
        setShowMenu2(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSearch = (value) => {
    setSarchValue(value);
    setFilteredLocations(
      locations0.filter((loc) =>
        loc.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleSearch1 = (value) => {
    setSarchValue1(value);
    setFilteredLocations1(
      locations1.filter((loc) =>
        loc.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleSearch2 = (value) => {
    setSarchValue2(value);
    setFilteredLocations2(
      locations2.filter((loc) =>
        loc.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const CreateGroup = async (e) => {
    e.preventDefault();
    try {
      if (groupDealer.length <= 0)
        return show("Please select dealers", "error");
      const payload = {
        managerId: managerId,
        groupName: tempname,
        description: Descpction === "" ? null : Descpction,
        dealerInfo: groupDealer,
      };

      setloading((prve) => ({
        ...prve,
        loading4: true,
      }));
      const res = await TimeOutFetch(`wallikonCampaign/createGroup`, payload);
      setloading((prve) => ({
        ...prve,
        loading4: false,
      }));
      if (res.status === "success") {
        setSelectedLocations2([]);
        setSelectedLocations1([]);
        setSelectedLocations1([]);
        setGroupDealer1([]);
        setGroupDealer([]);
        setSelectAll(false);
        setToggelList("Added");
        closeDrawer();
        getDealser();
        getSingleGroup();

        show(`${res.msg}`, "success");
        return;
      }
      if (res.status === "failed") {
        return show(`${res.msg}`, "error");
      } else {
        return show("Something went wrong", "error");
      }
    } catch (error) {
      setloading((prve) => ({
        ...prve,
        loading4: false,
      }));
      return show("Something went wrong", "error");
    }
  };

  const RemoveDelers = async (e) => {
    e.preventDefault();
    try {
      if (groupDealer1.length <= 0)
        return show("Please select dealers", "error");
      const dealerInfo = groupDealer1.map((dealer) => dealer.dealerId);
      const payload = {
        managerId: managerId,
        groupName: tempname,
        dealerIds: dealerInfo,
      };

      setloading((prve) => ({
        ...prve,
        loading5: true,
      }));
      const res = await TimeOutFetch(
        `wallikonCampaign/removeDealersInBulk`,
        payload
      );
      setloading((prve) => ({
        ...prve,
        loading5: false,
      }));
      if (res.status === "success") {
        setSelectedLocations1([]);
        setSelectedLocations2([]);
        setSelectedLocations([]);
        setSelectAll1(false);
        setGroupDealer1([]);
        setGroupDealer([]);
        getSingleGroup();
        getDealser();
        show(`${res.msg}`, "success");
        return;
      }
      if (res.status === "failed") {
        return show(`${res.msg}`, "error");
      } else {
        return show("Something went wrong", "error");
      }
    } catch (error) {
      setloading((prve) => ({
        ...prve,
        loading5: false,
      }));
      return show("Something went wrong", "error");
    }
  };

  const handleIndividualCheckbox = (event, dealer) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setGroupDealer((prev) => [...prev, dealer]);
    } else {
      setGroupDealer((prev) =>
        prev.filter((d) => d?.dealerId !== dealer?.dealerId)
      );
    }
    if (!isChecked) {
      setSelectAll(false);
    } else if (
      listData.every(
        (d) =>
          listData.some((g) => g.dealerId === d.dealerId) ||
          d.dealerId === dealer.dealerId
      )
    ) {
      setSelectAll(false);
    }
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setGroupDealer(
        listData.map((dealer) => {
          const { _id, updatedAt, createdAt, ...cleanedItem } = dealer;
          return cleanedItem;
        })
      );
    } else {
      setGroupDealer([]);
    }
  };

  const handleIndividualCheckbox1 = (event, dealer) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setGroupDealer1((prev) => [...prev, dealer]);
    } else {
      setGroupDealer1((prev) =>
        prev.filter((d) => d?.dealerId !== dealer?.dealerId)
      );
    }
    if (!isChecked) {
      setSelectAll1(false);
    } else if (
      addedData[0]?.dealerInfo.every(
        (d) =>
          addedData[0]?.dealerInfo.some((g) => g.dealerId === d.dealerId) ||
          d.dealerId === dealer.dealerId
      )
    ) {
      setSelectAll1(false);
    }
  };

  const handleSelectAll1 = (event) => {
    const isChecked = event.target.checked;
    setSelectAll1(isChecked);
    if (isChecked) {
      setGroupDealer1(
        addedData[0]?.dealerInfo.map((dealer) => {
          const { _id, updatedAt, createdAt, ...cleanedItem } = dealer;
          return cleanedItem;
        })
      );
    } else {
      setGroupDealer1([]);
    }
  };

  return (
    <div className={styles.mainCintner}>
      <div
        className={styles.Continer}
        style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}
      >
        <DrawerWrapper isOpen={isOpen} onClose={closeDrawer}>
          <div
            className={
              mode
                ? styles.DrawerWrapperContinetr
                : styles.DrawerWrapperContinetr_light
            }
          >
            <div className={styles.DrawerWrapperText}>
              <span>Selected: {groupDealer.length}</span>
            </div>
            <div className={styles.DrawerWrapperMainCointner}>
              <div className={styles.DrawerWrapperInputConintner1}>
                {/* <div style={{ width: "40%" }} >
                                    <InputTag
                                        placeholderText={"New Group..."}
                                        labelText={"Group Name"}
                                        placeholderType={"text"}
                                    />
                                </div> */}
                <div style={{ width: "40%" }}>
                  <InputTag
                    placeholderText={"Lucknow Trader..."}
                    labelText={"Description (Optional)"}
                    placeholderType={"text"}
                    onChange={(e) => setDescpction(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.DrawerWrapperInputConintner}>
                <div style={{ width: "20%" }}>
                  <Button
                    buttonBgColor={"#000000"}
                    buttonIcon={<Back />}
                    buttonTextColor={"#fff"}
                    buttonText={"Back"}
                    buttonClick={closeDrawer}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <Button
                    buttonBgColor={"#4164E3"}
                    buttonIcon={<TemplateDone />}
                    buttonTextColor={"#fff"}
                    buttonText={"Add"}
                    buttonClick={CreateGroup}
                    loading={loading.loading4}
                  />
                </div>
              </div>
            </div>
          </div>
        </DrawerWrapper>

        <div className={styles.goBackCon}>
          <div className={styles.gobacFristChlid1}>
            <div>
              <TopTabBar text={`One Group`} />
            </div>
            <div style={{ width: "160px" }}>
              {toggelList === "List" ? (
                <Button
                  buttonBgColor={"#4164E3"}
                  buttonText={"Add Member"}
                  buttonIcon={<Pluse />}
                  buttonTextColor={"#fff"}
                  buttonClick={toggleDrawer}
                />
              ) : (
                <Button
                  buttonBgColor={"#4164E3"}
                  buttonText={"Remove"}
                  // buttonIcon={<CreateTem />}
                  buttonIcon={<Pluse />}
                  buttonTextColor={"#fff"}
                  buttonClick={RemoveDelers}
                  loading={loading.loading5}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.sarchBarConinnter}>
          <div className={styles.sarchBarConinnterFristChild}>
            <div className={styles.sarchconGrop}>
              <div className={styles.sacrchGroup}>
                {toggelList === "List" && (
                  <SearchBar
                    placeholder={"Search"}
                    onChange={(event) =>
                      setInputBoxSarch((parv) => ({
                        ...parv,
                        listSarch: event.target.value,
                      }))
                    }
                  />
                )}
                {toggelList === "Added" && (
                  <SearchBar
                    placeholder={"Search"}
                    onChange={(event) =>
                      setInputBoxSarch((parv) => ({
                        ...parv,
                        addedSarch: event.target.value,
                      }))
                    }
                  />
                )}
              </div>
              <div style={{ marginLeft: "20px" }}>
                <SlidingFilter2
                  selectedItem={toggelList}
                  itemList={itemList}
                  setFilter={setToggelList}
                />
              </div>
              <div
                className={styles.iconWrapper0}
                style={{ color: mode ? "white" : "black" }}
                ref={boxRef1}
              >
                <div>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: mode ? "#fff" : "#000",
                    }}
                  >
                    {" "}
                    State{" "}
                  </span>
                </div>
                <div
                  className={styles.Who_Select3}
                  style={{
                    background: mode ? "#1B1D21" : "#fff",
                    border: mode ? "none" : "1px solid #E4E7EB",
                    position: "relative",
                  }}
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <div>
                    {" "}
                    <span style={{ fontSize: "12px" }}> Haryana </span>{" "}
                  </div>
                  <div>
                    {" "}
                    <span>
                      {" "}
                      <AddBtnicon
                        mode={mode}
                        rotate={showMenu ? 180 : 0}
                      />{" "}
                    </span>{" "}
                  </div>
                </div>
                {showMenu && (
                  <div
                    className={styles.NewLocatiionFilter}
                    style={{
                      top: "100%",
                      left: "0%",
                      zIndex: "999",
                      backgroundColor: mode ? "#000" : "#fff",
                      color: mode ? "#fff" : "#000",
                    }}
                  >
                    <LocationsFilter
                      locations={filteredLocations}
                      sarchValue={sarchValue}
                      sareshOnchange={handleSearch}
                      selectedLocations={selectedLocations}
                      setSelectedLocations={setSelectedLocations}
                    />
                  </div>
                )}
              </div>
              <div
                className={styles.iconWrapper0}
                style={{ color: mode ? "white" : "black" }}
                ref={boxRef2}
              >
                <div>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: mode ? "#fff" : "#000",
                    }}
                  >
                    {" "}
                    District{" "}
                  </span>
                </div>
                <div
                  className={styles.Who_Select3}
                  style={{
                    background: mode ? "#1B1D21" : "#fff",
                    border: mode ? "none" : "1px solid #E4E7EB",
                    position: "relative",
                  }}
                  onClick={() => setShowMenu1(!showMenu1)}
                >
                  <div>
                    {" "}
                    <span style={{ fontSize: "12px" }}> Kheri </span>{" "}
                  </div>
                  <div>
                    {" "}
                    <span>
                      {" "}
                      <AddBtnicon
                        mode={mode}
                        rotate={showMenu1 ? 180 : 0}
                      />{" "}
                    </span>{" "}
                  </div>
                </div>
                {showMenu1 && (
                  <div
                    className={styles.NewLocatiionFilter}
                    style={{
                      top: "100%",
                      left: "0%",
                      zIndex: "999",
                      backgroundColor: mode ? "#000" : "#fff",
                      color: mode ? "#fff" : "#000",
                    }}
                  >
                    <LocationsFilter
                      locations={filteredLocations1}
                      sarchValue={sarchValue1}
                      sareshOnchange={handleSearch1}
                      selectedLocations={selectedLocations1}
                      setSelectedLocations={setSelectedLocations1}
                    />
                  </div>
                )}
              </div>
              <div
                className={styles.iconWrapper0}
                style={{ color: mode ? "white" : "black" }}
                ref={boxRef3}
              >
                <div>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: mode ? "#fff" : "#000",
                    }}
                  >
                    {" "}
                    City{" "}
                  </span>
                </div>
                <div
                  className={styles.Who_Select3}
                  style={{
                    background: mode ? "#1B1D21" : "#fff",
                    border: mode ? "none" : "1px solid #E4E7EB",
                    position: "relative",
                  }}
                  onClick={() => setShowMenu2(!showMenu2)}
                >
                  <div>
                    {" "}
                    <span style={{ fontSize: "12px" }}> Lucknow </span>{" "}
                  </div>
                  <div>
                    {" "}
                    <span>
                      {" "}
                      <AddBtnicon
                        mode={mode}
                        rotate={showMenu2 ? 180 : 0}
                      />{" "}
                    </span>{" "}
                  </div>
                </div>
                {showMenu2 && (
                  <div
                    className={styles.NewLocatiionFilter}
                    style={{
                      top: "100%",
                      left: "0%",
                      zIndex: "999",
                      backgroundColor: mode ? "#000" : "#fff",
                      color: mode ? "#fff" : "#000",
                    }}
                  >
                    <LocationsFilter
                      locations={filteredLocations2}
                      sarchValue={sarchValue2}
                      sareshOnchange={handleSearch2}
                      selectedLocations={selectedLocations2}
                      setSelectedLocations={setSelectedLocations2}
                    />
                  </div>
                )}
              </div>
              <div
                className={styles.cont}
                style={{ color: mode ? "white" : "black" }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: mode ? "#fff" : "#000",
                    }}
                  >
                    {" "}
                    Pin{" "}
                  </span>
                </div>
                <div style={{ fontSize: "12px", fontWeight: "400" }}>
                  <SrarchPin
                    placeholder={"Sarch"}
                    type={"number"}
                    maxLength={6}
                    onChange={(event) =>
                      setSarchLocation((prv) => ({
                        ...prv,
                        pincodeSarch: event,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {toggelList === "List" && (
          <div className={styles.tableContiner}>
            <div className={styles.container} style={{ height: "100%" }}>
              <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                <li
                  className={styles.tableHeader}
                  style={
                    mode
                      ? { backgroundColor: "#232529", color: "#fafafa" }
                      : { backgroundColor: "#D9DCE5" }
                  }
                >
                  <div className={`${styles.col} `} style={{ width: "800px" }}>
                    <div className={styles.TableHeadChackBox}>
                      <label className={styles.cl_checkbox}>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                        <span></span>
                      </label>
                    </div>
                    Dealer Name{" "}
                  </div>
                  <div className={`${styles.col} ${styles.large}`}>
                    {" "}
                    <span>Address </span>{" "}
                  </div>
                  <div className={styles.col}>State</div>
                  <div className={styles.col}>District</div>
                  <div className={styles.col}>City</div>
                  <div className={styles.col}>PIN Code</div>
                  <div className={styles.col}>Status</div>
                </li>
                <div style={{ overflowY: "scroll", height: "100%" }}>
                  {loading?.loading2 ? (
                    <Skeleton
                      lines={8}
                      width="100%"
                      thickness={8}
                      height="400px"
                    />
                  ) : (
                    <>
                      {" "}
                      {listData?.length === 0 ? (
                        <div style={{ height: "90%" }}>
                          <NoDataFound
                            icon={<TemplateNotFound />}
                            linkname={"add a template."}
                            textTitle={`No Dealers found. To run a WhatsApp campaign`}
                            to={"/newgroup"}
                          />
                        </div>
                      ) : (
                        <>
                          {listData.map((e, i) => (
                            <li
                              className={styles.tablerow}
                              style={
                                mode
                                  ? {
                                      backgroundColor: "#1B1D21",
                                      color: "#fafafa",
                                    }
                                  : { backgroundColor: "#ffff" }
                              }
                              key={i}
                            >
                              <div className={`${styles.col} `}>
                                <div className={styles.TableHeadChackBox}>
                                  <label className={styles.cl_checkbox}>
                                    <input
                                      type="checkbox"
                                      onChange={(event) =>
                                        handleIndividualCheckbox(event, e)
                                      }
                                      checked={groupDealer.some(
                                        (dealer) =>
                                          dealer?.dealerId === e?.dealerId
                                      )}
                                    />
                                    <span></span>
                                  </label>
                                </div>
                                {truncateText(`${e?.dealerName}`, 20)}
                              </div>
                              <div className={`${styles.col} ${styles.large}`}>
                                {" "}
                                {truncateText(`${e?.address}`, 27)}{" "}
                              </div>
                              <div className={styles.col}>
                                {" "}
                                {truncateText(`${e?.state}`, 20)}
                              </div>
                              <div className={styles.col}>
                                {truncateText(`${e?.district}`, 20)}
                              </div>
                              <div className={styles.col}>
                                {truncateText(`${e?.city}`, 20)}
                              </div>
                              <div className={styles.col}>
                                {truncateText(`${e?.pincode}`, 20)}
                              </div>
                              <div
                                className={styles.col}
                                style={{ color: TextColorCode(e?.status) }}
                              >
                                {" "}
                                {truncateText(`${e?.status}`, 20)}{" "}
                              </div>
                            </li>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </div>
                <li style={{ height: "20px" }}></li>
              </ul>
            </div>
          </div>
        )}

        {toggelList === "List" && (
          <div
            className={styles.pagnationConintner}
            style={{ backgroundColor: mode ? "#232529" : "#fff" }}
          >
            <div className={styles.gotToPage1}>
              <GoToPage
                currentPage={listPage}
                setCurrentPage={setlistPage}
                totalPages={listPages}
                key={"1"}
              />
            </div>
            <div className={styles.gotToPage2}>
              <Pagenation
                currentPage={listPage}
                setCurrentPage={setlistPage}
                totalPages={listPages}
                key={"1"}
              />
            </div>
          </div>
        )}
        {toggelList === "Added" && (
          <div className={styles.tableContiner}>
            <div className={styles.container} style={{ height: "100%" }}>
              <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                <li
                  className={styles.tableHeader}
                  style={
                    mode
                      ? { backgroundColor: "#232529", color: "#fafafa" }
                      : { backgroundColor: "#D9DCE5" }
                  }
                >
                  <div className={`${styles.col} `} style={{ width: "800px" }}>
                    <div className={styles.TableHeadChackBox}>
                      <label className={styles.cl_checkbox}>
                        <input
                          type="checkbox"
                          checked={selectAll1}
                          onChange={handleSelectAll1}
                        />
                        <span></span>
                      </label>
                    </div>
                    Dealer Name{" "}
                  </div>
                  <div className={`${styles.col} ${styles.large}`}>
                    {" "}
                    <span>Address </span>{" "}
                  </div>
                  <div className={styles.col}>State</div>
                  <div className={styles.col}>District</div>
                  <div className={styles.col}>City</div>
                  <div className={styles.col}>PIN Code</div>
                  <div className={styles.col}>Status</div>
                </li>
                <div style={{ overflowY: "scroll", height: "100%" }}>
                  {addedData?.length === 0 ? (
                    <>
                      {" "}
                      {loading?.loading1 ? (
                        <Skeleton
                          lines={8}
                          width="100%"
                          thickness={8}
                          height="400px"
                        />
                      ) : (
                        <>
                          <div style={{ height: "90%" }}>
                            <NoDataFound
                              icon={<TemplateNotFound />}
                              linkname={"add a template."}
                              textTitle={`No Dealers found. To run a WhatsApp campaign`}
                              to={"/newgroup"}
                            />{" "}
                          </div>
                        </>
                      )}{" "}
                    </>
                  ) : (
                    addedData[0]?.dealerInfo?.map((e, i) => (
                      <li
                        className={styles.tablerow}
                        style={
                          mode
                            ? { backgroundColor: "#1B1D21", color: "#fafafa" }
                            : { backgroundColor: "#ffff" }
                        }
                        key={i}
                      >
                        <div className={`${styles.col} `}>
                          <div className={styles.TableHeadChackBox}>
                            <label className={styles.cl_checkbox}>
                              <input
                                type="checkbox"
                                onChange={(event) =>
                                  handleIndividualCheckbox1(event, e)
                                }
                                checked={groupDealer1.some(
                                  (dealer) => dealer?.dealerId === e?.dealerId
                                )}
                              />
                              <span></span>
                            </label>
                          </div>
                          {truncateText(`${e?.dealerName}`, 20)}
                        </div>
                        <div className={`${styles.col} ${styles.large}`}>
                          {" "}
                          {truncateText(`${e?.address}`, 27)}{" "}
                        </div>
                        <div className={styles.col}>
                          {" "}
                          {truncateText(`${e?.state}`, 20)}{" "}
                        </div>
                        <div className={styles.col}>
                          {" "}
                          {truncateText(`${e?.district}`, 20)}{" "}
                        </div>
                        <div className={styles.col}>
                          {" "}
                          {truncateText(`${e?.city}`, 20)}{" "}
                        </div>
                        <div className={styles.col}>
                          {" "}
                          {truncateText(`${e?.pincode}`, 20)}{" "}
                        </div>
                        <div
                          className={styles.col}
                          style={{ color: TextColorCode(e?.status) }}
                        >
                          {" "}
                          {truncateText(`${e?.status}`, 20)}{" "}
                        </div>
                      </li>
                    ))
                  )}
                </div>
                <li style={{ height: "20px" }}></li>
              </ul>
            </div>
          </div>
        )}

        {toggelList === "Added" && (
          <div
            className={styles.pagnationConintner}
            style={{ backgroundColor: mode ? "#232529" : "#fff" }}
          >
            <div className={styles.gotToPage1}>
              <GoToPage
                currentPage={addedPage}
                setCurrentPage={setAddedPage}
                totalPages={addedPages}
                key={"1"}
              />
            </div>
            <div className={styles.gotToPage2}>
              <Pagenation
                currentPage={addedPage}
                setCurrentPage={setAddedPage}
                totalPages={addedPages}
                key={"1"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DealerList;
