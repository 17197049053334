import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GreenOutButton } from "../../components/Buttons";
import { useAuth } from "../../components/context/Auth";
import Pagination from "../../components/Paginantion";
import styles from "./DishpatchManager.module.css";
const Packer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const pmId = params.pmId;
  const { switchDisplay, managerId } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState({
    totalPages: "",
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;

  const handlePagination = (current) => {
    setState({ ...state, currentPage: current });
  };

  useEffect(() => {
    if (managerId) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        pmId: pmId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "dispatch/packer?page=" + currentPage,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setLoading(false);
            setData(result.packer);
          }
          if (result.pages) {
            setState((prevState) => ({
              ...prevState,
              totalPages: result.pages ? result.pages : "",
              currentPage: result.page,
            }));
          }
        })
        .catch((error) => {})
    }
  }, [managerId, currentPage, pmId]);

  return (
    <div
      className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${
        styles.ff
      }`}
    >
      <div className={styles.flexdiv}>
        <h1 className={styles.heading}>Packer</h1>
        <div className={styles.flexdiv}>
          <div className={styles.Sort_By}>
            <label className={styles.sortBy_txt}>Sort By </label>
            <select className={styles.sortBy_select}>
              <option className={styles.sortBy_color} value="">
                View All
              </option>
              <option className={styles.sortBy_color} value="New">
                New
              </option>
              <option className={styles.sortBy_color} value="newestfirst">
                Newest First
              </option>
              <option className={styles.sortBy_color} value="Pending">
                Not Verified
              </option>
              <option className={styles.sortBy_color} value="Verified">
                Verified
              </option>
              <option className={styles.sortBy_color} value="Ascending">
                Ascending
              </option>
              <option className={styles.sortBy_color} value="Descending">
                Descending
              </option>
            </select>
          </div>
          <GreenOutButton
            title="CREATE PACKER"
            css={styles.createPackerBtn}
            handleSubmit={() => navigate("/createpacker")}
          />
        </div>
      </div>

      {loading ? (
        <div className={"loadingMain"}>
          <img src="/wallicon.gif" alt="walliconGIF" />
        </div>
      ) : (
        <>
          <div className={styles.heightFix_div}>
            <table className={styles.itemTable}>
              <thead>
                <tr className={styles.item}>
                  <th>Name & City</th>
                  <th>Cart Type</th>
                  <th>Date</th>
                  <th>Order Id</th>
                  <th>Delivery Partner</th>
                  <th>Status</th>
                  <th>Last Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((val, index) => (
                    <tr key={index} className={styles.item}>
                      <td>
                        {val.storeName}, {val.city}
                      </td>
                      <td>{val.cartType}</td>
                      <td>{val.orderDate}</td>
                      <td>{val.orderId}</td>
                      <td>{val.partnerName}</td>
                      <td>{val.status}</td>
                      <td>{val.lastAction}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Pagination
            total={totalPages}
            current={currentPage}
            pagination={(crPage) => handlePagination(crPage)}
          />
        </>
      )}
    </div>
  );
};
export default Packer;
