import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/context/Auth";
import "bootstrap/dist/css/bootstrap.min.css";
import { InactivityProvider } from "./components/context/Inactivity";
import { BrowserRouter } from "react-router-dom";
// import { NotificationProvider } from "./components/context/NotificationContext";
// import ErrorBoundary from "./components/updatedComponents/Hooks/ErrorHandeler";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <AuthProvider>
        {/* <ErrorBoundary> */}
          <InactivityProvider>
            {/* <NotificationProvider> */}
            <App />
            {/* </NotificationProvider> */}
          </InactivityProvider>
        {/* </ErrorBoundary> */}
      </AuthProvider>
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
