import React, { useEffect, useState } from "react";
import { useAuth } from "../../components/context/Auth";
import {
  Close,
  DeleteFile,
  PackingWhatsappIcon,
  PhoneMob,
  Print,
  Printmob,
  UpLoadFile,
} from "../../components/icons/Icon";
import styles from "./Dishpatchboy.module.css";
import { useReactToPrint } from "react-to-print";
import { PackingSlip } from "../../pages/dispatchmanager/PackingSlip";
import { useParams } from "react-router-dom";
import Modal from "../../components/Modal";
import { GreenButton, RedOutButton } from "../../components/Buttons";
import { PrintLabel } from "../dispatchmanager/PrintLabel";

const PackingOrder = () => {
  const params = useParams();
  const orderId = params.orderId;
  let componentRef = React.useRef();
  let printRef = React.createRef();
  const { switchDisplay, managerId } = useAuth();
  const [data, setData] = useState([]);
  const [check, setCheck] = useState(false);
  const [images, setImages] = useState([{}]);
  const [packs, setPacks] = useState();
  const [point, setPoint] = useState();
  const [showPackedModal, setShowPackedModal] = useState(false);
  const [msgShow, setMsgShow] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (packs) {
      setPoint(
        Array.from(
          Array(
            packs === "0"
              ? 1
              : packs === "1"
              ? 1
              : packs === "2"
              ? 2
              : parseInt(packs - 1)
          ).keys()
        )
      );
    }
  }, [packs]);

  const addFormFields = () => {
    point.forEach((element) => {
      setImages([{}]);
    });
  };

  useEffect(() => {
    if (check && images.length < parseInt(packs)) {
      point.forEach((element) => {
        setImages((prevState) => [
          ...prevState.slice(0, images.length),
          {},
          // ...images.slice(index + 1)
        ]);
      });
    }
  }, [check, images.length, packs, point]);

  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        orderId: orderId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "packing/order", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData(result.data);
            setPacks(result.data.packingQty);
          }
        })
        .catch((error) => {})
    }
  }, [managerId, orderId]);

  const Style = {
    display: show ? "block" : "none",
  };
  const PackedAlert = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const handlePacked = (e) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("packingQty", packs);
    images.map((obj) => formdata.append("images", obj));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL +
        "packing/orderPacked/" +
        managerId +
        "/" +
        orderId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setMsgShow(result.msg);
        setShowPackedModal(true);
      })
      .catch((error) => {})
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handlePrintLabel = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <React.Fragment>
      <Modal
        show={showPackedModal}
        close={setShowPackedModal}
        closeBtn={true}
        heading="PACKING ORDER STATUS"
        content={
          <div className={styles.modalMaindiv}>
            <p className={styles.message}>{msgShow}</p>
            <div className={styles.massageModal_btn}>
              <RedOutButton
                title="CANCEL"
                handleSubmit={() => {
                  setShowPackedModal(false);
                }}
              />
              <GreenButton
                title="OK"
                handleSubmit={() => {
                  window.location.reload();
                }}
              />
            </div>
          </div>
        }
      />

      <div
        className={`${switchDisplay ? styles.bg_dark : styles.bg_white} ${
          styles.markt
        }`}
      >
        <div className={styles.packingOrder}>
          <form onSubmit={PackedAlert} className={styles.marketing}>
            <div className={styles.flexdivprint}>
              <h1 className={styles.order_heading}>{data.storeName}</h1>
              <div
                className={`${styles.singleOrderStatus} 
                            ${
                              data.packingStatus === "New Order"
                                ? styles.status_blue
                                : data.packingStatus === "Packed"
                                ? styles.statuscolor3
                                : data.packingStatus === "Not Packed"
                                ? styles.statuscolor1
                                : ""
                            }`}
              >
                &#9679; {data.packingStatus}
              </div>

              <div className={styles.printicon} onClick={() => handlePrint()}>
                <Print />
                <p className={styles.print}>Print Slip</p>
              </div>
              <div style={{ display: "none" }}>
                <PackingSlip
                  orderId={orderId}
                  managerId={managerId}
                  ref={printRef}
                  handlePrint={handlePrint}
                />
              </div>
            </div>
            <div className={styles.flexdiv}>
              <div className={styles.order_text}>
                <div>
                  Dated: <b className={styles.textright}>{data.orderDate}</b>
                </div>
                <div>
                  Order ID: <b className={styles.textright}>{data.orderId}</b>
                </div>
              </div>
              <div
                className={styles.printicon}
                onClick={() => handlePrintLabel()}
              >
                <Printmob color={switchDisplay ? "#000000" : "#FFFFFF"} />
                <p className={styles.print}>Print Label</p>
              </div>
              <div style={{ display: "none" }}>
                <PrintLabel
                  data={data}
                  qtyPacks={packs}
                  ref={componentRef}
                  handlePrint={handlePrintLabel}
                />
              </div>
            </div>
            <div className={styles.packingDetailsHeading}>Details</div>
            <div className={styles.packingDetails_div}>
              <div className={styles.packingDetails_txt}>
                <div className={styles.ordermanagerName}>
                  Order Manager
                  <b>({data.orderManager && data.orderManager.name})</b>
                </div>
                <div className={styles.contactsDiv}>
                  <PhoneMob color={switchDisplay ? "#000000" : "#FFFFFF"} />
                  <a
                    href={`tel:+91${
                      data.orderManager && data.orderManager?.mobile
                    }`}
                  >
                    <b>+91-{data.orderManager && data.orderManager?.mobile}</b>
                  </a>
                  <a
                    className={styles.whatsappIcon}
                    target="_blank"
                    rel="noreferrer"
                    href={"https://wa.me/+91 9832560001"}
                  >
                    <PackingWhatsappIcon />
                  </a>
                </div>
              </div>

              <div className={styles.packingDetails_txt}>
                <div className={styles.ordermanagerName}>
                  Dispatch Manager
                  <b>({data.dispatchManager && data.dispatchManager.name})</b>
                </div>
                <div className={styles.contactsDiv}>
                  <PhoneMob color={switchDisplay ? "#000000" : "#FFFFFF"} />
                  <a
                    href={`tel:+91${
                      data.dispatchManager && data.dispatchManager?.mobile
                    }`}
                  >
                    <b>
                      +91-{data.dispatchManager && data.orderManager?.mobile}
                    </b>
                  </a>
                  <a
                    className={styles.whatsappIcon}
                    target="_blank"
                    rel="noreferrer"
                    href={"https://wa.me/+91 9832560001"}
                  >
                    <PackingWhatsappIcon />
                  </a>
                </div>
              </div>

              <div className={styles.packingDetails_txt}>
                <div className={styles.ordermanagerName}>Delivery Method</div>
                <div className={styles.contactsDiv}>
                  <b>{data.courierName && data.courierName}</b>
                </div>
              </div>
              <div className={styles.packingDetails_txt}>
                <div className={styles.ordermanagerName}>Delivery Partner</div>
                <div className={styles.contactsDiv}>
                  <b>{data.partnerName && data.partnerName}</b>
                </div>
              </div>
            </div>

            <div className={styles.itemsScroll}>
              {data.items &&
                data.items.map((item, index) => (
                  <div key={index} className={styles.flex}>
                    <div>
                      <img
                        src={process.env.REACT_APP_S3URL + item.thumbnail}
                        alt={item.itemNo}
                      />
                    </div>
                    <div className={styles.flexMA}>
                      <p>{item.product_name}</p>
                      <p>{item.collection_name}</p>
                      <p>
                        <b>{item.itemNo}</b>
                      </p>
                      <p>{item.warehouse}</p>
                    </div>
                    <div>
                      <p>Qty: {item.qty}</p>
                      <br />
                      <input
                        type="checkbox"
                        name="vehicle1"
                        value="check"
                        className={styles.checkbox}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className={styles.main_div}>
              <div className={styles.flexdiv}>
                <p>Enter Number Of Packs:</p>
                <input
                  type="number"
                  name="packs"
                  value={packs || ""}
                  // setPacks
                  onChange={(e) => {
                    setPacks(e.target.value);

                    if (
                      check &&
                      e.target.value &&
                      images.length > parseInt(e.target.value)
                    ) {
                      const values = [...images];
                      values.splice(
                        point,
                        images.length -
                          (parseInt(e.target.value) === 0
                            ? 1
                            : parseInt(e.target.value))
                      );
                      setImages(values);
                    }
                  }}
                  onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
                  className={styles.input}
                  required
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  name="vehicle1"
                  value="check"
                  className={styles.checkbox}
                  checked={check ? true : false}
                  onChange={(e) => {
                    if (e.target.checked && packs) {
                      addFormFields();
                      setCheck(true);
                    } else {
                      const values = [...images];
                      values.splice(point, point.length);
                      setImages(values);
                      setCheck(false);
                    }
                  }}
                />
                <label htmlFor="vehicle1">Add image of every pack</label>
              </div>

              <div>
                {images.map((item, index) => (
                  <div key={index} className={styles.flexPacksdiv}>
                    <span className={styles.packTxt}>Pack {index + 1}</span>
                    <label className={styles.label}>
                      <input
                        name=""
                        type="file"
                        hidden
                        onChange={(e) => {
                          let data = [...images];
                          data[index] = e.target.files[0];
                          setImages(data);
                        }}
                      />
                      <UpLoadFile />
                      &nbsp;Upload Image
                    </label>

                    {item.name ? (
                      <img
                        src={URL.createObjectURL(item)}
                        alt=""
                        className={styles.fileimg}
                      />
                    ) : (
                      <img
                        src="/assets/packingImage.png"
                        alt="packing_order_image"
                        className={styles.packingImage}
                      />
                    )}

                    <div
                      className={styles.deletediv}
                      onClick={(e) => {
                        let data = [...images];
                        data[index] = {};
                        setImages(data);
                      }}
                    >
                      <DeleteFile color={"red"} />
                      <p className={styles.deleteicon}>Delete</p>
                    </div>
                    {images.length !== 1 && (
                      <div
                        className={styles.deletediv}
                        onClick={(e) => {
                          setPacks(parseInt(packs) - 1);
                          const list = [...images];
                          list.splice(index, 1);
                          setImages(list);
                        }}
                      >
                        <span className={styles.crossIcon}> &#10006;</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className={styles.flexdiv}>
                <button
                  type="button"
                  className={styles.btn1}
                  onClick={() => window.history.back()}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={data.packingStatus === "Packed" ? true : false}
                  className={styles.btn2}
                >
                  Packed
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className={styles.leadlost} style={Style} onClick={PackedAlert}>
        <div className={styles.lost_content}>
          <div className={styles.flexdiv}>
            <b>PACKED</b> <Close onClick={PackedAlert} />
          </div>

          <br />
          <h3 className={styles.packedAlert}>Are You Sure Want To Pack </h3>
          <br />
          <div className={styles.flexdiv}>
            <RedOutButton
              title="CLOSE"
              handleSubmit={() => {
                PackedAlert();
              }}
            />
            <GreenButton
              title="SUBMIT"
              handleSubmit={() => {
                handlePacked();
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PackingOrder;
