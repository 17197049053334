import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Outlet, useNavigate } from "react-router-dom";
import AdminMenu from "../../../leftMeuns/AdminMenu";
import styles from "./Navbar.module.css";
import { useAuth } from "../../context/Auth";
import useWindowWidth from "../Hooks/WidthSize";
import { menuItems } from "../../../exports";
import { NavbarWhatsAppIcon } from "../../icons/Icon";

const NavLayout = () => {
  const [isClosed, setIsClosed] = useState(false);
  const navigate = useNavigate()
  const { mode, designations ,totalUnreadChat} = useAuth();
  const width = useWindowWidth();
  useEffect(() => {
    if (width < 1366) {
      setIsClosed(true);
    } else {
      setIsClosed(false);
    }
  }, [width]);



  const sidebarWidth = isClosed ? "80px" : "190px";
  const contentWidth = `calc(100% - ${sidebarWidth})`;
  const [isDrawerOpen, setIsDrawerOpen] = useState(width < 700 ? false : true);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const chacktoggleDrawer = () => {
    if (isDrawerOpen) {
      toggleDrawer();
    }
  };
  const handelClose = () => {
    if (width < 1250) {
      return;
    } else {
      setIsClosed(!isClosed);
    }
  };

  const filteredMenuItems = menuItems.filter((item) =>
    item.allowedDesignations.some((designation) =>
      designations.includes(designation)
    )
  );

  const allowedDesignations = [
    "ordermanager",
    "inventorymanager",
    "dispatchmanager",
    "paymentmanager",
    "packingmanager",
    // "superadmin",
  ];

  const isAllowed = designations.some((role) => allowedDesignations.includes(role));
  // console.log("filteredMenuItems", filteredMenuItems);

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "1920px",
        width: "100%",
        height: "100vh",
      }}
    >
      {width < 700 ? null : (
        <div
          className={
            isClosed
              ? `${styles.leftMenu} ${styles.leftMenuToggled}`
              : styles.leftMenu
          }
          style={mode ? { backgroundColor: "#2C2E33" } : {}}
        >
          <AdminMenu
            isClosed={isClosed}
            menuItems={filteredMenuItems}
            onClick={handelClose}
          />
        </div>
      )}

      <div
        style={{
          width: contentWidth,
          transition: "width 0.3s ease",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          flex: 1,
        }}
      >
        <Navbar
          isTogle={isClosed}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          menuItems={filteredMenuItems}
          defaultDashboard={"/"}
        />

        {isAllowed && 
        <div
          onClick={() => navigate('/whatsappchats')}
          style={{
            position: "fixed",
            bottom: "10%",
            right: width > 3020 ? "10%" : width > 2200 ? "13%": width > 1920 ? "20%" :"2%",
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            background: mode ? "#1C1D21" : "#F8F9FC",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontSize: "18px",
            fontWeight: "bold",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
            border: "2px solid white",
            zIndex:"999"
          }}
        >
          <NavbarWhatsAppIcon />
          {totalUnreadChat > 0 && <div
            style={{
              position: "absolute",
              top: "-5px",
              right: "-5px",
              backgroundColor: "red",
              height: "20px",
              width: "20px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "bold",
              lineHeight: "20px",

            }}
          >

            {totalUnreadChat}
          </div>}
        </div>}

        {width < 700 ? (
          <main onClick={chacktoggleDrawer} className={styles.mdSccreen}>
            <Outlet />
          </main>
        ) : (
          <main className={styles.lg_screen}>
            <Outlet />
          </main>
        )}
      </div>
    </div>
  );
};

export default NavLayout;
