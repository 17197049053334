import React, { useEffect, useState } from "react";
import styles from "./Product.module.css";
import { useAuth } from "../../components/context/Auth";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import Button from "../../components/updatedComponents/Dealers/Button";
import { AddTO, DeleteTo } from "../../components/icons/Icon3";
import { useParams } from "react-router-dom";
import InputTextArea from "../../components/updatedComponents/Product/InputTextArea";
import fetchData from "../../APIsControll/apiControll";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";
const ProductFAQ = () => {
  const { mode } = useAuth();
  const { Product } = useParams();
  const { show } = useToast();
  const [faqData, setFaqData] = useState([{ question: "", answer: "" }]);
  const [loading, setLoading] = useState({
    loadingToGetFAQ: false,
    loadingToAddFAQ: false,
  });
  const type_id = new URLSearchParams(window.location.search).get("productId");
  const handleChange = (index, field, value) => {
    const updatedFaqData = [...faqData];
    updatedFaqData[index][field] = value;
    setFaqData(updatedFaqData);
  };
  const addFaq = () => {
    setFaqData([...faqData, { question: "", answer: "" }]);
  };
  const removeFaq = (index) => {
    const updatedFaqData = faqData.filter((_, i) => i !== index);
    setFaqData(updatedFaqData);
  };

  // //console.log("faqData", faqData);
  const getFAQ = async () => {
    try {
      setLoading({ ...loading, loadingToGetFAQ: true });
      const payload = {
        product_name: Product,
      };
      const res = await fetchData(`content/getFAQ`, payload);
      // //console.log("res", res);
      if (res?.status === "success") {
        setFaqData(res?.data?.faqs);
        return;
      }
      if (res?.status === "failed") {
        setFaqData([{ question: "", answer: "" }]);
        return;
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading({ ...loading, loadingToGetFAQ: false });
    }
  };

  useEffect(() => {
    getFAQ();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AddFaq = async () => {
    try {
      setLoading({ ...loading, loadingToAddFAQ: true });
      const payload = {
        faqs: faqData,
        product_id: type_id,
        product_name: Product,
      };
      const res = await fetchData(`content/addFAQ`, payload);
      if (res?.status === "success") {
        show(res.message, "success");
        return;
      }
      if (res?.status === "failed") {
        show(res.message, "error");
        return;
      }
    } catch (error) {
      show("Something went wrong!", "error");
    } finally {
      setLoading({ ...loading, loadingToAddFAQ: false });
    }
  };

  return (
    <div className={styles.Main_Layout}>
      <div
        className={styles.Layout}
        style={{
          backgroundColor: mode ? "#2B2D31" : "#F8F9FC",
          color: mode
            ? "#fff"
            : "#000" /*  cursor: disabled ? "not-allowed" : ""  */,
        }}
      >
        {/* <form> */}
        <div className={styles.Top_bar_css}>
          <div className={styles.gobackClass}>
            <TopTabBar text={`Product / ${Product} `} />
          </div>
          <div className={styles.btn_Continer}>
            <div>
              <Button
                buttonText={"Create"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#fff"}
                buttonIcon={<AddTO />}
                disabledButtonTextColor={"#fff"}
                disabledButtonBgColor={"#4164E3"}
                buttonClick={AddFaq}
                loading={loading.loadingToAddFAQ}
              />
            </div>
          </div>
        </div>

        <div className={styles.Product_faq_continer}>
          <div className={styles.faq_text}>
            {" "}
            <span> Frequently Asked Questions (FAQ)</span>{" "}
          </div>
          {loading.loadingToGetFAQ ? (
            <div className={styles.faq_continer_Skeleton}>
              <div className={styles.faq_continer_Skeleton_01}>
                {" "}
                <Skeleton lines={2} thickness={8} />{" "}
              </div>
              <div className={styles.faq_continer_Skeleton_02}>
                {" "}
                <Skeleton lines={2} thickness={8} />{" "}
              </div>
              <div className={styles.faq_continer_Skeleton_03}>
                {" "}
                <Skeleton lines={2} thickness={8} />{" "}
              </div>
            </div>
          ) : (
            faqData.map((item, index) => (
              <div key={index} className={styles.faq_continer}>
                <div className={styles.faq_continer_001}>
                  <InputTextArea
                    lableText={`Question - ${index + 1}`}
                    value={item.question}
                    onChange={(e) =>
                      handleChange(index, "question", e.target.value)
                    }
                    minLength={5000}
                  />
                </div>
                <div className={styles.faq_continer_002}>
                  <InputTextArea
                    lableText={`Answer - ${index + 1}`}
                    value={item.answer}
                    onChange={(e) =>
                      handleChange(index, "answer", e.target.value)
                    }
                    minLength={5000}
                  />
                </div>
                <div
                  className={styles.faq_continer_003}
                  onClick={() => removeFaq(index)}
                >
                  <DeleteTo />
                </div>
              </div>
            ))
          )}
          <div>
            <div style={{ width: "120px", marginTop: "10px" }}>
              <Button
                buttonText={"Add"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#fff"}
                buttonIcon={<AddTO />}
                disabledButtonTextColor={"#fff"}
                disabledButtonBgColor={"#4164E3"}
                buttonClick={addFaq}
              />
            </div>
          </div>
        </div>

        {/* </form> */}
      </div>
    </div>
  );
};

export default ProductFAQ;
