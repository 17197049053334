import { useEffect, useRef, useState } from "react";
import { ManagerTickIcon, RevertIcon, SaveTaxRateIcon } from "../../icons/Icon";
import Button from "../Dealers/Button";
import InputTag from "../FormComponents/InputTag";
import styles from "../../../pages/ordermanager/Orders.module.css";
import FileUpload from "./FileUpload";
import VerticalDropDown2 from "../FormComponents/VerticalDropDown2";
import { useToast } from "../Toaster/Toaster";
import { Close } from "../../icons/Icon3";

const AddReceiptModal = ({
  handleCloseModal,
  mode,
  totalAmount,
  accounts = [],
  receiptData,
  updateReceiptData,
  type = "create",
  revertCase,
  flag,
  receiptsTotalAmount,
}) => {
  const transactionAmountRef = useRef(null);
  const { show } = useToast();
  const [localReceiptData, setLocalReceiptData] = useState(receiptData);
  const [image, setImage] = useState(
    receiptData?.transactionScreenshot || null
  );
  const [loading, setLoading] = useState(false);
  const [isPOD, setIsPOD] = useState(false);

  useEffect(() => {
    setLocalReceiptData(receiptData);
  }, [receiptData]);

  useEffect(() => {
    if (
      type === "create" &&
      flag !== "notupdateamount" &&
      localReceiptData.paymentMethod === "POD"
    ) {
      setLocalReceiptData((prevState) => ({
        ...prevState,
        paymentAmount: totalAmount - receiptsTotalAmount,
      }));
      setIsPOD(true);
    }
    if (type === "create" && localReceiptData.paymentMethod === "Cash") {
      setIsPOD(false);
    }
    if (type === "create" && localReceiptData.paymentMethod === "Photo") {
      setIsPOD(false);
    }
  }, [
    flag,
    localReceiptData.paymentMethod,
    receiptsTotalAmount,
    totalAmount,
    type,
  ]);

  const resetFieldsOnPaymentMethodChange = (newPaymentMethod) => {
    const updatedData = {
      ...localReceiptData,
      paymentMethod: newPaymentMethod,
    };

    switch (newPaymentMethod) {
      case "Cash":
        updatedData.accountNumber = "";
        updatedData.paymentType = "";
        updatedData.transactionId = "";
        updatedData.transactionScreenshot = "";
        break;
      case "Photo":
        updatedData.paymentType = "";
        break;
      case "POD":
        updatedData.accountNumber = "";
        updatedData.paymentType = "Cash";
        updatedData.transactionId = "";
        updatedData.transactionScreenshot = "";
        break;
      default:
        break;
    }
    setLocalReceiptData(updatedData);
  };

  const validateField = (field, value) => {
    switch (field) {
      case "paymentAmount":
        return value && !isNaN(value)
          ? null
          : "Payment amount must be a valid number.";
      case "accountNumber":
        return value ? null : "Account number is required.";
      case "transactionScreenshot":
        return value ? null : "Transaction screenshot is required.";
      case "transactionId":
        return value ? null : "Transaction ID is required.";
      default:
        return null;
    }
  };

  const validateData = () => {
    const errors = {};
    const rules = {
      Cash: ["paymentAmount"],
      Photo: [
        "paymentAmount",
        "accountNumber",
        "transactionScreenshot",
        "transactionId",
      ],
      "Pay on Delivery":
        localReceiptData.paymentType === "Cash"
          ? ["paymentAmount"]
          : [
              "paymentAmount",
              "accountNumber",
              "transactionScreenshot",
              "transactionId",
            ],
      Online:
        localReceiptData.paymentType === "BankAPI"
          ? ["transactionId"]
          : ["transactionScreenshot", "accountNumber", "transactionId"],
    };

    const requiredFields = rules[localReceiptData.paymentMethod] || [];
    requiredFields.forEach((field) => {
      const error = validateField(field, localReceiptData[field]);
      if (error) errors[field] = error;
    });

    if (Object.keys(errors).length > 0) {
      // console.error("Validation Errors:", errors);
      show("Please fill all the required fields!", "warning");
      return false;
    }

    return true;
  };

  const buttonClick = async () => {
    if (validateData()) {
      setLoading(true);
      try {
        await updateReceiptData(localReceiptData);
        handleCloseModal();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleFileChange = (file) => {
    setImage(file);
    setLocalReceiptData((prevState) => ({
      ...prevState,
      transactionScreenshot: file || "",
    }));
  };

  const handleFieldChange = (field, value) => {
    setLocalReceiptData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Invalid Date";

    const [day, month, year] = dateString.split("-");
    const formattedDate = `${year}-${month}-${day}`;

    const date = new Date(`${formattedDate}T00:00:00`);

    if (isNaN(date)) {
      console.error("Invalid date:", dateString);
      return "Invalid Date";
    }

    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const isReadOnly = () =>
    ["Received", "Refund", "Cancelled"].includes(localReceiptData.status) ||
    (localReceiptData.paymentMethod === "Online" &&
      localReceiptData.paymentType !== "Photo");

  const methodOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Photo", value: "Photo" },
    { label: "Pay on Delivery", value: "POD" },
  ];

  const subMethodOptions = [
    { label: "Cash", value: "Cash" },
    { label: "Photo", value: "Photo" },
  ];

  // console.log("receipt data ", receiptData);

  // console.log("local receipt data ", localReceiptData);

  return (
    <div
      className={styles.add_reciept_modal}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      <div style={{ alignItems: "flex-start" }}>
        <p style={{ color: mode ? "#fff" : "#000" }}>
          {(type === "create" || type === "edit") && "Receipt"}
          {type === "update" && "Update Receipt"}
        </p>
        {type !== "create" && (
          <div
            className={styles.receipt_dets}
            style={{
              color: mode ? "#FFFFFF" : "#000000",
            }}
          >
            <p style={{ margin: 0 }}>
              {localReceiptData.orderId} / {localReceiptData.receiptId} /{" "}
              {localReceiptData.receiptDate}{" "}
            </p>
            {!localReceiptData.verifyBy
              ? "Not Received"
              : localReceiptData.verifyBy}
          </div>
        )}
      </div>

      <div style={{ gap: "1rem" }}>
        <div style={{ width: "50%" }}>
          {localReceiptData.paymentMethod === "Online" ? (
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Method"}
              value={localReceiptData.paymentMethod}
              readOnly={true}
            />
          ) : (
            <VerticalDropDown2
              height={"37px"}
              fontSize={"12px"}
              label={"Method"}
              dropDownText={localReceiptData.paymentMethod || "Select Method"}
              dropDownOpen={
                type !== "create" || flag === "notupdateamount" ? false : true
              }
              options={methodOptions}
              value={methodOptions.find(
                (option) => option.value === localReceiptData.paymentMethod
              )}
              onchange={(option) => {
                resetFieldsOnPaymentMethodChange(option.value);
                transactionAmountRef.current.focus();
              }}
              disableOpen={isReadOnly()}
              star
            />
          )}
        </div>
        <div style={{ width: "50%" }}>
          {flag !== "verify" ? (
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Total Amount"}
              placeholderText={"000.00"}
              value={
                type !== "create" ? localReceiptData.totalAmount : totalAmount
              }
              readOnly={true}
            />
          ) : (
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Receipt Amount"}
              placeholderText={"000.00"}
              value={totalAmount}
              readOnly={true}
            />
          )}
        </div>
      </div>

      {localReceiptData.paymentMethod === "Online" && (
        <>
          <InputTag
            padding={"8px"}
            fontSize={"12px"}
            labelText={"Payment Type"}
            placeholderText={"000.00"}
            value={localReceiptData.paymentType}
            readOnly={true}
          />
          {localReceiptData.paymentType === "Photo" && (
            <FileUpload
              mode={mode}
              image={image}
              setImage={setImage}
              onFileChange={handleFileChange}
              viewOnly={isReadOnly()}
            />
          )}
          {localReceiptData.paymentType === "Photo" && (
            <div>
              <VerticalDropDown2
                height={"37px"}
                fontSize={"12px"}
                label={"Account"}
                dropDownText={
                  accounts.find(
                    (a) => a.accountNumber === localReceiptData.accountNumber
                  )
                    ? `${localReceiptData.accountNumber} / ${
                        accounts.find(
                          (a) =>
                            a.accountNumber === localReceiptData.accountNumber
                        ).bankName
                      }`
                    : "Select"
                }
                options={accounts.map((a) => ({
                  label: `${a.accountNumber} / ${a.bankName}`,
                  value: a.accountNumber,
                }))}
                value={{
                  label: accounts.find(
                    (a) => a.accountNumber === localReceiptData.accountNumber
                  )
                    ? `${localReceiptData.accountNumber} / ${
                        accounts.find(
                          (a) =>
                            a.accountNumber === localReceiptData.accountNumber
                        ).bankName
                      }`
                    : "Select",
                  value: localReceiptData.accountNumber,
                }}
                onchange={(option) =>
                  handleFieldChange("accountNumber", option.value)
                }
                disableOpen={isReadOnly()}
                star
              />
            </div>
          )}

          <div>
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Transaction ID / RRN No."}
              placeholderText={"Ref / UTR Number / Transaction ID"}
              placeholderType={"text"}
              value={localReceiptData.transactionId || ""}
              onChange={(e) =>
                handleFieldChange("transactionId", e.target.value)
              }
              maxLength={200}
              required={true}
              readOnly={isReadOnly()}
              star
            />
          </div>
          {localReceiptData.status === "Received" && (
            <span
              style={{
                fontSize: "12px",
                textAlign: "left",
                width: "100%",
                marginTop: "-6px",
                color: mode ? "#FFFFFF" : "#000000",
              }}
            >
              *Received on: {localReceiptData.verifyTime}
              {", "}
              {formatDate(localReceiptData?.verifyDate)}
            </span>
          )}
        </>
      )}

      {localReceiptData.paymentMethod === "Photo" && (
        <>
          <div>
            <VerticalDropDown2
              height={"37px"}
              fontSize={"12px"}
              label={"Account"}
              dropDownText={
                accounts.find(
                  (a) => a.accountNumber === localReceiptData.accountNumber
                )
                  ? `${localReceiptData.accountNumber} / ${
                      accounts.find(
                        (a) =>
                          a.accountNumber === localReceiptData.accountNumber
                      ).bankName
                    }`
                  : "Select"
              }
              options={accounts.map((a) => ({
                label: `${a.accountNumber} / ${a.bankName}`,
                value: a.accountNumber,
              }))}
              value={{
                label: accounts.find(
                  (a) => a.accountNumber === localReceiptData.accountNumber
                )
                  ? `${localReceiptData.accountNumber} / ${
                      accounts.find(
                        (a) =>
                          a.accountNumber === localReceiptData.accountNumber
                      ).bankName
                    }`
                  : "Select",
                value: localReceiptData.accountNumber,
              }}
              onchange={(option) =>
                handleFieldChange("accountNumber", option.value)
              }
              disableOpen={isReadOnly()}
              star
            />
          </div>

          <FileUpload
            mode={mode}
            image={image}
            setImage={setImage}
            onFileChange={handleFileChange}
            viewOnly={isReadOnly()}
          />

          <div>
            <InputTag
              padding={"8px"}
              fontSize={"12px"}
              labelText={"Transaction ID / RRN No."}
              placeholderText={"Ref / UTR Number / Transaction ID"}
              placeholderType={"text"}
              value={localReceiptData.transactionId || ""}
              onChange={(e) =>
                handleFieldChange("transactionId", e.target.value)
              }
              maxLength={200}
              required={true}
              readOnly={isReadOnly()}
              star
            />
          </div>
        </>
      )}

      {localReceiptData.paymentMethod === "POD" && (
        <>
          <div>
            <VerticalDropDown2
              height={"37px"}
              fontSize={"12px"}
              label={"Sub Method"}
              dropDownText={localReceiptData.paymentType || "Select"}
              options={subMethodOptions}
              value={subMethodOptions.find(
                (option) => option.value === localReceiptData.paymentType
              )}
              onchange={(option) =>
                handleFieldChange("paymentType", option.value)
              }
              disableOpen={isReadOnly()}
              star
            />
          </div>
          {localReceiptData.paymentType === "Photo" && (
            <div>
              <VerticalDropDown2
                height={"37px"}
                fontSize={"12px"}
                label={"Account"}
                dropDownText={
                  accounts.find(
                    (a) => a.accountNumber === localReceiptData.accountNumber
                  )
                    ? `${localReceiptData.accountNumber} / ${
                        accounts.find(
                          (a) =>
                            a.accountNumber === localReceiptData.accountNumber
                        ).bankName
                      }`
                    : "Select"
                }
                options={accounts.map((a) => ({
                  label: `${a.accountNumber} / ${a.bankName}`,
                  value: a.accountNumber,
                }))}
                value={{
                  label: accounts.find(
                    (a) => a.accountNumber === localReceiptData.accountNumber
                  )
                    ? `${localReceiptData.accountNumber} / ${
                        accounts.find(
                          (a) =>
                            a.accountNumber === localReceiptData.accountNumber
                        ).bankName
                      }`
                    : "Select",
                  value: localReceiptData.accountNumber,
                }}
                onchange={(option) =>
                  handleFieldChange("accountNumber", option.value)
                }
                disableOpen={isReadOnly()}
                star
              />
            </div>
          )}

          {localReceiptData.paymentType === "Photo" && (
            <>
              <FileUpload
                mode={mode}
                image={image}
                setImage={setImage}
                onFileChange={handleFileChange}
              />
              <div>
                <InputTag
                  padding={"8px"}
                  fontSize={"12px"}
                  labelText={"Transtaction ID / RRN No."}
                  placeholderText={"Ref / UTR Number / Transaction ID"}
                  placeholderType={"text"}
                  value={localReceiptData.transactionId}
                  onChange={(e) =>
                    handleFieldChange("transactionId", e.target.value)
                  }
                  maxLength={200}
                  required={true}
                  readOnly={isReadOnly()}
                  star
                />
              </div>
            </>
          )}
        </>
      )}

      <div>
        <InputTag
          padding={"8px"}
          fontSize={"12px"}
          labelText={type === "create" ? "Transaction" : "Received Amount"}
          placeholderText={"Enter Transaction Amount (In INR)"}
          placeholderType={"number"}
          ref={transactionAmountRef}
          value={localReceiptData.paymentAmount || ""}
          onChange={(e) => {
            handleFieldChange("paymentAmount", e.target.value);
          }}
          maxLength={10}
          required={true}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              buttonClick();
            }
          }}
          readOnly={isReadOnly() || isPOD || flag === "notupdateamount"}
          star
        />
      </div>

      <div
        style={{ gap: "1rem", justifyContent: "center", marginTop: "0.5rem" }}
      >
        <Button
          buttonBgColor={"#000"}
          buttonText={"Close"}
          buttonTextColor={"#fff"}
          buttonIcon={<Close />}
          buttonClick={() => {
            handleCloseModal();
            // resetFeilds();
          }}
        />

        {localReceiptData.status !== "Received" &&
          localReceiptData.status !== "Cancelled" &&
          localReceiptData.paymentType === "Photo" &&
          localReceiptData.paymentMethod === "Online" && (
            <Button
              buttonBgColor={"#F93E3E"}
              buttonText={"Revert Back"}
              buttonTextColor={"#FFFFFF"}
              buttonIcon={<RevertIcon color={"#FFFFFF"} />}
              buttonClick={() => {
                handleCloseModal();
                revertCase();
              }}
            />
          )}

        {localReceiptData.status === "Received" ? (
          <Button
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#fff"}
            buttonIcon={<ManagerTickIcon color="#FFFFFF" />}
            buttonText={"Verified"}
          />
        ) : localReceiptData.status === "Refund" ? (
          <Button
            buttonBgColor={"#31974D"}
            buttonTextColor={"#fff"}
            buttonIcon={<ManagerTickIcon color="#FFFFFF" />}
            buttonText={"Refunded"}
          />
        ) : localReceiptData.status === "Cancelled" ? (
          <Button
            buttonBgColor={"#E93445"}
            buttonTextColor={"#fff"}
            buttonIcon={<ManagerTickIcon color="#FFFFFF" />}
            buttonText={"Cancelled"}
          />
        ) : (
          <Button
            buttonBgColor={"#4164E3"}
            buttonText={
              type === "create"
                ? "SAVE"
                : type === "update"
                ? "Verify & Update"
                : "Verify"
            }
            buttonTextColor={"#fff"}
            buttonIcon={<SaveTaxRateIcon />}
            buttonClick={buttonClick}
            disabled={loading}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default AddReceiptModal;
