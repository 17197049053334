import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import {
  DealerInfoCardCallIcon,
  DealerInfoCardPhoneIcon,
  DealerInfoCardActiveStatusIcon,
  DealerInfoCardWhatsAppIcon,
  DealerInfoCardBlackListedStatusIcon,
  DealerInfoCardNewStatusIcon,
  DealerInfoCardPendingStatusIcon,
  DealerInfoCardRejectedStatusIcon,
  DealerInfoCardPhoneDarkIcon,
  DealerInfoCardWhatsAppDarkIcon,
  CallIcon,
  DealerInfoCardActiveStatusIcon1,
} from "../../icons/Icon";
import styles from "./DealerStyles.module.css";
import { AllCount, Deleted, Verified } from "../../icons/Icon2";

const DealerInfoCard = ({
  dealerName,
  dealerStatus,
  dealerDesignation,
  dealerLocation,
  type,
  imageUri,
  whatappNo,
}) => {
  const { mode , designation} = useAuth();
  const navigate = useNavigate();
  //active, blackListed, new, pending, rejected
  const renderStatusIcon = () => {
    switch (dealerStatus) {
      case "disable":
        return <DealerInfoCardBlackListedStatusIcon />;
      case "Blacklisted":
        return <DealerInfoCardBlackListedStatusIcon />;
      case "New":
        return <DealerInfoCardNewStatusIcon />;
      case "Pending":
        return <DealerInfoCardPendingStatusIcon />;
      case "Rejected":
        return <DealerInfoCardRejectedStatusIcon />;
      case "All":
        return <AllCount />;
      case "Verified":
        return <Verified />;
      case "active":
        return <DealerInfoCardActiveStatusIcon1 />;
      case "Update":
        return <DealerInfoCardActiveStatusIcon />;
      case "Deleted":
        return <Deleted />;
      default:
        return null;
    }
  };
  ;

  // console.log("imageUri",imageUri);

  return (
    <div
      className={
        mode
          ? `${styles.dealer_Info_Card} ${styles.dealer_Info_Card_Dark}`
          : styles.dealer_Info_Card
      }
      onClick={() => {
        if (type !== "manager") {
          if (designation === 'superadmin') {
            navigate("/dealers/" + whatappNo);
          }
        }
      }}
    >
      <div className={styles.mob_style_1}>
        <div className={styles.dealer_img}>
          {imageUri ? (
            <img
              src={`${process.env.REACT_APP_S3URL}/${imageUri}`}
              alt={type}
            />
          ) : (
            <div className={styles.Frist_text}>
              {dealerName.charAt(0).toUpperCase()}
            </div>
          )}
          <div className={styles.dealer_status_icon}>{renderStatusIcon()}</div>
        </div>

        <div>
          <div className={styles.dealer_Info_Card_name}>
            <p style={mode ? { color: "#fff" } : {}}>{dealerName}</p>
          </div>

          <div
            className={styles.dealer_Info_Card_Text_Section}
            // onClick={() => {
            //   navigate("/dealers/" + whatappNo);
            // }}
          >
            <p style={mode ? { color: "#fff" } : {}}>{dealerDesignation}</p>
            <p
              style={{
                color: mode ? "#fff" : "",
                display: type === "manager" ? "none" : "block",
              }}
            >
              {dealerLocation}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.dealer_Info_Card_Icons_Tray}>
        {mode ? (
          <>
            <DealerInfoCardPhoneDarkIcon />
            <CallIcon />
            <DealerInfoCardWhatsAppDarkIcon />
          </>
        ) : (
          <>
            <DealerInfoCardPhoneIcon />
            <DealerInfoCardCallIcon />
            <DealerInfoCardWhatsAppIcon />
          </>
        )}
      </div>
    </div>

  );
};

export default DealerInfoCard;