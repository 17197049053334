import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
  useRef,
} from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import {
  AddIcon2,
  CancelIcon2,
  EditManagerIcon,
  TickIcon,
} from "../../icons/Icon";
import Button from "../Dealers/Button";
import InputTag from "../FormComponents/InputTag";
import fetchData from "../../../APIsControll/apiControll";
import Spinner from "../FormComponents/Spinner";

const DealerAddressModal = forwardRef(
  (
    { handleCloseModal, onKeyPress, shippingAddress, onAddressUpdate, mode },
    ref
  ) => {
    const pinCodeRef = useRef(null);
    const streetAddressRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [transitioning, setTransitioning] = useState(false);
    const [dealerData, setDealerData] = useState({
      pinCode: "",
      city: "",
      district: "",
      state: "",
      streetAddress: "",
    });

    const getDealerLocation = useCallback(async () => {
      setLoading(true);
      setErrorMsg("");
      try {
        const apiData = {
          keyword: dealerData.pinCode,
        };
        const result = await fetchData("locations/locations", apiData);
        if (result.status === "success") {
          setLoading(false);
          setDealerData((prevState) => ({
            ...prevState,
            city: result.SubDistName[0]
              ? result.SubDistName[0].toUpperCase()
              : "",
            district: result.districts[0] ? result.districts[0] : "",
            state: result.states[0] ? result.states[0] : "",
          }));
          setErrorMsg("");
          if (
            result.SubDistName.length === 0 ||
            result.districts.length === 0 ||
            result.states[0].length === 0
          ) {
            setErrorMsg("No such Pincode exists!");
            pinCodeRef.current.focus();
          }
        }
      } catch (error) {
        setLoading(false);
        pinCodeRef.current.focus();
        //console.log("Error ", error);
        setErrorMsg(error);
      } finally {
        setLoading(false);
      }
    }, [dealerData.pinCode]);

    useEffect(() => {
      if (dealerData.pinCode.length === 6) {
        getDealerLocation();
        setTimeout(() => {
          streetAddressRef.current.focus();
        }, 1000);
      }
    }, [dealerData.pinCode, getDealerLocation]);

    useEffect(() => {
      if (showForm) {
        if (pinCodeRef.current) {
          pinCodeRef.current.focus();
        }
      }
    }, [pinCodeRef, showForm]);

    useImperativeHandle(ref, () => ({
      focus() {
        //console.log("Dealer Address Modal is focused");
      },
    }));

    const toggleForm = useCallback(() => {
      setTransitioning(true);
      setTimeout(() => {
        setShowForm(!showForm);
        setTransitioning(false);
      }, 300);
    }, [showForm]);

    useEffect(() => {
      const handleKeyPress = (e) => {
        if (e.key.toLowerCase() === "y" && !showForm) {
          onKeyPress && onKeyPress(e);
          setTimeout(() => {
            handleCloseModal();
          }, 0);
        }
        if (e.key === "Escape" && showForm) {
          onKeyPress && onKeyPress(e);
          setTimeout(() => {
            handleCloseModal();
          }, 0);
        }
        if (e.key.toLowerCase() === "c" && !showForm) {
          onKeyPress && onKeyPress(e);
          toggleForm();
        }
      };

      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }, [showForm, handleCloseModal, onKeyPress, toggleForm]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setDealerData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    const keyDown = (e) => {
      if (e.key === "Enter" && e.target === streetAddressRef.current) {
        if (
          dealerData.streetAddress.length !== 0 &&
          dealerData.pinCode.length !== 0
        ) {
          //console.log("ENTER IS PRESSED AT STREET ADDRESS ");
          handleSubmit();
        } else {
          alert("Street Address can't be empty!");
        }
      }
    };

    const handleSubmit = () => {
      if (
        dealerData.pinCode.length !== 6 &&
        dealerData.streetAddress.length === 0
      ) {
        alert.alert("FILL ALL FIELDS ");
      } else {
        onAddressUpdate && onAddressUpdate(dealerData);
        setTimeout(() => {
          handleCloseModal();
        }, 0);
      }
    };

    return (
      <div
        className={`${styles.dealer_address_modal} ${
          mode ? styles.dealer_address_modal_dark : ""
        } ${styles.transition} ${
          transitioning ? "" : styles["transition_active"]
        }`}
      >
        <p style={{ marginBottom: "1rem" }}>Update Shipping Address</p>
        {showForm ? (
          <div className={styles.update_location_form}>
            <div style={{ width: "100%" }}>
              <InputTag
                star
                labelText={"PIN Code"}
                placeholderText={"Enter PIN Code"}
                placeholderType={"number"}
                padding={"8px"}
                fontSize={"12px"}
                ref={pinCodeRef}
                name="pinCode"
                value={dealerData.pinCode}
                onChange={handleChange}
                onKeyDown={(e) => keyDown(e)}
                maxLength={6}
              />
            </div>
            <div style={{ width: "100%", marginTop: "0.5rem" }}>
              <InputTag
                star
                labelText={"Street Address"}
                placeholderText={"Enter Street Address"}
                placeholderType={"text"}
                padding={"8px"}
                fontSize={"12px"}
                ref={streetAddressRef}
                name="streetAddress"
                value={dealerData.streetAddress}
                onChange={handleChange}
                onKeyDown={keyDown}
                maxLength={100}
              />
            </div>
            <div className={styles.modal_txt_container}>
              {errorMsg.length !== 0 ? (
                <p style={{ color: "#F93E39" }}>{errorMsg}</p>
              ) : loading ? (
                <Spinner
                  height={20}
                  width={20}
                  color={mode ? "#000000" : "#4164E3"}
                />
              ) : dealerData.city.length === 0 ||
                dealerData.district.length === 0 ||
                dealerData.state.length === 0 ? (
                <p style={{ color: mode ? "#FFFFFF" : "#646B88" }}>
                  CITY / DISTRICT / STATE
                </p>
              ) : (
                <p style={{ color: mode ? "#FFFFFF" : "#646B88" }}>
                  {dealerData.city} / {dealerData.district} / {dealerData.state}
                </p>
              )}
            </div>
            <div className={styles.element} style={{ marginTop: "2rem" }}>
              <div className={styles.btn_div}>
                <Button
                  buttonText={"CANCEL"}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"#FFFFFF"}
                  buttonBorder={"none"}
                  buttonIcon={<CancelIcon2 />}
                  buttonClick={(e) => {
                    onKeyPress && onKeyPress(e);
                    setTimeout(() => {
                      handleCloseModal();
                    }, 0);
                  }}
                />
                <p className={styles.btn_text}>Press - ESC</p>
              </div>
              <div className={styles.btn_div}>
                <Button
                  buttonText={"ADD LOCATION"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#FFFFFF"}
                  buttonBorder={"none"}
                  buttonIcon={<AddIcon2 />}
                  buttonClick={() => {
                    handleSubmit();
                  }}
                />
                <p className={styles.btn_text}>Press - ENTER</p>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.address_Pop_Up}>
            <img
              src="/assets/updatedAssets/location.png"
              alt="location"
              style={{ marginTop: "-1rem" }}
            />
            <p>{shippingAddress}</p>
            <p>Do you want to ship to the current address?</p>
            <div className={styles.element}>
              <div className={styles.btn_div}>
                <Button
                  buttonText={"Yes"}
                  buttonBgColor={"#000000"}
                  buttonTextColor={"#FFFFFF"}
                  buttonBorder={"none"}
                  buttonIcon={<TickIcon />}
                  buttonClick={(e) => {
                    onKeyPress && onKeyPress(e);
                    setTimeout(() => {
                      handleCloseModal();
                    }, 0);
                  }}
                />
                <p className={styles.btn_text}>Press - Y</p>
              </div>
              <div className={styles.btn_div}>
                <Button
                  buttonText={"Change"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#FFFFFF"}
                  buttonBorder={"none"}
                  buttonIcon={<EditManagerIcon color={"#FFFFFF"} />}
                  buttonClick={toggleForm}
                />
                <p className={styles.btn_text}>Press - C</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default DealerAddressModal;
