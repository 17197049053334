import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../components/context/Auth";
import "../App.css";

const NoMatch = () => {
  let location = useLocation();
  const { managerId, switchDisplay } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (managerId) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className={`${switchDisplay ? "bg_white" : "bg_dark"} App`}>
      <h3>
        No match for <code>{location?.pathname}</code>
      </h3>
    </div>
  );
};

export default NoMatch;
