import { useAuth } from "../../components/context/Auth";
import styles from "./Receipts.module.css";
import {
  DispatchDownloadIcon,
  DispatchReloadIcon,
  ManagerDevices3Dots,
} from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
import { useEffect, useState } from "react";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";
import fetchData from "../../APIsControll/apiControll";
import OptionsMenu from "../../components/updatedComponents/Receipts/OptionsMenu";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import AddReceiptModal from "../../components/updatedComponents/OrderManager/AddReceiptModal";
import { handleFileData } from "../../APIsControll/apiControl2";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
// import { io } from "socket.io-client";
import CancelReceiptModal from "../../components/updatedComponents/Receipts/CancelReceiptModal";
import Info from "../../components/updatedComponents/Receipts/Info";
import RefundReceipt from "../../components/updatedComponents/Receipts/RefundReceipt";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import NoDataFound from "../../components/updatedComponents/NoDataFound/NoDataFound";
import { TemplateNotFound } from "../../components/icons/Icon2";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";
import { useNavigate } from "react-router-dom";
import { socket, SocketConnected } from "../../Socket/socket";

const Receipts = () => {
  const { mode, managerId, designations, designation } = useAuth();
  const navigate = useNavigate();
  const { show } = useToast();
  const [daysTab, setDaysTab] = useState("today");
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("All");
  const [shipingTab, setShipingTab] = useState({ label: "All", value: "all" });
  const [data, setData] = useState([]);
  const [managers, setManagers] = useState([]);
  const [receiptState, setReceiptState] = useState([]);
  const [selectedManagerID, setSelectedManagerID] = useState();
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(false);

  useEffect(() => {
    const getAllReceipts = async () => {
      if (daysTab === "period" && (startDate === "" || endDate === "")) return;

      const apiData = {
        managerId: managerId,
        keyword: debouncedKeyword,
        day: daysTab === "period" ? "" : daysTab,
        // cartType: "all",
        endDate: endDate,
        startDate: startDate,
        orderType: shipingTab.value,
        ordermanagerId: selectedManagerID,
        // sortKey: "",
        status: status,
      };
      try {
        setLoading(true);
        const result = await fetchData(
          "receipt/receipts?page=" + page + "&limit=15",
          apiData
        );
        if (result.status === "success") {
          // setLoading(false);
          setActionCompleted(false);
          setData(result.data);
          setReceiptState(result.receipts);
          setPages(result.pages);
          if (result.managers.orderManagers) {
            setManagers(result.managers.orderManagers);
          }
        }
        if (result.status === "failed") {
          setData([]);
          show(result.msg, "error");
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    if (managerId) {
      getAllReceipts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    managerId,
    debouncedKeyword,
    daysTab,
    startDate,
    endDate,
    shipingTab.value,
    selectedManagerID,
    status,
    actionCompleted,
  ]);

  useEffect(() => {
    if (
      designations.includes("superadmin") ||
      designations.includes("paymentmanager")
    ) {
      SocketConnected({ socketUserId: `${managerId}`, role: designation, warehouseNames: JSON.parse(localStorage.getItem("warehouse")) });
      socket.on("allreceipts", (updatedReceipts) => { 
        // console.log("allreceipts", updatedReceipts);
        setData((prev) => {
          const index = prev.findIndex(
            (order) => order.orderId === updatedReceipts.allrecipts[0].orderId
          );
          if (index !== -1) {
            const newupdatedOrders = [...prev];
            newupdatedOrders[index] = {
              ...newupdatedOrders[index],
              ...updatedReceipts.allrecipts[0],
            };
            return newupdatedOrders;
          } else {
            return [updatedReceipts.allrecipts[0], ...prev];
          }
        });
      });
      return () => {
        // socket.disconnect();
      };
    }
  }, [designation, designations, managerId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, 700);

    return () => clearTimeout(timer);
  }, [keyword]);

  const getReceiptData = async (receiptId, orderId, _case_, reason) => {
    setloading2(true);
    const apiData = {
      managerId: managerId,
      receiptId: receiptId,
      orderId: orderId,
    };
    try {
      const result = await fetchData("receipt/receipt", apiData);
      if (result.status === "success") {
        setloading2(false);
        if (_case_ === "click") {
          handleOpenModal(
            <AddReceiptModal
              mode={mode}
              handleCloseModal={handleCloseModal}
              accounts={result.accounts}
              receiptData={result.data}
              totalAmount={result.data.paymentAmount}
              updateReceiptData={(updatedData) => verifyReceipt(updatedData)}
              type="edit"
              flag="verify"
              revertCase={() => openRevertBackModal(result.data)}
            />
          );
        }
        if (_case_ === "update") {
          handleOpenModal(
            <AddReceiptModal
              mode={mode}
              handleCloseModal={handleCloseModal}
              accounts={result.accounts}
              receiptData={result.data}
              updateReceiptData={(updatedData) => verifyReceipt(updatedData)}
              type="update"
            />
          );
        }
        if (_case_ === "refund") {
          handleOpenModal(
            <RefundReceipt
              mode={mode}
              handleCloseModal={handleCloseModal}
              accounts={result.accounts}
              amount={result.data.paymentAmount}
              receiptId={result.data.receiptId}
              orderId={result.data.orderId}
              reason={reason ? reason : ""}
              receiptData={result.data}
              updateReceiptData={(updatedData) => refundReceipt(updatedData)}
            />
          );
        }
        if (_case_ === "cancel") {
          handleOpenModal(
            <CancelReceiptModal
              managerId={managerId}
              mode={mode}
              handleCloseModal={handleCloseModal}
              receiptData={result.data}
              receiptId={result.data.receiptId}
              orderId={result.data.orderId}
              revertCase={"false"}
              onActionCompleted={() => setActionCompleted((prev) => !prev)}
            />
          );
        }
      }
    } catch (error) {
      setloading2(false);
      //console.log("Error", error);
    }
  };

  const verifyReceipt = async (receiptData) => {
    const formData = new FormData();
    formData.append("managerId", managerId);
    formData.append("receiptId", receiptData.receiptId);
    formData.append("orderId", receiptData.orderId);
    formData.append(
      "paymentMethod",
      receiptData.paymentMethod ? receiptData.paymentMethod : ""
    );
    formData.append(
      "paymentType",
      receiptData.paymentType ? receiptData.paymentType : ""
    );
    formData.append(
      "accountNumber",
      receiptData.accountNumber ? receiptData.accountNumber : ""
    );
    formData.append(
      "transactionScreenshot",
      receiptData.transactionScreenshot ? receiptData.transactionScreenshot : ""
    );
    // formData.append(
    //   "transactionId",
    //   receiptData.transactionId ? receiptData.transactionId : ""
    // );
    if (
      (receiptData.paymentMethod !== "Cash" ||
        !(
          receiptData.paymentAmount === "POD" &&
          receiptData.paymentType === "Cash"
        )) &&
      receiptData.transactionId
    ) {
      formData.append("transactionId", receiptData.transactionId);
    }
    formData.append(
      "paymentAmount",
      receiptData.paymentAmount ? receiptData.paymentAmount : ""
    );
    try {
      const result = await handleFileData("receipt/verifyreceipt", formData);
      if (result.status === "success") {
        setActionCompleted(true);
        handleCloseModal();
        show(result.msg, "success");
      }
      if (result.status === "warn" || result.status === "warning") {
        show(result.msg, "warning");
      }
      if (result.status === "failed") {
        show(result.msg, "error");
      }
    } catch (error) {
      //console.log("error", error);
    }
  };

  const refundReceipt = async (receiptData) => {
    const formData = new FormData();
    formData.append("managerId", managerId);
    formData.append("receiptId", receiptData.receiptId);
    formData.append("orderId", receiptData.orderId);
    formData.append(
      "refundMethod",
      receiptData.paymentMethod ? receiptData.paymentMethod : ""
    );
    formData.append(
      "refundByAccount",
      receiptData.accountNumber ? receiptData.accountNumber : ""
    );
    formData.append(
      "transactionScreenshot",
      receiptData.transactionScreenshot ? receiptData.transactionScreenshot : ""
    );
    formData.append(
      "refundTransactionId",
      receiptData.transactionId ? receiptData.transactionId : ""
    );
    formData.append(
      "refundAmt",
      receiptData.paymentAmount ? receiptData.paymentAmount : ""
    );
    formData.append("reason", receiptData.reason ? receiptData.reason : "");
    try {
      const result = await handleFileData("receipt/refundreceipt", formData);
      if (result.status === "success") {
        setActionCompleted(true);
        handleCloseModal();
        show(result.msg, "success");
      }
      if (result.status === "warn" || result.status === "warning") {
        show(result.msg, "warning");
      }
      if (result.status === "failed") {
        show(result.msg, "error");
      }
    } catch (error) {
      //console.log("error", error);
    }
  };

  const openRevertBackModal = (receiptData) => {
    handleOpenModal(
      <CancelReceiptModal
        managerId={managerId}
        mode={mode}
        handleCloseModal={handleCloseModal}
        receiptData={receiptData}
        receiptId={receiptData.receiptId}
        orderId={receiptData.orderId}
        revertCase={"true"}
        onActionCompleted={() => setActionCompleted((prev) => !prev)}
      />
    );
  };
  // The customer, unfortunately, made an error in their order and selected the wrong item. They would like to cancel the order and request a full refund.
  const openUpdateModal = async (r) => {
    await getReceiptData(r.receiptId, r.orderId, "update", "");
  };

  const openRefundModal = async (r) => {
    await getReceiptData(r.receiptId, r.orderId, "refund", "");
  };

  const openCancelModal = async (r) => {
    await getReceiptData(r.receiptId, r.orderId, "cancel", "");
  };

  const handleRowClick = async (r) => {
    await getReceiptData(r.receiptId, r.orderId, "click", r.reason);
  };
  // const handleRowClick = async (r) => {
  //   if (r.status === "Pending For Refund") {
  //     await getReceiptData(r.receiptId, r.orderId, "refund", r.reason);
  //   } else {
  //   await getReceiptData(r.receiptId, r.orderId, "click", r.reason);
  //   }
  // };

  const openInfoModal = (status) => {
    handleOpenModal(
      <Info mode={mode} handleCloseModal={handleCloseModal} status={status} />
    );
  };

  const handleOpenModal = (content) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  // const daysTabChange = (label) => {
  //   const selectedFilter = daysData.find((item) => item.label === label);
  //   setDaysTab(selectedFilter || { label: "Today", value: "today" });
  // };

  const daysTabChange = (label) => {
    const selectedFilter = daysData.find((item) => item.label === label);
    const newDayTab = selectedFilter?.value || "today";

    setDaysTab(newDayTab);

    if (newDayTab !== "period") {
      // setStatus([]);
      setPages([]);
      setStartDate("");
      setEndDate("");
    }
  };

  const shippingTabChange = (label) => {
    const selectedFilter = shippingData.find((item) => item.label === label);
    setShipingTab(selectedFilter || { label: "All", value: "all" });
  };

  const handleManagerSelection = (option) => {
    setSelectedManagerID(option.value);
  };

  const handleManagerRejection = () => {
    setSelectedManagerID(null);
  };

  const getStatusColor = (status) => {
    if (status === "Received") {
      return "#31974D";
    } else if (status === "Cancelled") {
      return "#F93E3E";
    } else if (status.includes("Pending")) {
      return "#FF9B04";
    } else if (status === "Refund") {
      return "#F56221";
    } else {
      return "";
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "Invalid Date";

    const [day, month, year] = dateString.split("-");
    const formattedDate = `${year}-${month}-${day}`;

    const date = new Date(`${formattedDate}T00:00:00`);

    if (isNaN(date)) {
      console.error("Invalid date:", dateString);
      return "Invalid Date";
    }

    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const receiptStatus = [
    {
      status: "All",
      count: receiptState[0]?.count || 0,
    },
    {
      status: "Pending",
      count: receiptState[1]?.count || 0,
    },
    {
      status: "POD Pending",
      count: receiptState[2]?.count || 0,
    },
    {
      status: "Pending For Verification",
      count: receiptState[3]?.count || 0,
    },
    {
      status: "Pending For Refund",
      count: receiptState[4]?.count || 0,
    },
    {
      status: "Refund",
      count: receiptState[5]?.count || 0,
    },
    {
      status: "Cancelled",
      count: receiptState[6]?.count || 0,
    },
    {
      status: "Cash Pending at Partner",
      count: receiptState[7]?.count || 0,
    },
    {
      status: "Received",
      count: receiptState[8]?.count || 0,
    },
  ];

  let daysData = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "month", label: "This Month" },
    { value: "period", label: "Period" },
  ];

  if (designations.includes("superadmin")) {
    daysData.splice(daysData.length - 1, 0, {
      value: "previousyear",
      label: "Previous Year",
    });
  }

  const shippingData = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "cash",
      label: "Cash",
    },
    {
      value: "photo",
      label: "Photo",
    },
    {
      value: "pod",
      label: "POD",
    },
    {
      value: "online",
      label: "Online",
    },
  ];

  const receiptHeader = [
    "Receipt ID",
    "Order ID",
    "Time & Date",
    "Name & City",
    "Dealer ID",
    "Order Manager",
    "Created By",
    "Dispatch Status",
    "Delivery Partner",
    "Amount",
    "Method",
    "Status",
    "Receiver",
    "Last Action",
  ];

  const canViewOrder =
    designations.includes("superadmin") ||
    designations.includes("ordermanager") ||
    designations.includes("inventorymanager") ||
    designations.includes("paymentmanager");

  const routeName = "Receipts";

  // console.log("can view order ", canViewOrder);

  return (
    <>
      <div
        className={styles.receipts}
        style={
          mode ? { backgroundColor: "#2C2E33", borderColor: "#2C2E33" } : {}
        }
      >
        <ModalWrapper
          isOpen={isModalOpen || loading2}
          onClose={handleCloseModal}
          noBtn
        >
          {loading2 ? (
            <p style={mode ? { color: "#FFFFFF" } : {}}>Loading...</p>
          ) : (
            modalContent
          )}
        </ModalWrapper>
        <div className={styles.receipts_top}>
          <div className={styles.receipts_top_left}>
            <TopTabBar text={routeName} />
          </div>
          <div className={styles.receipts_right}>
            <div>
              {/* <SlidingFilter2
                itemList={daysData.map((item) => item.label)}
                setFilter={daysTabChange}
                selectedItem={daysTab.label}
              /> */}

              <SlidingFilter2
                itemList={daysData.map((item) => item.label)}
                setFilter={daysTabChange}
                selectedItem={
                  daysData.find((item) => item.value === daysTab)?.label
                }
              />
            </div>
            {daysTab === "period" && (
              <>
                <div>
                  <InputTypedate
                    text={"From"}
                    value={startDate}
                    max={endDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      if (daysTab !== "period") {
                        setDaysTab("");
                      }
                      setStatus("");
                    }}
                  />
                </div>
                {startDate !== "" && (
                  <div>
                    <InputTypedate
                      text={"To"}
                      value={endDate}
                      min={startDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        if (daysTab !== "period") {
                          setDaysTab("");
                        }
                        setStatus("");
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div>
              <Button
                buttonIcon={<DispatchReloadIcon />}
                buttonBgColor={"#000000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                //   buttonClick={() => resetFilters()}
              />
            </div>
            <div>
              <Button
                buttonIcon={<DispatchDownloadIcon />}
                buttonBgColor={"#000000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
              />
            </div>
          </div>
        </div>

        <div style={{ padding: "0 10px 0 10px" }}>
          <div
            className={styles.receipts_filters}
            style={
              mode ? { borderColor: "#232529", backgroundColor: "#232529" } : {}
            }
          >
            <>
              {receiptStatus?.map((s) => (
                <div
                  key={s.status}
                  className={`${styles.receipts_filter} ${
                    mode ? styles.receipts_filter_dark : ""
                  } ${
                    s.status === status
                      ? mode
                        ? styles.receipts_filter_active_dark
                        : styles.receipts_filter_active
                      : ""
                  }`}
                  onClick={() => {
                    setStatus(s.status);
                    setPage(1);
                    // console.log(s.status);
                  }}
                >
                  <DealerStatusCard
                    statusText={s.status}
                    statusItemCount={s.count}
                  />
                </div>
              ))}
            </>
          </div>
        </div>

        <div className={styles.receipts_bottom}>
          <div className={styles.receipts_bottom_header}>
            <div>
              <SearchBar
                padding={"6px"}
                fontSize={"12px"}
                fontWeight={"400"}
                borderRadius={"5px"}
                placeholder={"Receipt / Order ID / UTR / RRN"}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                  setPage(1);
                }}
              />
            </div>

            <div className={styles.receipts_bottom_filters}>
              <div>
                <SlidingFilter2
                  itemList={shippingData.map((item) => item.label)}
                  setFilter={shippingTabChange}
                  selectedItem={shipingTab.label}
                />
              </div>
              <DropDown
                label={"Account"}
                listLabel={"All"}
                // options={voucherOptions.map((d) => ({
                //   label: d,
                //   value: d,
                // }))}
                options={[
                  { label: "Test1", value: "test1" },
                  { label: "Test2", value: "test2" },
                ]}
                // onSelect={handleWarehouseSelection}
                // onReject={handleWarehouseRejection}
              />
              <DropDown
                label={"Manager"}
                listLabel={"All"}
                options={
                  managers &&
                  managers.map((m) => ({ label: m.name, value: m.managerId }))
                }
                onSelect={handleManagerSelection}
                onReject={handleManagerRejection}
              />

              {/* <DropDown
                  label={"Sort By"}
                  listLabel={"All"}
                  // options={warehouses}
                  // onSelect={handleWarehouseSelection}
                  // onReject={handleWarehouseRejection}
                /> */}
            </div>
          </div>

          <ul className={styles.receipts_data}>
            <li
              className={`${styles.receipts_data_header} ${
                mode ? styles.receipts_data_header_dark : ""
              }`}
            >
              {receiptHeader.map((h, index) => (
                <div className={styles.entry} key={`${h}-${index}`}>
                  {h}
                </div>
              ))}
            </li>
            <div className={styles.receipts_data_list}>
              {loading ? (
                <div>
                  <Skeleton
                    lines={15}
                    thickness={5}
                    additionalStyles={{ marginBottom: "0px", marginTop: "0px" }}
                  />
                </div>
              ) : (
                <>
                  {!loading && data.length === 0 ? (
                    <div className={styles.no_data}>
                      <NoDataFound
                        icon={<TemplateNotFound />}
                        textTitle={`No Data Found`}
                      />
                    </div>
                  ) : (
                    <>
                      {data.map((r, index) => (
                        <div key={index}>
                          <li
                            className={`${styles.receipts_data_row} ${
                              mode ? styles.receipts_data_row_dark : ""
                            }`}
                            onClick={() => {
                              handleRowClick(r);
                            }}
                          >
                            <div className={styles.entry}> {r.receiptId} </div>
                            <div className={styles.entry}>
                              <span
                                style={{
                                  textDecoration: canViewOrder && "underline",
                                }}
                                onClick={(e) => {
                                  if (canViewOrder) {
                                    e.stopPropagation();
                                    navigate(
                                      "/orders/order/saleorder?orderId=" +
                                        r.orderId
                                    );
                                  }
                                }}
                              >
                                {r.orderId}
                              </span>
                            </div>
                            <div className={styles.entry}>
                              {r.receiptTime} , {formatDate(r.receiptDate)}
                            </div>
                            {/* <div className={styles.entry}>{r.receiptDate}</div> */}
                            <div className={styles.entry}>
                              {r.storeName}, {r.city}
                            </div>
                            <div className={styles.entry}>{r.dealerId} </div>
                            <div className={styles.entry}>
                              {r.ordermanager}{" "}
                            </div>
                            <div className={styles.entry}>{r.createdBy} </div>
                            {/* <div className={styles.entry}>Created By</div> */}
                            <div className={styles.entry}>
                              {r.dispatchStatus}
                            </div>
                            <div className={styles.entry}>{r.partnerName} </div>
                            <div className={styles.entry}>
                              {r.paymentAmount}
                            </div>
                            <div className={styles.entry}>
                              {r.paymentMethod}
                            </div>
                            <div
                              className={styles.entry}
                              style={{ color: getStatusColor(r.status) }}
                            >
                              {r.status}
                            </div>
                            <div className={styles.entry}> {r.verifyBy} </div>
                            <div className={styles.entry}> {r.verifyTime} </div>
                            <div
                              className={styles.options}
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveMenuIndex((prevIndex) =>
                                  prevIndex === index ? null : index
                                );
                              }}
                            >
                              <ManagerDevices3Dots
                                color={mode ? "#ffffff" : "#1C274C"}
                              />
                            </div>

                            {activeMenuIndex === index && (
                              <OptionsMenu
                                mode={mode}
                                closeMenu={() => setActiveMenuIndex(null)}
                                index={index}
                                receiptData={r}
                                openInfoModal={openInfoModal}
                                openCancelReceiptModal={openCancelModal}
                                openUpdateModal={openUpdateModal}
                                openRefundModal={openRefundModal}
                              />
                            )}
                          </li>
                          {r.reason !== "" && (
                            <div
                              style={mode ? { color: "#FFFFFF" } : {}}
                              className={styles.desc}
                            >
                              {r.reason}
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </ul>
        </div>

        <div
          className={styles.pagination}
          style={mode ? { backgroundColor: "#232529" } : {}}
        >
          <div style={{ width: "50%" }}>
            <div className={styles.go_to_page}>
              <GoToPage
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={pages}
                key={"1"}
              />
            </div>
          </div>
          <div className={styles.pages}>
            <Pagenation
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={pages}
              key={"1"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Receipts;
