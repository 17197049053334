import React from "react";
import styles from "./FromComponent.module.css";
import { useAuth } from "../../context/Auth";

const InputTypeChack = ({ lable, onChange, checked }) => {
  // const handechange = (e)=>{
  //     const isChecked = e.target.checked;
  //     //console.log(isChecked);

  // }
  const { mode } = useAuth();
  return (
    <div className={styles.checkboxWrapper}>
      <label>
        <input
          type="checkbox"
          style={
            mode
              ? { backgroundColor: "#1b1d21", marginLeft: "5px" }
              : { backgroundColor: "#fff", marginLeft: "5px" }
          }
          onChange={onChange}
          checked={checked}
        />
        <span
          className={styles.checkbox}
          style={{ marginLeft: "5px", fontSize: "13px", fontWeight: "400" }}
        >
          {" "}
          {lable}
        </span>
      </label>
    </div>
  );
};

export default InputTypeChack;
