import React, { useEffect, useState } from "react";
import { useAuth } from "../../components/context/Auth";
import {
  DispatchReloadIcon,
  // ReloadIcon,
  // TestTopBackArrowIcon,
  // TestTopDotIcon,
} from "../../components/icons/Icon";
import styles from "./Admin.module.css";
import styles1 from "../../components/updatedComponents/Test/Test.module.css";

import PaginationComponent from "../../components/updatedComponents/Pagnation/Pagenation";
import { useNavigate, useSearchParams } from "react-router-dom";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import SlidingFilter from "../../components/updatedComponents/Dealers/SlidingFilter";
import Button from "../../components/updatedComponents/Dealers/Button";
import { Download } from "../../components/icons/Icon4";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
// import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";

import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
// import { io } from "socket.io-client";
import ToggleSearchBar from "../../components/updatedComponents/FormComponents/ToggleSearchBar";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import { socket, SocketConnected } from "../../Socket/socket";
const DispatchDasboard = () => {
  const slidingFilter2 = [
    "All",
    "Sales",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];
  const itemList = [
    " All Dealer",
    "Today",
    "Yesterday",
    "This Month",
    "Last 30 Days",
  ];

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { managerId, designation, mode } = useAuth();
  const [data, setData] = useState([]);
  const [daysTab, setDaysTab] = useState("today");
  // const [shipingTab, setShipingTab] = useState("all");
  // const [cartTab, setCartTab] = useState("all");
  const [orders, setOrders] = useState([]);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

  const [keyword, setKeyword] = useState("");
  // const [sortKey, setSortKey] = useState("");
  const [loading, setLoading] = useState(false);
  // const [orderStatus, setOrderStatus] = useState("");
  // const [warehouseName, setWarehouseName] = useState("");
  // const [managerName, setManagerName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [managers, setManagers] = useState([]);
  const [selectedManagerID, setSelectedManagerID] = useState("");
  const [selectorderStatus, setSelectOrderStatus] = useState("All");
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [filter2, setFilter2] = useState(false);

  // const [state, setState] = React.useState({
  //   totalPages: "",
  //   currentPage: 1,
  // });

  const options = managers.map(manager => ({
    label: manager.name,
    value: manager.managerId
  }));
  const optionsWhaer = warehouses?.map(Whaer => ({
    label: Whaer.warehousename,
  }));


  const dayOptions = [
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "month",
      label: "This Month",
    },
    {
      value: "previousyear",
      label: "Previous Year",
    },
  ];
  const orderHeader = [
    "Name & City",
    "Type",
    "Date",
    "Order ID",
    "createdBy",
    "Packer",
    "Order Manager",
    "Source",
    "Sub-Method",
    "Delivery Parther",
    "Status",
    "Last Action",
  ];
  const handleDayChange = (label) => {
    const selectedFilter = dayOptions.find((item) => item.label === label);
    setDaysTab(selectedFilter?.value || "today");
  };

  const handleReload = () => {

    setDaysTab("today")
    setSelectedManagerID("");
    setReloadTrigger((prev) => !prev);
  };
  const handleWarehouseSelection = (option) => {
    setSelectedWarehouse(option.label);
  };

  const handleWarehouseRejection = () => {
    setSelectedWarehouse(null);
  };



  const handleOrderSelection = (option) => {
    setSelectOrderStatus(option.label);
  };
  const handleOrderRejection = () => {
    setSelectOrderStatus(null);
  };

  const handleManagerSelection = (optionis) => {
    setSelectedManagerID(optionis.value);
  };

  const handleManagerRejection = () => {
    setSelectedManagerID(null);
  };




  useEffect(() => {
    if (managerId) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: parseInt(managerId),
        keyword: keyword,
        orderStatus: selectorderStatus,
        sortKey: "",
        shippingType: "all",
        cartType: "all",
        warehousename: selectedWarehouse,
        disPatchManagerId: selectedManagerID,
        day: daysTab,
        startDate: startDate,
        endDate: endDate,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_URL}dispatch/allOrders?page=${page}&limit=20`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setPage(result?.page);
            setPages(result?.pages);
            setLoading(false);
            setData(result.data);
            setOrders(result.orders);
            if (result.warehouses) {
              setWarehouses(result.warehouses);
            }
            if (result.managers) {
              setManagers(result.managers);
            }
          }
          if (result.pages) {
            // setState((prevState) => ({
            //   ...prevState,
            //   totalPages: result.pages ? result.pages : "",
            // }));
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [
    managerId,
    // sortKey,
    keyword,
    page,
    // orderStatus,
    // shipingTab,
    // cartTab,
    // warehouseName,
    // managerName,
    daysTab,
    startDate,
    endDate,
    selectedManagerID,
    selectedWarehouse,
    selectorderStatus,
    reloadTrigger,
  ]);
  useEffect(() => {
    SocketConnected({ socketUserId: `${managerId}`, role: designation, warehouseNames: JSON.parse(localStorage.getItem("warehouse")) });
    socket.on("allorders", (allorders) => {
      setData((prev) => {
        const newOrder = allorders.allOrders[0];
        if (designation === "superadmin") {
          const index = prev.findIndex((order) => order.orderId === newOrder.orderId);
          if (index !== -1) {
            const updatedOrders = [...prev];
            updatedOrders[index] = { ...updatedOrders[index], ...newOrder };
            return updatedOrders;
          } else {
            return [newOrder, ...prev];
          }
        } else {
          if (newOrder.dmId === parseInt(managerId)) {
            const index = prev.findIndex((order) => order.orderId === newOrder.orderId);
            if (index !== -1) {
              const updatedOrders = [...prev];
              updatedOrders[index] = { ...updatedOrders[index], ...newOrder };
              return updatedOrders;
            } else {
              return [newOrder, ...prev];
            }
          } else {
            return prev.filter((order) => order.orderId !== newOrder.orderId);
          }
        }
      });


    });
    return () => {
      // socket.disconnect();
      // socket.off("allorders");
    };
  }, [designation, managerId]);

  return (

    <React.Fragment>
      <div
        className={styles.mainContiner} >
        <div
          className={`${styles.container3} ${mode ? styles.darkMode : styles.lightMode}`}
          style={{   }}
        >
          <div className={styles.dispatchTopContainer}>

            <div
              className={styles1.dispatch_top_left}
              style={{

              }}
            >
              <div
                className={styles1.dispatch_top_left_go_back}
              >
                <TopTabBar text={"Dispatch"} />

              </div>

            </div>
            <div className={styles.slidingFilterDiv}>
              <div className={styles.SlidingFilterdiv1}>
                <SlidingFilter2
                  itemList={dayOptions.map((item) => item.label)}
                  setFilter={handleDayChange}
                  selectedItem={dayOptions.find((item) => item.value === daysTab)?.label}
                />
                <div className={styles.dateInputsDiv}>
                  <div>
                    <InputTypedate
                      text={"From"}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        setDaysTab("");
                      }}
                    />
                  </div>
                  <div>
                    <InputTypedate
                      text={"To"}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        setDaysTab("");
                      }}
                    />
                  </div>
                </div>
                <div className={styles.reloadAndDownloadButtonDiv}>
                  <div className={styles.reload_anddownloadbuttondiv}             >

                    <Button
                      buttonIcon={<DispatchReloadIcon />}
                      buttonBgColor={"#000000"}
                      buttonTextColor={"#ffffff"}
                      buttonBorder={"none"}
                      buttonClick={handleReload
                      }
                    />
                    <div>
                      <Button
                        style={{
                          padding: "8px 20px",
                          alignItems: "center",
                        }}
                        buttonBgColor={"#4164E3"}
                        buttonIcon={<Download />}
                        buttonTextColor={"#fff"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles}>
            <div
              className={styles.statusCard_div}
              style={{
                backgroundColor: mode ? "#232529" : "white",
                minWidth: "100%",
                maxWidth: "100%",
                overflowX: "scroll"
              }}
            >
              {orders &&
                orders.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.order_filter} ${mode ? styles.order_filter_dark : ""
                      } ${item.status === selectorderStatus ? styles.order_filter_active : ""
                      }`}
                    onClick={() => {
                      setSelectOrderStatus(item.status);
                      setPage(1)
                      if (searchParams.has("page")) {
                        const token = searchParams.get("page");
                        if (token) {
                          searchParams.delete("page");
                          setSearchParams(searchParams);
                        }
                      }
                    }}
                    style={{ maxWidth: "160px" }}>
                    <DealerStatusCard
                      dealerStatus={item.status}
                      statusItemCount={item.count}
                      statusText={item.status} />
                  </div>
                ))}
              <span
                className={
                  itemList.status === "New Order"
                    ? styles.statusNew
                    : itemList.status === "In Progress"
                      ? styles.statusYellow
                      : itemList.status === "Packed"
                        ? styles.statusBlue
                        : itemList.status === "All"
                          ? styles.statusAll
                          : itemList.status === "Pending"
                            ? styles.statusRed
                            : itemList.status === "Dispatched"
                              ? styles.statusGreen
                              : itemList.status === "Cancelled"
                                ? styles.statusWhite
                                : ""
                }   >
                {itemList.count}
              </span>
              <span className={styles.statusTxt}>{itemList.status}</span>
            </div>
            <div className={styles.search}
            >
              <div  >
                <ToggleSearchBar
                  placeholder={"Search by Order ID"}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onToggle={(isExpanded) => setIsSearchBarOpen(isExpanded)}

                />

              </div>
              <div
                className={{ styles }}
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}  >
                {!isSearchBarOpen && (
                  <div className={styles.hideOnSmallScreen}>
                    <SlidingFilter
                      itemList={slidingFilter2}
                      setFilter={setFilter2}
                    />
                  </div>)}
                <div style={{
                  display: "flex",
                  gap: "5px"

                }}>  <DropDown
                    label={"Warehouse"}
                    listLabel={"All"}
                    options={optionsWhaer}
                    onSelect={handleWarehouseSelection}
                    onReject={handleWarehouseRejection}
                  />
                  <DropDown
                    label={"Manager"}
                    listLabel={"All"}
                    options={options}
                    onSelect={handleManagerSelection}
                    onReject={handleManagerRejection}
                  />
                  <DropDown
                    label={"Sort By"}
                    listLabel={"View All"}
                    options={orders.map((o) => ({
                      label: o.status,
                    }))}
                    onSelect={handleOrderSelection}
                    onReject={handleOrderRejection}
                  /></div>
              </div>

            </div>
            <div className={styles.tablemain}>
              <ul className={styles.order_data_list}>
                <li
                  className={`${styles.order_data_list_row_header} ${mode ? styles.order_data_list_row_header_dark : ""
                    }`}
                  style={{

                    padding: "10px",
                    // gap:"2px",
                    backgroundColor: mode ? "#232529" : "#D9DCE5",
                  }}
                >

                  {orderHeader.map((h) => (
                    <div style={{
                      color: mode ? "#FFFFFF" : "#646B88",
                      fontWeight: "600",
                      marginRight: "40px"
                    }}
                      className={styles.entry} key={h}>
                      {h}
                    </div>
                  ))}

                </li>
                <div className={styles.order_data_list_container}>
                  {loading ?
                    <div style={{ margin: "1rem auto" }}>
                      <Spinner height={80} width={80} />
                    </div>
                    :
                    data?.length === 0 ? (
                      <img
                        src="/assets/updatedAssets/noDATA.png"
                        alt="img"
                        style={{
                          height: "12rem",
                          width: "16rem",
                          margin: "1rem auto",
                        }}
                      />
                    ) :

                      <>

                        {data.map((d, i) => (
                          <li
                            style={{

                              backgroundColor: mode ? "#1C1D21" : "#fff",
                              gap: "10px",

                            }}
                            key={i}
                            className={`${styles.order_data_list_row} ${mode ? styles.order_data_list_row_dark : ""
                              }`}
                            onClick={() => navigate("/dispatchorders/" + d.orderId)}
                          >

                            <div className={styles.entry}>    {d.storeName + ", " + d.city}</div>
                            <div className={styles.entry}>{""}</div>
                            <div className={styles.entry}>{d.orderDate}</div>
                            <div style={{ paddingLeft: "10px" }} className={styles.entry}>{d.orderId}</div>

                            <div className={styles.entry}>{d.createdBy}</div>
                            <div className={styles.entry}>{d.packinghManager}</div>
                            <div className={styles.entry}>{d.ordermanager}</div>
                            <div style={{ paddingLeft: "12px" }} className={styles.entry}>{d.orderType}</div>
                            <div className={styles.entry}>{d.methodName}</div>
                            <div className={styles.entry}>{d.partnerName}</div>
                            <div
                              className={`${styles.entry} ${d.status === "Dispatched"
                                ? styles.table_txt_green
                                : d.status === "Packed"
                                  ? styles.table_txt_blue1
                                  : ["Not Packed", "Request For Edit", "Request For Cancel"].includes(d.status)
                                    ? styles.table_txt_red
                                    : d.status === "In Progress"
                                      ? styles.table_txt_yellow
                                      : d.status === "Pending"
                                        ? styles.table_txt_Pending
                                        : d.status === "New Order"
                                          ? styles.table_txt_blue
                                          : d.status === "Cancelled"
                                            ? styles.table_txt_red
                                            : ""
                                }`}
                              style={{ padding: "10px" }}
                            >
                              {d.status}
                            </div>
                            <div style={{ paddingRight: "10px" }} className={styles.entry}>{d.lastAction}</div>
                          </li>
                        ))}
                      </>
                  }
                </div>


              </ul>
            </div>
          </div>
        </div>
        {data.length !== 0 ? (
          <div className={styles.paginationdiv}
            style={{
              backgroundColor: mode ? "#232529" : "#FFFFFF",
              // backgroundColor: mode ? "#1B1D21" : "#FFFFFF",
            }}    >
            <div className={styles.page1}
            >
              <GoToPage
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={pages}
                key={"1"}
              />
            </div>
            <div className={styles.page2}>
              <PaginationComponent
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={pages}
                key={"2"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>

  );
};

export default DispatchDasboard;