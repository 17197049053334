// import React, { useState, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
// import { useAuth } from '../../components/context/Auth'
// import { DeleteIcon, Edit, Setting, View } from '../../components/icons/Icon'
// import Modal from '../../components/Modal'
// import AdvancedSettings from './AdvancedSettings'
// import styles from './Product.module.css'

// const Products = () => {
//     const navigate = useNavigate()
//     const { managerId } = useAuth()
//     const [showModal, setShowModal] = useState(false)
//     const [productData, setProductData] = useState([])
//     const [loading, setLoading] = useState(false)
//     const [showDeleteProductModal, setShowDeleteProductModal] = useState(false)
//     const [deleteProductId, setDeleteProductId] = useState('')
//     const [deleteProductTrue, setDeleteProductTrue] = useState(false)
//     const [deleteProductName, setDeleteProductName] = useState('')
//     const selectModal = () => {
//         setShowModal(!showModal)
//     }

//     useEffect(() => {
//         setLoading(true)
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//         var raw = "";

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "content/products", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     setLoading(false)
//                     setProductData(result.data)
//                 }
//             })
//             .catch(error => //console.log('error', error));
//     }, [deleteProductTrue])

//     const handleDeleteProductItem = (deleteProductId) => {
//         if (managerId) {
//             setDeleteProductTrue(false)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "_id": deleteProductId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "content/deleteProduct", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     // alert(result.msg)
//                     if (result.status === 'success') {
//                         setDeleteProductTrue(true)
//                     }
//                 })
//                 .catch(error => //console.log('error', error));
//         }
//     }

//     return (
//         <React.Fragment>
//             <AdvancedSettings
//                 displayModal={showModal}
//                 closeModal={selectModal}
//             />

//             <Modal
//                 show={showDeleteProductModal}
//                 close={setShowDeleteProductModal}
//                 closeBtn={true}
//                 heading={deleteProductName&&deleteProductName}
//                 content={
//                     <div className={styles.text_center}>
//                         <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteProductName&&deleteProductName}</p></div>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteProductModal(false)} />
//                             <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteProductItem(deleteProductId); setShowDeleteProductModal(false) }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <div className={styles.product_main_div}>
//                 <div className={styles.product_header}>
//                     <h1 className={styles.heading}>Products</h1>
//                     <div>
//                         Sort By
//                         <select className={styles.product_filter}>
//                             <option className={styles.bg_dark}>View All</option>
//                             <option className={styles.bg_dark}>Active</option>
//                             <option className={styles.bg_dark}>Disabled</option>
//                             <option className={styles.bg_dark}>Deleted</option>
//                             <option className={styles.bg_dark}>Ascending</option>
//                             <option className={styles.bg_dark}>Descending</option>
//                         </select>

//                         <GreenOutButton type="button" title="+ ADD PRODUCT" css="" handleSubmit={() => navigate('/addproduct?step=0')} />
//                     </div>
//                 </div>
//                 <div className={styles.product_scroll_div}>

//                     {
//                         loading ? <div className={'loadingMainDealer'}>
//                             <img src='/wallicon.gif' alt='walliconGIF' />
//                         </div> :
//                             productData && productData.map((item, index) =>
//                                 <div className={styles.productRow} key={index}>
//                                     <div className={styles.w_100} onClick={() => navigate('/product/' + item.product_name.replace(/\s/g, "-").toUpperCase() + '?product_id=' + item._id)}>
//                                         <input type="radio" defaultChecked={item.status === "Active" ? true : false} /> <span className={styles.muted_clr}>Active</span>
//                                         <h1>
//                                             {item.product_name}
//                                         </h1>
//                                     </div>
//                                     <div className={styles.productBtns}>
//                                         <div className={styles.productsEVS} onClick={() => { setShowDeleteProductModal(true); setDeleteProductId(item._id); setDeleteProductName(item.product_name)}}>
//                                             <div><DeleteIcon /></div>
//                                             <span>Delete</span>
//                                         </div>
//                                         <div className={styles.productsEVS} onClick={selectModal}>
//                                             <div><Setting /></div>
//                                             <span>Settings</span>
//                                         </div>
//                                         <div className={styles.productsEVS}
//                                             onClick={(e) => { navigate('/addproduct?step=1&id=' + item._id); localStorage.setItem("viewEdit", 1) }}>
//                                             <div><Edit color="#ffffff" /></div>
//                                             Edit
//                                         </div>
//                                         <div className={styles.productsEVS} onClick={() => { navigate('/addproduct?step=2&id=' + item._id); localStorage.setItem("viewEdit", 0) }}>
//                                             <div><View /></div>
//                                             View
//                                         </div>
//                                     </div>
//                                 </div>
//                             )}
//                 </div>
//             </div>

//         </React.Fragment>
//     )
// }

// export default Products

import React, { useEffect, useState } from "react";
import styles from "./Product.module.css";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import { useAuth } from "../../components/context/Auth";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import Button from "../../components/updatedComponents/Dealers/Button";
import {
  AddTO,
  DeleteTo,
  EditeTo,
  SettingTo,
  ViewTo,
} from "../../components/icons/Icon3";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";
import fetchData from "../../APIsControll/apiControll";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";
import NoDataFound from "../../components/updatedComponents/NoDataFound/NoDataFound";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import ManagerModal from "../../components/updatedComponents/Manager/ManagerModal";
import { TextColorCode } from "../../Utils/TextUtils";
import { useNavigate } from "react-router-dom";
import { TemplateNotFound } from "../../components/icons/Icon2";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
// import useWindowWidth from '../../components/updatedComponents/Hooks/WidthSize';

const Products = () => {
  const { mode, managerId } = useAuth();
  const { show } = useToast();
  const navi = useNavigate();
  const [loading, setLoading] = useState({
    productLoading: false,
    productDelete: false,
  });
  const [sortBy, setSortBy] = useState(null);
  const [productSearch, setProductSerach] = useState('');

  // const width = useWindowWidth();
  const [toggle, setToggle] = useState({
    modelToggle: false,
  });
  const [ProductData, setProductData] = useState([]);
  const [modelContainer, setModeContiner] = useState(null);
  const [filteredData, setFilteredData] = useState([]); // Filtered data

  // const filteredAndSortedData = () => {
  //   let filteredData = ProductData;

  //   // Filter by search query
  //   if (productSearch) {
  //     console.log("=>",productSearch);
      
  //     filteredData = filteredData.filter(product =>
  //       product.product_name.toLowerCase().includes(productSearch.toLowerCase())
  //     );
  //   }

  //   // Sort by product name or status if sortBy is set
  //   if (sortBy) {
  //     filteredData = filteredData.sort((a, b) => {
  //       if (a[sortBy] < b[sortBy]) return -1;
  //       if (a[sortBy] > b[sortBy]) return 1;
  //       return 0;
  //     });
  //   }

  //   return filteredData;
  // };

  // useEffect(() => {
  //   const result = filteredAndSortedData();
  //   setProductData(result);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [productSearch, sortBy]);


  // get all products from
 
  const filterAndSort = () => {
    let data = [...ProductData];   // Original data se filter karega

    // Search filter
    if (productSearch) {
      data = data.filter((product) =>
        product.product_name.toLowerCase().includes(productSearch.toLowerCase())
      );
    }

    // Sorting
    if (sortBy) {
      data.sort((a, b) => {
        if (sortBy === "status") {
          // Custom status sorting (Active > Inactive)
          return a.status.localeCompare(b.status);  
        } else {
          // Default sorting by name or other fields
          if (a[sortBy] < b[sortBy]) return -1;
          if (a[sortBy] > b[sortBy]) return 1;
          return 0;
        }
      });
    }

    setFilteredData(data);  // Filtered state me result daal do
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  useEffect(() => {
    filterAndSort();
  }, [productSearch, sortBy]); 


  const getAllProduct = async () => {
    try {
      setLoading({ ...loading, productLoading: true });
      const res = await fetchData(`content/products`);
      if (res.status === "success") {
        // setProductData(res.data);
        setProductData(res.data);       // Original data
        setFilteredData(res.data); 
        return;
      }
      if (res.status === "failed") {
        show(res.msg, "error");
      }
    } catch (error) {
      show("Error fetching products", "error");
    } finally {
      setLoading({ ...loading, productLoading: false });
    }
  };

  // Delete product by Id
  const DeleteProduct = async (id) => {
    try {
      const payload = {
        managerId: managerId,
        _id: id,
      };
      setLoading({ ...loading, productDelete: true });
      const res = await fetchData(`content/deleteProduct`, payload);
      if (res.status === "success") {
        show(res.msg, "success");
        getAllProduct();
        return;
      }
      if (res.status === "failed") {
        show(res.msg, "error");
        return;
      }
    } catch (error) {
      show("Something went wrong", "error");
    } finally {
      setLoading({ ...loading, productDelete: false });
    }
  };

  useEffect(() => {
    getAllProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerId]);

  const handleSetModel = (con) => {
    setToggle({ ...toggle, modelToggle: true });
    setModeContiner(con);
  };

  return (
    <div className={styles.Main_Layout}>
      <ModalWrapper
        isOpen={toggle.modelToggle}
        onClose={() =>
          setToggle({ ...toggle, modelToggle: !toggle.modelToggle })
        }
      >
        {modelContainer}
      </ModalWrapper>

      <div
        className={styles.Layout}
        style={{ backgroundColor: mode ? "#2B2D31" : "#F8F9FC" }}
      >
        <div className={styles.Top_bar_css}>
          <div className={styles.gobackClass}>
            <TopTabBar text={"Product"} />
          </div>
          <div className={styles.btn_Continer}>
            <div>
              <InputTag
                placeholderText={"Search Product"}
                placeholderType={"text"}
                padding={"10px"}
                fontSize={"12px"}
                maxLength={20}
                // onChange={(e) => setProductSerach(e)}
                onChange={(e) => setProductSerach(e.target.value)}
              />
            </div>
            <div>
              {" "}
              <DropDown
                label={"Sort By"}
                options={[
                  { label: "Active", value: "Active" },
                  { label: "Disabled", value: "Disabled" }
                ]}
                onSelect={(e) => setSortBy(e.value)}
                onReject={() => setSortBy(null)}
              />{" "}
            </div>
            <div>
              <Button
                buttonText={"Add Product"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#fff"}
                buttonIcon={<AddTO />}
                buttonClick={() => navi(`/products/addproduct`)}
              />
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <ul className={styles.responsiveTable}>
            <li
              className={styles.tableHeader}
              style={
                mode
                  ? { backgroundColor: "#232529", color: "#fafafa" }
                  : { backgroundColor: "#D9DCE5" }
              }
            >
              <div className={styles.col}>Status</div>
              <div className={styles.col}>Product Name</div>
              <div className={styles.col}>Delete</div>
              <div className={styles.col}>Setting</div>
              <div className={styles.col}>Edit</div>
              <div className={styles.col}>View</div>
            </li>
            <div className={mode? styles.Table_row : styles.Table_row_light}>
              {loading.productLoading ? (
                <Skeleton lines={10} thickness={5} />
              ) : filteredData.length === 0 ? (
                <NoDataFound icon={<TemplateNotFound />} />
              ) : (
                filteredData.map((item) => (
                  <li
                    className={styles.tablerow}
                    style={
                      mode
                        ? { backgroundColor: "#1B1D21", color: "#fafafa" }
                        : { backgroundColor: "#ffff" }
                    }
                    onClick={() => {
                      navi(
                        `/products/product/${item?.product_name}?product_id=${item?._id}`
                      );
                    }}
                    key={item._id}
                  >
                    <div
                      className={styles.col}
                      data-label="Status"
                      style={{ color: TextColorCode(item?.status) }}
                    >
                      {" "}
                      {item?.status}
                    </div>
                    <div
                      className={styles.col}
                      data-label="Product Name"
                      style={{ color: mode ? "#fff" : "#000" }}
                    >
                      {item?.product_name}
                    </div>
                    <div
                      className={styles.col}
                      data-label="Delete"
                      style={{ color: mode ? "#fff" : "#000" }}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSetModel(
                            <>
                              <ManagerModal
                                heading={"Delete Product"}
                                centerHeading={
                                  "Are you sure you want to Delete?"
                                }
                                subHeading={
                                  "To confirm! type delete in the text field."
                                }
                                targetWord={"delete"}
                                handleSubmit={() => {
                                  DeleteProduct(item._id);
                                }}
                                handleCloseModal={() =>
                                  setToggle({ ...toggle, modelToggle: false })
                                }
                                loading={loading.productDelete}
                              />
                            </>
                          );
                        }}
                      >
                        {" "}
                        <DeleteTo />{" "}
                      </span>
                    </div>
                    <div
                      className={styles.col}
                      data-label="Setting"
                      style={{ color: mode ? "#fff" : "#000" }}
                    >
                      <SettingTo mode={mode} />
                    </div>
                    <div
                      className={styles.col}
                      data-label="Edit"
                      style={{ color: mode ? "#fff" : "#000" }}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          navi(`/products/addproduct?productId=${item?._id}`);
                        }}
                      >
                        <EditeTo />{" "}
                      </span>
                    </div>
                    <div
                      className={styles.col}
                      data-label="View"
                      style={{ color: mode ? "#fff" : "#000" }}
                    >
                      <ViewTo />
                    </div>
                  </li>
                ))
              )}
            </div>
          </ul>
        </div>
        <div
          className={styles.mgx_pagination}
          style={{ backgroundColor: mode ? "#232529" : "#fff" }}
        >
          <div style={{ width: "50%" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "14px",
              }}
            >
              <GoToPage key={"1"} />
            </div>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "12px",
            }}
          >
            <Pagenation key={"1"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
