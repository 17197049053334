import styles from "./Refunds.module.css";
import TextElement from "../FormComponents/TextElement";
import { CloseIcon } from "../../icons/Icon2";
import DetailRow from "../Transactions/DetailRow";
import Button from "../Dealers/Button";

const RefundModal = ({ mode, close, data, getStatusColor }) => {
  //console.log(data);

  return (
    <div
      className={styles.refund_modal}
      style={mode ? { backgroundColor: "#1B1D21" } : {}}
    >
      <div>
        <TextElement
          fontSize="16px"
          labelColor={mode ? "#FFFFFF" : "#000000"}
          label={`Refund Details`}
        />
        <div onClick={close} style={{ cursor: "pointer" }}>
          <CloseIcon mode={mode} />
        </div>
      </div>

      <DetailRow
        mode={mode}
        labelLeft="Sub Merchant ID"
        valueLeft={data.subMerchantId}
        labelRight="Status"
        valueRight={
          <span style={{ color: getStatusColor(data.status) }}>
            {data.status}
          </span>
        }
      />
      <div
        className={`${styles.border} ${mode ? styles.border_dark : ""}`}
      ></div>

      <DetailRow
        mode={mode}
        labelLeft="Original Transaction"
        valueLeft={data.originalTransactionId}
        labelRight="Refund Transaction ID"
        valueRight={data.refundTransactionId}
      />
      <div
        className={`${styles.border} ${mode ? styles.border_dark : ""}`}
      ></div>

      <DetailRow
        mode={mode}
        labelLeft="Original Bank RRN"
        valueLeft={data.originalBankRrn}
        labelRight="Refund Bank RRN"
        valueRight={data.refundBankRrn}
      />
      <div
        className={`${styles.border} ${mode ? styles.border_dark : ""}`}
      ></div>

      <DetailRow
        mode={mode}
        labelLeft="Customer VPA"
        valueLeft={data.customerVpaUpi}
        labelRight="Date & Time"
        valueRight={data.dateTime}
      />
      <div
        className={`${styles.border} ${mode ? styles.border_dark : ""}`}
      ></div>

      <DetailRow
        mode={mode}
        labelLeft="Refund Amount (INR)"
        valueLeft={data.refundAmountInr}
        labelRight="Reject Reason"
        valueRight={data.rejectReason}
      />
      <div
        className={`${styles.border} ${mode ? styles.border_dark : ""}`}
      ></div>

      <div style={{ gap: "15px" }}>
        <Button
          buttonBgColor={getStatusColor(data.status)}
          buttonTextColor="#FFFFFF"
          buttonText={data.status}
        />
      </div>
    </div>
  );
};

export default RefundModal;
