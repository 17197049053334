
import React, { useEffect, useState } from "react";
import styles from "./Invoices.module.css";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import { useAuth } from "../../components/context/Auth";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import { useNavigate, useParams } from "react-router-dom";
import fetchData from "../../APIsControll/apiControll";
import Button from "../../components/updatedComponents/Dealers/Button";
import {
  
  Uploadinvoice,
  Viewaccountinfo,
  Viewinvoice,
} from "../../components/icons/Icon4";
import { handleFileData } from "../../APIsControll/apiControl2";
import FileUploadButton from "../../components/updatedComponents/Dealers/Buttonupload";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";

const SaleInvoice = () => {
  const { mode } = useAuth();
  const navigate = useNavigate();
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountinfo, setAccountinfo] = useState({});

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const { managerId } = useAuth();
  const [data, setData] = useState([]);
  const [receipt, setReceipt] = useState([]);
  const params = useParams();
  const { show } = useToast();

  const orderHeader = [
    "S. No.",
    "Item Number",
    "Product",
    "Batch No.",
    "Quantity",
    "Unit",
    "Rate",
    "Amount",
  ];

  const orderHeader1 = [
    "Receipt ID",
    "Method",
    "Amount",
    "Status",
    "Account Info ",
    ,
  ];
  const getStatusColor = (status) => {
    switch (status) {
      case "ordered":
      case "Packed":
      case "success":
        return "green";
      case "Cancelled":
        return "red";
      case "Received":
        return "green";
      case "Not Packed":
      case "ordered Pending":
      case "Payment Under Verification":
      case "Request For Edit":
      case "Edit request Approved":
      case "Request For Cancel":
        return "red";

      case "Packing In Progress":
      case "Draft":
      case "In Progress":
      case "Payment Pending":
        return "red";

      case "New Order":
      case "draft":
        return "#7698FF";

      default:
        return "Orange";
    }
  };
  const allowedFiles = {
    image: ["png", "jpeg", "jpg", "webp", "svg", "gif"],
    document: ["pdf", "doc", "xls", "ppt", "txt", "json"],
  };
  const validateFile = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const isValid =
      allowedFiles.image.includes(fileExtension) ||
      allowedFiles.document.includes(fileExtension);

    if (!isValid) {
      alert(`Invalid file type: ${file.name}. Please upload valid files.`);
      return false;
    }

    return true;
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile && validateFile(uploadedFile)) {
      setFiles(uploadedFile);
    } else {
      e.target.value = null;
    }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get("orderId");

  useEffect(() => {
    if (files) {
      uploadinvoicedata();
    }
  }, [files]);

  const uploadinvoicedata = async () => {
    if (!files) return;

    const formData = new FormData();
    formData.append("managerId", managerId);
    formData.append("orderId", orderId);
    formData.append("file", files);

    const res = await handleFileData(`invoice/uploadInvoice`, formData);
    //console.log(res);
    if (res.status === "success") {
      show(res.msg);
    }
    if (res.status === "failed") {
      show(res.msg, "error");
    }
  };

  const getinvoicedata = async () => {
    try {
      const payload = {
        managerId: parseInt(managerId),
        orderId: orderId,
      };

      const res = await fetchData(`invoice/getInvoice`, payload);
      if (res.status === "success") {
        setData(res?.data?.order);
        setReceipt(res?.data?.receipt);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    getinvoicedata();
  }, []);
 
  const shippingData = data?.shippingInfo
  ? `${data.shippingInfo.city || ""} ${data.shippingInfo.district || ""} ${
      data.shippingInfo.state || ""
    } ${data.shippingInfo.country || ""}`.trim()
  : "";


  return (
    <React.Fragment>
      <div
        style={{ backgroundColor: mode ? "#232529" : "#D9DCE5" }}
        className={styles.main}
      >
        <div
          className={`${styles.mainCon} ${
            mode ? styles.mainConDark : styles.mainConLight
          }`}
        >
          <div className={styles.order_top}>
            <div className={styles.order_top_left}>
              <TopTabBar text={"Sale Invoice"} />
            </div>
            <TextElement
              label={"Voucher No. & Voucher Date:"}
              labelColor={mode ? "#fff" : "#646B88"}
              value={(orderId ? orderId : "") + (orderId && data.orderDate ? " / " : "") + (data.orderDate ? data.orderDate : "")}
             
              valueColor={mode ? "#fff" : "#646B88"}
              fontSize={"12px"}
              type={"order"}
            />
          </div>
          <div>
            <div className={styles.inputboxes}>
              <div className={styles.input1}>
                <InputTag
                  labelText={"Dealer / Dealer ID"}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter Warehouse Name"}
                  maxLength={40}
                  placeholderType={"text"}
                  value={data?.dealerId}
                />
              </div>
              <div className={styles.input2}>
                <InputTag
                  labelText={"Warehouse"}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter Warehouse Name"}
                  maxLength={40}
                  placeholderType={"text"}
                  value={data?.warehousename}
                />
              </div>
              <div className={styles.input2}>
                {" "}
                <InputTag
                  labelText={"Order Manager"}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter Warehouse Name"}
                  maxLength={40}
                  placeholderType={"text"}
                  value={data?.ordermanager}
                />
              </div>
              <div className={styles.input2}>
                {" "}
                <InputTag
                  labelText={"Delivery Partner"}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter Warehouse Name"}
                  maxLength={40}
                  placeholderType={"text"}
                  value={data?.shippingInfo?.partnerName}
                />
              </div>
              <div className={styles.input2}>
                {" "}
                <InputTag
                  labelText={"Delivery Method"}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter Warehouse Name"}
                  maxLength={40}
                  placeholderType={"text"}
                  value={
                    data?.shippingInfo?.methodName === "undefined"
                      ? ""
                      : data?.shippingInfo?.methodName
                  }
                />
              </div>
            </div>
            <div className={styles.inputboxes1}>
              <div className={styles.input3}>
                <InputTag
                  labelText={"Billing Address"}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter Warehouse Name"}
                  maxLength={40}
                  placeholderType={"text"}
                  value={data?.billingAddress}
                />
              </div>
              <div className={styles.input3}>
                <InputTag
                  labelText={"Shipping Address"}
                  padding={"8px"}
                  fontSize={"12px"}
                  placeholderText={"Enter Warehouse Name"}
                  maxLength={40}
                  placeholderType={"text"}
                  value={shippingData}
                />
              </div>
            </div>
          </div>

          <div className={styles.tablemain}>
            <ul className={styles.order_data_list}>
              <li
                className={`${styles.order_data_list_row_header} ${
                  mode ? styles.order_data_list_row_header_dark : ""
                }`}
              >
                {orderHeader.map((h) => (
                  <div className={styles.entry} key={h}>
                    {h}
                  </div>
                ))}
              </li>
              <div className={styles.order_data_list_container12}>
                {loading ? (
                  <div style={{ margin: "1rem auto" }}>
                    <Spinner height={80} width={80} />
                  </div>
                ) : data?.data?.length === 0 ? (
                  <img
                    src="/assets/updatedAssets/noDATA.png"
                    alt="img"
                    style={{
                      height: "12rem",
                      width: "16rem",
                      margin: "1rem auto",
                    }}
                  />
                ) : (
                  <>
                    {data?.items?.map((d, i) => (
                      <li
                        key={i}
                        className={`${styles.order_data_list_row2} ${
                          mode ? styles.order_data_list_row_dark : ""
                        }`}
                       
                      >
                        <div className={styles.entry}>{i + 1}</div>
                        <div className={styles.entry}>{d.itemNo}</div>
                        <div className={styles.entry}>{d.product_name}</div>
                        <div className={styles.entry}>{d.batch}</div>
                        <div className={styles.entry}>{d.qty}</div>
                        <div className={styles.entry}>{d.unit}</div>
                        <div className={styles.entry}>{d.price}</div>
                        <div className={styles.entry}>{d.qty * d.price}</div>
                      </li>
                    ))}
                  </>
                )}
              </div>
            </ul>
          </div>

          <div
            style={{
              backgroundColor: mode ? "#232529" : "#fff",
            }}
            className={styles.orders_btn_div1}
          >
            <div className={styles.continer_all_feed}>
              <div className={styles.bottomtable}>
                <ul className={styles.bottomul}>
                  <li
                    style={{
                      backgroundColor: mode ? "#2B2D31" : "",
                    }}
                    className={`${styles.order_data_list_row_header} ${
                      mode ? styles.order_data_list_row_header_dark : ""
                    }`}
                  >
                    {orderHeader1.map((h) => (
                      <div className={styles.entry} key={h}>
                        {h}
                      </div>
                    ))}
                  </li>
                </ul>

                <ul
                  className={styles.bottomu2}
                  style={{
                    minHeight: "5vh",
                    maxHeight: "10vh",
                  }}
                >
                  {receipt?.map((d, i) => (
                    <li
                      style={{
                        backgroundColor: mode ? "#1C1D21" : "#F8F9FC",
                      }}
                      key={i}
                      className={`${styles.order_data_list_row2} ${
                        mode ? styles.order_data_list_row_dark : ""
                      }`}
                    >
                      <div className={styles.entry}>{d.receiptId}</div>
                      <div className={styles.entry}>{d.paymentMethod}</div>
                      <div className={styles.entry}>{d.paymentAmount}</div>

                      <div
                        className={styles.entry}
                        style={{ color: getStatusColor(d?.status) }}
                      >
                        {d.status}
                      </div>
                      <div
                        style={{ paddingLeft: "15px" }}
                        className={styles.entry}
                      >
                        <span
                          onClick={() => {
                            handleOpenModal();
                            setAccountinfo(d);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Viewaccountinfo />
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div style={{ display: "flex" }}>
                {" "}
                <div
                  style={{ paddingTop: "37px" }}
                  className={styles.bottombtncon}
                >
                  <div>
                    <Button
                      buttonIcon={<Viewinvoice />}
                      buttonText={" VIEW ORDER"}
                      buttonBgColor={"black"}
                      buttonTextColor={"white"}
                      buttonClick={() => {
                        navigate("/orders/order/saleorder?orderId=" + orderId);
                      }}
                    />
                  </div>

                  <div className={styles.updateinvoicebtn}>
                    <FileUploadButton
                      buttonIcon={<Uploadinvoice />}
                      buttonText="Upload File"
                      buttonBgColor="black"
                      disabledButtonBgColor="#ccc"
                      buttonTextColor="#fff"
                      disabledButtonTextColor="#666"
                      buttonBorder="1px solid black"
                      loading={false}
                      disabled={false}
                      tabIndex={0}
                      buttonType="button"
                      onFileChange={handleFileChange}
                      accept=".jpg,.png,.pdf"
                      buttonWidth={"10px"}
                    />
                  </div>
                  <div></div>
                  <div>
                    <Button
                      buttonIcon={<Viewinvoice />}
                      buttonText={" View Invoice"}
                      buttonBgColor={"black"}
                      buttonTextColor={"white"}
                      buttonClick={() => {
                        navigate(
                          "/invoices/uploadedinvoice?orderId=" + orderId
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{ marginLeft: "10px" }}
                  className={styles.mshipping}
                >
                  <div
                    className={`${styles.so_amount_info} ${
                      mode ? styles.so_amount_info_dark : ""
                    }`}
                  >
                    <div>
                      <p>Sub Total:</p>

                      <p>{data.subtotal}</p>
                    </div>
                    <div className={styles.so_shipping}>
                      <p>
                        Shipping Charges:
                        {data?.order?.shippingInfo?.shippingCharge}
                      </p>

                      <div className={styles.so_check}>
                        <p>To Pay</p>
                        <input type="checkbox" checked={!!data?.toPay} />
                        <p>{data?.shippingInfo?.shippingCharge}</p>
                      </div>
                    </div>
                    <div>
                      <p>Total Amount(INR):</p>
                      <p>{data.totalAmount}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        
          <ModalWrapper
            style={{
              borderRadius: "15px",
              backgroundColor: mode ? "black" : "white",
              color: mode ? "white" : "black",
              width: "35vh",
              padding: "10px",
              position: "fixed",
              bottom: "10px", 
              left: "41%",
              transform: "translateX(-50%)",
            }}
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          >
            <p style={{ margin: "0 0 10px 0" }}>
              {" "}
              Account No: {accountinfo.accountNumber}
            </p>
            <p style={{ margin: 0 }}> IFSC Code: {accountinfo?.ifscCode}</p>
          </ModalWrapper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SaleInvoice;
