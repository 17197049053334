// import React, { useState } from 'react';
// import styles from "./Templet.module.css";
// import InputTag from '../FormComponents/InputTag';
// import SelectBox from '../FormComponents/SelectBox';
// import { AddBtnicon, CreateTem, Delete } from '../../icons/Icon2';
// import { useAuth } from '../../context/Auth';

// const AddBtnContner = ({ buttons, setButtons }) => {
//     const { mode } = useAuth();
//     const [dropdownVisible, setDropdownVisible] = useState(null);
//     const [errors, setErrors] = useState({});
//     // //console.log(buttons.length);

//     const addNewButton = () => {
//         const newButtonId = buttons?.length + 1;
//         setButtons([...buttons, {
//             id: newButtonId,
//             value: 'URL',
//             selectedOption: 'Visit Website',
//             text: '',
//             btnText: '',
//             selectValue: ''
//         }]);
//     };

//     const validateURL = (url) => {
//         const urlPattern = new RegExp(
//             '^(https?:\\/\\/)?' + // protocol
//             '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|(\\d{1,3}\\.){3}\\d{1,3})' + // domain name or IP
//             '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
//             '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
//             '(\\#[-a-z\\d_]*)?$',

//         );
//         return urlPattern.test(url);
//     };

//     const handleInputChange = (index, e) => {
//         const newButtons = [...buttons];
//         newButtons[index].btnText = e?.target?.value;
//         setButtons(newButtons);
//     };

//     const hadelSelect = (e, index) => {
//         const newButtons = [...buttons];
//         const previousSelectValue = newButtons[index].selectValue;
//         newButtons[index].selectValue = e?.label;

//         // If the current button is for phone number, update the text accordingly
//         if (newButtons[index].value === "PHONE_NUMBER") {
//             const phoneNumber = newButtons[index].text.replace(previousSelectValue, ''); // Remove old country code
//             newButtons[index].text = e?.label + phoneNumber; // Concatenate new country code with existing phone number
//         }

//         setButtons(newButtons);
//     };

//     const handleInputText = (index, e) => {
//         const newButtons = [...buttons];
//         if (newButtons[index].value === "PHONE_NUMBER") {
//             newButtons[index].text = newButtons[index].selectValue + e.target.value;
//         } else {
//             newButtons[index].text = e?.target?.value;
//         }
//         setButtons(newButtons);

//         // Validate the input if the selected option is URL
//         if (newButtons[index].selectedOption === 'Visit Website') {
//             const isValid = validateURL(e.target.value);
//             setErrors((prevErrors) => ({
//                 ...prevErrors,
//                 [index]: isValid ? '' : 'Invalid URL',
//             }));
//         }
//     };

//     const handleDropdownEnter = (index) => {
//         setDropdownVisible(index);
//     };

//     const handleDropdownLeave = () => {
//         setDropdownVisible(null);
//     };

//     const handleSelectChange = (index, option) => {
//         const newButtons = [...buttons];
//         newButtons[index].selectedOption = option?.label;
//         newButtons[index].value = option?.value; // Reset value when changing option
//         setButtons(newButtons);
//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             [index]: '', // Clear any previous errors
//         }));
//     };

//     return (
//         <div className={styles.create_tem_footer_btn_conitner} style={mode ? { backgroundColor: "#232529" } : {}}>
//             {buttons.map((button, index) => (
//                 <div key={index}>
//                     <div className={styles.coll_continer} style={mode ? { backgroundColor: "#1B1D21" } : { backgroundColor: "#E4E7EB" }}>
//                         <span style={mode ? { color: "#fff" } : { color: "#000" }}>Call to action</span>
//                     </div>
//                     <div className={styles.create_tem_footer_btn} style={mode ? { border: "1px solid #2C2E33" } : { border: "1px solid #E4E7EB" }}>
//                         <div className={styles.create_tem_footer_btn_head} style={mode ? { color: "#fff", backgroundColor: "#1B1D21" } : { color: "#000", backgroundColor: "#F8F9FC" }}>
//                             <div
//                                 className={styles.create_tem_footer_btn_dropdown}
//                                 onMouseEnter={() => handleDropdownEnter(index)}
//                                 onMouseLeave={handleDropdownLeave}
//                             >
//                                 <div className={styles.create_tem_footer_btn_drown_togle}>
//                                     <span style={{ marginRight: "10px", fontSize: "14px", fontWeight: "400" }}>
//                                         {button.selectedOption}
//                                     </span>
//                                     <span>
//                                         <AddBtnicon mode={mode} rotate={dropdownVisible === index ? 180 : 0} />
//                                     </span>
//                                 </div>

//                                 {dropdownVisible === index && (
//                                     <div className={styles.continer_dropdown}
//                                         style={mode ? { backgroundColor: "#2C2E33", border: "1px solid #2C2E33" }
//                                             : { backgroundColor: "#FFFFFF", border: "1px solid #E4E7EB" }}>
//                                         {[
//                                             { label: "Visit Website", value: "URL" },
//                                             { label: "Call Phone Number", value: "PHONE_NUMBER" },
//                                             { label: "Quick Reply Button", value: "QUICK_REPLY" },
//                                             // { label: "Copy offer code", value: "COPY_OFFER_CODE" }
//                                         ].map((option) => (
//                                             <span key={option.label} className={styles.spanContiner} onClick={() => { handleSelectChange(index, option); handleDropdownLeave() }}>
//                                                 {option.label}
//                                             </span>
//                                         ))}
//                                     </div>
//                                 )}
//                             </div>
//                             <div>
//                                 <span className={styles.deleteBtn} onClick={() => {
//                                     const newButtons = buttons.filter((_, i) => i !== index);
//                                     setButtons(newButtons);
//                                     setErrors((prevErrors) => {
//                                         const newErrors = { ...prevErrors };
//                                         delete newErrors[index]; // Remove error for deleted button
//                                         return newErrors;
//                                     });
//                                 }}>
//                                     <Delete /> Delete
//                                 </span>
//                             </div>
//                         </div>

//                         <div className={styles.create_tem_footer_btn_TextAndSelect}>
//                             {
//                                 button.value === "QUICK_REPLY" ? <div className={styles.can}>
//                                     <div>
//                                         <InputTag
//                                             placeholderType={"text"}
//                                             placeholderText={"Enter Text"}
//                                             maxLength={25}
//                                             onChange={(e) => handleInputChange(index, e)}
//                                         />
//                                     </div>
//                                     <div>
//                                         <span className={styles.countText} style={mode ? { color: "#fff" } : { color: "#000" }}>{`${button?.btnText?.length}/25`}</span>
//                                     </div>
//                                 </div> :
//                                     (<>
//                                         <div className={styles.create_tem_footer_btn_Textcon}>
//                                             <InputTag
//                                                 placeholderType={"text"}
//                                                 placeholderText={"Enter Text"}
//                                                 maxLength={25}
//                                                 onChange={(e) => handleInputChange(index, e)}
//                                             />
//                                             <span className={styles.countText} style={mode ? { color: "#fff" } : { color: "#000" }}>{`${button?.btnText?.length}/25`}</span>
//                                         </div>
//                                         {
//                                             button.value === "PHONE_NUMBER" ? (
//                                                 <div className={styles.create_tem_footer_btn_Textcon}>
//                                                     <SelectBox
//                                                         onSelect={(e) => hadelSelect(e, index)}
//                                                         dropDownText={"--Select--"}
//                                                         value={button.selectValue}
//                                                         option={[{ label: "91" }]} // Country code options
//                                                     />
//                                                 </div>
//                                             ) : (
//                                                 <div className={styles.create_tem_footer_btn_Textcon}>
//                                                     <SelectBox
//                                                         onSelect={(e) => hadelSelect(e, index)}
//                                                         dropDownText={"--Select--"}
//                                                         value={button.selectValue}
//                                                         option={[{ label: "Static" }, { label: "Dynamic" }]}
//                                                     />
//                                                 </div>
//                                             )
//                                         }
//                                     </>)
//                             }
//                         </div>

//                         <div>
//                             {
//                                 button.value === "QUICK_REPLY" ? null :
//                                     <InputTag
//                                         placeholderType={button.value === "PHONE_NUMBER" ? "number" : "text"}
//                                         placeholderText={button.value === "PHONE_NUMBER" ? "Enter Phone Number" : button.value === "URL" ? "Enter URL" : "Enter Text"}
//                                         maxLength={button.value === "PHONE_NUMBER" ? 10 : 300}
//                                         onChange={(e) => handleInputText(index, e)}
//                                     />
//                             }
//                             {errors[index] && <div className={styles.error}>{errors[index]}</div>}
//                         </div>
//                     </div>
//                 </div>
//             ))}
//             {
//                 buttons.length > 3 ? null :
//                     <div className={styles.addNewButton}>
//                         <span className={styles.addBtnIcon} onClick={addNewButton}>
//                             <CreateTem /> Add Button
//                         </span>
//                     </div>
//             }

//             <div style={{ height: "100px" }}></div>
//         </div>
//     );
// };

// export default AddBtnContner;

import React, { useState } from "react";
import styles from "./Templet.module.css";
import InputTag from "../FormComponents/InputTag";
import SelectBox from "../FormComponents/SelectBox";
import { AddBtnicon, CreateTem, Delete } from "../../icons/Icon2";
import { useAuth } from "../../context/Auth";

const AddBtnContner = ({ buttons, setButtons }) => {
  const { mode } = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [errors, setErrors] = useState({});
  // //console.log(buttons.length);

  const addNewButton = () => {
    const newButtonId = buttons?.length + 1;
    setButtons([
      ...buttons,
      {
        id: newButtonId,
        value: "URL",
        selectedOption: "Visit Website",
        text: "",
        btnText: "",
        selectValue: "",
      },
    ]);
  };

  const validateURL = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|(\\d{1,3}\\.){3}\\d{1,3})" + // domain name or IP
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$"
    );
    return urlPattern.test(url);
  };

  const handleInputChange = (index, e) => {
    const newButtons = [...buttons];
    newButtons[index].btnText = e?.target?.value;
    setButtons(newButtons);
  };

  const hadelSelect = (e, index) => {
    const newButtons = [...buttons];
    const previousSelectValue = newButtons[index].selectValue;
    newButtons[index].selectValue = e?.label;

    // If the current button is for phone number, update the text accordingly
    if (newButtons[index].value === "PHONE_NUMBER") {
      const phoneNumber = newButtons[index].text.replace(
        previousSelectValue,
        ""
      ); // Remove old country code
      newButtons[index].text = e?.label + phoneNumber; // Concatenate new country code with existing phone number
    }

    setButtons(newButtons);
  };

  const handleInputText = (index, e) => {
    const newButtons = [...buttons];
    if (newButtons[index].value === "PHONE_NUMBER") {
      newButtons[index].text = newButtons[index].selectValue + e.target.value;
    } else {
      newButtons[index].text = e?.target?.value;
    }
    setButtons(newButtons);

    // Validate the input if the selected option is URL
    if (newButtons[index].selectedOption === "Visit Website") {
      const isValid = validateURL(e.target.value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: isValid ? "" : "Invalid URL",
      }));
    }
  };

  const handleDropdownEnter = (index) => {
    setDropdownVisible(index);
  };

  const handleDropdownLeave = () => {
    setDropdownVisible(null);
  };

  const handleSelectChange = (index, option) => {
    const newButtons = [...buttons];
    newButtons[index].selectedOption = option?.label;
    newButtons[index].value = option?.value; // Update the value dynamically
    newButtons[index].selectValue = ""; // Reset selectValue to avoid confusion
    setButtons(newButtons); // Update the buttons state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: "", // Clear any previous errors
    }));
  };

  return (
    <div
      className={styles.create_tem_footer_btn_conitner}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      {buttons.map((button, index) => (
        <div key={index}>
          <div
            className={styles.coll_continer}
            style={
              mode
                ? { backgroundColor: "#1B1D21" }
                : { backgroundColor: "#E4E7EB" }
            }
          >
            <span style={mode ? { color: "#fff" } : { color: "#000" }}>
              Call to action
            </span>
          </div>
          <div
            className={styles.create_tem_footer_btn}
            style={
              mode
                ? { border: "1px solid #2C2E33" }
                : { border: "1px solid #E4E7EB" }
            }
          >
            <div
              className={styles.create_tem_footer_btn_head}
              style={
                mode
                  ? { color: "#fff", backgroundColor: "#1B1D21" }
                  : { color: "#000", backgroundColor: "#F8F9FC" }
              }
            >
              <div
                className={styles.create_tem_footer_btn_dropdown}
                onMouseEnter={() => handleDropdownEnter(index)}
                onMouseLeave={handleDropdownLeave}
              >
                <div className={styles.create_tem_footer_btn_drown_togle}>
                  <span
                    style={{
                      marginRight: "10px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {button.selectedOption}
                  </span>
                  <span>
                    <AddBtnicon
                      mode={mode}
                      rotate={dropdownVisible === index ? 180 : 0}
                    />
                  </span>
                </div>

                {dropdownVisible === index && (
                  <div
                    className={styles.continer_dropdown}
                    style={
                      mode
                        ? {
                            backgroundColor: "#2C2E33",
                            border: "1px solid #2C2E33",
                          }
                        : {
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #E4E7EB",
                          }
                    }
                  >
                    {[
                      { label: "Visit Website", value: "URL" },
                      { label: "Call Phone Number", value: "PHONE_NUMBER" },
                      { label: "Quick Reply Button", value: "QUICK_REPLY" },
                      // { label: "Copy offer code", value: "COPY_OFFER_CODE" }
                    ].map((option) => (
                      <span
                        key={option.label}
                        className={styles.spanContiner}
                        onClick={() => {
                          handleSelectChange(index, option);
                          handleDropdownLeave();
                        }}
                      >
                        {option.label}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div>
                <span
                  className={styles.deleteBtn}
                  onClick={() => {
                    const newButtons = buttons.filter((_, i) => i !== index);
                    setButtons(newButtons);
                    setErrors((prevErrors) => {
                      const newErrors = { ...prevErrors };
                      delete newErrors[index]; // Remove error for deleted button
                      return newErrors;
                    });
                  }}
                >
                  <Delete /> Delete
                </span>
              </div>
            </div>

            <div className={styles.create_tem_footer_btn_TextAndSelect}>
              {button.value === "QUICK_REPLY" ? (
                <div className={styles.can}>
                  <div>
                    <InputTag
                      placeholderType={"text"}
                      placeholderText={"Enter Text"}
                      maxLength={25}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </div>
                  <div>
                    <span
                      className={styles.countText}
                      style={mode ? { color: "#fff" } : { color: "#000" }}
                    >{`${button?.btnText?.length}/25`}</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.create_tem_footer_btn_Textcon}>
                    <InputTag
                      placeholderType={"text"}
                      placeholderText={"Enter Text"}
                      maxLength={25}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                    <span
                      className={styles.countText}
                      style={mode ? { color: "#fff" } : { color: "#000" }}
                    >{`${button?.btnText?.length}/25`}</span>
                  </div>
                  {button.value === "PHONE_NUMBER" ? (
                    <div className={styles.create_tem_footer_btn_Textcon}>
                      <SelectBox
                        onSelect={(e) => hadelSelect(e, index)}
                        dropDownText={"--Select--"}
                        value={button.selectValue}
                        option={[{ label: "91" }]} // Country code options
                      />
                    </div>
                  ) : (
                    <div className={styles.create_tem_footer_btn_Textcon}>
                      <SelectBox
                        onSelect={(e) => hadelSelect(e, index)}
                        dropDownText={"--Select--"}
                        value={button.selectValue}
                        option={[{ label: "Static" }, { label: "Dynamic" }]} // Static and Dynamic options
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <div>
              {button.value === "QUICK_REPLY" ? null : (
                <InputTag
                  placeholderType={
                    button.value === "PHONE_NUMBER" ? "number" : "text"
                  }
                  placeholderText={
                    button.value === "PHONE_NUMBER"
                      ? "Enter Phone Number"
                      : button.value === "URL"
                      ? "Enter URL"
                      : "Enter Text"
                  }
                  maxLength={button.value === "PHONE_NUMBER" ? 10 : 300}
                  onChange={(e) => handleInputText(index, e)}
                />
              )}
              {errors[index] && (
                <div className={styles.error}>{errors[index]}</div>
              )}
            </div>
          </div>
        </div>
      ))}
      {buttons.length > 3 ? null : (
        <div className={styles.addNewButton}>
          <span className={styles.addBtnIcon} onClick={addNewButton}>
            <CreateTem /> Add Button
          </span>
        </div>
      )}

      <div style={{ height: "100px" }}></div>
    </div>
  );
};

export default AddBtnContner;
