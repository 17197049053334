import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../../pages/ordermanager/PurchaseOrder.module.css";
import fetchData from "../../../APIsControll/apiControll";
import SearchItem from "./SearchItem";
import { RemoveIcon } from "../../icons/Icon";
import CryptoJS from "crypto-js";
import { useToast } from "../Toaster/Toaster";

const PurchaseNewItemList = ({
  mode,
  index,
  managerId,
  itemInpRefs,
  item,
  itemsdetailslength,
  onSelectItem,
  addItem,
  removeItem,
  showItemSearch,
  setShowItemSearch,
  focusedIndex,
  onFocus,
  onBlur,
  onCompletion,
  warehouse_id,
  openAddItemModal,
  type,
  quantityRefs,
}) => {
  const quantityInpRef = useRef();
  const unitRef = useRef();
  const rateRef = useRef();
  const { show } = useToast();
  let itemURL = "";
  if (type === "Adjustment") {
    itemURL = "inventory/searchAdjustment?page=";
  } else if (type === "Transfer") {
    itemURL = "inventory/stockSearch?page=";
  } else {
    itemURL = "inventory/searchPurchaseItems?page=";
  }
  const [designation, setDesignation] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [useFallback, setUseFallback] = useState(true);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const [selectedItem, setSelectedItem] = useState({
    itemNo: "",
    product_name: "",
    qty: "",
    unit: "",
    price: "",
  });

  const getDesignation = sessionStorage.getItem("designation")
    ? sessionStorage.getItem("designation")
    : "";
  const decryptedDesignationBytes = CryptoJS.AES.decrypt(
    getDesignation,
    "secret key"
  );
  const designations = decryptedDesignationBytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (designations) {
      setDesignation(designations.split(",")[0]);
    }
  }, [setDesignation, designations]);

  useEffect(() => {
    quantityRefs[index] = quantityInpRef;
  }, [index, quantityRefs]);

  const showAddItemModal = () => {
    if (designation === "superadmin" || designation === "inventorymanager") {
      openAddItemModal();
      return;
    } else {
      show("Only ADMIN and INVENTORY MANAGER can add new item!", "warning");
    }
  };

  const fetchItems = useCallback(async () => {
    const apiData = {
      managerId: managerId,
      keyword: searchItem,
      warehouse_id: warehouse_id,
    };
    try {
      setLoading(true);
      const result = await fetchData(itemURL, apiData);
      if (result.status === "success") {
        setResults(result.data || []);
        setCurrentIndex(0);
        setLoading(false);
      }
      if (result.status === "failed") {
        show(result.msg, "error");
        setLoading(false);
      }
    } catch (error) {
      //console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [itemURL, managerId, searchItem, warehouse_id]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchItem) {
        fetchItems();
      } else {
        setResults([]);
        setCurrentIndex(0);
      }
    }, 700);

    return () => clearTimeout(debounceTimer);
  }, [searchItem, managerId, fetchItems]);

  const handleFieldUpdate = (updatedField) => {
    const updatedItem = { ...selectedItem, ...updatedField };
    setSelectedItem(updatedItem);
    onSelectItem && onSelectItem(updatedItem);
  };

  const resetSearchComplete = () => {
    setCurrentIndex(0);
    setShowItemSearch(false);
  };

  const handleItemSelect = (index) => {
    if (warehouse_id !== undefined) {
      setSearchItem("");
      const selectedItem = results[index];
      if (!selectedItem) {
        resetSearchComplete();
        return;
      }
      const selectedKeys = {
        itemNo: selectedItem.itemNo || "",
        product_name: selectedItem.product_name || "",
        qty: selectedItem.qty || "",
        unit:
          selectedItem.unit || selectedItem.unit !== ""
            ? selectedItem.unit
            : "",
        price: type === "Transfer" ? selectedItem.price : "",
        totalQty: selectedItem.totalQty,
        ...(type === "Transfer" && {
          item_id: selectedItem.item_id,
        }),
      };
      //console.log("selected keys ", selectedKeys);
      setSelectedItem(selectedKeys);
      onSelectItem && onSelectItem(selectedKeys);
      resetSearchComplete();
      setTimeout(() => {
        quantityInpRef.current?.focus();
      }, 0);
    } else {
      show("Please select a warehouse first!", "warning");
    }
  };

  const itemKeyDown = (e) => {
    if (e.ctrlKey && e.key === "Backspace") {
      e.preventDefault();

      if (itemsdetailslength === 1) {
        show("YOU CANNOT REMOVE THE ONLY ITEM!", "warning");
        return;
      }
      removeItem(index);

      setTimeout(() => {
        if (index > 0) {
          const prevQuantityRef = quantityRefs[index - 1]?.current;
          if (prevQuantityRef) {
            prevQuantityRef.focus();
          }
        }
      }, 0);
    }
  };

  const itemInpKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setCurrentIndex((prevIndex) =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
      );
      e.preventDefault();
      return;
    }

    if (e.key === "ArrowUp") {
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      e.preventDefault();
      return;
    }

    if ((e.altKey || e.metaKey) && e.key.toLowerCase() === "c") {
      setTimeout(() => {
        showAddItemModal();
      }, 0);
    }

    if (e.key === "Enter") {
      if (searchItem.length === 0) {
        if (itemsdetailslength === 1) {
          show(
            "YOU CANNOT ADD NEW ITEM BEFORE FILLING THE CURRENT ONE!",
            "warning"
          );
          itemInpRefs?.current?.focus();
        } else {
          onCompletion && onCompletion();
          removeItem();
        }
      } else {
        handleItemSelect(currentIndex);
      }
      return;
    }
  };

  const quantityKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (selectedItem.qty !== "") {
        setTimeout(() => {
          unitRef.current?.focus();
        }, 0);
      } else {
        show("Quantity cannot be empty!", "warning");
      }
    }
    if (type !== "Adjustment") {
      if (e.key === "-" || e.key === "e") {
        e.preventDefault();
      }
    }
  };

  const unitKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (selectedItem.unit !== "" && selectedItem.unit !== undefined) {
        rateRef.current.focus();
      } else {
        show("Unit cannot be empty!", "warning");
      }
    }
  };

  // const rateKeyDown = (e) => {
  //   const warnings = [];
  //   if (e.key === "-" || e.key === "e") {
  //     e.preventDefault();
  //   }
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     if (selectedItem.itemNo === "") {
  //       warnings.push("Item No. cannot be empty!");
  //     } else if (selectedItem.qty === "") {
  //       warnings.push("Quantity cannot be empty!");
  //     } else if (selectedItem.unit === "" && selectedItem.unit !== undefined) {
  //       warnings.push("Unit cannot be empty!");
  //     } else if (selectedItem.price === "") {
  //       warnings.push("Rate cannot be empty!");
  //     } else {
  //       setTimeout(() => {
  //         itemInpRefs.current?.focus();
  //       }, 0);
  //       addItem();
  //     }
  //   }
  //   show(warnings.join(" "), "warning");
  // };

  const rateKeyDown = (e) => {
    const warnings = [];
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }

    if (e.key === "Enter") {
      e.preventDefault();

      if (selectedItem.itemNo === "") {
        warnings.push("Item No. cannot be empty!");
      }
      if (selectedItem.qty === "") {
        warnings.push("Quantity cannot be empty!");
      }
      if (selectedItem.unit === "" || selectedItem.unit === undefined) {
        warnings.push("Unit cannot be empty!");
      }
      if (selectedItem.price === "") {
        warnings.push("Rate cannot be empty!");
      }

      if (warnings.length === 0) {
        setTimeout(() => {
          itemInpRefs.current?.focus();
        }, 0);
        addItem();
      } else {
        show(warnings.join(" "), "warning");
      }
    }
  };

  const units = [
    { label: "Roll", value: "Roll" },
    { label: "Pcs", value: "Pcs" },
    { label: "Box", value: "Box" },
    { label: "Sq Meter", value: "Sq Meter" },
    { label: "Meter", value: "Meter" },
    { label: "Sq Feet", value: "Sq Feet" },
    { label: "Feet", value: "Feet" },
    { label: "Kg", value: "Kg" },
  ];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <li
        className={`${styles.p_table_row} ${
          mode ? styles.p_table_row_dark : ""
        }`}
        onKeyDown={itemKeyDown}
      >
        <div className={styles.entry_2}> {index + 1} </div>
        <div className={`${styles.entry_2} ${styles.item_no}`}>
          <div className={styles.inp_div}>
            <input
              type="text"
              placeholder="Search Item No."
              ref={itemInpRefs}
              className={`${styles.item_inp} ${
                mode ? styles.item_inp_dark : ""
              }`}
              value={
                useFallback && !searchItem
                  ? item.itemNo
                    ? `${item.itemNo}`
                    : ""
                  : searchItem.toUpperCase()
              }
              onChange={(e) => {
                setSearchItem(e.target.value);
                setUseFallback(false);
              }}
              onFocus={() => onFocus(index)}
              onBlur={() => {
                onBlur();
                if (searchItem === "") {
                  setUseFallback(true);
                }
              }}
              onKeyDown={itemInpKeyDown}
            />
            {showItemSearch && focusedIndex === index && (
              <SearchItem
                key={index}
                mode={mode}
                results={results}
                currentIndex={currentIndex}
                loading={loading}
                onItemSelect={handleItemSelect}
                openModal={showAddItemModal}
              />
            )}
          </div>
        </div>
        <div className={styles.entry_2}> {item.product_name} </div>
        <div className={styles.entry_2}>
          <div className={styles.inp_div}>
            <input
              type="number"
              placeholder="Enter Quantity"
              ref={quantityInpRef}
              className={`${styles.item_inp} ${
                mode ? styles.item_inp_dark : ""
              }`}
              onKeyDown={quantityKeyDown}
              value={selectedItem.qty}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (type !== "Adjustment") {
                  if (value >= 0 || e.target.value === "") {
                    setSelectedItem((prev) => ({
                      ...prev,
                      qty: parseFloat(e.target.value),
                    }));
                    handleFieldUpdate({
                      qty: parseFloat(e.target.value) || "",
                    });
                  }
                } else {
                  setSelectedItem((prev) => ({
                    ...prev,
                    qty: parseFloat(e.target.value),
                  }));
                  handleFieldUpdate({
                    qty: parseFloat(e.target.value) || "",
                  });
                }
              }}
            />
            {/* <input
            type="number"
            placeholder="Enter Quantity"
            ref={quantityInpRef}
            className={`${styles.item_inp} ${mode ? styles.item_inp_dark : ""}`}
            onKeyDown={(e) => {
              if (e.key === "-" || e.key === "e") {
                e.preventDefault();
              }
            }}
            value={selectedItem.qty}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (value >= 0 || e.target.value === "") {
                setSelectedItem((prev) => ({
                  ...prev,
                  qty: value,
                }));
                handleFieldUpdate({ qty: value || "" });
              }
            }}
          /> */}
          </div>
        </div>
        <div className={styles.entry_2}>
          <select
            className={`${styles.unit_dd} ${mode ? styles.unit_dd_dark : ""}`}
            ref={unitRef}
            value={selectedItem.unit || ""}
            onChange={(e) => {
              setSelectedItem((prev) => ({
                ...prev,
                unit: e.target.value,
              }));
              handleFieldUpdate({ unit: e.target.value });
            }}
            onKeyDown={unitKeyDown}
          >
            <option
              value=""
              disabled
              className={`${styles.unit_dd_option} ${
                mode ? styles.unit_dd_option_dark : ""
              }`}
            >
              Select
            </option>
            {units.map((option, index) => (
              <option
                key={index}
                value={option.value}
                className={`${styles.unit_dd_option} ${
                  mode ? styles.unit_dd_option_dark : ""
                }`}
              >
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.entry_2}>
          <div className={styles.inp_div}>
            <input
              type="number"
              placeholder="Enter Rate"
              ref={rateRef}
              className={`${styles.item_inp} ${
                mode ? styles.item_inp_dark : ""
              }`}
              onKeyDown={rateKeyDown}
              value={selectedItem.price}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (value >= 0 || e.target.value === "") {
                  setSelectedItem((prev) => ({
                    ...prev,
                    price: parseFloat(e.target.value),
                  }));
                  handleFieldUpdate({
                    price: parseFloat(e.target.value) || "",
                  });
                }
              }}
            />
          </div>
        </div>
        <div className={styles.entry_2}>
          {selectedItem.qty * selectedItem.price}
        </div>
      </li>
      {itemsdetailslength > 1 && (
        <div style={{ cursor: "pointer" }} onClick={removeItem}>
          <RemoveIcon />
        </div>
      )}
    </div>
  );
};

export default PurchaseNewItemList;
