import React from "react";
import {
  BusIcon,
  CourierAirIcon,
  CurrierSurfaceIcon,
  ErickshawIcon,
  PickUpIcon,
  RapidoIcon,
  TransportIcon,
} from "../../components/icons/Icon";

export class PrintLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [
        {
          index: "1",
          name: "wallpaper",
          qty: "1",
        },
        {
          index: "2",
          name: "wallpaper",
          qty: "1",
        },
        {
          index: "3",
          name: "wallpaper",
          qty: "1",
        },
      ],
    };
  }

  render() {
    const data = this.props.data;
    const SubDistName = this.props.SubDistName;
    const districts = this.props.districts;
    const qtyPacks = this.props.qtyPacks;
    const noPrint = [];
    for (let i = 0; i < qtyPacks; i++) {
      noPrint.push(i + 1);
    }
    function transform(value) {
      if (value) {
        var a = [
          "",
          "One ",
          "Two ",
          "Three ",
          "Four ",
          "Five ",
          "Six ",
          "Seven ",
          "Eight ",
          "Nine ",
          "Ten ",
          "Eleven ",
          "Twelve ",
          "Thirteen ",
          "Fourteen ",
          "Fifteen ",
          "Sixteen ",
          "Seventeen ",
          "Eighteen ",
          "Nineteen ",
        ];
        var b = [
          "",
          "",
          "Twenty",
          "Thirty",
          "Forty",
          "Fifty",
          "Sixty",
          "Seventy",
          "Eighty",
          "Ninety",
        ];
        let number = parseFloat(value).toFixed(2).split(".");
        let num = parseInt(number[0]);
        let digit = parseInt(number[1]);
        if (num) {
          if (num.toString().length > 9) {
            return "";
          }
          const n = ("000000000" + num)
            .substr(-9)
            .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
          const d = ("00" + digit).substr(-2).match(/^(\d{2})$/);
          if (!n) {
            return "";
          }
          let str = "";
          str +=
            Number(n[1]) !== 0
              ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
              : "";
          str +=
            Number(n[2]) !== 0
              ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
              : "";
          str +=
            Number(n[3]) !== 0
              ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
              : "";
          str +=
            Number(n[4]) !== 0
              ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
              : "";
          str +=
            Number(n[5]) !== 0
              ? (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) + " "
              : "";
          str +=
            Number(d[1]) !== 0
              ? (str !== "" ? "and " : "") +
                (a[Number(d[1])] || b[d[1][0]] + " " + a[d[1][1]])
              : "";
          return str;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
    // var Inwords = transform(data&& data.packingQty ? data.packingQty : 0)
    var Inwords = transform(data && data.packingQty ? data.packingQty : 0);

    // //console.log('data', data);

    return (
      <React.Fragment>
        <div style={{}}>
          {noPrint &&
            noPrint.map((obj, idx) => (
              <div
                key={idx}
                style={{
                  background: "#fff",
                  minWidth: "100%",
                  height: "19cm",
                  position: "relative",
                  padding: "25px 20px",
                }}
              >
                <h1
                  style={{
                    fontSize: "20px",
                    minWidth: "100%",
                    textAlign: "center",
                    textDecoration: "underline",
                    marginBottom: "10px",
                  }}
                >
                  Label
                </h1>
                <div style={{ fontSize: "14px" }}>
                  {noPrint.length > 1 && (
                    <div style={{ float: "right" }}>
                      Page {idx + 1 + "/" + noPrint.length}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "300px",
                      marginLeft: "46px",
                      paddingTop: "40px",
                    }}
                  >
                    {data.methodName &&
                    data.methodName.toLowerCase().includes("bus") ? (
                      <BusIcon />
                    ) : data.methodName &&
                      data.methodName.toLowerCase().includes("air") ? (
                      <CourierAirIcon />
                    ) : data.methodName &&
                      data.methodName.toLowerCase().includes("surface") ? (
                      <CurrierSurfaceIcon />
                    ) : data.methodName &&
                      data.methodName.toLowerCase().includes("tata ace") ? (
                      <TransportIcon />
                    ) : data.methodName &&
                      data.methodName.toLowerCase().includes("rapido") ? (
                      <RapidoIcon />
                    ) : data.methodName &&
                      data.methodName.toLowerCase().includes("ricksha") ? (
                      <ErickshawIcon />
                    ) : (
                      <PickUpIcon />
                    )}
                  </div>
                </div>
                <table
                  style={{
                    borderCollapse: "collapse",
                    fontSize: "12px",
                    lineHeight: "20px",
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ border: "1px solid", padding: "2px 10px" }}>
                        <div>Delivery type:</div>
                        <div style={{ fontWeight: "bold", height: "20px" }}>
                          {data.shippingType}
                        </div>
                      </td>
                      <td style={{ border: "1px solid", padding: "2px 10px" }}>
                        <div>Delivery Method:</div>
                        <div style={{ fontWeight: "bold" }}>
                          {data.courierName}
                        </div>
                      </td>
                      <td style={{ border: "1px solid", padding: "2px 10px" }}>
                        <div>Delivery Partner:</div>
                        <div style={{ fontWeight: "bold", height: "20px" }}>
                          {data.partnerName}
                        </div>
                      </td>
                      <td style={{ border: "1px solid", padding: "2px 10px" }}>
                        <div>Order Id</div>
                        <div style={{ fontWeight: "bold" }}>{data.orderId}</div>
                      </td>
                      <td style={{ border: "1px solid", padding: "2px 10px" }}>
                        <div>Dated</div>
                        <div style={{ fontWeight: "bold" }}>
                          {data.orderDate}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    border: "1px solid",
                    borderTop: "none",
                    fontSize: "13px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        rowSpan={2}
                        style={{
                          verticalAlign: "top",
                          padding: "6px 10px",
                          borderBottom: "none",
                          borderRight: "1px solid",
                          width: "50%",
                        }}
                      >
                        <div>
                          <h6>To,</h6>
                        </div>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            lineHeight: "22px",
                          }}
                        >
                          {data.shippingInfo && data.shippingInfo.name}
                        </div>
                        <div>
                          Street:{" "}
                          {data.shippingInfo && data.shippingInfo.street}
                        </div>
                        <div>
                          City: {SubDistName && SubDistName.toUpperCase()}
                        </div>
                        <div>District: {districts && districts}</div>
                        <div>
                          State: {data.shippingInfo && data.shippingInfo.state}
                        </div>
                        <div>
                          Pincode:{" "}
                          {data.shippingInfo && data.shippingInfo.pincode}
                        </div>
                        <div>
                          Contact:{" "}
                          <b>
                            {data.shippingInfo?.mobile &&
                              data.shippingInfo?.mobile}
                          </b>
                        </div>
                        <div className="text-uppercase">
                          GSTUIN: <b>{data.gstuin && data.gstuin}</b>
                        </div>
                      </td>
                      {data &&
                        data.courierName &&
                        data.courierName === "Bus" && (
                          <td style={{ verticalAlign: "top" }}>
                            <h6>To,</h6>
                            {SubDistName && (
                              <h3 style={{ marginTop: "0" }}>
                                {SubDistName.toUpperCase() + ","}
                              </h3>
                            )}
                            {districts && (
                              <h6 style={{ marginTop: "0" }}>{districts}</h6>
                            )}
                          </td>
                        )}
                    </tr>
                    <tr style={{ border: "1px solid" }}>
                      {data &&
                        data.courierName &&
                        data.courierName === "Bus" && (
                          <td style={{ verticalAlign: "top" }}>
                            <h6 style={{ marginBottom: "0" }}>&nbsp;</h6>
                            <h3 style={{ marginTop: "0" }}>&nbsp;</h3>
                          </td>
                        )}
                    </tr>
                  </tbody>
                </table>

                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    borderLeft: "1px solid",
                    borderRight: "1px solid",
                    fontSize: "12px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: "6px 10px", borderBottom: "none" }}>
                        <div>From,</div>
                        <div>
                          <b>{data.storeName && "..."}</b>
                        </div>
                        <div> {"..."}</div>
                        <div>
                          GSTUIN: <b>{data.gstuin && "..."}</b>
                        </div>
                        <div>State: {data.state && "..."}</div>
                        <div>
                          Contact:{" "}
                          {data?.mobile && !data.courierName === "Bus"
                            ? "..."
                            : "9140968733"}
                        </div>
                        <div>Email: {data.email && "..."}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    border: "1px solid",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{ padding: "6px 0 8px 0", borderBottom: "none" }}
                      >
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center",
                          }}
                        >
                          Total {Inwords} Packages
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* <div style={{ fontSize: '12px', textAlign: "center" }}>THIS IS A COMPUTER GENERATED SALE ORDER.</div> */}
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }
}
