import React, { useRef } from "react";
import { useAuth } from "../../context/Auth";
import styles from "./FromComponent.module.css";

const InputTypedate = ({ text, onChange, value, min, max ,disable }) => {
    const { mode } = useAuth();
    const today = new Date().toISOString().split('T')[0];
    const date = useRef(null);

    const inputStyle = {
        width: '135px', // Set a fixed width
        boxSizing: 'border-box', // Include padding and border in the width
        padding: '12px ',
        color: mode ? '#fff' : '#1B1D21',
        fontSize: '13px',
        outline: 'none',
        borderRadius: '5px',
        position: 'relative',
        backgroundColor: mode ? '#1B1D21' : '#fff',
        cursor: 'pointer',
        appearance: 'none',
        WebkitAppearance: 'none',
        border: mode ? "none" : "1px solid #E4E7EB",
        cursor:disable? "not-allowed":"pointer"
        
    };

    return (
        <div className={styles.dateInputWrapper} 
        style={{ 
            position: 'relative', display:"flex", justifyContent:"center" , cursor:disable? "not-allowed":"pointer"
        }} 
        >
            <span style={{ color: mode ? '#fafafa' : '#1B1D21', marginRight: '8px', fontSize: "12px", marginTop:"12px" }}>{text}</span>
            <input
                type="date"
                className={styles.inputTypeDate1}
                onChange={onChange}
                style={inputStyle}
                value={value}
                max={min ? null : max || today}
                min={min}
                ref={date}
                onClick={()=>date.current.showPicker()}  
                disabled={disable}
            />
            <div
                className={styles.backgroundIcon}
                style={{
                    content: '',
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    width: '18px', // Adjust size as necessary
                    height: '18px',
                    backgroundImage: mode
                        ? "url('./assets/updatedAssets/dateblack2.png')"
                        : "url('./assets/updatedAssets/date.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    zIndex: 999,
                    pointerEvents: 'none',
                    cursor: disable ?"not-allowed":"pointer"
                }}
                
            />
        </div>
    );
};

export default InputTypedate;
