// import React, { useEffect, useRef, useState } from 'react'
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import ReactToPrint, { useReactToPrint } from 'react-to-print';
// import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
// import { useAuth } from '../../components/context/Auth';
// import { BlackPrint, Camera, DeleteFile, DownArrowCircle, Email, GSTIcon, LocationIcon, NoImageIcon, Phone, PhoneMob, Spinner, UpArrowCircle, WhatsApp } from '../../components/icons/Icon';
// import styles from './DispatchBoy.module.css'
// import DispatchModal from '../components/DispatchModal';
// import { PackingSlip } from '../../pages/dispatchmanager/PackingSlip';
// import { PrintLabel } from '../../pages/dispatchmanager/PrintLabel';
// import { FileHandlerMob } from '../components/DragAndDropMob';
// import { Modal } from 'react-bootstrap';
// // import CropModal from '../../components/CropModal';

// const DispatchOrder = () => {
//     const navigate = useNavigate();
//     let componentRef = React.useRef();
//     const { managerId, designation } = useAuth();
//     const params = useParams()
//     const orderId = params.orderId
//     const [loading, setLoading] = useState(true);
//     const [packingLoading, setPackingLoading] = useState(false);
//     const [showLabelModal, setShowLabelModal] = useState(false);
//     const [showDeliveryModal, setShowDeliveryModal] = useState(false);
//     const [showAcceptModal, setShowAcceptModal] = useState(false);
//     const [msgShow, setMsgShow] = useState("");
//     const [packer, setPacker] = useState(managerId);
//     // const [couriers, setCouriers] = useState([])
//     const [partners, setPartners] = useState("")
//     const [data, setData] = useState({})
//     const [isReloaded, setIsReloaded] = useState(false)
//     const [lableBtnLoading, setLableBtnLoading] = useState(false)
//     const [paymentLink, setPaymentLink] = useState('')
//     const [showApproveModal, setShowApproveModal] = useState(false)
//     const [showDenyModal, setShowDenyModal] = useState(false)
//     const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
//     const [cancelReason, setCancelReason] = useState('')
//     const [requestCancelLoading, setRequestCancelLoading] = useState(false)
//     const [requestAproveLoading, setRequestAproveLoading] = useState(false)

//     // const [itemNo, setItemNo] = useState('')

//     const [isImage, setisImage] = React.useState("");

//     const [delivery, setDelivery] = useState({
//         courierName: "",
//         partnerName: "",
//     })
//     const [printLabel, setPrintLabel] = useState({
//         qtyPacks: "",
//         delPerName: "",
//         delPerMobile: "",
//         delTrackingNo: "",
//         delTrackingUrl: "",
//     })
//     const [courierSlip, setCourierSlip] = useState("")
//     const [detailShow, setDetailShow] = useState(false);
//     const [showPackedModal, setShowPackedModal] = useState(false);
//     const [check, setCheck] = useState(false);
//     const [images, setImages] = useState([]);
//     const [packs, setPacks] = useState()
//     const [point, setPoint] = useState()

//     useEffect(() => {
//         if (managerId) {
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "orderId": orderId,
//                 "courierName": delivery.courierName
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === "success") {
//                         setData(result.data)
//                         setPacker(result.data.order.pmId)
//                         // setCouriers(result.data.couriers)
//                         setPartners(result.data.courierPartners)
//                         setPrintLabel(prevState => ({
//                             ...prevState,
//                             qtyPacks: result.data.order.qtyPacks,
//                             delPerName: result.data.order.delPerName,
//                             delPerMobile: result.data.order.delPerMobile,
//                             delTrackingNo: result.data.order.delTrackingNo,
//                             delTrackingUrl: result.data.order.delTrackingUrl,
//                         }))
//                         if (!delivery.courierName) {
//                             setDelivery(prevState => ({
//                                 ...prevState,
//                                 courierName: result.data.order.courierName,
//                                 partnerName: result.data.order.partnerName,
//                                 shippingType: result.data.order.shippingType,
//                                 methodName: result.data.order.methodName,
//                             }))
//                         }
//                         setCourierSlip(result.data.order.courierSlip)
//                     }
//                     setLoading(false)
//                 })
//                 .catch(error => //console.log('error', error));
//         }
//     }, [orderId, managerId, delivery.courierName, isReloaded])

//     // -----------------Courier---------//
//     const handleSendLink = async () => {
//         setPaymentLink('')
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "orderId": orderId,
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         await fetch(process.env.REACT_APP_URL + "receipt/generatePaymentLink", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === 'success') {
//                     setPaymentLink(result.shortUrl)
//                 }
//             })
//             .catch(error => //console.log('error', error));
//     }
//     const handleAcceptOrder = (e) => {
//         e.preventDefault()

//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId,
//             "pmId": packer ? packer : managerId,
//             "courierName": delivery.courierName,
//             "partnerName": delivery.partnerName,
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/acceptOrder", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === 'success') {
//                     handleSendLink()
//                 }
//                 setMsgShow(result.msg)
//                 setShowAcceptModal(true)
//             })
//             .catch(error => //console.log('error', error));
//     }

//     const orderPlacedNotification = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "phoneNumber": data.order.whatsapp,
//             "templateName": "online_order_placed",
//             "headerId": "https://dealer.wallicon.in/assets/order_placed.jpg",
//             "buttonDataPayload": paymentLink
//         });

//         const requestOptions = {
//           method: "POST",
//           headers: myHeaders,
//           body: raw,
//           redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
//           .then((response) => response.json())
//           .then(result => {
//             //console.log(result);
//         })
//           .catch((error) => console.error(error));
//     }

//     const orderPlacedPod = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "phoneNumber": data.order.whatsapp,
//             "templateName": "order_placed_pod",
//             "headerId": "https://dealer.wallicon.in/assets/order_placed.jpg",
//             "buttonDataPayload": paymentLink
//         });

//         const requestOptions = {
//           method: "POST",
//           headers: myHeaders,
//           body: raw,
//           redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
//           .then((response) => response.json())
//           .then(result => {
//             //console.log(result);
//         })
//           .catch((error) => console.error(error));
//     }

//     useEffect(() => {
//         if (packs) {
//             setPoint(Array.from(Array(packs === "0" ? 1 : packs === "1" ? 1 : packs === "2" ? 2 : parseInt(packs - 1)).keys()))
//         }
//     }, [packs])

//     const addFormFields = () => {
//         point.forEach(element => {
//             setImages(prevState => ([...prevState, {}]))
//         });
//     }

//     useEffect(() => {
//         if (check && (images.length < parseInt(packs))) {
//             point.forEach(element => {
//                 setImages(prevState => [
//                     ...prevState.slice(0, images.length),
//                     {},
//                     // ...images.slice(index + 1)
//                 ])
//             });
//         }
//     }, [check, images.length, packs, point])

//     const handlePrintLabel = () => {
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId,
//             "qtyPacks": printLabel.qtyPacks,
//             "delPerName": printLabel.delPerName,
//             "delPerMobile": printLabel.delPerMobile,
//             "delTrackingNo": printLabel.delTrackingNo,
//             "delTrackingUrl": printLabel.delTrackingUrl,
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     handlePrint()
//                     setShowLabelModal(false)
//                 } else {
//                     alert(result.msg)
//                 }
//             })
//             .catch(error => //console.log('error', error));
//     }

//     const handlePacked = (e) => {
//         setPackingLoading(true)
//         e.preventDefault();
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//         var formdata = new FormData();
//         formdata.append("packingQty", packs);
//         // formdata.append("images", images[0]);
//         images && images.map((image) =>
//             formdata.append("images", image)
//         )

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: formdata,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "packing/orderPacked/" + managerId + "/" + orderId, requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     window.location.reload()
//                     packingLoading(false)
//                 } else {
//                     alert(result.msg)
//                 }
//             })
//             .catch(error => //console.log('error', error));
//     }

//     const labelRef = useRef();
//     const handlePrint = useReactToPrint({
//         content: () => labelRef.current
//     });

//     const generatePaymentLink = () => {
//         setPaymentLink('')
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "orderId": orderId,
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "receipt/generatePaymentLink", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === 'success') {
//                     setPaymentLink(result.shortUrl)
//                 }
//             })
//             .catch(error => //console.log('error', error));
//     }

//     const handleDispatched = () => {
//         setPackingLoading(true)
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//         var formdata = new FormData();
//         formdata.append("shippingType", delivery.shippingType);
//         formdata.append("courierName", delivery.courierName);
//         formdata.append("partnerName", delivery.partnerName);

//         formdata.append("delPerName", printLabel.delPerName);
//         formdata.append("delPerMobile", printLabel.delPerMobile);
//         formdata.append("delTrackingNo", printLabel.delTrackingNo);
//         formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
//         formdata.append("image", courierSlip);

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: formdata,
//             redirect: 'follow'
//         };
//         fetch(process.env.REACT_APP_URL + "dispatch/deliveryDetails/" + managerId + "/" + orderId, requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     if (result.data.dac === null) {
//                         orderDispatchedNotification()
//                     } else {
//                         orderDispatchedNotificationAuth(result.data.shippingInfo.name, result.data.orderId, result.data.dac)
//                     }
//                     setShowDeliveryModal(false)
//                     setIsReloaded(Math.random())
//                 }
//                 alert(result.msg)
//             })
//             .catch(error => //console.log('error', error));
//     }

//     const orderDispatchedNotification = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId":  managerId,
//             "phoneNumber": data.order.whatsapp,
//             "templateName": "online_order_dispatched",
//             // "headerId": "https://dealer.wallicon.in/assets/dispatched.jpg",
//             "headerId": "https://dealer.wallicon.in/assets/dispatched.jpg",
//             "buttonDataPayload": paymentLink
//         });

//         const requestOptions = {
//           method: "POST",
//           headers: myHeaders,
//           body: raw,
//           redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
//           .then((response) => response.json())
//           .then(result => {
//             //console.log(result);
//         })
//           .catch((error) => console.error(error));
//     }

//     const orderDispatchedNotificationAuth = (name, id, dac) => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "phoneNumber":data.order.whatsapp,
//             "templateName": "delivery_auths_code",
//             "headerId": "https://dealer.wallicon.in/assets/dispatched.jpg",
//             "buttonDataPayload": paymentLink,
//             "dealerName": name,
//             "orderId": id,
//             "deliveryCode": dac
//         });

//         const requestOptions = {
//           method: "POST",
//           headers: myHeaders,
//           body: raw,
//           redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
//           .then((response) => response.json())
//           .then(result => {
//             //console.log(result);
//         })
//           .catch((error) => console.error(error));
//     }

//     useEffect(() => {
//         if (isImage) {
//             setImages(prevState => ([...prevState, ...isImage]))
//         }
//     }, [isImage])

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             event.preventDefault();
//             event.stopPropagation();
//         } else {
//             // if (printLabel.courierSlip||printLabel.delTrackingNo||courierSlip) {
//                 handleDispatched()
//             // }
//             // else {
//             //     alert('Please Upload Packing Slip OR Tracking Number')
//             // }
//         }
//     };

//     const handleItemImage = (itemNo, file) => {
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//         var formdata = new FormData();
//         formdata.append("image", file);

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: formdata,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + 'packing/itemimage/' + managerId + '/' + orderId + '/' + itemNo, requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === 'success') {
//                     setIsReloaded(Math.random())
//                 }
//             })
//             .catch(error => //console.log('error', error));
//     }

//     useEffect(() => {
//         if (data.order && data.order.shippingInfo && data.order.shippingInfo.pincode) {
//             setLableBtnLoading(true)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "keyword": data.order.shippingInfo.pincode,
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === "success") {
//                         setData(prevState => ({
//                             ...prevState,
//                             SubDistName: result.SubDistName[0] ? result.SubDistName[0] : "",
//                             districts: result.districts[0] ? result.districts[0] : "",
//                         }))
//                     }
//                     setLableBtnLoading(false)
//                 })
//                 .catch(error => //console.log('error', error));
//         }
//     }, [data.order])

//     const handleApproveCancel = () => {
//         setRequestAproveLoading(true)
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/cancelOrderApproved", requestOptions)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.status === "success") {
//                     setShowApproveModal(false)
//                     navigate('/dispatchdashboard')
//                     setRequestAproveLoading(false)
//                 } else {
//                     alert(result.msg)
//                     setShowApproveModal(false)
//                     setRequestAproveLoading(false)
//                 }
//             })
//             .catch((error) => console.error(error));
//     }

//     const handleDenyCancel = () => {
//         setRequestCancelLoading(true)
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/canceleOrderDeny", requestOptions)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.status === "success") {
//                     setShowDenyModal(false)
//                     window.location.reload()
//                     setRequestCancelLoading(false)
//                 } else {
//                     setShowDenyModal(false)
//                     setRequestCancelLoading(false)
//                 }
//             })
//             .catch((error) => console.error(error));
//     }

//     const handleCancelOrder = () => {
//         setRequestCancelLoading(true)
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId,
//             "cancelReason": cancelReason
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/cancelOrder", requestOptions)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.status === 'success') {
//                     setShowCancelOrderModal(false)
//                     window.location.reload()
//                     setRequestCancelLoading(false)
//                 } else {
//                     alert(result.msg)
//                     setShowCancelOrderModal(false)
//                     setRequestCancelLoading(false)
//                 }
//             })
//             .catch((error) => console.error(error));
//     }

//     const filterItem = (data.order && data.order.items && data.order.items.length > 0) && data.order.items.filter(item => (item.unit || item.qty > 0 || item.desc))
//     // //console.log('data', data.order.partnerName);

//     const transformItems = (items) => {
//         // Initialize an empty array for transformed items
//         const transformedItems = [];

//         // Iterate over each item in the items array
//         items && items.forEach((item) => {
//             if (item.itemNo) {
//                 // If the item has an itemNo, add it to the transformedItems array
//                 transformedItems.push({
//                     itemNo: item.itemNo,
//                     qty: item.qty,
//                     unit: item.unit,
//                     product_name: item.product_name,
//                     thumbnail: item.thumbnail,
//                     warehouse: item.warehouse,
//                     accessories: []
//                 });
//             } else if (item.accessoriesName) {
//                 // If the item has an accessoriesName, find the last added item with an itemNo
//                 const lastItem = transformedItems[transformedItems.length - 1];

//                 // Add the accessory to the accessories array of the last item
//                 lastItem.accessories.push({
//                     accessoriesName: item.accessoriesName,
//                     accessoriesPrice: item.price,
//                     accessoriesQty: item.qty,
//                     _id: Math.random().toString(36).substring(2, 15)
//                 });
//             }
//         });

//         return transformedItems;
//     };

//     const transformedItems = transformItems(filterItem);
//     return (
//         loading ?
//             <div className={'Mob_dispatch_loading'}>
//                 <img src='/wallicon.gif' alt='walliconGIF' />
//             </div> :
//             <React.Fragment>
//                 <DispatchModal
//                     show={showAcceptModal}
//                     close={setShowAcceptModal}
//                     closeBtn={true}
//                     heading="DISPATCH ORDER STATUS"
//                     content={
//                         <div className={styles.modalMaindiv}>
//                             <p className={styles.message}>{msgShow}</p>
//                             <div className={styles.massageModal_btn}>
//                                 <RedOutButton title="CANCEL" handleSubmit={() => {
//                                     data.order.paymentMethod === 'POD' ? orderPlacedPod() : orderPlacedNotification();
//                                     setTimeout(() => {
//                                         setShowAcceptModal(false); window.location.reload()
//                                     }, 1000);
//                                 }} />
//                                 <GreenOutButton title="OK" handleSubmit={() => {
//                                     data.order.paymentMethod === 'POD' ? orderPlacedPod() : orderPlacedNotification();
//                                     setTimeout(() => {
//                                         setShowAcceptModal(false); window.location.reload()
//                                     }, 1000);
//                                 }} />
//                             </div>
//                         </div>
//                     }
//                 />
//                 <DispatchModal
//                     show={showPackedModal}
//                     close={setShowPackedModal}
//                     // setImage={setImage}
//                     heading="PACKING STATUS"
//                     content={
//                         <form onSubmit={handlePacked} className={styles.text_center}>
//                             <div className={styles.main_div}>
//                                 <div className={styles.flexdiv}>
//                                     <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
//                                     <input type="number"
//                                         name="packs"
//                                         value={packs}
//                                         // setPacks
//                                         onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
//                                         onChange={(e) => {
//                                             setPacks(e.target.value)

//                                             if (check && e.target.value && (images.length > parseInt(e.target.value))) {
//                                                 const values = [...images];
//                                                 values.splice(point, (images.length - (parseInt(e.target.value) === 0 ? 1 : parseInt(e.target.value))));
//                                                 setImages(values);
//                                             }
//                                         }}
//                                         className={styles.input}
//                                         required
//                                         autoFocus={true}
//                                     />
//                                 </div>

//                                 <div className={styles.checkBox_addImage}>
//                                     <input
//                                         type="checkbox"
//                                         name="vehicle1"
//                                         value="check"
//                                         className={styles.checkbox}
//                                         checked={check ? true : false}
//                                         onChange={(e) => {
//                                             if (e.target.checked && packs) {
//                                                 addFormFields()
//                                                 setCheck(true)
//                                             } else {
//                                                 const values = [...images];
//                                                 values.splice(point, point.length);
//                                                 setImages(values);
//                                                 setCheck(false)
//                                             }
//                                         }}
//                                     />
//                                     <label htmlFor="vehicle1">&nbsp;&nbsp;Add image of every pack</label>
//                                 </div>

//                                 <div className={styles.dragAndDrop_div}>
//                                     <FileHandlerMob
//                                         setisImage={setisImage}
//                                         title="Upload Image"
//                                     />
//                                 </div>
//                                 <div>
//                                     {images && images.map((item, index) =>
//                                         <div key={index} className={styles.flexdiv}>
//                                             <span>Pack {index + 1}</span>

//                                             {item.name ? <img src={URL.createObjectURL(item)} alt="" className={styles.fileimg}
//                                                 onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")}
//                                             /> :
//                                                 <img
//                                                     src='/assets/packingImage.png'
//                                                     alt='packing_order_image'
//                                                     className={styles.packingImage}
//                                                 />
//                                             }
//                                             <div className={styles.view_packingImage} onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")} >View</div>
//                                             <div className={styles.deletediv}
//                                                 onClick={e => {
//                                                     let data = [...images];
//                                                     data[index] = {};
//                                                     setImages(data);
//                                                 }}
//                                             >
//                                                 <DeleteFile color={"red"} />
//                                                 <p className={styles.deleteicon_css}>Delete</p>
//                                             </div>
//                                             {images.length !== 1 &&
//                                                 <div className={styles.deletediv}
//                                                     onClick={e => {
//                                                         setPacks(parseInt(packs) - 1)
//                                                         const list = [...images];
//                                                         list.splice(index, 1);
//                                                         setImages(list);
//                                                     }}
//                                                 >
//                                                     <span className={styles.crossIcon_css}>&#10006;</span>
//                                                 </div>
//                                             }
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                             <div className={styles.flexdiv}>
//                                 <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => setShowPackedModal(false)} />
//                                 <GreenOutButton btnType="submit" title={packingLoading ? <Spinner size="15" /> : 'OK'} />
//                             </div>
//                         </form>
//                     }
//                 />

//                 <Modal show={showDeliveryModal} className={'dispatchModal'} onHide={() => setShowDeliveryModal(false)}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>Delivery Details</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body className='pb-0'>
//                         <form onSubmit={handleSubmit}>

//                             <div className='position-relative mb-2'>
//                                 <label className={styles.inputLabel}>Delivery Method</label>
//                                 <div className={styles.inputdiv}>{delivery.courierName}</div>
//                             </div>
//                             <div className='position-relative mb-2'>
//                                 <label className={styles.inputLabel}>Sub-Method</label>
//                                 <div className={styles.inputdiv}>{delivery.methodName}</div>
//                             </div>
//                             <div className='position-relative mb-2'>
//                                 <label className={styles.inputLabel}>Delivery Partner</label>
//                                 <select
//                                     // className={`${switchDisplay ? styles.inputcolor : styles.input}`}
//                                     className={styles.inputdiv}
//                                     value={delivery.partnerName}
//                                     onChange={(e) => {
//                                         setDelivery(prevState => ({
//                                             ...prevState,
//                                             partnerName: e.target.value
//                                         }));
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             delPerName: partners && partners.find(x => x.partnerName === e.target.value)?.partnerName,
//                                             delPerMobile: partners && partners.find(x => x.partnerName === e.target.value)?.contactNumber,
//                                             delTrackingNo: partners && partners.find(x => x.partnerName === e.target.value)?.trackingNumber,
//                                             delTrackingUrl: partners && partners.find(x => x.partnerName === e.target.value)?.trackingUrl,
//                                         }));
//                                     }}
//                                     required
//                                 >

//                                     <option value='' hidden>Select Partner</option>
//                                     {partners && partners.map((obj, index) =>
//                                         <option key={index} value={obj.partnerName}>{obj.partnerName}</option>
//                                     )}

//                                 </select>
//                             </div>

//                             <div className='position-relative mb-2'>
//                                 <label className={styles.inputLabel}>Delivery Person</label>
//                                 <input
//                                     className={styles.inputdiv}
//                                     name="name"
//                                     type="text"
//                                     // placeholder='Name'
//                                     value={printLabel.delPerName}
//                                     onChange={(e) => {
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             delPerName: e.target.value
//                                         }))
//                                     }}
//                                 />
//                             </div>
//                             <div className='position-relative mb-2'>
//                                 <label className={styles.inputLabel}>Contact Number</label>
//                                 <input
//                                     className={styles.inputdiv}
//                                     name="mobile"
//                                     type="number"
//                                     // placeholder='Mobile Number'
//                                     value={printLabel.delPerMobile}
//                                     onChange={(e) => {
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             delPerMobile: e.target.value
//                                         }))
//                                     }}
//                                     onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
//                                 />
//                             </div>
//                             <div className='position-relative mb-2'>
//                                 <label className={styles.inputLabel}>Tracking Number</label>
//                                 <input
//                                     className={styles.inputdiv}
//                                     name="name"
//                                     type="text"
//                                     // placeholder='Tracking Number'
//                                     value={printLabel.delTrackingNo}
//                                     onChange={(e) => {
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             delTrackingNo: e.target.value
//                                         }))
//                                     }}
//                                 />
//                             </div>
//                             <div className='position-relative'>
//                                 <label className={styles.inputLabel}>Tracking URL</label>
//                                 <input
//                                     className={styles.inputdiv}
//                                     name="name"
//                                     type="text"
//                                     // placeholder='Tracking URL'
//                                     value={printLabel.delTrackingUrl}
//                                     onChange={(e) => {
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             delTrackingUrl: e.target.value
//                                         }))
//                                     }}
//                                 />
//                             </div>
//                             <h2 className={styles.modal_heading}>Or</h2>
//                             <div className='mb-3'>
//                                 {courierSlip ?
//                                     <div className={styles.courierSlip}>
//                                         <label onClick={() => { setCourierSlip("") }}>X</label>
//                                         <img src={courierSlip.name ? URL.createObjectURL(courierSlip)
//                                             : process.env.REACT_APP_S3URL + courierSlip}
//                                             alt='Courier Slip'
//                                             height={86}
//                                             className={`w-100 ${styles.courierSlip_image}`}
//                                             onClick={() => {
//                                                 window.open(courierSlip.name ? URL.createObjectURL(courierSlip)
//                                                     : process.env.REACT_APP_S3URL + courierSlip)
//                                             }}
//                                         />
//                                     </div>
//                                     :
//                                     <FileHandlerMob
//                                         setisImage={(item) => setCourierSlip(item[0])}
//                                         styles={styles}
//                                         title="Upload Courier Slip"
//                                     />
//                                 }
//                             </div>
//                             <div className={styles.dispatchModalBtm}>
//                                 <RedOutButton btnType="button" title={"CANCEL"} handleSubmit={() => { setShowDeliveryModal(false) }} />
//                                 <GreenOutButton btnType="submit" title={data.order && data.order.dispatchStatus === "Dispatched" ? "UPDATE DISPATCHED" : packingLoading ? <Spinner size="15" /> : "DISPATCHED"} />
//                             </div>
//                         </form>

//                     </Modal.Body>
//                 </Modal>

//                 <DispatchModal
//                     show={showLabelModal}
//                     close={setShowLabelModal}
//                     content={
//                         <div className={styles.modalMaindiv1}>
//                             <h2 className={styles.modal_heading}>Print Label</h2>
//                             <p className={styles.modaltext}>Are you sure you want to Print Label
//                                 & Marked as Packed?</p>
//                             <div className={styles.flexdiv}>
//                                 <p className={styles.sliptext}>Enter Number Of Packs</p>
//                                 <input
//                                     name="name"
//                                     type="number"
//                                     className={styles.input}
//                                     value={printLabel.qtyPacks}
//                                     onChange={(e) => {
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             qtyPacks: e.target.value
//                                         }))
//                                     }}
//                                 />
//                             </div>
//                             {detailShow &&
//                                 <form>
//                                     <label>Delivery Partner</label>
//                                     <input
//                                         className={styles.inputdiv}
//                                         name="name"
//                                         type="text"
//                                         placeholder='Name'
//                                         value={printLabel.delPerName}
//                                         onChange={(e) => {
//                                             setPrintLabel(prevState => ({
//                                                 ...prevState,
//                                                 delPerName: e.target.value
//                                             }))
//                                         }}
//                                     />
//                                     <label>Contact Number</label>
//                                     <input
//                                         className={styles.inputdiv}
//                                         name="mobile"
//                                         type="number"
//                                         placeholder='Mobile Number'
//                                         value={printLabel.delPerMobile}
//                                         onChange={(e) => {
//                                             setPrintLabel(prevState => ({
//                                                 ...prevState,
//                                                 delPerMobile: e.target.value
//                                             }))
//                                         }}
//                                     />
//                                     <label>Tracking Number</label>
//                                     <input
//                                         className={styles.inputdiv}
//                                         name="name"
//                                         type="text"
//                                         placeholder='Tracking Number'
//                                         value={printLabel.delTrackingNo}
//                                         onChange={(e) => {
//                                             setPrintLabel(prevState => ({
//                                                 ...prevState,
//                                                 delTrackingNo: e.target.value
//                                             }))
//                                         }}
//                                     />
//                                     <label>Tracking URL</label>
//                                     <input
//                                         className={styles.inputdiv}
//                                         name="name"
//                                         type="text"
//                                         placeholder='Tracking URL'
//                                         value={printLabel.delTrackingUrl}
//                                         onChange={(e) => {
//                                             setPrintLabel(prevState => ({
//                                                 ...prevState,
//                                                 delTrackingUrl: e.target.value
//                                             }))
//                                         }}
//                                     />
//                                 </form>
//                             }
//                             <div className={styles.flexdiv}>
//                                 <RedOutButton title={"CANCEL"} css='py-2' handleSubmit={() => { setShowLabelModal(false) }} />
//                                 <div className={styles.printicon}>
//                                     <GreenOutButton css={!printLabel.qtyPacks ? styles.disableBtn : 'py-2'} title={"PRINT"} disabled={printLabel.qtyPacks ? false : true} handleSubmit={() => { handlePrintLabel() }} />
//                                 </div>
//                                 <div style={{ display: "none" }}>
//                                     <PrintLabel
//                                         data={data.order}
//                                         qtyPacks={printLabel.qtyPacks}
//                                         ref={labelRef}
//                                         handlePrint={handlePrint}
//                                         SubDistName={data.SubDistName}
//                                         districts={data.districts}
//                                     />
//                                 </div>
//                             </div>
//                             <div className={styles.flexdiv}>
//                                 <p> Add Delivery Details</p>
//                                 <div onClick={() => setDetailShow(!detailShow)}>{detailShow ? <UpArrowCircle /> : <DownArrowCircle />}</div>
//                             </div>
//                         </div>
//                     }
//                 />

//                 <DispatchModal
//                     show={showApproveModal}
//                     close={setShowApproveModal}
//                     closeBtn={true}
//                     heading={data.order && (data.order.reqType === 'Request For Cancel' ? "CANCEL ORDER REQUEST" : "EDIT ORDER REQUEST")}
//                     content={
//                         <div className={styles.text_center}>
//                             <p className={styles.message_cancelOrder}>Are you sure you want to Approve this {data.order && (data.order.reqType === 'Request For Cancel' ? 'Cancel' : "Edit")} Order Request?</p>
//                             <div className={styles.massageModal_btn}>
//                                 <RedOutButton css={styles.moveToSale_btn_no} title="NO" handleSubmit={() => setShowApproveModal(false)} />
//                                 <GreenButton css={`${styles.moveToSale_btn} ms-4`} title={requestAproveLoading ? <Spinner size="15" /> : "YES"} handleSubmit={() => { if (data.order && (data.order.reqType === 'Request For Cancel')) { handleApproveCancel() } }} />
//                             </div>
//                         </div>
//                     }
//                 />

//                 <DispatchModal
//                     show={showDenyModal}
//                     close={setShowDenyModal}
//                     closeBtn={true}
//                     heading={data.order && (data.order.reqType === 'Request For Cancel' ? "DENY CANCEL ORDER REQUEST" : "DENY EDIT ORDER REQUEST")}
//                     content={
//                         <div className={styles.text_center}>
//                             <p className={styles.message_cancelOrder}>Are you sure you want to Deny this {data.order && (data.order.reqType === 'Request For Cancel' ? 'Cancel' : "Edit")} Order Request?</p>
//                             <div className={styles.massageModal_btn}>
//                                 <RedOutButton css={styles.moveToSale_btn_no} title="NO" handleSubmit={() => setShowDenyModal(false)} />
//                                 <GreenButton css={`${styles.moveToSale_btn} ms-4`} title={requestCancelLoading ? <Spinner size="15" /> : "YES"} handleSubmit={() => { if (data.order && (data.order.reqType === 'Request For Cancel')) { handleDenyCancel() } }} />
//                             </div>
//                         </div>
//                     }
//                 />
//                 <DispatchModal
//                     show={showCancelOrderModal}
//                     close={setShowCancelOrderModal}
//                     closeBtn={true}
//                     heading="CANCEL ORDER"
//                     content={
//                         <div className={styles.text_center}>
//                             <p className={styles.message_cancelOrder}>Are You Sure Want To Cancel This Order</p>
//                             <textarea type="text" placeholder='Enter Cancel Reason' className={styles.reqIssue} value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} />
//                             <div className={styles.massageModal_btn}>
//                                 <RedOutButton css={styles.moveToSale_btn_no} title="NO" handleSubmit={() => setShowCancelOrderModal(false)} />
//                                 <GreenButton css={`${styles.moveToSale_btn} ms-4`} title={requestCancelLoading ? <Spinner size="15" /> : "YES"} handleSubmit={() => { handleCancelOrder(); }} />
//                             </div>
//                         </div>
//                     }
//                 />

//                 <form
//                     onSubmit={handleAcceptOrder}>
//                     <div className={styles.dispatch_main}>
//                         <div className={styles.dispatch_scroll}>

//                             <div className={styles.header_main}>
//                                 <div className='d-flex justify-content-between align-items-center'>
//                                     {((data.order.dispatchStatus === "Packed") || (data.order.dispatchStatus === "Dispatched") || (data.order.dispatchStatus === "Pending")) && lableBtnLoading ?
//                                         <Spinner size="15" /> :
//                                         <>
//                                             {(data.order.dispatchStatus !== "Cancelled") &&
//                                                 <div className={`${styles.printicon} d-flex me-3`}
//                                                     onClick={() => { setShowLabelModal(true); }}
//                                                 >
//                                                     <b><u>Label</u></b>&nbsp;&nbsp;
//                                                     <BlackPrint color={"#000000"} />

//                                                 </div>
//                                             }
//                                         </>
//                                     }
//                                     {((data.order.pmId === parseInt(managerId)) || (designation === 'superadmin')) && (data.order.packingStatus !== "Packed") && (data.order.dispatchStatus !== "Cancelled") &&
//                                         <ReactToPrint
//                                             trigger={() =>
//                                                 <div className={`${styles.printicon} d-flex`}>
//                                                     <b><u>Print Slip</u></b>&nbsp;&nbsp;
//                                                     <BlackPrint color={"#000000"} />
//                                                 </div>
//                                             }
//                                             content={() => componentRef}
//                                         />
//                                     }
//                                     <div style={{ display: "none" }}>
//                                         <PackingSlip
//                                             orderId={orderId}
//                                             managerId={managerId}
//                                             delivery={delivery}
//                                             ref={(el) => (componentRef = el)}
//                                         />
//                                     </div>

//                                 </div>
//                                 <div className='d-flex flex-column '>
//                                     <div className='d-flex'>
//                                         <span className={styles.dispatch_status}>Dispatch Status&nbsp;&nbsp;</span>
//                                         <div className={`${styles.status_height} ${(data.order.dispatchStatus === "New Order") ? styles.status_blue :
//                                             (data.order.dispatchStatus === "Packed" || data.order.dispatchStatus === "Dispatched") ? styles.statuscolor3 :
//                                                 (data.order.dispatchStatus === "Not Packed") ? styles.statuscolor1 :
//                                                     (data.order.dispatchStatus === 'In Progress' || data.order.dispatchStatus === 'Pending') ?
//                                                         styles.status : ''
//                                             }`}>

//                                             {data.order.dispatchStatus}
//                                         </div>
//                                     </div>

//                                     <div className='d-flex'>
//                                         <span className={styles.dispatch_status}>Packing Status&nbsp;&nbsp;</span>
//                                         <div className={`${styles.status_height} ${(data.order.packingStatus === "New Order") ? styles.status_blue :
//                                             (data.order.packingStatus === "Packed" || data.order.packingStatus === "Dispatched") ? styles.statuscolor3 :
//                                                 (data.order.packingStatus === "Not Packed") ? styles.statuscolor1 :
//                                                     (data.order.packingStatus === 'In Progress' || data.order.packingStatus === 'Pending') ?
//                                                         styles.status : ''
//                                             }`}>
//                                             {data.order.packingStatus}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                             <h2 className={styles.packingDetailsHeading}>
//                                 {data.order && data.order.storeName}
//                             </h2>
//                             <div className={styles.orderId_date}>
//                                 <div>Order ID: <b>{orderId}</b></div>
//                                 <div>Dated: <b>{data.order && data.order.orderDate}</b></div>
//                             </div>

//                             <div className={styles.items_div}>
//                                 {transformedItems && transformedItems.map((item, index) =>
//                                     <div key={index} className={`${styles.itemCard} flex-column`}>
//                                         <div className='d-flex w-100'>
//                                             <div>
//                                                 {item.thumbnail ?
//                                                     <img
//                                                         src={process.env.REACT_APP_S3URL + item.thumbnail} className={styles.thumbnail_itemImage} alt={item.itemNo}
//                                                         onClick={() => window.open(process.env.REACT_APP_S3URL + item.thumbnail, "mozillaWindow", "popup")}
//                                                     /> :
//                                                     <div className={styles.NoImageIcon}><NoImageIcon /></div>
//                                                 }
//                                             </div>
//                                             <div className={styles.flexMA}>
//                                                 <div className={styles.packingDetails_div}><b>{item.itemNo || item.accessoriesName}</b><span className={styles.productName}>{item.product_name}</span></div>
//                                                 <b>{item.qty}&nbsp;{item.unit}</b>
//                                                 <div className={styles.warehouseName}>{item.warehouse}</div>
//                                                 {item.desc && <div className={styles.packingDetails_div}><b><span>Description:</span> <span>{item.desc}</span></b></div>}
//                                             </div>
//                                             <div>
//                                                 {item.packingImg ?
//                                                     <img src={
//                                                         item.packingImg ? process.env.REACT_APP_S3URL + item.packingImg
//                                                             : "/assets/img.png"}
//                                                         alt="img" className={styles.itemImg}
//                                                     />
//                                                     :
//                                                     <>
//                                                         {!item.accessoriesName &&
//                                                             <label className={styles.bannerAddBtn}>
//                                                                 <Camera />
//                                                                 <input
//                                                                     name="bannerImg"
//                                                                     type="file"
//                                                                     className={styles.bannerImgInput}
//                                                                     id="banner"
//                                                                     onChange={(e) => {
//                                                                         //setPackingImage(e.target.files[0]);
//                                                                         handleItemImage(item.itemNo, e.target.files[0])
//                                                                     }}
//                                                                     accept="image/*"
//                                                                 />
//                                                             </label>}
//                                                     </>

//                                                 }
//                                             </div>
//                                         </div>
//                                         {(item.accessories && item.accessories.length !== 0) && <>
//                                             <div className={styles.accessories_heading}>Accessories</div>
//                                             {(item.accessories && item.accessories.length !== 0) &&
//                                                 item.accessories.map((obj, i) =>
//                                                     <div className={styles.itemCard_clip_price}><span>{item.accessories[i].accessoriesName}&nbsp;&nbsp;&nbsp;&nbsp;{item.accessories[i].accessoriesQty}&nbsp;pcs</span></div>
//                                                 )}
//                                         </>}

//                                     </div>
//                                 )}
//                             </div>

//                             <div className={styles.contactCard}>
//                                 <h2 className={styles.packingDetailsHeading}>
//                                     {data.order.shippingInfo.name && data.order.shippingInfo.name}
//                                 </h2>
//                                 <div className='d-flex'>
//                                     <div className={styles.contact_details_icon}><LocationIcon /></div>
//                                     {data.order &&
//                                         <span className={styles.contact_details}>
//                                             {data.order.shippingInfo &&
//                                                 (data.order.shippingInfo.street + ", " + data.order.shippingInfo.city + ", " + data.order.shippingInfo.state + ", " + data.order.shippingInfo.pincode)
//                                             }
//                                         </span>
//                                     }
//                                 </div>
//                                 <div className='d-flex'>
//                                     <div className={styles.contact_details_icon}><Phone /></div>
//                                     <span className={styles.contact_details}>+91-{data.order.shippingInfo && data.order.shippingInfo.mobile}</span>
//                                 </div>
//                                 {data.order && data.order.gstuin &&
//                                     <div className='d-flex'>
//                                         <div className={styles.contact_details_icon}><GSTIcon /></div>
//                                         <span className={styles.contact_details}>{data.order && data.order.gstuin}</span>
//                                     </div>
//                                 }
//                                 <div className='d-flex'>
//                                     <div className={styles.contact_details_icon}><Email /></div>
//                                     <span className={styles.contact_details}>{data.order && data.order.email}</span>
//                                 </div>
//                             </div>

//                             <div className={styles.diliveryCard}>
//                                 <h2 className={styles.packingDetailsHeading}>
//                                     Delivery Details
//                                 </h2>

//                                 <div className={styles.packingDetails_txt}>
//                                     <div className={styles.ordermanagerName}>Delivery Type</div>
//                                     <div className={styles.contactsDiv}>{data.order && data.order.shippingType}</div>
//                                 </div>
//                                 <div className={styles.packingDetails_txt}>
//                                     <div className={styles.ordermanagerName}>Delivery Method</div>
//                                     <div className={styles.contactsDiv}>{delivery.courierName}</div>
//                                 </div>
//                                 <div className={styles.packingDetails_txt}>
//                                     <div className={styles.ordermanagerName}>Sub-Method</div>
//                                     <div className={styles.contactsDiv}>{delivery.partnerName}</div>
//                                 </div>

//                                 <div className={styles.packingDetails_txt}>
//                                     <div className={styles.ordermanagerName}>Order Manager<b>&nbsp;
//                                         ({data.order && data.order.ordermanager})
//                                     </b></div>
//                                     {data.order.managercontact &&
//                                         <div className={styles.contactsDiv}>
//                                             <PhoneMob color={"#000000"} />
//                                             <a href={`tel:+91${data.order.managercontact}`}>
//                                                 <b className='ps-1'>+91-{data.order.managercontact}</b>
//                                             </a>
//                                             <div>
//                                                 <a className={styles.whatsappIcon} target="_blank" rel="noreferrer" href={`https://wa.me/+91${data.order.managercontact}`}>
//                                                     <WhatsApp />
//                                                 </a>
//                                             </div>
//                                         </div>
//                                     }
//                                 </div>
//                                 <div className={styles.packingDetails_txt}>
//                                     <div className={styles.ordermanagerName}>Packing Manager<b>&nbsp;
//                                         {data.order.packermanager && (`${data.order.packermanager}`)}
//                                     </b></div>
//                                     {data.order.pmcontact &&
//                                         <div className={styles.contactsDiv}>
//                                             <PhoneMob color={"#000000"} />
//                                             <a href={`tel:+91${data.order.pmcontact}`}>
//                                                 <b className='ps-1'>+91-{data.order.pmcontact}</b>
//                                             </a>
//                                             <a className={styles.whatsappIcon} target="_blank" rel="noreferrer" href={`https://wa.me/+91${data.order.pmcontact}`}>
//                                                 <WhatsApp />
//                                             </a>
//                                         </div>
//                                     }
//                                 </div>

//                             </div>
//                         </div>

//                         <div className={styles.dispatchFooter}>
//                             <div className={styles.flexdiv1}>
//                                 <span><b>Select Packer</b></span>
//                                 <select className={styles.select_packer}
//                                     onChange={(e) => setPacker(e.target.value)}
//                                     value={packer}
//                                     required
//                                 >
//                                     <option value={managerId} >Self</option>
//                                     {data.pakingManagers && data.pakingManagers.map((item, index) =>
//                                         <option
//                                             key={index}
//                                             className={styles.selecthover}
//                                             value={item.managerId}
//                                         >
//                                             {item.name}
//                                         </option>
//                                     )}
//                                 </select>
//                             </div>
//                             <div className={styles.dispatchFooter_button}>
//                                 <RedOutButton css={`${styles.cencelbtn} ${data.order.dispatchStatus === 'Cancelled' && 'mx-auto'}`} btnType="button" title={"BACK"} handleSubmit={() => { window.history.back() }} />
//                                 {data.order.reqStatus === '' && data.order.dispatchStatus !== 'New Order' && data.order.dispatchStatus !== 'Cancelled' &&
//                                     <RedOutButton
//                                         // disabled={false}
//                                         btnType="button"
//                                         title='CANCEL ORDER'
//                                         css={styles.cencelbtn}
//                                         handleSubmit={() => { setShowCancelOrderModal(true) }}
//                                     />
//                                 }
//                                 {data.order.dispatchStatus === 'New Order' ?
//                                     <GreenOutButton btnType="submit" title={"ACCEPT"} css={styles.greenOutbtn} />
//                                     :
//                                     data.order.pmId === parseInt(managerId) && data.order.packingStatus === "Not Packed" ?
//                                         <>
//                                             <>
//                                                 {
//                                                     data.order.reqStatus === "Pending" ?
//                                                         <>
//                                                             {
//                                                                 data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
//                                                                 <GreenOutButton
//                                                                     btnType="button"
//                                                                     title={"APPROVE CANCEL"}
//                                                                     handleSubmit={() => { setShowApproveModal(true) }}
//                                                                     css={styles.greenOutbtn}
//                                                                 />
//                                                             }
//                                                             {
//                                                                 data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
//                                                                 <RedOutButton
//                                                                     btnType="button"
//                                                                     title={"DENY CANCEL"}
//                                                                     handleSubmit={() => { setShowDenyModal(true) }}
//                                                                     css={styles.cencelbtn}
//                                                                 // css='px-3 py-2'
//                                                                 />
//                                                             }
//                                                         </>
//                                                         :
//                                                         <>
//                                                             {data.order.dispatchStatus !== "Cancelled" &&
//                                                                 <>
//                                                                     <GreenOutButton
//                                                                         btnType="button"
//                                                                         title={"PACKED"}
//                                                                         handleSubmit={() => { setShowPackedModal(true) }}
//                                                                         css={styles.greenOutbtn}
//                                                                     />
//                                                                 </>
//                                                             }
//                                                         </>
//                                                 }
//                                             </>
//                                         </>
//                                         :
//                                         <>
//                                             {/* <Link to={'/viewdispatchdetails/' + orderId} className={styles.viewDetail_btn}>View Dispatch Details</Link> */}
//                                             {data.order.dispatchStatus === "Dispatched" ?
//                                                 <Link to={'/viewdispatchdetails/' + orderId} className={styles.viewDetail_btn}>View Dispatch Details</Link>
//                                                 :
//                                                 data.order.reqStatus === "Pending" ?
//                                                     <>
//                                                         {
//                                                             data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
//                                                             <GreenOutButton
//                                                                 btnType="button"
//                                                                 title={"APPROVE CANCEL"}
//                                                                 handleSubmit={() => { setShowApproveModal(true) }}
//                                                                 css={styles.greenOutbtn}
//                                                             />
//                                                         }
//                                                         {
//                                                             data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
//                                                             <RedOutButton
//                                                                 btnType="button"
//                                                                 title={"DENY CANCEL"}
//                                                                 handleSubmit={() => { setShowDenyModal(true) }}
//                                                                 css={styles.cencelbtn}
//                                                             // css='px-3 py-2'
//                                                             />
//                                                         }
//                                                     </>
//                                                     :
//                                                     <>
//                                                         {data.order.dispatchStatus !== "Cancelled" &&
//                                                             <GreenOutButton css={styles.cencelbtn} btnType="button"
//                                                                 title="DELIVERY DETAILS"
//                                                                 handleSubmit={() => {
//                                                                     setPrintLabel(prevState => ({
//                                                                         ...prevState,
//                                                                         delPerName: partners && partners.find(x => x.partnerName === delivery.partnerName)?.partnerName,
//                                                                         delPerMobile: partners && partners.find(x => x.partnerName === delivery.partnerName)?.contactNumber,
//                                                                         delTrackingNo: partners && partners.find(x => x.partnerName === delivery.partnerName)?.trackingNumber,
//                                                                         delTrackingUrl: partners && partners.find(x => x.partnerName === delivery.partnerName)?.trackingUrl,
//                                                                     }));
//                                                                     setShowDeliveryModal(true);
//                                                                     generatePaymentLink()
//                                                                 }}
//                                                             />
//                                                         }
//                                                     </>
//                                             }

//                                         </>
//                                 }
//                             </div>
//                         </div>

//                     </div>
//                 </form>
//             </React.Fragment >
//     )
// }
// export default DispatchOrder;

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import {
  GreenButton,
  GreenOutButton,
  RedOutButton,
} from "../../components/Buttons";
import { useAuth } from "../../components/context/Auth";
import {
  BlackPrint,
  Camera,
  DeleteFile,
  DownArrowCircle,
  Email,
  GSTIcon,
  LocationIcon,
  NoImageIcon,
  Phone,
  PhoneMob,
  Spinner,
  UpArrowCircle,
  WhatsApp,
} from "../../components/icons/Icon";
import styles from "./DispatchBoy.module.css";
import DispatchModal from "../components/DispatchModal";
import { PackingSlip } from "../../pages/dispatchmanager/PackingSlip";
import { PrintLabel } from "../../pages/dispatchmanager/PrintLabel";
import { FileHandlerMob } from "../components/DragAndDropMob";
import { Modal } from "react-bootstrap";
// import CropModal from '../../components/CropModal';

const DispatchOrder = () => {
  const navigate = useNavigate();
  let componentRef = React.useRef();
  const { managerId, designation } = useAuth();
  const params = useParams();
  const orderId = params.orderId;
  const [loading, setLoading] = useState(true);
  const [packingLoading, setPackingLoading] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [msgShow, setMsgShow] = useState("");
  const [packer, setPacker] = useState(managerId);
  // const [couriers, setCouriers] = useState([])
  const [partners, setPartners] = useState("");
  const [data, setData] = useState({});
  const [isReloaded, setIsReloaded] = useState(false);
  const [lableBtnLoading, setLableBtnLoading] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [requestCancelLoading, setRequestCancelLoading] = useState(false);
  const [requestAproveLoading, setRequestAproveLoading] = useState(false);

  // const [itemNo, setItemNo] = useState('')

  const [isImage, setisImage] = React.useState("");

  const [delivery, setDelivery] = useState({
    courierName: "",
    partnerName: "",
  });
  const [printLabel, setPrintLabel] = useState({
    qtyPacks: "",
    delPerName: "",
    delPerMobile: "",
    delTrackingNo: "",
    delTrackingUrl: "",
  });
  const [courierSlip, setCourierSlip] = useState("");
  const [detailShow, setDetailShow] = useState(false);
  const [showPackedModal, setShowPackedModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [images, setImages] = useState([]);
  const [packs, setPacks] = useState();
  const [point, setPoint] = useState();

  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        orderId: orderId,
        courierName: delivery.courierName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData(result.data);
            setPacker(result.data.order.pmId);
            // setCouriers(result.data.couriers)
            setPartners(result.data.courierPartners);
            setPrintLabel((prevState) => ({
              ...prevState,
              qtyPacks: result.data.order.qtyPacks,
              delPerName: result.data.order.delPerName,
              delPerMobile: result.data.order.delPerMobile,
              delTrackingNo: result.data.order.delTrackingNo,
              delTrackingUrl: result.data.order.delTrackingUrl,
            }));
            if (!delivery.courierName) {
              setDelivery((prevState) => ({
                ...prevState,
                courierName: result.data.order.courierName,
                partnerName: result.data.order.partnerName,
                shippingType: result.data.order.shippingType,
                methodName: result.data.order.methodName,
              }));
            }
            setCourierSlip(result.data.order.courierSlip);
          }
          setLoading(false);
        })
        .catch((error) => {})

    }
  }, [orderId, managerId, delivery.courierName, isReloaded]);

  // -----------------Courier---------//
  const handleSendLink = async () => {
    setPaymentLink("");
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      orderId: orderId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_URL + "receipt/generatePaymentLink",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setPaymentLink(result.shortUrl);
        }
      })
      .catch((error) => {})

  };
  const handleAcceptOrder = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      pmId: packer ? packer : managerId,
      courierName: delivery.courierName,
      partnerName: delivery.partnerName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/acceptOrder", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          handleSendLink();
        }
        setMsgShow(result.msg);
        setShowAcceptModal(true);
      })
      .catch((error) => {})

  };

  const orderPlacedNotification = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: data.order.whatsapp,
      templateName: "online_order_placed",
      headerId: "https://dealer.wallicon.in/assets/order_placed.jpg",
      buttonDataPayload: paymentLink,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
      })
      .catch((error) => console.error(error));
  };

  const orderPlacedPod = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: data.order.whatsapp,
      templateName: "order_placed_pod",
      headerId: "https://dealer.wallicon.in/assets/order_placed.jpg",
      buttonDataPayload: paymentLink,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (packs) {
      setPoint(
        Array.from(
          Array(
            packs === "0"
              ? 1
              : packs === "1"
              ? 1
              : packs === "2"
              ? 2
              : parseInt(packs - 1)
          ).keys()
        )
      );
    }
  }, [packs]);

  const addFormFields = () => {
    point.forEach((element) => {
      setImages((prevState) => [...prevState, {}]);
    });
  };

  useEffect(() => {
    if (check && images.length < parseInt(packs)) {
      point.forEach((element) => {
        setImages((prevState) => [
          ...prevState.slice(0, images.length),
          {},
          // ...images.slice(index + 1)
        ]);
      });
    }
  }, [check, images.length, packs, point]);

  const handlePrintLabel = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      qtyPacks: printLabel.qtyPacks,
      // delPerName: printLabel.delPerName,
      // delPerMobile: printLabel.delPerMobile,
      // delTrackingNo: printLabel.delTrackingNo,
      // delTrackingUrl: printLabel.delTrackingUrl,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          handlePrint();
          setShowLabelModal(false);
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {})

  };

  const handlePacked = (e) => {
    setPackingLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("packingQty", packs);
    // formdata.append("images", images[0]);
    images && images.map((image) => formdata.append("images", image));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL +
        "packing/orderPacked/" +
        managerId +
        "/" +
        orderId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          window.location.reload();
          packingLoading(false);
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => {})

  };

  const labelRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => labelRef.current,
  });

  const generatePaymentLink = () => {
    setPaymentLink("");
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      orderId: orderId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "receipt/generatePaymentLink",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setPaymentLink(result.shortUrl);
        }
      })
      .catch((error) => {})

  };

  const handleDispatched = () => {
    setPackingLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("shippingType", delivery.shippingType);
    formdata.append("courierName", delivery.courierName);
    formdata.append("partnerName", delivery.partnerName);

    formdata.append("delPerName", printLabel.delPerName);
    formdata.append("delPerMobile", printLabel.delPerMobile);
    formdata.append("delTrackingNo", printLabel.delTrackingNo);
    formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
    formdata.append("image", courierSlip);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_URL +
        "dispatch/deliveryDetails/" +
        managerId +
        "/" +
        orderId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          if (result.data.dac === null) {
            orderDispatchedNotification();
          } else {
            orderDispatchedNotificationAuth(
              result.data.shippingInfo.name,
              result.data.orderId,
              result.data.dac
            );
          }
          setShowDeliveryModal(false);
          setIsReloaded(Math.random());
        }
        alert(result.msg);
      })
      .catch((error) => {})

  };

  const orderDispatchedNotification = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: data.order.whatsapp,
      templateName: "online_order_dispatched",
      // "headerId": "https://dealer.wallicon.in/assets/dispatched.jpg",
      headerId: "https://dealer.wallicon.in/assets/dispatched.jpg",
      buttonDataPayload: paymentLink,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
      })
      .catch((error) => console.error(error));
  };

  const orderDispatchedNotificationAuth = (name, id, dac) => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: data.order.whatsapp,
      templateName: "delivery_auths_code",
      headerId: "https://dealer.wallicon.in/assets/dispatched.jpg",
      buttonDataPayload: paymentLink,
      dealerName: name,
      orderId: id,
      deliveryCode: dac,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (isImage) {
      setImages((prevState) => [...prevState, ...isImage]);
    }
  }, [isImage]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // if (printLabel.courierSlip||printLabel.delTrackingNo||courierSlip) {
      handleDispatched();
      // }
      // else {
      //     alert('Please Upload Packing Slip OR Tracking Number')
      // }
    }
  };

  const handleItemImage = (itemNo, file) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("image", file);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL +
        "packing/itemimage/" +
        managerId +
        "/" +
        orderId +
        "/" +
        itemNo,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setIsReloaded(Math.random());
        }
      })
      .catch((error) => {})

  };

  useEffect(() => {
    if (
      data.order &&
      data.order.shippingInfo &&
      data.order.shippingInfo.pincode
    ) {
      setLableBtnLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        keyword: data.order.shippingInfo.pincode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData((prevState) => ({
              ...prevState,
              SubDistName: result.SubDistName[0] ? result.SubDistName[0] : "",
              districts: result.districts[0] ? result.districts[0] : "",
            }));
          }
          setLableBtnLoading(false);
        })
        .catch((error) => {})

    }
  }, [data.order]);

  const handleApproveCancel = () => {
    setRequestAproveLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "dispatch/cancelOrderApproved",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowApproveModal(false);
          navigate("/dispatchdashboard");
          setRequestAproveLoading(false);
        } else {
          alert(result.msg);
          setShowApproveModal(false);
          setRequestAproveLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleDenyCancel = () => {
    setRequestCancelLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "dispatch/canceleOrderDeny",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowDenyModal(false);
          window.location.reload();
          setRequestCancelLoading(false);
        } else {
          setShowDenyModal(false);
          setRequestCancelLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleCancelOrder = () => {
    setRequestCancelLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      cancelReason: cancelReason,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/cancelOrder", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowCancelOrderModal(false);
          window.location.reload();
          setRequestCancelLoading(false);
        } else {
          alert(result.msg);
          setShowCancelOrderModal(false);
          setRequestCancelLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const filterItem =
    data.order &&
    data.order.items &&
    data.order.items.length > 0 &&
    data.order.items.filter((item) => item.unit || item.qty > 0 || item.desc);
  // //console.log('data', data.order.partnerName);

  const transformItems = (items) => {
    // Initialize an empty array for transformed items
    const transformedItems = [];

    // Iterate over each item in the items array
    items &&
      items.forEach((item) => {
        if (item.itemNo) {
          // If the item has an itemNo, add it to the transformedItems array
          transformedItems.push({
            itemNo: item.itemNo,
            qty: item.qty,
            unit: item.unit,
            product_name: item.product_name,
            thumbnail: item.thumbnail,
            warehouse: item.warehouse,
            accessories: [],
          });
        } else if (item.accessoriesName) {
          // If the item has an accessoriesName, find the last added item with an itemNo
          const lastItem = transformedItems[transformedItems.length - 1];

          // Add the accessory to the accessories array of the last item
          lastItem.accessories.push({
            accessoriesName: item.accessoriesName,
            accessoriesPrice: item.price,
            accessoriesQty: item.qty,
            _id: Math.random().toString(36).substring(2, 15),
          });
        }
      });

    return transformedItems;
  };

  const transformedItems = transformItems(filterItem);
  return loading ? (
    <div className={"Mob_dispatch_loading"}>
      <img src="/wallicon.gif" alt="walliconGIF" />
    </div>
  ) : (
    <React.Fragment>
      <DispatchModal
        show={showAcceptModal}
        close={setShowAcceptModal}
        closeBtn={true}
        heading="DISPATCH ORDER STATUS"
        content={
          <div className={styles.modalMaindiv}>
            <p className={styles.message}>{msgShow}</p>
            <div className={styles.massageModal_btn}>
              <RedOutButton
                title="CANCEL"
                handleSubmit={() => {
                  data.order.paymentMethod === "POD"
                    ? orderPlacedPod()
                    : orderPlacedNotification();
                  setTimeout(() => {
                    setShowAcceptModal(false);
                    window.location.reload();
                  }, 1000);
                }}
              />
              <GreenOutButton
                title="OK"
                handleSubmit={() => {
                  data.order.paymentMethod === "POD"
                    ? orderPlacedPod()
                    : orderPlacedNotification();
                  setTimeout(() => {
                    setShowAcceptModal(false);
                    window.location.reload();
                  }, 1000);
                }}
              />
            </div>
          </div>
        }
      />
      <DispatchModal
        show={showPackedModal}
        close={setShowPackedModal}
        // setImage={setImage}
        heading="PACKING STATUS"
        content={
          <form onSubmit={handlePacked} className={styles.text_center}>
            <div className={styles.main_div}>
              <div className={styles.flexdiv}>
                <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
                <input
                  type="number"
                  name="packs"
                  value={packs}
                  // setPacks
                  onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
                  onChange={(e) => {
                    setPacks(e.target.value);

                    if (
                      check &&
                      e.target.value &&
                      images.length > parseInt(e.target.value)
                    ) {
                      const values = [...images];
                      values.splice(
                        point,
                        images.length -
                          (parseInt(e.target.value) === 0
                            ? 1
                            : parseInt(e.target.value))
                      );
                      setImages(values);
                    }
                  }}
                  className={styles.input}
                  required
                  autoFocus={true}
                />
              </div>

              <div className={styles.checkBox_addImage}>
                <input
                  type="checkbox"
                  name="vehicle1"
                  value="check"
                  className={styles.checkbox}
                  checked={check ? true : false}
                  onChange={(e) => {
                    if (e.target.checked && packs) {
                      addFormFields();
                      setCheck(true);
                    } else {
                      const values = [...images];
                      values.splice(point, point.length);
                      setImages(values);
                      setCheck(false);
                    }
                  }}
                />
                <label htmlFor="vehicle1">
                  &nbsp;&nbsp;Add image of every pack
                </label>
              </div>

              <div className={styles.dragAndDrop_div}>
                <FileHandlerMob setisImage={setisImage} title="Upload Image" />
              </div>
              <div>
                {images &&
                  images.map((item, index) => (
                    <div key={index} className={styles.flexdiv}>
                      <span>Pack {index + 1}</span>

                      {item.name ? (
                        <img
                          src={URL.createObjectURL(item)}
                          alt=""
                          className={styles.fileimg}
                          onClick={() =>
                            window.open(
                              URL.createObjectURL(item),
                              "mozillaWindow",
                              "popup"
                            )
                          }
                        />
                      ) : (
                        <img
                          src="/assets/packingImage.png"
                          alt="packing_order_image"
                          className={styles.packingImage}
                        />
                      )}
                      <div
                        className={styles.view_packingImage}
                        onClick={() =>
                          window.open(
                            URL.createObjectURL(item),
                            "mozillaWindow",
                            "popup"
                          )
                        }
                      >
                        View
                      </div>
                      <div
                        className={styles.deletediv}
                        onClick={(e) => {
                          let data = [...images];
                          data[index] = {};
                          setImages(data);
                        }}
                      >
                        <DeleteFile color={"red"} />
                        <p className={styles.deleteicon_css}>Delete</p>
                      </div>
                      {images.length !== 1 && (
                        <div
                          className={styles.deletediv}
                          onClick={(e) => {
                            setPacks(parseInt(packs) - 1);
                            const list = [...images];
                            list.splice(index, 1);
                            setImages(list);
                          }}
                        >
                          <span className={styles.crossIcon_css}>&#10006;</span>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.flexdiv}>
              <RedOutButton
                btnType="button"
                title="CANCEL"
                handleSubmit={() => setShowPackedModal(false)}
              />
              <GreenOutButton
                btnType="submit"
                title={packingLoading ? <Spinner size="15" /> : "OK"}
              />
            </div>
          </form>
        }
      />

      <Modal
        show={showDeliveryModal}
        className={"dispatchModal"}
        onHide={() => setShowDeliveryModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delivery Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <form onSubmit={handleSubmit}>
            <div className="position-relative mb-2">
              <label className={styles.inputLabel}>Delivery Method</label>
              <div className={styles.inputdiv}>{delivery.courierName}</div>
            </div>
            <div className="position-relative mb-2">
              <label className={styles.inputLabel}>Sub-Method</label>
              <div className={styles.inputdiv}>{delivery.methodName}</div>
            </div>
            <div className="position-relative mb-2">
              <label className={styles.inputLabel}>Delivery Partner</label>
              <select
                // className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                className={styles.inputdiv}
                value={delivery.partnerName}
                onChange={(e) => {
                  setDelivery((prevState) => ({
                    ...prevState,
                    partnerName: e.target.value,
                  }));
                  setPrintLabel((prevState) => ({
                    ...prevState,
                    delPerName:
                      partners &&
                      partners.find((x) => x.partnerName === e.target.value)
                        ?.partnerName,
                    delPerMobile:
                      partners &&
                      partners.find((x) => x.partnerName === e.target.value)
                        ?.contactNumber,
                    delTrackingNo:
                      partners &&
                      partners.find((x) => x.partnerName === e.target.value)
                        ?.trackingNumber,
                    delTrackingUrl:
                      partners &&
                      partners.find((x) => x.partnerName === e.target.value)
                        ?.trackingUrl,
                  }));
                }}
                required
              >
                <option value="" hidden>
                  Select Partner
                </option>
                {partners &&
                  partners.map((obj, index) => (
                    <option key={index} value={obj.partnerName}>
                      {obj.partnerName}
                    </option>
                  ))}
              </select>
            </div>

            <div className="position-relative mb-2">
              <label className={styles.inputLabel}>Delivery Person</label>
              <input
                className={styles.inputdiv}
                name="name"
                type="text"
                // placeholder='Name'
                value={printLabel.delPerName}
                onChange={(e) => {
                  setPrintLabel((prevState) => ({
                    ...prevState,
                    delPerName: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="position-relative mb-2">
              <label className={styles.inputLabel}>Contact Number</label>
              <input
                className={styles.inputdiv}
                name="mobile"
                type="number"
                // placeholder='Mobile Number'
                value={printLabel.delPerMobile}
                onChange={(e) => {
                  setPrintLabel((prevState) => ({
                    ...prevState,
                    delPerMobile: e.target.value,
                  }));
                }}
                onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
              />
            </div>
            <div className="position-relative mb-2">
              <label className={styles.inputLabel}>Tracking Number</label>
              <input
                className={styles.inputdiv}
                name="name"
                type="text"
                // placeholder='Tracking Number'
                value={printLabel.delTrackingNo}
                onChange={(e) => {
                  setPrintLabel((prevState) => ({
                    ...prevState,
                    delTrackingNo: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="position-relative">
              <label className={styles.inputLabel}>Tracking URL</label>
              <input
                className={styles.inputdiv}
                name="name"
                type="text"
                // placeholder='Tracking URL'
                value={printLabel.delTrackingUrl}
                onChange={(e) => {
                  setPrintLabel((prevState) => ({
                    ...prevState,
                    delTrackingUrl: e.target.value,
                  }));
                }}
              />
            </div>
            <h2 className={styles.modal_heading}>Or</h2>
            <div className="mb-3">
              {courierSlip ? (
                <div className={styles.courierSlip}>
                  <label
                    onClick={() => {
                      setCourierSlip("");
                    }}
                  >
                    X
                  </label>
                  <img
                    src={
                      courierSlip.name
                        ? URL.createObjectURL(courierSlip)
                        : process.env.REACT_APP_S3URL + courierSlip
                    }
                    alt="Courier Slip"
                    height={86}
                    className={`w-100 ${styles.courierSlip_image}`}
                    onClick={() => {
                      window.open(
                        courierSlip.name
                          ? URL.createObjectURL(courierSlip)
                          : process.env.REACT_APP_S3URL + courierSlip
                      );
                    }}
                  />
                </div>
              ) : (
                <FileHandlerMob
                  setisImage={(item) => setCourierSlip(item[0])}
                  styles={styles}
                  title="Upload Courier Slip"
                />
              )}
            </div>
            <div className={styles.dispatchModalBtm}>
              <RedOutButton
                btnType="button"
                title={"CANCEL"}
                handleSubmit={() => {
                  setShowDeliveryModal(false);
                }}
              />
              <GreenOutButton
                btnType="submit"
                title={
                  data.order && data.order.dispatchStatus === "Dispatched" ? (
                    "UPDATE DISPATCHED"
                  ) : packingLoading ? (
                    <Spinner size="15" />
                  ) : (
                    "DISPATCHED"
                  )
                }
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <DispatchModal
        show={showLabelModal}
        close={setShowLabelModal}
        content={
          <div className={styles.modalMaindiv1}>
            <h2 className={styles.modal_heading}>Print Label</h2>
            <p className={styles.modaltext}>
              Are you sure you want to Print Label & Marked as Packed?
            </p>
            <div className={styles.flexdiv}>
              <p className={styles.sliptext}>Enter Number Of Packs</p>
              <input
                name="name"
                type="number"
                className={styles.input}
                value={printLabel.qtyPacks}
                onChange={(e) => {
                  setPrintLabel((prevState) => ({
                    ...prevState,
                    qtyPacks: e.target.value,
                  }));
                }}
              />
            </div>
            {detailShow && (
              <form>
                <label>Delivery Partner</label>
                <input
                  className={styles.inputdiv}
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={printLabel.delPerName}
                  onChange={(e) => {
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerName: e.target.value,
                    }));
                  }}
                />
                <label>Contact Number</label>
                <input
                  className={styles.inputdiv}
                  name="mobile"
                  type="number"
                  placeholder="Mobile Number"
                  value={printLabel.delPerMobile}
                  onChange={(e) => {
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerMobile: e.target.value,
                    }));
                  }}
                />
                <label>Tracking Number</label>
                <input
                  className={styles.inputdiv}
                  name="name"
                  type="text"
                  placeholder="Tracking Number"
                  value={printLabel.delTrackingNo}
                  onChange={(e) => {
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delTrackingNo: e.target.value,
                    }));
                  }}
                />
                <label>Tracking URL</label>
                <input
                  className={styles.inputdiv}
                  name="name"
                  type="text"
                  placeholder="Tracking URL"
                  value={printLabel.delTrackingUrl}
                  onChange={(e) => {
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delTrackingUrl: e.target.value,
                    }));
                  }}
                />
              </form>
            )}
            <div className={styles.flexdiv}>
              <RedOutButton
                title={"CANCEL"}
                css="py-2"
                handleSubmit={() => {
                  setShowLabelModal(false);
                }}
              />
              <div className={styles.printicon}>
                <GreenOutButton
                  css={!printLabel.qtyPacks ? styles.disableBtn : "py-2"}
                  title={"PRINT"}
                  disabled={printLabel.qtyPacks ? false : true}
                  handleSubmit={() => {
                    handlePrintLabel();
                  }}
                />
              </div>
              <div style={{ display: "none" }}>
                <PrintLabel
                  data={data.order}
                  qtyPacks={printLabel.qtyPacks}
                  ref={labelRef}
                  handlePrint={handlePrint}
                  SubDistName={data.SubDistName}
                  districts={data.districts}
                />
              </div>
            </div>
            <div className={styles.flexdiv}>
              <p> Add Delivery Details</p>
              <div onClick={() => setDetailShow(!detailShow)}>
                {detailShow ? <UpArrowCircle /> : <DownArrowCircle />}
              </div>
            </div>
          </div>
        }
      />

      <DispatchModal
        show={showApproveModal}
        close={setShowApproveModal}
        closeBtn={true}
        heading={
          data.order &&
          (data.order.reqType === "Request For Cancel"
            ? "CANCEL ORDER REQUEST"
            : "EDIT ORDER REQUEST")
        }
        content={
          <div className={styles.text_center}>
            <p className={styles.message_cancelOrder}>
              Are you sure you want to Approve this{" "}
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "Cancel"
                  : "Edit")}{" "}
              Order Request?
            </p>
            <div className={styles.massageModal_btn}>
              <RedOutButton
                css={styles.moveToSale_btn_no}
                title="NO"
                handleSubmit={() => setShowApproveModal(false)}
              />
              <GreenButton
                css={`
                  ${styles.moveToSale_btn} ms-4
                `}
                title={requestAproveLoading ? <Spinner size="15" /> : "YES"}
                handleSubmit={() => {
                  if (
                    data.order &&
                    data.order.reqType === "Request For Cancel"
                  ) {
                    handleApproveCancel();
                  }
                }}
              />
            </div>
          </div>
        }
      />

      <DispatchModal
        show={showDenyModal}
        close={setShowDenyModal}
        closeBtn={true}
        heading={
          data.order &&
          (data.order.reqType === "Request For Cancel"
            ? "DENY CANCEL ORDER REQUEST"
            : "DENY EDIT ORDER REQUEST")
        }
        content={
          <div className={styles.text_center}>
            <p className={styles.message_cancelOrder}>
              Are you sure you want to Deny this{" "}
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "Cancel"
                  : "Edit")}{" "}
              Order Request?
            </p>
            <div className={styles.massageModal_btn}>
              <RedOutButton
                css={styles.moveToSale_btn_no}
                title="NO"
                handleSubmit={() => setShowDenyModal(false)}
              />
              <GreenButton
                css={`
                  ${styles.moveToSale_btn} ms-4
                `}
                title={requestCancelLoading ? <Spinner size="15" /> : "YES"}
                handleSubmit={() => {
                  if (
                    data.order &&
                    data.order.reqType === "Request For Cancel"
                  ) {
                    handleDenyCancel();
                  }
                }}
              />
            </div>
          </div>
        }
      />
      <DispatchModal
        show={showCancelOrderModal}
        close={setShowCancelOrderModal}
        closeBtn={true}
        heading="CANCEL ORDER"
        content={
          <div className={styles.text_center}>
            <p className={styles.message_cancelOrder}>
              Are You Sure Want To Cancel This Order
            </p>
            <textarea
              type="text"
              placeholder="Enter Cancel Reason"
              className={styles.reqIssue}
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
            />
            <div className={styles.massageModal_btn}>
              <RedOutButton
                css={styles.moveToSale_btn_no}
                title="NO"
                handleSubmit={() => setShowCancelOrderModal(false)}
              />
              <GreenButton
                css={`
                  ${styles.moveToSale_btn} ms-4
                `}
                title={requestCancelLoading ? <Spinner size="15" /> : "YES"}
                handleSubmit={() => {
                  handleCancelOrder();
                }}
              />
            </div>
          </div>
        }
      />

      <form onSubmit={handleAcceptOrder}>
        <div className={styles.dispatch_main}>
          <div className={styles.dispatch_scroll}>
            <div className={styles.header_main}>
              <div className="d-flex justify-content-between align-items-center">
                {(data.order.dispatchStatus === "Packed" ||
                  data.order.dispatchStatus === "Dispatched" ||
                  data.order.dispatchStatus === "Pending") &&
                lableBtnLoading ? (
                  <Spinner size="15" />
                ) : (
                  <>
                    {data.order.dispatchStatus !== "Cancelled" && (
                      <div
                        className={`${styles.printicon} d-flex me-3`}
                        onClick={() => {
                          setShowLabelModal(true);
                        }}
                      >
                        <b>
                          <u>Label</u>
                        </b>
                        &nbsp;&nbsp;
                        <BlackPrint color={"#000000"} />
                      </div>
                    )}
                  </>
                )}
                {(data.order.pmId === parseInt(managerId) ||
                  designation === "superadmin") &&
                  data.order.packingStatus !== "Packed" &&
                  data.order.dispatchStatus !== "Cancelled" && (
                    <ReactToPrint
                      trigger={() => (
                        <div className={`${styles.printicon} d-flex`}>
                          <b>
                            <u>Print Slip</u>
                          </b>
                          &nbsp;&nbsp;
                          <BlackPrint color={"#000000"} />
                        </div>
                      )}
                      content={() => componentRef}
                    />
                  )}
                <div style={{ display: "none" }}>
                  <PackingSlip
                    orderId={orderId}
                    managerId={managerId}
                    delivery={delivery}
                    ref={(el) => (componentRef = el)}
                  />
                </div>
              </div>
              <div className="d-flex flex-column ">
                <div className="d-flex">
                  <span className={styles.dispatch_status}>
                    Dispatch Status&nbsp;&nbsp;
                  </span>
                  <div
                    className={`${styles.status_height} ${
                      data.order.dispatchStatus === "New Order"
                        ? styles.status_blue
                        : data.order.dispatchStatus === "Packed" ||
                          data.order.dispatchStatus === "Dispatched"
                        ? styles.statuscolor3
                        : data.order.dispatchStatus === "Not Packed"
                        ? styles.statuscolor1
                        : data.order.dispatchStatus === "In Progress" ||
                          data.order.dispatchStatus === "Pending"
                        ? styles.status
                        : ""
                    }`}
                  >
                    {data.order.dispatchStatus}
                  </div>
                </div>

                <div className="d-flex">
                  <span className={styles.dispatch_status}>
                    Packing Status&nbsp;&nbsp;
                  </span>
                  <div
                    className={`${styles.status_height} ${
                      data.order.packingStatus === "New Order"
                        ? styles.status_blue
                        : data.order.packingStatus === "Packed" ||
                          data.order.packingStatus === "Dispatched"
                        ? styles.statuscolor3
                        : data.order.packingStatus === "Not Packed"
                        ? styles.statuscolor1
                        : data.order.packingStatus === "In Progress" ||
                          data.order.packingStatus === "Pending"
                        ? styles.status
                        : ""
                    }`}
                  >
                    {data.order.packingStatus}
                  </div>
                </div>
              </div>
            </div>

            <h2 className={styles.packingDetailsHeading}>
              {data.order && data.order.storeName}
            </h2>
            <div className={styles.orderId_date}>
              <div>
                Order ID: <b>{orderId}</b>
              </div>
              <div>
                Dated: <b>{data.order && data.order.orderDate}</b>
              </div>
            </div>

            <div className={styles.items_div}>
              {transformedItems &&
                transformedItems.map((item, index) => (
                  <div key={index} className={`${styles.itemCard} flex-column`}>
                    <div className="d-flex w-100">
                      <div>
                        {item.thumbnail ? (
                          <img
                            src={process.env.REACT_APP_S3URL + item.thumbnail}
                            className={styles.thumbnail_itemImage}
                            alt={item.itemNo}
                            onClick={() =>
                              window.open(
                                process.env.REACT_APP_S3URL + item.thumbnail,
                                "mozillaWindow",
                                "popup"
                              )
                            }
                          />
                        ) : (
                          <div className={styles.NoImageIcon}>
                            <NoImageIcon />
                          </div>
                        )}
                      </div>
                      <div className={styles.flexMA}>
                        <div className={styles.packingDetails_div}>
                          <b>{item.itemNo || item.accessoriesName}</b>
                          <span className={styles.productName}>
                            {item.product_name}
                          </span>
                        </div>
                        <b>
                          {item.qty}&nbsp;{item.unit}
                        </b>
                        <div className={styles.warehouseName}>
                          {item.warehouse}
                        </div>
                        {item.desc && (
                          <div className={styles.packingDetails_div}>
                            <b>
                              <span>Description:</span> <span>{item.desc}</span>
                            </b>
                          </div>
                        )}
                      </div>
                      <div>
                        {item.packingImg ? (
                          <img
                            src={
                              item.packingImg
                                ? process.env.REACT_APP_S3URL + item.packingImg
                                : "/assets/img.png"
                            }
                            alt="img"
                            className={styles.itemImg}
                          />
                        ) : (
                          <>
                            {!item.accessoriesName && (
                              <label className={styles.bannerAddBtn}>
                                <Camera />
                                <input
                                  name="bannerImg"
                                  type="file"
                                  className={styles.bannerImgInput}
                                  id="banner"
                                  onChange={(e) => {
                                    //setPackingImage(e.target.files[0]);
                                    handleItemImage(
                                      item.itemNo,
                                      e.target.files[0]
                                    );
                                  }}
                                  accept="image/*"
                                />
                              </label>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {item.accessories && item.accessories.length !== 0 && (
                      <>
                        <div className={styles.accessories_heading}>
                          Accessories
                        </div>
                        {item.accessories &&
                          item.accessories.length !== 0 &&
                          item.accessories.map((obj, i) => (
                            <div className={styles.itemCard_clip_price}>
                              <span>
                                {item.accessories[i].accessoriesName}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {item.accessories[i].accessoriesQty}&nbsp;pcs
                              </span>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                ))}
            </div>

            <div className={styles.contactCard}>
              <h2 className={styles.packingDetailsHeading}>
                {data.order.shippingInfo.name && data.order.shippingInfo.name}
              </h2>
              <div className="d-flex">
                <div className={styles.contact_details_icon}>
                  <LocationIcon />
                </div>
                {data.order && (
                  <span className={styles.contact_details}>
                    {data.order.shippingInfo &&
                      data.order.shippingInfo.street +
                        ", " +
                        data.order.shippingInfo.city +
                        ", " +
                        data.order.shippingInfo.state +
                        ", " +
                        data.order.shippingInfo.pincode}
                  </span>
                )}
              </div>
              <div className="d-flex">
                <div className={styles.contact_details_icon}>
                  <Phone />
                </div>
                <span className={styles.contact_details}>
                  +91-
                  {data.order.shippingInfo && data.order.shippingInfo.mobile}
                </span>
              </div>
              {data.order && data.order.gstuin && (
                <div className="d-flex">
                  <div className={styles.contact_details_icon}>
                    <GSTIcon />
                  </div>
                  <span className={styles.contact_details}>
                    {data.order && data.order.gstuin}
                  </span>
                </div>
              )}
              <div className="d-flex">
                <div className={styles.contact_details_icon}>
                  <Email />
                </div>
                <span className={styles.contact_details}>
                  {data.order && data.order.email}
                </span>
              </div>
            </div>

            <div className={styles.diliveryCard}>
              <h2 className={styles.packingDetailsHeading}>Delivery Details</h2>

              <div className={styles.packingDetails_txt}>
                <div className={styles.ordermanagerName}>Delivery Type</div>
                <div className={styles.contactsDiv}>
                  {data.order && data.order.shippingType}
                </div>
              </div>
              <div className={styles.packingDetails_txt}>
                <div className={styles.ordermanagerName}>Delivery Method</div>
                <div className={styles.contactsDiv}>{delivery.courierName}</div>
              </div>
              <div className={styles.packingDetails_txt}>
                <div className={styles.ordermanagerName}>Sub-Method</div>
                <div className={styles.contactsDiv}>{delivery.partnerName}</div>
              </div>

              <div className={styles.packingDetails_txt}>
                <div className={styles.ordermanagerName}>
                  Order Manager
                  <b>&nbsp; ({data.order && data.order.ordermanager})</b>
                </div>
                {data.order.managercontact && (
                  <div className={styles.contactsDiv}>
                    <PhoneMob color={"#000000"} />
                    <a href={`tel:+91${data.order.managercontact}`}>
                      <b className="ps-1">+91-{data.order.managercontact}</b>
                    </a>
                    <div>
                      <a
                        className={styles.whatsappIcon}
                        target="_blank"
                        rel="noreferrer"
                        href={`https://wa.me/+91${data.order.managercontact}`}
                      >
                        <WhatsApp />
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.packingDetails_txt}>
                <div className={styles.ordermanagerName}>
                  Packing Manager
                  <b>
                    &nbsp;
                    {data.order.packermanager && `${data.order.packermanager}`}
                  </b>
                </div>
                {data.order.pmcontact && (
                  <div className={styles.contactsDiv}>
                    <PhoneMob color={"#000000"} />
                    <a href={`tel:+91${data.order.pmcontact}`}>
                      <b className="ps-1">+91-{data.order.pmcontact}</b>
                    </a>
                    <a
                      className={styles.whatsappIcon}
                      target="_blank"
                      rel="noreferrer"
                      href={`https://wa.me/+91${data.order.pmcontact}`}
                    >
                      <WhatsApp />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={styles.dispatchFooter}>
            <div className={styles.flexdiv1}>
              <span>
                <b>Select Packer</b>
              </span>
              <select
                className={styles.select_packer}
                onChange={(e) => setPacker(e.target.value)}
                value={packer}
                required
              >
                <option value={managerId}>Self</option>
                {data.pakingManagers &&
                  data.pakingManagers.map((item, index) => (
                    <option
                      key={index}
                      className={styles.selecthover}
                      value={item.managerId}
                    >
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className={styles.dispatchFooter_button}>
              <RedOutButton
                css={`
                  ${styles.cencelbtn} ${data.order.dispatchStatus ===
                    "Cancelled" && "mx-auto"}
                `}
                btnType="button"
                title={"BACK"}
                handleSubmit={() => {
                  window.history.back();
                }}
              />
              {data.order.reqStatus === "" &&
                data.order.dispatchStatus !== "New Order" &&
                data.order.dispatchStatus !== "Cancelled" && (
                  <RedOutButton
                    // disabled={false}
                    btnType="button"
                    title="CANCEL ORDER"
                    css={styles.cencelbtn}
                    handleSubmit={() => {
                      setShowCancelOrderModal(true);
                    }}
                  />
                )}
              {data.order.dispatchStatus === "New Order" ? (
                <GreenOutButton
                  btnType="submit"
                  title={"ACCEPT"}
                  css={styles.greenOutbtn}
                />
              ) : data.order.pmId === parseInt(managerId) &&
                data.order.packingStatus === "Not Packed" ? (
                <>
                  <>
                    {data.order.reqStatus === "Pending" ? (
                      <>
                        {data.order.reqType === "Request For Cancel" &&
                          data.order.reqStatus !== "Approved" && (
                            <GreenOutButton
                              btnType="button"
                              title={"APPROVE CANCEL"}
                              handleSubmit={() => {
                                setShowApproveModal(true);
                              }}
                              css={styles.greenOutbtn}
                            />
                          )}
                        {data.order.reqType === "Request For Cancel" &&
                          data.order.reqStatus !== "Approved" && (
                            <RedOutButton
                              btnType="button"
                              title={"DENY CANCEL"}
                              handleSubmit={() => {
                                setShowDenyModal(true);
                              }}
                              css={styles.cencelbtn}
                              // css='px-3 py-2'
                            />
                          )}
                      </>
                    ) : (
                      <>
                        {data.order.dispatchStatus !== "Cancelled" && (
                          <>
                            <GreenOutButton
                              btnType="button"
                              title={"PACKED"}
                              handleSubmit={() => {
                                setShowPackedModal(true);
                              }}
                              css={styles.greenOutbtn}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                </>
              ) : (
                <>
                  {/* <Link to={'/viewdispatchdetails/' + orderId} className={styles.viewDetail_btn}>View Dispatch Details</Link> */}
                  {data.order.dispatchStatus === "Dispatched" ? (
                    <Link
                      to={"/viewdispatchdetails/" + orderId}
                      className={styles.viewDetail_btn}
                    >
                      View Dispatch Details
                    </Link>
                  ) : data.order.reqStatus === "Pending" ? (
                    <>
                      {data.order.reqType === "Request For Cancel" &&
                        data.order.reqStatus !== "Approved" && (
                          <GreenOutButton
                            btnType="button"
                            title={"APPROVE CANCEL"}
                            handleSubmit={() => {
                              setShowApproveModal(true);
                            }}
                            css={styles.greenOutbtn}
                          />
                        )}
                      {data.order.reqType === "Request For Cancel" &&
                        data.order.reqStatus !== "Approved" && (
                          <RedOutButton
                            btnType="button"
                            title={"DENY CANCEL"}
                            handleSubmit={() => {
                              setShowDenyModal(true);
                            }}
                            css={styles.cencelbtn}
                            // css='px-3 py-2'
                          />
                        )}
                    </>
                  ) : (
                    <>
                      {data.order.dispatchStatus !== "Cancelled" && (
                        <GreenOutButton
                          css={styles.cencelbtn}
                          btnType="button"
                          title="DELIVERY DETAILS"
                          handleSubmit={() => {
                            setPrintLabel((prevState) => ({
                              ...prevState,
                              delPerName:
                                partners &&
                                partners.find(
                                  (x) => x.partnerName === delivery.partnerName
                                )?.partnerName,
                              delPerMobile:
                                partners &&
                                partners.find(
                                  (x) => x.partnerName === delivery.partnerName
                                )?.contactNumber,
                              delTrackingNo:
                                partners &&
                                partners.find(
                                  (x) => x.partnerName === delivery.partnerName
                                )?.trackingNumber,
                              delTrackingUrl:
                                partners &&
                                partners.find(
                                  (x) => x.partnerName === delivery.partnerName
                                )?.trackingUrl,
                            }));
                            setShowDeliveryModal(true);
                            generatePaymentLink();
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
export default DispatchOrder;
