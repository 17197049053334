//? Data
import {
  AppsIcon,
  RefundsIcon,
  SideBarAccountIcon,
  SideBarApplicationIcon,
  SideBarComplaintsIcon,
  SideBarDashBoardIcon,
  SideBarDealersIcon,
  SideBarDeliveryIcon,
  SideBarDispatchIcon,
  SideBarEditDeleteIcon,
  SideBarGreetingIcon,
  SideBarInventoryIcon,
  SideBarInvoiceIcon,
  SideBarJobsIcon,
  SideBarManagerIcon,
  SideBarMarketingIcon,
  SideBarNotificationIcon,
  SideBarOrdersIcon,
  SideBarPackingOrderIcon,
  SideBarProductIcon,
  SideBarReceiptIcon,
  SideBarTemplateIcon,
  SideBarWareHousetIcon,
  TransactionsIcon,
  WhatChat,
} from "./components/icons/Icon";

export const menuItems = [
  {
    label: "DASHBOARD",
    path: "/dashboard",
    Icon: SideBarDashBoardIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "DEALERS",
    path: "/dealers",
    Icon: SideBarDealersIcon,
    allowedDesignations: [
      "superadmin",
      "ordermanager"
    ],
  },
  {
    label: "ORDERS",
    path: "/orders",
    Icon: SideBarOrdersIcon,
    allowedDesignations: [
      "superadmin",
      "ordermanager",
      "inventorymanager",
    ],
  },
  {
    label: "RECEIPT",
    path: "/recipts",
    Icon: SideBarReceiptIcon,
    allowedDesignations: [
      "superadmin",
      "paymentmanager"
    ],
  },
  {
    label: "DISPATCH",
    path: "/dispatchdashboard",
    Icon: SideBarDispatchIcon,
    allowedDesignations: [
      "superadmin",
      "dispatchmanager",
    ],
  },
  {
    label: "INVENTORY",
    path: "/inventory",
    Icon: SideBarInventoryIcon,
    allowedDesignations: ["superadmin", "inventorymanager"],
  },
  // {
  //   label: "INVENTORY REPORT",
  //   path: "/inventoryreport",
  //   Icon: SideBarInventoryIcon,
  //   allowedDesignations: ["superadmin"],
  // },
  {
    label: "CAMPAIGN",
    path: "/campaign",
    Icon: SideBarTemplateIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "CHAT",
    path: "/whatsappchats",
    Icon: WhatChat,
    allowedDesignations: ["superadmin", "ordermanager"],
  },
  {
    label: "PACKING ORDER",
    path: "/packingorder",
    Icon: SideBarPackingOrderIcon,
    allowedDesignations: ["superadmin", "packingmanager"],
  },
  {
    label: "INVOICE",
    path: "/invoices",
    Icon: SideBarInvoiceIcon,
    allowedDesignations: ["superadmin", "invoicemanager"],
  },
  {
    label: "COMPLAINTS",
    path: "/complaints",
    Icon: SideBarComplaintsIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "PRODUCTS",
    path: "/products",
    Icon: SideBarProductIcon,
    allowedDesignations: ["superadmin", "contentmanager"],
  },
  {
    label: "MANAGER",
    path: "/managers",
    Icon: SideBarManagerIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "MARKETING",
    path: "/marketing",
    Icon: SideBarMarketingIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "WAREHOUSE",
    path: "/warehouses",
    Icon: SideBarWareHousetIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "JOBS",
    path: "/jobs",
    Icon: SideBarJobsIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "APPLICATION",
    path: "/applicants",
    Icon: SideBarApplicationIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "DELIVERY",
    path: "/delivery",
    Icon: SideBarDeliveryIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "APPS",
    path: "/apps",
    Icon: AppsIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "GREETING",
    path: "/greetings",
    Icon: SideBarGreetingIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "NOTIFICATION",
    path: "/createnotification",
    Icon: SideBarNotificationIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "DELETE / EDIT",
    path: "/deleteoredit",
    Icon: SideBarEditDeleteIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "TRANSACTIONS",
    path: "/transactions",
    Icon: TransactionsIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "REFUNDS",
    path: "/refunds",
    Icon: RefundsIcon,
    allowedDesignations: ["superadmin"],
  },
  {
    label: "ACCOUNT",
    path: "/accounts",
    Icon: SideBarAccountIcon,
    allowedDesignations: ["superadmin"],
  },
];

//? Pages
// Managers
export { default as NewManager } from "./components/updatedComponents/Manager/NewManager";
export { default as Managers } from "./pages/managers/Managers";
export { default as AddManager } from "./pages/managers/AddManager";

// Products
export { default as AddProduct } from "./pages/products/AddProduct";
export { default as Products } from "./pages/products/Products";
export { default as ViewProduct } from "./pages/products/ViewProduct";
export { default as Items } from "./pages/products/Items";
export { default as AddItem } from "./pages/products/AddItem";
export { default as AddStyleAndType } from "./pages/products/AddStyleAndType";
export { default as AddCollection } from "./pages/products/AddCollection";
export { default as ProductFAQ } from "./pages/products/ProductFAQ";

// KYC
export { default as Kyc } from "./pages/kyc/Kyc";
export { default as Dealers } from "./pages/kyc/Dealers";
export { default as TransferDealer } from "./pages/kyc/TransferDealer";

// Marketing
export { default as Marketing } from "./pages/marketing/Marketing";
export { default as MarketingLead } from "./pages/marketing/MarketingLead";
export { default as MarketingDashboard } from "./pages/marketing/MarketingDashboard";
export { default as Contacts } from "./pages/marketing/Contacts";
export { default as Leads } from "./pages/marketing/Leads";

// Home
export { default as Home } from "./pages/Home";

// Dashboard
export { default as ContentDashboard } from "./pages/dashboard/ContentDashboard";
export { default as DispatchDasboard } from "./pages/dashboard/DispatchDasboard";
export { default as CommonDashboard } from "./components/updatedComponents/Dashboard/CommonDashboard";

// Order Management
export { default as OrderDealers } from "./pages/ordermanager/OrderDealers";
export { default as AllOrders } from "./pages/ordermanager/AllOrders";
export { default as Order } from "./pages/ordermanager/Order";

// Invoices
export { default as SaleOrderInovice } from "./pages/invoices/SaleOrderInovice";
export { default as PurchaseInovice } from "./pages/invoices/PurchaseInvoice";
export { default as AdjustmentInvoice } from "./pages/invoices/AdjustmentInvoice";
export { default as EstimateInvoice } from "./pages/invoices/EstimateInvoice";

// Inventory
export { default as Inventory } from "./pages/inventorymanager/Inventory";
export { default as InventoryCollections } from "./pages/inventorymanager/InventoryCollections";
export { default as CollectionInfo } from "./pages/inventorymanager/CollectionInfo";
export { default as TransferStock } from "./pages/inventorymanager/TransferStock";
export { default as TransferOrders } from "./pages/inventorymanager/TransferOrders";
export { default as TaxRate } from "./pages/inventorymanager/TaxRate";

// Dispatch Manager
export { default as DispatchOrders } from "./pages/dispatchmanager/DispatchOrders";
export { default as PackingVoucher } from "./pages/dispatchmanager/PackingVoucher";
export { default as Packers } from "./pages/dispatchmanager/Packers";
export { default as Packer } from "./pages/dispatchmanager/Packer";
export { default as CreatePackers } from "./pages/dispatchmanager/CreatePackers";
export { default as ViewDispatchDetails } from "./pages/dispatchmanager/ViewDispatchDetails";
export { default as Newviewdispatchdetails } from "./pages/dispatchmanager/Newviewdispatchdetails";

// Packing Manager
export { default as PackingOrders } from "./pages/packingmanager/PackingOrders";
export { default as PackingOrder } from "./pages/packingmanager/PackingOrder";

// Mobile Views
export { default as DispatchDasboardMob } from "./mobile/pages/DispatchDasboardMob";
export { default as DispatchOrder } from "./mobile/pages/DispatchOrder";
export { default as ViewDispatchDetailsMob } from "./mobile/pages/ViewDispatchDetailsMob";
export { default as TransferOrdersMob } from "./mobile/pages/TransferOrdersMob";
export { default as ReciptsMob } from "./mobile/pages/ReciptsMob";

// Super Admin
export { default as Complaints } from "./pages/superadmin/Complaints";
export { default as Recipts } from "./pages/superadmin/Receipts";
export { default as Accounts } from "./pages/superadmin/Accounts";
export { default as Delivery } from "./pages/superadmin/Delivery";
export { default as Warehouse } from "./pages/superadmin/Warehouse";
export { default as Invoices } from "./pages/superadmin/Invoices";
export { default as SaleInvoice } from "./pages/superadmin/SaleInvoice";
export { default as SearchItem } from "./pages/superadmin/SearchItem";
export { default as AddPartner } from "./pages/superadmin/AddPartner";
export { default as UploadInvoice } from "./pages/superadmin/uploadinvoice";

// Apps
export { default as Apps } from "./components/updatedComponents/Apps/Apps";
export { default as ViewApp } from "./components/updatedComponents/Apps/ViewApp";
export { default as CreateApp } from "./components/updatedComponents/Apps/CreateApp";

// Transactions
export { default as Transactions } from "./components/updatedComponents/Transactions/Transactions";

// Refunds
export { default as Refunds } from "./components/updatedComponents/Refunds/Refunds";

// Edit or Delete
export { default as EditDelete } from "./pages/editDelete/EditDelete";

// Templates & Campaign
export { default as Templates } from "./pages/template/Templates";
export { default as CreateTemplate } from "./pages/template/CreateTemplate";
export { default as SingleTemp } from "./pages/template/SingleTemp";
export { default as Campaign } from "./pages/template/Campaign";
export { default as CreateGroup } from "./pages/template/CreateGroup";
export { default as Groups } from "./pages/template/Groups";
export { default as DealerList } from "./pages/template/DealerList";
export { default as CreateCampaign } from "./pages/template/CreateCampaign";
export { default as SingleCampaing } from "./pages/template/SingleCampaing";
export { default as CampaignReport } from "./pages/template/CampaignReport";

// Components
export { default as NavLayout } from "./components/updatedComponents/Navbar/NavLayout";
export { default as MainWhatsappChat } from "./components/updatedComponents/WhatsappChat/MainWhasappChat";
export { default as MainMobileChats } from "./components/updatedComponents/WhatsappChat/MainMobileChats";
export { default as TestLink } from "./components/updatedComponents/WhatsappChat/TestLink";
export { default as ModalWrapper } from "./components/updatedComponents/layoutWrapper/ModalWrapper";
export { default as TimerLogout } from "./components/updatedComponents/Login/TimerLogout";

// No Match
export { default as NoMatch } from "./AppFeatures/NoMatch";

// Mananger based routes
export { default as SuperAdminRoutes } from "./AppFeatures/SuperAdminRoutes";
export { default as ContentManagerRoutes } from "./AppFeatures/ContentManagerRoutes";
export { default as OrderManagerRoutes } from "./AppFeatures/OrderManagerRoutes";
export { default as InventoryManagerRoutes } from "./AppFeatures/InventoryManagerRoutes";
export { default as DispatchManagerRoutes } from "./AppFeatures/DispatchManagerRoutes";
export { default as PaymentManagerRoutes } from "./AppFeatures/PaymentManagerRoutes";
export { default as PackingManagerRoutes } from "./AppFeatures/PackingManagerRoutes";
export { default as InvoiceManagerRoutes } from "./AppFeatures/InvoiceManagerRoutes";
export { default as MarketingRoutes } from "./AppFeatures/MarketingRoutes";

// !Out Side Inventory (Will be removed)
export { default as OutSideInventory } from "./pages/inventorymanager/OutSideInventory";
export { default as OutSideIvtColl } from "./pages/inventorymanager/OutSideIvtColl";
export { default as OutSideIvtCollInfo } from "./pages/inventorymanager/OutSideIvtCollInfo";
export { default as OutSideInventoryMob } from "./mobile/pages/OutSideInventoryMob";
export { default as OutSideIvtCollMob } from "./mobile/pages/OutSideIvtCollMob";
export { default as OutSideIvtCollInfomob } from "./mobile/pages/OutSideIvtCollInfomob";

// Inventryreportdetails 
export { default as Inventryreportdetails } from "./pages/Inventryreport/Inventryreportdetails";