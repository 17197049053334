const TimeOutFetch = async (endpoint, apiData, timeout = 300000) => { 
    const controller = new AbortController();
    const signal = controller.signal;
  
    const timeoutId = setTimeout(() => controller.abort(), timeout);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
        },
        body: JSON.stringify(apiData),
        signal: signal,
      });
  
      clearTimeout(timeoutId); 
  
      const result = await response.json();
      return result;
    } catch (error) {
      clearTimeout(timeoutId); 
      if (error.name === 'AbortError') {
        console.error("Request aborted due to timeout");
        return { error: "Request timed out" }; 
      }
  
      return error; 
    } 
  };
  
  export default TimeOutFetch;
  