import { useState } from "react";
import styles from "../../../pages/superadmin/Receipts.module.css";
import { Close, RevertIcon, RoundedTickIcon } from "../../icons/Icon";
import Button from "../Dealers/Button";
import { useToast } from "../Toaster/Toaster";
import fetchData from "../../../APIsControll/apiControll";

const CancelReceiptModal = ({
  mode,
  managerId,
  handleCloseModal,
  receiptId,
  orderId,
  revertCase = "false",
  onActionCompleted,
  receiptData,
}) => {
  const [reason, setReason] = useState("");
  const { show } = useToast();

  const handleCancelReceipt = async () => {
    const apiData = {
      managerId: managerId,
      receiptId: receiptId,
      orderId: orderId,
      reason: reason,
    };
    try {
      const result = await fetchData("receipt/cancelreceipt", apiData);
      if (result.status === "success") {
        show(result.msg, "success");
        onActionCompleted();
      }
      if (result.status === "failed") {
        show(result.msg, "error");
      }
    } catch (error) {
      //console.log("error ", error);
    }
  };

  const hanldeRevertReceipt = async () => {
    const apiData = {
      managerId: managerId,
      receiptId: receiptId,
      orderId: orderId,
      description: reason,
    };
    try {
      const result = await fetchData("receipt/revertReceipt", apiData);
      if (result.status === "success") {
        show(result.msg, "success");
        onActionCompleted();
      }
      if (result.status === "failed") {
        show(result.msg, "error");
      }
    } catch (error) {
      //console.log("error ", error);
    }
  };

  const handleClick = () => {
    if (reason.length < 20) {
      show("Enter a reason with minimum 20 characters!", "warning");
    } else {
      if (revertCase === "true") {
        hanldeRevertReceipt();
      } else {
        handleCancelReceipt();
      }
      handleCloseModal();
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "ordered":
      case "Packed":
      case "success":
      case "Dispatched":
      case "Delivered":
        return "#31974D";

      case "Not Packed":
      case "ordered Pending":
      case "Payment Under Verification":
      case "Request For Edit":
      case "Edit request Approved":
      case "Dispatched Pending":
        return "#FF9B04";

      case "Packing In Progress":
        return "#9747FF";
      // return "#7698FF";

      case "Draft":
      case "In Progress":
      case "Cancelled":
      case "Request For Cancel":
      case "Payment Pending":
        return "#F93E3E";

      case "New Order":
      case "draft":
        return "#7698FF";

      default:
        return "";
    }
  };
// console.log(receiptData)
  return (
    <>
      <div
        className={styles.cancel_receipt_modal}
        style={mode ? { backgroundColor: "#232529" } : {}}
      >
        <>
          <div className={styles.cancel_receipt_header}>
            <p
              className={styles.cancel_receipt_head}
              style={{ color: mode ? "#fff" : "#000" }}
            >
              {revertCase === "true" ? "Revert Receipt" : "Request for Cancel"}
            </p>
            <p style={{ color: mode ? "#fff" : "#000", fontSize: "12px" }}>
              Order Status:{" "}
              <span
                style={{
                  color: getStatusColor(receiptData.orderStatus),
                  fontSize: "14px",
                }}
              >
                {receiptData.orderStatus || "DEV"}
              </span>
            </p>
            <div
              className={styles.receipt_dets}
              style={{
                color: mode ? "#FFFFFF" : "#000000",
              }}
            >
              <p style={{ margin: 0 }}>
                {receiptData.orderId} / {receiptData.receiptId} /{" "}
                {receiptData.receiptDate}
              </p>
              <p style={{ margin: 0 }}>
                {" "}
                {!receiptData.verifyBy ? "Not Received" : receiptData.verifyBy}
              </p>
            </div>
          </div>

          <p
            className={styles.cancel_receipt_p_txt}
            style={mode ? { color: "#FFFFFF" } : {}}
          >
            Enter the reason why you need to{" "}
            {revertCase === "true" ? "revert" : "cancel"} this receipt.
          </p>
          <textarea
            name=""
            id=""
            placeholder="*enter reason here (not less than 20 Characters)"
            required
            rows="4"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className={`${styles.textarea} ${mode ? styles.textarea_dark : ""}`}
          ></textarea>
          <div>
            <Button
              buttonText={"Close"}
              buttonIcon={<Close />}
              buttonBgColor={"#000000"}
              buttonTextColor={"#FFFFFF"}
              buttonClick={handleCloseModal}
            />
            {revertCase === "true" ? (
              <Button
                buttonIcon={<RevertIcon color={"#FFFFFF"} />}
                buttonText={"Revert Back"}
                buttonBgColor={"#F93E3E"}
                buttonTextColor={"#FFFFFF"}
                buttonClick={handleClick}
              />
            ) : (
              <Button
                buttonIcon={<RoundedTickIcon />}
                buttonText={"Submit"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#FFFFFF"}
                buttonClick={handleClick}
              />
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default CancelReceiptModal;
