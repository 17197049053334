import React, { useEffect, useRef } from 'react'
import styles from "./ProductCompo.module.css"
import { useAuth } from '../../context/Auth'
import { Spinner } from '../../icons/Icon';
const InputTextArea = ({
    star,
    lableText,
    ErrorText,
    value,
    onChange,
    disabled,
    required,
    warning,
    minLength,
    loading
}) => {
    const { mode } = useAuth();
    const textareaRef = useRef(null);


    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set new height
        }
    }, [value]);




    return (
        <div className={styles.textarea_container}>
            <div className={styles.textarea_text}>
                <span style={{ color: mode ? "#fff" : "#646b88" }}>{lableText} {star && <span style={{ color: "red" }} >*</span>} </span>
                {ErrorText && <span style={{ color: mode ? "#FEDF00" : "#4164E3", fontSize: "12px" }} >{ErrorText}</span>}

            </div>
            {
                loading ? <div style={{backgroundColor: mode ? "#1B1D21":"#fff",}} className={styles.loading_sk} >
                     <Spinner size="15" />
                     
                     </div> :
                    <div className={styles.textarea_container_con}>
                        <textarea
                            ref={textareaRef}
                            className={mode ? styles.textarea_textarea_dark : styles.textarea_textarea_Light}
                            value={value}
                            onChange={(e) => onChange(e)}
                            disabled={disabled}
                            style={{ minHeight: "40px", overflow: "hidden", cursor: disabled && "not-allowed", }}
                            required={required}
                            minLength={minLength}
                        >
                        </textarea>
                        {warning && <p>{warning}</p>}

                    </div>
            }
        </div>
    )
}

export default InputTextArea