import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { RemoveIcon } from "../../icons/Icon";
import SearchItem from "./SearchItem";
import fetchData from "../../../APIsControll/apiControll";
// import CryptoJS from "crypto-js";
import { useToast } from "../Toaster/Toaster";

const NewItemList = ({
  mode,
  showItemSearch,
  setShowItemSearch,
  managerId,
  orderType,
  onSelectItem,
  index,
  removeItem,
  addItem,
  itemsdetailslength,
  itemInpRefs,
  itemInpRefsMob,
  item,
  onShowDesc,
  onCompletion,
  quantityRefs,
  quantityRefsMob,
  // openAddItemModal,
  focusedIndex,
  onFocus,
  onBlur,
}) => {
  // //console.log("item input ref in child ", itemInpRefs);
  const { show } = useToast();
  const quantityInpRef = useRef(null);
  const quantityInpRefMob = useRef(null);
  const descInpRef = useRef(null);
  const descInpRefMob = useRef(null);
  const outerDivRef = useRef(null);
  const [outerDivWidth, setOuterDivWidth] = useState(0);
  // const [designation, setDesignation] = useState();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDescInput, setShowDescInput] = useState(false);
  const [useFallback, setUseFallback] = useState(true);
  const [quantity, setQuantity] = useState();
  const [desc, setDesc] = useState("");
  const [selectedItem, setSelectedItem] = useState({
    itemNo: "",
    product_name: "",
    collection_name: "",
    warehouse: "",
    qty: "",
    dp: "",
    unit: "",
    amount: "",
    totalQty: "",
    desc: "",
    descKey: "",
  });
  // const getDesignation = sessionStorage.getItem("designation")
  //   ? sessionStorage.getItem("designation")
  //   : "";
  // const decryptedDesignationBytes = CryptoJS.AES.decrypt(
  //   getDesignation,
  //   "secret key"
  // );
  // const designations = decryptedDesignationBytes.toString(CryptoJS.enc.Utf8);

  // const isMobile = "ontouchstart" in window || navigator.maxTouchPoints > 0;

  //? Responsive
  useEffect(() => {
    const updateWidth = () => {
      if (outerDivRef.current) {
        setOuterDivWidth(outerDivRef.current.offsetWidth);
      }
    };

    updateWidth();

    const resizeObserver = new ResizeObserver(updateWidth);
    if (outerDivRef.current) {
      resizeObserver.observe(outerDivRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  // const handleFocus = (index) => {
  //   //console.log(index);

  //   //console.log("focused ", itemInpRefsMob?.current[0]);
  //   itemInpRefsMob?.current[index].scrollIntoView({
  //     behavior: "smooth",
  //     block: "center",
  //   });
  // };
  // //console.log("jhbmjhgkgujl", itemInpRefsMob);

  // useEffect(() => {
  //   if (designations) {
  //     setDesignation(designations.split(",")[0]);
  //   }
  // }, [setDesignation, designations]);

  useEffect(() => {
    quantityRefs[index] = quantityInpRef;
  }, [index, quantityRefs]);

  useEffect(() => {
    quantityRefsMob[index] = quantityInpRefMob;
  }, [index, quantityRefsMob]);

  const fetchItems = useCallback(async () => {
    const apiData = {
      managerId: managerId,
      keyword: searchItem,
      orderType: orderType,
    };
    try {
      setLoading(true);
      const result = await fetchData("order/searchitems?page=", apiData);
      if (result.status === "success") {
        setResults(result.data || []);
        setCurrentIndex(0);
        setLoading(false);
      }
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  }, [searchItem, managerId, orderType]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchItem) {
        fetchItems();
      } else {
        setResults([]);
        setCurrentIndex(0);
      }
    }, 700);

    return () => clearTimeout(debounceTimer);
  }, [searchItem, managerId, orderType, fetchItems]);

  const handleDescToggle = (value) => {
    setShowDescInput(value);
    if (onShowDesc) {
      onShowDesc(value);
    }
  };

  const handleFieldUpdate = (updatedField) => {
    const updatedItem = { ...selectedItem, ...updatedField };
    // //console.log("Selected Item in handleFieldUpdate :", updatedItem);
    setSelectedItem(updatedItem);
    onSelectItem && onSelectItem(updatedItem);
  };

  // const showAddItemModal = () => {
  //   if (designation === "superadmin" || designation === "inventorymanager") {
  //     openAddItemModal();
  //     return;
  //   } else {
  //     show("Only ADMIN and INVENTORY MANAGER can add new item!", "warning");
  //   }
  // };

  const itemKeyDown = (e) => {
    if (e.ctrlKey && e.key === "Backspace") {
      e.preventDefault();

      if (itemsdetailslength === 1) {
        show("YOU CANNOT REMOVE THE ONLY ITEM!", "warning");
        return;
      }
      removeItem(index);

      setTimeout(() => {
        if (index > 0) {
          const prevQuantityRef = quantityRefs[index - 1]?.current;
          if (prevQuantityRef) {
            prevQuantityRef.focus();
          }
        }
      }, 0);
      setTimeout(() => {
        if (index > 0) {
          const prevQuantityRef = quantityRefsMob[index - 1]?.current;
          if (prevQuantityRef) {
            prevQuantityRef.focus();
          }
        }
      }, 0);
    }
  };

  const itemInpKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setCurrentIndex((prevIndex) =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex
      );
      e.preventDefault();
      return;
    }

    if (e.key === "ArrowUp") {
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
      e.preventDefault();
      return;
    }

    // if ((e.altKey || e.metaKey) && e.key.toLowerCase() === "c") {
    //   setTimeout(() => {
    //     showAddItemModal();
    //   }, 0);
    // }

    if (e.key === "Enter") {
      if (searchItem.length === 0) {
        if (itemsdetailslength === 1) {
          show(
            "YOU CANNOT ADD NEW ITEM BEFORE FILLING THE CURRENT ONE!",
            "warning"
          );
          itemInpRefs?.current?.focus();
          itemInpRefsMob?.current?.focus();
        } else {
          onCompletion && onCompletion();
          removeItem();
        }
      } else {
        handleItemSelect(currentIndex);
      }
      return;
    }
  };

  const quantityKeyDown = (e) => {
    setQuantity(parseFloat(e.target.value));
    if (e.key === "Enter") {
      e.preventDefault();
      if (selectedItem.outsourcedItem === "Disable") {
        disabledItemQuantityChange(quantity);
      }
      if (selectedItem.outsourcedItem === "Active") {
        activeItemQuantityChange(quantity);
      }
    }
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  const descKeyDown = (e) => {
    setDesc(e.target.value);
    if (e.key === "Enter") {
      e.preventDefault();
      handleDescChange(desc);
    }
  };

  const activeItemQuantityChange = (qValue) => {
    if (qValue < selectedItem.outSourceItemlimit) {
      const amount = item.price * qValue;
      const updatedItem = {
        ...selectedItem,
        qty: quantity,
        amount: amount,
      };
      setSelectedItem(updatedItem);
      handleFieldUpdate(updatedItem);
      handleDescToggle(true);
      setTimeout(() => {
        descInpRef.current?.focus();
      }, 0);
      setTimeout(() => {
        descInpRefMob.current?.focus();
      }, 0);
    } else {
      show(
        `Out Source limit is ${selectedItem.outSourceItemlimit}, Please decrease the quantity!`,
        "warning"
      );
    }
  };

  const disabledItemQuantityChange = (qValue) => {
    if (qValue <= selectedItem.totalQty) {
      const amount = item.price * qValue;
      const updatedItem = {
        ...selectedItem,
        qty: quantity,
        amount: amount,
      };
      setSelectedItem(updatedItem);
      handleFieldUpdate(updatedItem);
      handleDescToggle(true);
      setTimeout(() => {
        descInpRef.current?.focus();
      }, 0);
      setTimeout(() => {
        descInpRefMob.current?.focus();
      }, 0);
    } else {
      show(
        `Quantity exceeds available stock! Total available: ${selectedItem.totalQty}`,
        "warning"
      );
    }
  };

  const handleDescChange = (desc) => {
    const updatedItem = { ...selectedItem, desc };
    setSelectedItem(updatedItem);
    handleFieldUpdate(updatedItem);
    if (desc.length === 0) {
      handleDescToggle(false);
    }
    setTimeout(() => {
      itemInpRefs?.current?.focus();
      itemInpRefsMob?.current?.focus();
    }, 0);
    addItem();
  };

  const resetSearchComplete = () => {
    setCurrentIndex(0);
    setShowItemSearch(false);
  };

  const handleItemSelect = (index) => {
    const selectedItem = results[index];
    //console.log("selected item ", selectedItem);
    const warnings = [];

    if (selectedItem?.vm === null || selectedItem?.weight === null) {
      warnings.push("Either weight or volume is not added to this item!");
    }
    if (!selectedItem?.price || selectedItem?.dp === 0) {
      warnings.push("Price is not assigned to this item!");
    }
    if (selectedItem?.unit === "") {
      warnings.push("Unit is not assigned to this item!");
    }
    //disable
    if (selectedItem?.outsourcedItem === "Disable") {
      if (selectedItem?.totalQty <= 0) {
        warnings.push("Item is out of stock!");
      }
    }
    // if (selectedItem.outSourceItemlimit === "Active") {
    //   if (selectedItem.totalQty > selectedItem.outSourceItemlimit) {
    //     warnings.push(
    //       `Out Source limit is ${selectedItem.outSourceItemlimit}, Please decrease the quantity!`
    //     );
    //   }
    // }
    if (warnings.length > 0) {
      show(warnings.join(" "), "warning");
    } else {
      setSearchItem("");
      setSelectedItem(selectedItem);
      onSelectItem && onSelectItem(selectedItem);
      resetSearchComplete();
      setTimeout(() => {
        quantityInpRef.current?.focus();
      }, 0);
      setTimeout(() => {
        quantityInpRefMob.current?.focus();
      }, 0);
    }
  };

  const style = mode ? { backgroundColor: "#232529" } : {};

  return (
    <>
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <li
            className={`${styles.p_table_row} ${styles.hide_760px} ${
              mode ? styles.p_table_row_dark : {}
            }`}
            onKeyDown={itemKeyDown}
          >
            <div className={styles.entry_2}> {index + 1} </div>
            <div className={`${styles.entry_2} ${styles.item_no}`}>
              <div className={styles.inp_div}>
                <input
                  ref={itemInpRefs}
                  type="text"
                  placeholder="Search Item No."
                  className={`${styles.item_inp} ${
                    mode ? styles.item_inp_dark : {}
                  }`}
                  value={
                    useFallback && !searchItem
                      ? item.itemNo
                        ? `${item.itemNo}`
                        : ""
                      : searchItem.toUpperCase()
                  }
                  onChange={(e) => {
                    setSearchItem(e.target.value);
                    setUseFallback(false);
                  }}
                  onFocus={() => onFocus(index)}
                  onBlur={() => {
                    onBlur();
                    if (searchItem === "") {
                      setUseFallback(true);
                    }
                  }}
                  onKeyDown={itemInpKeyDown}
                />
                {showItemSearch && focusedIndex === index && (
                  <SearchItem
                    key={index}
                    mode={mode}
                    results={results}
                    currentIndex={currentIndex}
                    loading={loading}
                    onItemSelect={(index) => handleItemSelect(index)}
                    // openModal={showAddItemModal}
                  />
                )}
              </div>
            </div>
            <div className={styles.entry_2}> {item.product_name} </div>
            <div className={styles.entry_2}> {item.warehouse} </div>
            <div className={styles.entry_2}>
              <div className={styles.inp_div}>
                <input
                  type="number"
                  placeholder="Quantity"
                  ref={quantityInpRef}
                  className={`${styles.item_inp} ${
                    mode ? styles.item_inp_dark : {}
                  }`}
                  onKeyDown={quantityKeyDown}
                  value={quantity}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (value >= 0 || e.target.value === "") {
                      setQuantity(value);
                    }
                  }}
                />
              </div>
            </div>
            {/* <div className={styles.entry_2}> {item.vm} </div> */}
            <div className={styles.entry_2}> {item.unit} </div>
            <div className={styles.entry_2}> {item.price} </div>
            <div className={styles.entry_2}>
              {selectedItem.amount ? selectedItem.amount : ""}
            </div>
          </li>
          {itemsdetailslength > 1 && (
            <div
              className={styles.hide_760px}
              style={{ cursor: "pointer" }}
              onClick={removeItem}
            >
              <RemoveIcon />
            </div>
          )}
        </div>

        {showDescInput && (
          <input
            type="text"
            placeholder="Enter description"
            className={`${styles.item_inp} ${styles.desc} ${
              styles.hide_760px
            } ${mode ? styles.item_inp_dark : {}}`}
            ref={descInpRef}
            onKeyDown={descKeyDown}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        )}
      </>

      <div
        onKeyDown={itemKeyDown}
        className={`${styles.show_760px} ${mode ? styles.show_760px_dark : {}}`}
      >
        <div className={styles.show_760px_1}>
          <p style={mode ? { color: "#FFFFFF" } : {}}>
            <span>{index + 1}.</span> Item Information{" "}
          </p>
          {itemsdetailslength > 1 && (
            <div style={{ cursor: "pointer" }} onClick={removeItem}>
              <RemoveIcon />
            </div>
          )}
        </div>
        <div
          className={`${styles.show_760px_2}`}
          style={mode ? { backgroundColor: "#232529" } : {}}
          ref={outerDivRef}
        >
          <div className={styles.inp_div}>
            <input
              ref={itemInpRefsMob}
              type="text"
              placeholder="Search Item No."
              className={`${styles.item_inp} ${
                mode ? styles.item_inp_dark : {}
              }`}
              style={{ maxWidth: "120px" }}
              value={
                useFallback && !searchItem
                  ? item.itemNo
                    ? `${item.itemNo}`
                    : ""
                  : searchItem.toUpperCase()
              }
              onChange={(e) => {
                setSearchItem(e.target.value);
                setUseFallback(false);
              }}
              onFocus={() => {
                onFocus(index);
                // handleFocus(index);
              }}
              onBlur={() => {
                onBlur();
                if (searchItem === "") {
                  setUseFallback(true);
                }
              }}
              onKeyDown={itemInpKeyDown}
              enterKeyHint="enter"
            />
            {showItemSearch && focusedIndex === index && (
              <SearchItem
                key={index}
                mode={mode}
                results={results}
                currentIndex={currentIndex}
                loading={loading}
                onItemSelect={(index) => handleItemSelect(index)}
                // openModal={showAddItemModal}
                style={{ width: `${outerDivWidth}px` }}
              />
            )}
          </div>
          <div>
            <p>{item.product_name}</p>
          </div>
          <div>
            <p>{item.warehouse}</p>
          </div>
        </div>
        <div className={styles.show_760px_3}>
          <div className={styles.element}>
            <p>Qty</p>
            <div style={style}>
              <div className={styles.inp_div}>
                <input
                  type="number"
                  placeholder="Quantity"
                  ref={quantityInpRefMob}
                  className={`${styles.item_inp} ${
                    mode ? styles.item_inp_dark : {}
                  }`}
                  onKeyDown={quantityKeyDown}
                  value={quantity}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    if (value >= 0 || e.target.value === "") {
                      setQuantity(value);
                    }
                  }}
                  enterKeyHint="enter"
                />
              </div>
            </div>
          </div>
          <div className={styles.element}>
            <p>Unit</p>
            <div style={style}>
              <p>{item.unit || "-"}</p>
            </div>
          </div>
          <div className={styles.element}>
            <p>Rate</p>
            <div style={style}>
              <p>{item.price || "-"}</p>
            </div>
          </div>
          <div className={styles.element}>
            <p>Amount</p>
            <div style={style}>
              <p> {selectedItem.amount ? selectedItem.amount : "" || "-"}</p>
            </div>
          </div>
        </div>
        {showDescInput && (
          <div className={styles.show_760px_4} style={style}>
            <input
              type="text"
              placeholder="Enter description"
              className={`${styles.item_inp} ${styles.desc_mob} ${
                mode ? styles.item_inp_dark : {}
              }`}
              ref={descInpRefMob}
              onKeyDown={descKeyDown}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              enterKeyHint="enter"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default NewItemList;
