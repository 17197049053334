import React, { useEffect, useState } from "react";
import {
  GreenButton,
  GreenOutButton,
  RedOutButton,
  WhiteOutButton,
} from "../../components/Buttons";
import { OrderChecked, Search } from "../../components/icons/Icon";
import Modal from "../../components/Modal";
import Pagination from "../../components/Paginantion";
import styles from "./Complaints.module.css";

const Complaints = () => {
  const [requestTypeData, setRequestTypeData] = useState("");
  const [showComplain, setShowComplain] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [modalData, setModalData] = useState([]);

  const [state, setState] = React.useState({
    totalPages: "",
    currentPage: 1,
  });

  const { totalPages, currentPage } = state;

  const handlePagination = (current) => {
    setState({ ...state, currentPage: current });
  };

  const data = [
    // {
    //   name: 'Demo Interiors',
    //   city: 'Lucknow',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'New',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Cancel',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Demo Interiors',
    //   city: 'Lucknow',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'New',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Cancel',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Demo Interiors',
    //   city: 'Lucknow',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'New',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Cancel',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Demo Interiors',
    //   city: 'Lucknow',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'New',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Cancel',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
    // {
    //   name: 'Patil Designs',
    //   city: 'Shahjahapur',
    //   requestType: 'Complaint',
    //   cartType: 'Cart',
    //   date: '10-10-2021',
    //   orderId: '#23567894',
    //   status: 'Resolved',
    // },
  ];

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: 2,
      dmId: 3,
      orderId: "4",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "managers/order", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setModalData(result.data.orders.items);
        }
      })
      .catch((error) => {})
  }, []);

  const handleComplaintModal = (requestType) => {
    if (requestType === "Complaint") {
      setRequestTypeData(requestType);
      setShowComplain(true);
    } else if (requestType === "Cancel") {
      setRequestTypeData(requestType);
      setShowComplain(true);
    }
  };

  return (
    <React.Fragment>
      <ModalComplain
        show={showComplain}
        close={setShowComplain}
        closeBtn={true}
        heading=" "
        content={
          <div className={styles.text_center}>
            <div className={styles.modal_container}>
              <h1 className={styles.modal_heading}>
                {requestTypeData === "Complaint"
                  ? "Complaint"
                  : "Cancel Request"}
              </h1>
              <h3 className={styles.modal_sub_heading}>Description</h3>
              <p className={styles.modal_description}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere
                eget eget lacus, semper pretium scelerisque elementum porta eu.
                Pulvinar eu ut id volutpat. Imperdiet at vel lectus mollis mi,
                quis. Bibendum elit urna ornare ligula quis venenatis vulputate
                at dolor. Dui ac sit faucibus.
              </p>
              <div className={styles.complainDetails_div}>
                <div className={styles.complainDetails}>
                  <div>
                    <span>Dealer Name :</span>
                    <span>
                      <b> Demo Interiors</b>
                    </span>
                  </div>
                  <div>
                    <span>Address : </span>
                    <span>302/12 lorem ipsum gol, Holo Pin Jin-223344</span>
                  </div>
                  <div>
                    <span>Contact : </span>
                    <span>+91-9876543210</span>
                  </div>
                  <div>
                    <span>Email : </span>
                    <span>demo_dealer@demo.com</span>
                  </div>
                </div>

                <div className={styles.complainDetails_right}>
                  <div className={styles.right_content_div}>
                    <span>Complaint Date: </span>
                    <span className={styles.right_txt}>
                      <b>20-10-2021</b>
                    </span>
                  </div>
                  <div className={styles.right_content_div}>
                    <span>Order Id: </span>
                    <span className={styles.right_txt}>
                      <b>#2653897</b>
                    </span>
                  </div>
                  <div className={styles.right_content_div}>
                    <span>Order Date: </span>
                    <span className={styles.right_txt}>
                      <b>12-10-2021</b>
                    </span>
                  </div>
                  <div className={styles.right_content_div}>
                    <span>Cart Type: </span>
                    <span className={styles.right_txt}>
                      <b>Cart</b>
                    </span>
                  </div>
                </div>
              </div>

              <table className={styles.table_main}>
                <thead>
                  <tr>
                    <th className={styles.table_th_Sno}>S. No</th>
                    <th className={styles.table_th}>Item Description </th>
                    <th className={styles.table_th_details}>Qty </th>
                    <th className={styles.table_th_details}>Per</th>
                  </tr>
                </thead>

                <tbody>
                  {modalData &&
                    modalData.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td className={styles.table_th_Sno}>{key + 1}</td>
                          <td className={styles.table_th}>
                            <div className={styles.table_description}>
                              <div className={styles.orderItemNo}>
                                {val.itemNo}{" "}
                              </div>
                              <div className={styles.orderProductName}>
                                {val.product_name}
                              </div>
                              <div className={styles.orderProductName}>
                                Batch No. {val.batch}
                              </div>
                            </div>
                          </td>
                          <td className={styles.table_th_details}>{val.qty}</td>
                          <td className={styles.table_th_details}>
                            {val.unit}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <div className={styles.complaintModal_btn}>
                <RedOutButton
                  type="button"
                  title={requestTypeData === "Complaint" ? "BACK" : "DENY"}
                  handleSubmit={() => setShowComplain(false)}
                />
                <GreenButton
                  type="button"
                  title={
                    requestTypeData === "Complaint"
                      ? "MARK AS RESOLVED"
                      : "APPROVE"
                  }
                  handleSubmit={() => {
                    setShowCancel(true);
                  }}
                />
              </div>
            </div>
          </div>
        }
      />

      <Modal
        modalType="complain"
        show={showCancel}
        close={setShowCancel}
        closeBtn={true}
        heading=" "
        content={
          <div className={styles.ModalText_center}>
            <div className={styles.checkIcon}>
              <OrderChecked width="40" height="38" />
            </div>
            <h2 className={styles.heading_resolve}>
              {requestTypeData === "Complaint"
                ? "Mark As Resolved!"
                : "Cancel Request"}
            </h2>
            <p className={styles.cancel_sub_heading}>
              {requestTypeData === "Complaint"
                ? "Are you sure you want to Mark this complaint as Resolved?"
                : "Are you sure you want to Approve this Cancel Request?"}
            </p>
            {requestTypeData === "Complaint" ? (
              <div className={styles.cancelModal_btn}>
                <WhiteOutButton
                  type="button"
                  title="NO"
                  handleSubmit={() => {
                    setShowCancel(false);
                  }}
                />
                <GreenButton
                  type="button"
                  title="YES"
                  handleSubmit={() => {}}
                />
              </div>
            ) : (
              <div className={styles.cancelModal_btn}>
                <GreenButton
                  type="button"
                  title="NO"
                  handleSubmit={() => {
                    setShowCancel(false);
                  }}
                />
                <GreenOutButton
                  type="button"
                  title="YES"
                  handleSubmit={() => {}}
                />
              </div>
            )}
          </div>
        }
      />

      <div className={styles.main_container}>
        <div className={styles.complain_header}>
          <h2 className={styles.heading}>Complaints & Cancel Requests</h2>

          <div className={styles.complain_header_right}>
            <div className={styles.complain_Sort_By}>
              <label className={styles.complain_sortBy_txt}>Sort By </label>
              <select className={styles.complain_sortBy_select}>
                <option className={styles.dealer_sortBy_color} value="viewall">
                  View All
                </option>
                <option className={styles.dealer_sortBy_color} value="new">
                  New
                </option>
                <option
                  className={styles.dealer_sortBy_color}
                  value="newestfirst"
                >
                  Newest First
                </option>
                <option
                  className={styles.dealer_sortBy_color}
                  value="notverified"
                >
                  Not Verified
                </option>
                <option className={styles.dealer_sortBy_color} value="varified">
                  Verified
                </option>
                <option
                  className={styles.dealer_sortBy_color}
                  value="ascending"
                >
                  Ascending
                </option>
                <option
                  className={styles.dealer_sortBy_color}
                  value="descending"
                >
                  Descending
                </option>
              </select>
            </div>
            <div className={styles.complain_search_div}>
              <input
                type="search"
                placeholder="Search"
                className={styles.dealer_search}
              />
              <Search color="#ffffff" css={styles.dealer_search_icon} />
            </div>
          </div>
        </div>

        <div className={styles.table_heading_div}>
          <span className={styles.table_heading}>Name & City</span>
          <span className={styles.table_heading}>Request Type</span>
          <span className={styles.table_heading}>Cart Type </span>
          <span className={styles.table_heading}>Date </span>
          <span className={styles.table_heading}>Order Id</span>
          <span className={styles.table_heading}>Status</span>
        </div>

        <div className={styles.scrollTable}>
          {data &&
            data.map((item, index) => (
              <div
                key={index}
                className={styles.table_content_div}
                onClick={() => handleComplaintModal(item.requestType)}
              >
                <span className={styles.table_txt}>
                  {item.name + ", " + item.city}
                </span>
                <span
                  className={
                    item.requestType === "Complaint"
                      ? styles.table_txt_green
                      : item.requestType === "Cancel"
                      ? styles.table_txt_red
                      : ""
                  }
                >
                  {item.requestType}
                </span>
                <span className={styles.table_txt}>{item.cartType}</span>
                <span className={styles.table_txt}>{item.date}</span>
                <span className={styles.table_txt}>{item.orderId}</span>
                <span
                  className={
                    item.status === "New"
                      ? styles.table_txt_yellow
                      : item.status === "Resolved"
                      ? styles.table_txt_green
                      : ""
                  }
                >
                  {item.status}
                </span>
              </div>
            ))}
        </div>
        <Pagination
          total={totalPages}
          current={currentPage}
          pagination={(crPage) => handlePagination(crPage)}
        />
      </div>
    </React.Fragment>
  );
};

export default Complaints;

const ModalComplain = ({ show, close, content, heading, closeBtn }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show]);
  return (
    show && (
      <div
        className={styles.modal_backdrop}
        onClick={() => {
          close();
        }}
      >
        <div
          className={styles.modal_content}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {(heading || closeBtn) && (
            <div className={styles.modal_header}>
              {heading && <h3>{heading}</h3>}
              {closeBtn && (
                <button
                  type="button"
                  className={styles.modal_close_button}
                  onClick={() => close()}
                >
                  <span>&times;</span>
                </button>
              )}
            </div>
          )}

          {content}
        </div>
      </div>
    )
  );
};
