import { useNavigate } from "react-router-dom";
import { LinkIcon, RightArrowIcon2 } from "../../icons/Icon";
import styles from "./Apps.module.css";
import ImageSlider from "./ImageSlider";

const AppCard = ({
  mode,
  logo,
  images,
  name,
  shortDesc,
  websiteUrl,
  latest_ver,
  visibility,
}) => {
  const navigate = useNavigate();
  const formatAppName = (name) => name.toLowerCase().replace(/\s+/g, "-");
  const bg_col =
    visibility === "public"
      ? "#31974D"
      : visibility === "unlisted"
      ? "#F93E3E"
      : "#4164E3";
  return (
    <div
      className={styles.apps_app}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      <div className={styles.apps_app_left}>
        <div
          className={styles.img_div}
          style={
            mode
              ? {
                  backgroundColor: "#1B1D21",
                  borderColor: "#1B1D21",
                }
              : {}
          }
        >
          <img src={`${process.env.REACT_APP_S3URL}${logo}`} alt="app_logo" />
        </div>
        <div className={styles.text_div}>
          <p style={mode ? { color: "#FFFFFF" } : {}}>{name}</p>
          <p style={mode ? { color: "#FFFFFF" } : {}}>{shortDesc}</p>
          <a
            href={websiteUrl}
            target="blank"
            style={mode ? { color: "#7698FF" } : {}}
          >
            <span>
              <LinkIcon color={mode ? "#7698FF" : "#0100E4"} />
            </span>
            {websiteUrl}
          </a>
          <div style={mode ? { color: "#FFFFFF" } : {}}>
            <p>Version:</p>{" "}
            <span style={mode ? { backgroundColor: "#1B1D21 " } : {}}>
              Latest {latest_ver}
            </span>{" "}
            <span
              style={{
                backgroundColor: bg_col,
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              {visibility}
            </span>
          </div>
          <span
            className={styles.view_more}
            onClick={() =>
              navigate(`/apps/view?app_name=${formatAppName(name)}`, {
                state: { name },
              })
            }
            style={mode ? { color: "#7698FF" } : {}}
          >
            View More
            <span>
              <RightArrowIcon2 color={mode ? "#7698FF" : "#0100E4"} />
            </span>
          </span>
        </div>
      </div>
      <div className={styles.img_slider}>
        <ImageSlider images={images} autoSlideInterval={3000} />
      </div>
    </div>
  );
};

export default AppCard;
