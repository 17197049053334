import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import { DeleteIcon, LargeEyeIcon } from "../../components/icons/Icon";
import styles from "../inventorymanager/Inventories.module.css";
import Pagination from "../../components/Paginantion";
import Modal from "../../components/Modal";
import { GreenButton, RedOutButton } from "../../components/Buttons";

const OutSideIvtColl = () => {
  const navigate = useNavigate();
  const { managerId } = useAuth();
  const params = useParams();
  const [searchparams] = useSearchParams();
  const [pages, setPages] = useState(1);
  const page = searchparams.get("page")
    ? parseInt(searchparams.get("page"))
    : 1;
  const product_id = searchparams.get("product_id");
  const product_name = params.product;

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [deleteCollection, setDeleteCollection] = useState("");

  useEffect(() => {
    if (managerId) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        product_id: product_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL +
          "inventory/outsideCollections?page=" +
          page +
          "&limit=5",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setLoading(false);
            setData(result.data);
            setPages(result.pages);
          }
          // if (result.pages) {
          //     setPages(result.pages ? result.pages : "")
          //   }
        })
        .catch((error) => {});
    }
  }, [managerId, product_id, page, reload]);

  const handleDeleteCollection = () => {
    if (deleteCollection) {
      setReload(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        collection_id: deleteCollection,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL +
          "inventory/outsideCollections-deleteCollection",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setDeleteCollection("");
            setDeleteText("");
            setShowDeleteModal(false);
            setReload(false);
            alert(result.msg);
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <React.Fragment>
      <div className={styles.main_Container}>
        <div>
          <span onClick={() => navigate(-1)}>
            &lt;&nbsp;&nbsp;<span className={styles.goBack}>Go Back</span>
          </span>
          <h1>{product_name.toUpperCase()}</h1>
        </div>
        <div className={styles.outside_scroll_coll}>
          {loading ? (
            <div className={"loadingMain"}>
              <img src="/wallicon.gif" alt="walliconGIF" />
            </div>
          ) : (
            data &&
            data.map((item, index) => (
              <div
                key={index}
                className={styles.InventoryCard}
                onClick={() =>
                  navigate(
                    "/outsideinventory/" +
                      product_name.replace(/\s/g, "-").toLowerCase() +
                      "/" +
                      item.collection_name.replace(/\s/g, "-").toLowerCase() +
                      "?product_id=" +
                      product_id +
                      "&collection_id=" +
                      item._id
                  )
                }
              >
                <div className={styles.productName_div}>
                  <div className={styles.activeRadioBtn}>
                    <input
                      type="radio"
                      defaultChecked={item.status === "Active" ? true : false}
                    />
                    <span className={styles.status_active}>Active</span>
                  </div>
                  <h2 className={styles.productName}>{item.collection_name}</h2>
                </div>
                <div className={styles.collections_details_div}>
                  <div className={styles.view_div}>
                    <span className={styles.qty}>{item.itemCount}</span>
                    <span className={styles.qty_txt}>Items</span>
                  </div>
                  <div className={styles.view_div}>
                    <span className={styles.qty}>{item.qty}</span>
                    <span className={styles.qty_txt}>Qty</span>
                  </div>
                </div>
                <div className={styles.editView_details_div}>
                  <div className={styles.view_div}>
                    <span>
                      <LargeEyeIcon color="#FFFFFF" />
                    </span>
                    <span className={styles.qty_txt}>View</span>
                  </div>
                  <div
                    className={styles.view_div}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteModal(true);
                      setDeleteCollection(item._id);
                    }}
                  >
                    <span>
                      <DeleteIcon />
                    </span>
                    <span className={styles.delete_txt}>Delete</span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <Pagination total={pages} current={page} />
      </div>

      <Modal
        show={showDeleteModal}
        close={setShowDeleteModal}
        closeBtn={true}
        heading="Delete Collection"
        content={
          <div className={styles.text_center}>
            <p className={styles.message}>
              To confirm! type <span className="text-danger">delete</span> in
              the text input field.
            </p>
            <input
              type="text"
              maxLength={6}
              className={styles.input_delete}
              placeholder="Enter"
              onChange={(e) => setDeleteText(e.target.value)}
            />
            <div className={styles.massageModal_btn}>
              <RedOutButton
                title="CANCEL"
                handleSubmit={() => setShowDeleteModal(false)}
              />
              <GreenButton
                disabled={deleteText.toUpperCase() === "DELETE" ? false : true}
                title="DELETE"
                handleSubmit={() => {
                  handleDeleteCollection();
                }}
              />
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
};

export default OutSideIvtColl;
