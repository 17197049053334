// import React, { useEffect, useRef, useState } from 'react'
// import styles from "./ProductCompo.module.css"
// import { DragNDropIcon } from '../../icons/Icon'
// import { useAuth } from '../../context/Auth'
// import { AddBtnicon } from '../../icons/Icon2'
// import { truncateText } from '../../../Utils/TextUtils'
// const ImageUploder = ({ options, image, setimage, labelText }) => {
//     const { mode } = useAuth();
//     const dropdownRef = useRef()
//     const [isOpen, setIsOpen] = useState(false);
//     const inputRef = useRef(null);
//     const handleChange = (e) => {
//         e.preventDefault();
//         const file = e.target.files[0];
//         setimage(file);
//     }
//     const onButtonClick = () => {
//         inputRef.current.click();
//     };
//     const handelPreview = () => {
//         if (image) {
//             const imageUrl =
//                 image instanceof File || image instanceof Blob
//                     ? URL.createObjectURL(image)
//                     : process.env.REACT_APP_S3URL + image;

//             window.open(
//                 imageUrl,
//                 "ImageViewer",
//                 "width=800,height=600,scrollbars=yes,resizable=yes"
//             );
//         }
//     }

//     const handelDeleteImage = () => {
//         setimage(null)
//     }
//     // //console.log(image);
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setIsOpen(false);
//             }
//         };

//         if (isOpen) {
//             document.addEventListener("mousedown", handleClickOutside);
//         } else {
//             document.removeEventListener("mousedown", handleClickOutside);
//         }

//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, [isOpen]);

//     return (
//         <div className={styles.main_imageUploader_con} >
//             {options &&
//                 <div className={styles.DropDown_value}>
//                     <div ref={dropdownRef} className={styles.drop_down_Con}>
//                         <div onClick={() => setIsOpen(!isOpen)} className={styles.select_icon_con}
//                             style={{ backgroundColor: mode ? "#1B1D21" : "#D9DCE5" }}   >
//                             <div>Select</div>
//                             <div> <AddBtnicon mode={mode} rotate={isOpen ? 0 : 180} /></div>
//                         </div>
//                         {isOpen && (
//                             <ul
//                                 className={styles.drop_down_list}
//                                 style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
//                             >
//                                 {options.map((option, index) => (
//                                     <li
//                                         key={index}
//                                         className={`${styles.drop_down_item} ${mode ? styles.drop_down_item_dark : ""
//                                             }`}
//                                     // onClick={() => handleOptionSelect(option)}
//                                     >
//                                         {truncateText(option.label, 11)}
//                                     </li>
//                                 ))}
//                             </ul>
//                         )}
//                     </div>
//                 </div>
//             }
//             {
//                 labelText &&
//                 <div className={styles.DropDown_value}>
//                     <span style={{ color: mode ? "#D6D6D6" : "#2C2E33" }} > Thumbnail </span>
//                 </div>
//             }

//             <div className={styles.layout_imageUpload_con}
//                 style={{ backgroundColor: mode ? "#2C2E33" : "#D9DCE5", color: mode ? "#fff" : "#000" }} >
//                 <div className={styles.image_con} style={{ backgroundColor: mode ? "#1B1D21" : "#F5F6FA" }} >
//                     {
//                         image ?
//                             <div className={styles.imageContainer}>
//                                 <img src={URL.createObjectURL(image)} alt="Preview" />
//                             </div>
//                             : <>
//                                 <div className={styles.drage_icon}><DragNDropIcon /></div>
//                                 <div className={styles.daag_drop_text} style={{ color: mode ? "#979797" : "#2C2E33" }} >Drag & drop any file.</div>
//                                 <div>
//                                     <p className={styles.browser_fike_text} >
//                                         or <span onClick={onButtonClick}>browse files</span> on your
//                                         computer
//                                     </p>
//                                     <input
//                                         type="file"
//                                         id="fileInput"
//                                         //   accept={fileType}
//                                         style={{ display: "none" }}
//                                         ref={inputRef}
//                                         onChange={handleChange}
//                                     />
//                                 </div>

//                             </>
//                     }

//                 </div>
//                 <div className={styles.bootem_text} style={{ backgroundColor: mode ? "#2C2E33" : "#D9DCE5", color: mode ? "#fff" : "#000" }} >
//                     <div className={styles.bootem_text1}> Max Size - 1920x680 </div>
//                     <div className={styles.bootem_text2}>
//                         <div onClick={handelPreview} > Preview </div>
//                         <div style={{ color: "#E43D3D" }} onClick={handelDeleteImage} > Delete </div>
//                     </div>
//                 </div>

//             </div>
//         </div>
//     )
// }

// export default ImageUploder

// import React, { useEffect, useRef, useState } from 'react'
// import styles from "./ProductCompo.module.css"
// import { DragNDropIcon } from '../../icons/Icon'
// import { useAuth } from '../../context/Auth'
// import { AddBtnicon } from '../../icons/Icon2'
// import { truncateText } from '../../../Utils/TextUtils'

// const ImageUploder = ({ options, image, setimage, labelText, disabled, setoption, value, selectText }) => {
//     const { mode } = useAuth();
//     const dropdownRef = useRef()
//     const [isOpen, setIsOpen] = useState(false);
//     const inputRef = useRef(null);

//     const handleChange = (e) => {
//         e.preventDefault();
//         const file = e.target.files[0];
//         setimage(file);
//     }

//     const onButtonClick = () => {
//         if (!disabled) {
//             inputRef.current.click();
//         }
//     };

//     const handelPreview = () => {
//         if (!disabled) {
//             if (image) {
//                 const imageUrl =
//                     image instanceof File || image instanceof Blob
//                         ? URL.createObjectURL(image)
//                         : process.env.REACT_APP_S3URLA + image;

//                 window.open(
//                     imageUrl,
//                     "ImageViewer",
//                     "width=800,height=600,scrollbars=yes,resizable=yes"
//                 );
//             }
//         }
//     }

//     const handelDeleteImage = () => {
//         if (!disabled) {
//             setimage(null);
//         }
//     }

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setIsOpen(false);
//             }
//         };
//         if (isOpen) {
//             document.addEventListener("mousedown", handleClickOutside);
//         } else {
//             document.removeEventListener("mousedown", handleClickOutside);
//         }

//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, [isOpen]);

//     return (
//         <div className={styles.main_imageUploader_con}>
//             {options && (
//                 <div className={styles.DropDown_value}>
//                     <div ref={dropdownRef} className={styles.drop_down_Con}>
//                         <div
//                             onClick={() => setIsOpen(!isOpen)}
//                             className={styles.select_icon_con}
//                             style={{ backgroundColor: mode ? "#1B1D21" : "#F5F6FA" }}
//                         >
//                             <div className={styles.select_value}>{value}</div>
//                             <div> <AddBtnicon mode={mode} rotate={isOpen ? 180 : 0} /></div>
//                         </div>
//                         {isOpen && (
//                             <ul
//                                 className={styles.drop_down_list}
//                                 style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
//                             >
//                                 {options.map((option, i) => (
//                                     <li
//                                         key={`${i}`}
//                                         className={`${styles.drop_down_item} ${mode ? styles.drop_down_item_dark : ""}`}
//                                         onClick={() => { setoption(option); setIsOpen(false) }}
//                                     >
//                                         {truncateText(option.label, 11)}
//                                     </li>
//                                 ))}
//                             </ul>
//                         )}
//                     </div>
//                 </div>
//             )}
//             {labelText && (
//                 <div className={styles.DropDown_value}>
//                     <span style={{ color: mode ? "#D6D6D6" : "#2C2E33" }} > {labelText} </span>
//                 </div>
//             )}

//             <div className={styles.layout_imageUpload_con}
//                 style={{ backgroundColor: mode ? "#2C2E33" : "#D9DCE5", color: mode ? "#fff" : "#000" }}>
//                 <div className={styles.image_con}
//                     style={{
//                         backgroundColor: mode ? "#1B1D21" : "#F5F6FA",
//                     }}
//                 >
//                     {
//                         image ? <div className={styles.imageContainer}>
//                             <img
//                                 src={
//                                     image instanceof File || image instanceof Blob
//                                         ? URL.createObjectURL(image)
//                                         : process.env.REACT_APP_S3URLA + image
//                                 }
//                                 // src={URL.createObjectURL(image)}
//                                 alt="Preview" loading="lazy" />
//                         </div> :
//                             <>
//                                 <p> <DragNDropIcon /></p>
//                                 <p>Drag & drop any file.</p>
//                                 <p >
//                                     or <span onClick={onButtonClick}>browse files</span> on your
//                                     computer

//                                 </p>
//                                 <input
//                                     type="file"
//                                     id="fileInput"
//                                     style={{ display: "none" }}
//                                     ref={inputRef}
//                                     onChange={handleChange}
//                                     disabled={disabled}
//                                 />
//                             </>
//                     }

//                 </div>

//                 <div className={styles.bootem_text} style={{ backgroundColor: mode ? "#2C2E33" : "#D9DCE5", color: mode ? "#fff" : "#000" }}>
//                     <div className={styles.bootem_text1}> Max Size - 1920x680 </div>
//                     <div className={styles.bootem_text2}>
//                         <div onClick={handelPreview}> Preview </div>
//                         <div style={{ color: "#E43D3D" }} onClick={handelDeleteImage}> Delete </div>
//                     </div>
//                 </div>
//             </div>
//             {
//                 selectText &&
//                 <div className={styles.select_image_chack}>
//                     <div>{selectText}</div>
//                     <div><label className={styles.cl_checkbox} >
//                         <input
//                             type="checkbox"
//                         />
//                         <span></span>
//                     </label></div>
//                 </div>
//             }
//         </div>
//     )
// }

// export default ImageUploder;

// import React, { useEffect, useRef, useState } from 'react'
// import styles from "./ProductCompo.module.css"
// import { DragNDropIcon } from '../../icons/Icon'
// import { useAuth } from '../../context/Auth'
// import { AddBtnicon } from '../../icons/Icon2'
// import { truncateText } from '../../../Utils/TextUtils'
// import Skeleton from '../Skeleton/Skeleton'

// const ImageUploder = ({ options, image, setimage, labelText, disabled, setoption, value, error, loading }) => {
//     const { mode } = useAuth();
//     const dropdownRef = useRef()
//     const [isOpen, setIsOpen] = useState(false);
//     const inputRef = useRef(null);

//     const handleChange = (e) => {
//         e.preventDefault();
//         const file = e.target.files[0];
//         setimage(file);
//     }

//     const onButtonClick = () => {
//         if (!disabled) {
//             inputRef.current.click();
//         }
//     };

//     const handelPreview = () => {
//         if (!disabled) {
//             if (image) {
//                 const imageUrl =
//                     image instanceof File || image instanceof Blob
//                         ? URL.createObjectURL(image)
//                         : process.env.REACT_APP_S3URLA + image;

//                 window.open(
//                     imageUrl,
//                     "ImageViewer",
//                     "width=800,height=600,scrollbars=yes,resizable=yes"
//                 );
//             }
//         }
//     }

//     const handelDeleteImage = () => {
//         if (!disabled) {
//             setimage(null);
//         }
//     }

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setIsOpen(false);
//             }
//         };
//         if (isOpen) {
//             document.addEventListener("mousedown", handleClickOutside);
//         } else {
//             document.removeEventListener("mousedown", handleClickOutside);
//         }

//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, [isOpen]);

//     const handeDrage = (event)=>{
//         //console.log(event);

//     }

//     return (
//         <div className={styles.main_imageUploader_con} onDrag={handeDrage} >

//             {
//                 loading ? <div className={styles.Skeleton_con}>
//                     <div>  <Skeleton thickness={4} /> </div>
//                     <div> <Skeleton thickness={10} /> </div>
//                     <div> <Skeleton thickness={3} lines={2} /> </div>

//                 </div> : <>
//                     {options && (
//                         <div className={styles.DropDown_value}>
//                             <div ref={dropdownRef} className={styles.drop_down_Con}>
//                                 <div
//                                     onClick={() => setIsOpen(!isOpen)}
//                                     className={styles.select_icon_con}
//                                     style={{ backgroundColor: mode ? "#1B1D21" : "#F5F6FA" }}
//                                 >
//                                     <div className={styles.select_value}>{value || "Select"}</div>
//                                     <div> <AddBtnicon mode={mode} rotate={isOpen ? 180 : 0} /></div>
//                                 </div>
//                                 {isOpen && (
//                                     <ul
//                                         className={styles.drop_down_list}
//                                         style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
//                                     >
//                                         {options.map((option, i) => (
//                                             <li
//                                                 key={`${i}`}
//                                                 className={`${styles.drop_down_item} ${mode ? styles.drop_down_item_dark : ""}`}
//                                                 onClick={() => { setoption(option); setIsOpen(false) }}
//                                             >
//                                                 {truncateText(option.label, 11)}
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 )}
//                             </div>
//                         </div>
//                     )}
//                     {labelText && (
//                         <div className={styles.DropDown_value}>
//                             <span style={{ color: mode ? "#D6D6D6" : "#2C2E33" }} > {labelText} </span>
//                         </div>
//                     )}

//                     <div className={styles.layout_imageUpload_con}
//                         style={{ backgroundColor: mode ? "#2C2E33" : "#D9DCE5", color: mode ? "#fff" : "#000" }}>
//                         <div className={styles.image_con}
//                             style={{
//                                 backgroundColor: mode ? "#1B1D21" : "#F5F6FA",
//                             }}
//                         >
//                             {
//                                 image ? <div className={styles.imageContainer} style={{ cursor: disabled ? "not-allowed" : "" }} >
//                                     <img
//                                         src={
//                                             image instanceof File || image instanceof Blob
//                                                 ? URL.createObjectURL(image)
//                                                 : process.env.REACT_APP_S3URLA + image
//                                         }
//                                         // src={URL.createObjectURL(image)}
//                                         alt="Preview" loading="lazy" />
//                                 </div> :
//                                     <>
//                                         <p> <DragNDropIcon /></p>
//                                         <p>Drag & drop any file.</p>
//                                         <p >
//                                             or <span onClick={onButtonClick}>browse files</span> on your
//                                             computer

//                                         </p>
//                                         <input
//                                             type="file"
//                                             id="fileInput"
//                                             style={{ display: "none" }}
//                                             ref={inputRef}
//                                             onChange={handleChange}
//                                             disabled={disabled}
//                                         />
//                                     </>
//                             }

//                         </div>

//                         <div className={styles.bootem_text} style={{ backgroundColor: mode ? "#2C2E33" : "#D9DCE5", color: mode ? "#fff" : "#000" }}>
//                             <div className={styles.bootem_text1}> Max Size - 1920x680 </div>
//                             <div className={styles.bootem_text2}>
//                                 <div onClick={handelPreview}> Preview </div>
//                                 <div style={{ color: "#E43D3D" }} onClick={handelDeleteImage}> Delete </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className={styles.errorText}>
//                         <span>{error}</span>
//                     </div>
//                 </>
//             }

//         </div>
//     )
// }

// export default ImageUploder;

import React, { useEffect, useRef, useState } from "react";
import styles from "./ProductCompo.module.css";
import { DragNDropIcon } from "../../icons/Icon";
import { useAuth } from "../../context/Auth";
import { AddBtnicon } from "../../icons/Icon2";
import { truncateText } from "../../../Utils/TextUtils";
import Skeleton from "../Skeleton/Skeleton";

const ImageUploader = ({
  options,
  image,
  setimage,
  labelText,
  disabled,
  setoption,
  value,
  error,
  loading,
}) => {
  const { mode } = useAuth();
  const dropdownRef = useRef();
  const inputRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // New state for drag effect

  const handleChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) setimage(file);
  };

  const onButtonClick = () => {
    if (!disabled) {
      inputRef.current.click();
    }
  };

  const handelPreview = () => {
    if (!disabled && image) {
      const imageUrl =
        image instanceof File || image instanceof Blob
          ? URL.createObjectURL(image)
          : process.env.REACT_APP_S3URL + image;

      window.open(
        imageUrl,
        "ImageViewer",
        "width=800,height=600,scrollbars=yes,resizable=yes"
      );
    }
  };

  const handelDeleteImage = () => {
    if (!disabled) {
      setimage(null);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Drag and Drop Handlers
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const file = event.dataTransfer.files[0]; // Get the dropped file
    if (file) {
      setimage(file);
    }
  };

  return (
    <div
      className={styles.main_imageUploader_con}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop} // Handle file drop
    >
      {loading ? (
        <div className={styles.Skeleton_con}>
          <div>
            {" "}
            <Skeleton thickness={4} />{" "}
          </div>
          <div>
            {" "}
            <Skeleton thickness={10} />{" "}
          </div>
          <div>
            {" "}
            <Skeleton thickness={3} lines={2} />{" "}
          </div>
        </div>
      ) : (
        <>
          {options && (
            <div className={styles.DropDown_value}>
              <div ref={dropdownRef} className={styles.drop_down_Con}>
                <div
                  onClick={() => setIsOpen(!isOpen)}
                  className={styles.select_icon_con}
                  style={{ backgroundColor: mode ? "#1B1D21" : "#F5F6FA" }}
                >
                  <div className={styles.select_value}>{value || "Select"}</div>
                  <div>
                    {" "}
                    <AddBtnicon mode={mode} rotate={isOpen ? 180 : 0} />
                  </div>
                </div>
                {isOpen && (
                  <ul
                    className={styles.drop_down_list}
                    style={
                      mode ? { backgroundColor: "#232529", border: "none" } : {}
                    }
                  >
                    {options.map((option, i) => (
                      <li
                        key={`${i}`}
                        className={`${styles.drop_down_item} ${
                          mode ? styles.drop_down_item_dark : ""
                        }`}
                        onClick={() => {
                          setoption(option);
                          setIsOpen(false);
                        }}
                      >
                        {truncateText(option.label, 11)}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}

          {labelText && (
            <div className={styles.DropDown_value}>
              <span style={{ color: mode ? "#D6D6D6" : "#2C2E33" }}>
                {" "}
                {labelText}{" "}
              </span>
            </div>
          )}

          <div
            className={`${styles.layout_imageUpload_con} ${
              isDragging ? styles.dragging : ""
            }`}
            style={{
              backgroundColor: mode ? "#2C2E33" : "#D9DCE5",
              color: mode ? "#fff" : "#000",
            }}
          >
            <div
              className={styles.image_con}
              style={{ backgroundColor: mode ? "#1B1D21" : "#F5F6FA", cursor:"pointer" }}
              onClick={() => {
                if (!image) {
                  onButtonClick(); 
                }else{
                  handelPreview();
                }
              }}
            >
              {image ? (
                <div
                  className={styles.imageContainer}
                  style={{ cursor: disabled ? "not-allowed" : "" }}
                >
                  <img
                    src={
                      image instanceof File || image instanceof Blob
                        ? URL.createObjectURL(image)
                        : process.env.REACT_APP_S3URLA + image
                    }
                    alt="Preview"
                    loading="lazy"
                  />
                </div>
              ) : (
                <>
                  <p>
                    <DragNDropIcon />
                  </p>
                  <p>Drag & drop any file.</p>
                  <p>
                    or <span /*    */ >browse files</span> on your
                    computer
                  </p>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    ref={inputRef}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </>
              )}
            </div>

            <div
              className={styles.bootem_text}
              style={{
                backgroundColor: mode ? "#2C2E33" : "#D9DCE5",
                color: mode ? "#fff" : "#000",
              }}
            >
              {/* <div className={styles.bootem_text1}> Max Size - 1920x680 </div> */}
              <div className={styles.bootem_text2}>
                <div onClick={handelPreview}> Preview </div>
                <div style={{ color: "#E43D3D" }} onClick={handelDeleteImage}>
                  {" "}
                  Delete{" "}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.errorText}>
            <span>{error}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageUploader;
