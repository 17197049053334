// import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { useAuth } from '../../components/context/Auth'
// import { LargeEyeIcon } from '../../components/icons/Icon'
// import styles from '../inventorymanager/Inventories.module.css'

// const Inventory = () => {

//     const { managerId } = useAuth()
//     const navigate = useNavigate()
//     const [loading, setLoading] = useState(false)
//     const [data, setData] = useState([])

//     useEffect(() => {
//         if (managerId) {
//             setLoading(true)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "inventory/products", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === "success") {
//                         setLoading(false)
//                         setData(result.data)
//                     }
//                 })
//                 .catch(error => //console.log('error', error));
//         }
//     }, [managerId])

//     return (
//         <React.Fragment>
//             <div className={styles.main_Container}>
//                 <h1 className={styles.inventory_heading}>Inventory</h1>
//                 <div className={styles.inventory_scroll_div}>
//                     {
//                         loading ? <div className={'loadingMain'}>
//                             <img src='/wallicon.gif' alt='walliconGIF' />
//                         </div> :

//                             data && data.map((item, index) =>
//                                 <div key={index} className={styles.InventoryCard} onClick={() => navigate('/inventory/' + item.product_name.replace(/\s/g, "-").toLowerCase() + "?product_id=" + item._id)}>
//                                     <div className={styles.productName_div}>
//                                         <div className={styles.activeRadioBtn}>
//                                             <input type="radio" defaultChecked={item.status === "Active" ? true : false} />
//                                             <span className={styles.status_active}>Active</span>
//                                         </div>
//                                         <h2 className={styles.productName}>{item.product_name}</h2>
//                                     </div>
//                                     <div className={styles.produts_details_div}>
//                                         <div className={styles.view_div}>
//                                             <span className={styles.qty}>{item.collCount}</span>
//                                             <span className={styles.qty_txt}>Colls</span>
//                                         </div>
//                                         <div className={styles.view_div}>
//                                             <span className={styles.qty}>{item.itemCount}</span>
//                                             <span className={styles.qty_txt}>Items</span>
//                                         </div>
//                                         <div className={styles.view_div}>
//                                             <span className={styles.qty}>{item.qty.toFixed(2)}</span>
//                                             <span className={styles.qty_txt}>Qty</span>
//                                         </div>
//                                     </div>
//                                     <div className={styles.view_div}>
//                                         <span><LargeEyeIcon color="#FFFFFF" /></span>
//                                         <span className={styles.qty_txt}>View</span>
//                                     </div>
//                                 </div>
//                             )}
//                 </div>
//             </div>
//         </React.Fragment>
//     )
// }

// export default Inventory

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import {
  AddIcon2,
  ArrowRightIcon,
  EyeBtn,
  TaxRateIcon,
} from "../../components/icons/Icon";
import styles from "./Inventory.module.css";
import fetchData from "../../APIsControll/apiControll";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import Button from "../../components/updatedComponents/Dealers/Button";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";
import NoDataFound from "../../components/updatedComponents/NoDataFound/NoDataFound";
import { TemplateNotFound } from "../../components/icons/Icon2";
import { ModalWrapper } from "../../exports";
import AddNewItemModal from "../../components/updatedComponents/OrderManager/AddNewItemModal";

const Inventory = () => {
  const { managerId } = useAuth();
  const { mode } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [itemSearch, setItemSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(itemSearch);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getInventoryData = useCallback(async () => {
    if (!managerId) return;

    setLoading(true);

    const apiData = {
      managerId: managerId,
      searchKeyword: debouncedSearch,
    };

    try {
      const res = await fetchData("inventory/products", apiData);
      if (res.status === "success") {
        setData(res.data);
      } else {
        setData([]);
      }
    } catch (error) {
      // console.error("Error while fetching inventory data", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  }, [debouncedSearch, managerId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(itemSearch);
    }, 700);

    return () => clearTimeout(handler);
  }, [itemSearch]);

  useLayoutEffect(() => {
    if (!managerId) return;
    getInventoryData();
  }, [getInventoryData, managerId]);

  return (
    <React.Fragment>
      <ModalWrapper isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddNewItemModal
          mode={mode}
          managerId={managerId}
          isModalOpen={isModalOpen}
          handleCloseModal={() => setIsModalOpen(false)}
        />
      </ModalWrapper>
      <div
        className={styles.inventory}
        style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
      >
        <div className={styles.inventory_top}>
          <div className={styles.inventory_top_left}>
            <TopTabBar text={"Product"} />
            <ArrowRightIcon color={mode ? "#FFFFFF" : "#646B88"} />
          </div>

          <div className={styles.inventory_top_right}>
            <div className={styles.tax_rate_btn}>
              <Button
                buttonIcon={<AddIcon2 />}
                buttonText={"Add Item"}
                buttonBgColor={"#000000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  setIsModalOpen(true);
                }}
              />
            </div>
            <div className={styles.tax_rate_btn}>
              <Button
                buttonIcon={<TaxRateIcon />}
                buttonText={"Tax Rate"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  navigate("/inventory/taxrate");
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.inventory_search_bar}>
          <SearchBar
            padding={"6px"}
            fontSize={"12px"}
            fontWeight={"400"}
            borderRadius={"5px"}
            placeholder={"Search by Product"}
            value={itemSearch}
            onChange={(e) => setItemSearch(e.target.value)}
          />
        </div>
        <ul className={styles.responsiveTable}>
          <li
            className={styles.tableHeader}
            style={
              mode
                ? {
                    backgroundColor: "#232529",
                    color: "#fafafa",
                    fontWeight: "500",
                  }
                : {
                    backgroundColor: "#D9DCE5",
                    color: "#646B88",
                    fontWeight: "500",
                  }
            }
          >
            <div className="col">Status</div>
            <div className="col">Product</div>
            <div className="col">Collections</div>
            <div className="col">Items</div>
            <div className="col">Quantity</div>
            <div className="col" style={{ visibility: "hidden" }}>
              Icon
            </div>
          </li>

          <div className={styles.inventory_data_container}>
            {loading ? (
              <Skeleton
                lines={data.length || 15}
                thickness={5}
                additionalStyles={{ marginBottom: "0px", marginTop: "0px" }}
              />
            ) : data.length > 0 ? (
              data.map((item, index) => (
                <li
                  key={index}
                  className={styles.tableHeader}
                  style={
                    mode
                      ? {
                          backgroundColor: "#1B1D21",
                          color: "#fafafa",
                          cursor: "pointer",
                        }
                      : { backgroundColor: "#FFFFFF", cursor: "pointer" }
                  }
                  onClick={() =>
                    navigate(
                      "/inventory/" +
                        item.product_name.replace(/\s/g, "-").toLowerCase() +
                        "?product_id=" +
                        item._id
                    )
                  }
                >
                  <div
                    className="col"
                    style={{
                      color: item.status === "Active" ? "#34A853" : "#F93E3E",
                    }}
                  >
                    {item.status === "Active" ? "Active" : "Inactive"}
                  </div>
                  <div className="col">{item.product_name}</div>
                  <div className="col">{item.collCount}</div>
                  <div className="col">{item.itemCount}</div>
                  <div className="col">{item.qty.toFixed(2)}</div>
                  <div className="col">
                    <EyeBtn color={mode ? "#ffffff" : "#000000"} />
                  </div>
                </li>
              ))
            ) : (
              <div className={styles.inventory_loader}>
                <NoDataFound
                  icon={<TemplateNotFound />}
                  textTitle={`No Data Found`}
                />
              </div>
            )}
          </div>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Inventory;
