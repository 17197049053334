import TopTabBar from "../Tanolet/TopTabBar";
import styles from "./Transactions.module.css";
import VerticalDropDown from "../FormComponents/VerticalDropDown";
import DealerStatusCard from "../Dealers/DealerStatusCard";
import { useAuth } from "../../context/Auth";
import { useState } from "react";
import Skeleton from "../Skeleton/Skeleton";
import NoDataFound from "../NoDataFound/NoDataFound";
import { TemplateNotFound } from "../../icons/Icon2";
import SearchBar from "../FormComponents/SearchBar";
import InputTypedate from "../FormComponents/InputTypedate";
import InputRadio from "../FormComponents/InputRedio";
import GoToPage from "../Pagnation/GoToPage";
import Pagination from "../Pagnation/Pagenation";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import TransactionModal from "./TransactionModal";

const Transactions = () => {
  const { mode } = useAuth();
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState({
    label: "Merchant ID",
    value: "merchantId",
  });

  const getStatusColor = (status) => {
    switch (status) {
      case "Success":
        return "#31974D";

      case "Failed":
        return "#F93E3E";

      case "Rejected":
        return "#F93E3E";

      case "Pending":
        return "#FF9B04";

      default:
        return "";
    }
  };

  const seachBarPlaceholder = selectedRadio.label;

  const loading = false;
  return (
    <>
      <ModalWrapper
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        noBtn
      >
        <TransactionModal
          mode={mode}
          close={() => setIsModalOpen(false)}
          data={transactionData}
          getStatusColor={getStatusColor}
        />
      </ModalWrapper>

      <div
        className={styles.transactions}
        style={
          mode ? { backgroundColor: "#2C2E33", borderColor: "#2C2E33" } : {}
        }
      >
        <div className={styles.transactions_top}>
          <TopTabBar text={"Transactions"} />
          <div className={styles.d_f}>
            <InputTypedate text={"From"} onChange={(e) => {}} />
            <InputTypedate text={"To"} onChange={(e) => {}} />
            <div className={styles.bank_dd}>
              <VerticalDropDown
                height={"37px"}
                fontSize={"12px"}
                dropDownText={"Select Bank"}
              />
            </div>
          </div>
        </div>

        <div
          className={styles.transactions_filters}
          style={
            mode ? { borderColor: "#232529", backgroundColor: "#232529" } : {}
          }
        >
          <>
            {filterData?.map((s, index) => (
              <div
                key={s.status + index}
                className={`${styles.filter} ${
                  mode ? styles.filter_dark : ""
                } ${
                  s.status === selectedFilter
                    ? mode
                      ? styles.filter_active_dark
                      : styles.filter_active
                    : ""
                }`}
                onClick={() => {
                  setSelectedFilter(s.status);
                }}
              >
                <DealerStatusCard
                  statusText={s.status}
                  statusItemCount={s.count}
                />
              </div>
            ))}
          </>
        </div>

        <div className={styles.d_f}>
          <div style={{ width: "180px" }}>
            <SearchBar
              padding={"3px"}
              fontSize={"12px"}
              fontWeight={"300"}
              borderRadius={"5px"}
              placeholder={seachBarPlaceholder}
            />
          </div>
          <div style={{ width: "180px" }}>
            <VerticalDropDown
              height={"30px"}
              fontSize={"12px"}
              dropDownText={"All"}
            />
          </div>
          <div className={styles.transactions_radio_btns}>
            <p style={mode ? { color: "#FFFFFF" } : {}}>Sort By</p>
            <InputRadio
              lableText="Merchant ID"
              name="sortOption"
              id="merchantId"
              value="merchantId"
              checked={selectedRadio.value === "merchantId"}
              onChange={() =>
                setSelectedRadio({
                  label: "Merchant ID",
                  value: "merchantId",
                })
              }
            />
            <InputRadio
              lableText="Original Transaction ID"
              name="sortOption"
              id="originalTransactionId"
              value="originalTransactionId"
              checked={selectedRadio.value === "originalTransactionId"}
              onChange={() =>
                setSelectedRadio({
                  label: "Original Transaction ID",
                  value: "originalTransactionId",
                })
              }
            />
            <InputRadio
              lableText="Refund Bank RRN"
              name="sortOption"
              id="refundBankRRN"
              value="refundBankRRN"
              checked={selectedRadio.value === "refundBankRRN"}
              onChange={() =>
                setSelectedRadio({
                  label: "Refund Bank RRN",
                  value: "refundBankRRN",
                })
              }
            />
            <InputRadio
              lableText="Original Bank RRN"
              name="sortOption"
              id="originalBankRRN"
              value="originalBankRRN"
              checked={selectedRadio.value === "originalBankRRN"}
              onChange={() =>
                setSelectedRadio({
                  label: "Original Bank RRN",
                  value: "originalBankRRN",
                })
              }
            />
          </div>
        </div>

        <ul className={styles.data_list}>
          <li
            className={`${styles.data_list_row_header} ${
              mode ? styles.data_list_row_header_dark : ""
            }`}
          >
            {tableHeaderData.map((h) => (
              <div className={styles.entry} key={h}>
                {h}
              </div>
            ))}
          </li>

          <div className={styles.data_list_container}>
            {loading ? (
              <div>
                <Skeleton
                  lines={15}
                  thickness={5}
                  additionalStyles={{ marginBottom: "0px", marginTop: "0px" }}
                />
              </div>
            ) : (
              <>
                {dummyData.length === 0 ? (
                  <div className={styles.no_data}>
                    <NoDataFound
                      icon={<TemplateNotFound />}
                      textTitle={`No Data Found`}
                    />
                  </div>
                ) : (
                  <>
                    {dummyData.map((d, index) => (
                      <>
                        <li
                          key={d.merchantTransactionId + index}
                          className={`${styles.data_list_row} ${
                            mode ? styles.data_list_row_dark : ""
                          }`}
                          onClick={() => {
                            setTransactionData(d);
                            setIsModalOpen(true);
                          }}
                        >
                          <div className={styles.entry}>
                            {d.merchantTransactionId}
                          </div>
                          <div className={styles.entry}>{d.bankRrnUtr}</div>
                          <div className={styles.entry}>{d.subMerchantId}</div>
                          <div className={styles.entry}>{d.billNo}</div>
                          <div className={styles.entry}>{d.customerVpaUpi}</div>
                          <div className={styles.entry}>
                            {d.transactionInitiationDate}
                          </div>
                          <div className={styles.entry}>
                            {d.transactionCompletionDate}
                          </div>
                          <div className={styles.entry}>{d.amount}</div>
                          <div
                            className={styles.entry}
                            style={{ color: getStatusColor(d.status) }}
                          >
                            {d.status}
                          </div>
                          <div className={styles.entry}>{d.refundStatus}</div>
                          <div className={styles.entry}>
                            {d.chargebackStatus}
                          </div>
                        </li>
                      </>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </ul>

        <div
          className={styles.pagination}
          style={mode ? { backgroundColor: "#232529" } : {}}
        >
          <div style={{ width: "50%" }}>
            <div className={styles.go_to_page}>
              <GoToPage
                // currentPage={page}
                // setCurrentPage={setPage}
                // totalPages={pages}
                key={"1"}
              />
            </div>
          </div>
          <div className={styles.pages}>
            <Pagination
              // currentPage={page}
              // setCurrentPage={setPage}
              // totalPages={pages}
              key={"1"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;

const filterData = [
  {
    status: "All",
    count: 0,
  },
  {
    status: "Success",
    count: 0,
  },
  {
    status: "Pending",
    count: 0,
  },
  {
    status: "Failed",
    count: 0,
  },
];
const tableHeaderData = [
  "Merchant Transaction ID",
  "Bank RRN / UTR",
  "Sub Merchant ID",
  "Bill Number",
  "Customer VPA / UPI",
  "Initiation Date",
  "Completion Date",
  "Amount",
  "Status",
  "Refund Status",
  "Chargeback Status",
];
const dummyData = [
  {
    merchantTransactionId: "MT123456789",
    bankRrnUtr: "UTR987654321",
    subMerchantId: "SM12345",
    billNo: "BILL1001",
    customerVpaUpi: "customer@upi",
    transactionInitiationDate: "2024-02-19 10:30:00",
    transactionCompletionDate: "2024-02-19 10:35:00",
    amount: 500.75,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456790",
    bankRrnUtr: "UTR987654322",
    subMerchantId: "SM12346",
    billNo: "BILL1002",
    customerVpaUpi: "buyer@upi",
    transactionInitiationDate: "2024-02-19 11:00:00",
    transactionCompletionDate: "2024-02-19 11:05:00",
    amount: 1200.0,
    status: "Pending",
    refundStatus: "Processing",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456791",
    bankRrnUtr: "UTR987654323",
    subMerchantId: "SM12347",
    billNo: "BILL1003",
    customerVpaUpi: "shopper@upi",
    transactionInitiationDate: "2024-02-19 12:00:00",
    transactionCompletionDate: "2024-02-19 12:05:00",
    amount: 299.99,
    status: "Failed",
    refundStatus: "Initiated",
    chargebackStatus: "Under Review",
  },
  {
    merchantTransactionId: "MT123456792",
    bankRrnUtr: "UTR987654324",
    subMerchantId: "SM12348",
    billNo: "BILL1004",
    customerVpaUpi: "user@upi",
    transactionInitiationDate: "2024-02-19 13:30:00",
    transactionCompletionDate: "2024-02-19 13:35:00",
    amount: 750.5,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456793",
    bankRrnUtr: "UTR987654325",
    subMerchantId: "SM12349",
    billNo: "BILL1005",
    customerVpaUpi: "client@upi",
    transactionInitiationDate: "2024-02-19 14:00:00",
    transactionCompletionDate: "2024-02-19 14:05:00",
    amount: 999.99,
    status: "Pending",
    refundStatus: "Processing",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456794",
    bankRrnUtr: "UTR987654326",
    subMerchantId: "SM12350",
    billNo: "BILL1006",
    customerVpaUpi: "customer@upi",
    transactionInitiationDate: "2024-02-19 15:00:00",
    transactionCompletionDate: "2024-02-19 15:05:00",
    amount: 459.75,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456795",
    bankRrnUtr: "UTR987654327",
    subMerchantId: "SM12351",
    billNo: "BILL1007",
    customerVpaUpi: "merchant@upi",
    transactionInitiationDate: "2024-02-19 16:30:00",
    transactionCompletionDate: "2024-02-19 16:35:00",
    amount: 1450.25,
    status: "Failed",
    refundStatus: "Initiated",
    chargebackStatus: "Under Review",
  },
  {
    merchantTransactionId: "MT123456796",
    bankRrnUtr: "UTR987654328",
    subMerchantId: "SM12352",
    billNo: "BILL1008",
    customerVpaUpi: "shopkeeper@upi",
    transactionInitiationDate: "2024-02-19 17:00:00",
    transactionCompletionDate: "2024-02-19 17:05:00",
    amount: 330.5,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456797",
    bankRrnUtr: "UTR987654329",
    subMerchantId: "SM12353",
    billNo: "BILL1009",
    customerVpaUpi: "consumer@upi",
    transactionInitiationDate: "2024-02-19 18:30:00",
    transactionCompletionDate: "2024-02-19 18:35:00",
    amount: 599.99,
    status: "Pending",
    refundStatus: "Processing",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456798",
    bankRrnUtr: "UTR987654330",
    subMerchantId: "SM12354",
    billNo: "BILL1010",
    customerVpaUpi: "client@upi",
    transactionInitiationDate: "2024-02-19 19:00:00",
    transactionCompletionDate: "2024-02-19 19:05:00",
    amount: 725.0,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456799",
    bankRrnUtr: "UTR987654331",
    subMerchantId: "SM12355",
    billNo: "BILL1011",
    customerVpaUpi: "buyer1@upi",
    transactionInitiationDate: "2024-02-19 20:00:00",
    transactionCompletionDate: "2024-02-19 20:05:00",
    amount: 880.25,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456800",
    bankRrnUtr: "UTR987654332",
    subMerchantId: "SM12356",
    billNo: "BILL1012",
    customerVpaUpi: "shopper2@upi",
    transactionInitiationDate: "2024-02-19 21:00:00",
    transactionCompletionDate: "2024-02-19 21:05:00",
    amount: 400.5,
    status: "Pending",
    refundStatus: "Processing",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456801",
    bankRrnUtr: "UTR987654333",
    subMerchantId: "SM12357",
    billNo: "BILL1013",
    customerVpaUpi: "merchant2@upi",
    transactionInitiationDate: "2024-02-19 22:30:00",
    transactionCompletionDate: "2024-02-19 22:35:00",
    amount: 1450.75,
    status: "Failed",
    refundStatus: "Initiated",
    chargebackStatus: "Under Review",
  },
  {
    merchantTransactionId: "MT123456802",
    bankRrnUtr: "UTR987654334",
    subMerchantId: "SM12358",
    billNo: "BILL1014",
    customerVpaUpi: "user2@upi",
    transactionInitiationDate: "2024-02-19 23:00:00",
    transactionCompletionDate: "2024-02-19 23:05:00",
    amount: 999.99,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456803",
    bankRrnUtr: "UTR987654335",
    subMerchantId: "SM12359",
    billNo: "BILL1015",
    customerVpaUpi: "client2@upi",
    transactionInitiationDate: "2024-02-19 23:30:00",
    transactionCompletionDate: "2024-02-19 23:35:00",
    amount: 1100.0,
    status: "Pending",
    refundStatus: "Processing",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456804",
    bankRrnUtr: "UTR987654336",
    subMerchantId: "SM12360",
    billNo: "BILL1016",
    customerVpaUpi: "customer2@upi",
    transactionInitiationDate: "2024-02-20 00:30:00",
    transactionCompletionDate: "2024-02-20 00:35:00",
    amount: 599.75,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456805",
    bankRrnUtr: "UTR987654337",
    subMerchantId: "SM12361",
    billNo: "BILL1017",
    customerVpaUpi: "merchant3@upi",
    transactionInitiationDate: "2024-02-20 01:00:00",
    transactionCompletionDate: "2024-02-20 01:05:00",
    amount: 1350.25,
    status: "Failed",
    refundStatus: "Initiated",
    chargebackStatus: "Under Review",
  },
  {
    merchantTransactionId: "MT123456806",
    bankRrnUtr: "UTR987654338",
    subMerchantId: "SM12362",
    billNo: "BILL1018",
    customerVpaUpi: "shopkeeper2@upi",
    transactionInitiationDate: "2024-02-20 02:00:00",
    transactionCompletionDate: "2024-02-20 02:05:00",
    amount: 430.5,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456807",
    bankRrnUtr: "UTR987654339",
    subMerchantId: "SM12363",
    billNo: "BILL1019",
    customerVpaUpi: "consumer2@upi",
    transactionInitiationDate: "2024-02-20 03:30:00",
    transactionCompletionDate: "2024-02-20 03:35:00",
    amount: 725.99,
    status: "Pending",
    refundStatus: "Processing",
    chargebackStatus: "None",
  },
  {
    merchantTransactionId: "MT123456808",
    bankRrnUtr: "UTR987654340",
    subMerchantId: "SM12364",
    billNo: "BILL1020",
    customerVpaUpi: "client3@upi",
    transactionInitiationDate: "2024-02-20 04:00:00",
    transactionCompletionDate: "2024-02-20 04:05:00",
    amount: 825.0,
    status: "Success",
    refundStatus: "Not Initiated",
    chargebackStatus: "None",
  },
];
