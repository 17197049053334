import "./App.css";
import React, { lazy, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAuth } from "./components/context/Auth";
import { debounce } from "./Utils/TextUtils";
import { useInactivity } from "./components/context/Inactivity";
import CryptoJS from "crypto-js";

import {
  Accounts, AddCollection, AddItem, AddManager, AddPartner, AddProduct, AddStyleAndType,
  AdjustmentInvoice, AllOrders, Apps, Campaign, CampaignReport, CollectionInfo, CommonDashboard,
  Complaints, Contacts, ContentDashboard, CreateApp, CreateCampaign, CreateGroup, CreatePackers,
  CreateTemplate, DealerList, Dealers, Delivery, DispatchDasboard, DispatchDasboardMob, DispatchOrder,
  DispatchOrders, EditDelete, EstimateInvoice, Groups, Home, Inventory, InventoryCollections,
  Inventryreportdetails, Invoices, Items, Kyc, Leads, MainMobileChats, MainWhatsappChat, Managers,
  Marketing, MarketingDashboard, MarketingLead, NavLayout, NewManager, Newviewdispatchdetails,
  NoMatch, Order, OrderDealers, OutSideInventory, OutSideInventoryMob, OutSideIvtColl, OutSideIvtCollInfo,
  OutSideIvtCollInfomob, OutSideIvtCollMob, Packer, Packers, PackingOrder, PackingOrders, PackingVoucher,
  ProductFAQ, Products, PurchaseInovice, Recipts, ReciptsMob, Refunds, SaleInvoice, SaleOrderInovice,
  SingleCampaing, SingleTemp, TaxRate, Templates, TestLink, Transactions, TransferDealer, TransferOrders,
  TransferOrdersMob, TransferStock, UploadInvoice, ViewApp, ViewDispatchDetails, ViewDispatchDetailsMob,
  ViewProduct, Warehouse

} from "./exports";
import { useNotification } from "./components/context/NotificationContext";
import { SocketConnected, socket } from "./Socket/socket";
import fetchData from "./APIsControll/apiControll";
import WhatsappMobSaleOrder from "./mobile/pages/WhatsappMobSaleOrder";
const orderId = new URLSearchParams(window.location.search).get("orderId");
const AppSearchModal = lazy(() => import("./AppFeatures/AppSearchModal"));

const ModalWrapper = lazy(() =>
  import("./components/updatedComponents/layoutWrapper/ModalWrapper")
);
const TimerLogout = lazy(() =>
  import("./components/updatedComponents/Login/TimerLogout")
);

function App() {
  const { showModal, setShowModal } = useInactivity();

  const {
    mode,
    loggedIn,
    setLoggedIn,
    designation,
    setDesignation,
    setManagerId,
    setManagerName,
    setDesignations,
    designations,
    setOrderData,
    // orderData,
    managerId,
    setTotalUnreadChat,
    // Warehouse,
    setWarehouse,
  } = useAuth();

  const { addNotification, sendBrowserNotification } = useNotification();

  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  //  const {designation} =  useAuth();


  useEffect(() => {
    const mid = sessionStorage.getItem("managerId");
    const encryptedName = sessionStorage.getItem("name")
      ? sessionStorage.getItem("name")
      : "";
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedName, "secret key");
    const mname = decryptedBytes.toString(CryptoJS.enc.Utf8);
    if (designation) {
      setLoggedIn(true);
    }
    if (mid) {
      setManagerId(mid);
    }
    if (mname) {
      setManagerName(mname);
    }
  }, [designation, setLoggedIn, setManagerId, setManagerName]);

  //  get single getDesignation
  const getDesignation = sessionStorage.getItem("designation") ? sessionStorage.getItem("designation") : "";
  const decryptedDesignationBytes = CryptoJS.AES.decrypt(getDesignation, "secret key");
  const designationsIs = decryptedDesignationBytes.toString(CryptoJS.enc.Utf8);
  //  get arr designations for routing

  const getDesignations = sessionStorage.getItem("designations") ? sessionStorage.getItem("designations") : "";
  const decryptedDesignationsBytes = CryptoJS.AES.decrypt(getDesignations, "secret key");
  const designationss = decryptedDesignationsBytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (designationsIs) {
      setDesignation(designationsIs.split(",")[0]);
    }
  }, [setDesignation, designationsIs]);

  useEffect(() => {
    if (designationss) {
      setDesignations(JSON.parse(designationss));
    }
  }, [setDesignations, designationss]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 500);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getManagerId = async () => {
      const id = await sessionStorage.getItem("managerId");
      setManagerId(id);
    };

    getManagerId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const handleKeyDown = (event) => {
      // console.log("check 1");
      if (
        loggedIn &&
        Array.isArray(designations) &&
        (designations.includes("superadmin") ||
          designations.includes("inventorymanager") ||
          designations.includes("ordermanager"))
      ) {
        // console.log("check 2");
        const isInputFocused = ["INPUT", "TEXTAREA", "SELECT"].includes(
          document.activeElement.tagName
        );

        if (event.altKey && event.key?.toLowerCase() === "v") {
          // console.log("check 3");
          event.preventDefault();
          setOpenSearchModal(false);
          navigate("/orders/order/saleorder");
          return;
        }

        if (!isInputFocused && event.key?.toLowerCase() === "v") {
          // console.log("check 4");
          event.preventDefault();
          setOpenSearchModal(false);
          navigate("/orders/order/saleorder");
          return;
        }
        setShowModal(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, designations]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (designations.includes("superadmin") && loggedIn) {
        // CTRL + SPACE opens up default search in opera
        if (event.ctrlKey && event.key?.toLowerCase() === "k") {
          event.preventDefault();
          setOpenSearchModal(true);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [designations, loggedIn]);


  useEffect(() => {
    setWarehouse(JSON.parse(localStorage.getItem("warehouse")));
    if (!managerId || !designation) return;
    SocketConnected({ socketUserId: `${managerId}`, role: designation, warehouseNames: JSON.parse(localStorage.getItem("warehouse")) });
    socket.on("allorder", (updatedOrders) => {
      // console.log("allorder ", updatedOrders);

      const orderManagerId = updatedOrders.allOrder[0].managerId.toString();
      if (managerId !== orderManagerId) {
        if (updatedOrders.allOrder[0].status === "Payment Pending") {
          addNotification("New order received!", "info");
          sendBrowserNotification(
            `New Order #${updatedOrders.allOrder[0]?.orderId}!`,
            `Order #${updatedOrders.allOrder[0]?.orderId} was placed.`,
            "/assets/updatedAssets/logo.png",
            "/orders"
          );
        }

        if (updatedOrders.allOrder[0].status === "Packing In Progress") {
          addNotification("Payment received!", "info");
          sendBrowserNotification(
            `Updated order #${updatedOrders.allOrder[0]?.orderId}!`,
            `Payment received for order #${updatedOrders.allOrder[0]?.orderId}.`,
            "/assets/updatedAssets/logo.png",
            "/orders"
          );
        }
      }

      setOrderData((prev) => {
        const index = prev.findIndex((order) => order.orderId === updatedOrders.allOrder[0].orderId);
        if (index !== -1) {
          const newupdatedOrders = [...prev];
          newupdatedOrders[index] = { ...newupdatedOrders[index], ...updatedOrders.allOrder[0] };
          return newupdatedOrders;
        } else {
          return [updatedOrders.allOrder[0], ...prev];
        }
      });
    });

    return () => {
      // socket.off("allorder");
      // socket.off("Socket Connected");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designation, managerId,]);


  const previousUnreadCount = useRef(0);

  const audio = new Audio("./assets/whatsapp_notification.mp3");
  const allowedDesignations = [
    "ordermanager",
    "inventorymanager",
    "dispatchmanager",
    "paymentmanager",
    "packingmanager",
    // "superadmin",
  ];
  const handleUnReadChats = async () => {
    const isAllowed = designations.some((role) => allowedDesignations.includes(role));
    if (isAllowed) {
      try {
        const res = await fetchData("wallikonChat/unreadChats", {
          managerId: await sessionStorage.getItem("managerId"),
        });

        if (res.status === "success") {
          const newUnreadCount = res.totalUnreadChat;

          if (
            newUnreadCount > previousUnreadCount.current &&
            designation === ""
          ) {
            audio
              .play()
              .catch((error) => console.error("Error playing sound:", error));
          }

          // Update the unread count state and ref
          setTotalUnreadChat(newUnreadCount);
          previousUnreadCount.current = newUnreadCount;
        } else {
          setTotalUnreadChat(0);
          previousUnreadCount.current = 0;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    const getManagerId = async () => {
      const id = await sessionStorage.getItem("managerId");
      setManagerId(id);
    };

    getManagerId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (managerId) {
      handleUnReadChats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerId]);
  useEffect(() => {
    // const socket = io(process.env.REACT_APP_URL);
    socket.on("updateAdminChats", (allAdminChats) => {
      if (allAdminChats) {
        handleUnReadChats();
      }
    });

    socket.on("updateDealerChats", (allDealerChats) => {

      if (allDealerChats) {
        handleUnReadChats();
        sendBrowserNotification(
          `New Message #${allDealerChats?.allDealerChats?.whatsapp} !`,
          `Message #${allDealerChats?.allDealerChats?.message[0]?.dealerChat}`,
          "/assets/updatedAssets/logo.png",
          "/whatsappchats"
        );
      }
    });

    // Clean up on component unmount
    return () => {
      socket.off("updateAdminChats");
      socket.off("updateDealerChats");
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SplitScreenLayout = () => {
    return (
      <div style={styles.container}>
        <div style={styles.leftPanel}>
          <Order />
        </div>
        <div style={styles.rightPanel}>
          <WhatsappMobSaleOrder />
        </div>
      </div>
    );
  };

  const styles = {
    container: {
      display: "flex",
      height: "90vh",
    },
    leftPanel: {
      width: "75%",

    },
    rightPanel: {
      width: "25%",

    },
  };

  const MemoizedSplitScreenLayout = useMemo(() => <SplitScreenLayout />, []);
  return (
    <div
      className="main_container"
      style={{ backgroundColor: mode ? "#232529" : "#D9DCE5", }}
    >
      {showModal && (
        <ModalWrapper isOpen={showModal}>
          <div
            style={{
              background: mode ? "#2C2E33" : "#fff",
              color: mode ? "#fff" : "#000",
              border: mode ? "none" : "1px solid #ccc",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <TimerLogout setShowModal={setShowModal} />
          </div>
        </ModalWrapper>
      )}

      <Suspense fallback={<div>Loading...</div>}>
        {openSearchModal && (
          <ModalWrapper
            isOpen={openSearchModal}
            onClose={() => setOpenSearchModal(false)}
          >
            <AppSearchModal
              mode={mode}
              close={() => setOpenSearchModal(false)}
            />
          </ModalWrapper>
        )}
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        {!loggedIn ? (
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        ) : (
          <div className="content">
            <Routes>
              <Route path="/" element={<NavLayout />}>
                <Route index element={<AddManager />} />


                {designations.includes("superadmin") && (
                  <>
                    <Route path="/dashboard" element={<CommonDashboard />} />
                    <Route path="/profile" element={<AddManager />} />
                    {/* Dealer Routes */}

                    <Route path="/dealers" element={<Dealers />} />
                    <Route path="/dealers/:id" element={<Kyc />} />
                    <Route path="/dealers/transferdealer" element={<TransferDealer />} />
                    {/* Order Routes */}

                    <Route path="/orders" element={<AllOrders />} />
                    <Route path="/orders/order/saleorder" element={<Order />} />
                    <Route path="/orders/order/:route" element={<Order />} />
                    {/* Receipts */}

                    <Route path="/recipts" element={width < 1084 ? <ReciptsMob /> : <Recipts />} />
                    {/* Dispatch */}

                    <Route path="/dispatchdashboard" element={width < 184 ? <DispatchDasboardMob /> : <DispatchDasboard />} />
                    <Route path="/dispatchorders" element={<DispatchOrders />} />
                    <Route path="/dispatchorders/:orderId" element={<PackingVoucher />} />
                    {/* Inventory */}

                    <Route path="/inventory" element={<Inventory />} />
                    <Route path="/inventory/:product" element={<InventoryCollections />} />
                    <Route path="/inventory/taxrate" element={<TaxRate />} />
                    <Route path="/inventory/:product/:collection" element={<CollectionInfo />} />
                    {/* inventory report */}

                    {/* <Route path="/inventoryreport" element={<DispatchOrders />} /> */}
                    {/* Campaigns */}

                    <Route path="/campaign/templates" element={<Templates />} />
                    <Route path="/campaign" element={<Campaign />} />
                    <Route path="/campaign-report/:tempname/:campaignName" element={<CampaignReport />} />
                    <Route path="/campaign/newcampaign" element={<CreateCampaign />} />
                    <Route path="/singlecampaign/:campaing" element={<SingleCampaing />} />
                    <Route path="/campaign/groups" element={<Groups />} />
                    <Route path="/newgroup" element={<CreateGroup />} />
                    <Route path="/newgroup/:tempname" element={<DealerList />} />
                    <Route path="/singeltemp" element={<SingleTemp />} />
                    <Route path="/templates/template" element={<CreateTemplate />} />
                    {/* Managers */}

                    <Route path="/managers" element={<Managers />} />
                    <Route path="/managers/manager" element={<AddManager />} />
                    <Route path="/managers/newmanager" element={<NewManager />} />
                    <Route path="/managers/newmanager/:manager_Id" element={<NewManager />} />
                    {/* Products */}

                    <Route path="/products" element={<Products />} />
                    <Route path="/products/product/:product" element={<ViewProduct />} />
                    <Route path="/products/items/:product/:collection" element={<Items />} />
                    <Route path="/products/item/:product/:collection" element={<AddItem />} />
                    <Route path="/products/addstyle/:product" element={<ViewProduct />} />
                    <Route path="/products/item/newitem/:product/:collection" element={<AddItem />} />
                    <Route path="/products/:Product/faq" element={<ProductFAQ />} />
                    <Route path="/products/addstyle/:productname/:productId" element={<AddStyleAndType />} />
                    <Route path="/products/addtype/:productname/:productId" element={<AddStyleAndType />} />
                    <Route path="/products/addproduct" element={<AddProduct />} />
                    <Route path="/products/product/addcollection/:productName" element={<AddCollection />} />

                    {/* Marketing */}
                    <Route path="/marketing" element={<Marketing />} />
                    <Route path="/marketing-lead/:id" element={<MarketingLead />} />

                    {/* Invoices */}
                    <Route path="/orders/saleinvoice" element={<SaleOrderInovice />} />
                    <Route path="/orders/estimateinvoice" element={<EstimateInvoice />} />
                    <Route path="/orders/purchaseinvoice/:voucherNo" element={<PurchaseInovice />} />
                    <Route path="/orders/adjustmentinvoice/:voucherNo" element={<AdjustmentInvoice />} />

                    {/* Transfer Orders */}
                    <Route path="/orders/transfer" element={<TransferStock />} />
                    <Route path="/transferorders" element={width < 1084 ? <TransferOrdersMob /> : <TransferOrders />} />

                    {/* Dispatch & Packing */}
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <Newviewdispatchdetails />} />
                    <Route path="/packingorder" element={<PackingOrders />} />
                    <Route path="/packingorder/:orderId" element={<PackingOrder />} />
                    <Route path="/packers" element={<Packers />} />
                    <Route path="/packer/:pmId" element={<Packer />} />
                    <Route path="/createpacker" element={<CreatePackers />} />

                    {/* Other Routes */}
                    <Route path="/complaints" element={<Complaints />} />
                    <Route path="/accounts" element={<Accounts />} />
                    <Route path="/delivery" element={<Delivery />} />
                    <Route path="/delivery/addpartner" element={<AddPartner />} />
                    <Route path="/warehouses" element={<Warehouse />} />
                    <Route path="/deleteoredit" element={<EditDelete />} />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route path="/invoices/invoice" element={<SaleInvoice />} />
                    <Route path="/invoices/uploadedinvoice" element={<UploadInvoice />} />

                    {/* Out Side Inventory */}
                    <Route path="/outsideinventory" element={width < 1084 ? <OutSideInventoryMob /> : <OutSideInventory />} />
                    <Route path="/outsideinventory/:product" element={width < 1084 ? <OutSideIvtCollMob /> : <OutSideIvtColl />} />
                    <Route path="/outsideinventory/:product/:collection" element={width < 1084 ? <OutSideIvtCollInfomob /> : <OutSideIvtCollInfo />} />

                    {/* WhatsApp Chats */}
                    <Route path="/whatsappchats" element={width < 1084 ? <MainMobileChats /> : <MainWhatsappChat />} />

                    {/* Apps */}
                    <Route path="/apps" element={<Apps />} />
                    <Route path="/apps/view" element={<ViewApp />} />
                    <Route path="/apps/edit" element={<CreateApp />} />
                    <Route path="/apps/create" element={<CreateApp />} />

                    {/* transactions */}
                    <Route path="/transactions" element={<Transactions />} />

                    {/* refunds */}
                    <Route path="/refunds" element={<Refunds />} />

                    {/* Test */}
                    <Route path="/inventory-report" element={<Inventryreportdetails />} />
                    <Route path="/TestLink" element={<TestLink />} />
                    <Route path="/Inventryreportdetails" element={<Inventryreportdetails />} />

                    <Route path="/Inventryreport" element={<Inventryreportdetails />} />
                    {/* <Route path="/Inventryreport/products" element={<Inventryproducts />} />
                          <Route path="/Inventryreport/item" element={<Inventrycollectionitem/>} />
                          <Route path="/Inventryreport/item" element={<InventoryCollections/>} />
                          <Route path ="Inventrycollectionitemmodal" element={<Inventrycollectionitemmodal/>}   /> */}

                    {/* Catch-All Route */}
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designations.includes("contentmanager") && (
                  <>
                    <Route path="/dashboard" element={<ContentDashboard />} />
                    <Route path="/profile" element={<AddManager />} />
                    <Route path="/addfaq/:product" element={<ViewProduct />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/products/product/:product" element={<ViewProduct />} />
                    <Route path="/products/items/:product/:collection" element={<Items />} />
                    <Route path="/products/item/:product/:collection" element={<AddItem />} />
                    <Route path="/products/addstyle/:product" element={<ViewProduct />} />
                    <Route path="/products/item/newitem/:product/:collection" element={<AddItem />} />
                    <Route path="/products/addstyle/:productname/:productId" element={<AddStyleAndType />} />
                    <Route path="/products/addtype/:productname/:productId" element={<AddStyleAndType />} />
                    <Route path="/products/addproduct" element={<AddProduct />} />
                    <Route path="/products/product/addcollection/:productName" element={<AddCollection />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designations.includes("ordermanager") && (
                  <>
                    <Route path="/dashboard" element={<Dealers />} />
                    <Route path="/profile" element={<AddManager />} />
                    <Route path="/dealers/:id" element={<Kyc />} />
                    <Route path="/dealers" element={<Dealers />} />
                    <Route path="/dealers/transferdealer" element={<TransferDealer />} />
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <Newviewdispatchdetails />} />
                    {/* <Route path="/orders/order/:route" element={<Order />} /> */}
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <ViewDispatchDetails />} />
                    <Route path="/orders" element={<AllOrders />} />
                    {/* <Route path="/orders/order/:route" element={<Order />} /> */}
                    <Route path="/orders/saleinvoice" element={<SaleOrderInovice />} />
                    <Route path="/orders/dealers" element={<OrderDealers />} />
                    <Route path="/orders/estimateinvoice" element={<EstimateInvoice />} />
                    <Route path="/whatsappchats" element={width < 1084 ? <MainMobileChats /> : <MainWhatsappChat />} />
                    {!orderId &&
                      // (<Route path="/orders/order/saleorder" element={<SplitScreenLayout />} />)}
                      (<Route path="/orders/order/saleorder" element={MemoizedSplitScreenLayout} />)}
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designations.includes("inventorymanager") && (
                  <>
                    <Route path="/dashboard" element={<Inventory />} />
                    <Route path="/profile" element={<AddManager />} />
                    <Route path="/orders" element={<AllOrders />} />
                    <Route path="/orders/order/saleorder" element={<Order />} />
                    <Route path="/orders/order/:route" element={<Order />} />
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <ViewDispatchDetails />} />
                    <Route path="/transferorders" element={width < 1084 ? <TransferOrdersMob /> : <TransferOrders />} />
                    <Route path="/orders/transfer" element={<TransferStock />} />
                    <Route path="/orders/saleinvoice" element={<SaleOrderInovice />} />
                    <Route path="/orders/purchaseinvoice/:voucherNo" element={<PurchaseInovice />} />
                    <Route path="/orders/adjustmentinvoice/:voucherNo" element={<AdjustmentInvoice />} />
                    <Route path="/orders/estimateinvoice" element={<EstimateInvoice />} />
                    <Route path="/inventory" element={<Inventory />} />
                    <Route path="/inventory/:product" element={<InventoryCollections />} />
                    <Route path="/inventory/:product/:collection" element={<CollectionInfo />} />
                    <Route path="/inventory/taxrate" element={<TaxRate />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designations.includes("dispatchmanager") && (
                  <>
                    <Route path="/dashboard" element={width < 1084 ? <DispatchDasboardMob /> : <DispatchDasboard />} />
                    <Route path="/profile" element={<AddManager />} />
                    <Route path="/dispatchdashboard" element={<DispatchDasboard />} />
                    <Route path="/dispatchorders/:orderId" element={width < 1084 ? <DispatchOrder /> : <PackingVoucher />} />
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <Newviewdispatchdetails />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designations.includes("paymentmanager") && (
                  <>
                    <Route path="/dashboard" element={width < 1084 ? <ReciptsMob /> : <Recipts />} />
                    <Route path="/profile" element={<AddManager />} />
                    <Route path="/recipts" element={width < 1084 ? <ReciptsMob /> : <Recipts />} />
                    <Route path="/orders/order/:route" element={<Order />} />
                    <Route path="/viewdispatchdetails/:orderId" element={width < 1084 ? <ViewDispatchDetailsMob /> : <ViewDispatchDetails />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designations.includes("packingmanager") && (
                  <>
                    <Route path="/dashboard" element={<PackingOrders />} />
                    <Route path="/profile" element={<AddManager />} />
                    <Route path="/packingorder" element={<PackingOrders />} />
                    <Route path="/packingorder/:orderId" element={<PackingOrder />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designations.includes("invoicemanager") && (
                  <>
                    <Route path="/dashboard" element={<Invoices />} />
                    <Route path="/profile" element={<AddManager />} />
                    <Route path="/invoices" element={<Invoices />} />
                    <Route path="/invoices/invoice" element={<SaleInvoice />} />
                    <Route path="/invoices/uploadedinvoice" element={<UploadInvoice />} />
                    <Route path="/orders/order/saleorder" element={<Order />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
                {designations.includes("marketing") && (
                  <>
                    <Route path="/dashboard" element={<MarketingDashboard />} />
                    <Route path="/profile" element={<AddManager />} />
                    <Route path="/marketing/contacts" element={<Contacts />} />
                    <Route path="/marketing/leads" element={<Leads />} />
                    <Route path="/marketing-lead/:id" element={<MarketingLead />} />
                    <Route path="/orders/order/saleorder" element={<Order />} />
                    <Route path="*" element={<NoMatch />} />
                  </>
                )}
              </Route>
            </Routes>
          </div>
        )}
      </Suspense>
    </div>
  );
}

export default App;
