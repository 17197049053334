import { createContext, useContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  //   const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        // //console.log("Notification permission:", permission);
      });
    }
  }, []);

  const addNotification = (message, type = "info") => {
    // //console.log("Adding in-app notification:", message);

    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, type }]);

    setTimeout(() => removeNotification(id), 3000);
  };

  const sendBrowserNotification = (title, body, icon) => {
    // //console.log("Trying to send browser notification:", title, body);

    if (Notification.permission === "granted") {
      // //console.log("Browser notification permission granted. Sending...");

      new Notification(title, {
        body,
        icon,
      });
    } else {
      // console.warn("Browser notification permission denied.");
    }
  };

  //   const sendBrowserNotification = (title, body, icon, navigateTo) => {
  //     //console.log("Trying to send browser notification:", title, body);
  //     if (Notification.permission === "granted") {
  //       const notification = new Notification(title, {
  //         body,
  //         icon,
  //       });

  //       notification.onclick = () => {
  //         //console.log("Notification clicked!");

  //         window.focus();
  //         // window.location.href = "/";
  //         navigate(navigateTo);
  //       };
  //     } else {
  //       console.warn("Notification permission denied.");
  //     }
  //   };

  const removeNotification = (id) => {
    // //console.log("Removing in-app notification:", id);

    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  // //console.log("NOTIFICATIONS ", notifications);

  return (
    <NotificationContext.Provider
      value={{ addNotification, sendBrowserNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
