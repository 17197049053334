import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Pagination.module.css";

const Pagination = (props) => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const [searchparams] = useSearchParams();
  const product_id = searchparams.get("product_id");
  const collectionId = searchparams.get("collection_id");

  const handlePagination = (current) => {
    if (url.split("/").length === 3) {
      navigate(url + "?product_id=" + product_id + "&page=" + current);
    } else if (url.split("/").length === 4) {
      navigate(
        url +
          "?product_id=" +
          product_id +
          "&collection_id=" +
          collectionId +
          "&page=" +
          current
      );
    } else {
      navigate(url + "?page=" + current);
    }
  };
  const [gotoPage, setGotoPage] = useState("");

  // //console.log("gotoPage",gotoPage);
  // //console.log("url", url.split('/'));

  return (
    <div className={styles.pagination_Main}>
      <div
        className={styles.goToPage_div}
        style={{
          color: `black`,
        }}
      >
        Page &nbsp;&nbsp;
        <span>
          <input
            type="number"
            placeholder="01"
            className={styles.page_input}
            value={gotoPage}
            onChange={(e) => {
              if (parseInt(props.total) >= parseInt(e.target.value)) {
                setGotoPage(e.target.value);
              }
            }}
          />
          &nbsp;&nbsp;
          <button
            className={styles.page_btn}
            onClick={() => {
              handlePagination(gotoPage);
            }}
          >
            {" "}
            of 10
          </button>
        </span>
      </div>
      <div className={styles.pagination_container}>
        <button
          className={
            props.current === 1
              ? styles.paginationDisable_btn
              : styles.pagination_btn
          }
          onClick={() => handlePagination(props.current - 1)}
          disabled={props.current === 1 ? true : false}
        >
          Previous
        </button>
        {props.total < 15 ? (
          <>
            {Array.apply(0, Array(props.total)).map((arr, i) => (
              <div
                key={i}
                onClick={() => handlePagination(i + 1)}
                className={`${styles.page_item} ${
                  props.current === i + 1 ? styles.active : ""
                }`}
              >
                {i + 1}
              </div>
            ))}
          </>
        ) : props.current % 13 >= 0 &&
          props.current > 12 &&
          props.current + 10 < props.total ? (
          <>
            <div
              onClick={() => handlePagination(1)}
              className={styles.page_item}
            >
              {1}
            </div>
            <div className={styles.disableDots}>...</div>
            <div
              onClick={() => handlePagination(props.current - 5)}
              className={styles.page_item}
            >
              {props.current - 5}
            </div>
            <div
              onClick={() => handlePagination(props.current - 4)}
              className={styles.page_item}
            >
              {props.current - 4}
            </div>
            <div
              onClick={() => handlePagination(props.current - 3)}
              className={styles.page_item}
            >
              {props.current - 3}
            </div>
            <div
              onClick={() => handlePagination(props.current - 2)}
              className={styles.page_item}
            >
              {props.current - 2}
            </div>
            <div
              onClick={() => handlePagination(props.current - 1)}
              className={styles.page_item}
            >
              {props.current - 1}
            </div>
            <div
              onClick={() => handlePagination(props.current)}
              className={`${styles.page_item} ${styles.active}`}
            >
              {props.current}
            </div>
            <div
              onClick={() => handlePagination(props.current + 1)}
              className={styles.page_item}
            >
              {props.current + 1}
            </div>
            <div
              onClick={() => handlePagination(props.current + 2)}
              className={styles.page_item}
            >
              {props.current + 2}
            </div>
            <div
              onClick={() => handlePagination(props.current + 3)}
              className={styles.page_item}
            >
              {props.current + 3}
            </div>
            <div
              onClick={() => handlePagination(props.current + 4)}
              className={styles.page_item}
            >
              {props.current + 4}
            </div>
            <div
              onClick={() => handlePagination(props.current + 5)}
              className={styles.page_item}
            >
              {props.current + 5}
            </div>
            <div className={styles.disableDots}>...</div>
            <div
              onClick={() => handlePagination(props.total)}
              className={styles.page_item}
            >
              {props.total}
            </div>
          </>
        ) : props.current % 13 >= 0 &&
          props.current > 12 &&
          props.current + 10 >= props.total ? (
          <>
            <div
              onClick={() => handlePagination(1)}
              className={styles.page_item}
            >
              1
            </div>
            <div className={styles.disableDots}>...</div>
            <div
              onClick={() => handlePagination(props.total - 12)}
              className={`${styles.page_item} ${
                props.current === props.total - 12 ? styles.active : ""
              }`}
            >
              {props.total - 12}
            </div>
            <div
              onClick={() => handlePagination(props.total - 11)}
              className={`${styles.page_item} ${
                props.current === props.total - 11 ? styles.active : ""
              }`}
            >
              {props.total - 11}
            </div>
            <div
              onClick={() => handlePagination(props.total - 10)}
              className={`${styles.page_item} ${
                props.current === props.total - 10 ? styles.active : ""
              }`}
            >
              {props.total - 10}
            </div>
            <div
              onClick={() => handlePagination(props.total - 9)}
              className={`${styles.page_item} ${
                props.current === props.total - 9 ? styles.active : ""
              }`}
            >
              {props.total - 9}
            </div>
            <div
              onClick={() => handlePagination(props.total - 8)}
              className={`${styles.page_item} ${
                props.current === props.total - 8 ? styles.active : ""
              }`}
            >
              {props.total - 8}
            </div>
            <div
              onClick={() => handlePagination(props.total - 7)}
              className={`${styles.page_item} ${
                props.current === props.total - 7 ? styles.active : ""
              }`}
            >
              {props.total - 7}
            </div>
            <div
              onClick={() => handlePagination(props.total - 6)}
              className={`${styles.page_item} ${
                props.current === props.total - 6 ? styles.active : ""
              }`}
            >
              {props.total - 6}
            </div>
            <div
              onClick={() => handlePagination(props.total - 5)}
              className={`${styles.page_item} ${
                props.current === props.total - 5 ? styles.active : ""
              }`}
            >
              {props.total - 5}
            </div>
            <div
              onClick={() => handlePagination(props.total - 4)}
              className={`${styles.page_item} ${
                props.current === props.total - 4 ? styles.active : ""
              }`}
            >
              {props.total - 4}
            </div>
            <div
              onClick={() => handlePagination(props.total - 3)}
              className={`${styles.page_item} ${
                props.current === props.total - 3 ? styles.active : ""
              }`}
            >
              {props.total - 3}
            </div>
            <div
              onClick={() => handlePagination(props.total - 2)}
              className={`${styles.page_item} ${
                props.current === props.total - 2 ? styles.active : ""
              }`}
            >
              {props.total - 2}
            </div>
            <div
              onClick={() => handlePagination(props.total - 1)}
              className={`${styles.page_item} ${
                props.current === props.total - 1 ? styles.active : ""
              }`}
            >
              {props.total - 1}
            </div>
            <div
              className={`${styles.page_item} ${
                props.current === props.total ? styles.active : ""
              }`}
              onClick={() => handlePagination(props.total)}
            >
              {props.total}
            </div>
          </>
        ) : (
          <>
            {Array.apply(0, Array(13)).map((arr, i) => (
              <div
                key={i}
                className={`${styles.page_item} ${
                  props.current === i + 1 ? styles.active : ""
                }`}
                onClick={() => handlePagination(i + 1)}
              >
                {i + 1}
              </div>
            ))}
            <div className={styles.disableDots}>...</div>
            <div
              onClick={() => handlePagination(props.total)}
              className={styles.page_item}
            >
              {props.total}
            </div>
          </>
        )}
        <button
          className={
            props.current === props.total
              ? styles.paginationDisable_btn
              : styles.pagination_btn
          }
          onClick={() => handlePagination(props.current + 1)}
          disabled={props.current === props.total ? true : false}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
