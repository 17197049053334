import styles from "./Apps.module.css";
import TextElement from "../FormComponents/TextElement";
import { DownloadIcon2, NavbarArrowDownIcon } from "../../icons/Icon";
import { useRef } from "react";
import fetchData from "../../../APIsControll/apiControll";
import { useToast } from "../Toaster/Toaster";

const VersionHistory = ({
  version,
  date,
  content,
  mode,
  latest,
  apkUrl,
  isOpen,
  onToggle,
  apkSize,
}) => {
  const contentRef = useRef(null);
  const { show } = useToast();

  const [folderName, fileName] = apkUrl.split("/");

  const formatDate = (dateString) => {
    if (!dateString) return "Invalid Date";

    const isoDate = dateString.split("T")[0];

    const date = new Date(isoDate);

    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateString);
      return "Invalid Date";
    }

    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${sizes[i]}`;
  };

  const downloadApp = async () => {
    try {
      const apiData = {
        fileName: fileName,
        folderName: folderName,
      };

      const result = await fetchData("apps/downloadFile", apiData);

      if (result.status === "success") {
        show("Download Started.", "success");

        const url = result.url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (result.status === "failed") {
        show(result.msg, "error");
      } else if (result.status === "warning") {
        show(result.msg, "warning");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      show(
        "An error occurred while downloading the file. Please try again.",
        "error"
      );
    }
  };

  return (
    <div
      className={styles.version_history_accordion}
      style={{
        backgroundColor: latest
          ? mode
            ? "#2C2E33"
            : "#E4EAFF"
          : mode
          ? "#1B1D21"
          : "#F8F9FC",
      }}
    >
      <div onClick={onToggle} className={styles.accordion_header}>
        <TextElement
          label={"Version:"}
          value={version}
          labelColor={mode ? "#FFFFFF" : "#000000"}
          valueColor={mode ? "#FFFFFF" : "#000000"}
        />
        <div>
          <TextElement
            label={"Date:"}
            value={formatDate(date)}
            labelColor={mode ? "#FFFFFF" : "#000000"}
            valueColor={mode ? "#FFFFFF" : "#000000"}
          />
          <div className={`${styles.arrow_icon} ${isOpen ? styles.open : ""}`}>
            <NavbarArrowDownIcon color={mode ? "#FFFFFF" : "#000000"} />
          </div>
        </div>
      </div>

      <div
        ref={contentRef}
        className={`${styles.accordion_content} ${isOpen ? styles.show : ""}`}
        style={{
          height: isOpen ? `${contentRef.current?.scrollHeight}px` : "0px",
          opacity: isOpen ? 1 : 0,
        }}
      >
        <p style={mode ? { color: "#FFFFFF" } : {}}>{content}</p>
        <div className={styles.download_btn} onClick={downloadApp}>
          <p>{formatBytes(apkSize)}</p> <DownloadIcon2 />
        </div>
      </div>
    </div>
  );
};

export default VersionHistory;
