import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  GreenButton,
  GreenOutButton,
  RedOutButton,
  WhiteOutButton,
} from "../../components/Buttons";
import { useAuth } from "../../components/context/Auth";
import {
  CrossCircleIcon,
  DeleteIcon,
  Search,
  UpLoadFile,
} from "../../components/icons/Icon";
import Modal from "../../components/Modal";
import VouchersTab from "../../components/VouchersTab";

import LiveSearch from "../ordermanager/LiveSearch";
import styles from "../ordermanager/Order.module.css";
import CryptoJS from "crypto-js";
// import States from "./States.json"

const TransferStock = () => {
  const { managerId, switchDisplay } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const orderId = searchParams.get("orderId");
  const [error, setErrors] = useState({
    dp: "",
    index: "",
  });
  const descRef = useRef();
  const [results, setResults] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);

  const [couriersDetails, setCouriersDetails] = useState({
    name: "",
    mobile: "",
    trackingId: "",
    trackingUrl: "",
  });

  const [generalInfo, setGeneralInfo] = useState({
    vendorName: "",
    voucherNo: "",
    voucherDate: "",
    fromWarehouse: "",
    toWarehouse: "",
    shippingCharge: "",
    manageName: "",
    manageValue: "",
  });

  const qtyFocus = useRef();

  const [itemsdetails, setItemsdetails] = useState([
    {
      cartType: "",
      itemNo: "",
      price: "",
      product_name: "",
      thumbnail: "",
      totalQty: "",
      qty: "",
      unit: "",
      warehouse: "",
    },
  ]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...itemsdetails];
    list[index][name] = value;
    setItemsdetails(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...itemsdetails];
    list.splice(index, 1);
    setItemsdetails(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setSearchItem("");
    setItemsdetails([
      ...itemsdetails,
      {
        itemNo: "",
        product_name: "",
        collection_name: "",
        qty: "",
        price: "",
        unit: "",
        amount: "",
      },
    ]);
  };

  useEffect(() => {
    if (searchItem & !generalInfo.fromWarehouse) {
      setSearchItem("");
      alert("Please select from warehouse");
    } else if (searchItem && generalInfo.fromWarehouse) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        keyword: searchItem,
        warehouse_id: generalInfo.fromWarehouse,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "inventory/stockSearch?page=",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setResults(result.data);
          }
        })
        .catch((error) => {})
    }
  }, [generalInfo.cartType, generalInfo.fromWarehouse, searchItem, managerId]);

  function enforce_maxlength(e) {
    var t = e.target;
    if (t.hasAttribute("maxlength")) {
      t.value = t.value.slice(0, t.getAttribute("maxlength"));
    }
  }

  const unitRef = useRef();
  const priceRef = useRef();

  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        voucherNo: orderId,
        // "warehouse_id": generalInfo.fromWarehouse
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "inventory/stocktransfer",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            if (result.data.order) {
              setGeneralInfo((prevState) => ({
                ...prevState,
                vendorName: result.data.order.vendorName
                  ? result.data.order.vendorName
                  : "",
                voucherNo: result.data.order.voucherNo
                  ? result.data.order.voucherNo
                  : "",
                voucherDate: result.data.order.voucherDate
                  ? result.data.order.voucherDate
                  : "",
                warehouse_id: result.data.order.warehouse_id
                  ? result.data.order.warehouse_id
                  : "",
                warehouse2_id: result.data.order.warehouse_id
                  ? result.data.order.warehouse_id
                  : "",
                shippingCharge: result.data.order.shippingCharge
                  ? result.data.order.shippingCharge
                  : "",
                manageName: result.data.order.manageName
                  ? result.data.order.manageName
                  : "",
                manageValue: result.data.order.manageValue
                  ? result.data.order.manageValue
                  : "",
                status: result.data.order.status
                  ? result.data.order.status
                  : "",
              }));
              setItemsdetails(result.data.order.itemsdetails);
            }
            if (result.data.warehouses) {
              setWarehouses(result.data.warehouses);
            }
          }
        })
        .catch((error) => {})
    }
  }, [managerId, orderId]);

  const cartTypeRef = useRef(null);
  const itemseacrhRef = useRef(null);
  const paymentRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handlePuchaseOrder("New Order");
    }
  };

  // const qty = itemsdetails.map(obj => parseFloat(obj.qty)).reduce((a, b) => a + b)
  // const totalItems = itemsdetails.length

  let cartPriceSum =
    itemsdetails &&
    itemsdetails.reduce((sum, currentItem) => {
      return (
        sum +
        parseFloat(currentItem.price ? currentItem.price : 0) *
          parseFloat(currentItem.qty ? currentItem.qty : 0)
      );
    }, 0);

  var cartTotal =
    itemsdetails &&
    itemsdetails
      .map((x) =>
        !x.accessories
          ? 0
          : x.accessories
              .map(
                (y) =>
                  (y.accessoriesQty ? y.accessoriesQty : 0) * y.accessoriesPrice
              )
              .reduce((a, b) => a + b, 0)
      )
      .reduce((a, b) => a + b, 0);
  let subtotal = cartPriceSum ? cartPriceSum : 0 + cartTotal ? cartTotal : 0;
  let totalAmount =
    subtotal +
    parseFloat(generalInfo.shippingCharge ? generalInfo.shippingCharge : 0) +
    parseFloat(generalInfo.manageValue ? generalInfo.manageValue : 0);

  const handlePuchaseOrder = (status) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      voucherNo: orderId,
      fromWarehouse: generalInfo.fromWarehouse,
      toWarehouse: generalInfo.toWarehouse,
      shippingCharge: generalInfo.shippingCharge,
      manageName: generalInfo.manageName,
      manageValue: generalInfo.manageValue,
      status: status,
      itemsdetails: itemsdetails,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "inventory/stocktransfervoucher",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        alert(result.msg);
        if (result.status === "success") {
          navigate("/transferorders");
        }
      })
      .catch((error) => {})
  };

  const encryptedName = sessionStorage.getItem("name")
    ? sessionStorage.getItem("name")
    : "";
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedName, "secret key");
  const mname = decryptedBytes.toString(CryptoJS.enc.Utf8);
  // //console.log(searchItem);
  return (
    <React.Fragment>
      <div className={styles.main_div_dark}>
        <div className={styles.main_container}>
          <div className={styles.tabs_div}>
            <h2 className={styles.orderHeading}>Stock Tranfser</h2>
            <VouchersTab styles={styles} />
          </div>

          <form onSubmit={handleSubmit} className={styles.ordersScroll}>
            <div className={styles.d_flex}>
              <div className={styles.orderContent_div}>
                <div className={styles.orderInput_div}>
                  <label className={styles.order_txt}>Manager Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    className={styles.inputField}
                    value={mname}
                    required
                    readOnly
                  />
                  <span className={styles.search_icon}>
                    <Search color="#FFFFFF" />
                  </span>
                </div>

                <div className={styles.orderInput_div}>
                  <label className={styles.order_txt}>From Warehouse</label>
                  <select
                    className={styles.whereHouse_select_box}
                    value={generalInfo.fromWarehouse}
                    onChange={(e) => {
                      if (generalInfo.toWarehouse === e.target.value) {
                        alert("Please select different warehouse");
                      } else {
                        setGeneralInfo((prevState) => ({
                          ...prevState,
                          fromWarehouse: e.target.value,
                        }));
                      }
                    }}
                    required
                  >
                    <option value="">All Warehouse</option>
                    {warehouses &&
                      warehouses.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.warehousename}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className={styles.orderContent_div}>
                <div className={styles.orderInput_div}>
                  <label className={styles.order_txt}>Voucher No.</label>
                  <div className={styles.voucherDate_div}>
                    <span>
                      <b>{generalInfo.voucherNo}</b>
                    </span>
                    <span>Voucher Date</span>
                    <span>
                      <b>{generalInfo.voucherDate}</b>
                    </span>
                  </div>
                </div>

                <div className={styles.orderInput_div}>
                  <label className={styles.order_txt}>To Warehouse</label>
                  <select
                    className={styles.whereHouse_select_box}
                    value={generalInfo.toWarehouse}
                    onChange={(e) => {
                      if (generalInfo.fromWarehouse === e.target.value) {
                        alert("Please select different warehouse");
                      } else {
                        setGeneralInfo((prevState) => ({
                          ...prevState,
                          toWarehouse: e.target.value,
                        }));
                      }
                    }}
                    required
                  >
                    <option value="">All Warehouse</option>
                    {warehouses &&
                      warehouses.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.warehousename}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>

            <table className={styles.table_main}>
              <thead>
                <tr>
                  <th className={styles.table_th_Sno}>S. No</th>
                  <th className={styles.table_th}>Item Description </th>
                  <th className={styles.table_th_details}>Qty </th>
                  <th className={styles.table_th_details}>Per</th>
                  <th className={styles.table_th_details}>Rate</th>
                  <th className={styles.table_th_details}>Amount</th>
                </tr>
              </thead>
              {itemsdetails &&
                itemsdetails.map((x, index) => (
                  <tbody key={index}>
                    <tr
                      onKeyDown={(e) => {
                        // //console.log(e.keyCode)
                        if (e.ctrlKey && e.keyCode === 88) {
                          handleRemoveClick(index + 1);
                          paymentRef.current.focus();
                        }
                      }}
                    >
                      <td className={styles.table_th_Sno}>{index + 1}</td>
                      <td className={styles.table_th + " " + styles.w_50}>
                        <div className={styles.table_th_div1}>
                          <LiveSearch
                            results={results}
                            itemsdetails={itemsdetails}
                            cartType={generalInfo.cartType}
                            value={x.itemNo ? x.itemNo : searchItem}
                            renderItem={(item) => <>{item.name}</>}
                            onChange={(e) => setSearchItem(e.target.value)}
                            setSearchItem={setSearchItem}
                            itemseacrhRef={itemseacrhRef}
                            cartTypeRef={cartTypeRef}
                            handleRemoveClick={() =>
                              handleRemoveClick(index + 1)
                            }
                            removeIndex={index + 1}
                            // setShowAddItem={setShowAddItem}
                            onSelect={(item) => {
                              if (!generalInfo.fromWarehouse) {
                                alert("Please select from warehouse");
                              } else {
                                setItemsdetails([
                                  ...itemsdetails.slice(0, index),
                                  item,
                                  ...itemsdetails.slice(index + 1),
                                ]);
                              }

                              // if (item.accessories.length > 0) {
                              //     setItemsdetails([
                              //         ...itemsdetails.slice(0, index),
                              //         {
                              //             itemNo: item.itemNo,
                              //             product_name: item.product_name,
                              //             qty: "",
                              //             unit: item.unit,
                              //             price: item.price,
                              //         },
                              //         ...itemsdetails.slice(index + 1),

                              //         ...item.accessories.map((xj, ix) =>
                              //         ({
                              //             itemNo: xj.accessoriesName,
                              //             qty: parseInt(xj.accessoriesQty),
                              //             price: parseFloat(xj.accessoriesPrice),
                              //         })
                              //         )
                              //     ],

                              //     )
                              //     // setItemsdetails(
                              //     //     ...itemsdetails.slice(0, index),

                              //     //     item.accessories.map((xj, ix) =>

                              //     //     ({
                              //     //         itemNo: xj.accessoriesName,
                              //     //         qty: parseInt(xj.accessoriesQty),
                              //     //         price: parseFloat(xj.accessoriesPrice),
                              //     //     })
                              //     //     ),
                              //     //     ...itemsdetails.slice(index + 2)
                              //     // )
                              // }
                              // // else {
                              // //     itemsArr.push({
                              // //         itemNo: item.itemNo,
                              // //         product_name: item.product_name,
                              // //         qty: item.qty,
                              // //         unit: item.unit,
                              // //         price: item.price,
                              // //     })
                              // // }
                            }}
                            qtyFocus={qtyFocus}
                          />
                          {x.product_name}{" "}
                          <span className={styles.ms_5}>{x.warehouse}</span>
                        </div>
                        {x.accessories &&
                          x.accessories.length > 0 &&
                          x.accessories.map((item, idx) => (
                            <div key={idx} className={styles.table_th_div1}>
                              <input
                                type="text"
                                defaultValue={item.accessoriesName}
                                readOnly
                                className={styles.acc_div_input}
                              />
                              <input
                                type="number"
                                defaultValue={item.accessoriesPrice}
                                readOnly
                                className={styles.acc_div_input}
                              />
                              <input
                                type="number"
                                placeholder="Enter Qty"
                                className={styles.acc_div_input}
                                value={item.accessoriesQty}
                                onChange={(e) => {
                                  setItemsdetails([
                                    ...itemsdetails.slice(0, index),
                                    {
                                      ...itemsdetails[index],
                                      accessories: [
                                        ...itemsdetails[
                                          index
                                        ].accessories.slice(0, idx),
                                        {
                                          ...itemsdetails[index].accessories[
                                            idx
                                          ],
                                          accessoriesQty: e.target.value,
                                        },
                                        ...itemsdetails[
                                          index
                                        ].accessories.slice(idx + 1),
                                      ],
                                    },
                                    ...itemsdetails.slice(index + 1),
                                  ]);
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    unitRef.current.focus();
                                  }
                                }}
                              />
                              <input
                                type="number"
                                readOnly
                                className={styles.acc_div_input}
                                value={
                                  item.accessoriesPrice *
                                  parseInt(item.accessoriesQty)
                                }
                              />
                            </div>
                          ))}
                        {/* <div className={styles.item_div}>
                                                    <span>U-Clip</span>
                                                    <input
                                                        type="number"
                                                        defaultValue={20}
                                                        disabled
                                                    />
                                                    <input
                                                        type="number"
                                                        placeholder='Enter Qty'
                                                    />
                                                    <input
                                                        type="number"

                                                        disabled
                                                    />
                                                </div> */}
                      </td>
                      <td className={styles.table_th_details}>
                        <input
                          type="number"
                          ref={qtyFocus}
                          placeholder="Enter Qty"
                          name="qty"
                          value={x.qty}
                          onChange={(e) => {
                            if (!x.cartType) {
                              enforce_maxlength(e, index);
                              handleInputChange(e, index);
                              const list = [...itemsdetails];
                              list[index]["amount"] = x.qty * x.price;
                              setItemsdetails(list);
                            } else if (e.target.value <= x.totalQty) {
                              enforce_maxlength(e, index);
                              handleInputChange(e, index);
                              const list = [...itemsdetails];
                              list[index]["amount"] = x.qty * x.price;
                              setItemsdetails(list);
                            }
                          }}
                          // onKeyPress={(e) => { e.key === 'Enter' return (unitRef.current.focus() && e.preventDefault()) }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              unitRef.current.focus();
                            }
                          }}
                          required
                          className={styles.inputFocus}
                        />
                      </td>
                      <td className={styles.table_th_details}>
                        <select
                          name="unit"
                          value={x.unit}
                          onChange={(e) => {
                            handleInputChange(e, index);
                          }}
                          ref={unitRef}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              priceRef.current.focus();
                            }
                          }}
                          required
                          className={styles.inputFocus}
                        >
                          <option value="" hidden>
                            Select
                          </option>
                          <option value="Roll">Roll</option>
                          <option value="Pcs">Pcs</option>
                          <option value="Box">Box</option>
                          <option value="Sq Meter">Sq Meter</option>
                          <option value="Meter">Meter</option>
                          <option value="Sq Feet">Sq Feet</option>
                          <option value="Feet">Feet</option>
                          <option value="Kg">Kg</option>
                        </select>
                      </td>
                      <td className={styles.table_th_details}>
                        <input
                          type="number"
                          value={x.price}
                          // readOnly={x.price ? true : false}
                          name="price"
                          onChange={(e) => {
                            if (
                              x.dp >
                              parseInt(e.target.value ? e.target.value : 0)
                            ) {
                              setErrors((prevState) => ({
                                ...prevState,
                                dp: "Please Enter Price Above " + x.dp,
                                index: index,
                              }));
                            } else {
                              setErrors((prevState) => ({
                                ...prevState,
                                dp: "",
                                index: "",
                              }));
                            }

                            handleInputChange(e, index);
                          }}
                          ref={priceRef}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              const list = [...itemsdetails];
                              list[index]["descKey"] = index;
                              setItemsdetails(list);
                              descRef.current.focus();
                            }
                          }}
                          className={styles.inputFocus}
                        />
                        {error.index === index && (
                          <div className="fs_10 text_danger">{error.dp}</div>
                        )}
                      </td>
                      <td className={styles.table_th_details}>
                        <input
                          type="number"
                          value={(x.qty * x.price).toFixed(2)}
                          disabled
                          readOnly
                          className={styles.inputFocus}
                        />
                      </td>
                      <td
                        className={styles.crossIcon}
                        onClick={() => handleRemoveClick(index)}
                      >
                        <CrossCircleIcon />
                      </td>
                    </tr>
                    {(index === x.descKey || x.desc) && (
                      <tr>
                        <td colSpan={6} className={styles.table_th_details}>
                          <input
                            type={"text"}
                            className={styles.inputFocus}
                            style={{ height: "30px", textAlign: "left" }}
                            ref={descRef}
                            name="desc"
                            autoFocus
                            value={x.desc}
                            onChange={(e) => handleInputChange(e, index)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                if (!x.desc) {
                                  const list = [...itemsdetails];
                                  list[index]["descKey"] = "";
                                  setItemsdetails(list);
                                }
                                e.preventDefault();
                                handleAddClick();
                              }
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                ))}
            </table>

            <div className="text_center">
              <WhiteOutButton
                btnType="button"
                handleSubmit={() => handleAddClick()}
                title="Add More Item"
              />
            </div>

            <div>
              <div className={styles.d_flex}>
                <div className={styles.paymentMethod_div}>
                  <div className={styles.transfer_div}>
                    <div className={styles.transferInputDIv}>
                      <span className={styles.payment}>Name</span>
                      <input
                        type="text"
                        className={styles.transferInput}
                        value={couriersDetails.name}
                        onChange={(e) =>
                          setCouriersDetails({
                            ...couriersDetails,
                            name: e.target.value
                              .toLocaleLowerCase()
                              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                letter.toUpperCase()
                              ),
                          })
                        }
                        disabled
                      />
                    </div>
                    <div className={styles.transferInputDIv}>
                      <span className={styles.payment}>Mobile</span>
                      <input
                        type="number"
                        className={styles.transferInput}
                        value={couriersDetails.mobile}
                        onChange={(e) =>
                          setCouriersDetails({
                            ...couriersDetails,
                            mobile: e.target.value,
                          })
                        }
                        maxLength="10"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        disabled
                      />
                    </div>
                    <div className={styles.transferInputDIv}>
                      <span className={styles.payment}>Tracking ID</span>
                      <input
                        type="text"
                        className={styles.transferInput}
                        value={couriersDetails.trackingId}
                        onChange={(e) =>
                          setCouriersDetails({
                            ...couriersDetails,
                            trackingId: e.target.value,
                          })
                        }
                        disabled
                      />
                    </div>
                    <div className={styles.transferInputDIv}>
                      <span className={styles.payment}>Tracking URL</span>
                      <input
                        type="text"
                        className={styles.transferInput}
                        value={couriersDetails.trackingUrl}
                        onChange={(e) =>
                          setCouriersDetails({
                            ...couriersDetails,
                            trackingUrl: e.target.value,
                          })
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.subTotal_flex_col}>
                  <div className={styles.subTotal_div}>
                    <span>Sub Total</span>
                    <span>
                      {subtotal.toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </span>
                  </div>
                  <div className={styles.subTotal_div}>
                    <span>Shipping Charges</span>
                    <input
                      type="number"
                      className={styles.shipingCharges_input}
                      value={generalInfo.shippingCharge}
                      onChange={(e) =>
                        setGeneralInfo((prevState) => ({
                          ...prevState,
                          shippingCharge: e.target.value,
                        }))
                      }
                      maxLength="10"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                    />
                    <span className={styles.fixSize_input}>
                      {generalInfo.shippingCharge
                        ? generalInfo.shippingCharge
                        : 0}
                    </span>
                  </div>
                  <div className={styles.subTotal_div}>
                    <input
                      type="text"
                      className={styles.adjustment}
                      value={generalInfo.manageName}
                      placeholder="Enter Manage"
                      onChange={(e) =>
                        setGeneralInfo((prevState) => ({
                          ...prevState,
                          manageName: e.target.value,
                        }))
                      }
                    />
                    <input
                      type="number"
                      className={styles.shipingCharges_input}
                      value={generalInfo.manageValue}
                      onChange={(e) =>
                        setGeneralInfo((prevState) => ({
                          ...prevState,
                          manageValue: e.target.value,
                        }))
                      }
                      maxLength="10"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                    />
                    <span className={styles.subTotal_txtRed}>
                      {generalInfo.manageValue ? generalInfo.manageValue : 0}
                    </span>
                  </div>
                  <div className={styles.totalINR_div}>
                    <span>Total (INR)</span>
                    <span>
                      {totalAmount.toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.orders_btn_div}>
                <RedOutButton
                  btnType="button"
                  title="CANCEL"
                  css={styles.calcel_btn}
                  handleSubmit={() => window.history.back()}
                />
                {!orderId && (
                  <GreenOutButton
                    btnType="button"
                    title="SAVE AS DRAFT"
                    handleSubmit={() => handlePuchaseOrder("draft")}
                  />
                )}
                {generalInfo.status === "In Progress" &&
                  generalInfo.managerId !== managerId && (
                    <GreenOutButton
                      btnType="button"
                      title="DELIVERY DETAILS"
                      handleSubmit={() => setShowDeliveryModal(true)}
                    />
                  )}
                {generalInfo.status && generalInfo.status !== "Draft" ? (
                  <GreenButton
                    btnType="button"
                    title="VIEW INOVICE"
                    // handleSubmit={() => navigate("/orders/purchaseinvoice/" + orderId)}
                  />
                ) : (
                  <GreenButton btnType="submit" title="SAVE & SEND" />
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal
        show={showDeliveryModal}
        close={setShowDeliveryModal}
        closeBtn={true}
        heading="DELIVERY DETAILS"
        content={
          <div className={styles.text_center}>
            <div>
              <div className={styles.modal_div}>
                <input
                  name="name"
                  type="text"
                  placeholder="Name"
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  // value={printLabel.delPerName}
                  // onChange={(e) => {
                  //     setPrintLabel(prevState => ({
                  //         ...prevState,
                  //         delPerName: e.target.value
                  //     }))
                  // }}
                />
                <input
                  name="mobile"
                  type="number"
                  placeholder="Mobile Number"
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  // value={printLabel.delPerMobile}
                  // onChange={(e) => {
                  //     setPrintLabel(prevState => ({
                  //         ...prevState,
                  //         delPerMobile: e.target.value
                  //     }))
                  // }}
                />
              </div>
              <div className={styles.modal_div}>
                <input
                  name="name"
                  type="text"
                  placeholder="Tracking Number"
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  // value={printLabel.delTrackingNo}
                  // onChange={(e) => {
                  //     setPrintLabel(prevState => ({
                  //         ...prevState,
                  //         delTrackingNo: e.target.value
                  //     }))
                  // }}
                />
                <input
                  name="name"
                  type="text"
                  placeholder="Tracking URL"
                  className={`${
                    switchDisplay ? styles.inputcolor : styles.input
                  }`}
                  // value={printLabel.delTrackingUrl}
                  // onChange={(e) => {
                  //     setPrintLabel(prevState => ({
                  //         ...prevState,
                  //         delTrackingUrl: e.target.value
                  //     }))
                  // }}
                />
              </div>

              <div className={styles.uploadImage_div}>
                <div className={styles.demoImage_div}>
                  <img
                    src={
                      // courierSlip.name ? URL.createObjectURL(courierSlip) :
                      // courierSlip ? process.env.REACT_APP_S3URL + courierSlip :
                      "/assets/packingImage.png"
                    }
                    alt=""
                    className={styles.modalItem_Image}
                  />
                </div>
                <label className={styles.uploadImage_btn}>
                  <input
                    name=""
                    type="file"
                    hidden
                    // onChange={(e) => {
                    //     setCourierSlip(e.target.files[0])
                    // }}
                  />
                  <UpLoadFile />
                  &nbsp;&nbsp;Upload Courier Slip
                </label>
                <div
                  className={styles.delete_div}
                  //  onClick={() => { setCourierSlip("") }}
                >
                  <span>
                    <DeleteIcon />
                  </span>
                  <span>Delete</span>
                </div>
              </div>

              <div className={styles.modalBtn_div}>
                <RedOutButton
                  title={"CANCEL"}
                  handleSubmit={() => {
                    setShowDeliveryModal(false);
                  }}
                />
                <GreenOutButton
                  title={"DISPATCHED"}
                  //  handleSubmit={() => { handleDispatched() }}
                />
              </div>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
};

export default TransferStock;
