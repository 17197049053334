import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Apps.module.css";
import TopTabBar from "../Tanolet/TopTabBar";
import Button from "../Dealers/Button";
import { EditManagerIcon, LinkIcon } from "../../icons/Icon";
import { useAuth } from "../../context/Auth";
import ImageSlider from "./ImageSlider";
import VersionHistory from "./VersionHistory";
import ReviewCard from "./ReviewCard";
import { useEffect, useState } from "react";
import fetchData from "../../../APIsControll/apiControll";

const ViewApp = () => {
  const { mode, managerId } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);
  const [appData, setAppData] = useState({});

  const app_name = location.state?.name;

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const formatAppName = (name) => name.toLowerCase().replace(/\s+/g, "-");

  useEffect(() => {
    const getApp = async () => {
      const apiData = { managerId: managerId, name: app_name };
      try {
        const result = await fetchData("apps/getApp", apiData);
        setAppData(result.app);
      } catch (error) {}
    };
    getApp();
  }, [app_name, managerId]);

  const sections = appData?.desc?.split(/\r?\n\r?\n/);

  const features = sections?.map((section) => {
    const [title, ...descriptionParts] = section.split(": ");
    return {
      title: title.trim(),
      description: descriptionParts.join(": ").trim(),
    };
  });

  const bg_col =
    appData?.visibility === "public"
      ? "#31974D"
      : appData?.visibility === "unlisted"
      ? "#F93E3E"
      : "#4164E3";

  const ratings = [1, 2, 3, 4, 5, 4.5];
  const review =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";

  return (
    <div
      className={styles.apps}
      style={mode ? { backgroundColor: "#2C2E33", borderColor: "#2C2E33" } : {}}
    >
      <div className={styles.apps_top}>
        <TopTabBar truncate={false} text={"Apps / " + app_name} />
        <div>
          <Button
            buttonText={"Edit"}
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#FFFFFF"}
            buttonIcon={<EditManagerIcon />}
            buttonClick={() => {
              navigate(`/apps/edit?app_name=${formatAppName(app_name)}`, {
                state: { app_name },
              });
            }}
          />
        </div>
      </div>

      <div
        className={styles.v_app_content}
        style={mode ? { backgroundColor: "#232529" } : {}}
      >
        <div className={styles.apps_app_left}>
          <div
            className={styles.img_div}
            style={
              mode
                ? {
                    backgroundColor: "#1B1D21",
                    borderColor: "#1B1D21",
                  }
                : {}
            }
          >
            <img
              src={`${process.env.REACT_APP_S3URL}${appData?.logo}`}
              alt="app_logo"
            />
          </div>
          <div className={styles.text_div}>
            <p style={mode ? { color: "#FFFFFF" } : {}}>{appData?.name}</p>
            <p style={mode ? { color: "#FFFFFF" } : {}}>{appData?.shortDesc}</p>
            <a
              href={appData?.websiteUrl}
              target="blank"
              style={mode ? { color: "#7698FF" } : {}}
            >
              <span>
                <LinkIcon color={mode ? "#7698FF" : "#0100E4"} />
              </span>
              {appData?.websiteUrl}
            </a>
            <div style={mode ? { color: "#FFFFFF" } : {}}>
              <p>Version:</p>{" "}
              <span style={mode ? { backgroundColor: "#1B1D21 " } : {}}>
                Latest {appData?.version}
              </span>{" "}
              <span
                style={{
                  backgroundColor: bg_col,
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }}
              >
                {appData?.visibility}
              </span>
            </div>
          </div>
        </div>

        <ImageSlider
          images={appData?.images}
          autoSlideInterval={3000}
          width={"100%"}
        />

        <div className={styles.v_app_content_3}>
          <div
            style={mode ? { backgroundColor: "#1B1D21", color: "#FFFFFF" } : {}}
          >
            <p>Description</p>
            <ul>
              {features?.map((feature, index) => (
                <li key={index}>
                  <span>
                    {feature.title} {feature.description !== "" && "-"}{" "}
                  </span>{" "}
                  {feature.description}
                </li>
              ))}
            </ul>
          </div>

          <div
            style={mode ? { backgroundColor: "#1B1D21", color: "#FFFFFF" } : {}}
          >
            <p>Reviews</p>
            <p>Total Reviews</p>
            <p style={mode ? { color: "#FFFFFF" } : {}}>10.2K</p>
            <p style={mode ? { color: "#FFFFFF" } : {}}>
              Growth in reviews this year.
            </p>
          </div>
        </div>

        <div className={styles.v_app_content_4}>
          <div className={styles.version_history}>
            <p style={mode ? { color: "#FFFFFF" } : {}}>Version History</p>
            {appData?.versionHistory?.map((data, index) => (
              <VersionHistory
                mode={mode}
                key={index}
                version={data.version}
                date={data.releaseDate}
                content={data.changelog}
                apkUrl={data.apkUrl}
                apkSize={data.apkSize}
                latest={data.version === appData.version}
                isOpen={openIndex === index}
                onToggle={() => handleToggle(index)}
              />
            ))}
          </div>

          <div className={styles.review_section}>
            {ratings.map((_, index) => (
              <ReviewCard
                mode={mode}
                key={index}
                ratingCount={_}
                review={review}
                name={"Rakesh Kumar"}
                store={"RK interior"}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewApp;
