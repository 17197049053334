import { useAuth } from "../../context/Auth";
import { AddIcon2 } from "../../icons/Icon";
import Button from "../Dealers/Button";
import TopTabBar from "../Tanolet/TopTabBar";
import styles from "./Apps.module.css";
import SearchBar from "../FormComponents/SearchBar";
import { useNavigate } from "react-router-dom";
import fetchData from "../../../APIsControll/apiControll";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useToast } from "../Toaster/Toaster";
import AppCard from "./AppCard";
import NoDataFound from "../NoDataFound/NoDataFound";
import { TemplateNotFound } from "../../icons/Icon2";
import Skeleton from "../Skeleton/Skeleton";

const Apps = () => {
  const { mode, managerId } = useAuth();
  const { show } = useToast();
  const navigate = useNavigate();
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState();
  const [keyword, setKeyword] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(keyword);

  const getApps = useCallback(async () => {
    if (!managerId) return;

    setLoading(true);

    const apiData = {
      managerId: managerId,
      searchKeyword: debouncedSearch,
    };

    try {
      const response = await fetchData("apps/getApps", apiData);
      if (response.status === "success") {
        setApps(response.apps);
      }
      if (response.status === "failed") {
        show(response.msg, "error");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerId, debouncedSearch]);

  useLayoutEffect(() => {
    if (!managerId) return;
    getApps();
  }, [getApps, managerId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(keyword);
    }, 700);

    return () => clearTimeout(handler);
  }, [keyword]);

  return (
    <div
      className={styles.apps}
      style={mode ? { backgroundColor: "#2C2E33", borderColor: "#2C2E33" } : {}}
    >
      <div className={styles.apps_top}>
        <TopTabBar text={"Apps"} />
        <div>
          <Button
            buttonText={"Add New"}
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#FFFFFF"}
            buttonIcon={<AddIcon2 />}
            buttonClick={() => navigate("/apps/create")}
          />
        </div>
      </div>

      <div className={styles.search_bar}>
        <SearchBar
          padding={"6px"}
          fontSize={"12px"}
          fontWeight={"400"}
          borderRadius={"5px"}
          placeholder={"Search"}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>

      <div
        className={styles.apps_body}
        style={{
          justifyContent: apps.length === 0 && !loading && "center",
        }}
      >
        <>
          {loading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <div style={{ width: "100%" }} key={index}>
                <Skeleton
                  lines={1}
                  thickness={30}
                  additionalStyles={{
                    marginBottom: "0px",
                    marginTop: "0px",
                    borderRadius: "15px",
                  }}
                />
              </div>
            ))
          ) : apps.length === 0 && !loading ? (
            <div>
              <NoDataFound
                icon={<TemplateNotFound />}
                textTitle={`No Data Found`}
              />
            </div>
          ) : (
            apps.map((a, index) => (
              <div style={{ width: "100%" }} key={index}>
                <AppCard
                  mode={mode}
                  a={a}
                  images={a.images}
                  logo={a.logo}
                  name={a.name}
                  shortDesc={a.shortDesc}
                  websiteUrl={a.websiteUrl}
                  latest_ver={a.version}
                  visibility={a.visibility}
                />
              </div>
            ))
          )}
        </>
      </div>
    </div>
  );
};

export default Apps;
