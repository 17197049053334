import styles from "./FromComponent.module.css";
import { useAuth } from "../../context/Auth";
import { NavbarSearchIcon } from "../../icons/Icon";
const SearchBar = ({
  onChange,
  value,
  placeholder,
  type,
  maxLength,
  padding,
  borderRadius,
  style,
  fontSize,
  // fontWeight,
}) => {
  const { mode } = useAuth();

  return (
    <div
      className={style ? style : styles.navbar_Search_Bar}
      style={{
        padding: padding ? padding : "10px 20px",
        backgroundColor: mode && "#1b1d21",
        borderColor: mode && "#1b1d21",
        borderRadius: borderRadius,
      }}
    >
      <NavbarSearchIcon color={mode ? "#ffffff" : "#646B88"} />
      <input
        type={type ? type : "text"}
        placeholder={placeholder}
        className={
          mode ? styles.search_Bar_Input_Dark : styles.search_Bar_Input
        }
        onChange={onChange}
        value={value}
        // maxLength={Number(maxLength)}
        maxLength={maxLength}
        style={{
          fontSize: fontSize && fontSize,
          "--placeholder-font-weight": "300",
        }}
      />
    </div>
  );
};

export default SearchBar;
