import React, { useEffect, useState } from "react";
import { useAuth } from "../../components/context/Auth";
import styles from "../dispatchmanager/DishpatchManager.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../components/Paginantion";
import OrdersTab from "../../components/OrdersTab";

const TransferOrders = () => {
  const navigate = useNavigate();

  const [searchparams] = useSearchParams();
  const page = searchparams.get("page")
    ? parseInt(searchparams.get("page"))
    : 1;
  const { switchDisplay, managerId } = useAuth();
  const [orders, setOrders] = useState("");
  const [allData, setAllData] = useState([]);
  // const [orderData, setOrderData] = useState({})
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  // const [orderType, setOrderType] = useState('')
  const [keyword, setKeyword] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseName, setWarehouseName] = useState("");
  const [toWarehouse, setToWarehouse] = useState("");
  const [managers, setManagers] = useState([]);
  const [managerName, setManagerName] = useState("");
  const [daysTab, setDaysTab] = useState("today");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (managerId) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: parseInt(managerId),
        keyword: keyword,
        orderStatus: orderStatus,
        sortKey: sortKey,
        fromwarehouse: warehouseName,
        towarehouse: toWarehouse,
        inventorymanagerId: managerName,
        day: daysTab,
        startDate: startDate,
        endDate: endDate,
        // "orderType": orderType,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL +
          "inventory/stocktransfers?page=" +
          page +
          "&limit=8",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setLoading(false);
            setPages(result.pages);
            setAllData(result.data);
            setOrders(result);
            if (result.warehouses) {
              setWarehouses(result.warehouses);
            }
            if (result.managers) {
              setManagers(result.managers);
            }
          }
        })
        .catch((error) => {})
    }
  }, [
    managerId,
    keyword,
    orderStatus,
    sortKey,
    warehouseName,
    managerName,
    page,
    daysTab,
    endDate,
    startDate,
    toWarehouse,
    // orderType,
  ]);

  return (
    <React.Fragment>
      <div
        className={`${!switchDisplay ? styles.bg_dark : styles.bg_white} ${
          styles.ff
        }`}
      >
        <OrdersTab
          styles={styles}
          keyword={keyword}
          setKeyword={setKeyword}
          setOrderStatus={setOrderStatus}
          orderStatus={orderStatus}
          sortKey={sortKey}
          setSortKey={setSortKey}
          warehouses={warehouses}
          warehouseName={warehouseName}
          setWarehouseName={setWarehouseName}
          managers={managers}
          setManagers={setManagers}
          managerName={managerName}
          setManagerName={setManagerName}
          daysTab={daysTab}
          setDaysTab={setDaysTab}
          endDate={endDate}
          setEndDate={setEndDate}
          startDate={startDate}
          setStartDate={setStartDate}
          loading={loading}
          orders={orders}
          toWarehouse={toWarehouse}
          setToWarehouse={setToWarehouse}
          // orderType={orderType}
          // setOrderType={setOrderType}
        />
        {loading ? (
          <div className={"order_loadingMain"}>
            <img src="/wallicon.gif" alt="walliconGIF" />
          </div>
        ) : (
          <>
            <div className={styles.order_heightFix_div}>
              <table className={styles.itemTable}>
                <thead>
                  <tr className={styles.item}>
                    <th>Voucher No</th>
                    <th>From Warehouse</th>
                    <th>To Warehouse</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Items</th>
                  </tr>
                </thead>

                <tbody>
                  {allData &&
                    allData.map((val, index) => (
                      <tr
                        key={index}
                        className={styles.item}
                        onClick={() => {
                          navigate("/orders/transfer?orderId=" + val.voucherNo);
                        }}
                      >
                        <td>{val.voucherNo} </td>
                        <td>{val.fromWarehouse} </td>
                        <td>{val.toWarehouse} </td>
                        <td>{val.voucherDate} </td>
                        <td>{val.totalAmount} </td>
                        <td
                          className={`${
                            val.status === "draft"
                              ? styles.table_txt_yellow
                              : styles.table_txt_green
                          }`}
                        >
                          {val.status}{" "}
                        </td>
                        <td>{val.totalItems} </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <Pagination total={pages} current={page} />
          </>
        )}
      </div>
    </React.Fragment>
  );
};
export default TransferOrders;
