import React, { useState, useEffect, useRef, Suspense, lazy } from 'react'
import styles from './Templates.module.css'
import { useNavigate, } from 'react-router-dom'
import { useAuth } from '../../components/context/Auth'
// import io from 'socket.io-client';
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import { CreateTem, Refresh, TemplateIcon, } from '../../components/icons/Icon2'
import Button from '../../components/updatedComponents/Dealers/Button'
import InputTypedate from '../../components/updatedComponents/FormComponents/InputTypedate'
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage';
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation"
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import fetchData from '../../APIsControll/apiControll';
import { useToast } from '../../components/updatedComponents/Toaster/Toaster';
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton';
import useWindowWidth from '../../components/updatedComponents/Hooks/WidthSize';
import { socket } from '../../Socket/socket';
const TampletTable = lazy(() => import("../../components/updatedComponents/Tanolet/Table/TampletTable"));


// const SOCKET_SERVER_URL = process.env.REACT_APP_URL;

const Templates = () => {
    const { mode } = useAuth();
    const { show } = useToast()
    const navigate = useNavigate();
    const { managerId } = useAuth()
    const width = useWindowWidth()
    const [showMenu, setShowMenu] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [categoryFilter, setcategoryFilter] = useState("")
    const [attachmentTypeFilter, setattachmentTypeFilter] = useState("")
    const [statusFilter, setstatusFilter] = useState("")
    const markReadRef = useRef(null);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState({
        search: '',
        From: '',
        To: '',

    })
    const HandealRefresh = () => {
        setPage(1)
        setFilter({
            search: '',
            From: '',
            To: '',
        });
        setcategoryFilter("")
        setattachmentTypeFilter("")
        setstatusFilter("")
    }

    const getAlltemplate = async () => {
        setLoading(true)
        if (filter?.search || filter?.From || filter?.To || categoryFilter || attachmentTypeFilter || statusFilter !== "") {
            setPage(1)
        }
        const response = await fetchData(`wallikonChat/templateList?page=${page}`, {
            "managerId": managerId,
            "keyword": filter?.search.replace(/ /g, '_').toLowerCase() || '',
            "startDate": filter?.From,
            "endDate": filter?.To,
            "category": categoryFilter,
            "headerType": attachmentTypeFilter,
            "templateSent": statusFilter,
        })
        if (response.status === 'success') {
            // setData(response?.data);
            setData((prevDealerData) => {
                let newData = response?.data;
                if (filter.search || filter.From || filter.To || attachmentTypeFilter || statusFilter) {
                    return newData;
                }
                const mergedData = [...prevDealerData, ...newData];
                const uniqueData = mergedData.filter(
                    (item, index, self) => index === self.findIndex((t) => t._id === item._id)
                );
                setLoading(false);
                return uniqueData;
            });
            setPages(response.pages);
            setLoading(false)
            return
        }
        if (response.status === 'failed') {
            setData(response?.data || []);
            setPages(response.pages || 1);
            setLoading(false)
            return
        }
        else {
            show("Error", "error");
            setLoading(false)
            return
        }
    }


    useEffect(() => {
        // const socket = io(SOCKET_SERVER_URL);
        socket.on('templateList', (updatedTemplates) => {
            return setData(updatedTemplates);
        });
        getAlltemplate();
        return () => {
            // socket.disconnect();
            socket.off("templateList")
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managerId, page, filter, statusFilter, attachmentTypeFilter, categoryFilter]);



    const handleClickOutsideOptions = (event) => {

        if (markReadRef.current && !markReadRef.current.contains(event.target)) {
            setShowMenu(false);
            setActiveSubMenu(null); // Hide submenus when clicking outside
        }else{
            
            setShowMenu(false);
            setActiveSubMenu(null);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutsideOptions);
        return () => {
            document.removeEventListener("click", handleClickOutsideOptions);
        };
    }, []);



    return (
        <React.Fragment>
            <div className={styles.main_continer} >
                <div className={styles.Conitner} style={mode ? { backgroundColor: "#2C2E33", border:mode ? "none" :"1px solid #e4e7eb" } :
                    {/*  border: "1px solid #E4E7EB", */ backgroundColor: "#F8F9FC" }} >
                    {
                        width < 700 ? <div className={styles.sarchbar}>
                            <div className={styles.dateFilter} >
                                <div>
                                    <InputTypedate
                                        text={"From"}
                                        onChange={(e) => setFilter({ ...filter, From: e.target.value })}
                                        value={filter?.From}

                                    />
                                </div>
                                <div>
                                    <InputTypedate
                                        onChange={(e) => setFilter({ ...filter, To: e.target.value })}
                                        value={filter?.To}
                                        text={"To"} />
                                </div>
                                <div>
                                    <Button
                                        buttonBgColor={"#000000"}
                                        buttonIcon={<Refresh />}
                                        buttonTextColor={"#fafafa"}
                                        buttonText={width <= 700 ? null : "Refresh"}
                                        buttonClick={HandealRefresh}
                                    />
                                </div>
                            </div>
                            <div className={styles.dateFilter1} >
                                <div className={styles.sacrch}>
                                    <SearchBar
                                        onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                        value={filter?.search}
                                        placeholder={"Search ..."}
                                    />
                                </div>
                            </div>
                            <div></div>
                        </div> :
                            <div className={styles.Secand_Coninter} style={mode ? { backgroundColor: "#2C2E33",  } :
                                { backgroundColor: "#F8F9FC", }} >
                                <div className={styles.TopTabBa} >
                                    <div className={styles.TopTabBaconntiner1}>
                                        <TopTabBar text={"Campaigns/Template"} />
                                    </div>
                                    <div className={styles.TopTabBaconntiner}>
                                        <div>
                                            <InputTypedate
                                                text={"From"}
                                                onChange={(e) => setFilter({ ...filter, From: e.target.value })}
                                                value={filter?.From}

                                            />
                                        </div>
                                        <div>
                                            <InputTypedate
                                                onChange={(e) => setFilter({ ...filter, To: e.target.value })}
                                                value={filter?.To}
                                                text={"To"} />
                                        </div>
                                        <div>
                                            <Button
                                                buttonBgColor={"#000000"}
                                                buttonIcon={<Refresh />}
                                                buttonTextColor={"#fafafa"}
                                                buttonText={width < 970 ? null : "Refresh"}
                                                buttonClick={HandealRefresh}
                                            />
                                        </div>
                                        <div className={styles.createTemp} >
                                            <Button
                                                buttonBgColor={"#4164E3"}
                                                buttonIcon={<CreateTem />}
                                                buttonTextColor={"#fafafa"}
                                                buttonText={width < 886 ? null : "Create Template"}
                                                buttonClick={() => navigate("/templates/template")}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className={styles.btn_coninter} >
                                    <div className={styles.menuWrapper}>
                                        <div className={styles.SerachContiner} style={{ float: "left" }} >
                                            <SearchBar
                                                onChange={(e) => setFilter({ ...filter, search: e.target.value })}
                                                value={filter?.search}
                                                placeholder={"Search ..."}
                                            />
                                        </div>
                                        <div
                                            className={styles.iconWrapper}
                                            onMouseEnter={() => setShowMenu(true)}
                                            onMouseLeave={() => {
                                                setShowMenu(false);
                                                setActiveSubMenu(null);
                                            }}
                                            // onClick={()=>setShowMenu(true)}
                                            style={{ float: "left" }}
                                           
                                        >
                                            <div
                                                className={styles.AroBtn}
                                                style={
                                                    mode
                                                        ? { backgroundColor: "#1B1D21" }
                                                        : { backgroundColor: "#fafafafa", border: "1px solid #E4E7EB" }
                                                }
                                                //  ref={markReadRef}
                                            >
                                                <TemplateIcon mode={mode} />
                                            </div>

                                            {/* Main menu that appears on hover over the icon */}
                                            {showMenu && (
                                                <div
                                                    className={styles.mainMenu}
                                                    style={{
                                                        backgroundColor: mode ? "#232529" : "#fff",
                                                        color: mode ? "#fff" : "#000",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    {/* Category */}
                                                    <div
                                                        className={mode ? styles.menuItem_dark : styles.menuItem}
                                                        onMouseEnter={() => setActiveSubMenu('category')}
                                                        onMouseLeave={() => setActiveSubMenu(null)}
                                                    >
                                                        Category
                                                        {activeSubMenu === 'category' && (
                                                            <div
                                                                className={mode ? styles.subMenudark : styles.subMenu}
                                                                style={{
                                                                    backgroundColor: mode ? "#232529" : "#fff",
                                                                    color: mode ? "#fff" : "#000",
                                                                }}
                                                            >
                                                                <div onClick={() => setcategoryFilter("MARKETING")}>Marketing</div>
                                                                <div onClick={() => setcategoryFilter("UTILITY")}>Utility</div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Attachment Type */}
                                                    <div
                                                        className={mode ? styles.menuItem_dark : styles.menuItem}
                                                        onMouseEnter={() => setActiveSubMenu('attachmentType')}
                                                        onMouseLeave={() => setActiveSubMenu(null)}
                                                    >
                                                        Attachment Type
                                                        {activeSubMenu === 'attachmentType' && (
                                                            <div
                                                                className={mode ? styles.subMenudark : styles.subMenu}
                                                                style={{
                                                                    backgroundColor: mode ? "#232529" : "#fff",
                                                                    color: mode ? "#fff" : "#000",
                                                                }}
                                                            >
                                                                <div onClick={() => setattachmentTypeFilter("text")}>Text</div>
                                                                <div onClick={() => setattachmentTypeFilter("image")}>Image</div>
                                                                <div onClick={() => setattachmentTypeFilter("video")}>Video</div>
                                                                <div onClick={() => setattachmentTypeFilter("document")}>Document</div>
                                                                <div onClick={() => setattachmentTypeFilter("location")}>Location</div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* Status */}
                                                    <div
                                                        className={mode ? styles.menuItem_dark : styles.menuItem}
                                                        onMouseEnter={() => setActiveSubMenu('status')}
                                                        onMouseLeave={() => setActiveSubMenu(null)}
                                                    >
                                                        Status
                                                        {activeSubMenu === 'status' && (
                                                            <div
                                                                className={mode ? styles.subMenudark : styles.subMenu}
                                                                style={{
                                                                    backgroundColor: mode ? "#232529" : "#fff",
                                                                    color: mode ? "#fff" : "#000",
                                                                }}
                                                            >
                                                                <div onClick={() => setstatusFilter("sent")}>Sent</div>
                                                                <div onClick={() => setstatusFilter("unsent")}>Unsent</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>


                                    </div>
                                    <div className={styles.btn_coninter_Right} >

                                        {/* <div>
                                    <Button
                                        buttonBgColor={"#000000"}
                                        buttonIcon={<Import />}
                                        buttonTextColor={"#fafafa"}
                                        buttonText={"Import Template"} />
                                </div> */}


                                    </div>
                                </div>

                            </div>
                    }
                    <div className={styles.Secand_Coninter1} style={mode ? { backgroundColor: "#2C2E33", } :
                        { backgroundColor: "#F8F9FC", }} >
                        <Suspense fallback={<div>
                            <Skeleton lines={8} width="100%" thickness={8} height='400px' />
                        </div>}>
                            <TampletTable
                                data={data}
                                loading={loading}
                                page={page}
                                setPage={setPage}
                                pages={pages}
                                setPages={setPages}
                            />
                        </Suspense>
                    </div>

                    <div className={styles.Secand_Coninter2} style={mode ? { backgroundColor: "#2C2E33", } :
                        { backgroundColor: "#F8F9FC", }} >
                        <div style={{ width: "100%", display: "flex", alignItems: "center", }} >
                            <div style={{ width: "50%", }} >
                                <div style={{ width: "50%", display: "flex", justifyContent: "flex-start", fontSize: "12px" }} >
                                    <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                                </div>
                            </div>
                            <div style={{ width: "50%", display: "flex", justifyContent: "flex-end", fontSize: "12px" }} >
                                <Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} /></div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Templates
