import { useEffect, useState } from "react";
import {
  CancelIcon2,
  CrossIcon,
  SaveTaxRateIcon,
  UpdateIcon2,
} from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import RadioButtonGroup from "../../components/updatedComponents/FormComponents/RadioButtonGroup";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import styles from "./Inventory.module.css";

const AddTaxRateModal = ({
  handleCloseModal,
  mode,
  managerId,
  type,
  taxRate,
  onSuccess,
}) => {
  const { show } = useToast();
  const [taxName, setTaxName] = useState("");
  const [taxValue, setTaxValue] = useState("");
  const [taxRateId, setTaxRateId] = useState("");
  const [status, setStatus] = useState("Disable");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (type === "edit" && taxRate) {
      setTaxName(taxRate.name || "");
      setTaxValue(taxRate.value || "");
      setTaxRateId(taxRate.taxRateId || "");
      setStatus(taxRate.status || "Disable");
    }
  }, [type, taxRate]);

  useEffect(() => {
    if (type !== "edit" && taxName.trim() !== "" && taxValue.trim() !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [taxName, taxValue, type]);

  const handleAddTaxRate = async () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "wa-platform",
      "WAzxe01MBXU2werWrW2WAIMAdminWi342ASDADAShyIIoKvmYI"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      taxRateId: type !== "create" && taxRateId,
      name: taxName,
      value: taxValue,
      status: status,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      await fetch(
        `${process.env.REACT_APP_URL}inventory/adduptax`,
        requestOptions
      );
      show(
        `Tax Rate ${type === "edit" ? "updated" : "added"} successfully`,
        "success"
      );
      handleCloseModal();
      onSuccess();
    } catch (error) {
      console.error("Error while adding tax rate:", error);
    }
  };

  return (
    <div
      className={styles.add_tax_rate}
      style={mode ? { backgroundColor: "#2c2e33", color: "#fff" } : {}}
    >
      <div className={styles.add_tax_rate_top}>
        <p style={mode ? { color: "#fff" } : {}}>ID: AIDI</p>
        <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
          <CrossIcon color={mode ? "#FFFFFF" : "#1B1D21"} />
        </div>
      </div>
      <div className={styles.add_tax_rate_form}>
        <div className={styles.add_tax_rate_form_element}>
          <label htmlFor="">
            Name<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            required
            value={taxName}
            placeholder="Enter Tax Name"
            onChange={(e) => setTaxName(e.target.value)}
            maxLength={10}
            disabled={type === "edit"}
            style={{
              cursor: type === "edit" && "not-allowed",
              ...(mode && {
                backgroundColor: "#1B1D21",
                borderColor: "#1B1D21",
                color: "#FFFFFF",
              }),
            }}
            autoComplete={"new-password"}
          />
        </div>
        <div className={styles.add_tax_rate_form_element}>
          <label htmlFor="">
            Value<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="number"
            required
            value={taxValue}
            placeholder="Enter Tax Rate"
            onChange={(e) => setTaxValue(e.target.value)}
            maxLength={3}
            disabled={type === "edit"}
            style={{
              cursor: type === "edit" && "not-allowed",
              ...(mode && {
                backgroundColor: "#1B1D21",
                borderColor: "#1B1D21",
                color: "#FFFFFF",
              }),
            }}
            autoComplete={"new-password"}
          />
        </div>
        {type !== "create" && (
          <div className={styles.add_tax_rate_form_element}>
            <label htmlFor="">
              Tax Rate ID<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              required
              value={taxRateId}
              placeholder="Enter Tax Rate ID"
              onChange={(e) => setTaxRateId(e.target.value)}
              maxLength={10}
              disabled={type === "edit"}
              style={{
                cursor: type === "edit" && "not-allowed",
                ...(mode && {
                  backgroundColor: "#1B1D21",
                  borderColor: "#1B1D21",
                  color: "#FFFFFF",
                }),
              }}
              autoComplete={"new-password"}
            />
          </div>
        )}
        <div className={styles.add_tax_rate_form_element}>
          <label htmlFor="" style={{ marginBottom: "-1.2rem" }}>
            Status<span style={{ color: "red" }}>*</span>
          </label>
          <RadioButtonGroup
            options={[
              { value: "Active", label: "Active" },
              { value: "Disable", label: "Disable" },
            ]}
            selectedOption={status}
            onChange={(value) => {
              setStatus(value);
              if (type === "edit") {
                setDisabled(false);
              }
            }}
          />
        </div>

        <p>{taxName && `${taxName} ${taxValue}%`}</p>
        <div>
          <Button
            buttonIcon={<CancelIcon2 />}
            buttonText={"CANCEL"}
            buttonBgColor={"#000000"}
            buttonTextColor={"#ffffff"}
            buttonBorder={"none"}
            buttonClick={handleCloseModal}
          />
          <Button
            buttonIcon={type === "edit" ? <UpdateIcon2 /> : <SaveTaxRateIcon />}
            buttonText={type === "edit" ? "UPDATE" : "SAVE"}
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#ffffff"}
            buttonBorder={"none"}
            buttonClick={handleAddTaxRate}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default AddTaxRateModal;
