import { useEffect, useState } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { CrossIcon2, SkipIcon, TickIcon } from "../../icons/Icon";
import Button from "../Dealers/Button";
import ItemProperties from "./ItemProperties";
import ModalWrapper from "../layoutWrapper/ModalWrapper";

const ShippingChargesModal = ({
  close,
  // calculateCharges,
  orderDeliveryMethod,
  checkboxRef,
  mode,
  skipShippingCharges,
  noShippingCharges,
  itemsdetails,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key.toLowerCase() === "y") {
        orderDeliveryMethod && orderDeliveryMethod("yes");
        checkboxRef.current?.focus();
        close();
      }
      if (e.key.toLowerCase() === "n") {
        close();
        noShippingCharges();
        orderDeliveryMethod &&
          setTimeout(() => {
            orderDeliveryMethod("no");
          }, 100);
      }
      if (e.key.toLowerCase() === "s") {
        skipShippingCharges && skipShippingCharges();
        close();
      }
      if (e.key.toLowerCase() === "v") {
        setIsModalOpen(!isModalOpen);
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    checkboxRef,
    close,
    orderDeliveryMethod,
    skipShippingCharges,
    noShippingCharges,
    isModalOpen,
  ]);

  const totalCBM =
    Array.isArray(itemsdetails) && itemsdetails.length > 0
      ? parseFloat(
          itemsdetails
            .reduce((sum, item) => sum + (Number(item.vm) * item.qty || 0), 0)
            .toFixed(5)
        )
      : 0;

  const totalWeight =
    Array.isArray(itemsdetails) && itemsdetails.length > 0
      ? parseFloat(
          itemsdetails
            .reduce(
              (sum, item) => sum + (Number(item.weight) * item.qty || 0),
              0
            )
            .toFixed(5)
        )
      : 0;

  const groupItems = (items) => {
    const validItems = items.filter(
      (item) => item.itemNo && item.weight && item.vm && item.qty
    );

    const grouped = {};

    validItems.forEach(({ itemNo, weight, vm, qty }) => {
      const key = `${itemNo}-${weight}-${vm}`;

      if (!grouped[key]) {
        grouped[key] = { itemNo, weight, vm, qty };
      } else {
        grouped[key].qty += qty;
      }
    });

    return Object.values(grouped);
  };

  const groupedItems = groupItems(itemsdetails);

  return (
    <div
      className={`${styles.shipping_charges_modal} ${
        mode ? styles.dealer_address_modal_dark : ""
      }`}
      style={isModalOpen ? { borderRadius: "0" } : {}}
    >
      <p>Shipping charges detail</p>
      <img
        src="/assets/updatedAssets/shippingCharge.png"
        alt="Shipping charges"
      />
      <p>Do you want the system to calculate shipping charges automatically?</p>

      <ul className={styles.data_list}>
        <li
          style={{
            backgroundColor: mode ? "#2C2E33" : "",
            color: mode ? "#FFFFFF" : "#646B88",
            fontWeight: "600",
          }}
        >
          <div>Total Volume</div>
          <div>Total Weight</div>
        </li>
        <li style={mode ? { backgroundColor: "#1B1D21" } : {}}>
          <div>{totalCBM} CBM</div>
          <div>{totalWeight} KGs</div>
        </li>
        <li
          style={{
            backgroundColor: "transparent",
            // padding: "0",
            marginTop: "-12px",
            textAlign: "center",
            padding: "10px 80px",
          }}
        >
          <div>
            <span
              onClick={() => setIsModalOpen(true)}
              style={mode ? { color: "#749AFF" } : {}}
            >
              View Details
            </span>
          </div>
          <div>
            <p className={styles.info_txt}>Press - V</p>
          </div>
        </li>
      </ul>

      <ModalWrapper isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ItemProperties
          mode={mode}
          list={groupedItems}
          handleCloseModal={() => setIsModalOpen(false)}
          totalCBM={totalCBM}
          totalWeight={totalWeight}
        />
      </ModalWrapper>

      <div>
        <div>
          <Button
            buttonText={"YES"}
            buttonIcon={<TickIcon />}
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#FFFFFF"}
            buttonBorder={"none"}
            buttonClick={() => {
              // calculateCharges && calculateCharges();
              orderDeliveryMethod && orderDeliveryMethod();
              checkboxRef.current?.focus();
              close();
            }}
          />
          <p className={styles.btn_text}>Press - Y</p>
        </div>
        <div>
          <Button
            buttonText={"NO"}
            buttonIcon={<CrossIcon2 />}
            buttonBgColor={"#F93E3E"}
            buttonTextColor={"#FFFFFF"}
            buttonBorder={"none"}
            buttonClick={() => {
              orderDeliveryMethod && orderDeliveryMethod();
              noShippingCharges();
              close();
            }}
          />
          <p className={styles.btn_text}>Press - N</p>
        </div>
        <div>
          <Button
            buttonText={"SKIP"}
            buttonIcon={<SkipIcon />}
            buttonBgColor={"#000000"}
            buttonTextColor={"#FFFFFF"}
            buttonBorder={"none"}
            buttonClick={() => {
              skipShippingCharges && skipShippingCharges();
              close();
            }}
          />
          <p className={styles.btn_text}>Press - S</p>
        </div>
      </div>
    </div>
  );
};

export default ShippingChargesModal;
