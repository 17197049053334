// import React, { useState } from 'react'
// import styles from "./ProductCompo.module.css";
// import { Crous } from '../../icons/Icon3';
// import { useAuth } from '../../context/Auth';
// const KeyWordGen = ({
//     lableText,
//     star,
//     words,
//     setWords,
//     disabled,
//     errorText
// }) => {
//     const { mode } = useAuth();

//     const [inputValue, setInputValue] = useState("");
//     // const [, ] = useState([]);

//     const handleKeyPress = (e) => {
//         if (!disabled) {
//             if (e.key === "Enter" && inputValue.trim() !== "") {
//                 setWords([...words, inputValue.trim()]);
//                 setInputValue("");
//             }
//         }
//     };

//     const handleChange = (index) => {
//         if (!disabled) {
//             const filterData = words?.filter((_, i) => i !== index);
//             setWords(filterData)
//         }
//     }
//     const handleDragStart = (e, index) => {
//         if (!disabled) {
//             e.dataTransfer.setData("text/plain", index);
//         }
//     };

//     const handleDragOver = (e) => {
//         e.preventDefault();
//     };

//     const handleDrop = (e, dropIndex) => {
//         if (!disabled) {
//             e.preventDefault();
//             const dragIndex = parseInt(e.dataTransfer?.getData("text/plain"), 10);
//             const updatedWords = [...words];
//             const [draggedItem] = updatedWords.splice(dragIndex, 1);
//             updatedWords.splice(dropIndex, 0, draggedItem);
//             setWords(updatedWords);
//         }
//     };


//     return (
//         <div className={styles.Key_word_container}>
//             <div style={{ fontSize: "14px", fontWeight: "400", color: mode ? "#fff" : "#646b88" }}>
//                 {lableText}  {star && <span style={{ color: "red" }}>*</span>} </div>
//             {
//                 words?.length === 0 ? null :
//                     <div className={styles.keyWord_word}
//                         style={{
//                             backgroundColor: mode ? "#1B1D21" : "#fff",
//                             border: mode ? "1px solid #1B1D21" : "1px solid #E4E7EB"
//                         }}
//                     >
//                         {words?.map((word, i) => 
//                         <div key={i}
//                             style={{
//                                 backgroundColor: mode ? "#232529" : "#F5F6FA",
//                                 color: mode ? "#fff" : "#000",
//                                 cursor: disabled ? "not-allowed" : "text"
//                             }}
//                             draggable
//                             // onClick={() => handleChange(i)}
//                             onDragStart={(e) => handleDragStart(e, i)}
//                             onDragOver={(e) => handleDragOver(e)}
//                             onDrop={(e) => handleDrop(e, i)}
//                         > 
//                         <span>{word} </span> 
//                         <span onClick={() => handleChange(i)}> <Crous mode={mode} /> </span> 
//                         </div>)}
//                     </div>
//             }
//             <div className={styles.inputTage_keyWord_gen} >
//                 <input
//                     type="text"
//                     value={inputValue}
//                     onChange={(e) => setInputValue(e.target.value)}
//                     onKeyPress={handleKeyPress}
//                     placeholder="Enter Tags..."
//                     style={{
//                         backgroundColor: mode ? "#1B1D21" : "#fff",
//                         color: mode ? "#fff" : "#000",
//                         cursor: disabled && "not-allowed",
//                     }}
//                     disabled={disabled}
//                     className={mode ? styles.borderColorDark : styles.borderColorLight}
//                 />

//             </div>
//             {
//                 errorText && <div className={styles.errText} > <span> {errorText} </span> </div>
//             }



//         </div>
//     )
// }

// export default KeyWordGen



import React, { useState } from 'react';
import styles from "./ProductCompo.module.css";
import { Crous } from '../../icons/Icon3';
import { useAuth } from '../../context/Auth';
import { useToast } from '../Toaster/Toaster';

const KeyWordGen = ({ lableText, star, words, setWords, disabled, errorText }) => {
    const { mode } = useAuth();
    const {show} =useToast()
    const [inputValue, setInputValue] = useState("");

    const handleKeyPress = (e) => {
        if (!disabled && e.key === "Enter" && inputValue.trim() !== "") {
            setWords([...words, inputValue.trim()]);
            setInputValue("");
        }
    };

    const handleRemove = (index) => {
        if (!disabled) {
            setWords(words.filter((_, i) => i !== index));
        }
    };

    const handleDragStart = (e, index) => {
        if (!disabled) {
            e.dataTransfer.setData("text/plain", index);
        }
    };

    const handleDragOver = (e) => e.preventDefault();

    const handleDrop = (e, dropIndex) => {
        if (!disabled) {
            e.preventDefault();
            const dragIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);
            const updatedWords = [...words];
            const [draggedItem] = updatedWords.splice(dragIndex, 1);
            updatedWords.splice(dropIndex, 0, draggedItem);
            setWords(updatedWords);
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() =>  show("Copied!", "success"))
            .catch(() =>show("Failed to copy!", "error"));
    };

    return (
        <div className={styles.Key_word_container}>
            <div style={{ fontSize: "14px", fontWeight: "400", color: mode ? "#fff" : "#646b88" }}>
                {lableText} {star && <span style={{ color: "red" }}>*</span>}
            </div>
            {words.length > 0 && (
                <div className={styles.keyWord_word}
                    style={{
                        backgroundColor: mode ? "#1B1D21" : "#fff",
                        border: mode ? "1px solid #1B1D21" : "1px solid #E4E7EB"
                    }}
                >
                    {words.map((word, i) => (
                        <div key={i}
                            style={{
                                backgroundColor: mode ? "#232529" : "#F5F6FA",
                                color: mode ? "#fff" : "#000",
                                cursor: disabled ? "not-allowed" : "text",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px"
                            }}
                            draggable
                            onDragStart={(e) => handleDragStart(e, i)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, i)}
                        >
                            <span style={{ cursor: "pointer" }} onClick={() => copyToClipboard(word)}>{word}</span>
                            <span onClick={() => handleRemove(i)}
                                style={{ cursor: "pointer",  padding:"5px"}}
                                > <Crous mode={mode} /> </span>
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.inputTage_keyWord_gen}>
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Enter Tags..."
                    style={{
                        backgroundColor: mode ? "#1B1D21" : "#fff",
                        color: mode ? "#fff" : "#000",
                        cursor: disabled ? "not-allowed" : "text"
                    }}
                    disabled={disabled}
                    className={mode ? styles.borderColorDark : styles.borderColorLight}
                />
            </div>
            {/* {words.length > 0 && (
                <button
                    onClick={() => copyToClipboard(words.join(", "))}
                    style={{
                        marginTop: "10px",
                        padding: "5px 10px",
                        backgroundColor: mode ? "#1B1D21" : "#007BFF",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                >
                    Copy All
                </button>
            )} */}
            {errorText && <div className={styles.errText}><span>{errorText}</span></div>}
        </div>
    );
};

export default KeyWordGen;




