import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AddDealerModal from "../../components/AddDealerModal";
import { GreenOutButton } from "../../components/Buttons";
import { useAuth } from "../../components/context/Auth";
import {
  AddDealerIcon,
  Ban,
  Call,
  Checked,
  Clock,
  Messanger,
  Search,
  WhatsApp,
} from "../../components/icons/Icon";
import Pagination from "../../components/Paginantion";
import styles from "../kyc/Dealer.module.css";

const OrderDealers = () => {
  const [searchparams] = useSearchParams();
  const page = searchparams.get("page")
    ? parseInt(searchparams.get("page"))
    : 1;
  const { managerId } = useAuth();
  const [dealersData, setDealersData] = useState([]);
  const [showAddDealer, setShowAddDealer] = useState(false);
  const [searchDealerData, setSearchDealerData] = useState({
    searchDealerName: "",
    sortByStatus: "",
  });
  const [state, setState] = React.useState({
    totalPages: "",
    currentPage: 1,
  });

  const { totalPages } = state;
  useEffect(() => {
    if (managerId) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        keyword: searchDealerData.searchDealerName,
        sortKey: searchDealerData.sortByStatus,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      //   fetch(process.env.REACT_APP_URL + `order/dealers?page=${currentPage}`, requestOptions)
      fetch(
        process.env.REACT_APP_URL + `order/dealers?page=${page}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            if (result.data) {
              setDealersData(result.data);
            }
            if (result.pages) {
              setState((prevState) => ({
                ...prevState,
                totalPages: result.pages ? result.pages : "",
              }));
            }
          }
        })
        .catch((error) => {})
    }
  }, [
    managerId,
    searchDealerData.sortByStatus,
    searchDealerData.searchDealerName,
    page,
  ]);

  return (
    <React.Fragment>
      <AddDealerModal show={showAddDealer} close={setShowAddDealer} />

      <div className={styles.right_main}>
        <div className={styles.addDealers}>
          <h1 className={styles.dealers}>Dealers</h1>
          <GreenOutButton
            btnType="button"
            title={
              <>
                <AddDealerIcon />
                &nbsp;&nbsp;ADD DEALER
              </>
            }
            css={styles.addDealerButton}
            handleSubmit={() => {
              setShowAddDealer(true);
            }}
          />
        </div>
        <div className={styles.Dealer_main_container}>
          <div className={styles.dealer_header}>
            <div className={styles.dealer_search_div}>
              <input
                type="search"
                placeholder="Search"
                className={styles.dealer_search}
                value={searchDealerData.searchDealerName}
                onChange={(e) =>
                  setSearchDealerData({
                    ...searchDealerData,
                    searchDealerName: e.target.value,
                  })
                }
              />
              <Search color="#ffffff" css={styles.dealer_search_icon} />
            </div>
            <div className={styles.dealer_header_right}>
              <div className={styles.dealer_Sort_By}>
                <label className={styles.dealer_sortBy_txt}>Sort By </label>
                <select
                  className={styles.dealer_sortBy_select}
                  value={searchDealerData.sortByStatus}
                  onChange={(e) =>
                    setSearchDealerData({
                      ...searchDealerData,
                      sortByStatus: e.target.value,
                    })
                  }
                >
                  <option className={styles.dealer_sortBy_color} value="">
                    View All
                  </option>
                  <option className={styles.dealer_sortBy_color} value="New">
                    New
                  </option>
                  <option
                    className={styles.dealer_sortBy_color}
                    value="newestfirst"
                  >
                    Newest First
                  </option>
                  <option
                    className={styles.dealer_sortBy_color}
                    value="Pending"
                  >
                    Not Verified
                  </option>
                  <option
                    className={styles.dealer_sortBy_color}
                    value="Verified"
                  >
                    Verified
                  </option>
                  <option
                    className={styles.dealer_sortBy_color}
                    value="Ascending"
                  >
                    Ascending
                  </option>
                  <option
                    className={styles.dealer_sortBy_color}
                    value="Descending"
                  >
                    Descending
                  </option>
                </select>
              </div>
              <div className={styles.dealer_header_select_days}>
                <div
                  className={`${styles.dealer_days_select} ${styles.days_active}`}
                >
                  All
                </div>
                <div className={styles.dealer_days_select}>15 Days</div>
                <div className={styles.dealer_days_select}>15-30 Days</div>
                <div className={styles.dealer_days_select}>30 Days</div>
              </div>
            </div>
          </div>
          <div className={styles.dealer_main_div}>
            {dealersData &&
              dealersData.map((item, index) => (
                <div className={styles.dealer_card} key={index}>
                  <div className={styles.dealer_card_div}>
                    <div className={styles.cursor_pointer}>
                      <div className={styles.dealer_status}>
                        {item.status === "Rejected" ? (
                          <Ban
                            color="#F93E3E"
                            css={styles.dealer_status_icon}
                          />
                        ) : item.status === "Verified" ? (
                          <Checked
                            color="#1FFC33"
                            css={styles.dealer_status_icon}
                          />
                        ) : item.status === "Pending" ? (
                          <Clock
                            color="yellow"
                            css={styles.dealer_status_icon}
                          />
                        ) : (
                          <div className={styles.newIcon_div}>
                            <div className={styles.newIcon}>New</div>
                          </div>
                        )}
                      </div>

                      {!item.profileImage ? (
                        <div className={styles.name_char}>
                          <span className={styles.name_char_mb}>
                            {item && item.name.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      ) : (
                        <img
                          src={process.env.REACT_APP_S3URL + item.profileImage}
                          className={styles.dealer_image}
                          alt="dealer_image"
                        />
                      )}

                      <h4 className={styles.dealer_name}>{item.name}</h4>
                      <div className={styles.dealeStore_owner}>
                        {item.designation}
                      </div>
                      <div
                        className={item.storeName ? "" : styles.storeName_hide}
                      >
                        <div className={styles.dealeStore_owner}>at</div>
                        <div className={styles.dealeStore_owner}>
                          {item.storeName}
                        </div>
                      </div>
                    </div>
                    <div className={styles.dealer_btn_div}>
                      <button className={styles.dealer_Btn}>
                        <Messanger color="#ffffff" width={16} />
                      </button>
                      <a
                        href={"tel:+91" + item.mobile}
                        className={styles.dealer_Btn}
                      >
                        <Call color="#ffffff" width={16} />
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={"https://wa.me/+91" + item.whatsapp}
                        className={styles.dealer_Btn}
                      >
                        <WhatsApp width={16} />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <Pagination total={totalPages} current={page} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderDealers;
