import React, { forwardRef } from "react";
import styles from "../../../pages/Home.module.css";
import { useAuth } from "../../context/Auth";
import { Spinner } from "../../icons/Icon";

const InputTag = forwardRef(
  (
    {
      id,
      labelText,
      placeholderType,
      placeholderText,
      required,
      value,
      onChange,
      star,
      disabled,
      name,
      maxLength,
      loading,
      padding,
      fontSize,
      onFocus,
      onBlur,
      onKeyDown,
      autoComplete,
      readOnly,
      enterKeyHint,
      errorText,
      warning,
    },
    ref
  ) => {
    // Destructure `ref` here for forwardRef
    const { mode } = useAuth();

    const handleChange = (e) => {
      const newValue = e.target.value;
      if (newValue.length <= maxLength) {
        onChange(e);
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault(); // Prevent the default form submission
      }
    };

    return (
      <div
        className={styles.login_Form_element}
        onClick={() =>
          placeholderType === "date" ? ref?.current?.showPicker() : ""
        }
      >
        <label
          htmlFor={id}
          className={`${styles.login_Form_label} ${
            mode ? styles.login_Form_label_dark : ""
          }`}
        >
          {labelText}
          {star && <span style={{ color: "red" }}>*</span>}
          {errorText && (
            <span
              style={{
                float: "right",
                color: mode ? "#FEDF00" : "#4164E3",
                fontSize: "12px",
              }}
            >
              {errorText}
            </span>
          )}
        </label>
        {loading ? (
          <div
            className={`${styles.loading} ${styles.login_Form_input} ${
              mode ? styles.login_Form_input_dark : ""
            }`}
          >
            <span>
              <Spinner size="15" />
            </span>
          </div>
        ) : (
          <>
            <input
              {...(maxLength && { maxLength: Number(maxLength) })}
              name={name}
              id={id}
              type={placeholderType}
              placeholder={placeholderText}
              required={required}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              onKeyPress={handleKeyPress}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              ref={ref} // This attaches the forwarded ref
              className={`${styles.login_Form_input} ${
                mode ? styles.login_Form_input_dark : ""
              }`}
              style={{
                // backgroundColor: disabled && "#e4e7eb",
                cursor: disabled && "not-allowed",
                padding: padding && padding,
                fontSize: fontSize && fontSize,
              }}
              autoComplete={"new-password"}
              readOnly={readOnly}
              enterKeyHint={enterKeyHint}
            />
            {warning && <span className={styles.wor}>{warning}</span>}
          </>
        )}
      </div>
    );
  }
);

export default InputTag;
