import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import styles from "./ReciptsMob.module.css";
import {
  ArrowIcon,
  CartIcon,
  DateIcon,
  DownloadIcongrey,
  Loadmore,
  OrdersIcon,
  Search,
  SettingIcon,
  Spinner,
  ThreeDots,
} from "../../components/icons/Icon";
import ModalMob from "../components/ModalMob";
import { FileHandlerMob } from "../components/DragAndDropMob";
import { RedOutButton } from "../../components/Buttons";
import DispatchModal from "../components/DispatchModal";

const ReciptsMob = () => {
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [searchparams] = useSearchParams();
  // const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;
  const { managerId } = useAuth();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showAcceptModal1, setShowAcceptModal1] = useState(false);
  const [showAcceptModal2, setShowAcceptModal2] = useState(false);
  const [showAcceptModal3, setShowAcceptModal3] = useState(false);
  const [showAcceptModal4, setShowAcceptModal4] = useState(false);
  // const [showAcceptModal5, setShowAcceptModal5] = useState(false);
  // const [warehouses, setWarehouses] = useState([])
  // const [warehouseName, setWarehouseName] = useState('')
  // const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(true);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowAcceptModal3(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const data1 = [
    {
      key: "1",
      sort: "All",
    },
    {
      key: "2",
      sort: "New Order",
    },
    {
      key: "3",
      sort: "Cancelled",
    },
    {
      key: "4",
      sort: "In progress",
    },
    {
      key: "5",
      sort: "Packed",
    },
    {
      key: "6",
      sort: "Pending",
    },
    {
      key: "7",
      sort: "Dispatched",
    },
    {
      key: "8",
      sort: "Descending",
    },
    {
      key: "9",
      sort: "Ascending",
    },
    {
      key: "10",
      sort: "Last Action",
    },
  ];

  // const [pages, setPages] = useState(1)
  const [data, setData] = useState([]);
  const [receiptState, setReceiptState] = useState([]);
  const [daysTab, setDaysTab] = useState("today");
  const [shipingTab, setShipingTab] = useState("all");
  const [cartTab, setCartTab] = useState("all");
  const [keyword, setKeyword] = useState("");
  const [sortKey, setSortKey] = useState("");
  // const [loading, setLoading] = useState(false)
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  // const [showDropdown, setShowDropdown] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showReciptModal, setShowReciptModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [cancelUpdateRefund, setCancelUpdateRefund] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [managers, setManagers] = useState([]);
  const [managerName, setManagerName] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [isImage, setisImage] = useState();
  const [accounts, setAccounts] = useState([]);
  const [reload, setReload] = useState(false);
  const [reciptData, setReciptData] = useState({
    paymentMethod: "",
    paymentType: "",
    paymentAmount: "",
    transactionId: "",
    accountNumber: "",
  });
  const [refundData, setRefundData] = useState({
    refundAmt: "",
    refundMethod: "",
    reason: "",
  });

  const [receiptVerify, setReceiptVerify] = useState(false);
  const [error, setError] = useState({
    receiptImage: "",
  });

  const [revertbackReason, setRevertBackReason] = useState("");

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showOverlay]);

  useEffect(() => {
    if (showReciptModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      setShowReciptModal(false);
      setShowWarningModal(false);
      setCancelUpdateRefund("");
    }
  }, [showReciptModal]);

  const getCardData = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      keyword: keyword,
      day: daysTab,
      cartType: cartTab,
      endDate: endDate,
      orderType: shipingTab,
      ordermanagerId: managerName,
      sortKey: sortKey,
      startDate: startDate,
      status: orderStatus,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${
        process.env.REACT_APP_URL
      }receipt/receipts?page=${page}&limit=8&skip=${true}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setLoading(false);
          setData(result.data);
          setReceiptState(result.receipts);
          // setPages(result.pages)
          if (result.managers.orderManagers) {
            setManagers(result.managers.orderManagers);
          }
        }
        if (result.pages === 1) {
          setPage(1);
        }
        setPages(result.pages);
        setLoading1(false);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    /*eslint-disable*/
    getCardData();
  }, [
    page,
    managerId,
    keyword,
    cartTab,
    daysTab,
    startDate,
    endDate,
    shipingTab,
    managerName,
    orderStatus,
    sortKey,
    receiptVerify,
    reload,
  ]);

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading1(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    if (pages > page) {
      window.addEventListener("scroll", handelInfiniteScroll);
      return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }
  }, [pages, page]);

  const handleReceipt = (receiptId, orderId) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      receiptId: receiptId,
      orderId: orderId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "receipt/receipt", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowReciptModal(true);
          setReciptData(result.data);
          setAccounts(result.accounts);
        }
      })
      .catch((error) => {});
  };

  const handleSubmitRecipt = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (
      (reciptData.paymentMethod === "Photo" ||
        reciptData.paymentType === "Photo") &&
      !reciptData.transactionScreenshot &&
      !isImage
    ) {
      setError({ ...error, receiptImage: "Please upload Receipt image" });
      alert("Please upload Receipt image");
    } else if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleVerifyReceipt();
      setError({ ...error, receiptImage: "" });
    }
  };

  const handleVerifyReceipt = (receiptId, orderId) => {
    setReceiptVerify(false);
    setLoadingReceipt(true);
    setReload(false);
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("managerId", managerId);
    formdata.append("receiptId", reciptData.receiptId);
    formdata.append("orderId", reciptData.orderId);
    formdata.append("paymentMethod", reciptData.paymentMethod);
    formdata.append("paymentType", reciptData.paymentType);
    formdata.append(
      "accountNumber",
      reciptData.accountNumber ? reciptData.accountNumber : ""
    );
    formdata.append("transactionScreenshot", isImage ? [...isImage][0] : "");
    formdata.append("transactionId", reciptData.transactionId);
    formdata.append("paymentAmount", reciptData.paymentAmount);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "receipt/verifyreceipt", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowReciptModal(false);
          setReload(true);
        } else {
          alert(result.msg);
          setShowReciptModal(false);
        }
      })
      .catch((error) => {})
      .finally(() => setLoadingReceipt(false));
  };

  const handleRefundReceipt = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    var formdata = new FormData();
    formdata.append("managerId", managerId);
    formdata.append("receiptId", refundData.receiptId);
    formdata.append("orderId", refundData.orderId);
    formdata.append("refundAmt", refundData.refundAmt);
    formdata.append("refundMethod", refundData.refundMethod);
    formdata.append("reason", refundData.reason);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "receipt/refundreceipt", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowWarningModal(false);
        } else {
          alert(result.msg);
          setShowWarningModal(false);
        }
      })
      .catch((error) => {});
  };

  const handleCancelReceipt = () => {
    setReload(false);
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      receiptId: refundData.receiptId,
      orderId: refundData.orderId,
      reason: refundData.reason,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "receipt/cancelreceipt", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowWarningModal(false);
          setReload(true);
          // alert(result.msg)
        } else {
          alert(result.msg);
          setShowWarningModal(false);
        }
      })
      .catch((error) => {});
  };

  const submitRevertback = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleRevertback();
    }
  };

  const handleRevertback = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: parseInt(managerId),
      orderId: reciptData.orderId,
      receiptId: reciptData.receiptId,
      description: revertbackReason,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "receipt/revertReceipt", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          alert(result.msg);
          setShowRevertModal(false);
        }
      })
      .catch((error) => {});
  };

  // const handelInfiniteScroll = async () => {
  //     try {
  //         if (
  //             window.innerHeight + document.documentElement.scrollTop + 1 >=
  //             document.documentElement.scrollHeight

  //         ) {
  //             setLoading1(true);
  //             // setPage((prev) => prev + 1);
  //         }
  //     } catch (error) {
  //         //console.log(error);
  //     }
  // };

  // useEffect(() => {
  //     if (pages > page) {
  //         window.addEventListener("scroll", handelInfiniteScroll);
  //         return () => window.removeEventListener("scroll", handelInfiniteScroll);
  //     }
  // }, [pages, page]);

  // //console.log(setWarehouses(),setLoading1(), pages, setShowOverlay )
  return (
    <React.Fragment>
      <DispatchModal
        show={showRevertModal}
        close={setShowRevertModal}
        closeBtn={true}
        heading="Warning"
        content={
          <form onSubmit={submitRevertback}>
            <div className={styles.text_center}>
              <div className={`${styles.reciptHeader_div} pe-4 me-2`}>
                <h2 className={styles.reciptHeading}>{""}</h2>
                <div className="d-flex flex-column w-100">
                  <input
                    type="text"
                    placeholder="Write Revert back Reason"
                    className={styles.warningInput}
                    value={revertbackReason}
                    onChange={(e) => {
                      setRevertBackReason(e.target.value);
                    }}
                    required
                  />

                  <div className={styles.method + " mx-auto my-3"}>
                    Are You Sure Want To Revert back This Receipt
                  </div>

                  <div className="d-flex">
                    <button
                      className={styles.order_btn}
                      type="button"
                      onClick={() => setShowRevertModal(false)}
                    >
                      NO
                    </button>
                    <button className={styles.order_btn1} type="submit">
                      YES
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        }
      />

      <DispatchModal
        show={showWarningModal}
        close={setShowWarningModal}
        closeBtn={true}
        heading="Warning"
        content={
          <div className="d-flex flex-column">
            <div className={`${styles.reciptHeader_div}`}>
              {/* <h2 className={styles.reciptHeading}>{''}</h2> */}

              <div className="d-flex justify-content-between w-100">
                <div className="w-50">
                  <span className={styles.warning_text}>Date:&nbsp;</span>
                  <span>
                    <b>{refundData.receiptDate}</b>
                  </span>
                </div>
                <div className="w-50">
                  <span className={styles.warning_text}>Recipt Id:&nbsp;</span>
                  <span>
                    <b>{refundData.receiptId}</b>
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between w-100">
                <div className="w-50">
                  <span className={styles.warning_text}>Order Id:&nbsp;</span>
                  <span>
                    <b>{refundData.orderId}</b>
                  </span>
                </div>
                <div className="w-50">
                  <span className={styles.warning_text}>Reciver:&nbsp;</span>
                  <span></span>
                </div>
              </div>

              {/* <div className={styles.recipt_tableDiv}>
                                <div className={styles.recipt_table_cell}>
                                    <span>Date</span>
                                    <span><b>{refundData.receiptDate}</b></span>
                                </div>
                                <div className={styles.recipt_table_cell}>
                                    <span>Recipt Id</span>
                                    <span><b>{refundData.receiptId}</b></span>
                                </div>
                                <div className={styles.recipt_table_cell}>
                                    <span>Reciver</span>
                                    <span><b></b></span>
                                </div>
                                <div className={styles.recipt_table_cell}>
                                    <span>Order Id</span>
                                    <span><b>{refundData.orderId}</b></span>
                                </div>
                            </div> */}
            </div>
            {cancelUpdateRefund === "Cancel" && (
              <input
                type="text"
                placeholder="Write Cancel Reason"
                className={styles.warningInput}
                value={refundData.reason}
                onChange={(e) => {
                  setRefundData((prevState) => ({
                    ...prevState,
                    reason: e.target.value,
                  }));
                }}
              />
            )}
            {cancelUpdateRefund === "Refund" && (
              <div>
                <div className={`${"d-flex"} ${styles.refund_width}`}>
                  <div className="w-100 me-1">
                    <span className={styles.method}>Method</span>
                    <select
                      value={refundData.refundMethod}
                      onChange={(e) => {
                        setRefundData((prevState) => ({
                          ...prevState,
                          refundMethod: e.target.value,
                        }));
                      }}
                      className={styles.methodSelect}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      <option value="Photo">Photo</option>
                      <option value="Cash">Cash</option>
                      <option value="Online">Online</option>
                      <option value="POD">Pay On Delivery</option>
                    </select>
                  </div>

                  <div className="w-100 ms-1">
                    <span className={styles.method}>Payment Amount</span>
                    <input
                      type="number"
                      placeholder="Enter Amount"
                      className={styles.amount_input}
                      value={refundData.paymentAmount}
                      disabled
                      readOnly
                      //    onChange={(e) => setReciptData(prevState => ({ ...prevState, paymentAmount: e.target.value }))}
                    />
                  </div>
                </div>

                <input
                  type="text"
                  placeholder="Write Refund Reason"
                  className={styles.warningInput}
                  value={refundData.reason}
                  onChange={(e) => {
                    setRefundData((prevState) => ({
                      ...prevState,
                      reason: e.target.value,
                    }));
                  }}
                />
                <input
                  type="number"
                  placeholder="Enter Full Amount (in Rs)"
                  className={styles.warningInput}
                  value={refundData.refundAmt}
                  onChange={(e) => {
                    setRefundData((prevState) => ({
                      ...prevState,
                      refundAmt: e.target.value,
                    }));
                  }}
                />
              </div>
            )}
            <p className={styles.message}>
              Are You Sure Want To&nbsp;
              {cancelUpdateRefund === "Cancel"
                ? "Cancel Recipt"
                : cancelUpdateRefund === "Update"
                ? "Update Recipt"
                : cancelUpdateRefund === "Refund"
                ? "Refund Amount"
                : ""}
            </p>
            <div className={styles.warnigModal_btn}>
              {cancelUpdateRefund === "Cancel" ? (
                <button
                  type="button"
                  className={styles.order_btn1}
                  onClick={() => {
                    handleCancelReceipt();
                  }}
                >
                  YES
                </button>
              ) : cancelUpdateRefund === "Update" ? (
                <button
                  type="button"
                  className={styles.order_btn1}
                  onClick={() => {
                    setShowReciptModal(true);
                    setShowWarningModal(false);
                  }}
                >
                  YES
                </button>
              ) : cancelUpdateRefund === "Refund" ? (
                <button
                  type="button"
                  className={styles.order_btn1}
                  onClick={() => {
                    handleRefundReceipt()();
                  }}
                >
                  YES
                </button>
              ) : (
                ""
              )}
              <button
                type="button"
                className={styles.order_btn}
                onClick={() => {
                  setShowWarningModal(false);
                }}
              >
                NO
              </button>
            </div>
          </div>
        }
      />

      <div className={styles.main_container}>
        <div className={styles.dispatch_div}>
          <div
            onClick={() => setShowAcceptModal(true)}
            className={styles.tab_dropdown}
          >
            {daysTab ? daysTab : "SelectDay"}
            {showAcceptModal ? <span>&#9650;</span> : <span>&#9660;</span>}
          </div>
          <DateIcon />
          <label className={styles.dispatch_div1}>
            <div className={styles.date_text}>Start date:</div>
            <input
              type="date"
              className={styles.date_select}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                setDaysTab("");
              }}
            />
          </label>
          <label className={styles.dispatch_div1}>
            <div className={styles.date_text}>End date:</div>
            <input
              type="date"
              className={styles.date_select}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
                setDaysTab("");
              }}
            />
          </label>
        </div>
        <div className={styles.statusCard_div}>
          {loading ? (
            <>
              {Array.from({ length: 7 }).map((_, idx) => (
                <div key={idx} className="Mob_skeleton">
                  <span className={styles.orders_status_filter}>0</span>
                  <span className={styles.statusTxt}>status</span>
                </div>
              ))}
            </>
          ) : (
            receiptState &&
            receiptState.map((item, index) => (
              <div key={index} className={styles.statuscard1}>
                <div
                  className={`${styles.statusCard} ${
                    orderStatus === item.status && item.status === "New Order"
                      ? styles.newOrder_active
                      : orderStatus === item.status &&
                        (item.status === "In Progress" ||
                          item.status === "Pending For Refund")
                      ? styles.inProgress_active
                      : orderStatus === item.status &&
                        (item.status === "Packed" || item.status === "Refund")
                      ? styles.packed_active
                      : orderStatus === item.status &&
                        (item.status === "Pending" ||
                          item.status === "POD Pending")
                      ? styles.Pending_active
                      : orderStatus === item.status &&
                        (item.status === "Dispatched" ||
                          item.status === "Received")
                      ? styles.Dispatches_active
                      : orderStatus === item.status && item.status === "All"
                      ? styles.newOrder_active
                      : orderStatus === item.status &&
                        item.status === "Cancelled"
                      ? styles.cancel_status
                      : ""
                  }`}
                  onClick={() => {
                    setOrderStatus(item.status);
                  }}
                >
                  <div
                    className={
                      `${styles.orders_status_filter} ` +
                      (item.status === "New Order"
                        ? styles.statusNew
                        : item.status === "In Progress" ||
                          item.status === "Pending For Refund"
                        ? styles.statusYellow
                        : item.status === "Packed" || item.status === "Refund"
                        ? styles.statusBlue
                        : item.status === "All"
                        ? styles.statusNew
                        : item.status === "Pending" ||
                          item.status === "POD Pending"
                        ? styles.statusRed
                        : item.status === "Dispatched" ||
                          item.status === "Received"
                        ? styles.statusGreen
                        : item.status === "Cancelled"
                        ? styles.statusWhite
                        : "")
                    }
                  >
                    {item.count}
                    <div className={styles.statusTxt}>{item.status}</div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div>
          <div className={styles.flex}>
            <span className={styles.dashboard_search_div}>
              <input
                type="text"
                placeholder="Search"
                className={styles.dashboard_search}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
              <Search color="#000" css={styles.dashboard_search_icon} />
            </span>
            <span
              className={styles.icons_css}
              onClick={() => setShowAcceptModal2(true)}
            >
              <CartIcon />
            </span>
            <span
              className={styles.icons_css}
              onClick={() => setShowAcceptModal1(true)}
            >
              <CartIcon />
            </span>
            <span
              className={styles.icons_css}
              onClick={() => setShowAcceptModal4(true)}
            >
              <SettingIcon />
            </span>
            {/* <span className={styles.icons_css} onClick={() => setShowAcceptModal5(true)}>
                            <IndustryIcon />
                        </span> */}
            <span
              className={styles.icons_css}
              onClick={() => setShowAcceptModal3(!showAcceptModal3)}
            >
              <ArrowIcon />
            </span>
            <span className={styles.icons_css}>
              <DownloadIcongrey color="#D3D3D3" />
            </span>
            {showAcceptModal3 && (
              <div
                className={styles.short_div}
                ref={wrapperRef}
                onClick={(e) => e.stopPropagation()}
              >
                <h3>Short By</h3>
                {data1.map((item, index) => (
                  <h6
                    key={index}
                    className={`${
                      sortKey === item.sort ? styles.days_active : ""
                    }`}
                    onClick={(e) => {
                      setShowAcceptModal3(false);
                      if (
                        item.sort === "Ascending" ||
                        item.sort === "Descending" ||
                        item.sort === "lastAction"
                      ) {
                        setSortKey(item.sort);
                      } else {
                        setOrderStatus(item.sort);
                        setSortKey("");
                      }
                    }}
                  >
                    {item.sort}
                  </h6>
                ))}
              </div>
            )}
          </div>
          <h2 className={styles.orders_text}>Receipts</h2>
          {loading ? (
            <div className={"Mob_loading"}>
              <img src="/wallicon.gif" alt="walliconGIF" />
            </div>
          ) : (
            <>
              {data.length !== 0 ? (
                <div className={styles.orders + " pb-5"}>
                  {!loading1 ? (
                    <>
                      {data &&
                        data.map((item, index) => (
                          <div
                            role="button"
                            key={index}
                            className={styles.orders_div}
                            onClick={() => {
                              handleReceipt(item.receiptId, item.orderId);
                            }}
                          >
                            <div className="d-flex justify-content-between w-100">
                              <div className={styles.receipt_card_heading}>
                                <b>{item.storeName}</b>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span className={styles.verify_time}>
                                  {item.verifyTime}
                                </span>
                                <span
                                  className={
                                    item.status === "Pending"
                                      ? styles.receiptStatus_yellow
                                      : item.status === "Received"
                                      ? styles.receiptStatus_green
                                      : item.status === "POD Pending" ||
                                        item.status ===
                                          "Pending For Verification" ||
                                        item.status === "Cancelled"
                                      ? styles.receiptStatus_red
                                      : ""
                                  }
                                >
                                  {item.status}
                                </span>
                                <span
                                  className={styles.ThreeDots}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowOverlay(index);
                                  }}
                                >
                                  <ThreeDots color="#3A3A3A" />
                                </span>
                              </div>
                            </div>
                            {showOverlay === index && (
                              <div
                                className={styles.modal_backdrop_overlay}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowOverlay();
                                }}
                              >
                                <div
                                  className={styles.modal_content_overlay}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {
                                    <>
                                      <span
                                        onClick={() => {
                                          setShowOverlay(false);
                                          setCancelUpdateRefund("Cancel");
                                          setShowWarningModal(true);
                                          setRefundData(item);
                                        }}
                                      >
                                        Cancel
                                      </span>
                                      <span
                                        onClick={() => {
                                          setShowOverlay(false);
                                          setCancelUpdateRefund("Update");
                                          setShowWarningModal(true);
                                        }}
                                      >
                                        Update
                                      </span>
                                      <span
                                        onClick={() => {
                                          setShowOverlay(false);
                                          setCancelUpdateRefund("Refund");
                                          setShowWarningModal(true);
                                          // setRefundData(prevState => ({
                                          //     ...prevState,
                                          //     receiptId: val.receiptId,
                                          //     orderId: val.orderId,
                                          //     paymentAmount: val.paymentAmount,
                                          //     totalAmount: val.totalAmount,
                                          // }))
                                          setRefundData(item);
                                          // //console.log(val)
                                        }}
                                      >
                                        Refund
                                      </span>
                                    </>
                                  }
                                </div>
                              </div>
                            )}
                            <div className="d-flex w-100">
                              <OrdersIcon />
                              <div className="w-100 ms-2">
                                <div className={styles.receipt_Id}>
                                  Recipt Id: {item.receiptId} | Order Id:{" "}
                                  <span
                                    onClick={() => {
                                      navigate(
                                        "/orders/saleorder?orderId=" +
                                          item.orderId +
                                          "&method=" +
                                          item.paymentMethod
                                      );
                                    }}
                                    className="text-info"
                                  >
                                    <u>{item.orderId}</u>
                                  </span>
                                </div>
                                <div className={styles.receipt_details}>
                                  <div className="d-flex justify-content-between">
                                    <span>Date & Time</span>
                                    <span>
                                      {item.receiptDate}&nbsp;&nbsp;
                                      {item.receiptTime}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <span>Dealer Id</span>
                                    <span>{item.dealerId}</span>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <span>Order Manager</span>
                                    <span>{item.ordermanager}</span>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <span>Delivery Partner</span>
                                    <span>{item.partnerName}</span>
                                  </div>
                                  {item.dispatchStatus && (
                                    <div className="d-flex justify-content-between">
                                      <span>Dispatch Status</span>
                                      <span>{item.dispatchStatus}</span>
                                    </div>
                                  )}

                                  {item.verifyBy && (
                                    <div className="d-flex justify-content-between">
                                      <span>Receiver</span>
                                      <span>{item.verifyBy}</span>
                                    </div>
                                  )}
                                  <div className="d-flex justify-content-between">
                                    <b>Amount | Cash</b>
                                    <b>{item.paymentAmount} ₹</b>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className='d-flex justify-content-center align-items-center'>
                                                            
                                                            <div className={styles.order_detail}>
                                                                <h2>{item.storeName}</h2>
                                                                <div className={styles.orderDate}><span>OrderId : {item.orderId}</span><span>Date : {item.orderDate}</span></div>
                                                                <span>Order Manager : {item.ordermanager}</span>
                                                                {designation === 'superadmin' ?
                                                                    <span>Dispatch Manager : {item.disPatchManager}</span> :
                                                                    designation === 'dispatchmanager' ?
                                                                        <span>Packing Manager : {item.packinghManager}</span> :
                                                                        ''
                                                                }

                                                                <span
                                                                    className={`${styles.orders_status} ` + (
                                                                        item.status === 'New Order' ? styles.statusNew :
                                                                            item.status === 'In Progress' ? styles.statusYellow :
                                                                                item.status === 'Packed' ? styles.statusBlue :
                                                                                    item.status === 'All' ? styles.statusAll :
                                                                                        item.status === 'Pending' ? styles.statusRed :
                                                                                            item.status === 'Dispatched' ? styles.statusGreen :
                                                                                                item.status === 'Cancelled' ? styles.statusWhite :
                                                                                                    '')}
                                                                >{item.status}
                                                                </span>
                                                            </div>
                                                        </div> */}

                            {/* <div>
                                                            <EyeTwo />
                                                        </div> */}
                          </div>
                        ))}
                    </>
                  ) : (
                    <div className={styles.loadmore}>
                      <Loadmore className={styles.loadmoreIocon} />
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.noData}>No Data Found !</div>
              )}
            </>
          )}
          {/* </>
                            :
                            <div className={styles.loadmore}>
                            <Loadmore className={styles.loadmoreIocon} />
                            </div>
                        } */}
        </div>
      </div>

      {showReciptModal && (
        <div
          className={styles.modal_backdrop}
          onClick={() => {
            setShowReciptModal();
          }}
        >
          <div
            className={styles.modal_content}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <form onSubmit={handleSubmitRecipt}>
              <div className={styles.reciptHeader_div}>
                <h2 className={styles.reciptHeading}>Receipt</h2>
                {reciptData.paymentMethod === "POD" && (
                  <div className="d-flex justify-content-end align-items-end mb-2">
                    <div className={styles.recipt_tableDiv}>
                      <div className={styles.recipt_table_cell}>
                        <span>Date</span>
                        <span>
                          <b>{reciptData.receiptDate}</b>
                        </span>
                      </div>
                      <div className={styles.recipt_table_cell}>
                        <span>Recipt Id</span>
                        <span>
                          <b>{reciptData.receiptId}</b>
                        </span>
                      </div>
                      <div className={styles.recipt_table_cell}>
                        <span>Reciver</span>
                        <span>
                          <b>{reciptData.receiver && reciptData.receiver}</b>
                        </span>
                      </div>
                      <div className={styles.recipt_table_cell}>
                        <span>Order Id</span>
                        <span>
                          <b>{reciptData.orderId}</b>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.reciptContentDiv}>
                <div className="d-flex">
                  <div className="w-100 me-1 mb-2">
                    <span className={styles.method}>Method</span>
                    <select
                      required
                      value={reciptData.paymentMethod}
                      onChange={(e) => {
                        setReciptData("");
                        setisImage("");
                        setReciptData((prevState) => ({
                          ...prevState,
                          paymentMethod: e.target.value,
                        }));
                      }}
                      className={styles.methodSelect}
                    >
                      <option value="" hidden>
                        Select
                      </option>
                      {reciptData.paymentMethod === "Online" ? (
                        <option value="Online">Online</option>
                      ) : (
                        <>
                          <option value="Photo">Photo</option>
                          <option value="Cash">Cash</option>
                          <option value="POD">Pay On Delivery</option>
                        </>
                      )}
                    </select>
                  </div>

                  <div className="w-100 ms-1">
                    <span className={styles.method}>Total Amount</span>
                    <input
                      type="number"
                      placeholder="Enter Amount"
                      className={styles.amount_input}
                      value={reciptData.totalAmount}
                      disabled
                      readOnly
                    />
                  </div>
                </div>

                <div className="d-flex mt-1">
                  {reciptData.paymentMethod === "POD" && (
                    <div className={styles.subMethod_div}>
                      <span className={styles.submethod_txt}>Sub Method</span>
                      <select
                        value={reciptData.paymentType}
                        onChange={(e) =>
                          setReciptData((prevState) => ({
                            ...prevState,
                            paymentType: e.target.value,
                          }))
                        }
                        className={styles.subMethod_select}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Cash">Cash</option>
                        {/* <option value='PayNow'>Pay Now</option> */}
                        <option value="Photo">Photo</option>
                      </select>
                    </div>
                  )}

                  {reciptData.paymentMethod !== "Cash" && (
                    <div className="w-100">
                      <span className={styles.submethod_txt}>Account</span>
                      <select
                        required={
                          reciptData.paymentMethod === "Photo" ||
                          reciptData.paymentType === "Photo"
                            ? true
                            : false
                        }
                        value={reciptData.accountNumber}
                        onChange={(e) =>
                          setReciptData((prevState) => ({
                            ...prevState,
                            accountNumber: e.target.value,
                          }))
                        }
                        className={styles.reciptMethod_select}
                      >
                        <option value="" hidden>
                          Select
                        </option>
                        {accounts &&
                          accounts.map((item, index) => (
                            <option key={index} value={item.accountNumber}>
                              {item.bankName + " " + item.accountNumber}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>

                {reciptData.paymentType !== "Cash" &&
                  (reciptData.paymentMethod === "Photo" ||
                    reciptData.paymentMethod === "POD" ||
                    reciptData.paymentMethod === "Online") && (
                    <>
                      {(isImage || reciptData.transactionScreenshot) && (
                        <>
                          <div className={styles.reciptImage_div}>
                            <div className={styles.Image_div}>
                              <img
                                src={
                                  isImage
                                    ? URL.createObjectURL(isImage[0])
                                    : process.env.REACT_APP_S3URL +
                                      reciptData.transactionScreenshot
                                }
                                alt="Recipt"
                                onClick={() =>
                                  window.open(
                                    isImage
                                      ? URL.createObjectURL(isImage[0])
                                      : process.env.REACT_APP_S3URL +
                                          reciptData.transactionScreenshot,
                                    "mozillaWindow",
                                    "popup"
                                  )
                                }
                                className={styles.reciptImage}
                              />
                            </div>
                            <div
                              className={styles.reciptImage_delete}
                              onClick={() => {
                                setisImage();
                                setReciptData((prevState) => ({
                                  ...prevState,
                                  transactionScreenshot: "",
                                }));
                              }}
                            >
                              {/* <DeleteIcon /> */}
                            </div>
                          </div>
                          <p
                            role="button"
                            className={styles.viewRecipt}
                            onClick={() =>
                              window.open(
                                isImage
                                  ? URL.createObjectURL(isImage[0])
                                  : process.env.REACT_APP_S3URL +
                                      reciptData.transactionScreenshot,
                                "mozillaWindow",
                                "popup"
                              )
                            }
                          >
                            *Click To View Screenshot
                          </p>
                        </>
                      )}
                      {!isImage && !reciptData.transactionScreenshot && (
                        <>
                          <div className={styles.dragImage_div}>
                            <FileHandlerMob
                              setisImage={setisImage}
                              title="Upload Image"
                              error={error}
                            />
                          </div>
                        </>
                      )}
                      <input
                        required={
                          reciptData.paymentMethod === "Photo" ||
                          reciptData.paymentType === "Photo"
                            ? true
                            : false
                        }
                        type="text"
                        placeholder="Ref / UTR Number / Transaction Id"
                        className={styles.recipt_inputField_ref}
                        value={reciptData.transactionId}
                        onChange={(e) =>
                          setReciptData((prevState) => ({
                            ...prevState,
                            transactionId: e.target.value,
                          }))
                        }
                      />
                    </>
                  )}

                <input
                  required
                  type="number"
                  placeholder="Enter Transcation Amount (in Rs)"
                  className={styles.recipt_transactionAmount}
                  value={reciptData.paymentAmount}
                  onChange={(e) =>
                    setReciptData((prevState) => ({
                      ...prevState,
                      paymentAmount: e.target.value,
                    }))
                  }
                />

                <div className={styles.recipt_btn}>
                  <button
                    className={styles.order_btn}
                    onClick={() => {
                      setShowReciptModal(false);
                      setShowWarningModal(false);
                      setCancelUpdateRefund("");
                    }}
                  >
                    CANCEL
                  </button>
                  {reciptData.status !== "Received" &&
                  reciptData.status !== "Cancelled" &&
                  reciptData.paymentMethod === "Online" ? (
                    <RedOutButton
                      title="REVERT BACK"
                      css={styles.cancel_receipt_btn}
                      handleSubmit={() => {
                        setShowRevertModal(true);
                        setShowReciptModal(false);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {cancelUpdateRefund === "Update" ? (
                    <button
                      className={styles.order_btn1}
                      onClick={() => setShowReciptModal(false)}
                    >
                      UPDATE & VERIFY
                    </button>
                  ) : (
                    <button
                      className={
                        reciptData.status === "Pending" ||
                        reciptData.status === "POD Pending" ||
                        reciptData.status === "Pending For Refund" ||
                        reciptData.status === "Pending For Verification" ||
                        reciptData.status === "Cash Pending at Partner"
                          ? styles.order_btn1
                          : styles.order_btn1_disable
                      }
                      disabled={
                        reciptData.status === "Pending" ||
                        reciptData.status === "POD Pending" ||
                        reciptData.status === "Pending For Refund" ||
                        reciptData.status === "Pending For Verification" ||
                        reciptData.status === "Cash Pending at Partner"
                          ? false
                          : true
                      }
                      type="submit"
                    >
                      {loadingReceipt ? <Spinner size="15" /> : "VERIFY"}
                    </button>
                  )}
                </div>
              </div>

              {/* <div className={styles.reciptHeader_div} >
                                <h2 className={styles.reciptHeading}>Receipt</h2>
                                {reciptData.paymentMethod === 'POD' &&
                                    <div className={styles.recipt_tableDiv}>
                                        <div className={styles.recipt_table_cell}><span>Date</span><span><b>{reciptData.receiptDate}</b></span></div>
                                        <div className={styles.recipt_table_cell}><span>Recipt Id</span><span><b>{reciptData.receiptId}</b></span></div>
                                        <div className={styles.recipt_table_cell}><span>Reciver</span><span><b></b></span></div>
                                        <div className={styles.recipt_table_cell}><span>Order Id</span><span><b>{reciptData.orderId}</b></span></div>
                                    </div>
                                }
                            </div>
                            <div className={styles.reciptContentDiv}>

                                <div className='d-flex'>
                                    <div className='w-100 me-1'>
                                        <span className={styles.method}>Method</span>
                                        <select
                                            value={reciptData.paymentMethod}
                                            onChange={(e) => {
                                                setReciptData('');
                                                setisImage('');
                                                setReciptData(prevState => ({
                                                    ...prevState, paymentMethod: e.target.value
                                                }))
                                            }}
                                            className={styles.methodSelect}
                                            required
                                        >
                                            <option value='' hidden>Select</option>
                                            {reciptData.paymentMethod === 'Online' ?
                                                <option value='Online'>Online</option> :
                                                <>
                                                    <option value='Photo'>Photo</option>
                                                    <option value='Cash'>Cash</option>
                                                    <option value='POD'>Pay On Delivery</option>
                                                </>}
                                        </select>
                                    </div>

                                    <div className='w-100 ms-1'>
                                        <span className={styles.method}>Total Amount</span>
                                        <input
                                            type='number'
                                            placeholder='Enter Amount'
                                            className={styles.amount_input}
                                            value={reciptData.totalAmount}
                                            disabled
                                            readOnly
                                      
                                        />
                                    </div>
                                </div>

                                <div className='d-flex mt-3'>
                                    {
                                        reciptData.paymentMethod === 'POD' &&
                                        <div className='w-100 me-1'>
                                            <span className={styles.submethod_txt}>Sub Method</span>
                                            <select
                                                value={reciptData.paymentType}
                                                onChange={(e) => setReciptData(prevState => ({
                                                    ...prevState, paymentType: e.target.value
                                                }))}
                                                className={styles.reciptMethod_select}
                                                required
                                            >
                                                <option value='' >Select</option>
                                                <option value='Cash'>Cash</option>
                                           
                                                <option value='Photo'>Photo</option>
                                            </select>
                                        </div>
                                    }

                                    {reciptData.paymentMethod === 'Cash' || reciptData.paymentType === 'Cash' ? '' :
                                        <div className='w-100 ms-1'>
                                            <span className={styles.submethod_txt}>Account</span>
                                            <select
                                                value={reciptData.accountNumber}
                                                onChange={(e) => setReciptData(prevState => ({
                                                    ...prevState, accountNumber: e.target.value
                                                }))}
                                                className={styles.reciptMethod_select}
                                                required
                                            >
                                                <option value='' hidden>Select</option>
                                                {accounts && accounts.map((item, index) =>
                                                    <option key={index} value={item.accountNumber}>
                                                        {item.bankName + " " + item.accountNumber}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    }
                                </div>

                                {reciptData.paymentType !== 'Cash' && (reciptData.paymentMethod === 'Photo' || reciptData.paymentMethod === 'POD' || reciptData.paymentMethod === 'Online') &&
                                    <>
                                        {(isImage || reciptData.transactionScreenshot) &&
                                            <>
                                                <div className={styles.reciptImage_div}>
                                                    <div className={styles.Image_div}>
                                                        <img
                                                            src={isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot}
                                                            alt='Recipt'
                                                            onClick={() => window.open(isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot, "mozillaWindow", "popup")}
                                                            className={styles.reciptImage}
                                                        />
                                                    </div>
                                                    {cancelUpdateRefund === 'Update' && <div className={styles.reciptImage_delete} onClick={() => { setisImage(); setReciptData(prevState => ({ ...prevState, transactionScreenshot: '' })) }}><DeleteIcon /></div>}
                                                    <div className={styles.reciptImage_delete} onClick={() => { setisImage(); setReciptData(prevState => ({ ...prevState, transactionScreenshot: '' })) }}><DeleteIcon />
                                                       
                                                    </div>
                                                </div>
                                                <p role='button' className={styles.viewRecipt} onClick={() => window.open(isImage ? URL.createObjectURL(isImage[0]) : process.env.REACT_APP_S3URL + reciptData.transactionScreenshot, "mozillaWindow", "popup")}>*Click To View Screenshot</p>
                                            </>
                                        }
                                        {(!isImage && !reciptData.transactionScreenshot) &&
                                            <>
                                                <div className={styles.dragImage_div}>
                                                    <FileHandler
                                                        setisImage={setisImage}
                                                        styles={styles}
                                                        error={error}
                                                    />
                                                </div>
                                            </>
                                        }
                                        <input
                                            type='text'
                                            placeholder='Ref / UTR Number / Transaction Id'
                                            className={styles.recipt_inputField_ref}
                                            value={reciptData.transactionId}
                                            onChange={(e) => setReciptData(prevState => ({
                                                ...prevState, transactionId: e.target.value
                                            }))}
                                            required
                                        />
                                    </>
                                }
                                <input

                                    type='number'
                                    placeholder='Enter Transcation Amount (in Rs)'
                                    className={styles.recipt_inputField}
                                    value={reciptData.paymentAmount}
                                    onChange={(e) => setReciptData(prevState => ({
                                        ...prevState,
                                        paymentAmount: e.target.value
                                    }))}
                                    required
                                />

                                <div className={styles.recipt_btn}>
                                    <GreenOutButton title="CANCEL" handleSubmit={() => { setShowReciptModal(false); setShowWarningModal(false); setCancelUpdateRefund('') }} />
                                    {((reciptData.status !== "Received") && (reciptData.status !== "Cancelled") && ( reciptData.paymentMethod === 'Online')) ? <RedOutButton title='REVERT BACK' css={styles.cancel_receipt_btn} handleSubmit={() => { setShowRevertModal(true); setShowReciptModal(false) }} /> : ''}
                                    {cancelUpdateRefund === 'Update' ?
                                        <GreenButton title="UPDATE & VERIFY" handleSubmit={() => setShowReciptModal(false)} /> :
                                        <GreenButton
                                            title={reciptData.status === "Received" ? "VERIFIED" : loadingReceipt ? <Spinner size="15" /> : "VERIFY"} btnType='submit'
                                            disabled={(reciptData.status === "Pending" || reciptData.status === "POD Pending" || reciptData.status === "Pending For Refund" || reciptData.status === "Pending For Verification") ? false : true}
                                        />
                                    }
                                </div>
                            </div> */}
            </form>
          </div>
        </div>
      )}

      <ModalMob
        show={showAcceptModal}
        close={setShowAcceptModal}
        // closeBtn={true}
        // heading="Order Status"
        content={
          <div className={styles.dispatch_select}>
            <h2
              className={`${styles.dashboard_days_select} ${
                daysTab === "today" ? styles.days_active : ""
              }`}
              onClick={() => {
                setDaysTab("today");
                setShowAcceptModal(false);
              }}
            >
              Today
            </h2>
            <h2
              className={`${styles.dashboard_days_select} ${
                daysTab === "yesterday" ? styles.days_active : ""
              }`}
              onClick={() => {
                setDaysTab("yesterday");
                setShowAcceptModal(false);
              }}
            >
              Yesterday
            </h2>
            <h2
              className={`${styles.dashboard_days_select} ${
                daysTab === "month" ? styles.days_active : ""
              }`}
              onClick={() => {
                setDaysTab("month");
                setShowAcceptModal(false);
              }}
            >
              This Month
            </h2>
          </div>
        }
      />

      <ModalMob
        show={showAcceptModal1}
        close={setShowAcceptModal1}
        // closeBtn={true}
        // heading="Order Status"
        css={styles.date_margin_top}
        content={
          <div className={styles.dispatch_select}>
            <h2>Select Cart Type</h2>
            <div
              className={styles.flex}
              onClick={() => {
                setCartTab("all");
                setShowAcceptModal1(false);
              }}
            >
              <h2 className={styles.dashboard_days_select1}>All</h2>
              <input
                checked={cartTab === "all" ? true : false}
                type="checkbox"
                className={styles.largerCheckbox}
              />
            </div>
            <div
              className={styles.flex}
              onClick={() => {
                setCartTab("cart");
                setShowAcceptModal1(false);
              }}
            >
              <h2 className={styles.dashboard_days_select1}>Cart</h2>
              <input
                checked={cartTab === "cart" ? true : false}
                type="checkbox"
                className={styles.largerCheckbox}
              />
            </div>
            <div
              className={styles.flex}
              onClick={() => {
                setCartTab("pcart");
                setShowAcceptModal1(false);
              }}
            >
              <h2 className={styles.dashboard_days_select1}>Partner cart</h2>
              <input
                checked={cartTab === "pcart" ? true : false}
                type="checkbox"
                className={styles.largerCheckbox}
              />
            </div>
          </div>
        }
      />

      <ModalMob
        show={showAcceptModal2}
        close={setShowAcceptModal2}
        // closeBtn={true}
        // heading="Order Status"
        css={styles.date_margin_top}
        content={
          <div className={styles.dispatch_select}>
            <h2>Choose Delivery Type</h2>
            <div
              className={styles.flex}
              onClick={() => {
                setShipingTab("all");
                setShowAcceptModal2(false);
              }}
            >
              <h2 className={styles.dashboard_days_select1}>All</h2>
              <input
                checked={shipingTab === "all" ? true : false}
                type="checkbox"
                className={styles.largerCheckbox}
              />
            </div>
            <div
              className={styles.flex}
              onClick={() => {
                setShipingTab("standred");
                setShowAcceptModal2(false);
              }}
            >
              <h2 className={styles.dashboard_days_select1}>Standard</h2>
              <input
                checked={shipingTab === "standred" ? true : false}
                type="checkbox"
                className={styles.largerCheckbox}
              />
            </div>
            <div
              className={styles.flex}
              onClick={() => {
                setShipingTab("express");
                setShowAcceptModal2(false);
              }}
            >
              <h2 className={styles.dashboard_days_select1}>Express</h2>
              <input
                checked={shipingTab === "express" ? true : false}
                type="checkbox"
                className={styles.largerCheckbox}
              />
            </div>
          </div>
        }
      />

      <ModalMob
        show={showAcceptModal4}
        close={setShowAcceptModal4}
        // closeBtn={true}
        // heading="Order Status"
        css={styles.date_margin_top}
        content={
          <div className={styles.dispatch_select}>
            <h2>Select Managers</h2>
            {managers &&
              managers.map((item, index) => (
                <div
                  key={index}
                  className={styles.flex}
                  onClick={() => {
                    setManagerName(item.name);
                    setShowAcceptModal4(false);
                  }}
                >
                  <h2 className={styles.dashboard_days_select1}>{item.name}</h2>
                  <input
                    checked={managerName === item.name ? true : false}
                    type="checkbox"
                    className={styles.largerCheckbox}
                  />
                </div>
              ))}
          </div>
        }
      />

      {/* <ModalMob
                show={showAcceptModal5}
                close={setShowAcceptModal5}
                css={styles.date_margin_top}
                content={
                    <div className={styles.dispatch_select}>
                        <h2 >Select Warehouse</h2>
                        {warehouses && warehouses.map((item, index) =>
                            <div className={styles.flex} key={index} onClick={() => { setWarehouseName(item.warehousename); setShowAcceptModal5(false); }}>
                                <h2 className={styles.dashboard_days_select1}>{item.warehousename}</h2>
                                <input checked={warehouseName === item.warehousename ? true : false} type='checkbox' className={styles.largerCheckbox}
                                />
                            </div>
                        )}
                    </div>
                }
            /> */}
    </React.Fragment>
  );
};

export default ReciptsMob;
