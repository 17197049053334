import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../pages/InventoryMob.module.css";
import { useAuth } from "../../components/context/Auth";
import { EyeTwo } from "../../components/icons/Icon";

const OutSideInventoryMob = () => {
  const { managerId } = useAuth();
  const navigate = useNavigate();
  // const [searchparams] = useSearchParams();
  // const page = searchparams.get("page") ? parseInt(searchparams.get("page")) : 1;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [pages, setPages] = useState("");

  useEffect(() => {
    if (managerId) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "inventory/outsideProducts",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setLoading(false);
            setData(result.data);
          }
          // if (result.pages) {
          //     setPages(result.pages ? result.pages : "")
          //   }
        })
        .catch((error) => {});
    }
  }, [managerId]);

  return (
    <React.Fragment>
      <div className={styles.mob_flex}>
        <h1 className={styles.main_heading}>Outside Inventory</h1>
      </div>
      {loading ? (
        <div className={"loadingMain"}>
          <img src="/wallicon.gif" alt="walliconGIF" />
        </div>
      ) : (
        data &&
        data.map((item, index) => (
          <div
            className={styles.box}
            key={index}
            onClick={() =>
              navigate(
                "/outsideinventory/" +
                  item.product_name.replace(/\s/g, "-").toLowerCase() +
                  "?product_id=" +
                  item._id
              )
            }
          >
            <div className={styles.mob_flex}>
              <h6 className={styles.product}>{item.product_name}</h6>
              <div className={styles.status_div2}>
                <input
                  type="radio"
                  defaultChecked={item.status === "Active" ? true : false}
                  className={styles.status_active}
                />
                &nbsp;
                <span className={styles.status}>Active</span>
                &nbsp;
              </div>
            </div>
            <div className={styles.flex}>
              <div className={styles.productdiv}>
                <img
                  src="/assets/noimage.jpg"
                  alt=""
                  className={styles.product_img}
                />
              </div>

              <div>
                <div className={styles.box_div}>
                  <div className={styles.outSide_boxcoll}>Collection</div>
                </div>
                <div className={styles.box_div}>
                  <div className={styles.outSide_boxcoll}>Items</div>
                </div>
                <div className={styles.box_div}>
                  <div className={styles.outSide_boxcoll}>Qty</div>
                </div>
              </div>

              <div>
                <EyeTwo />
              </div>
            </div>
          </div>
        ))
      )}
    </React.Fragment>
  );
};

export default OutSideInventoryMob;
