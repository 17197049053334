import React, { useEffect, useState } from "react";
import { useAuth } from "../../components/context/Auth";
import styles from "../pages/InventoryMob.module.css";
// import { LargeEyeIcon, Setting, ThreeDots } from '../../components/icons/Icon'
import { useSearchParams } from "react-router-dom";
// import Paginantion from '../../components/Paginantion'
// import { AddItemModal } from '../ordermanager/SaleOrder'
// import ModalMob from '../components/ModalMob'
import {
  DeleteIcon,
  DemoImageIcon,
  Edit,
  UpLoadFile,
} from "../../components/icons/Icon";
import { GreenButton, RedOutButton } from "../../components/Buttons";
import DispatchModal from "../components/DispatchModal";

const OutSideIvtCollInfomob = () => {
  const { managerId } = useAuth();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page")
    ? parseInt(searchParams.get("page"))
    : 1;
  // const navigate = useNavigate()
  const collectionId = searchParams.get("collection_id");
  // const [loading, setLoading] = useState(false)
  const [showAddItem, setShowAddItem] = useState(false);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  // const [state, setState] = React.useState({
  //     totalPages: "",
  //     currentPage: 1
  // });

  // const { totalPages, } = state;
  const [msgShow, setMsgShow] = useState("");
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [addNewItemData, setAddNewItemData] = useState({
    product_name: "",
    product_Id: "",
    collection_name: "",
    collection_id: "",
    itemNo: "",
    unit: "",
    description: "",
    products: [],
    collections: [],
  });

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    if (managerId) {
      // setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        collection_id: collectionId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      // fetch(process.env.REACT_APP_URL + "order/newItem", requestOptions)
      fetch(
        process.env.REACT_APP_URL + `inventory/outsideitems?page=${page}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            // setLoading(false)
            setData(result.data);
          }
          // if (result.items) {
          //     setState(prevState => ({
          //         ...prevState,
          //         totalPages: result.pages ? result.pages : "",
          //     }))
          // }
        })
        .catch((error) => {});
    }
  }, [managerId, collectionId, page, reload]);

  const handleSubmitAddItem = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      handleAddItemApi();
    }
  };

  const handleAddItemApi = () => {
    setReload(true);
    setMsgShow("");
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("image", file ? file : "");
    formdata.append("product_Id", addNewItemData.product_Id);
    formdata.append("collection_id", addNewItemData.collection_id);
    formdata.append("product_name", addNewItemData.product_name);
    formdata.append("collection_name", addNewItemData.collection_name);
    formdata.append("unit", addNewItemData.unit);
    formdata.append("price", addNewItemData.price);
    formdata.append("description", addNewItemData.description);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + `order/addNewItem/${addNewItemData.itemNo}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setMsgShow(result.msg);
          setShowAcceptModal(true);
          setShowAddItem(false);
          setReload(false);
        } else {
          setMsgShow(result.msg);
          setShowAcceptModal(true);
        }
      })
      .catch((error) => {});
  };

  return (
    <React.Fragment>
      <div className={styles.main_Container}>
        <h1 className={styles.main_heading1}>OutSide Items</h1>
        {data &&
          data.map((item, index) => (
            <div
              className={styles.outSidebox}
              key={index}
              onClick={() => {
                setShowAddItem(true);
                setAddNewItemData(item);
              }}
            >
              <div className={styles.mob_flex}>
                <h6 className={styles.product}>Item No. {item.itemNo}</h6>
                <div className={styles.status_div2}>
                  <span className={styles.outSidebox_date}>
                    {item.createdAt.slice(8, 10) +
                      "-" +
                      item.createdAt.slice(5, 7) +
                      "-" +
                      item.createdAt.slice(0, 4)}{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                    <Edit color="gray" className={styles.edit_Icon} />
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-start mt-1">
                <div className={styles.outSide_imagediv}>
                  <img
                    src="/assets/noimage.jpg"
                    alt=""
                    className={styles.product_img}
                  />
                </div>

                <div className="d-flex flex-column w-100 justify-content-center">
                  <div className="d-flex justify-content-center mb-1">
                    <div className={styles.outside_text}>Price</div>
                    <input
                      type="number"
                      className={styles.outside_box}
                      value={item.price}
                      onChange={(e) => {}}
                      disabled
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className={styles.outside_text}>Unit</div>
                    <input
                      type="text"
                      className={styles.outside_box}
                      value={item.unit}
                      onChange={(e) => {}}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {item.description && (
                <div className={styles.outsideProduct_description}>
                  <div className={styles.outsourcetext + " my-1"}>
                    Description
                  </div>
                  <input
                    type="text"
                    className={styles.outside_description}
                    value={item.description}
                    onChange={(e) => {}}
                    disabled
                  />
                </div>
              )}
            </div>
          ))}
      </div>

      <DispatchModal
        show={showAddItem}
        close={setShowAddItem}
        closeBtn={true}
        heading="Add New Item"
        content={
          <form onSubmit={handleSubmitAddItem}>
            <div className={styles.inputFields_div}>
              <label className={styles.inputFields_heading}>Product Name</label>
              <input
                type="text"
                list="prodcuts"
                className={styles.inputField_name}
                value={addNewItemData.product_name}
                onChange={(e) =>
                  setAddNewItemData({
                    ...addNewItemData,
                    product_name: e.target.value,
                  })
                }
                autoFocus
              />
              <datalist id="prodcuts">
                {addNewItemData.products &&
                  addNewItemData.products.map((item, index) => (
                    <option key={index}>{item.product_name}</option>
                  ))}
              </datalist>
            </div>

            <div className="d-flex">
              <div className={styles.inputFields_div}>
                <label className={styles.inputFields_heading}>
                  Collection Name
                </label>
                <input
                  type="text"
                  list="collections"
                  className={styles.inputField_item_name}
                  value={addNewItemData.collection_name}
                  onChange={(e) =>
                    setAddNewItemData({
                      ...addNewItemData,
                      collection_name: e.target.value,
                    })
                  }
                />
                <datalist id="collections">
                  {addNewItemData.collections &&
                    addNewItemData.collections.map((item, index) => (
                      <option key={index}>{item.collection_name}</option>
                    ))}
                </datalist>
              </div>
              &nbsp;&nbsp;
              <div className={styles.inputFields_div}>
                <label className={styles.inputFields_heading}>
                  Item Number
                </label>
                <input
                  type="text"
                  className={styles.inputField_item_name}
                  placeholder="Enter Item Number"
                  value={addNewItemData.itemNo}
                  onChange={(e) =>
                    setAddNewItemData({
                      ...addNewItemData,
                      itemNo: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>

            <div className="d-flex">
              <div className={styles.inputFields_div}>
                <label className={styles.inputFields_heading}>Price</label>
                <input
                  type="number"
                  className={styles.inputField_item_name}
                  placeholder="Enter Price"
                  value={addNewItemData.price}
                  onChange={(e) =>
                    setAddNewItemData({
                      ...addNewItemData,
                      price: e.target.value,
                    })
                  }
                  required
                />
              </div>
              &nbsp;&nbsp;
              <div className={styles.inputFields_div}>
                <label className={styles.inputFields_heading}>Unit</label>
                <input
                  type="text"
                  list="units"
                  className={styles.inputField_item_name}
                  value={addNewItemData.unit}
                  onChange={(e) =>
                    setAddNewItemData({
                      ...addNewItemData,
                      unit: e.target.value,
                    })
                  }
                />
                <datalist id="units">
                  <option value="Roll">Roll</option>
                  <option value="Pcs">Pcs</option>
                  <option value="Box">Box</option>
                  <option value="Sq Meter">Sq Meter</option>
                  <option value="Meter">Meter</option>
                  <option value="Sq Feet">Sq Feet</option>
                  <option value="Feet">Feet</option>
                  <option value="Kg">Kg</option>
                </datalist>
              </div>
            </div>

            <div className={styles.inputFields_div}>
              <label className={styles.inputFields_heading}>
                Description{" "}
                <span className={styles.unbold_text}>(optional)</span>
              </label>
              <input
                type="text"
                className={styles.inputField_name}
                placeholder="Enter Description"
                value={addNewItemData.description}
                onChange={(e) =>
                  setAddNewItemData({
                    ...addNewItemData,
                    description: e.target.value,
                  })
                }
              />
            </div>

            <div className={styles.uploadImage_div}>
              <label onChange={handleChange} className={styles.uploadImage_btn}>
                <input name="" type="file" id="formId" hidden />
                <UpLoadFile />
                &nbsp;Upload Image
              </label>
              {file ? (
                <div className={styles.demoImage_div}>
                  <img src={file} alt="" className={styles.modalItem_Image} />
                </div>
              ) : (
                <div className={styles.demoImage_div}>
                  <div className={styles.demoImage_bgc}>
                    <DemoImageIcon />
                  </div>
                </div>
              )}
              <div className={styles.delete_div} onClick={() => setFile()}>
                <span>
                  <DeleteIcon />
                </span>
                <span>Delete</span>
              </div>
            </div>

            <div className={styles.modalButton_div}>
              <RedOutButton
                title="CANCEL"
                css={styles.add_btn_css}
                handleSubmit={() => {
                  setShowAddItem(false);
                }}
              />
              <GreenButton
                btnType="submit"
                css={styles.add_btn_css}
                title="ADD"
                handleSubmit={() => {}}
              />
            </div>
          </form>
        }
      />

      <DispatchModal
        show={showAcceptModal}
        close={setShowAcceptModal}
        closeBtn={true}
        heading="ADD DEALER STATUS"
        content={
          <div className={styles.text_center}>
            <p className={styles.message}>{msgShow}</p>
            <div className={styles.modalButton_div}>
              <RedOutButton
                title="CANCEL"
                css={styles.add_btn_css}
                handleSubmit={() => setShowAcceptModal(false)}
              />
              <GreenButton
                title="OK"
                css={styles.add_btn_css}
                handleSubmit={() => {
                  setShowAcceptModal(false);
                }}
              />
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
};

export default OutSideIvtCollInfomob;
