import { CancelIcon2, TestTopAddDealerIcon } from "../../icons/Icon";
import { CloseIcon } from "../../icons/Icon2";
import Button from "../Dealers/Button";
import InputTag from "../FormComponents/InputTag";
import FileUpload from "./FileUpload";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { useEffect, useRef, useState } from "react";
import fetchData from "../../../APIsControll/apiControll";
import { handleFileData } from "../../../APIsControll/apiControl2";
import { useToast } from "../Toaster/Toaster";
import VerticalDropDown2 from "../FormComponents/VerticalDropDown2";
import MultipleSelectDropDown from "../FormComponents/MultipleSelectDropDown";

const AddNewItemModal = ({
  mode,
  managerId,
  isModalOpen,
  handleCloseModal,
}) => {
  const initialNewItemData = {
    product_name: "",
    product_Id: "",
    collection_name: "",
    collection_id: "",
    description: "",
    unit: "",
    price: "",
    weight: "",
    vm: "",
    itemNo: "",
    image: "",
    products: [],
    collections: [],
  };
  const { show } = useToast();
  const inpRefs = useRef([]);
  const [image, setImage] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [newItemData, setNewItemData] = useState(initialNewItemData);

  const [collectionDD, setCollectionDD] = useState(false);
  const [unitDD, setUnitDD] = useState(false);
  const [warehouseDD, setWarehouseDD] = useState(false);
  const [fileUploadFocus, setFileUploadFocus] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const apiData = {
          managerId: managerId,
          product_id: newItemData.product_Id,
        };
        const result = await fetchData("content/getProductsDetails", apiData);
        if (result.status === "success") {
          setNewItemData((prevState) => ({
            ...prevState,
            products: result.data.products ? result.data.products : [],
            collections: result.data.collections ? result.data.collections : [],
          }));
        }
      } catch (error) {}
    };

    const getWarehouses = async () => {
      try {
        const result = await fetchData(`content/getWarehouse/${managerId}`);
        setWarehouses(
          result.data.map((r) => ({
            label: r.warehousename,
            value: r._id,
          }))
        );
      } catch (error) {
        //console.log("error while getting warehouse ", error);
      }
    };
    if (managerId && isModalOpen) {
      getProducts();
      getWarehouses();
    }
  }, [isModalOpen, managerId, newItemData.product_Id]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (fileUploadFocus) {
        if (event.key === "Enter") {
          // setTimeout(() => {
          submitForm();
          // }, 200);
          return;
        }
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadFocus]);

  const handleAddItem = async () => {
    const formdata = new FormData();
    formdata.append("product_name", newItemData.product_name);
    formdata.append("product_id", newItemData.product_Id);
    formdata.append("collection_name", newItemData.collection_name);
    formdata.append("collection_id", newItemData.collection_id);
    formdata.append("description", newItemData.description);
    formdata.append("unit", newItemData.unit);
    formdata.append("price", newItemData.price);
    formdata.append("weight", newItemData.weight);
    formdata.append("vm", newItemData.vm);
    formdata.append("image", image ? image : "");
    formdata.append(
      "selectedWarehouses",
      JSON.stringify(
        selectedWarehouses.map((w) => ({
          _id: w.value,
          warehousename: w.label,
        }))
      )
    );
    try {
      const result = await handleFileData(
        `content/addOutsideItems/${managerId}/${newItemData.itemNo}`,
        formdata
      );
      if (result.status === "success") {
        show(result.msg, "success");
      }
      if (result.status === "falied") {
        show(result.msg, "error");
      }
    } catch (error) {}
  };

  const isFormValid = () => {
    for (const key in newItemData) {
      if (key === "description") continue;
      if (key === "image") continue;
      if (Array.isArray(newItemData[key])) {
        if (newItemData[key].length === 0) return false;
      } else if (newItemData[key] === "") {
        return false;
      }
    }
    return true;
  };

  const submitForm = () => {
    if (!isFormValid()) {
      show("Please fill in all fields before submitting.", "warning");
      return;
    } else {
      handleAddItem();
      resetFields();
      handleCloseModal();
    }
  };

  const resetFields = () => {
    setNewItemData(initialNewItemData);
    setImage("");
    setSelectedWarehouses([]);
  };

  const handleProductSelect = (selectedOption) => {
    setNewItemData({
      ...newItemData,
      product_name: selectedOption.label,
      product_Id: selectedOption.value,
      collection_name: "",
      collection_id: "",
    });
    setCollectionDD(true);
  };

  const handleCollectionSelect = (selectedOption) => {
    setNewItemData({
      ...newItemData,
      collection_name: selectedOption.label,
      collection_id: selectedOption.value,
    });
    if (inpRefs?.current && inpRefs?.current[0]) {
      inpRefs?.current[0]?.focus();
    }
  };

  const handleFileChange = (file) => {
    setImage(file);
    setNewItemData({
      ...newItemData,
      image: file,
    });
  };

  const units = [
    { label: "Roll", value: "Roll" },
    { label: "Pcs", value: "Pcs" },
    { label: "Box", value: "Box" },
    { label: "Sq Meter", value: "Sq Meter" },
    { label: "Meter", value: "Meter" },
    { label: "Sq Feet", value: "Sq Feet" },
    { label: "Feet", value: "Feet" },
    { label: "Kg", value: "Kg" },
  ];

  return (
    <div
      className={styles.add_new_item_modal}
      style={{ backgroundColor: mode && "#232529" }}
    >
      <div>
        <p style={{ color: mode && "#FFFFFF" }}> Add New Item</p>
        <div style={{ cursor: "pointer" }} onClick={handleCloseModal}>
          <CloseIcon mode={mode} />
        </div>
      </div>

      <div>
        <VerticalDropDown2
          options={newItemData.products.map((v) => ({
            label: v.product_name,
            value: v._id,
          }))}
          label={"Product Name"}
          star
          value={{
            label: newItemData.product_name,
            value: newItemData.product_Id,
          }}
          onchange={handleProductSelect}
          height={"37px"}
          fontSize={"12px"}
          padding={"6px"}
          dropDownOpen={isModalOpen}
        />
        <VerticalDropDown2
          options={newItemData.collections.map((v) => ({
            label: v.collection_name,
            value: v._id,
          }))}
          label={"Collection Name"}
          star
          value={{
            label: newItemData.collection_name,
            value: newItemData.collection_id,
          }}
          onchange={handleCollectionSelect}
          height={"37px"}
          fontSize={"12px"}
          padding={"6px"}
          dropDownOpen={collectionDD}
        />
      </div>
      <div>
        <InputTag
          labelText={"Item Number"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"text"}
          maxLength={20}
          star
          value={newItemData.itemNo}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              itemNo: e.target.value,
            })
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setNewItemData({
                ...newItemData,
                itemNo: e.target.value,
              });
              setUnitDD(true);
              inpRefs.current[0].blur();
            }
          }}
          ref={(el) => (inpRefs.current[0] = el)}
        />
        <VerticalDropDown2
          height={"37px"}
          fontSize={"12px"}
          label={"Unit"}
          star
          options={units}
          value={{
            label: newItemData.unit,
            value: newItemData.unit,
          }}
          onchange={(option) => {
            setNewItemData((prevState) => ({
              ...prevState,
              unit: option.value,
            }));
            inpRefs.current[1].focus();
          }}
          dropDownOpen={unitDD}
        />
      </div>
      <div>
        <InputTag
          labelText={"Price"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"number"}
          maxLength={10}
          star
          value={newItemData.price}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              price: e.target.value,
            })
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setNewItemData({
                ...newItemData,
                price: e.target.value,
              });
              inpRefs.current[2].focus();
              inpRefs.current[1].blur();
            }
          }}
          ref={(el) => (inpRefs.current[1] = el)}
        />
        <InputTag
          labelText={"Description"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"text"}
          maxLength={1000}
          value={newItemData.description}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              description: e.target.value,
            })
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setNewItemData({
                ...newItemData,
                description: e.target.value,
              });
              inpRefs.current[3].focus();
              inpRefs.current[2].blur();
            }
          }}
          ref={(el) => (inpRefs.current[2] = el)}
        />
      </div>
      <div>
        <InputTag
          labelText={"Weight"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"number"}
          maxLength={10}
          star
          value={newItemData.weight}
          onChange={(e) => {
            let value = e.target.value;
            if (value.startsWith(".")) {
              value = 0 + value;
            }
            setNewItemData({
              ...newItemData,
              weight: value,
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              let value = e.target.value;
              if (value.startsWith(".")) {
                value = 0 + value;
              }
              setNewItemData({
                ...newItemData,
                weight: value,
              });
              inpRefs.current[4].focus();
              inpRefs.current[3].blur();
            }
          }}
          ref={(el) => (inpRefs.current[3] = el)}
        />
        <InputTag
          labelText={"Volume (CBM)"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"number"}
          maxLength={10}
          star
          value={newItemData.vm}
          onChange={(e) => {
            let value = e.target.value;
            if (value.startsWith(".")) {
              value = 0 + value;
            }
            setNewItemData({
              ...newItemData,
              vm: value,
            });
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              let value = e.target.value;
              if (value.startsWith(".")) {
                value = 0 + value;
              }
              setNewItemData({
                ...newItemData,
                vm: value,
              });

              setWarehouseDD(true);
              inpRefs.current[4].blur();
            }
          }}
          ref={(el) => (inpRefs.current[4] = el)}
        />
      </div>
      <div>
        <MultipleSelectDropDown
          options={warehouses}
          label={"Select Warehouses"}
          star
          value={selectedWarehouses}
          onchange={setSelectedWarehouses}
          multiple={true}
          height={"37px"}
          fontSize={"12px"}
          padding={"6px"}
          dropDownOpen={warehouseDD}
          selectionCompleted={() => {
            setFileUploadFocus(true);
          }}
        />
      </div>
      <FileUpload
        mode={mode}
        image={image}
        setImage={setImage}
        onFileChange={handleFileChange}
        focus={fileUploadFocus}
      />
      <div style={{ justifyContent: "center" }}>
        <div>
          <Button
            buttonBgColor={"#000"}
            buttonText={"CANCEL"}
            buttonTextColor={"#fff"}
            buttonIcon={<CancelIcon2 />}
            buttonClick={() => {
              resetFields();
              handleCloseModal();
            }}
          />
        </div>
        <div>
          <Button
            buttonBgColor={"#4164E3"}
            buttonText={"ADD ITEM"}
            buttonTextColor={"#fff"}
            buttonIcon={<TestTopAddDealerIcon />}
            buttonClick={(e) => submitForm(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewItemModal;
