import React, { useEffect, useRef, useState } from "react";

import { Modal, Form, Row, Col } from "react-bootstrap";
import {
  GreenButton,
  GreenOutButton,
  RedOutButton,
} from "../../components/Buttons";
import {
  CheckGreenIcon,
  DeleteIcon,
  DropDownBlueIcon,
  Edit,
  Eye,
  EyeIcon,
  ManagerDevices3Dots,
  NextIcon,
  PreviousIcon,
  Spinner,
} from "../../components/icons/Icon";
import styles from "./Delivery.module.css";
import { useNavigate } from "react-router-dom";
import Button from "../../components/updatedComponents/Dealers/Button";
import { RequestForDevice } from "../../components/icons/Icon2";
import { useAuth } from "../../components/context/Auth";
import fetchData from "../../APIsControll/apiControll";
import { ModalWrapper } from "../../exports";
import RequestModel from "../../components/updatedComponents/Manager/RequestModel";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";

const Delivery = () => {
  const navigate = useNavigate();
  const { managerId, mode } = useAuth();
  const [show1, setShow] = useState(false);

  const { show } = useToast();
  const dropdownRefs = useRef([]);
  const [deleteModal, setDeletModal] = useState(false);
  const [modelContact, setModelContact] = useState(null);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [loadingIs, setloadingIs] = useState(false);
  const [deliveryModalData, setDeliveryModalData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [courierName, setCourierName] = useState([]);
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [modelState, setModeleState] = useState(false);
  // const [courierPartner, setCourierPartner] = useState([])
  const [products, setProducts] = useState([]);
  const [fields, setFields] = useState([
    { partnerName: "", methodName: "", partnerStatus: "", products: [] },
  ]);
  const [fields1, setFields1] = useState({
    courierName: "",
    cityName: "",
    disclaimer: "",
    enableForm: "",
    status: "",
    countryDelivery: "",
    stateDelivery: "",
    cityDelivery: "",
    methodSelection: "",
  });

  const fieldRef = useRef(null);
  const [deleteItemId, setDeleteItemId] = useState();
  const [methodActive, setMethodActive] = useState();
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  // const [showPartner, setShowPartner] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // const [methods, setMethods] = useState([]);
  const [partners, setPartners] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSubDeliveryModal, setShowDeleteSubDeliveryModal] =
    useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [deletePartner, setDeletePartner] = useState("");
  const [reload, setReload] = useState();
  const [stateNames, setStateNames] = useState([]);
  const [citiesName, setCitiesName] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [view, setView] = useState(false);
  // const [viewPartner, setViewPartner] = useState()
  const [loading, setLoading] = useState({
    city: false,
    state: false,
  });
  // const [showPartnerData, setShowPartnerData] = useState({
  //     partnerId: '',
  //     partnerName: '',
  //     methodName: '',
  //     courierName: '',
  //     cityName: '',
  //     partnerStatus: '',
  //     contactNumber: '',
  //     trackingNumber: '',
  //     trackingUrl: '',
  // });

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      // "stateName": stateName
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "deliverymethod/getState", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setStates(result.data);
          // setCityName(result.data[0].cityName)
        }
      })
      .catch((error) => {})
  }, []);

  useEffect(() => {
    if (stateName) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        stateName: stateName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "deliverymethod/getCity",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setCities(result.data);
            // setCityName(result.data[0].cityName)
          }
        })
        .catch((error) => {})
    }
  }, [stateName]);
  const togleModel = (contant) => {
    setModelContact(contant);
    setModeleState(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownVisible !== null &&
        dropdownRefs.current[dropdownVisible] &&
        !dropdownRefs.current[dropdownVisible].contains(event.target)
      ) {
        setDropdownVisible(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisible]);

  const postData1 = () => {
    setRefresh(false);
    fetch(process.env.REACT_APP_URL + "deliverymethod/addDeliveryMethod", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
      },

      body: JSON.stringify({
        courierId: fields1.courierId ? fields1.courierId : "",
        courierName: fields1.courierName,
        stateName: state,
        cityName: city,
        methods: fields,
        disclaimer: fields1.disclaimer,
        enableForm: fields1.enableForm,
        status: fields1.status,
        cityDelivery: fields1.cityDelivery,
        stateDelivery: fields1.stateDelivery,
        countryDelivery: fields1.countryDelivery,
        methodSelection: fields1.methodSelection,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setFields([
            {
              partnerName: "",
              methodName: "",
              partnerStatus: "",
              products: products,
            },
          ]);
          setFields1({
            courierName: "",
            cityName: "",
            disclaimer: "",
            enableForm: "",
            status: "",
            countryDelivery: "",
            stateDelivery: "",
            cityDelivery: "",
            methodSelection: "",
          });
          getCourierName();
          setShow(false);
          setRefresh(true);
          alert(result.msg);
          setCity();
          setState();
        }
      });
  };

  useEffect(() => {
    /* eslint-disable */
    if (cityName && stateName) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        // "courierId": id,
        // "courierId":'656869c3529a7075e37fb99e',
        cityName: cityName,
        stateName: stateName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "deliverymethod/getDeliveryMethod",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            // setProducts(result.products)
            // setFields(result.data.partners)
            // if (!result.data.partners) {
            setCourierName(result.data);
            // }
          }
        })
        .catch((error) => {})
    } else {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        // "courierId": id,
        // "courierId":'656869c3529a7075e37fb99e',
        // "cityName": "Lucknow",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "deliverymethod/getDeliveryMethod",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setProducts(result.products);
            setFields(result.data.methods);
            setCourierName(result.data);

            if (!result.data.methods) {
              // setCourierName(result.data)
            } else {
              // setCourierPartner(result.data.partners);
              // setFields(result.data.partners)
              if (result.data.methods.length !== 0) {
                const transformedData = result.data.methods.map((item) => ({
                  contactNumber: item.contactNumber ? item.contactNumber : "",
                  maxWeight: item.maxWeight ? item.maxWeight : "",
                  minimumPrice: item.minimumPrice ? item.minimumPrice : "",
                  partnerName: item.partnerName ? item.partnerName : "",
                  methodName: item.methodName ? item.methodName : "",
                  partnerStatus: item.partnerStatus ? item.partnerStatus : "",
                  methodStatus: item.methodStatus ? item.methodStatus : "",
                  disclaimerStatus: item.disclaimerStatus
                    ? item.disclaimerStatus
                    : "",
                  price: item.price ? item.price : "",
                  products:
                    item.products.length !== 0
                      ? [...item.products, ...products].filter(
                          (product, index, self) =>
                            index ===
                            self.findIndex(
                              (p) =>
                                p.product_id ===
                                product.product_id.replace("OUTSIDE - ", "")
                            )
                        )
                      : products,
                  trackingNumber: item.trackingNumber
                    ? item.trackingNumber
                    : "",
                  trackingUrl: item.trackingUrl ? item.trackingUrl : "",
                  unit: item.unit ? item.unit : "",
                  maxLimit: item.maxLimit ? item.maxLimit : "",
                  disclaimer: item.disclaimer ? item.disclaimer : "",
                  maxLimitStatus: item.maxLimitStatus
                    ? item.maxLimitStatus
                    : "",
                  maxVm: item.maxVm ? item.maxVm : "",
                }));
                setFields(transformedData);
              }
              setCity(result.data.cityName);
              setFields1({
                courierId: result.data._id,
                courierName: result.data.courierName,
                cityName: result.data.cityName,
                disclaimer: result.data.disclaimer,
                enableForm: result.data.enableForm,
                status: result.data.status,
                countryDelivery: result.data.countryDelivery,
                stateDelivery: result.data.stateDelivery,
                cityDelivery: result.data.cityDelivery,
                methodSelection: result.data.methodSelection,
              });
            }
          }
        })
        .catch((error) => {})
    }
  }, [refresh, cityName, stateName]);

  // useEffect(() => {
  //     /* eslint-disable */
  //     getCourierName()
  // }, [refresh,])

  // useEffect(() => {
  //     if (showPartnerData.cityName && showPartnerData.courierName) {
  //         var myHeaders = new Headers();
  //         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //         myHeaders.append("Content-Type", "application/json");

  //         var raw = JSON.stringify({
  //             // "courierId": id,
  //             "cityName": showPartnerData.cityName,
  //             "courierName": showPartnerData.courierName
  //         });

  //         var requestOptions = {
  //             method: 'POST',
  //             headers: myHeaders,
  //             body: raw,
  //             redirect: 'follow'
  //         };

  //         fetch(process.env.REACT_APP_URL + "deliverymethod/getDeliveryMethod", requestOptions)
  //             .then(response => response.json())
  //             .then(result => {
  //                 if (result.status === 'success') {
  //                     if (result.data.methods && result.data.methods.length !== 0) {
  //                         setMethods(result.data.methods)
  //                     }
  //                 }
  //             })
  //             .catch(error => //console.log('error', error));
  //     }
  // }, [showPartnerData.cityName, showPartnerData.courierName])

  const getCourierName = (id, cityName, stateName) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      courierId: id,
      // "courierId":'656869c3529a7075e37fb99e',
      cityName: cityName,
      stateName: stateName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "deliverymethod/getDeliveryMethod",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setProducts(result.products);
          setFields(result.data.methods);

          if (!result.data.methods) {
            // setCourierName(result.data)
          } else {
            // setCourierPartner(result.data.partners);
            // setFields(result.data.partners)
            if (result.data.methods.length !== 0) {
              const transformedData = result.data.methods.map((item) => ({
                contactNumber: item.contactNumber ? item.contactNumber : "",
                maxWeight: item.maxWeight ? item.maxWeight : "",
                minimumPrice: item.minimumPrice ? item.minimumPrice : "",
                partnerName: item.partnerName ? item.partnerName : "",
                methodName: item.methodName ? item.methodName : "",
                partnerStatus: item.partnerStatus ? item.partnerStatus : "",
                methodStatus: item.methodStatus ? item.methodStatus : "",
                disclaimerStatus: item.disclaimerStatus
                  ? item.disclaimerStatus
                  : "",
                price: item.price ? item.price : "",
                products:
                  item.products.length !== 0
                    ? [...item.products, ...products].filter(
                        (product, index, self) =>
                          index ===
                          self.findIndex(
                            (p) =>
                              p.product_id ===
                              product.product_id.replace("OUTSIDE - ", "")
                          )
                      )
                    : products,
                trackingNumber: item.trackingNumber ? item.trackingNumber : "",
                trackingUrl: item.trackingUrl ? item.trackingUrl : "",
                unit: item.unit ? item.unit : "",
                maxLimit: item.maxLimit ? item.maxLimit : "",
                disclaimer: item.disclaimer ? item.disclaimer : "",
                maxLimitStatus: item.maxLimitStatus ? item.maxLimitStatus : "",
                maxVm: item.maxVm ? item.maxVm : "",
              }));
              setFields(transformedData);
            }
            setCity(result.data.cityName);
            setFields1({
              courierId: result.data._id,
              courierName: result.data.courierName,
              cityName: result.data.cityName,
              disclaimer: result.data.disclaimer,
              enableForm: result.data.enableForm,
              status: result.data.status,
              countryDelivery: result.data.countryDelivery,
              stateDelivery: result.data.stateDelivery,
              cityDelivery: result.data.cityDelivery,
              methodSelection: result.data.methodSelection,
            });
          }
        }
      })
      .catch((error) => {})
  };

  const deleteDeliveryMethod = () => {
    if (deleteItemId) {
      setRefresh(false);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        courierId: deleteItemId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "deliverymethod/deleteDeliveryMethod",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setDeletModal(false);
            setDeleteItemId("");
            getCourierName();
            setDeleteText("");
            setRefresh(true);
            // setCourierPartner([])
            alert("Delivery Method Deleted Successfully");
          }
        })
        .catch((error) => {})
    }
  };

  const [validated, setValidated] = useState(false);

  const handleChangeField = (e, index) => {
    const { name, value } = e.target;
    const list = [...fields];
    list[index][name] = value;
    setFields(list);
  };

  function handleAdd() {
    const values = [...fields];
    const modifiedProducts = products.map((product) => ({
      ...product,
      status: "Disable",
    }));
    values.push({
      partnerName: "",
      methodName: "",
      partnerStatus: "",
      products: modifiedProducts,
      preference: "",
      maxLimit: 1,
      disclaimerStatus: "Disable",
    });
    setFields(values);
    fieldRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const [deleteIndex, setDeleteIndex] = useState(null); // Step 1: State to track the index of the item to be deleted
  // function handleRemove(i) {
  //     const values = [...fields];
  //     values.splice(i, 1);
  //     setFields(values);
  // }
  function handleRemove(i) {
    setDeleteIndex(i); // Step 2: Set the index of the item to be deleted
    setShowDeleteSubDeliveryModal(true); // Open the delete modal
  }

  function confirmDelete() {
    const values = [...fields];
    values.splice(deleteIndex, 1);
    setFields(values);
    setShowDeleteSubDeliveryModal(false); // Close the delete modal
  }

  // function handleRemove(i) {
  //     const values = [...fields];
  //     values.splice(i, 1);
  //     setFields(values);
  // }

  const handleChangeField1 = (e) => {
    const { name, value } = e.target;
    setFields1({
      ...fields1,
      [name]: value,
    });

    if (name === "cityDelivery" && value === "Active") {
      setFields1((prevFields) => ({
        ...prevFields,
        stateDelivery: "Disable",
        countryDelivery: "Disable",
      }));
    }
    if (name === "stateDelivery" && value === "Active") {
      setFields1((prevFields) => ({
        ...prevFields,
        cityDelivery: "Disable",
        countryDelivery: "Disable",
      }));
    }
    if (name === "countryDelivery" && value === "Active") {
      setFields1((prevFields) => ({
        ...prevFields,
        cityDelivery: "Disable",
        stateDelivery: "Disable",
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      postData1();
    }

    setValidated(true);
  };

  const modifiedProducts = products.map((product) => ({
    ...product,
    status: "Disable",
  }));
  // //console.log('modifiedProducts', modifiedProducts);
  // //console.log('fields', fields);
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevCard = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + courierName.length) % courierName.length
    );
  };

  const nextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % courierName.length);
  };

  const showNextButton =
    currentIndex < courierName.length - (courierName.length > 7 ? 7 : 6);

  const validationPartnerDetail = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      submitPartnerDetail();
    }
    setValidated(true);
  };

  // const submitPartnerDetail = () => {
  //     setReload(false)
  //     var myHeaders = new Headers();
  //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //         "partnerId": showPartnerData.partnerId,
  //         "partnerName": showPartnerData.partnerName,
  //         "courierName": showPartnerData.courierName,
  //         "methodName": showPartnerData.methodName,
  //         "cityName": showPartnerData.cityName,
  //         "partnerStatus": showPartnerData.partnerStatus,
  //         "contactNumber": showPartnerData.contactNumber,
  //         "trackingNumber": showPartnerData.trackingNumber,
  //         "trackingUrl": showPartnerData.trackingUrl
  //     });

  //     var requestOptions = {
  //         method: 'POST',
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: 'follow'
  //     };

  //     fetch(process.env.REACT_APP_URL + "deliverymethod/addUpDeliveryPartners", requestOptions)
  //         .then(response => response.json())
  //         .then(result => {
  //             if (result.status === 'success') {
  //                 // setShowPartner(false)
  //                 setReload(true)
  //                 alert(result.msg)
  //             }
  //         })
  //         .catch(error => //console.log('error', error));
  // }
  // //console.log('showPartnerData', showPartnerData);

  useEffect(() => {
    if (stateName && cityName) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        partnerId: "",
        courierId: deliveryMethod,
        stateName: stateName,
        cityName: cityName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "deliverymethod/getPartner",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setPartners(result.data);
          } else {
            setPartners([]);
            alert(result.msg);
          }
        })
        .catch((error) => {})
    }
  }, [reload, deliveryMethod, cityName]);

  const handleDeletePartner = () => {
    if (deletePartner) {
      setReload(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        partnerId: deletePartner,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_URL + "deliverymethod/delPartner",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setDeletePartner("");
            setDeleteText("");
            setShowDeleteModal(false);
            setReload(false);
            alert(result.msg);
          }
        })
        .catch((error) => {})
    }
  };

  useEffect(() => {
    setLoading({ state: true });
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      keyword: "",
      statename: "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          if (result.states) {
            setLoading({ state: false });
            setStateNames(result.states);
          }
        }
      })
      .catch((error) => {})
  }, []);

  useEffect(() => {
    if (state && !fields1.cityName) {
      setLoading({ city: true });
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        keyword: "",
        statename: state,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            if (result.districts) {
              setLoading({ city: false });
              setCitiesName(result.districts);
            }
          }
        })
        .catch((error) => {})
    }
  }, [state]);

  useEffect(() => {
    if (city) {
      // setLoading({ city: true })
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        keyword: city,
        statename: "",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            if (result.states.length !== 0) {
              // setLoading({ city: false })
              setState(result.states[0]);
            }
          }
        })
        .catch((error) => {})
    }
  }, [city]);

  // const getAvailablePreferences = (currentIndex) => {
  //     const selectedPreferences = fields.map((field, idx) => idx !== currentIndex ? field.preference : null);
  //     return Array.from({ length: fields.length }, (_, i) => i + 1)
  //         .filter(pref => !selectedPreferences.includes(pref.toString()));
  // };

  const selectAllProducts = () => {
    const allProductsSelected = fields.every((field) =>
      field.products.every((product) => product.status === "Active")
    );

    const updatedFields = fields.map((field) => ({
      ...field,
      products: field.products.map((product) => ({
        ...product,
        status: allProductsSelected ? "Disable" : "Active",
      })),
    }));

    setFields(updatedFields);
  };

  const toggleDropdown = (index) => {
    setDropdownVisible((prev) => (prev === index ? null : index));
  };
  const getDeliveryPatner = async () => {
    try {
      const payload = { managerId: managerId };
      const res = await fetchData(`deliveryPartner/getalldecive`, payload);
      if (res.status === "success") {
        setDeliveryModalData(res.pendingDevices);
      }
      if (res.status === "failed") {
        setDeliveryModalData([]);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    getDeliveryPatner();
  }, []);

  const handleOptionClick = async (option, data) => {
    if (option === "Active") {
      try {
        setloadingIs(true);
        const payload = {
          deviceId: data.deviceId,
          loginId: data.loginId,
          managerId: managerId,
        };
        const res = await fetchData(`deliveryPartner/acceptdecive`, payload);
        if (res.status === "success") {
          show(res.msg, "success");
          setModeleState(false);
          getDeliveryPatner();
        }
        if (res.status === "failed") {
          show(res.msg, "error");
        }
      } catch (error) {
        show("Error", "error");
      } finally {
        setloadingIs(false);
      }
    }
    if (option === "Disabled") {
      //console.log("data", data);
    }

    setDropdownVisible(null);
  };

  return (
    <React.Fragment>
      <ModalWrapper
        isOpen={deliveryModal}
        onClose={() => setDeliveryModal(false)}
      >
        <div
          className={styles.continerModel}
          style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}
        >
          <div
            className={styles.continerModelHader}
            style={{
              backgroundColor: mode ? "#232529" : "#D9DCE4",
              color: mode ? "#fff" : "#000",
              fontWeight: "600",
            }}
          >
            <div>Login ID</div>
            <div>Name</div>
            <div>Device ID</div>
            <div>Device OS</div>
            <div>Device Name</div>
            <div>Status</div>
            <div>Action</div>
          </div>
          <div
            className={styles.continerModelHader}
            style={{ color: mode ? "#fff" : "#000" }}
          >
            {deliveryModalData.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {" "}
                <span>No data Found </span>
              </div>
            ) : (
              deliveryModalData.map((e, index) => (
                <>
                  <div
                    style={{ backgroundColor: mode ? "#1B1D21" : "#fff" }}
                    className={styles.coll1}
                  >
                    {e?.loginId}
                  </div>
                  <div
                    style={{ backgroundColor: mode ? "#1B1D21" : "#fff" }}
                    className={styles.coll}
                  >
                    {e?.partnerName}
                  </div>
                  <div
                    style={{ backgroundColor: mode ? "#1B1D21" : "#fff" }}
                    className={styles.coll}
                  >
                    {e?.deviceId}
                  </div>
                  <div
                    style={{ backgroundColor: mode ? "#1B1D21" : "#fff" }}
                    className={styles.coll}
                  >
                    {e?.deviceOS}
                  </div>
                  <div
                    style={{ backgroundColor: mode ? "#1B1D21" : "#fff" }}
                    className={styles.coll}
                  >
                    {e?.deviceName}
                  </div>
                  <div
                    style={{
                      backgroundColor: mode ? "#1B1D21" : "#fff",
                      color: "#FF9B04",
                    }}
                    className={styles.coll}
                  >
                    {e?.DeviceStatus}
                  </div>
                  <div
                    style={{ backgroundColor: mode ? "#1B1D21" : "#fff" }}
                    className={styles.coll2}
                  >
                    <span
                      className={styles.ThreeDotDevely}
                      onClick={(e) => {
                        toggleDropdown(index);
                        e.stopPropagation();
                      }}
                    >
                      <ManagerDevices3Dots
                        color={mode ? "#ffffff" : "#1C274C"}
                      />
                      {dropdownVisible === index && (
                        <div
                          className={styles.optionIs}
                          style={{
                            backgroundColor: mode ? "#232529" : "#ffff",
                            color: mode ? "#fff" : "#000",
                            border: mode ? "none" : "1px solid #E4E7EB",
                          }}
                          ref={(el) => (dropdownRefs.current[index] = el)}
                        >
                          <div
                            className={`${styles.option_item} ${
                              mode ? styles.darkHover : styles.lightHover
                            }`}
                            // onClick={handleOptionClick("Active", e)}
                            onClick={() =>
                              togleModel(
                                <RequestModel
                                  colorcode={"green"}
                                  title={"Accept"}
                                  handleClose={() => setModeleState(false)}
                                  handeSubmit={() =>
                                    handleOptionClick("Active", e)
                                  }
                                  loading={loadingIs}
                                />
                              )
                            }
                          >
                            <span>Accept</span>
                          </div>
                          <div
                            className={`${styles.option_item} ${
                              mode ? styles.darkHover : styles.lightHover
                            }`}
                            // onClick={() => handleOptionClick("Disabled", e)}
                            onClick={() =>
                              togleModel(
                                <RequestModel
                                  colorcode={"red"}
                                  title={"Decline"}
                                  handleClose={() => setModeleState(false)}
                                  handeSubmit={() =>
                                    handleOptionClick("Disabled", e)
                                  }
                                  loading={loadingIs}
                                />
                              )
                            }
                          >
                            <span>Decline</span>
                          </div>
                        </div>
                      )}
                    </span>
                  </div>
                </>
              ))
            )}
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={modelState} onClose={() => setModeleState(false)}>
        {modelContact}
      </ModalWrapper>
      <div className={`${"bgSale w-100 text-white cp"} ${styles.main_div}`}>
        <Row className="m-0 position-relative">
          <Col className="p-0">
            <h3 className="pf mb-3">Delivery Methods</h3>
          </Col>
          <Col className="d-flex justify-content-end align-items-center text-end p-0 mb-2 ">
            <div style={{ marginRight: "10px" }}>
              <Button
                buttonIcon={<RequestForDevice />}
                buttonText={"Request"}
                buttonBgColor={"#000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => setDeliveryModal(true)}
                notify={deliveryModalData.length === 0 ? false : true}
                count={deliveryModalData.length}
              />
            </div>
            <select
              value={stateName}
              onChange={(e) => setStateName(e.target.value)}
              className={styles.select_city}
            >
              <option hidden>Select State</option>
              {states &&
                states.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </select>
            <select
              value={cityName}
              onChange={(e) => setCityName(e.target.value)}
              className={styles.select_city}
            >
              <option hidden>Select City</option>
              {cities &&
                cities.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </select>
            <GreenOutButton
              title="Add Delivery Method"
              handleSubmit={() => {
                setShow(true);
                setFields([
                  {
                    partnerName: "",
                    methodName: "",
                    partnerStatus: "",
                    products: modifiedProducts,
                    maxLimit: 1,
                    disclaimerStatus: "Disable",
                  },
                ]);
                setFields1({});
                setCity();
                setState();
                setView(false);
              }}
              css="bg-transparent text-white wm_fit px-2"
            />
          </Col>
        </Row>

        {courierName && courierName.length === 0 ? (
          <h1 className="shadow py-5 text-center bgColor my-5">
            No Delivery Methods
          </h1>
        ) : (
          <div
            role="button"
            className="rounded-2 bgColor shadow pt-4 pb-4 mb-2"
          >
            {/* <h3 className="text-center mb-3">Delivery Methods</h3> */}
            <div className={styles.card_slider_container}>
              <div
                className={styles.card_slider}
                style={{ transform: `translateX(-${currentIndex * 10}%)` }}
              >
                {courierName &&
                  courierName.map((item, index) => (
                    <div
                      key={index}
                      className={styles.deliveryMethod_col}
                      onClick={() => {
                        if (!stateName || !cityName) {
                          alert("select State and City to View Partners");
                        } else {
                          setMethodActive(index);
                          setDeliveryMethod(item._id);
                        }
                      }}
                    >
                      <div
                        className={`${styles.deliveryMethod_card} ${
                          methodActive === index && styles.deliveryMethod_active
                        }`}
                      >
                        <span className={styles.label_cityName}>
                          {item.cityName}
                        </span>
                        <h5 className="pb-2 fw300 mouse">{item.courierName}</h5>
                        <div className="d-flex justify-content-between mx-3 mt-2 px-2">
                          <button
                            className={`${styles.courier_btn} text-light`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShow(true);
                              getCourierName(
                                item._id,
                                item.cityName,
                                item.stateName
                              );
                              setCity();
                              setState();
                              setView(true);
                            }}
                          >
                            <EyeIcon color="#fff" />
                            <br />
                            <div>View</div>
                          </button>
                          <button
                            className={`${styles.courier_btn} text-success`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShow(true);
                              getCourierName(
                                item._id,
                                item.cityName,
                                item.stateName
                              );
                              setCity();
                              setState();
                              setView(false);
                            }}
                          >
                            <Edit color="#1ffc33" />
                            <br />
                            <div>Edit</div>
                          </button>
                          <button
                            className={`${styles.courier_btn} text-danger`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeletModal(true);
                              getCourierName(item._id);
                              setDeleteItemId(item._id);
                            }}
                          >
                            <DeleteIcon className="redClr" />
                            <br />
                            <div>Delete</div>
                          </button>
                        </div>
                      </div>
                      {methodActive === index && (
                        <div className={styles.delivery_downArrow}>
                          <DropDownBlueIcon />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <button className={styles.sliderBtn} onClick={prevCard}>
                {currentIndex > 0 && <PreviousIcon />}
              </button>
              <button className={styles.sliderBtn} onClick={nextCard}>
                {showNextButton && <NextIcon />}
              </button>
            </div>
          </div>
        )}

        {courierName && courierName.length === 0 ? null : (
          <>
            <Row className="m-0 position-relative">
              <Col className="d-flex justify-content-end align-items-center text-end p-0 position-absolute">
                <GreenOutButton
                  title="Add Delivery Partner"
                  handleSubmit={() => {
                    // setShowPartnerData({
                    //     partnerId: '',
                    //     partnerName: '',
                    //     courierName: '',
                    //     methodName: '',
                    //     cityName: '',
                    //     partnerStatus: '',
                    //     contactNumber: '',
                    //     trackingNumber: '',
                    //     trackingUrl: '',
                    // });
                    navigate("/delivery/addpartner");
                  }}
                  css="bg-transparent text-white wm_fit px-2"
                />
              </Col>
              <Col className="p-0 text-center">
                <h3 className="pf">Delivery Partners</h3>
              </Col>
            </Row>

            <div className={styles.partner_scroll_div}>
              {partners &&
                partners.map((item, index) => (
                  <div
                    key={index}
                    className="rounded d-flex m-0 bgColor shadow px-3 py-2 align-items-center mb-2 cursor-pointer"
                    onClick={() => {
                      navigate(
                        "/delivery/addpartner?courierId=" +
                          deliveryMethod +
                          "&stateName=" +
                          stateName +
                          "&cityName=" +
                          cityName +
                          "&partnerId=" +
                          item._id
                      );
                      // setShowPartner(true);
                      // setShowPartnerData(prevState => ({ ...prevState, partnerId: item._id }));
                      // setViewPartner('view')
                    }}
                  >
                    <div className="w-25">
                      <div
                        className={`${
                          item.partnerStatus === "Disable"
                            ? styles.status_disable
                            : styles.status_active
                        } "d-flex justify-content-left align-items-center"`}
                      >
                        <input type="radio" id="Active" defaultChecked />
                        &nbsp;&nbsp;
                        <label
                          htmlFor="Active"
                          className={
                            item.partnerStatus === "Disable"
                              ? "text-danger"
                              : styles.status_active_txt
                          }
                        >
                          {item.partnerStatus}
                        </label>
                      </div>
                      <h2 className="mb-0">{item.partnerName}</h2>
                    </div>
                    <div className="text-center w-50">
                      <h2 className="mb-0">{item.methodName}</h2>
                    </div>
                    <div className="d-flex justify-content-around w-25">
                      <div className="bg-dark rounded-circle text-white mx-2 text-center mouse product_pev_btn pt-3">
                        <Eye />
                        <br />
                        View
                      </div>
                      <div
                        className="bg-dark rounded-circle text-white mx-2 text-center mouse product_pev_btn pt-3"
                        onClick={(e) => {
                          navigate(
                            "/delivery/addpartner?courierId=" +
                              deliveryMethod +
                              "&stateName=" +
                              stateName +
                              "&cityName=" +
                              cityName +
                              "&partnerId=" +
                              item._id +
                              "&edit=" +
                              true
                          );
                          e.stopPropagation();
                        }}
                      >
                        <Edit color="#FFFFFF" />
                        <br />
                        Edit
                      </div>
                      <div
                        className="bg-dark rounded-circle text-white mx-2 text-center mouse product_pev_btn pt-3"
                        onClick={(e) => {
                          setShowDeleteModal(true);
                          setDeletePartner(item._id);
                          e.stopPropagation();
                        }}
                      >
                        <DeleteIcon />
                        <br />
                        <span className="text-danger">Delete</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}

        <Modal size="lg" show={show1} onHide={() => setShow(false)} centered>
          <Modal.Body className="deliveryModal text-white px-4">
            <h4 className="text-center mb-0 ">
              <u>Add Delivery Method</u>
            </h4>
            <Form onSubmit={handleSubmit}>
              <div className={styles.courier_name_div}>
                <Form.Group
                  className="mb-1 me-1 w-100"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="mb-1">Method Name</Form.Label>
                  <Form.Control
                    type="text"
                    className="bg-transparent textGray"
                    placeholder="Enter Method Name"
                    name="courierName"
                    value={fields1.courierName}
                    onChange={handleChangeField1}
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-1 w-100 mx-1 "
                  controlId="formBasicEmail"
                >
                  <Form.Label className="mb-1">State Name</Form.Label>
                  {loading.state === true ? (
                    <div className={styles.loading_css}>
                      <span>Loading . . .</span>
                      <span>
                        <Spinner size="15" />
                      </span>
                    </div>
                  ) : (
                    <select
                      className={styles.loading_css}
                      // name="state"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      required
                    >
                      {state && state.length !== 0 ? (
                        <option value={state}>{state}</option>
                      ) : (
                        <option value="" hidden>
                          Select State
                        </option>
                      )}
                      {stateNames &&
                        stateNames.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-1 w-100 mx-1 "
                  controlId="formBasicEmail"
                >
                  <Form.Label className="mb-1">City Name</Form.Label>
                  {loading.city === true ? (
                    <div className={styles.loading_css}>
                      <span>Loading . . .</span>
                      <span>
                        <Spinner size="15" />
                      </span>
                    </div>
                  ) : (
                    <select
                      className={styles.loading_css}
                      // name="cityName"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                    >
                      {city && city.length !== 0 ? (
                        <option value={city}>{city}</option>
                      ) : (
                        <option value="" hidden>
                          Select City
                        </option>
                      )}
                      {citiesName &&
                        citiesName.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  )}
                </Form.Group>
              </div>
              <Form.Label className="mb-0">Add Sub-Methods</Form.Label>
              <div className="partnerHeight_delivery">
                {fields &&
                  fields.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex form_group_delivery delivery_method"
                    >
                      <div className="me-2">{index + 1}.</div>
                      <Form.Group as={Row} className=" align-items-center">
                        <Col sm="3" className="px-1">
                          <label>Sub-Method Name</label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Method Name"
                            className="shadow p-1 bg-transparent textGray"
                            name="methodName"
                            value={item.methodName}
                            onChange={(e) => handleChangeField(e, index)}
                            required
                          />
                        </Col>

                        <Col sm="3" className="px-1">
                          <label>Unit</label>
                          <Form.Select
                            className={`${styles.select_unit} shadow p-1 bg-transparent textGray`}
                            name="unit"
                            value={item.unit}
                            onChange={(e) => handleChangeField(e, index)}
                            required
                          >
                            <option value="" hidden>
                              Select
                            </option>
                            <option value="Km">Km</option>
                            <option value="Kg">Kg</option>
                            <option value="Trip">Trip</option>
                          </Form.Select>
                        </Col>

                        <Col sm="3" className="px-1">
                          <label>Price</label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Price"
                            className="shadow p-1 bg-transparent textGray"
                            name="price"
                            value={item.price}
                            onChange={(e) => handleChangeField(e, index)}
                            required
                          />
                        </Col>
                        <Col sm="3" className="px-1">
                          <label>Minimum Amount</label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Min. Amount"
                            className="shadow p-1 bg-transparent textGray"
                            name="minimumPrice"
                            value={item.minimumPrice}
                            onChange={(e) => handleChangeField(e, index)}
                            required
                          />
                        </Col>
                        <Col sm="3" className="px-1">
                          <label>Maximum Weight</label>
                          <Form.Control
                            type="text"
                            placeholder="Max Weight"
                            className="shadow p-1 bg-transparent textGray"
                            name="maxWeight"
                            value={item.maxWeight}
                            onChange={(e) => handleChangeField(e, index)}
                            required
                          />
                        </Col>
                        <Col sm="3" className="px-1">
                          <label>Max Volume (CBM)</label>
                          <Form.Control
                            type="text"
                            placeholder="Max Volume"
                            className="shadow p-1 me-1 bg-transparent textGray"
                            name="maxVm"
                            value={item.maxVm}
                            onChange={(e) => handleChangeField(e, index)}
                            required
                          />
                        </Col>

                        <Col sm="3" className="px-1">
                          <label>Maximum Limit</label>
                          <Form.Control
                            type="text"
                            placeholder="Max Limit"
                            className="shadow p-1 me-1 bg-transparent textGray"
                            name="maxLimit"
                            value={item.maxLimit}
                            onChange={(e) => handleChangeField(e, index)}
                          />
                        </Col>

                        <Col sm="3" className="px-1">
                          <label>Disclaimer</label>
                          <div className="d-flex pt-1">
                            <Form.Label className="shadow py-1 d-flex align-items-center mb-0">
                              <Form.Check
                                type="radio"
                                className="bg-transparent textGray"
                                value="Active"
                                // name="partnerStatus"
                                onChange={(e) => {
                                  const list = [...fields];
                                  list[index]["disclaimerStatus"] = "Active";
                                  setFields(list);
                                }}
                                checked={
                                  item.disclaimerStatus === "Active"
                                    ? true
                                    : false
                                }
                                label="Active"
                              />
                            </Form.Label>
                            <Form.Label className="shadow py-1 d-flex align-items-center mb-0 ms-4">
                              <Form.Check
                                type="radio"
                                value="Disable"
                                // name="partnerStatus"
                                onChange={(e) => {
                                  const list = [...fields];
                                  list[index]["disclaimerStatus"] = "Disable";
                                  setFields(list);
                                }}
                                checked={
                                  item.disclaimerStatus === "Disable"
                                    ? true
                                    : false
                                }
                                label="Disable"
                              />
                            </Form.Label>
                          </div>
                        </Col>

                        {/* <Col sm="3" className="px-1">
                                                <label>Preference</label>
                                                <Form.Select
                                                    className={`${styles.select_unit} shadow p-1 bg-transparent textGray`}
                                                    name="preference"
                                                    value={item.preference}
                                                    onChange={e => handleChangeField(e, index)}
                                                >
                                                    <option value="" hidden>Select</option>
                                                    {getAvailablePreferences(index).map(pref => (
                                                        <option key={pref} value={pref}>{pref}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col> */}

                        {item.disclaimerStatus === "Active" && (
                          <Col sm="12" className="my-1 px-1">
                            {/* <label>Disclaimer (optional)</label> */}
                            <Form.Control
                              as="textarea"
                              rows={2}
                              placeholder="Enter Disclaimer"
                              className="bg-transparent textGray py-0 px-1"
                              name="disclaimer"
                              value={item.disclaimer}
                              onChange={(e) => handleChangeField(e, index)}
                            />
                          </Col>
                        )}

                        <Col sm="12" className="mb-1 p-0">
                          <div className="d-flex justify-content-between align-items-center w-100">
                            <label>Products:</label>

                            <div className="d-flex me-2">
                              <label className="me-2 mb-0">Select All</label>
                              <div
                                role="button"
                                className="border rounded p-1 d-flex justify-content-center align-items-center"
                                onClick={() => selectAllProducts()}
                              >
                                {fields.every((field) =>
                                  field.products.every(
                                    (product) => product.status === "Active"
                                  )
                                ) ? (
                                  <CheckGreenIcon />
                                ) : (
                                  <span className="p-2"></span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex flex-wrap">
                            {/* {item.products && item.products.length !== 0 && products && products.map((i, idx) => */}
                            {products &&
                              products.map((i, idx) => (
                                <div key={idx} className={"productName_center"}>
                                  <button
                                    type="button"
                                    className={
                                      item.products.find(
                                        (val) =>
                                          val.product_id === i.product_id &&
                                          val.status === "Active"
                                      )
                                        ? "productBtn_DELIVERY"
                                        : "productDisableBtn_delivery"
                                    }
                                    // onClick={() => {
                                    //     if ((item.products.find((val) => val.product_id === i.product_id && val.status === 'Active'))) {
                                    //         setFields([
                                    //             ...fields.slice(0, index),
                                    //             {
                                    //                 ...fields[index],
                                    //                 products: [...fields[index].products.slice(0, idx),
                                    //                 {
                                    //                     ...fields[index].products[idx],
                                    //                     product_id: i.product_id,
                                    //                     product_name: i.product_name.replace('OUTSIDE - ', ''),
                                    //                     status: 'Disable',
                                    //                 },
                                    //                 ...fields[index].products.slice(idx + 1)
                                    //                 ]
                                    //             },
                                    //             ...fields.slice(index + 1)
                                    //         ])
                                    //     }
                                    //     else if ((item.products.find((val) => val.product_id === i.product_id && val.status === 'Disable'))) {
                                    //         setFields([
                                    //             ...fields.slice(0, index),
                                    //             {
                                    //                 ...fields[index],
                                    //                 products: [...fields[index].products.slice(0, idx),
                                    //                 {
                                    //                     ...fields[index].products[idx],
                                    //                     product_id: i.product_id,
                                    //                     product_name: i.product_name.replace('OUTSIDE - ', ''),
                                    //                     status: 'Active',
                                    //                 },
                                    //                 ...fields[index].products.slice(idx + 1)
                                    //                 ]
                                    //             },
                                    //             ...fields.slice(index + 1)
                                    //         ])
                                    //     }

                                    //     else {
                                    //         setFields([
                                    //             ...fields.slice(0, index),
                                    //             {
                                    //                 ...fields[index],
                                    //                 products: [...fields[index].products.slice(0, idx),
                                    //                 {
                                    //                     ...fields[index].products[idx],
                                    //                     product_id: i.product_id,
                                    //                     product_name: i.product_name.replace('OUTSIDE - ', ''),
                                    //                     status: 'Active',
                                    //                 },
                                    //                 ...fields[index].products.slice(idx + 1)
                                    //                 ]
                                    //             },
                                    //             ...fields.slice(index + 1)
                                    //         ])
                                    //     }
                                    // }}

                                    // onClick={() => {
                                    //     const updatedFields = [...fields]; // Create a copy of fields array
                                    //     const productIndex = updatedFields[index].products.findIndex(val => val.product_id === i.product_id);
                                    //     updatedFields[index].products[productIndex].product_name = i.product_name.replace('OUTSIDE - ', '');
                                    //     if (productIndex !== -1) {
                                    //         // Toggle status based on current status
                                    //         updatedFields[index].products[productIndex].status =
                                    //             updatedFields[index].products[productIndex].status === 'Active' ? 'Disable' : 'Active';

                                    //         setFields(updatedFields); // Update state
                                    //     }
                                    // }}
                                    onClick={() => {
                                      const updatedFields = [...fields]; // Create a copy of fields array
                                      updatedFields[index].products.forEach(
                                        (product) => {
                                          // Remove 'OUTSIDE - ' from product names
                                          product.product_name =
                                            product.product_name.replace(
                                              "OUTSIDE - ",
                                              ""
                                            );
                                        }
                                      );
                                      const productIndex = updatedFields[
                                        index
                                      ].products.findIndex(
                                        (val) => val.product_id === i.product_id
                                      );
                                      if (productIndex !== -1) {
                                        // Toggle status based on current status
                                        updatedFields[index].products[
                                          productIndex
                                        ].status =
                                          updatedFields[index].products[
                                            productIndex
                                          ].status === "Active"
                                            ? "Disable"
                                            : "Active";
                                        setFields(updatedFields); // Update state
                                      }
                                    }}
                                  >
                                    {item.products.find(
                                      (val) =>
                                        val.product_id === i.product_id &&
                                        val.status === "Active"
                                    ) ? (
                                      <span className={"plus_product"}>
                                        &#10004;
                                      </span>
                                    ) : (
                                      <span className={"plus_product"}>
                                        &#10133;
                                      </span>
                                    )}
                                    {i.product_name}
                                  </button>
                                </div>
                              ))}
                          </div>
                        </Col>

                        <Col sm="3" className="ps-0">
                          Status
                          <div className="d-flex">
                            <Form.Label className="shadow pt-2 d-flex align-items-center mb-0">
                              <Form.Check
                                type="radio"
                                className="bg-transparent textGray"
                                value="Active"
                                // name="partnerStatus"
                                onChange={(e) => {
                                  const list = [...fields];
                                  list[index]["methodStatus"] = "Active";
                                  setFields(list);
                                }}
                                checked={
                                  item.methodStatus === "Active" ? true : false
                                }
                                label="Active"
                              />
                            </Form.Label>
                            <Form.Label className="shadow pt-2 d-flex align-items-center mb-0 ms-4">
                              <Form.Check
                                type="radio"
                                value="Disable"
                                // name="partnerStatus"
                                onChange={(e) => {
                                  const list = [...fields];
                                  list[index]["methodStatus"] = "Disable";
                                  setFields(list);
                                }}
                                checked={
                                  item.methodStatus === "Disable" ? true : false
                                }
                                label="Disable"
                              />
                            </Form.Label>
                          </div>
                        </Col>

                        <Col sm="4" className="">
                          Booking Limit
                          <div className="d-flex">
                            <Form.Label className="shadow pt-2 d-flex align-items-center mb-0 ">
                              <Form.Check
                                type="radio"
                                className="bg-transparent textGray"
                                value="Active"
                                // name="partnerStatus"
                                onChange={(e) => {
                                  const list = [...fields];
                                  list[index]["maxLimitStatus"] = "Active";
                                  setFields(list);
                                }}
                                checked={
                                  item.maxLimitStatus === "Active"
                                    ? true
                                    : false
                                }
                                label="Active"
                              />
                            </Form.Label>
                            <Form.Label className="shadow pt-2 d-flex align-items-center mb-0 ms-4">
                              <Form.Check
                                type="radio"
                                value="Disable"
                                // name="partnerStatus"
                                onChange={(e) => {
                                  const list = [...fields];
                                  list[index]["maxLimitStatus"] = "Disable";
                                  setFields(list);
                                }}
                                checked={
                                  item.maxLimitStatus === "Disable"
                                    ? true
                                    : false
                                }
                                label="Disable"
                              />
                            </Form.Label>
                          </div>
                        </Col>

                        <Col sm="3" className="ps-0 pb-2">
                          Add Partner
                          <div className="d-flex">
                            <Form.Label className="shadow pt-2 d-flex align-items-center mb-0 ">
                              <Form.Check
                                type="radio"
                                className="bg-transparent textGray"
                                value="Active"
                                // name="partnerStatus"
                                onChange={(e) => {
                                  const list = [...fields];
                                  list[index]["partnerStatus"] = "Active";
                                  setFields(list);
                                }}
                                checked={
                                  item.partnerStatus === "Active" ? true : false
                                }
                                label="Active"
                              />
                            </Form.Label>
                            <Form.Label className="shadow pt-2 d-flex align-items-center mb-0 ms-4">
                              <Form.Check
                                type="radio"
                                value="Disable"
                                // name="partnerStatus"
                                onChange={(e) => {
                                  const list = [...fields];
                                  list[index]["partnerStatus"] = "Disable";
                                  setFields(list);
                                }}
                                checked={
                                  item.partnerStatus === "Disable"
                                    ? true
                                    : false
                                }
                                label="Disable"
                              />
                            </Form.Label>
                          </div>
                        </Col>

                        <Col ref={fieldRef} sm="2" className="p-0 pt-3">
                          <div
                            role="button"
                            className="text-center shadow deletebtncss p-1 text-danger w-100"
                            onClick={() => handleRemove(index)}
                          >
                            Delete
                          </div>
                        </Col>
                      </Form.Group>

                      <Modal
                        size="xs"
                        show={showDeleteSubDeliveryModal}
                        onHide={() => setShowDeleteSubDeliveryModal(false)}
                        centered
                      >
                        <Modal.Body className="bgSale cardcss text-white text-center">
                          <h2>Delete Sub-Methods</h2>
                          <p className={styles.message}>
                            To confirm! type{" "}
                            <span className="text-danger">delete</span> in the
                            text input field.
                          </p>
                          <input
                            type="text"
                            maxLength={6}
                            className={styles.input_delete}
                            placeholder="Enter"
                            onChange={(e) => setDeleteText(e.target.value)}
                          />
                          <div className={styles.massageModal_btn}>
                            <RedOutButton
                              title="CANCEL"
                              handleSubmit={() =>
                                setShowDeleteSubDeliveryModal(false)
                              }
                            />
                            <GreenButton
                              disabled={
                                deleteText.toUpperCase() === "DELETE"
                                  ? false
                                  : true
                              }
                              title="DELETE"
                              handleSubmit={confirmDelete}
                            />
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  ))}
              </div>

              <h6
                role="button"
                className="mt-2 mb-3 mouse w-auto"
                onClick={() => handleAdd()}
              >
                + Add More Sub-Method
              </h6>

              {/* <Form.Group className="mb-3">
                                <Form.Label>Disclaimer (optional)</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    className="bg-transparent textGray"
                                    rows={2}
                                    name="disclaimer"
                                    placeholder="Enter Disclaimer"
                                    value={fields1.disclaimer}
                                    onChange={handleChangeField1}
                                />
                            </Form.Group> */}
              {/* <Form.Group className="mb-3 d-flex">
                                <Form.Check
                                    type="radio"
                                    label="Enable Custom Partner Form"
                                    className="fs_18"
                                    name="enableForm"
                                    value="Active"
                                    onChange={handleChangeField1}
                                    checked={fields1.enableForm === 'Active' ? true : false}
                                    required
                                />
                                <Form.Check
                                    type="radio"
                                    label="Disable Custom Partner Form"
                                    className="fs_18 ms-5"
                                    name="enableForm"
                                    value="Disable"
                                    onChange={handleChangeField1}
                                    checked={fields1.enableForm === 'Disable' ? true : false}
                                    required
                                />
                            </Form.Group> */}

              <div className="d-flex">
                <Form.Group
                  as={Row}
                  className={`mb-2 ${styles.state_delivery_width}`}
                >
                  <Form.Label className="ps-0 mb-1">City Delivery</Form.Label>
                  <Col sm="3 ps-0" xs="6">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Active"
                      value="Active"
                      name="cityDelivery"
                      onChange={handleChangeField1}
                      checked={fields1.cityDelivery === "Active" ? true : false}
                      required
                    />
                  </Col>
                  <Col sm="3" xs="6" className="ms-5 p-0">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Disable"
                      value="Disable"
                      name="cityDelivery"
                      onChange={handleChangeField1}
                      checked={
                        fields1.cityDelivery === "Disable" ? true : false
                      }
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className={`mb-2 ${styles.state_delivery_width}`}
                >
                  <Form.Label className="ps-0 mb-1">State Delivery</Form.Label>
                  <Col sm="3" xs="6" className="p-0">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Active"
                      value="Active"
                      name="stateDelivery"
                      onChange={handleChangeField1}
                      checked={
                        fields1.stateDelivery === "Active" ? true : false
                      }
                      required
                    />
                  </Col>
                  <Col sm="3" xs="6" className="ms-5">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Disable"
                      value="Disable"
                      name="stateDelivery"
                      onChange={handleChangeField1}
                      checked={
                        fields1.stateDelivery === "Disable" ? true : false
                      }
                      required
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className={`mb-2 ${styles.state_delivery_width}`}
                >
                  <Form.Label className="ps-0 mb-1">
                    Country Delivery
                  </Form.Label>
                  <Col sm="3 ps-0" xs="6">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Active"
                      value="Active"
                      name="countryDelivery"
                      onChange={handleChangeField1}
                      checked={
                        fields1.countryDelivery === "Active" ? true : false
                      }
                      required
                    />
                  </Col>
                  <Col sm="3" xs="6" className="ms-5 p-0">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Disable"
                      value="Disable"
                      name="countryDelivery"
                      onChange={handleChangeField1}
                      checked={
                        fields1.countryDelivery === "Disable" ? true : false
                      }
                      required
                    />
                  </Col>
                </Form.Group>
              </div>

              <div className="d-flex">
                <Form.Group
                  as={Row}
                  className={`mb-2 ${styles.state_delivery_width}`}
                >
                  <Form.Label className="ps-0 mb-1">
                    Custom Partner Form
                  </Form.Label>
                  <Col sm="3 ps-0" xs="6">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Active"
                      value="Active"
                      name="enableForm"
                      onChange={handleChangeField1}
                      checked={fields1.enableForm === "Active" ? true : false}
                      required
                    />
                  </Col>
                  <Col sm="3" xs="6" className="ms-5">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Disable"
                      value="Disable"
                      name="enableForm"
                      onChange={handleChangeField1}
                      checked={fields1.enableForm === "Disable" ? true : false}
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className={`mb-2 ${styles.state_delivery_width}`}
                >
                  <Form.Label className="ps-0 mb-1">
                    Method Selection
                  </Form.Label>
                  <Col sm="3 ps-0" xs="6">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Active"
                      value="Active"
                      name="methodSelection"
                      onChange={handleChangeField1}
                      checked={
                        fields1.methodSelection === "Active" ? true : false
                      }
                      required
                    />
                  </Col>
                  <Col sm="3" xs="6" className="ms-5">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Disable"
                      value="Disable"
                      name="methodSelection"
                      onChange={handleChangeField1}
                      checked={
                        fields1.methodSelection === "Disable" ? true : false
                      }
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className={`mb-2 ${styles.state_delivery_width}`}
                >
                  <Form.Label className="ps-0 mb-1">Status</Form.Label>
                  <Col sm="3 ps-0" xs="6">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Active"
                      value="Active"
                      name="status"
                      onChange={handleChangeField1}
                      checked={fields1.status === "Active" ? true : false}
                      required
                    />
                  </Col>
                  <Col sm="3" xs="6" className="ms-5">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Disable"
                      value="Disable"
                      name="status"
                      onChange={handleChangeField1}
                      checked={fields1.status === "Disable" ? true : false}
                      required
                    />
                  </Col>
                </Form.Group>
              </div>
              {view ? (
                <div className="d-flex justify-content-center w-50 mx-auto">
                  <RedOutButton
                    btnType="Button"
                    title="BACK"
                    css="bg-transparent textGray p-2 px-4 text-danger"
                    handleSubmit={() => {
                      setShow(false);
                      setFields([
                        { partnerName: "", partnerStatus: "", products: [] },
                      ]);
                      setFields1({});
                    }}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-between w-50 mx-auto">
                  <RedOutButton
                    btnType="Button"
                    title="BACK"
                    css="bg-transparent textGray p-2 px-4 text-danger"
                    handleSubmit={() => {
                      setShow(false);
                      setFields([
                        { partnerName: "", partnerStatus: "", products: [] },
                      ]);
                      setFields1({});
                    }}
                  />
                  <GreenButton
                    btnType="submit"
                    css="p-2"
                    title={!fields1.courierId ? "CREATE" : "UPDATE"}
                  />
                </div>
              )}
            </Form>
          </Modal.Body>
        </Modal>

        {/* <Modal size="lg" show={showPartner} onHide={() => setShowPartner(false)} centered>
                    <Modal.Body className="bgSale cardcss text-white">
                        <h3 className="text-center mt-3 pb-2">Add Partners</h3>
                        <Form className="d-flex delivery_method mt-4 mb-5 mx-2 pt-2">
                            <Form.Group as={Row} className={styles.form_main_div}>
                                <div className={styles.form_partner_div}>
                                    <Col sm="3" className="py-3 px-2">
                                        <label>Partner Name</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Partner Name"
                                            className="shadow p-1 bg-transparent textGray"
                                            required
                                            value={showPartnerData.partnerName}
                                            onChange={e => setShowPartnerData(prevState => ({
                                                ...prevState,
                                                partnerName: e.target.value
                                            }))}
                                            disabled={viewPartner === 'view' ? true : false}
                                        />
                                    </Col>

                                    <Col sm="3" className="py-3 px-2">
                                        <label>City Name</label>
                                        <Form.Select
                                            className="shadow p-1 bg-transparent textGray"
                                            required
                                            value={showPartnerData.cityName}
                                            onChange={e => setShowPartnerData(prevState => ({
                                                ...prevState,
                                                cityName: e.target.value
                                            }))}
                                            disabled={viewPartner === 'view' ? true : false}
                                        >
                                            <option value="" hidden>Select</option>
                                            {cities && cities.map((item, index) => <option key={index} value={item.cityName}>{item.cityName}</option>)}
                                        </Form.Select>
                                    </Col>

                                    <Col sm="3" className="py-3 px-2">
                                        <label>Courier Name</label>
                                        <Form.Select
                                            className="shadow p-1 bg-transparent textGray"
                                            required
                                            value={showPartnerData.courierName}
                                            disabled={viewPartner === 'view' ? true : false}
                                            onChange={e => setShowPartnerData(prevState => ({
                                                ...prevState,
                                                courierName: e.target.value
                                            }))}
                                        >
                                            <option value="" hidden>Select </option>
                                            {courierName && courierName.map((item, index) =>
                                                <option key={index} value={item.courierName}>{item.courierName}</option>
                                            )}
                                        </Form.Select>
                                    </Col>
                                    <Col sm="3" className="py-3 px-2">
                                        <label>Methods Name</label>
                                        <Form.Select
                                            className="shadow p-1 bg-transparent textGray"
                                            required
                                            value={showPartnerData.methodName}
                                            disabled={viewPartner === 'view' ? true : false}
                                            onChange={e => setShowPartnerData(prevState => ({
                                                ...prevState,
                                                methodName: e.target.value
                                            }))}
                                        >
                                            <option value="" hidden>Select</option>
                                            {methods && methods.map((item, index) =>
                                                <option key={index} value={item.methodName}>{item.methodName}</option>
                                            )}
                                        </Form.Select>
                                    </Col>

                                    <Col sm="3" className="py-3 px-2">
                                        <label>Contact Number</label>
                                        <Form.Control
                                            type="Number"
                                            placeholder="Enter Contact No."
                                            className="shadow p-1 bg-transparent textGray"
                                            required
                                            value={showPartnerData.contactNumber}
                                            disabled={viewPartner === 'view' ? true : false}
                                            onChange={e => setShowPartnerData(prevState => ({
                                                ...prevState,
                                                contactNumber: e.target.value
                                            }))}
                                            maxLength="10"
                                            onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                                        />
                                    </Col>
                                    <Col sm="3" className="py-3 px-2">
                                        <label>Tracking Number</label>
                                        <Form.Control
                                            type="Number"
                                            placeholder="Enter Tracking No."
                                            className="shadow p-1 bg-transparent textGray"
                                            disabled={viewPartner === 'view' ? true : false}
                                            // required
                                            value={showPartnerData.trackingNumber}
                                            onChange={e => setShowPartnerData(prevState => ({
                                                ...prevState,
                                                trackingNumber: e.target.value
                                            }))}
                                        />
                                    </Col>
                                    <Col sm="3" className="py-3 px-2">
                                        <label>Tracking URL </label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Tracking URL"
                                            className="shadow p-1 bg-transparent textGray"
                                            disabled={viewPartner === 'view' ? true : false}
                                            // required
                                            value={showPartnerData.trackingUrl}
                                            onChange={e => setShowPartnerData(prevState => ({
                                                ...prevState,
                                                trackingUrl: e.target.value
                                            }))}
                                        />
                                    </Col>
                                    <div className='d-flex w-100 justify-content-center align-items-start mt-5 mb-4'>
                                        <h4 className="me-5">Status</h4>
                                        <Form.Check
                                            className="text-success"
                                            type="radio"
                                            aria-label="radio 1"
                                            label="Active"
                                            value="Active"
                                            name="status"
                                            onChange={e => setShowPartnerData(prevState => ({
                                                ...prevState,
                                                partnerStatus: e.target.value
                                            }))}
                                            checked={showPartnerData.partnerStatus === 'Active' ? true : false}
                                            required
                                        />
                                        <Form.Check
                                            className="ms-5 text-danger"
                                            type="radio"
                                            aria-label="radio 1"
                                            label="Disable"
                                            value="Disable"
                                            name="status"
                                            onChange={e => setShowPartnerData(prevState => ({
                                                ...prevState,
                                                partnerStatus: e.target.value
                                            }))}
                                            checked={showPartnerData.partnerStatus === 'Disable' ? true : false}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <RedOutButton title="CANCEL" btnType='button' css="me-5" handleSubmit={() => setShowPartner(false)} />
                                    {viewPartner === 'view' ? '' : <GreenButton title={showPartnerData.partnerId ? "UPDATE" : "CREATE"} handleSubmit={(e) => validationPartnerDetail(e)} css="ms-5" />}
                                </div>
                            </Form.Group>
                        </Form >
                    </Modal.Body>
                </Modal> */}

        <Modal
          size="lg"
          show={deleteModal}
          onHide={() => setDeletModal(false)}
          centered
        >
          <Modal.Body className="bgSale cardcss text-white text-center">
            <h3>Are you sure you want to delete this delivery method?</h3>
            <p className={styles.message}>
              To confirm! type <span className="text-danger">delete</span> in
              the text input field.
            </p>
            <input
              type="text"
              maxLength={6}
              className={styles.input_delete}
              placeholder="Enter"
              onChange={(e) => setDeleteText(e.target.value)}
            />
            <div className={styles.massageModal_btn}>
              <RedOutButton
                title="CANCEL"
                handleSubmit={() => setDeletModal(false)}
              />
              <GreenButton
                disabled={deleteText.toUpperCase() === "DELETE" ? false : true}
                title="DELETE"
                handleSubmit={() => {
                  deleteDeliveryMethod(fields1.courierId);
                }}
              />
            </div>
            {/* <h2>Delete</h2>
                        <h6 className='my-5'>Are you sure you want to delete this delivery method?</h6>

                        <GreenButton title="CANCEL" css="me-5 " handleSubmit={() => { setDeletModal(false); }} />

                        <RedOutButton title="DELETE" css="ms-5 bg-transparent mb-4" handleSubmit={() => deleteDeliveryMethod(fields1.courierId)} /> */}
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Body className="bgSale cardcss text-white text-center">
            <h2>Delete Partner</h2>
            <p className={styles.message}>
              To confirm! type <span className="text-danger">delete</span> in
              the text input field.
            </p>
            <input
              type="text"
              maxLength={6}
              className={styles.input_delete}
              placeholder="Enter"
              onChange={(e) => setDeleteText(e.target.value)}
            />
            <div className={styles.massageModal_btn}>
              <RedOutButton
                title="CANCEL"
                handleSubmit={() => setShowDeleteModal(false)}
              />
              <GreenButton
                disabled={deleteText.toUpperCase() === "DELETE" ? false : true}
                title="DELETE"
                handleSubmit={() => {
                  handleDeletePartner();
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Delivery;

// import React, { useEffect, useRef, useState } from "react";

// import styles from "./Delivery.module.css";
// import { useAuth } from "../../components/context/Auth";
// import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
// import Button from "../../components/updatedComponents/Dealers/Button";
// import { CreateTem } from "../../components/icons/Icon2";
// import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
// import { Editwarehouse, View12 } from "../../components/icons/Icon4";
// import WarehouseInfoCard from "../../components/updatedComponents/Dealers/Warehousecardinfo";
// import { useNavigate } from "react-router-dom";
// const Delivery = () => {
//     const { mode, managerId } = useAuth();
//     const [keyword1, setKeyword1] = useState("");
//     const navigate = useNavigate();

//     return (
//         <React.Fragment>
//             <div className={styles.mainCon} style={{ backgroundColor: mode ? "#232529" : "#D9DCE5", }}>
//                 <div style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC", }} className={styles.voucher_maindiv} >

//                     <div className={styles.order_top_left}>
//                         <TopTabBar text={"Invoice"} />
//                         <div>

//                             <Button

//                                 buttonIcon={<CreateTem />}
//                                 buttonBgColor={"#4164E3"}
//                                 buttonText={"Add Delivery Method"}
//                                 buttonTextColor={"white"}

//                             /></div>
//                     </div>

//                     <div
//                         className={styles.search}

//                     >

//                         <div style={{ width: "46vh" }} >
//                             <p className={styles.DeliveryPartner} style={{ color: mode ? "#FFFFFF" : "#646B88", }}>            Delivery Partner
//                             </p>              <SearchBar
//                                 placeholder={"Search by Order ID"}
//                                 value={keyword1}
//                                 onChange={(e) => setKeyword1(e.target.value)}
//                             />

//                         </div>
//                         <div
//                         >
//                             <Button
//                                 buttonIcon={<CreateTem />}
//                                 buttonBgColor={"#4164E3"}
//                                 buttonText={"Add Delivery Partner"}
//                                 buttonTextColor={"white"}
//                                 buttonClick={()=>navigate('/delivery/addpartner')
//                                 }
//                             />

//                         </div>
//                     </div>
//                     <div style={{

//                         marginBottom: "60px",
//                         overflowY: "auto",
//                         padding: "10PX",
//                         position: "relative",
//                         height: 'calc(100vh - 420px)',
//                     }}>
//                         <div className={styles.container}
//                         >

//                             <div className={styles.cards}
//                                 style={{
//                                     backgroundColor: mode ? "#1B1D21" : "white",
//                                 }}
//                             // key={index}
//                             >

//                                 <div>
//                                     <WarehouseInfoCard

//                                         warehouseStatus={"QWQW"}

//                                         warehouseName={"KJNOI"}
//                                     // warehouseName={getTruncatedName(item?.warehousename, item?.city, item?.state)}
//                                     />
//                                     <div style={{ display: "flex", gap: "20PX", }} >
//                                         <div style={{ display: "flex", gap: "20PX", }}
//                                         //   onClick={() => {
//                                         //     setViewEdit(0);
//                                         //     getWareHouse(item?.warehouseId);
//                                         //   }}
//                                         >
//                                             <View12 mode={mode} />
//                                             <div style={{}}
//                                             //    onClick={(e) => {
//                                             //     e.stopPropagation();
//                                             //     setViewEdit(1);
//                                             //     getWareHouse(item?.warehouseId);
//                                             //   }}
//                                             > <Editwarehouse mode={mode} />
//                                             </div>

//                                         </div>

//                                     </div>
//                                 </div>
//                             </div>

//                         </div></div>

//                 </div>

//             </div>
//         </React.Fragment >
//     );
// };
// export default Delivery;
