import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AdminMenuWalliconLogo,
  HamBurgerIcon /* SideBarWalliconLogo */,
} from "../components/icons/Icon";
import styles from "./Menus.module.css";
import { useAuth } from "../components/context/Auth";
import useWindowWidth from "../components/updatedComponents/Hooks/WidthSize";

const AdminMenu = ({ onClick, isClosed, menuItems }) => {
  const navigate = useNavigate();
  const { designation, mode } = useAuth();
  const { pathname } = useLocation();
  const [hoveredPath, setHoveredPath] = useState(null);
  const [hoveredIcon, setHovereIcon] = useState(null);
  const width = useWindowWidth();
  const [updatedMenuItems, setUpdatedMenuItems] = useState([]);

  useEffect(() => {
    const updatedItems = [...menuItems];
    if (designation && designation.length >= 1) {
      updatedItems[0] = { ...updatedItems[0], label: "DASHBOARD" };
    }
    setUpdatedMenuItems(updatedItems);
  }, [designation, menuItems]);

  return (
    <React.Fragment>
      <div
        className={`${styles.admin_menu_logo} ${mode ? styles.admin_menu_logo_dark : {}
          }`}
      >
        {isClosed ? (
          <div onClick={onClick} >
            <HamBurgerIcon color={mode ? "#fff" : "#646B88"} />
          </div>
        ) : (
          <div onClick={onClick}>
            <AdminMenuWalliconLogo
              backgroundColor={mode ? "#232529" : "#F5F6FA"}
              textColor={mode ? "#FFFFFF" : "#231F20"}
            />
          </div>
        )}
      </div>
      <div className={styles.menuItem_div_con}>
        {updatedMenuItems.map(({ label, path, Icon }) => {
          const getBaseRoute = (url) => {
            const firstSlashIndex = url.indexOf('/', 1);
            return firstSlashIndex === -1 ? url : url.slice(0, firstSlashIndex); 
          };
        
          const basePathname = getBaseRoute(pathname); 
          const basePath = getBaseRoute(path);  
          return (
            <div
              key={path}
              // className={`${styles.menuItem_div} ${pathname.startsWith(path)
              // className={`${styles.menuItem_div} ${pathname === path
              className={`${styles.menuItem_div} ${basePathname === basePath
                ? mode
                  ? `${styles.active_div_Dark}`
                  : `${styles.active_div}`
                : ""
                } ${isClosed ? styles.menuItem_div_toggled : ""}`}
              onClick={() => navigate(path)}
              onMouseEnter={() => {
                setHoveredPath(path);
                setHovereIcon(path);
              }}
              onMouseLeave={() => {
                setHoveredPath(null);
                setHovereIcon(null);
              }}
              style={{
                // backgroundColor: pathname.startsWith(path)
                backgroundColor: basePathname === basePath
                  ? mode
                    ? "#232529"
                    : "#f5f6fa"
                  : mode
                    ? hoveredPath === path
                      ? "#232529"
                      : "#2C2E33"
                    : hoveredPath === path
                      ? "#f5f6fa"
                      : "#ffffff",
              }}
            >
              <div>
                {width < 1250
                  ? hoveredIcon === path && (
                    <div
                      className={styles.hoverPathname}
                      style={{
                        backgroundColor: mode ? "#000" : "#bebebe",
                        color: mode ? "#fff" : "#000",
                        display: "none",
                      }}
                    >
                      <div>{label}</div>
                    </div>
                  )
                  : null}
                <Icon
                  css={styles.iconImage}
                  color={mode ? "#ffffff" : "#646B88"}
                />
              </div>
              <span
                className={isClosed ? styles.hide_span : ""}
                style={mode ? { color: "#fff" } : { color: "#646B88" }}
              >
                {label}
              </span>
            </div>
          )
        }

        )}
      </div>
    </React.Fragment>
  );
};

export default AdminMenu;
