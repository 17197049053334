import { useAuth } from "../../context/Auth";
import { NavbarArrowDownDarkIcon, NavbarArrowDownIcon } from "../../icons/Icon";
import styles from "./FromComponent.module.css";
import { useState, useEffect, useRef, useCallback } from "react";

const MultipleSelectDropDown = ({
  label,
  options,
  dropDownText,
  star,
  value,
  onchange,
  height,
  fontSize,
  disableOpen = false,
  padding,
  dropDownOpen = false,
  selectionCompleted,
}) => {
  const [isOpen, setIsOpen] = useState(dropDownOpen);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const dropdownRef = useRef(null);
  const itemRefs = useRef([]);
  const { mode } = useAuth();

  const handleOptionSelect = useCallback(
    (option) => {
      setSelectedOptions((prevSelected) => {
        const newSelection = prevSelected.includes(option)
          ? prevSelected.filter((item) => item !== option)
          : [...prevSelected, option];

        const uniqueSelection = Array.from(
          new Set(newSelection.map((o) => o.value))
        ).map((value) => newSelection.find((o) => o.value === value));

        setTimeout(() => {
          onchange(uniqueSelection);
        }, 0);

        return uniqueSelection;
      });
    },
    [onchange]
  );

  const handleItemRemove = (option) => {
    const newSelection = selectedOptions.filter((item) => item !== option);
    setSelectedOptions(newSelection);
    onchange(newSelection);
  };

  useEffect(() => {
    setSelectedOptions(value || []);
  }, [value]);

  useEffect(() => {
    setIsOpen(dropDownOpen);
  }, [dropDownOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isOpen) return;
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) => {
          const newIndex = prevIndex < options.length - 1 ? prevIndex + 1 : 0;
          itemRefs?.current[newIndex]?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          return newIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : options.length - 1;
          itemRefs?.current[newIndex]?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          return newIndex;
        });
      } else if (
        event.key === " " ||
        event.code === "Space" ||
        event.keyCode === 32
      ) {
        if (highlightedIndex !== -1) {
          handleOptionSelect(options[highlightedIndex]);
        }
      } else if (event.key === "Enter") {
        event.stopPropagation();
        event.preventDefault();
        if (selectedOptions.length === 0) {
          alert("Please select altleast 1 option!");
        } else {
          setIsOpen(false);
          if (selectionCompleted) {
            selectionCompleted();
          }
        }
      } else if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    isOpen,
    highlightedIndex,
    options,
    handleOptionSelect,
    selectionCompleted,
    selectedOptions.length,
  ]);

  return (
    <div className={styles.vertical_dropdown_container} ref={dropdownRef}>
      {label && (
        <label
          className={styles.vertical_dropdown_label}
          style={mode ? { color: "#fff" } : {}}
        >
          {label}
          {star && <span style={{ color: "red" }}>*</span>}
        </label>
      )}

      <div
        className={styles.vertical_custom_select}
        style={mode ? { borderColor: "#1b1d21" } : {}}
      >
        <div
          className={styles.vertical_dropdown_trigger}
          style={{
            height: height || "auto",
            fontSize: fontSize || "inherit",
            backgroundColor: mode ? "#1b1d21" : "",
            borderColor: mode ? "#1b1d21" : "",
            cursor: disableOpen ? "text" : "pointer",
          }}
          onClick={() => {
            if (!disableOpen) setIsOpen(!isOpen);
          }}
        >
          <span style={{ fontSize: fontSize || "inherit" }}>
            {selectedOptions.length > 0
              ? `Selected Option(s): ${selectedOptions.length}`
              : dropDownText}
          </span>
          {!disableOpen &&
            (mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />)}
        </div>
        {isOpen && (
          <ul
            className={styles.vertical_dropdown_list}
            style={
              mode ? { backgroundColor: "#232529", borderColor: "#232529" } : {}
            }
          >
            {options?.map((option, index) => (
              <li
                key={index}
                ref={(el) => (itemRefs.current[index] = el)}
                className={`${styles.vertical_dropdown_item} 
                ${mode ? styles.vertical_dropdown_item_dark : ""} 
                ${
                  highlightedIndex === index
                    ? mode
                      ? styles.focused_item_dark
                      : styles.focused_item
                    : ""
                }`}
                onClick={() => handleOptionSelect(option)}
                style={{ fontSize: fontSize || "inherit" }}
              >
                <span>{option.label} </span>
                <span>
                  {highlightedIndex === index &&
                    "Press Space to Select or Unselect"}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className={styles.selected_items}>
        {selectedOptions.length > 0 ? (
          selectedOptions.map((opt, index) => (
            <span
              key={index}
              className={styles.selected_item}
              onClick={() => handleItemRemove(opt)}
              style={{
                fontSize: fontSize || "inherit",
                padding: padding || "auto",
              }}
            >
              {opt.label}
              <span className={styles.remove_item}>&times;</span>
            </span>
          ))
        ) : (
          <span
            className={styles.placeholder_text}
            style={
              mode
                ? { color: "#fff", fontSize: fontSize || "inherit" }
                : { fontSize: fontSize || "inherit" }
            }
          >
            Nothing Selected
          </span>
        )}
      </div>
    </div>
  );
};

export default MultipleSelectDropDown;

// import { useAuth } from "../../context/Auth";
// import { NavbarArrowDownDarkIcon, NavbarArrowDownIcon } from "../../icons/Icon";
// import styles from "./FromComponent.module.css";
// import { useState, useEffect, useRef } from "react";

// const MultipleSelectDropDown = ({
//   label,
//   options,
//   dropDownText,
//   star,
//   value,
//   onchange,
//   height,
//   fontSize,
//   disableOpen = false,
//   padding,
//   dropDownOpen = false,
// }) => {
//   const [isOpen, setIsOpen] = useState(dropDownOpen);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const dropdownRef = useRef(null);
//   const { mode } = useAuth();

//   useEffect(() => {
//     setIsOpen(dropDownOpen);
//   }, [dropDownOpen]);

//   const handleOptionSelect = (option) => {
//     setSelectedOptions((prevSelected) => {
//       const newSelection = prevSelected.includes(option)
//         ? prevSelected.filter((item) => item !== option)
//         : [...prevSelected, option];

//       const uniqueSelection = Array.from(
//         new Set(newSelection?.map((o) => o.value))
//       )?.map((value) => newSelection.find((o) => o.value === value));

//       setTimeout(() => {
//         onchange(uniqueSelection);
//       }, 0);

//       return uniqueSelection;
//     });

//     setIsOpen(false);
//   };

//   const handleItemRemove = (option) => {
//     const newSelection = selectedOptions.filter((item) => item !== option);
//     setSelectedOptions(newSelection);
//     onchange(newSelection);
//   };

//   useEffect(() => {
//     setSelectedOptions(value || []);
//   }, [value]);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };

//     if (isOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isOpen]);

//   return (
//     <div className={styles.vertical_dropdown_container} ref={dropdownRef}>
//       {label && (
//         <label
//           className={styles.vertical_dropdown_label}
//           style={mode ? { color: "#fff" } : {}}
//         >
//           {label}
//           {star && <span style={{ color: "red" }}>*</span>}
//         </label>
//       )}

//       <div
//         className={styles.vertical_custom_select}
//         style={mode ? { borderColor: "#1b1d21" } : {}}
//       >
//         <div
//           className={styles.vertical_dropdown_trigger}
//           style={{
//             height: height && height,
//             fontSize: fontSize && fontSize,
//             backgroundColor: mode && "#1b1d21",
//             borderColor: mode && "#1b1d21",
//             cursor: disableOpen ? "text" : "pointer",
//           }}
//           onClick={() => {
//             if (!disableOpen) setIsOpen(!isOpen);
//           }}
//         >
//           <span style={{ fontSize: fontSize && fontSize }}>
//             {selectedOptions.length > 0
//               ? `Selected Option(s): ${selectedOptions.length}`
//               : dropDownText}
//           </span>
//           {!disableOpen &&
//             (mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />)}
//         </div>
//         {isOpen && (
//           <ul
//             className={styles.vertical_dropdown_list}
//             style={
//               mode ? { backgroundColor: "#232529", borderColor: "#232529" } : {}
//             }
//           >
//             {options?.map((option, index) => (
//               <li
//                 key={index}
//                 className={`${styles.vertical_dropdown_item} ${
//                   mode ? styles.vertical_dropdown_item_dark : ""
//                 }`}
//                 onClick={() => handleOptionSelect(option)}
//                 style={{ fontSize: fontSize && fontSize }}
//               >
//                 {option.label}
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>

//       <div className={styles.selected_items}>
//         {selectedOptions.length > 0 ? (
//           selectedOptions?.map((opt, index) => (
//             <span
//               key={index}
//               className={styles.selected_item}
//               onClick={() => handleItemRemove(opt)}
//               style={{
//                 fontSize: fontSize && fontSize,
//                 padding: padding && padding,
//               }}
//             >
//               {opt.label}
//               <span className={styles.remove_item}>&times;</span>{" "}
//             </span>
//           ))
//         ) : (
//           <span
//             className={styles.placeholder_text}
//             style={
//               mode
//                 ? { color: "#fff", fontSize: fontSize && fontSize }
//                 : { fontSize: fontSize && fontSize }
//             }
//           >
//             Nothing Selected{" "}
//           </span>
//         )}
//       </div>
//     </div>
//   );
// };

// export default MultipleSelectDropDown;
