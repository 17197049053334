import React, { useEffect, useState } from "react";
import styles from "./inventryreport.module.css";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/context/Auth";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
import SlidingFilter from "../../components/updatedComponents/Dealers/SlidingFilter";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
const Inventryreportdetails = () => {
  const { managerId } = useAuth();
  const [data, setData] = useState([]);
  const [daysTab, setDaysTab] = useState("");
  const [inout, setInout] = useState("");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [itemSearch, setItemSearch] = useState("");
  const navigate = useNavigate();
  const { mode, } = useAuth();
  const orderHeader = [
    'Product',
    "Side",
    "Quantity",
    "Rate",
    "Value",
  ];
  const dayOptions = [
    {
      value: "",
      label: "All",
    },
    {
      value: "Disable",
      label: "Inside",
    },
    {
      value: "Active",
      label: "Outside ",
    },
     ];
    const dayOptions1 = [
      {
        value: "today",
        label: "Today",
      },
      {
        value: "yesterday",
        label: "Yesterday",
      },
      {
        value: "month",
        label: "This Month ",
      },
  ];
 const handleDayChange = (label) => {
    const selectedFilter = dayOptions.find((item) => item.label === label);
    setInout(selectedFilter?.value || "all");
  };
  const handleDayChange1 = (label) => {
    const selectedFilter1 = dayOptions1.find((item) => item.label === label);
    setDaysTab(selectedFilter1?.value || "today");
  };
  const getProducts = () => {
    setLoading(true)
   var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      managerId: managerId,
      searchKeyword: itemSearch,
      outsourcedItem: inout,
      day: daysTab ,
      startDate: startDate ? startDate : '',
      endDate: endDate ? endDate : '',
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    fetch(process.env.REACT_APP_URL + "inventory/getProducts", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.status === "success") {
          setLoading(false)

          setData(result?.data);

        } else {
          console.error("API Response Error:", result);
        }
      })
      .catch((error) => console.error("Fetch Error:", error))
      .finally(() => {
        setLoading(false)

      })
  };
  useEffect(() => {
    getProducts()
  }, [startDate, endDate, itemSearch, daysTab,inout])
  return (
   <React.Fragment>
    <div
        style={{ backgroundColor: mode ? "#232529" : "#D9DCE5", }} className={styles.main} >
        <div className={`${styles.mainCon} ${mode ? styles.mainConDark : styles.mainConLight}`}>
          <div className={styles.order_top}>

            <div className={styles.order_top_left}>
              <TopTabBar text={"Inventory Report"} />

            </div>
            <div className={styles.order_right}>
              <div>
                <SlidingFilter2
                  itemList={dayOptions.map((item) => item.label)}
                  setFilter={handleDayChange}
                  selectedItem={
                    dayOptions.find((item) => item.value === inout)?.label
                  }            />
              </div>
              <div>
              <SlidingFilter
          itemList={dayOptions1.map((item) => item.label)}
                  setFilter={handleDayChange1}
                  selectedItem={
                    dayOptions1.find((item) => item.value === daysTab)?.label
                  } />
              </div>
              <div>
                <InputTypedate
                  text={"From"}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setDaysTab("");
                  }}/>
              </div>
              <div>
                <InputTypedate
                  text={"To"}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setDaysTab("");
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{  }} className={styles.inventory_search_bar}>
            <SearchBar
              placeholder={"Search by Collection"}
              value={itemSearch}
              onChange={(e) => setItemSearch(e.target.value)}            />
          </div>
          <div className={styles.tablemain1}>
            <ul className={styles.order_data_list}>
              <li
                className={`${styles.order_data_list_row_header} ${mode ? styles.order_data_list_row_header_dark : ""
                  }`}            >
                {orderHeader.map((h) => (
                  <div style={{ textAlign: "center" }} className={styles.entry} key={h}>
                    {h}
                  </div>
                ))}
              </li>
              <div className={styles.order_data_list_container12}>
                {loading ? (
                  <div style={{ margin: "1rem auto" }}>
                    <Spinner height={80} width={80} />
                  </div>
                ) : data?.data?.length === 0 ? (
                  <img
                    src="/assets/updatedAssets/noDATA.png"
                    alt="img"
                    style={{
                      height: "12rem",
                      width: "16rem",
                      margin: "1rem auto",
                    }}
                  />
                ) : (
                  <>
                    {data?.getProducts?.map((d, i) => (
                      <li style={{ textAlign: "center", cursor: 'pointer' }}
                        key={i}
                        className={`${styles.order_data_list_row2} ${mode ? styles.order_data_list_row_dark : ""
                          }`}

                        onClick={() => {
                          navigate("/Inventryreport/products", { state: { product_id: d?._id } });
                        }}                    >
                        <div className={styles.entry}>{d.product_name}</div>
                        <div className={styles.entry}>{d.outsourcedItem}</div>
                        <div className={styles.entry}>{d.qty}</div>
                        <div className={styles.entry}>{""}</div>
                        <div className={styles.entry}>{d.price}</div>
                    </li>
                    ))}
                  </>
                )} 
              </div>
            </ul>
         </div>
         <div style={{ backgroundColor: mode ? "#2C2E33CC" : " white", left: "0", color: mode ? "white" : "black" }} className={styles.button_div}>
            <div className={styles.btn_flex} style={{ color: mode ? "white" : " black", }} >
              <li className={styles.grand_total_list}>
                <div className={styles.grand_total_label}>Grand Total</div>
                <div className={styles.grand_total_count}>{data?.totalCount}</div>
                <div className={styles.grand_total_qty}>{data?.grandTotal?.qty}</div>
                <div>{data?.grandTotal?.price}</div>
              </li>
          </div>
          </div>
   </div>
     </div>
    </React.Fragment>

  );                                                           

};

export default Inventryreportdetails;