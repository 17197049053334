import React, { useState, useEffect, useRef, useCallback } from "react";
import { useAuth } from "../../context/Auth";
import { NavbarArrowDownDarkIcon, NavbarArrowDownIcon } from "../../icons/Icon";
import styles from "./FromComponent.module.css";

const VerticalDropDown2 = ({
  label,
  options,
  dropDownText,
  star,
  value,
  multiple = false,
  onchange,
  height,
  fontSize,
  dropDownOpen,
  ref,
  disableOpen = false,
  errorText
}) => {
  const dropdownRef = useRef(null);
  const itemRefs = useRef([]);
  const { mode } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(multiple ? [] : null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setSelectedOptions(value || (multiple ? [] : null));
  }, [value, multiple]);

  useEffect(() => {
    if (dropDownOpen) {
      setIsOpen(true);
    }
  }, [dropDownOpen]);

  const handleOptionSelect = useCallback(
    (option) => {
      if (multiple) {
        setSelectedOptions((prevSelected) => {
          const newSelection = prevSelected.includes(option)
            ? prevSelected.filter((item) => item !== option)
            : [...prevSelected, option];
          const uniqueSelection = Array.from(
            new Set(newSelection?.map((o) => o.value))
          )?.map((value) => newSelection.find((o) => o.value === value));

          setTimeout(() => {
            onchange(uniqueSelection);
          }, 0);

          return uniqueSelection;
        });
        setIsOpen(false);
      } else {
        setTimeout(() => {
          onchange(option);
          ref?.current?.focus();
        }, 0);
        setIsOpen(false);
      }
    },
    [multiple, onchange, ref]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (!isOpen) return;

      if (event.key === "ArrowDown") {
        event.preventDefault();
        setFocusedIndex((prevIndex) => {
          const newIndex = prevIndex < options.length - 1 ? prevIndex + 1 : 0;
          itemRefs.current[newIndex]?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          return newIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setFocusedIndex((prevIndex) => {
          const newIndex = prevIndex > 0 ? prevIndex - 1 : options.length - 1;
          itemRefs.current[newIndex]?.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          return newIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (focusedIndex >= 0 && focusedIndex < options.length) {
          handleOptionSelect(options[focusedIndex]);
        }
      } else if (event.key === "Escape") {
        setIsOpen(false);
      }
    },
    [focusedIndex, handleOptionSelect, isOpen, options]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, handleKeyDown]);

  const handleItemRemove = (option) => {
    const newSelection = selectedOptions.filter((item) => item !== option);
    setSelectedOptions(newSelection);
    onchange(newSelection);
  };

  return (
    <div className={styles.vertical_dropdown_container} ref={dropdownRef}>
      <label
        className={styles.vertical_dropdown_label}
        style={mode ? { color: "#fff" } : {}}
      >
        {label}
        {star && <span style={{ color: "red" }}>*</span>}
      </label>
      <div
        className={styles.vertical_custom_select}
        style={mode ? { border: "none" } : {}}
      >
        <div
          className={styles.vertical_dropdown_trigger}
          style={{
            height: height && height,
            fontSize: fontSize && fontSize,
            backgroundColor: mode && "#1b1d21",
            borderColor: "#1b1d21",
            cursor: disableOpen ? "text" : "pointer",
          }}
          onClick={() => {
            if (!disableOpen) setIsOpen(!isOpen);
          }}
        >
          <div
            className={styles.vertical_dropdown_placeholder}
          >
            {!multiple &&
              (value ? (
                <span
                  style={{
                    fontSize: fontSize && fontSize,
                    color: mode ? "#FFFFFF" : "#1b1d21",
                  }}
                >
                  {value.label}
                </span>
              ) : (
                <span style={{ fontSize: fontSize && fontSize }}>
                  {dropDownText}
                </span>
              ))}
            {multiple &&
              (value ? (
                <span style={{ fontSize: fontSize && fontSize }}>
                  Select States
                </span>
              ) : (
                <span style={{ fontSize: fontSize && fontSize }}>
                  {dropDownText}
                </span>
              ))}
            {!disableOpen && (
              <>
                {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />}
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <ul
            className={styles.vertical_dropdown_list}
            style={
              mode ? { backgroundColor: "#232529", borderColor: "#232529" } : {}
            }
          >
            {options?.map((option, index) => (
              <li
                key={index}
                ref={(el) => (itemRefs.current[index] = el)}
                className={`${styles.vertical_dropdown_item} 
                          ${mode ? styles.vertical_dropdown_item_dark : ""} 
                          ${focusedIndex === index
                    ? mode
                      ? styles.focused_item_dark
                      : styles.focused_item
                    : ""
                  }`}
                onClick={() => handleOptionSelect(option)}
                style={{
                  fontSize: fontSize && fontSize,
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {multiple && (
        <div className={styles.selected_items}>
          {selectedOptions.length > 0 ? (
            selectedOptions?.map((opt, index) => (
              <span
                key={index}
                className={styles.selected_item}
                onClick={() => handleItemRemove(opt)}
              >
                {opt.value}
                <span className={styles.remove_item}>&times;</span>{" "}
              </span>
            ))
          ) : (
            <span
              className={styles.placeholder_text}
              style={mode ? { color: "#fff" } : {}}
            >
              No State Selected
            </span>
          )}
        </div>
      )}
      {errorText &&  <div className={styles.ErrorText}> <span>{errorText}</span> </div>}
      {/* <div className={styles.ErrorText}> <span>{"errorText"}</span> </div> */}
    </div>
  );
};

export default VerticalDropDown2;

// import React, { useState, useEffect, useRef, useCallback } from "react";
// import { useAuth } from "../../context/Auth";
// import { NavbarArrowDownDarkIcon, NavbarArrowDownIcon } from "../../icons/Icon";
// import styles from "./FromComponent.module.css";

// const VerticalDropDown2 = ({
//   label,
//   options,
//   dropDownText,
//   star,
//   value,
//   multiple = false,
//   onchange,
//   height,
//   fontSize,
//   dropDownOpen,
//   ref,
//   disableOpen = false,
// }) => {
//   const dropdownRef = useRef(null);
//   const { mode } = useAuth();
//   const [isOpen, setIsOpen] = useState(false);
//   const [focusedIndex, setFocusedIndex] = useState(0);
//   const [selectedOptions, setSelectedOptions] = useState(multiple ? [] : null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };

//     if (isOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isOpen]);

//   useEffect(() => {
//     setSelectedOptions(value || (multiple ? [] : null));
//   }, [value, multiple]);

//   useEffect(() => {
//     if (dropDownOpen) {
//       setIsOpen(true);
//     }
//   }, [dropDownOpen]);

//   const handleOptionSelect = useCallback(
//     (option) => {
//       if (multiple) {
//         setSelectedOptions((prevSelected) => {
//           const newSelection = prevSelected.includes(option)
//             ? prevSelected.filter((item) => item !== option)
//             : [...prevSelected, option];
//           const uniqueSelection = Array.from(
//             new Set(newSelection?.map((o) => o.value))
//           )?.map((value) => newSelection.find((o) => o.value === value));

//           setTimeout(() => {
//             onchange(uniqueSelection);
//           }, 0);

//           return uniqueSelection;
//         });
//         setIsOpen(false);
//       } else {
//         setTimeout(() => {
//           onchange(option);
//           ref?.current?.focus();
//         }, 0);
//         setIsOpen(false);
//       }
//     },
//     [multiple, onchange, ref]
//   );

//   const handleKeyDown = useCallback(
//     (event) => {
//       if (!isOpen) return;

//       if (event.key === "ArrowDown") {
//         event.preventDefault();
//         setFocusedIndex((prevIndex) =>
//           prevIndex < options.length - 1 ? prevIndex + 1 : 0
//         );
//       } else if (event.key === "ArrowUp") {
//         event.preventDefault();
//         setFocusedIndex((prevIndex) =>
//           prevIndex > 0 ? prevIndex - 1 : options.length - 1
//         );
//       } else if (event.key === "Enter") {
//         event.preventDefault();
//         if (focusedIndex >= 0 && focusedIndex < options.length) {
//           handleOptionSelect(options[focusedIndex]);
//         }
//       } else if (event.key === "Escape") {
//         setIsOpen(false);
//       }
//     },
//     [focusedIndex, handleOptionSelect, isOpen, options]
//   );

//   useEffect(() => {
//     if (isOpen) {
//       document.addEventListener("keydown", handleKeyDown);
//     } else {
//       document.removeEventListener("keydown", handleKeyDown);
//     }

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [isOpen, focusedIndex, options, handleKeyDown]);

//   const handleItemRemove = (option) => {
//     const newSelection = selectedOptions.filter((item) => item !== option);
//     setSelectedOptions(newSelection);
//     onchange(newSelection);
//   };

//   return (
//     <div className={styles.vertical_dropdown_container} ref={dropdownRef}>
//       <label
//         className={styles.vertical_dropdown_label}
//         style={mode ? { color: "#fff" } : {}}
//       >
//         {label}
//         {star && <span style={{ color: "red" }}>*</span>}
//       </label>
//       <div
//         className={styles.vertical_custom_select}
//         style={mode ? { border: "none" } : {}}
//       >
//         <div
//           className={styles.vertical_dropdown_trigger}
//           style={{
//             height: height && height,
//             fontSize: fontSize && fontSize,
//             backgroundColor: mode && "#1b1d21",
//             borderColor: "#1b1d21",
//             cursor: disableOpen ? "text" : "pointer",
//           }}
//           // onClick={() => setIsOpen(!isOpen)}
//           onClick={() => {
//             if (!disableOpen) setIsOpen(!isOpen);
//           }}
//         >
//           <div
//             className={styles.vertical_dropdown_placeholder}
//             style={{ color: mode ? "#fff" : "#646b88" }}
//           >
//             {!multiple &&
//               (value ? (
//                 value.label
//               ) : (
//                 <span
//                   style={{
//                     fontSize: "14px",
//                     fontWeight: "400",
//                     color: mode && "#9C9797",
//                   }}
//                 >
//                   {dropDownText}
//                 </span>
//               ))}
//             {multiple &&
//               (value ? (
//                 <span
//                   style={{
//                     fontSize: "14px",
//                     fontWeight: "400",
//                     color: mode && "#9C9797",
//                   }}
//                 >
//                   Select States
//                 </span>
//               ) : (
//                 <span
//                   style={{
//                     fontSize: "14px",
//                     fontWeight: "400",
//                     color: mode && "#9C9797",
//                   }}
//                 >
//                   {dropDownText}
//                 </span>
//               ))}
//             {!disableOpen && (
//               <>
//                 {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />}
//               </>
//             )}
//           </div>
//         </div>
//         {isOpen && (
//           <ul
//             className={styles.vertical_dropdown_list}
//             style={
//               mode ? { backgroundColor: "#232529", borderColor: "#232529" } : {}
//             }
//           >
//             {options?.map((option, index) => (
//               <li
//                 key={index}
//                 className={`${styles.vertical_dropdown_item}
//                           ${mode ? styles.vertical_dropdown_item_dark : ""}
//                           ${
//                             focusedIndex === index
//                               ? mode
//                                 ? styles.focused_item_dark
//                                 : styles.focused_item
//                               : ""
//                           }`}
//                 onClick={() => handleOptionSelect(option)}
//                 style={{
//                   fontSize: fontSize && fontSize,
//                 }}
//               >
//                 {option.label}
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//       {multiple && (
//         <div className={styles.selected_items}>
//           {selectedOptions.length > 0 ? (
//             selectedOptions?.map((opt, index) => (
//               <span
//                 key={index}
//                 className={styles.selected_item}
//                 onClick={() => handleItemRemove(opt)}
//               >
//                 {opt.value}
//                 <span className={styles.remove_item}>&times;</span>{" "}
//               </span>
//             ))
//           ) : (
//             <span
//               className={styles.placeholder_text}
//               style={mode ? { color: "#fff" } : {}}
//             >
//               No State Selected
//             </span>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default VerticalDropDown2;

// import React, { useState, useEffect, useRef, useCallback } from "react";
// import { useAuth } from "../../context/Auth";
// import { NavbarArrowDownDarkIcon, NavbarArrowDownIcon } from "../../icons/Icon";
// import styles from "./FromComponent.module.css";

// const VerticalDropDown2 = ({
//   label,
//   options,
//   dropDownText,
//   star,
//   value,
//   multiple = false,
//   onchange,
//   height,
//   fontSize,
//   dropDownOpen,
//   shippingRef,
// }) => {
//   const dropdownRef = useRef(null);
//   const { mode } = useAuth();
//   const [isOpen, setIsOpen] = useState(false);
//   const [focusedIndex, setFocusedIndex] = useState(0);
//   const [selectedOptions, setSelectedOptions] = useState(multiple ? [] : null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };

//     if (isOpen) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isOpen]);

//   useEffect(() => {
//     setSelectedOptions(value || (multiple ? [] : null));
//   }, [value, multiple]);

//   useEffect(() => {
//     if (dropDownOpen) {
//       setIsOpen(true);
//     }
//   }, [dropDownOpen]);

//   const handleOptionSelect = useCallback(
//     (option) => {
//       if (multiple) {
//         setSelectedOptions((prevSelected) => {
//           const newSelection = prevSelected.includes(option)
//             ? prevSelected.filter((item) => item !== option)
//             : [...prevSelected, option];
//           const uniqueSelection = Array.from(
//             new Set(newSelection?.map((o) => o.value))
//           )?.map((value) => newSelection.find((o) => o.value === value));

//           setTimeout(() => {
//             onchange(uniqueSelection);
//           }, 0);

//           return uniqueSelection;
//         });
//         setIsOpen(false);
//       } else {
//         setTimeout(() => {
//           onchange(option);
//           shippingRef.current?.focus();
//         }, 0);
//         setIsOpen(false);
//       }
//     },
//     [multiple, onchange, shippingRef]
//   );

//   const handleKeyDown = useCallback(
//     (event) => {
//       if (!isOpen) return;

//       if (event.key === "ArrowDown") {
//         event.preventDefault();
//         setFocusedIndex((prevIndex) =>
//           prevIndex < options.length - 1 ? prevIndex + 1 : 0
//         );
//       } else if (event.key === "ArrowUp") {
//         event.preventDefault();
//         setFocusedIndex((prevIndex) =>
//           prevIndex > 0 ? prevIndex - 1 : options.length - 1
//         );
//       } else if (event.key === "Enter") {
//         event.preventDefault();
//         if (focusedIndex >= 0 && focusedIndex < options.length) {
//           handleOptionSelect(options[focusedIndex]);
//         }
//       } else if (event.key === "Escape") {
//         setIsOpen(false);
//       }
//     },
//     [focusedIndex, handleOptionSelect, isOpen, options]
//   );

//   useEffect(() => {
//     if (isOpen) {
//       document.addEventListener("keydown", handleKeyDown);
//     } else {
//       document.removeEventListener("keydown", handleKeyDown);
//     }

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [isOpen, focusedIndex, options, handleKeyDown]);

//   const handleItemRemove = (option) => {
//     const newSelection = selectedOptions.filter((item) => item !== option);
//     setSelectedOptions(newSelection);
//     onchange(newSelection);
//   };

//   return (
//     <div className={styles.vertical_dropdown_container} ref={dropdownRef}>
//       <label
//         className={styles.vertical_dropdown_label}
//         style={mode ? { color: "#fff" } : {}}
//       >
//         {label}
//         {star && <span style={{ color: "red" }}>*</span>}
//       </label>
//       <div
//         className={styles.vertical_custom_select}
//         style={mode ? { border: "none" } : {}}
//       >
//         <div
//           className={styles.vertical_dropdown_trigger}
//           style={{
//             height: height && height,
//             fontSize: fontSize && fontSize,
//             backgroundColor: mode && "#1b1d21",
//             borderColor: "#1b1d21",
//           }}
//           onClick={() => setIsOpen(!isOpen)}
//         >
//           <div
//             className={styles.vertical_dropdown_placeholder}
//             style={{ color: mode ? "#fff" : "#646b88" }}
//           >
//             {!multiple &&
//               (value ? (
//                 value.label
//               ) : (
//                 <span
//                   style={{
//                     fontSize: "14px",
//                     fontWeight: "400",
//                     color: mode && "#9C9797",
//                   }}
//                 >
//                   {dropDownText}
//                 </span>
//               ))}
//             {multiple &&
//               (value ? (
//                 <span
//                   style={{
//                     fontSize: "14px",
//                     fontWeight: "400",
//                     color: mode && "#9C9797",
//                   }}
//                 >
//                   Select States
//                 </span>
//               ) : (
//                 <span
//                   style={{
//                     fontSize: "14px",
//                     fontWeight: "400",
//                     color: mode && "#9C9797",
//                   }}
//                 >
//                   {dropDownText}
//                 </span>
//               ))}
//             {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />}
//           </div>
//         </div>
//         {isOpen && (
//           <ul
//             className={styles.vertical_dropdown_list}
//             style={
//               mode ? { backgroundColor: "#232529", borderColor: "#232529" } : {}
//             }
//           >
//             {options?.map((option, index) => (
//               <li
//                 key={index}
//                 className={`${styles.vertical_dropdown_item}
//                           ${mode ? styles.vertical_dropdown_item_dark : ""}
//                           ${
//                             focusedIndex === index
//                               ? mode
//                                 ? styles.focused_item_dark
//                                 : styles.focused_item
//                               : ""
//                           }`}
//                 onClick={() => handleOptionSelect(option)}
//                 style={{
//                   fontSize: fontSize && fontSize,
//                 }}
//               >
//                 {option.label}
//               </li>
//             ))}
//           </ul>
//         )}
//       </div>
//       {multiple && (
//         <div className={styles.selected_items}>
//           {selectedOptions.length > 0 ? (
//             selectedOptions?.map((opt, index) => (
//               <span
//                 key={index}
//                 className={styles.selected_item}
//                 onClick={() => handleItemRemove(opt)}
//               >
//                 {opt.value}
//                 <span className={styles.remove_item}>&times;</span>{" "}
//               </span>
//             ))
//           ) : (
//             <span
//               className={styles.placeholder_text}
//               style={mode ? { color: "#fff" } : {}}
//             >
//               No State Selected
//             </span>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default VerticalDropDown2;
