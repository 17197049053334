import React, { createContext, useState, useContext, useEffect } from "react";
import styles from "./Toast.module.css";
import {
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from "../../icons/Icon";

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children, mode }) => {
  const [toasts, setToasts] = useState([]);

  const show = (message, type = "info") => {
    const newToast = { id: Date.now(), message, type };

    const existingToasts = toasts.filter((toast) => toast.type === type);
    if (existingToasts.length >= 3) return;

    setToasts((prev) => [...prev, newToast]);

    setTimeout(() => {
      setToasts((prev) => prev.filter((toast) => toast.id !== newToast.id));
    }, 4000);
  };

  useEffect(() => {
    if (toasts.length > 5) {
      setToasts((prev) => prev.slice(toasts.length - 5));
    }
  }, [toasts]);

  const getIcon = (type) => {
    switch (type) {
      case "success":
        return <SuccessIcon />;
      case "error":
        return <ErrorIcon />;
      case "info":
        return <InfoIcon />;
      case "warning":
        return <WarningIcon />;
      default:
        return null;
    }
  };

  return (
    <ToastContext.Provider value={{ show }}>
      {children}
      <div className={styles.toastContainer}>
        {toasts.map((toast, index) => (
          <div
            className={`${styles.toast} ${mode ? styles.toast_dark : ""}`}
            key={toast.id}
            style={{ fontSize: "12px" }}
          >
            {getIcon(toast.type)}
            <p className={styles.message}>{toast.message}</p>
            <div
              className={`${styles.progressWrapper} ${
                styles[`progress_wrapper_${toast.type}`]
              }`}
            >
              <div
                className={`${styles.progressBar} ${
                  styles[`progress_${toast.type}`]
                }`}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
