import styles from "../../../pages/ordermanager/Orders.module.css";
import { CrossIcon } from "../../icons/Icon";

const ItemProperties = ({
  mode,
  list,
  handleCloseModal,
  totalWeight,
  totalCBM,
}) => {
  return (
    <div
      className={styles.item_properties}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      <div className={styles.cross_icon}>
        <span>hidden</span>
        <div onClick={() => handleCloseModal()}>
          <CrossIcon color={mode ? "#FFFFFF" : "#1B1D21"} />
        </div>
      </div>

      <ul className={styles.data_list}>
        <li
          style={{
            backgroundColor: mode ? "#2C2E33" : "",
            color: mode ? "#FFFFFF" : "#646B88",
            fontWeight: "600",
          }}
        >
          <div>Item</div>
          <div>Quantity</div>
          <div>Volume</div>
          <div>Weight</div>
        </li>
        <div className={styles.item_properties_scroll}>
          {list.map(({ itemNo, weight, vm, qty }, index) => (
            <li
              style={{ backgroundColor: mode ? "#1B1D21" : "#FFFFFF" }}
              key={index}
            >
              <div> {itemNo}</div>
              <div>{`${qty}`}</div>
              <div>{`${qty} x ${vm}`}</div>
              <div>{`${qty} x ${weight}`}</div>
            </li>
          ))}
        </div>
        <li
          style={{
            backgroundColor: "#4164E3",
            color: "#FFFFFF",
            fontWeight: "600",
          }}
        >
          <div>Total</div>
          <div></div>
          <div>{totalCBM}</div>
          <div>{totalWeight}</div>
        </li>
      </ul>
    </div>
  );
};

export default ItemProperties;
