import React, { useState, useRef, useEffect } from "react";
import {
  Download,
  Email,
  InvoiceWhatsapp,
  Print,
  Send,
  Wallicon,
} from "../../components/icons/Icon";
import { useReactToPrint } from "react-to-print";
import styles from "./Printinvoice.module.css";
import QRCode from "react-qr-code";
import { useAuth } from "../../components/context/Auth";
import { useParams } from "react-router-dom";

function PurchaseInovice() {
  const { managerId } = useAuth();
  const params = useParams();
  const orderId = params.voucherNo;

  const [data, setData] = useState("");

  useEffect(() => {
    if (managerId && orderId) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        voucherNo: orderId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        process.env.REACT_APP_URL + "inventory/purchase/invoice",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData(result.data);
          }
        })
        .catch((error) => {})
    }
  }, [managerId, orderId]);

  function transform(value) {
    if (value) {
      var a = [
        "",
        "One ",
        "Two ",
        "Three ",
        "Four ",
        "Five ",
        "Six ",
        "Seven ",
        "Eight ",
        "Nine ",
        "Ten ",
        "Eleven ",
        "Twelve ",
        "Thirteen ",
        "Fourteen ",
        "Fifteen ",
        "Sixteen ",
        "Seventeen ",
        "Eighteen ",
        "Nineteen ",
      ];
      var b = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];
      let number = parseFloat(value).toFixed(2).split(".");
      let num = parseInt(number[0]);
      let digit = parseInt(number[1]);
      if (num) {
        if (num.toString().length > 9) {
          return "";
        }
        const n = ("000000000" + num)
          .substr(-9)
          .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        const d = ("00" + digit).substr(-2).match(/^(\d{2})$/);
        if (!n) {
          return "";
        }
        let str = "";
        str +=
          Number(n[1]) !== 0
            ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
            : "";
        str +=
          Number(n[2]) !== 0
            ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
            : "";
        str +=
          Number(n[3]) !== 0
            ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
            : "";
        str +=
          Number(n[4]) !== 0
            ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
            : "";
        str +=
          Number(n[5]) !== 0
            ? (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) + "Rupee "
            : "";
        str +=
          Number(d[1]) !== 0
            ? (str !== "" ? "and " : "") +
              (a[Number(d[1])] || b[d[1][0]] + " " + a[d[1][1]]) +
              "Paise Only"
            : "Only";
        return "INR " + str;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  var Inwords = transform(data.totalAmount ? data.totalAmount : 0);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const qty =
    data.itemsdetails &&
    data.itemsdetails.map((obj) => parseInt(obj.qty)).reduce((a, b) => a + b);
  return (
    <React.Fragment>
      <div
        style={{
          background: "#fff",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ background: "#fff", display: "none" }}>
          <PrintInvoice
            ref={componentRef}
            data={data}
            handlePrint={handlePrint}
            Inwords={Inwords}
            qty={qty}
          />
        </div>
        <div style={{ background: "#fff", display: "block" }}>
          <div
            style={{
              background: "#fff",
              width: "13.7cm",
              position: "relative",
              padding: "25px 20px",
            }}
          >
            <h1
              style={{
                fontSize: "16px",
                position: "absolute",
                width: "13.7cm",
                textAlign: "center",
                textDecoration: "underline",
                marginBottom: "0px",
              }}
            >
              Purchase Order
            </h1>
            <div style={{ fontSize: "14px" }}>
              <Wallicon
                style={{ width: "50px", height: "30px", objectFit: "cover" }}
              />
            </div>
            <table
              style={{
                border: "1px solid",
                borderBottom: "none",
                borderCollapse: "collapse",
                fontSize: "12px",
                lineHeight: "13px",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    rowSpan={2}
                    colSpan={4}
                    style={{
                      borderRight: "1px solid",
                      lineHeight: "1.5",
                      verticalAlign: "top",
                    }}
                  >
                    <div>
                      Vendor: <b>{data && data.vendorName}</b>
                    </div>
                    <div>
                      <b>{data && data.warehouse}</b>
                    </div>
                  </td>
                  <td style={{ border: "1px solid", lineHeight: "1.5" }}>
                    <div>Order Id</div>
                    <div>
                      <b>{data.voucherNo}</b>
                    </div>
                  </td>
                  <td style={{ border: "1px solid", lineHeight: "1.5" }}>
                    <div>Dated</div>
                    <div>
                      <b>{data.voucherDate}</b>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3} colSpan={2} style={{ textAlign: "center" }}>
                    <div style={{ marginBottom: "20px", fontSize: "11.4px" }}>
                      <b>Scan Now To Check Our Catalogue</b>
                    </div>
                    <QRCode
                      value={"https://dealer.wallicon.in/store"}
                      size={100}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                height: "300px",
              }}
            >
              <thead>
                <tr style={{ fontSize: "12px", textAlign: "center" }}>
                  <td style={{ border: "1px solid", width: "4%" }}>Sl.No.</td>
                  <td style={{ border: "1px solid", width: "55%" }}>
                    Description of Items
                  </td>
                  <td style={{ border: "1px solid", width: "11%" }}>
                    Quantity
                  </td>
                  <td style={{ border: "1px solid", width: "11%" }}>Rate</td>
                  <td style={{ border: "1px solid", width: "4%" }}>Per</td>
                  <td style={{ border: "1px solid", width: "15%" }}>Amount</td>
                </tr>
              </thead>
              <tbody>
                {data.itemsdetails &&
                  data.itemsdetails.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        fontSize: "11px",
                        fontWeight: "600",
                        color: "#000000",
                        height: "6px",
                      }}
                    >
                      <td
                        style={{ textAlign: "center", borderLeft: "1px solid" }}
                      >
                        {1 + index}
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          borderLeft: "1px solid",
                          fontWeight: "700",
                        }}
                      >
                        {item.itemNo} {item.product_name}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                          fontWeight: "700",
                        }}
                      >
                        {item.qty}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      >
                        {parseFloat(item.price)
                          .toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })
                          .slice(1)}
                      </td>
                      <td
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      >
                        {item.unit}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                          paddingRight: "4px",
                          fontWeight: "700",
                        }}
                      >
                        {(parseFloat(item.price) * parseFloat(item.qty))
                          .toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })
                          .slice(1)}
                      </td>
                    </tr>
                  ))}
                <tr
                  style={{
                    borderBottom: "none",
                    borderLeft: "1px solid",
                    borderRight: "1px solid",
                  }}
                >
                  <th style={{ paddingTop: "" }}></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                </tr>

                <tr
                  style={{
                    fontSize: "10px",
                    fontWeight: "500",
                    color: "#000000",
                    margin: "0px",
                    height: "6px",
                  }}
                >
                  <td style={{ border: "1px solid" }}></td>
                  <td
                    style={{
                      border: "1px solid",
                      textAlign: "right",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Total
                  </td>
                  <td
                    style={{
                      border: "1px solid",
                      textAlign: "right",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    {qty && qty}
                  </td>
                  <td style={{ border: "1px solid" }}></td>
                  <td style={{ border: "1px solid" }}></td>
                  <td
                    style={{
                      border: "1px solid",
                      fontSize: "14px",
                      textAlign: "right",
                      fontWeight: "600",
                    }}
                  >
                    {data.totalAmount
                      ? data.totalAmount.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })
                      : 0}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                lineHeight: "13px",
                border: "1px solid",
                borderTop: "none",
                borderCollapse: "collapse",
                fontSize: "12px",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <div>Amount Chargeable (in words)</div>
                  </td>
                  <td
                    colSpan={3}
                    style={{ textAlign: "right", verticalAlign: "text-top" }}
                  >
                    E. &amp; O.E
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td colSpan={6}>
                    <h4 style={{ margin: "0" }}>{Inwords}</h4>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3}></td>
                  <td colSpan={3}>
                    <div
                      style={{
                        paddingTop: "5px",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "3px",
                        minHeight: "70px",
                      }}
                    >
                      {/* <>
                                                <div style={{ fontSize: '12px', fontWeight: '600' }}>Company's Bank Details</div>
                                                <div>
                                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>Bank Name:</span>
                                                    <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;ICICI BANK</span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>A/c No.:</span>
                                                    <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;777705555807</span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>Branch & IFC Code:</span>
                                                    <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;Aminabad & ICIC0001033</span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>UPI ID:</span>
                                                    <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;MYBANK@BAK9875</span>
                                                </div>
                                            </> */}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{ width: "50%", verticalAlign: "text-top" }}
                  >
                    {/* <u >Delcaration</u>
                                        {data.orderId && !data.orderId.toString().includes("P") &&
                                            <div style={{ fontSize: "12px", fontWeight: "500" }}>
                                                These items should be billed & shipped
                                                by our partnered company
                                            </div>
                                        } */}
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      borderTop: "1px solid",
                      borderLeft: "1px solid",
                      textAlign: "right",
                    }}
                  >
                    <b>For ...</b>
                    <br />
                    <br />
                    Authorised Signatory
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ fontSize: "12px", textAlign: "center" }}>
              THIS IS A COMPUTER GENERATED PURCHASE ORDER.
            </div>
          </div>

          <div className={styles.icons_div} style={{ display: "flex" }}>
            <div onClick={handlePrint}>
              <div className={styles.icons}>
                <Print />
              </div>
              <h6 className={styles.icontext}>Print</h6>
            </div>
            <div>
              <div className={styles.icons}>
                <Download />
              </div>
              <h6 className={styles.icontext}>Download</h6>
            </div>
            <div>
              <div className={styles.icons}>
                <Email />
              </div>
              <h6 className={styles.icontext}>Email</h6>
            </div>
            <div>
              <div className={styles.icons}>
                <InvoiceWhatsapp />
              </div>
              <h6 className={styles.icontext}>Whatsapp</h6>
            </div>
            <div>
              <div className={styles.icons}>
                <Send />
              </div>
              <h6 className={styles.icontext}>Send</h6>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PurchaseInovice;

export class PrintInvoice extends React.Component {
  render() {
    const data = this.props.data;
    const qty = this.props.qty;

    const itemsLength = data.itemsdetails && data.itemsdetails.length / 20;
    const isInt = Number(itemsLength) === itemsLength && itemsLength % 1 === 0;
    const countLenth = isInt ? itemsLength : parseInt(itemsLength) + 1;
    const noPrint = [];
    for (let i = 0; i < countLenth; i++) {
      noPrint.push(i + 1);
    }

    return (
      <div>
        {noPrint &&
          noPrint.map((obj, idx) => (
            <div
              key={idx}
              style={{
                background: "#fff",
                width: "13.7cm",
                position: "relative",
                padding: "25px 20px",
              }}
            >
              <h1
                style={{
                  fontSize: "16px",
                  position: "absolute",
                  width: "13.7cm",
                  textAlign: "center",
                  textDecoration: "underline",
                  marginBottom: "0px",
                }}
              >
                Purchase Order
              </h1>
              <div style={{ fontSize: "14px" }}>
                <Wallicon
                  style={{ width: "50px", height: "30px", objectFit: "cover" }}
                />
              </div>
              <table
                style={{
                  border: "1px solid",
                  borderBottom: "none",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  lineHeight: "13px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      rowSpan={2}
                      colSpan={4}
                      style={{
                        borderRight: "1px solid",
                        lineHeight: "1.5",
                        verticalAlign: "top",
                      }}
                    >
                      <div>
                        Vendor: <b>{data && data.vendorName}</b>
                      </div>
                      <div>
                        <b>{data && data.warehouse}</b>
                      </div>
                    </td>
                    <td style={{ border: "1px solid", lineHeight: "1.5" }}>
                      <div>Order Id</div>
                      <div>
                        <b>{data.voucherNo}</b>
                      </div>
                    </td>
                    <td style={{ border: "1px solid", lineHeight: "1.5" }}>
                      <div>Dated</div>
                      <div>
                        <b>{data.voucherDate}</b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={3} colSpan={2} style={{ textAlign: "center" }}>
                      <div style={{ marginBottom: "20px", fontSize: "11.4px" }}>
                        <b>Scan Now To Check Our Catalogue</b>
                      </div>
                      <QRCode
                        value={"https://dealer.wallicon.in/store"}
                        size={100}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  height: "360px",
                }}
              >
                <thead>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td style={{ border: "1px solid", width: "4%" }}>Sl.No.</td>
                    <td style={{ border: "1px solid", width: "55%" }}>
                      Description of Items
                    </td>
                    <td style={{ border: "1px solid", width: "11%" }}>
                      Quantity
                    </td>
                    <td style={{ border: "1px solid", width: "11%" }}>Rate</td>
                    <td style={{ border: "1px solid", width: "4%" }}>Per</td>
                    <td style={{ border: "1px solid", width: "15%" }}>
                      Amount
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {data.itemsdetails &&
                    data.itemsdetails
                      .slice(idx * 20, 20 * obj)
                      .map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            fontSize: "11px",
                            fontWeight: "600",
                            color: "#000000",
                            height: "6px",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "center",
                              borderLeft: "1px solid",
                            }}
                          >
                            {idx * 20 + (1 + index)}
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              borderLeft: "1px solid",
                              fontWeight: "700",
                            }}
                          >
                            {item.itemNo} {item.product_name}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              borderLeft: "1px solid",
                              borderRight: "1px solid",
                              fontWeight: "700",
                            }}
                          >
                            {item.qty}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              borderLeft: "1px solid",
                              borderRight: "1px solid",
                            }}
                          >
                            {parseFloat(item.price)
                              .toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })
                              .slice(1)}
                          </td>
                          <td
                            style={{
                              borderBottom: "none",
                              borderLeft: "1px solid",
                              borderRight: "1px solid",
                            }}
                          >
                            {item.unit}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              borderLeft: "1px solid",
                              borderRight: "1px solid",
                              paddingRight: "4px",
                              fontWeight: "700",
                            }}
                          >
                            {(parseFloat(item.price) * parseFloat(item.qty))
                              .toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })
                              .slice(1)}
                          </td>
                        </tr>
                      ))}
                  {idx === countLenth - 1 && (
                    <tr
                      style={{
                        borderBottom: "none",
                        borderLeft: "1px solid",
                        borderRight: "1px solid",
                      }}
                    >
                      <th style={{ paddingTop: "" }}></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                    </tr>
                  )}
                  {idx === countLenth - 1 && (
                    <tr
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        color: "#000000",
                        margin: "0px",
                        height: "6px",
                      }}
                    >
                      <td style={{ border: "1px solid" }}></td>
                      <td
                        style={{
                          border: "1px solid",
                          textAlign: "right",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          border: "1px solid",
                          textAlign: "right",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {qty && qty}
                      </td>
                      <td style={{ border: "1px solid" }}></td>
                      <td style={{ border: "1px solid" }}></td>
                      <td
                        style={{
                          border: "1px solid",
                          fontSize: "14px",
                          textAlign: "right",
                          fontWeight: "600",
                        }}
                      >
                        {data.totalAmount
                          ? data.totalAmount.toLocaleString("en-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                          : 0}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <table
                style={{
                  lineHeight: "13px",
                  border: "1px solid",
                  borderTop: "none",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  width: "100%",
                }}
              >
                {idx !== countLenth - 1 ? (
                  <thead>
                    <tr
                      style={{
                        borderTop: "1px solid",
                        height: "150px",
                        textAlign: "right",
                        verticalAlign: "text-top",
                      }}
                    >
                      <td>continued..</td>
                    </tr>
                  </thead>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <div>Amount Chargeable (in words)</div>
                      </td>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: "right",
                          verticalAlign: "text-top",
                        }}
                      >
                        E. &amp; O.E
                      </td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                      <td colSpan={6}>
                        <h4 style={{ margin: "0" }}>{this.props.Inwords}</h4>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                      <td colSpan={3}>
                        <div
                          style={{
                            paddingTop: "5px",
                            fontSize: "12px",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "3px",
                            minHeight: "70px",
                          }}
                        >
                          {/* <>
                                                    <div style={{ fontSize: '12px', fontWeight: '600' }}>Company's Bank Details</div>
                                                    <div>
                                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>Bank Name:</span>
                                                        <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;ICICI BANK</span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>A/c No.:</span>
                                                        <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;777705555807</span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: '10px', fontWeight: '600' }}>Branch & IFC Code:</span>
                                                        <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;Aminabad & ICIC0001033</span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>UPI ID:</span>
                                                        <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;MYBANK@BAK9875</span>
                                                    </div>
                                                </> */}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={3}
                        style={{ width: "50%", verticalAlign: "text-top" }}
                      >
                        <u>Delcaration</u>
                        {data.orderId &&
                          !data.orderId.toString().includes("P") && (
                            <div
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              These items should be billed & shipped by our
                              partnered company
                            </div>
                          )}
                      </td>
                      <td
                        colSpan={3}
                        style={{
                          borderTop: "1px solid",
                          borderLeft: "1px solid",
                          textAlign: "right",
                        }}
                      >
                        {/* <b>For Wallicon Private Limited</b> */}
                        <br />
                        <br />
                        Authorised Signatory
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              <div style={{ fontSize: "12px", textAlign: "center" }}>
                THIS IS A COMPUTER GENERATED PURCHASE ORDER.
              </div>
            </div>
          ))}
      </div>
    );
  }
}
