import React, { useState, useRef, useEffect } from "react";
import {
  Download,
  Email,
  InvoiceWhatsapp,
  Print,
  Send,
  Wallicon,
} from "../../components/icons/Icon";
import { useReactToPrint } from "react-to-print";
import styles from "./Printinvoice.module.css";
import QRCode from "react-qr-code";
import { useAuth } from "../../components/context/Auth";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

function EstimateInvoice() {
  const { managerId } = useAuth();
  const [searchparams] = useSearchParams();
  const orderId = searchparams.get("orderId")
    ? searchparams.get("orderId")
    : "";
  // const orderId = 2
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (managerId && orderId) {
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        managerId: managerId,
        estimateId: orderId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(process.env.REACT_APP_URL + "order/estimateInvoice", requestOptions)
        // fetch("http://192.168.0.117:5055/order//estimateInvoice", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData(result.data);
          }
        })
        .catch((error) => {})
    }
  }, [managerId, orderId]);

  function transform(value) {
    if (value) {
      var a = [
        "",
        "One ",
        "Two ",
        "Three ",
        "Four ",
        "Five ",
        "Six ",
        "Seven ",
        "Eight ",
        "Nine ",
        "Ten ",
        "Eleven ",
        "Twelve ",
        "Thirteen ",
        "Fourteen ",
        "Fifteen ",
        "Sixteen ",
        "Seventeen ",
        "Eighteen ",
        "Nineteen ",
      ];
      var b = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];
      let number = parseFloat(value).toFixed(2).split(".");
      let num = parseInt(number[0]);
      let digit = parseInt(number[1]);
      if (num) {
        if (num.toString().length > 9) {
          return "";
        }
        const n = ("000000000" + num)
          .substr(-9)
          .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        const d = ("00" + digit).substr(-2).match(/^(\d{2})$/);
        if (!n) {
          return "";
        }
        let str = "";
        str +=
          Number(n[1]) !== 0
            ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
            : "";
        str +=
          Number(n[2]) !== 0
            ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
            : "";
        str +=
          Number(n[3]) !== 0
            ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
            : "";
        str +=
          Number(n[4]) !== 0
            ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
            : "";
        str +=
          Number(n[5]) !== 0
            ? (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) + "Rupee "
            : "";
        str +=
          Number(d[1]) !== 0
            ? (str !== "" ? "and " : "") +
              (a[Number(d[1])] || b[d[1][0]] + " " + a[d[1][1]]) +
              "Paise Only"
            : "Only";
        return "INR " + str;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  var Inwords = transform(data.totalAmount ? data.totalAmount : 0);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const createAndDownloadPdf = () => {
    setLoading(true);
    fetch(process.env.REACT_APP_URL + "invoice/estimateinvoicepdf", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
      },
      body: JSON.stringify({ data: data }),
    })
      .then((res) => res.blob())
      .then((result) => {
        const pdfBlob = new Blob([result], { type: "application/pdf" });
        //console.log(result);
        let url = window.URL.createObjectURL(pdfBlob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "estimateinvoice" + orderId + ".pdf";
        a.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div
        style={{
          background: "#fff",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ background: "#fff", display: "none" }}>
          <PrintInvoice1
            ref={componentRef}
            data={data}
            handlePrint={handlePrint}
            Inwords={Inwords}
          />
        </div>

        <div>
          <div className="d-flex justify-content-between ">
            <Link to="/estimateorders" className="text-dark">
              Estimate Orders
            </Link>
            <Link
              to={"/orders/estimate?orderId=" + data.estimateId}
              className="text-dark"
            >
              Order
            </Link>
          </div>
          <div
            style={{
              background: "#fff",
              width: "14.7cm",
              position: "relative",
              padding: "25px 20px",
            }}
          >
            <h1
              style={{
                fontSize: "16px",
                position: "absolute",
                width: "14.7cm",
                textAlign: "center",
                textDecoration: "underline",
                marginBottom: "0px",
              }}
            >
              Estimate Order
            </h1>
            <div style={{ fontSize: "14px" }}>
              <Wallicon
                style={{ width: "50px", height: "30px", objectFit: "cover" }}
              />
            </div>
            <table
              style={{
                border: "1px solid",
                borderBottom: "none",
                borderCollapse: "collapse",
                fontSize: "12px",
                lineHeight: "13px",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td rowSpan={2} colSpan={4} style={{ border: "1px solid" }}>
                    <div>
                      <b>{data.adminInfo && data.adminInfo.storeName}</b>
                    </div>
                    <div>
                      {" "}
                      {data.adminInfo && data.adminInfo.street}{" "}
                      {data.adminInfo && data.adminInfo.city}{" "}
                      {data.adminInfo && data.adminInfo.pincode}
                    </div>
                    <div>
                      GSTUIN: <b>{data.adminInfo && data.adminInfo.gstuin}</b>
                    </div>
                    <div>State: {data.adminInfo && data.adminInfo.state}</div>
                    <div>
                      Contact: {data.adminInfo && data.adminInfo.mobile}
                    </div>
                    <div>Email: {data.adminInfo && data.adminInfo.email}</div>
                  </td>
                  <td style={{ border: "1px solid" }}>
                    <div>Estimate Id</div>
                    <br />
                    <div>
                      <b>{data.estimateId}</b>
                    </div>
                  </td>
                  <td style={{ border: "1px solid" }}>
                    <div>Dated</div>
                    <br />
                    <div>
                      <b>{data.orderDate}</b>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3} colSpan={2} style={{ textAlign: "center" }}>
                    <div style={{ marginBottom: "20px", fontSize: "11.4px" }}>
                      <b>Scan Now To Check Our Catalogue</b>
                    </div>
                    {data.adminInfo && (
                      <QRCode value={data.adminInfo.website} size={100} />
                    )}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid" }}>
                    <div>Sale To :</div>
                    <div>
                      <b>{data.dealerInfo && data.dealerInfo.storeName}</b>
                    </div>
                    <div>
                      {data.dealerInfo && data.dealerInfo.street}{" "}
                      {data.dealerInfo && data.dealerInfo.city}{" "}
                      {data.dealerInfo && data.dealerInfo.district}{" "}
                      {data.dealerInfo && data.dealerInfo.pincode}
                    </div>
                    <div>
                      GSTUIN: <b>{data.dealerInfo && data.dealerInfo.gstuin}</b>
                    </div>
                    <div>State: {data.dealerInfo && data.dealerInfo.state}</div>
                    <div>
                      Contact: {data.dealerInfo && data.dealerInfo.mobile}
                    </div>
                    <div>Email: {data.dealerInfo && data.dealerInfo.email}</div>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderRight: "1px solid" }}>
                    <div>Ship To :</div>
                    <div>
                      <b>{data.shippingInfo && data.shippingInfo.name}</b>
                    </div>
                    <div>
                      {" "}
                      {data.shippingInfo && data.shippingInfo.street}{" "}
                      {data.shippingInfo && data.shippingInfo.city}{" "}
                      {data.shippingInfo && data.shippingInfo.district}{" "}
                      {data.shippingInfo && data.shippingInfo.pincode}
                    </div>
                    <div>
                      GSTUIN:{" "}
                      <b>{data.shippingInfo && data.shippingInfo.gstuin}</b>
                    </div>
                    <div>
                      State: {data.shippingInfo && data.shippingInfo.state}
                    </div>
                    <div>
                      Contact: {data.shippingInfo && data.shippingInfo.mobile}
                    </div>
                    <div>
                      Email: {data.shippingInfo && data.shippingInfo.email}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                height: "270px",
              }}
            >
              <thead>
                <tr style={{ fontSize: "12px", textAlign: "center" }}>
                  <td style={{ border: "1px solid", width: "4%" }}>Sl.No.</td>
                  <td style={{ border: "1px solid", width: "50%" }}>
                    Description of Items
                  </td>
                  <td style={{ border: "1px solid", width: "11%" }}>
                    Quantity
                  </td>
                  <td style={{ border: "1px solid", width: "11%" }}>Rate</td>
                  <td style={{ border: "1px solid", width: "9%" }}>Per</td>
                  <td style={{ border: "1px solid", width: "15%" }}>Amount</td>
                </tr>
              </thead>
              <tbody>
                {data.items &&
                  data.items.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        fontSize: "11px",
                        fontWeight: "600",
                        color: "#000000",
                        height: "6px",
                      }}
                    >
                      <td
                        style={{ textAlign: "center", borderLeft: "1px solid" }}
                      >
                        {1 + index}
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          borderLeft: "1px solid",
                          fontWeight: "700",
                        }}
                      >
                        {item.itemNo} {item.product_name}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                          fontWeight: "700",
                        }}
                      >
                        {item.qty}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      >
                        {parseFloat(item.price)
                          .toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })
                          .slice(1)}
                      </td>
                      <td
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      >
                        {item.unit}
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                          paddingRight: "4px",
                          fontWeight: "700",
                        }}
                      >
                        {(parseFloat(item.price) * parseFloat(item.qty))
                          .toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })
                          .slice(1)}
                      </td>
                    </tr>
                  ))}

                <tr
                  style={{
                    borderBottom: "none",
                    borderLeft: "1px solid",
                    borderRight: "1px solid",
                  }}
                >
                  <th style={{ paddingTop: "" }}></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                  <th
                    style={{
                      borderBottom: "none",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                    }}
                  ></th>
                </tr>

                {(data.shippingCharge ||
                  data.manageName ||
                  data.manageValue ||
                  data.roundOff) && (
                  <tr
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      color: "#000000",
                      margin: "0px",
                      height: "6px",
                    }}
                  >
                    <td style={{ borderLeft: "1px solid" }}></td>
                    <td
                      style={{
                        borderLeft: "1px solid",
                        textAlign: "right",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {data.shippingCharge ? "Courier Charges" : ""}
                      {data.manageName && (
                        <div style={{ textTransform: "capitalize" }}>
                          {data.manageName}
                        </div>
                      )}
                      <div>{data.roundOff && "Round-off"}</div>
                    </td>
                    <td
                      style={{
                        borderLeft: "1px solid",
                        fontSize: "14px",
                        textAlign: "center",
                      }}
                    ></td>
                    <td style={{ borderLeft: "1px solid" }}></td>
                    <td style={{ borderLeft: "1px solid" }}></td>
                    <td
                      style={{
                        borderLeft: "1px solid",
                        borderRight: "1px solid",
                        fontSize: "14px",
                        textAlign: "right",
                        fontWeight: "600",
                      }}
                    >
                      {data.shippingCharge !== 0 &&
                        data.shippingCharge
                          .toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })
                          .replace("\u20b9", "")}
                      {data.manageValue && (
                        <div>
                          {data.manageValue
                            .toLocaleString("en-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .replace("\u20b9", "")}
                        </div>
                      )}
                      {data.roundOff && (
                        <div>
                          {data.roundOff
                            .toLocaleString("en-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .replace("\u20b9", "")}
                        </div>
                      )}
                    </td>
                  </tr>
                )}

                <tr
                  style={{
                    fontSize: "10px",
                    fontWeight: "500",
                    color: "#000000",
                    margin: "0px",
                    height: "6px",
                  }}
                >
                  <td style={{ border: "1px solid" }}></td>
                  <td
                    style={{
                      border: "1px solid",
                      textAlign: "right",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Total
                  </td>
                  <td
                    style={{
                      border: "1px solid",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    {data && data.qty}
                  </td>
                  <td style={{ border: "1px solid" }}></td>
                  <td style={{ border: "1px solid" }}></td>
                  <td
                    style={{
                      border: "1px solid",
                      fontSize: "14px",
                      textAlign: "right",
                      fontWeight: "600",
                    }}
                  >
                    {data.totalAmount
                      ? data.totalAmount.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })
                      : 0}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                lineHeight: "13px",
                border: "1px solid",
                borderTop: "none",
                borderCollapse: "collapse",
                fontSize: "12px",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <div>Amount Chargeable (in words)</div>
                  </td>
                  <td
                    colSpan={3}
                    style={{ textAlign: "right", verticalAlign: "text-top" }}
                  >
                    E. &amp; O.E
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td colSpan={6}>
                    <b style={{ margin: "0" }}>{Inwords}</b>
                  </td>
                </tr>

                <tr>
                  <td colSpan={3}></td>
                  <td colSpan={3}>
                    <div
                      style={{
                        paddingTop: "5px",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "3px",
                        minHeight: "70px",
                      }}
                    >
                      {/* <>
                                                <div style={{ fontSize: '12px', fontWeight: '600' }}>Company's Bank Details</div>
                                                <div>
                                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>Bank Name:</span>
                                                    <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;ICICI BANK</span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>A/c No.:</span>
                                                    <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;777705555807</span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '10px', fontWeight: '600' }}>Branch & IFC Code:</span>
                                                    <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;Aminabad & ICIC0001033</span>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: '12px', fontWeight: '600' }}>UPI ID:</span>
                                                    <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;MYBANK@BAK9875</span>
                                                </div>
                                            </> */}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{ width: "50%", verticalAlign: "text-top" }}
                  >
                    <u>Delcaration</u>
                    {data.orderId && data.orderId.toString().includes("P") && (
                      <div style={{ fontSize: "12px", fontWeight: "500" }}>
                        These items should be billed & shipped by our partnered
                        company
                      </div>
                    )}
                  </td>
                  <td
                    colSpan={3}
                    style={{
                      borderTop: "1px solid",
                      borderLeft: "1px solid",
                      textAlign: "right",
                    }}
                  >
                    <b>For ...</b>
                    <br />
                    <br />
                    Authorised Signatory
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ fontSize: "12px", textAlign: "center" }}>
              THIS IS A COMPUTER GENERATED ESTIMATE ORDER.
            </div>
          </div>

          <div className={styles.icons_div} style={{ display: "flex" }}>
            <div onClick={handlePrint} role="button">
              <div className={styles.icons}>
                <Print />
              </div>
              <h6 className={styles.icontext}>Print</h6>
            </div>
            <button
              className="border-0 bg-transparent"
              disabled={loading ? true : false}
              onClick={() => createAndDownloadPdf()}
            >
              <div className={styles.icons}>
                {loading ? (
                  <div className={"order_loadingMain"}>
                    <img src="/wallicon.gif" alt="walliconGIF" width={30} />
                  </div>
                ) : (
                  <Download />
                )}
              </div>
              <h6 className={styles.icontext}>Download</h6>
            </button>
            <div role="button" onClick={() => setModalShow(true)}>
              <div className={styles.icons}>
                <Email />
              </div>
              <h6 className={styles.icontext}>Email</h6>
            </div>
            <div>
              <div className={styles.icons}>
                <InvoiceWhatsapp />
              </div>
              <h6 className={styles.icontext}>Whatsapp</h6>
            </div>
            <div>
              <div className={styles.icons}>
                <Send />
              </div>
              <h6 className={styles.icontext}>Send</h6>
            </div>
          </div>
        </div>
        {/* <button onClick={() => downloadFile('http://192.168.0.117:3000/saleinvoice.pdf')}>
                    Download Ho</button>

                <a href="http://192.168.0.117:3000/saleinvoice.pdf" download="Brochure">Download</a> */}
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Centered Modal</h4>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={() => setModalShow(false)}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EstimateInvoice;

export class PrintInvoice1 extends React.Component {
  render() {
    const data = this.props.data;

    const itemsLength = data.items && data.items.length / 14;
    const isInt = Number(itemsLength) === itemsLength && itemsLength % 1 === 0;
    const countLenth = isInt ? itemsLength : parseInt(itemsLength) + 1;
    const noPrint = [];
    for (let i = 0; i < countLenth; i++) {
      noPrint.push(i + 1);
    }

    return (
      <div>
        {noPrint &&
          noPrint.map((obj, idx) => (
            <div
              key={idx}
              style={{
                background: "#fff",
                minWidth: "100%",
                height: "14.8cm",
                position: "relative",
                padding: "25px 20px",
              }}
            >
              <h1
                style={{
                  fontSize: "16px",
                  position: "absolute",
                  minWidth: "100%",
                  textAlign: "center",
                  textDecoration: "underline",
                  marginBottom: "0px",
                }}
              >
                Estimate Order
              </h1>
              <div style={{ fontSize: "14px" }}>
                <Wallicon
                  style={{ width: "50px", height: "30px", objectFit: "cover" }}
                />
                {noPrint.length > 1 && (
                  <div style={{ float: "right" }}>
                    Page {idx + 1 + "/" + noPrint.length}
                  </div>
                )}
              </div>

              <table
                style={{
                  border: "1px solid",
                  borderBottom: "none",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  lineHeight: "13px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      rowSpan={2}
                      colSpan={4}
                      style={{
                        border: "1px solid",
                        width: "285px",
                        overflow: "hidden",
                      }}
                    >
                      <div>
                        <b>{data.adminInfo && data.adminInfo.storeName}</b>
                      </div>
                      <div>
                        {" "}
                        {data.adminInfo && data.adminInfo.street}{" "}
                        {data.adminInfo && data.adminInfo.city}{" "}
                        {data.adminInfo && data.adminInfo.pincode}
                      </div>
                      <div>
                        GSTUIN: <b>{data.adminInfo && data.adminInfo.gstuin}</b>
                      </div>
                      <div>State: {data.adminInfo && data.adminInfo.state}</div>
                      <div>
                        Contact: {data.adminInfo && data.adminInfo.mobile}
                      </div>
                      <div>
                        Email: {data.adminInfo.email && data.adminInfo.email}
                      </div>
                    </td>
                    <td style={{ border: "1px solid" }}>
                      <div>Estimate Id</div>
                      <br />
                      <div>
                        <b>{data.estimateId}</b>
                      </div>
                    </td>
                    <td style={{ border: "1px solid" }}>
                      <div>Dated</div>
                      <br />
                      <div>
                        <b>{data.orderDate}</b>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={3} colSpan={2} style={{ textAlign: "center" }}>
                      <div style={{ marginBottom: "20px", fontSize: "11.4px" }}>
                        <b>Scan Now To Check Our Catalogue</b>
                      </div>
                      {data.adminInfo && (
                        <QRCode
                          value={data.adminInfo && data.adminInfo.website}
                          size={100}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid" }}>
                      <div>Sale To :</div>
                      <div>
                        <b>{data.dealerInfo && data.dealerInfo.storeName}</b>
                      </div>
                      <div>
                        {data.dealerInfo && data.dealerInfo.street}{" "}
                        {data.dealerInfo && data.dealerInfo.city}{" "}
                        {data.dealerInfo && data.dealerInfo.district}{" "}
                        {data.dealerInfo && data.dealerInfo.pincode}
                      </div>
                      <div>
                        GSTUIN:{" "}
                        <b>{data.dealerInfo && data.dealerInfo.gstuin}</b>
                      </div>
                      <div>
                        State: {data.dealerInfo && data.dealerInfo.state}
                      </div>
                      <div>
                        Contact: {data.dealerInfo && data.dealerInfo.mobile}
                      </div>
                      <div>
                        Email: {data.dealerInfo && data.dealerInfo.email}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderRight: "1px solid" }}>
                      <div>Ship To :</div>
                      <div>
                        <b>{data.shippingInfo && data.shippingInfo.name}</b>
                      </div>
                      <div>
                        {" "}
                        {data.shippingInfo && data.shippingInfo.street}{" "}
                        {data.shippingInfo && data.shippingInfo.city}{" "}
                        {data.shippingInfo && data.shippingInfo.district}{" "}
                        {data.shippingInfo && data.shippingInfo.pincode}
                      </div>
                      <div>
                        GSTUIN:{" "}
                        <b>{data.shippingInfo && data.shippingInfo.gstuin}</b>
                      </div>
                      <div>
                        State: {data.shippingInfo && data.shippingInfo.state}
                      </div>
                      <div>
                        Contact: {data.shippingInfo && data.shippingInfo.mobile}
                      </div>
                      <div>
                        Email:{" "}
                        {data.shippingInfo.email && data.shippingInfo.email}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  height: "270px",
                }}
              >
                <thead>
                  <tr style={{ fontSize: "12px", textAlign: "center" }}>
                    <td style={{ border: "1px solid", width: "4%" }}>Sl.No.</td>
                    <td style={{ border: "1px solid", width: "55%" }}>
                      Description of Items
                    </td>
                    <td style={{ border: "1px solid", width: "11%" }}>
                      Quantity
                    </td>
                    <td style={{ border: "1px solid", width: "11%" }}>Rate</td>
                    <td style={{ border: "1px solid", width: "4%" }}>Per</td>
                    <td style={{ border: "1px solid", width: "15%" }}>
                      Amount
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {data.items &&
                    data.items.slice(idx * 14, 14 * obj).map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          fontSize: "11px",
                          fontWeight: "600",
                          color: "#000000",
                          height: "6px",
                        }}
                      >
                        <td
                          style={{
                            textAlign: "center",
                            borderLeft: "1px solid",
                          }}
                        >
                          {idx * 14 + (1 + index)}
                        </td>
                        <td
                          style={{
                            textAlign: "left",
                            borderLeft: "1px solid",
                            fontWeight: "700",
                          }}
                        >
                          {item.itemNo} {item.product_name}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            borderLeft: "1px solid",
                            borderRight: "1px solid",
                            fontWeight: "700",
                          }}
                        >
                          {item.qty}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            borderLeft: "1px solid",
                            borderRight: "1px solid",
                          }}
                        >
                          {parseFloat(item.price)
                            .toLocaleString("en-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .slice(1)}
                        </td>
                        <td
                          style={{
                            borderBottom: "none",
                            borderLeft: "1px solid",
                            borderRight: "1px solid",
                          }}
                        >
                          {item.unit}
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            borderLeft: "1px solid",
                            borderRight: "1px solid",
                            paddingRight: "4px",
                            fontWeight: "700",
                          }}
                        >
                          {(parseFloat(item.price) * parseFloat(item.qty))
                            .toLocaleString("en-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                            .slice(1)}
                        </td>
                      </tr>
                    ))}
                  {idx === countLenth - 1 && (
                    <tr
                      style={{
                        borderBottom: "none",
                        borderLeft: "1px solid",
                        borderRight: "1px solid",
                      }}
                    >
                      <th style={{ paddingTop: "" }}></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                      <th
                        style={{
                          borderBottom: "none",
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                        }}
                      ></th>
                    </tr>
                  )}

                  {(data.shippingCharge ||
                    data.manageName ||
                    data.manageValue ||
                    data.roundOff) && (
                    <tr
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        color: "#000000",
                        margin: "0px",
                        height: "6px",
                      }}
                    >
                      <td style={{ borderLeft: "1px solid" }}></td>
                      <td
                        style={{
                          borderLeft: "1px solid",
                          textAlign: "right",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {data.shippingCharge && "Courier Charges"}
                        {data.manageName && (
                          <div style={{ textTransform: "capitalize" }}>
                            {data.manageName}
                          </div>
                        )}
                        <div>{data.roundOff && "Round-off"}</div>
                      </td>
                      <td
                        style={{
                          borderLeft: "1px solid",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      ></td>
                      <td style={{ borderLeft: "1px solid" }}></td>
                      <td style={{ borderLeft: "1px solid" }}></td>
                      <td
                        style={{
                          borderLeft: "1px solid",
                          borderRight: "1px solid",
                          fontSize: "14px",
                          textAlign: "right",
                          fontWeight: "600",
                        }}
                      >
                        {data.shippingCharge
                          ? data.shippingCharge
                              .toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })
                              .replace("\u20b9", "")
                          : 0}
                        {data.manageValue && (
                          <div>
                            {data.manageValue
                              .toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })
                              .replace("\u20b9", "")}
                          </div>
                        )}
                        {data.roundOff && (
                          <div>
                            {data.roundOff
                              .toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })
                              .replace("\u20b9", "")}
                          </div>
                        )}
                      </td>
                    </tr>
                  )}
                  {idx === countLenth - 1 && (
                    <tr
                      style={{
                        fontSize: "10px",
                        fontWeight: "500",
                        color: "#000000",
                        margin: "0px",
                        height: "6px",
                      }}
                    >
                      <td style={{ border: "1px solid" }}></td>
                      <td
                        style={{
                          border: "1px solid",
                          textAlign: "right",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          border: "1px solid",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {data && data.qty}
                      </td>
                      <td style={{ border: "1px solid" }}></td>
                      <td style={{ border: "1px solid" }}></td>
                      <td
                        style={{
                          border: "1px solid",
                          fontSize: "14px",
                          textAlign: "right",
                          fontWeight: "600",
                        }}
                      >
                        {data.totalAmount
                          ? data.totalAmount.toLocaleString("en-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                          : 0}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <table
                style={{
                  lineHeight: "13px",
                  border: "1px solid",
                  borderTop: "none",
                  borderCollapse: "collapse",
                  fontSize: "12px",
                  width: "100%",
                }}
              >
                {idx !== countLenth - 1 ? (
                  <thead>
                    <tr
                      style={{
                        borderTop: "1px solid",
                        height: "150px",
                        textAlign: "right",
                        verticalAlign: "text-top",
                      }}
                    >
                      <td style={{ paddingTop: "20px", paddingRight: "10px" }}>
                        continued...
                      </td>
                    </tr>
                  </thead>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <div>Amount Chargeable (in words)</div>
                      </td>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: "right",
                          verticalAlign: "text-top",
                        }}
                      >
                        E. &amp; O.E
                      </td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                      <td colSpan={6}>
                        <b style={{ margin: "0" }}>{this.props.Inwords}</b>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                      <td colSpan={3}>
                        <div
                          style={{
                            paddingTop: "5px",
                            fontSize: "12px",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "3px",
                            minHeight: "70px",
                          }}
                        >
                          {/* <>
                                                    <div style={{ fontSize: '12px', fontWeight: '600' }}>Company's Bank Details</div>
                                                    <div>
                                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>Bank Name:</span>
                                                        <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;ICICI BANK</span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>A/c No.:</span>
                                                        <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;777705555807</span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: '10px', fontWeight: '600' }}>Branch & IFC Code:</span>
                                                        <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;Aminabad & ICIC0001033</span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize: '12px', fontWeight: '600' }}>UPI ID:</span>
                                                        <span style={{ fontSize: '11px', fontWeight: 'bold' }}>&nbsp;MYBANK@BAK9875</span>
                                                    </div>
                                                </> */}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={3}
                        style={{ width: "50%", verticalAlign: "text-top" }}
                      >
                        <u>Delcaration</u>
                        {data.orderId &&
                          data.orderId.toString().includes("P") && (
                            <div
                              style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                              These items should be billed & shipped by our
                              partnered company
                            </div>
                          )}
                      </td>
                      <td
                        colSpan={3}
                        style={{
                          borderTop: "1px solid",
                          borderLeft: "1px solid",
                          textAlign: "right",
                        }}
                      >
                        {data.orderId &&
                          !data.orderId.toString().includes("P") && (
                            <b>For ...</b>
                          )}
                        <br />
                        <br />
                        Authorised Signatory
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              <div style={{ fontSize: "12px", textAlign: "center" }}>
                THIS IS A COMPUTER GENERATED ESTIMATE ORDER.
              </div>
            </div>
          ))}
      </div>
    );
  }
}
