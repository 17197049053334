import React, { useEffect, useState } from "react";
import {
  GreenButton,
  GreenOutButton,
  RedOutButton,
  WhiteOutButton,
} from "../../components/Buttons";
import LeadLostModal from "./LeadLostModal";
import { useAuth } from "../../components/context/Auth";
import {
  DeleteFile,
  Envelope,
  Phone,
  ThreeDots,
  WhatsApp,
} from "../../components/icons/Icon";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CreateLeadModal from "./CreateLeadModal";
import styles from "./MarketingLead.module.css";

const MarketingLead = () => {
  const navigate = useNavigate();
  const { switchDisplay, managerId } = useAuth();

  const leadId = window.location.pathname.split("/")[2];
  // const managerId = sessionStorage.getItem("mangerId")

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [slectedItem, setSelectedItem] = useState([]);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [data, setData] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  // lead edit

  const [success, setSuccess] = useState();
  const [showModal1, setShowModal1] = useState(false);

  const selectModal1 = () => {
    setShowModal1(!showModal1); // true/false toggle
  };
  // products
  const handleChange = (e) => {
    setSelectedProducts(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const [showModal, setShowModal] = useState(false);
  const selectModal = () => {
    setShowModal(!showModal); // true/false toggle
    setShow(false);
  };

  const searchData = [
    {
      src: "/assets/images/drawing_room.png",
      product: "Wallpaper",
      itemNo: "3D-30020",
      qty: "500 roll",
    },
    {
      src: "/assets/images/drawing_room.png",
      product: "Wallpaper",
      itemNo: "3D-30021",
      qty: "700 roll",
    },
    {
      src: "/assets/images/drawing_room.png",
      product: "Wallpaper",
      itemNo: "3D-30022",
      qty: 0,
    },
    {
      src: "/assets/images/drawing_room.png",
      product: "Wallpaper",
      itemNo: "3D-30023",
      qty: "400 roll",
    },
    {
      src: "/assets/images/drawing_room.png",
      product: "Wallpaper",
      itemNo: "3D-30024",
      qty: 0,
    },
  ];

  function handleRemove(i) {
    const values = [...slectedItem];
    values.splice(i, 1);
    setSelectedItem(values);
  }
  const searchValues = searchData.filter((item) =>
    item.itemNo.toLowerCase().includes(searchItem.toLocaleLowerCase())
  );

  const date = new Date();
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      var time = new Date();
      var lstr = time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      setSeconds(lstr);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const dateTime =
    date.getDate() +
    " " +
    monthNames[date.getMonth()] +
    " " +
    date.getFullYear() +
    " " +
    seconds;

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      leadId: window.location.pathname.split("/")[2],
      managerId: managerId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "marketing/lead", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setData(result.data);
          setNotes(result.data.notes ? result.data.notes : []);
          setSelectedProducts(result.data.products ? result.data.products : []);
          setProducts(result.products ? result.products : []);
          if (result.data.answered === "Yes") {
            setShow1(1);
          }
          if (result.data.answered === "No") {
            setShow1(2);
          }
          if (result.data.intrested === "Yes") {
            setShow1(3);
          }
          if (result.data.intrested === "No") {
            setShow1(4);
          }
        }
      })
      .catch((error) => {})
  }, [success, managerId]);

  // ----------------------ANSWERED------------------------------
  const handleAnswer = (answer) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      leadId: leadId,
      managerId: managerId,
      answered: answer,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "marketing/addUpdateLead", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShow1(answer === "Yes" ? 1 : 2);
          setData((prevState) => {
            return { ...prevState, answered: answer };
          });
        }
      })
      .catch((error) => {})
  };

  // ----------------------INTRESTED------------------------------
  const handleIntrested = (intrested) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      leadId: leadId,
      managerId: managerId,
      intrested: intrested,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "marketing/addUpdateLead", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShow1(intrested === "Yes" ? 3 : 4);
          setData((prevState) => {
            return { ...prevState, intrested: intrested };
          });
        }
      })
      .catch((error) => {})
  };

  // ----------------------DND------------------------------
  const handleDND = (dnd) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      leadId: leadId,
      managerId: managerId,
      dnd: dnd,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "marketing/addUpdateLead", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          // setShow1(dnd === "Yes" ? 3 : 4);
          setData((prevState) => {
            return { ...prevState, dnd: dnd };
          });
        }
      })
      .catch((error) => {})
  };

  // ----------------------TASK SCHEDULE------------------------------
  const handleTaskSchedule = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      leadId: leadId,
      managerId: managerId,
      task: data.task,
      schePri: data.schedule.priority,
      time: data.schedule.time,
      date: data.schedule.date,
      products: selectedProducts,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "marketing/addUpdateLead", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          alert("Task Scheduled");
        }
      })
      .catch((error) => {})
  };

  // ----------------------NOTES------------------------------
  const handleNotes = async (index) => {
    //console.log(index === 0 || index ? index : "helo");
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      leadId: leadId,
      managerId: managerId,
      notes:
        index === 0 || index
          ? notes.slice(0, index).concat(notes.slice(index + 1))
          : notes.reverse(),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_URL + "marketing/addUpdateLead",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setNote("");
          if (!note) {
            setNotes(notes.slice(0, index).concat(notes.slice(index + 1)));
          }
        }
      })
      .catch((error) => {})
  };
  return (
    <React.Fragment>
      <LeadLostModal
        displayModal={showModal}
        closeModal={selectModal}
        leadId={leadId}
      />
      <CreateLeadModal
        displayModal={showModal1}
        closeModal={selectModal1}
        leadId={leadId}
        setSuccess={setSuccess}
      />
      <div
        className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${
          styles.row
        } ${styles.w_100}`}
      >
        {/* <div className="width75" >
                    <LeftMenu />
                </div> */}
        <div className={`${styles.col} ${styles.m_16} ${styles.cp}`}>
          <div
            className={`${styles.text_underline} ${styles.fs_14} ${styles.mb_20}`}
          >
            <span className={styles.mouse} onClick={() => navigate(-1)}>
              &lt; Go Back
            </span>
          </div>
          <div className={styles.marketing_main_content}>
            <div className={styles.col_6}>
              <div
                className={`${
                  switchDisplay
                    ? styles.bg_white_gray_Light
                    : styles.bg_gray_dark
                } ${styles.marketOrder}`}
              >
                <div className="">
                  <div
                    className={`${styles.order_customer_name} ${styles.cpm}`}
                  >
                    {data.name}
                  </div>
                  <div
                    className={`${styles.mt_1} ${styles.marketOrder_mob_no}`}
                  >
                    {data.companyName}
                  </div>
                  <div
                    className={`${styles.mt_1} ${styles.marketOrder_mob_no}`}
                  >
                    +91-{data.mobile}
                  </div>
                  <div
                    className={`${styles.mt_1} ${styles.mb_2} ${styles.marketOrder_mob_no}`}
                  >
                    {data.address}
                  </div>
                  <div
                    className={`${styles.mt_1} ${styles.mb_5} ${styles.marketOrder_mob_no}`}
                  >
                    {data.city}
                  </div>
                  <a href={"tel:+91" + data.mobile} className="">
                    <Phone
                      css={`
                        ${styles.iconHw} ${styles.icon_mr_40}
                      `}
                      color="#FFFFFF"
                    />
                  </a>
                  <a
                    href={"https://wa.me/+91" + data.mobile}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatsApp
                      css={`
                        ${styles.iconHw} ${styles.icon_mr_40}
                      `}
                      color="#FFFFFF"
                    />
                  </a>
                  <a href={`mailto:${data.email}`} className="">
                    <Envelope css={styles.iconHw} color="#FFFFFF" />
                  </a>
                </div>

                <div className="">
                  <div className={styles.fs_24}>Priority</div>
                  <select
                    value={data.priority}
                    disabled
                    className={`${
                      switchDisplay
                        ? styles.bg_white_gray_Light
                        : styles.borderNone
                    } ${styles.p_5} ${styles.w_50}`}
                  >
                    <option>High</option>
                    <option>Modrate</option>
                    <option>Low</option>
                  </select>
                  <div className={`${styles.cpm} ${styles.call_answer}`}>
                    {show1 !== 0 ? "Is Interested" : "Call Answered"}
                  </div>
                  <br />
                  {show1 === 0 ? (
                    <>
                      <GreenOutButton
                        title="YES"
                        css={`
                          ${switchDisplay
                            ? styles.bg_white
                            : styles.bg_dark} ${styles.br_30} ${styles.float_start} ${styles.btnMKD1} ${styles.me_20}
                        `}
                        handleSubmit={() => {
                          handleAnswer("Yes");
                        }}
                      />
                      <RedOutButton
                        title="NO"
                        css={`
                          ${switchDisplay
                            ? styles.bg_white
                            : styles.bg_dark} ${styles.br_30} ${styles.float_start} ${styles.btnMKD1}
                        `}
                        handleSubmit={() => {
                          handleAnswer("No");
                        }}
                      />
                    </>
                  ) : (
                    <div>
                      <button
                        className={
                          show1 === 1
                            ? styles.yesOutBtn
                            : show1 === 3
                            ? styles.yesBtn
                            : styles.disableBtn + " " + styles.me_20
                        }
                        // disabled={show1 === 2 || show1 === 4 ? true : false}
                        onClick={() => {
                          handleIntrested("Yes");
                        }}
                      >
                        YES
                      </button>
                      <button
                        className={
                          show1 === 1 || show1 === 2
                            ? styles.dangerBtn +
                              " " +
                              styles.btnMKD1 +
                              " " +
                              styles.br_30 +
                              " " +
                              styles.float_end
                            : show1 === 4
                            ? styles.noBtn + " " + styles.float_end
                            : styles.disableBtn + " " + styles.float_end
                        }
                        // disabled={show1 === 1 || show1 === 3 ? true : false}
                        onClick={() => {
                          handleIntrested("No");
                        }}
                      >
                        NO
                      </button>
                    </div>
                  )}
                </div>
                <div
                  className=""
                  onMouseLeave={() => {
                    setShow(false);
                  }}
                >
                  <span
                    style={{ height: "fit-content" }}
                    onClick={() => setShow(!show)}
                  >
                    <ThreeDots
                      color="#FFFFFF"
                      css={styles.float_end + " " + styles.mouse}
                    />
                  </span>

                  {show && (
                    <div
                      className={styles.threeDots_div_position}
                      onMouseLeave={() => {
                        setShow(false);
                      }}
                    >
                      <div
                        className={`${
                          switchDisplay
                            ? styles.bg_white_gray_Light
                            : styles.bg_dark
                        } ${styles.border_05} ${styles.mouse}`}
                      >
                        <div
                          className={styles.mb_5 + " " + styles.fs_18}
                          onClick={() => {
                            selectModal1();
                            setShow(false);
                          }}
                        >
                          Edit Lead
                        </div>
                        <div
                          className={`${
                            switchDisplay
                              ? styles.bg_white_gray_Light +
                                " " +
                                styles.borderTopDark
                              : styles.bg_dark + " " + styles.borderTop
                          } ${styles.text_danger} ${styles.mouse}`}
                          onClick={selectModal}
                        >
                          <div
                            className={
                              styles.mt_5 +
                              " " +
                              styles.fs_18 +
                              " " +
                              styles.mouse
                            }
                            onClick={() => {
                              selectModal();
                              setShow(false);
                            }}
                          >
                            Mark As Lead Lost
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {show1 === 3 && data.answered === "Yes" ? (
                <div className={styles.mt_20}>
                  <div className={styles.ms_10 + " " + styles.fs_20}>
                    Choose Task
                  </div>
                  <select
                    className={`${
                      switchDisplay ? styles.bg_white : styles.dropdownCss
                    } ${styles.w_100} ${styles.mt_5} ${styles.p_10_5}`}
                    value={data.task}
                    onChange={(e) =>
                      setData((prevState) => ({
                        ...prevState,
                        task: e.target.value,
                      }))
                    }
                  >
                    <option value="">Choose</option>
                    <option value="Order">Order</option>
                    <option value="Schedule">Schedule</option>
                  </select>
                </div>
              ) : show1 === 4 ? (
                <div className={styles.mt_20}>
                  <div className={styles.ms_10 + " " + styles.fs_20}>
                    Mark As DND
                  </div>
                  <select
                    className={`${
                      switchDisplay ? styles.bg_white : styles.dropdownCss
                    } ${styles.w_100} ${styles.mt_5} ${styles.p_10_5}`}
                    value={data.dnd}
                    onChange={(e) => {
                      if (e.target.value) {
                        handleDND(e.target.value);
                      }
                    }}
                  >
                    <option value="">Choose</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              ) : null}
              <>
                {data.task === "Order" ? (
                  <div className={styles.mb_10}>
                    <div className={styles.mt_30 + " " + styles.ms_5}>
                      <input
                        type="search"
                        className={`${
                          switchDisplay ? styles.bg_white : styles.bg_dark
                        } ${styles.w_100} ${styles.br_30} ${styles.p_10} ${
                          styles.border_05
                        }`}
                        placeholder="Search Items To Add"
                        value={searchItem}
                        onChange={(e) => setSearchItem(e.target.value)}
                      />
                      {/* <img src="/assets/icons/Search.png" alt="Search" className="search_Icon" /> */}
                    </div>
                    {slectedItem.map((item, index) => (
                      <div
                        key={index}
                        className={`${styles.row} ${styles.align_items_center} ${styles.mt_10}`}
                      >
                        <div className={styles.col_11}>
                          <div
                            className={`${styles.row} ${styles.border_05} ${styles.rounded} ${styles.p_5}`}
                          >
                            <div className={styles.col_4}>
                              <div className={styles.p_10_5}>{item.itemNo}</div>
                            </div>
                            <div
                              className={`${styles.col_4} ${styles.mt_10} ${styles.text_center}`}
                            >
                              <span className={styles.me_5}>Qty</span>
                              <input
                                type="text"
                                className={`${
                                  switchDisplay
                                    ? styles.bg_white
                                    : styles.bg_dark
                                } ${styles.border_05} ${styles.p_5} ${
                                  styles.w_50
                                } ${styles.rounded} ${styles.text_center}`}
                                placeholder="00"
                              />
                            </div>
                            <div
                              className={`${styles.col_4} ${styles.mt_10} ${styles.text_center}`}
                            >
                              <div
                                className={`${
                                  switchDisplay
                                    ? styles.bg_white
                                    : styles.bg_dark
                                }`}
                              >
                                Amount: ₹{item.amount}/-
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={styles.col_1 + " " + styles.text_center}
                          onClick={(e) => handleRemove(index, e)}
                        >
                          <DeleteFile className={styles.text_danger} />
                          <div
                            className={styles.fs_10 + " " + styles.text_danger}
                          >
                            Delete
                          </div>
                        </div>
                      </div>
                    ))}
                    {searchItem && searchValues.length > 0 && (
                      <div
                        className={`${
                          switchDisplay
                            ? styles.bg_white + " " + styles.searchList
                            : styles.bg_dark + " " + styles.searchList
                        } ${styles.mt_10}`}
                      >
                        {searchValues.map((item, index) => (
                          <div
                            className={`${styles.row} ${styles.align_items_center} ${styles.br_5} ${styles.p_10} ${styles.border_05} ${styles.mt_5}`}
                            key={index}
                          >
                            <div className={styles.col_1}>
                              <img
                                src={item.src}
                                alt=""
                                className={styles.imgHW}
                              />
                            </div>
                            <div className={styles.col_4}>
                              <div className={styles.ms_5}>
                                Product:{item.product}
                              </div>
                              <div
                                className={`${styles.ms_5} ${styles.mt_20} ${styles.mb_5} ${styles.cpm}`}
                              >
                                {" "}
                                Item No.:{item.itemNo}
                              </div>
                              {item.qty === 0 ? (
                                <div
                                  className={`${styles.ms_5} ${styles.text_danger} ${styles.cpm} ${styles.fs_30}`}
                                >
                                  Out of stock
                                </div>
                              ) : (
                                <div
                                  className={`${styles.ms_5} ${styles.text_success} ${styles.cpm} ${styles.fs_30}`}
                                >
                                  In stock
                                </div>
                              )}
                            </div>
                            <div
                              className={
                                styles.col_4 + " " + styles.text_center
                              }
                            >
                              Available Qty: {item.qty}
                            </div>
                            <div
                              className={
                                styles.col_3 + " " + styles.text_center
                              }
                            >
                              {item.qty === 0 ? (
                                <WhiteOutButton title="Add" css={styles.cpm} />
                              ) : slectedItem.find(
                                  (obj) => obj.itemNo === item.itemNo
                                ) ? (
                                <RedOutButton
                                  title="Remove"
                                  css={styles.cpm}
                                  handleSubmit={() => {
                                    const x = slectedItem.indexOf(
                                      slectedItem.find(
                                        (obj) => obj.itemNo === item.itemNo
                                      )
                                    );
                                    slectedItem.splice(x, 1);
                                    setSelectedItem([...slectedItem]);
                                  }}
                                />
                              ) : (
                                <GreenOutButton
                                  title="Add"
                                  css={`
                                    ${switchDisplay
                                      ? styles.text_black
                                      : styles.text_white} ${styles.cpm}
                                  `}
                                  handleSubmit={() =>
                                    setSelectedItem([...slectedItem, item])
                                  }
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div
                      className={`${styles.cpm} ${styles.text_center} ${styles.mt_40}`}
                    >
                      <RedOutButton
                        title="CANCEL"
                        css={styles.cpm}
                        handleSubmit={() => navigate(-1)}
                      />
                      <GreenOutButton
                        title="SAVE"
                        css={`
                          ${switchDisplay
                            ? styles.text_black
                            : styles.text_white} ${styles.cpm} ${styles.ms_20}
                        `}
                      />
                      <GreenButton
                        title="SEND"
                        css={`
                          ${styles.ms_20} ${styles.cpm}
                        `}
                      />
                    </div>
                  </div>
                ) : data.task === "Schedule" ? (
                  <form onSubmit={handleTaskSchedule}>
                    <div className={styles.mt_10}>
                      <div className={styles.ms_10 + " " + styles.fs_20}>
                        Priority
                      </div>
                      <select
                        required
                        className={`${
                          switchDisplay ? styles.bg_white : styles.dropdownCss
                        } ${styles.w_100} ${styles.mt_5} ${styles.p_10_5}`}
                        value={data.schedule && data.schedule.priority}
                        onChange={(e) =>
                          setData({
                            ...data,
                            schedule: {
                              ...data.schedule,
                              priority: e.target.value,
                            },
                          })
                        }
                      >
                        <option value="">Choose</option>
                        <option>High</option>
                        <option>Modrate</option>
                        <option>Low</option>
                      </select>
                    </div>
                    <div className={styles.row + " " + styles.mt_10}>
                      <div className={styles.col_6 + " " + styles.fs_20}>
                        <div className={styles.ms_10}>Time</div>
                        <input
                          type="time"
                          name="time"
                          className={`${
                            switchDisplay ? styles.bg_white : styles.bg_dark
                          } ${styles.mt_5} ${styles.border_05} ${styles.p_5} ${
                            styles.w_50
                          } ${styles.timeClr}`}
                          value={data.schedule && data.schedule.time}
                          onChange={(e) =>
                            setData({
                              ...data,
                              schedule: {
                                ...data.schedule,
                                time: e.target.value,
                              },
                            })
                          }
                          required
                        />
                      </div>
                      <div className={styles.col_6 + " " + styles.fs_20}>
                        <div className={styles.ms_10}>Date</div>
                        <input
                          type="date"
                          name="date"
                          className={`${
                            switchDisplay ? styles.bg_white : styles.bg_dark
                          } ${styles.mt_5} ${styles.border_05} ${styles.p_5} ${
                            styles.w_50
                          } ${styles.timeClr}`}
                          value={data.schedule && data.schedule.date}
                          onChange={(e) =>
                            setData({
                              ...data,
                              schedule: {
                                ...data.schedule,
                                date: e.target.value,
                              },
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div
                      className={`${styles.mt_10} ${styles.mb_10} ${styles.ms_10} ${styles.fs_20}`}
                    >
                      Choose Catgories They Are Interested
                    </div>
                    {/* <div className={styles.border_05 + " " + styles.mt_5}>
                                                <div className={styles.cardcss + " " + styles.p_5}> */}
                    {/* <level className="p_5 d_flex text_center">
                                                        {buttonsData.map((item, index) =>
                                                            <button key={index} className={`${switchDisplay ? "bg_white chooseBtn" : "chooseBtn bg_Transparent"} me_10`}>{item.btnName}<span className="text_danger ms_20">X</span></button>
                                                        )}
                                                    </level> */}

                    <Select
                      options={
                        products &&
                        products.map((type) => ({
                          value: type.product_name,
                          label: type.product_name,
                        }))
                      }
                      value={products
                        .map((type) => ({
                          value: type.product_name,
                          label: type.product_name,
                        }))
                        .filter((obj) => selectedProducts.includes(obj.value))}
                      onChange={handleChange}
                      isMulti
                      className={styles.selectReact}
                    />
                    {/* </div>
                                            </div> */}
                    <div className={`${styles.text_center} ${styles.mt_40}`}>
                      <RedOutButton
                        btnType="button"
                        title="CANCEL"
                        css={styles.bgSale}
                        handleSubmit={() => navigate(-1)}
                      />
                      <GreenOutButton
                        btnType="submit"
                        title="SAVE"
                        css={`
                          ${switchDisplay
                            ? styles.text_black
                            : styles.text_white} ${styles.cpm} ${styles.ms_20}
                        `}
                      />
                    </div>
                  </form>
                ) : null}
              </>
            </div>

            <div className={styles.marketOrder_right_col}>
              <div className={styles.marketOrder_notes + " " + styles.cpm}>
                Notes
              </div>
              <div className={styles.text_end}>
                <GreenOutButton
                  title="+ Add Note"
                  css={`
                    ${switchDisplay
                      ? styles.text_black
                      : styles.text_white} ${styles.cpm}
                  `}
                  handleSubmit={() => setShowAdd(true)}
                />
              </div>

              {showAdd ? (
                <div className={styles.row + " " + styles.justify_content_end}>
                  <div
                    className={`${styles.col_2} ${styles.fs_20} ${styles.mt_20} ${styles.offset_1_2}`}
                  >
                    <div className={styles.marketOrder_mob_no}>{dateTime}</div>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleNotes();
                    }}
                    className={styles.col_10 + " " + styles.align_items_center}
                  >
                    <div className={styles.ms_10 + " " + styles.fs_20}>
                      New Note
                    </div>
                    <textarea
                      type="text"
                      className={`${
                        switchDisplay ? styles.bg_white : styles.bg_dark
                      } ${styles.text_BoxHW}`}
                      placeholder="Write Your Note.."
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      required
                    />
                    <div className={styles.text_center + " " + styles.mt_10}>
                      <RedOutButton
                        btnType="button"
                        title="CANCEL"
                        css={styles.me_40}
                        handleSubmit={() => setShowAdd(false)}
                      />
                      <GreenOutButton
                        handleSubmit={() => {
                          setNotes([
                            ...notes,
                            { note: note, dateTime: dateTime },
                          ]);
                        }}
                        title="SAVE"
                        css={`
                          ${switchDisplay
                            ? styles.text_black
                            : styles.text_white} ${styles.cpm}
                        `}
                      />
                    </div>
                  </form>
                </div>
              ) : null}

              {notes &&
                notes.reverse().map((item, index) => (
                  <div key={index} className={styles.note}>
                    <div className={` ${styles.me_5}  ${styles.w_20}`}>
                      <div className={styles.border_Right}>{item.dateTime}</div>
                    </div>
                    <div className={styles.w_70}>{item.note}</div>
                    <div
                      className={styles.text_center + " " + styles.mouse}
                      onClick={() => {
                        // setNotes(notes.slice(0, index).concat(notes.slice(index + 1)));
                        handleNotes(index);
                      }}
                    >
                      <DeleteFile
                        color="#FFFFFF"
                        className={styles.text_danger}
                      />
                      <div className={styles.fs_10 + " " + styles.text_danger}>
                        Delete
                      </div>
                    </div>
                  </div>
                ))}

              {/* </>
                                :
                                <div className="justify_content_between mt_20">
                                    <div className="fs_20 me_5 border_Right offset_1_2 marketOrder_mob_no">
                                        {dateTime}
                                        <div className="text_danger fs_20 cpm marketOrder_mob_no">
                                            Lead Lost
                                        </div>
                                    </div>
                                    <div className="pl_5 marketOrder_mob_no">
                                        Not Interested in Business &amp; Libero feugiat neque, eget vitae sed praesent eget aenean massa.
                                    </div>
                                </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MarketingLead;
